import { config } from "@/Config";

class AuthApi {
  public logIn() {
    return `${config.apiBaseUrl}login`;
  }

  public tokenLogin() {
    return `${config.apiBaseUrl}public/user/token_login`;
  }

  public logInPublicUser() {
    return `${config.apiBaseUrl}public/user/login`;
  }

    public resendMail() {
        return `${config.apiBaseUrl}resend-verify-mail`;
    }
    public sendOtp() {
        return `${config.apiBaseUrl}login/otp`;
    }
    public sendTokenLinks(){
      return `${config.apiBaseUrl}send-invitationlinks`;
    }
    public logInWithOtp() {
        return `${config.apiBaseUrl}password-less`;
    }
  public forgetPassword() {
    return `${config.apiBaseUrl}forgot-password`;
  }
  public resetPassword() {
    return `${config.apiBaseUrl}reset-password`;
  }

  // public calender of smart contract
  public smartContractCalender(
    contractId: string,
    startDate: string,
    endDate: string
  ) {
    return `${config.apiBaseUrl}todo/calendar/smartcontract?id=${contractId}&start_date=${startDate}&end_date=${endDate}`;
  }
}
export const authapi = new AuthApi();
