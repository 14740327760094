
import { defineComponent, onMounted, reactive, watch } from "vue";
import { ethitransService } from "@/services/EthitransService";
import SuccessfulModalCustom from "@/components/modal/SuccessfulModalCustom.vue";
import ErrorList from "@/components/commonDesign/ErrorList.vue";
import Editor from '@tinymce/tinymce-vue'

export default defineComponent({
  name: "MailEditor",
  props: {
    showModal: Boolean,
  },
  components: {
    Editor,
    SuccessfulModalCustom,
    ErrorList,
  },
  setup(props, { emit }) {
    const state = reactive({
      userList: [] as any,
      templateList: [] as any,
      checkUser: [] as any,
      isCheckedAll: false,
      email: "" as any,
      subject: "" as any,
      message: "" as any,

      filter: {
        selected: '',
      },
      sortList: [
        {
          name: "All",
        },
        {
          name: "Online",
        },
        {
          name: "Offline",
        },
      ],
      data: {
        SuccessFulMessage: "",
        showSuccesCustomModal: false,
        mailData: {} as any,
        showNotificationMailModel: false,
        selectedUser: {} as any,
        imageBaseUrl: "",
        title: "User",
        heading: "No User found .",
        searchUser: "",
        paging: {
          clientDocumentList: {
            perPage: 10,
            totalDocs: 0,
            totalPages: 0,
            currentPage: 1,
          },
        },
      },
      ui: {
        isLoading: {},
        isPostLoading: {
          getUserActivityList: false,
          saveEditUser: false,
        },
        error: {
          validationList: [] as any,
          hasError: false,
        },
      },

      newSearchData: {
        emailError: false,
      },
      error: {
        loginFromError: "" as any,
        loginToError: "" as any,
        createdFromError: "" as any,
        createdToError: "" as any,
      },
    });
    function serachValidation() {
      state.ui.error.validationList = [];
      state.ui.error.hasError = false;
      if (!state.subject) {
        state.ui.error.hasError = true;
        state.ui.error.validationList.push("Subject is required.");
      }
      if (!state.message) {
        state.ui.error.hasError = true;
        state.ui.error.validationList.push("Mail is required.");
      }
    }

    function allActiveUser() {
      state.ui.isPostLoading.getUserActivityList = true;
      // console.log("HELLO GROM ROLE LIST FROM PROJET DETAILS");
      const payLoad = {
        sort: state.email,
        email: state.email,
      };
      ethitransService
        .getAllActiveUser(payLoad)
        .then((res: any) => {
          console.log("getProjectRoleList Response Check:>>> ", res.data.data);
          state.userList = res.data.data.users ? res.data.data.users : [];
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.getUserActivityList = false;
        });
    }

    watch(
      () => [state.email],
      (first, second) => {
        allActiveUser();
      }
    );


    function checkAll() {
      state.isCheckedAll = !state.isCheckedAll;
      state.checkUser = [];
      if (state.isCheckedAll) {
        // Check all
        for (const user in state.userList) {
          state.checkUser.push(state.userList[user].id);
        }
        console.log("Check Online user", state.checkUser);
      }
    }
    function updateCheckall() {
      if (state.checkUser.length == state.userList.length) {
        state.isCheckedAll = true;
      } else {
        state.isCheckedAll = false;
      }
      // if (state.checkUser.length == 0) {
      // }
    }
    function clearSearch() {
      (state.email = ""), (state.filter.selected = { name: "All" } as any);
      allActiveUser();
    }

    function showSendMail() {
      console.log("lengthom", state.ui.error.validationList.length);
      serachValidation();
      allActiveUser();
      if (
        state.ui.error.validationList.length != 0 || state.ui.error.hasError
      )
        return false;
      else
        return $("#sendMail").modal("show");


    }

    function hideSendMail() {
      $("#sendMail").modal("hide");
    }

    function closeSuccessCustomModal() {
      state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
      $("#SuccessfulModalCustom").modal("hide");
    }
    function sendMail() {
      state.ui.isPostLoading.getUserActivityList = true;
      // console.log("HELLO GROM ROLE LIST FROM PROJET DETAILS");
      serachValidation();
      const payLoad = {
        subject: state.subject,
        message: state.message,
        users: state.checkUser,
        template: state.filter.selected
      };
      ethitransService
        .sendBusinessMail(payLoad)
        .then((res: any) => {
          console.log("getProjectRoleList Response Check:>>> ", res.data.data);
          hideSendMail();
          state.checkUser = [];
          state.subject = "";
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
          state.data.SuccessFulMessage = "Mail sent Successfully.";
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.getUserActivityList = false;
        });
    }
    function selectedTemplate() {
      state.message = state.filter.selected;
    }


    function allBusinessTemplate() {
      ethitransService
        .businessMailTemplateList()
        .then((res: any) => {
          console.log("getProjectRoleList Response Check:>>> ", res.data.data);
          state.templateList = res.data.data ? res.data.data : [];

        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          // state.ui.isPostLoading.getUserActivityList = false;
        });
    }

    onMounted(() => {
      Editor;
      allBusinessTemplate();
    });

    return {
      state,
      selectedTemplate,
      showSendMail,
      hideSendMail,
      allActiveUser,
      checkAll,
      updateCheckall,
      clearSearch,
      serachValidation,
      sendMail,
      closeSuccessCustomModal,
      allBusinessTemplate,
    };
  },
});
