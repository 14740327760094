
import { defineComponent, reactive, onMounted, computed } from "vue";
import { ethitransService } from "@/services/EthitransService";
import { common } from "@/common/services/Common";
import { config } from "@/Config";

import EmptyData from "@/components/commonDesign/EmptyData.vue";
import SuccessfulModalCustom from "@/components/modal/SuccessfulModalCustom.vue";

// import FormView from "@/components/cards/FormView.vue";
export default defineComponent({
  name: "UploadEdocList",
  components: {
    EmptyData,
    SuccessfulModalCustom,
  },
  setup() {
    const state = reactive({
      data: {
        imageBaseUrl: "",
        showSuccesCustomModal: false,
        successFulMessage: "",
        selectedForDelete: {} as any,
        clientDocuments: [] as any,
        clientPdfs: [] as any,
        searchUser: "",
        title: "File Tag",
        heading: "File Tag List...",
        paging: {
          clientDocumentList: {
            perPage: 10,
            totalDocs: 0,
            totalPages: 0,
            currentPage: 1,
          },
        },
      },
      ui: {
        isLoading: {
          getAccountFormList: false,
          clientDocumentList: false,
        },
        isPostLoading: {
          uploadClientDocument: false,
          enableClientDocument: false,
          disableClientDocument: false,
          deleteClientDocument: false,
        },
      },
      error: {
        hasError: false,
        erorList: {
          uploadClientDocument: [] as any,
        },
      },
    });
    function closeSuccessCustomModal() {
      state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
      $("#SuccessfulModalCustom").modal("hide");
    }

    function uploadClientDocument(pdf: any) {
      // console.log("UPLOAD READY FILE >>>", pdf)
      if (state.ui.isPostLoading.uploadClientDocument) return false;
      const payLoad = {
        name: pdf.fileName,
        file: pdf.base64,
      };
      state.ui.isPostLoading.uploadClientDocument = true;
      ethitransService
        .uploadClientDocument(payLoad)
        .then((res: any) => {
          // console.log("uploadClientDocument :>>> ", res.data.data);
          if (res.data.data && res.data.data.length) {
            res.data.data.forEach((item: any) => {
              item.enableStatus = false;
              item.disableStatus = false;
              item.deleteStatus = false;
              item.convertedFileSize = common.formatBytes(
                parseInt(item.file_size)
              );
            });
            state.data.clientDocuments = res.data.data;
          } else {
            state.data.clientDocuments = [];
          }
          $("#pdf-digitize").val("");
          if (state.data.clientPdfs && state.data.clientPdfs.length === 0) {
            $("#responseModal").modal("show");
          }
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.uploadClientDocument = false;
        });
    }
    function hideResponseModal() {
      $("#responseModal").modal("hide");
    }
    function uploadPdf() {
      if (state.data.clientPdfs && state.data.clientPdfs.length) {
        state.data.clientPdfs.forEach((item: any) => {
          uploadClientDocument(item);
          state.data.clientPdfs = state.data.clientPdfs.filter(
            (data: any) => data != item
          );
        });
      }
    }

    function readImage(file: any) {
      // console.log("FILE CHECK", file)
      const data = {} as any;
      const files = [] as any;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const extension = file.name.substring(file.name.lastIndexOf(".") + 1);

        if (extension != "pdf") return false;
        data.base64 = e.target.result;
        data.fileName = file.name.slice(0, file.name.lastIndexOf("."));
        // console.log("PDF CHECK", file);
        console.log("PDF CHECK", data);
        // data.extension = extension;
        // data.size = common.formatBytes(parseInt(file.size));
        // console.log("UPLOAD READY FILE >>>", data)
        // files.push(data)
        state.data.clientPdfs.push(data);
        // state.imageList.push(data);
        // if(files && files.length){

        //   files.forEach((item: any)=> {
        //     console.log("CHECK", item)
        uploadPdf();
        //   })
        // }
      };
      reader.readAsDataURL(file);
    }
    function onDocumentChange(event: any) {
      const files = event.target.files || event.dataTransfer.files;
      // console.log("DOCUMENT 1111", event.target.files)
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        // console.log("DOCUMENT", file)
        const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
        const extensionLower = extension.toLowerCase();
        if (extensionLower != "pdf") return false;
        readImage(file);
      }
    }
    function openPdf(pdfLink: any) {
      // alert(pdfLink)
      window.open(pdfLink);
    }

    function showDeleteModal(document: any) {
      state.data.selectedForDelete = {};
      $("#deleteConfirmeModal").modal("show");
      state.data.selectedForDelete = document;
    }
    function hideDeleteConfirmationModal(){
      $("#deleteConfirmeModal").modal("hide");
    }
    function deleteDocument() {
      // console.log("Delete Doc >> ", document);
      if (state.data.selectedForDelete.deleteStatus) return false;
      const payLoad = {
        id: state.data.selectedForDelete.id,
      };
      state.ui.isPostLoading.deleteClientDocument = true;
      // document.deleteStatus = true;
      ethitransService
        .deleteMasterFileTag(payLoad)
        .then((res: any) => {
          console.log("deleteClientDocument :>>> ", res.data.data);
          // state.data.selectedForDelete
          state.data.clientDocuments = state.data.clientDocuments.filter(
        (e: any) => e.id != state.data.selectedForDelete.id
      );
          // if (res.data.data && res.data.data.length) {
          //   res.data.data.forEach((item: any) => {
          //     item.enableStatus = false;
          //     item.disableStatus = false;
          //     item.deleteStatus = false;
          //     item.convertedFileSize = common.formatBytes(
          //       parseInt(item.file_size)
          //     );
          //   });
          //   state.data.clientDocuments = res.data.data;
          // } else {
          //   state.data.clientDocuments = [];
          // }
          // if(res.data.data){
          //   res.data.data.convertedFileSize = common.formatBytes(
          //           parseInt(
          //             res.data.data.file_size
          //           )
          //         );
          //   state.data.clientDocuments.unshift(res.data.data);
          // }
          $("#deleteConfirmeModal").modal("hide");
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.deleteClientDocument = false;
        });
    }
    function masterTagList() {
      const payLoad = {
        type : 'all'
      }
      ethitransService
        .masterFileTagList(payLoad)
        .then((res: any) => {
          console.log("masterTagList Check:>>> ", res.data.data);
          // res.data.data.forEach((item: any) => {
          //   item.label = item.name;
          //   item.value = item.code;
          // })
          state.data.clientDocuments = res.data.data ? res.data.data : [];
        })
        .catch((error: any) => {
          // common.sdCatchErr(error, state.errorList).then((res: any) => {
          //   state.isErrorStatus = true;
          //   // console.log("CHECK for Error >>>", res)
          //   for (const [key, value] of Object.entries(res)) {
          //     if (Array.isArray(value)) {
          //       state.errorList.push(value[0]);
          //     }
          //   }
          //   // if (res) {
          //   //   if (res.error) {
          //   //     console.log("CHECK for Error >>>", res.error[0])
          //   //     // state.errorList.push(res.email[0]);
          //   //     // state.errorList = [...state.errorList]
          //   //   }
          //   // }
          // });
        })
        .finally(() => {
          // state.postLoading = false;
        });
    }

    function enableDocument(document: any) {
      console.log("Enable Doc >> ", document.id);
      if (document.enableStatus) return false;
      const payLoad = {
        id: document.id,
      };
      state.ui.isPostLoading.enableClientDocument = true;
      document.enableStatus = true;
      ethitransService
        .enableMasterFileTag(payLoad)
        .then((res: any) => {
          console.log("enable File Tag :>>> ", res.data.data);
          // if (res.data.data && res.data.data.length) {
          //   res.data.data.forEach((item: any) => {
          //     item.enableStatus = false;
          //     item.disableStatus = false;
          //     item.deleteStatus = false;
          //     item.convertedFileSize = common.formatBytes(
          //       parseInt(item.file_size)
          //     );
          //   });
          //   state.data.clientDocuments = res.data.data;
          // } else {
          //   state.data.clientDocuments = [];
          // }
          // if(res.data.data){
          //   res.data.data.convertedFileSize = common.formatBytes(
          //           parseInt(
          //             res.data.data.file_size
          //           )
          //         );
          //   state.data.clientDocuments.unshift(res.data.data);
          // }
          masterTagList()
          state.data.successFulMessage = "File Tag Enabled Successfully.";
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
          
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.enableClientDocument = false;
        });
    }
    function disableDocument(document: any) {
      console.log("Disable Doc >> ", document);
      if (document.disableStatus) return false;

      const payLoad = {
        id: document.id,
      };
      state.ui.isPostLoading.disableClientDocument = true;
      document.disableStatus = true;
      ethitransService
        .disableMasterFileTag(payLoad)
        .then((res: any) => {
          console.log("disable File Tag :>>> ", res.data.data);
          masterTagList()
          // if (res.data.data && res.data.data.length) {
          //   res.data.data.forEach((item: any) => {
          //     item.enableStatus = false;
          //     item.disableStatus = false;
          //     item.deleteStatus = false;
          //     item.convertedFileSize = common.formatBytes(
          //       parseInt(item.file_size)
          //     );
          //   });
          //   state.data.clientDocuments = res.data.data;
          // } else {
          //   state.data.clientDocuments = [];
          // }
          // if(res.data.data){
          //   res.data.data.convertedFileSize = common.formatBytes(
          //           parseInt(
          //             res.data.data.file_size
          //           )
          //         );
          //   state.data.clientDocuments.unshift(res.data.data);
          // }
          state.data.successFulMessage = "File Tag Disabled Successfully.";
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.disableClientDocument = false;
        });
    }
    function copyDocumentSharableLink(inputId: any) {
      // console.log("CHECK INPUT ID >>>", inputId)
      const copyText: any = document.getElementById(inputId);

      // console.log("CHECK INPUT >>>", copyText)
      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices */

      // /* Copy the text inside the text field */
      document.execCommand("copy");
    }
    onMounted(() => {
      state.data.imageBaseUrl = config.imageBaseUrl;
      masterTagList();
    });

    return {
      state,
      hideDeleteConfirmationModal,
      onDocumentChange,
      readImage,
      uploadClientDocument,
      openPdf,
      enableDocument,
      disableDocument,
      deleteDocument,
      masterTagList,
      copyDocumentSharableLink,
      showDeleteModal,
      uploadPdf,
      hideResponseModal,
      closeSuccessCustomModal,
    };
  },
});
