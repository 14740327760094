
  import { defineComponent, onMounted, reactive } from "vue";
  import { config } from "@/Config";
  export default defineComponent({
    name: "ChannelChatWelcomeMessage",

    setup(props) {
      const state = reactive({
      imageBaseUrl: "",
    });
    
      onMounted(() => {
      state.imageBaseUrl = config.imageBaseUrl;
      // console.log("imageBaseUrl:", state.imageBaseUrl);
    });
      return { state};
    },
  });
  