<template>
  <div class="row">
    <div class="col-lg-12 d-flex align-items-center">
      <div class="form-group otp-counter">
        {{ timeLeft }}
      </div>
      <p class="otp-mini-text">
        seconds left
      </p>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, watch } from 'vue';

export default {
  name: 'CounterComponent',
  props: {
    initialTime: {
      type: Number,
      default: 90, // Default value if not passed by the parent
    },
  },
  emits: ['updateStatus'],
  setup(props, { emit }) {
    const timeLeft = ref(props.initialTime); // Set the initial time from the prop
    let countdown = null;

    const startCountdown = () => {
      countdown = setInterval(() => {
        timeLeft.value--;

        if (timeLeft.value <= 0) {
          clearInterval(countdown);
          emit('updateStatus', true);
        }
      }, 1000);
    };

    onMounted(() => {
      emit('updateStatus', false);
      startCountdown();
    });

    onUnmounted(() => {
      clearInterval(countdown); // Cleanup on unmount
    });

    // Watch for changes to the prop and reset the timer if needed
    watch(() => props.initialTime, (newTime) => {
      clearInterval(countdown);
      timeLeft.value = newTime;
      startCountdown();
    });

    return {
      timeLeft
    };
  }
};
</script>
