import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "absolute-loader-wrap justify-content-center d-flex align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", null, [
    _createVNode("div", _hoisted_1, [
      _createVNode("img", {
        src: 
                  _ctx.state.data.imageBaseUrl
                    ? `${_ctx.state.data.imageBaseUrl}img/loading.gif`
                    : 'img/loading.gif'
                ,
        alt: ""
      }, null, 8, ["src"])
    ])
  ]))
}