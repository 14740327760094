
import { defineComponent, reactive, onMounted } from "vue";
import { config } from "@/Config";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "UpgradeSubscription",
  props: {
    buttonLoading: Boolean,
  },
  setup(props, { emit }) {
    const router = useRouter();

    const state = reactive({
      data: {
        imageBaseUrl: "",
      },
    });
    function closeModal() {
      emit("closeModal");
      $("#subscriptionModal").modal("hide");
    }
    function redirectToUpgradeSubscription(){
      closeModal();
      router.push({
        name: "user-upgrade-subscription",
      });
    }
    
    onMounted(() => {
      $("#subscriptionModal").modal("show");
      state.data.imageBaseUrl = config.imageBaseUrl;
    });
    return {
      state,
      closeModal,
      redirectToUpgradeSubscription,
    };
  },
});
