
import { defineComponent, reactive, onMounted, computed, ref } from "vue";
import { ethitransService } from "@/services/EthitransService";
import { common } from "@/common/services/Common";
import { useRoute, useRouter } from "vue-router";
//   import SuccessfulModal from "@/components/modal/SuccessfulModal.vue";

export default defineComponent({
    name: "ListJobEstimation",
    components: {

    },
    setup() {
        const router = useRouter();
        const {
            params: { projectId },
        } = useRoute();
        const isInvalid = ref(false);
        const state = reactive({
            projectId: projectId,
            selectUnitId: '' as any,
            selectSubItemIndex: '' as any,
            selectItemIndex: '' as any,
            selectSubItem: '' as any,
            data: {
                lineItems: [] as any,
                unitLists: [] as any,
                unit: {
                    name: '',
                    abbreviation: '',
                },
            },
            ui: {
                isLoading: {
                    isEdit: false,
                    getItemList: false,
                    isUnitEdit: false,
                    getUnit: false,

                },
                isPostLoading: {
                    delete: false,
                    addUnite: false,
                },
                errors: {
                    errorList: {
                        createUnit: [] as any,
                    },
                    validationErrorList: {
                        itemName: "",
                        unitName: "",
                    },
                    hasError: false,
                },
            },
        });



        onMounted(() => {
            //

        });
        return {
            state,
        };
    },
});
