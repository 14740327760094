
import { useStore } from "vuex";
import { defineComponent, reactive, onMounted, watch } from "vue";
import ErrorList from "@/components/commonDesign/ErrorList.vue";
import { ethitransService } from "@/services/EthitransService";
import { common } from "@/common/services/Common";
import SuccessfulModal from "@/components/modal/SuccessfulModal.vue";
import { config } from "@/Config";
import { useRoute } from "vue-router";
export default defineComponent({
  name: "ShowDocusignShareDocumentModel",
  props: {
    showModal: Boolean,
    docDetails: Object,
    docData: Object
  },
  components: {
    // ErrorList,
    // SuccessfulModal,
  },
  setup(props, { emit }) {
    const router = useRoute();
    const store = useStore();
    const state = reactive({
      data: {
        showSuccesModal: false,
        loginUsre: localStorage.getItem("current_user_email"),
        loginUserDocumentStatus: {} as any,
        SuccessFulMessage: "",
        documentDetails: {} as any,
        imageBaseUrl: '',
      },
      ui: {
        error: {
          hasError: false,
          errorList: {
            delete: [] as any,
          },
        },
        isLoading: {
          getFolderList: false,
        },
        isPostLoading: {
          deleteSelected: false,
          clickToRedirect:false,
        },
      },
      routeValue: router.query.showModel,
      errorList: [] as any,
      isErrorStatus: false,
      postLoadingCreateAppointmen: false,
      meetingType: "" as any,
      title: "" as string,
      location: "" as string,
      note: "" as string,
      inviteUesr: [] as any,
      meetingUrl: "" as string,
      meetingCode: "" as string,
      googleMeetURL: "" as any,
      auth2: {},
    });


    function downloadFile(pdfLink: any) {
      window.open(pdfLink, "_blank");
    }

    function signDocument(uuid: any) {
      // state.ui.isLoading.clientDocumentList = true;
      state.ui.isPostLoading.clickToRedirect=true;
      ethitransService
        .envelopeSign(uuid)
        .then((res: any) => {
          // window.location.href = res.data.data.url;
          store.dispatch("openDocusignDocument", res.data.data.url);
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.clickToRedirect=false;
        });
    }


    function closeDocumentShareModel() {
      emit("close");
      $("#showDocusignShareDocument").modal("hide");
    }

    watch(
      () => store.state.saveSignedData,
      (newvalue, oldvalue) => {
        if (newvalue) {
          closeDocumentShareModel()
        }
      }
    );

    watch(
      () => store.state.openSignDocument,
      (newvalue, oldvalue) => {
        if (newvalue) {
          closeDocumentShareModel()
        }
      }
    );

    onMounted(() => {
      state.data.imageBaseUrl = config.imageBaseUrl;
      // if (props.showModal) {
      //   $("#showDocusignShareDocument").modal("show");
      // }

    });

    return {
      state,
      closeDocumentShareModel,
      signDocument,
      downloadFile
    };
  },
});
