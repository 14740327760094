import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", null, [
    _createVNode("p", null, "Browser Language Setting: " + _toDisplayString(_ctx.browserLanguage), 1),
    _createVNode("input", {
      type: "text",
      placeholder: "Type here...",
      onKeydown: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.detectKeyEvent && _ctx.detectKeyEvent(...args)))
    }, null, 32),
    _createVNode("p", null, "Detected Keyboard Language: " + _toDisplayString(_ctx.detectedLanguage), 1)
  ]))
}