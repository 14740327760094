
import { defineComponent, onMounted, reactive, computed } from "vue";
import { ethitransService } from "@/services/EthitransService";
import { useRouter } from "vue-router";
import { config } from "@/Config";
import EmptyData from "@/components/commonDesign/EmptyData.vue";

export default defineComponent({
  name: "Pdf",
  components: {
    EmptyData
  },

  setup() {
    const router = useRouter();
    const state = reactive({
      pdfList: [] as any,
      data: {
        imageBaseUrl: "",
        title: "PDF",
        heading: "No PDF found .",
        selectPdf: {} as any,
      },
      ui: {
        isLoading: {},
        isPostLoading: {
          createTutorial: false,
          saveEditUser: false,
        },
        error: {
          validationList: [] as any,
          hasError: false,
        },
      },

      newSearchData: {
        emailError: false,
      },
      error: {
        loginFromError: "" as any,
        loginToError: "" as any,
        createdFromError: "" as any,
        createdToError: "" as any,
      },
    });

    function getFaq() {
      // state.ui.isLoading.clientDocumentList = true;
      const type = 'pdf';
      ethitransService
        .getTutorial(type)
        .then((res: any) => {
          state.pdfList = res.data.data;
          console.log('res', res)
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          // state.ui.isLoading.clientDocumentList = false;
        });
    }

     function openPdfModel(data: any) {
            state.data.selectPdf = data;
            $("#pdfModal").modal("show");
        }
    function closePdfModel() {
            $("#pdfModal").modal("hide");
        }
    onMounted(() => {
      getFaq();
      state.data.imageBaseUrl = config.imageBaseUrl;
    });

    return {
      state,
      getFaq,
      openPdfModel,
      closePdfModel

    };
  },
});
