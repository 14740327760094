
import { defineComponent, reactive, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import "vue3-pagination/dist/vue3-pagination.css";
// import TutorialStep from "@/includes/TutorialStep.vue";

export default defineComponent({
  name: "Tutorial",
  components: {
  //  TutorialStep
  },
  setup() {
    const router = useRouter();
    const state = reactive({
      userList: [] as any,
      data: {
        selectedEdocForm: {} as any,
        
      },
      ui: {
        isLoading: {
          clientDocumentRetrieveWorkflow: false,
        },
        isPostLoading: {
          createFormTemplate: false,
        },
      },
    });
    
    onMounted(() => {
      console.log("Hello from Tutorial")
    });
    return {
      state,
    };
  },
});
