
import { defineComponent, reactive, onMounted, ref } from "vue";
import { ethitransService } from "@/services/EthitransService";
import { config } from "@/Config";
import { useRouter, useRoute } from "vue-router";
import { common } from "@/common/services/Common";


export default defineComponent({
  name: "MemberCard",
  props: {
    channel: Object,
    memberList: Array,
    pendingMembersCount: Number,
    approvedMembersCount: Number,
    channelType: Number,
    isUserStatus: Boolean,
    channelStatus: Boolean,
    ownerId: Number,
  },
  setup(props, { emit }) {
    const {
      params: { channelId },
    } = useRoute();
    const selectedValueForPending = ref(2) as any;
    const selectedDateForMember = ref('') as any;

    const router = useRouter();
    const state = reactive({
      data: {
        selectedPendingMember: {} as any,
        imageBaseUrl: "",
      },
      ui: {
        errors: {
          hasError: false,
          errorList: {
            modifyHistoryDateForMember: [] as any,
            resendInvitation: [] as any,
          },
        },
        isLoading: {
          communicationListAPICall: false,
        },
        isPostLoading: {
          modifyHistoryDateForMember: false,
          privateChat: false,
        },
      },
      email: "" as any,
      memberList: [] as any,
      selectedMemberForRemoval: {} as any,
      pendingMembersCount: 0 as any,
      approvedMembersCount: 0 as any,
      postLoading: {
        removeSelectedMember: false,
      },
    });
    function selectMember(member: any) {
      state.data.selectedPendingMember = member;
      state.ui.errors.errorList.modifyHistoryDateForMember = [];
      state.ui.errors.hasError = false;
      $("#modifyMemberDateByChannel").modal("show");

    }
    function hideHistoryMember() {
      $("#modifyMemberDateByChannel").modal("hide");
    }
    function modifyHistoryDateForMember() {
      state.ui.errors.errorList.modifyHistoryDateForMember = [];
      state.ui.errors.hasError = false;
      if (state.ui.isPostLoading.modifyHistoryDateForMember) return false;
      let payLoad: any = {};
      payLoad = {

        id: channelId,
        email: state.data.selectedPendingMember.email,
        // eslint-disable-next-line @typescript-eslint/camelcase
        contact_number: state.data.selectedPendingMember.contact_number,
        // eslint-disable-next-line @typescript-eslint/camelcase
        message_share: {
          type: selectedValueForPending.value,
        }
      };
      if (selectedValueForPending.value === "4") {
        payLoad.message_share.date = selectedDateForMember.value;
        // selectedDateForMember
      }
      state.ui.isPostLoading.modifyHistoryDateForMember = true;
      console.log("Payload Check >>> ", payLoad);
      // state.postLoading.saveEditProject = true;
      // ethitransService
      //   .convHistoryForUser(payLoad)
      //   .then((res: any) => {
      //     state.data.selectedProject.name = state.data.selectedProjectEdit.projectName;
      //     state.ui.successMessage = 'Project details updated successfully!';
      //     state.ui.isPostLoading.modifyHistoryDateForMember = false;
      //     hideHistoryMember()
      //   })
      //   .catch((error: any) => {
      //     // state.ui.isPostLoading.modifyHistoryDateForMember = false;
      //     // console.log(error);
      //     common
      //       .sdCatchErr(error, state.ui.errors.errorList.modifyHistoryDateForMember)
      //       .then((res: any) => {
      //         state.ui.errors.hasError = true;
      //         for (const [key, value] of Object.entries(res)) {
      //           if (Array.isArray(value)) {
      //             state.ui.errors.errorList.modifyHistoryDateForMember.push(value[0]);
      //           }
      //         }
      //       });
      //   })

      // .finally(() => {
      //   state.ui.isPostLoading.modifyHistoryDateForMember = false;
      // });
    }
    onMounted(() => {
      state.data.imageBaseUrl = config.imageBaseUrl;
      state.email = localStorage.getItem("current_user_email");
      state.pendingMembersCount = props.pendingMembersCount;
      state.approvedMembersCount = props.approvedMembersCount;

      state.memberList = props.memberList;
      state.memberList.forEach((member: any) => {
        member.isInProcess = false;
      });

      // console.log("PEMDING MEMBERS", state.pendingMembersCount);
      // console.log("APPROVED MEMBERS", state.approvedMembersCount);
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function selectedMember(member: any) {
      state.selectedMemberForRemoval = {};
      state.selectedMemberForRemoval = member;
      $("#removemember").appendTo("body").modal("show");
    }
    function hideRemoveMemberModal() {
      state.selectedMemberForRemoval = {};
      $("#removemember").modal("hide");
    }

    function muteMember(member: any) {
      state.selectedMemberForRemoval = {};
      state.selectedMemberForRemoval = member;
      $("#muteMember").appendTo("body").modal("show");
    }
    function hideMuteMemberModal() {
      state.selectedMemberForRemoval = {};
      $("#muteMember").modal("hide");
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function removeSelectedMember() {
      if (state.postLoading.removeSelectedMember) return false;

      const payLoad = {
        id: state.selectedMemberForRemoval.id,
      };
      state.postLoading.removeSelectedMember = true;
      ethitransService
        .removeChannelMember(payLoad)
        .then((res: any) => {
          console.log(
            "removeSelectedMember Response Check:>>> ",
            res.data.data.channel.members
          );
          res.data.data.channel.members.forEach((member: any) => {
            member.isInProcess = false;
          });
          emit("removedMember", state.selectedMemberForRemoval);
          emit("approveMemberCountRemove");

          state.memberList = state.memberList.filter(
            (e: any) => e != state.selectedMemberForRemoval
          );
          // state.approvedMembersCount = state.approvedMembersCount-Number(1);
          hideRemoveMemberModal();
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.postLoading.removeSelectedMember = false;
        });
    }


    function muteChannelMember() {
      if (state.postLoading.removeSelectedMember) return false;

      const payLoad = {
        id: state.selectedMemberForRemoval.id,
        mute: !state.selectedMemberForRemoval.is_mute,
      };
      state.postLoading.removeSelectedMember = true;
      ethitransService
        .muteChannelMember(payLoad)
        .then((res: any) => {
          const memeberIndex = state.memberList.findIndex((item: any) => item.id === res.data.data.id);
          if (memeberIndex !== -1) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            state.memberList[memeberIndex].is_mute = res.data.data.is_mute;
          }

          hideMuteMemberModal();
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.postLoading.removeSelectedMember = false;
        });
    }


    function privateChat(member: any) {
      if (props.channel && props.channel.project) {
        // console.log("Private CHAT >>>", member.user)
        if (state.ui.isPostLoading.privateChat) return false;
        member.isInProcess = true;
        let channel: any = "";
        if (props.channel && props.channel.related_id) {
          channel = props.channel.related_id;
        } else {
          channel = "";
        }
        const memberUUID: any = member.user;
        console.log("Private CHAT >>>", memberUUID.uuid);
        state.ui.isPostLoading.privateChat = true;
        ethitransService
          .phasePrivateChannelByPhaseIdUserId(
            channel,
            memberUUID.uuid,
            props.channel.project.uuid
          )
          .then((res: any) => {
            member.isInProcess = false;
            console.log("privateChat Response Check:>>> ", res.data.data);
            if (res.data.data.channel && res.data.data.channel.uuid) {
              router.push({
                name: "admin-channel",
                params: { channelId: res.data.data.channel.uuid.toString() },
              });
            }
          })
          .catch((error: any) => {
            console.log(error);
          })
          .finally(() => {
            state.ui.isPostLoading.privateChat = false;
          });
      }
    }
    // console.log("CHECK props", props.member.id);
    return {
      state,
      selectMember,
      hideHistoryMember,
      modifyHistoryDateForMember,
      privateChat,
      selectedMember,
      removeSelectedMember,
      hideRemoveMemberModal,
      muteMember,
      hideMuteMemberModal,
      muteChannelMember
    };
  },
});
