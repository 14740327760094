
import { defineComponent, reactive, onMounted, watch, computed } from "vue";
import { useRoute, useRouter } from "vue-router";

import { ethitransService } from "@/services/EthitransService";
import ErrorList from "@/components/commonDesign/ErrorList.vue";
import { validationService } from "@/common/ValidationService";
import { common } from "@/common/services/Common";
import { config } from "@/Config";

export default defineComponent({
  name: "ProjectShareMemberInvitation",
  components: {
    ErrorList,
  },
  setup() {
    const router = useRouter();
    const {
      params: { invitationId },
    } = useRoute();
    const state = reactive({
      data: {
        selectAll: false,
        childCheck:false,
        showSuccesModal: false,
        successFulMessage: "",
        declineMessage: "",
        imageBaseUrl: "",
        selectedInvition: {} as any,
        projectShareInvitionDetails: {
          attachments: [],
          breadcrumbs: [],
          allInvitation: [],
          phaseIds:[] as any,
          payers: [],
          phase: {} as any,
          project: {},
          type: 0,
          role: 0,
          description: "",
          todayLog: {} as any,
        } as any,
        channelIndex: 0,
        inviteAsGuest: false,
      },
      ui: {
        modal: {
          showProjectAcknowledgementModal: false,
        },
        isLoading: {
          projectShareInvitionDetails: false,
        },
        isPostLoading: {
          redirectToChat: false,
          acknowledgeProject: false,
          declineProject: false,
          projectShareInvitionDetails: false,
          acceptInvitation: false,
          acceptSelectedPhase: false,
          declineSelectedPhase: false,
        },
        errors: {
          errorList: {
            projectShareInvitionDetails: [] as any,
            declineTaskInvitations: [] as any,
          },
          hasError: false,
        },
      },
    });
    const isInvitatationSelected = computed(() => {
      if (!state.data.projectShareInvitionDetails.child_invitations) return false;
      const res = state.data.projectShareInvitionDetails.child_invitations.some(
        (item: any) => item.isChecked === true
      );
      return res;
    });
    watch(
      // () => watchTour,
      () => state.data.selectAll,
      (newvalue, oldvalue) => {
        if (newvalue === true) {
          state.data.projectShareInvitionDetails.child_invitations.forEach(
            (item: any) => {
              item.isChecked = true;
              item.tasks.forEach((tem2: any) => {
                tem2.isChecked = true;
              });

            }
          );
        } else {
          state.data.projectShareInvitionDetails.child_invitations.forEach(
            (item: any) => {
              item.isChecked = false;
              item.tasks.forEach((tem2: any) => {
                tem2.isChecked = false;
              });
            }
          );
        }

      }
    );

    function showSuccesModal() {
      $("#successfulModal").modal("show");
    }
    function closeSuccessfulModal() {
      $("#successfulModal").modal("hide");
      router.push({
        name: "admin-invitation",
      });
    }

    function showGenerateConfirmationModal() {
      state.ui.errors.errorList.projectShareInvitionDetails = [];
      state.ui.errors.hasError = false;
      $("#generateConfirmationModal").modal("show");
    }
    function hideGenerateConfirmationModal() {
      state.ui.errors.errorList.projectShareInvitionDetails = [];
      state.ui.errors.hasError = false;
      $("#generateConfirmationModal").modal("hide");
    }
    function check(selectedIndex: any, checkData: any) {
      state.data.projectShareInvitionDetails.child_invitations[selectedIndex].tasks.map((task: any) => task.isChecked = checkData)
      console.log("che",state.data.projectShareInvitionDetails);
    }

    function checkTask(parentSelectIndex: any ,selectedIndex: any, checkData: any) {
    state.data.childCheck =!state.data.childCheck;
      console.log("checkdata",parentSelectIndex , selectedIndex ,checkData)
      console.log("check",state.data
  .projectShareInvitionDetails.child_invitations[parentSelectIndex]);
  const checkedTask=state.data.projectShareInvitionDetails.child_invitations[parentSelectIndex].tasks.filter((task: any)=>task.isChecked==true)
  console.log("checktask",checkedTask);
  if(checkedTask.length==state.data.projectShareInvitionDetails.child_invitations[parentSelectIndex].tasks.length){
    state.data.projectShareInvitionDetails.child_invitations[parentSelectIndex].isChecked=true
    }else{
      state.data.projectShareInvitionDetails.child_invitations[parentSelectIndex].isChecked=false
    }
    }


    function acknowledgeProject() {
      state.ui.errors.errorList.projectShareInvitionDetails = [];
      state.ui.errors.hasError = false;
      if (state.ui.isPostLoading.acknowledgeProject) return false;
      state.ui.isPostLoading.acknowledgeProject = true;
      ethitransService
        .acceptProjectApprovalInvitation(invitationId.toString())
        .then((res: any) => {
          // console.log("Acknowledge Project Response:>>> ", res.data.data);
          // res.data.data.payerRequest.localStartedDate = common.localDateAndTime(
          //   res.data.data.payerRequest.start_date
          // );
          // res.data.data.payerRequest.localEndDate = common.localDateAndTime(
          //   res.data.data.payerRequest.end_date
          // );
          // res.data.data.attachments.forEach((attachment: any) => {
          //   // attachment.file_size
          //   attachment.convertedFileSize = common.formatBytes(
          //     parseInt(attachment.file_size)
          //   );
          // });
          //state.data.projectShareInvitionDetails = res.data.data;
          state.data.projectShareInvitionDetails.phase.status = 2;
        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.ui.errors.errorList.projectShareInvitionDetails)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.projectShareInvitionDetails.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.acknowledgeProject = false;
        });
      // alert("Acknowledge Project");
    }
    function closeAcknowledgementInvitationModal() {
      $("#deleteAcknowledgementInvitationModal").modal("hide");
    }
    function declineProject() {
      state.ui.errors.errorList.projectShareInvitionDetails = [];
      state.ui.errors.hasError = false;
      if (state.ui.isPostLoading.declineProject) return false;
      if (!state.data.declineMessage) return false;
      const payLoad = {
        id: invitationId.toString(),
        description: state.data.declineMessage,
      };
      state.ui.isPostLoading.declineProject = true;
      ethitransService
        .declineProjectApprovalInvitation(payLoad)
        .then((res: any) => {

          state.data.projectShareInvitionDetails.phase.status = 3;
          closeAcknowledgementInvitationModal();
        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.ui.errors.errorList.projectShareInvitionDetails)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.projectShareInvitionDetails.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.declineProject = false;
        });
    }

    function showSelectedDeclineModal() {
      state.data.declineMessage = "";
      state.ui.errors.errorList.declineTaskInvitations = [];
      state.ui.errors.hasError = false;
      $("#deleteSelectedInvitation").modal("show");
    }

    function hideSelectedDeclineModal() {
      state.ui.errors.errorList.declineTaskInvitations = [];
      state.ui.errors.hasError = false;
      state.data.declineMessage = "";
      $("#deleteSelectedInvitation").modal("hide");
    }
    function showDeclineModal() {
      state.data.declineMessage = "";
      $("#deleteAcknowledgementInvitationModal").modal("show");
    }
    function projectShareInvitationDetail() {
      state.ui.errors.errorList.projectShareInvitionDetails = [];
      state.ui.errors.hasError = false;
      if (state.ui.isLoading.projectShareInvitionDetails) return false;
      state.ui.isLoading.projectShareInvitionDetails = true;
      ethitransService
        .projectShareMemberInvitation(invitationId.toString())
        .then((res: any) => {
          console.log('console',res.data.data);
          
          state.data.projectShareInvitionDetails = res.data.data;
          state.data.projectShareInvitionDetails.allInvitation = [];
          state.data.projectShareInvitionDetails.phaseIds= [];
          // state.data.projectShareInvitionDetails.allInvitation =res.data.data.allInvitation
          res.data.data.child_invitations.forEach((invitation: any) => {
            state.data.projectShareInvitionDetails.phaseIds.push(invitation.uuid);
            invitation.tasks.forEach((task: any)=>{
              state.data.projectShareInvitionDetails.allInvitation.push(task.uuid);
            })
          }); 

        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.ui.errors.errorList.projectShareInvitionDetails)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.projectShareInvitionDetails.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isLoading.projectShareInvitionDetails = false;
        });
    }

    function acceptInvitation(uuid: any) {
      state.ui.errors.errorList.projectShareInvitionDetails = [];
      state.ui.errors.hasError = false;
      if (state.ui.isPostLoading.acceptInvitation) return false;
      const payLoad = {
        id: state.data.projectShareInvitionDetails.allInvitation,
        phaseId: state.data.projectShareInvitionDetails.phaseIds,
      };
      state.ui.isPostLoading.acceptInvitation = true;

      ethitransService
        .joinProjectShareInvitation(payLoad)
        .then((res: any) => {
          // eslint-disable-next-line @typescript-eslint/camelcase
          state.data.projectShareInvitionDetails.is_complete = 1;
          state.data.successFulMessage = "Project Joined";
          showSuccesModal()
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.errors.errorList.projectShareInvitionDetails)
            .then((res: any) => {
              state.ui.errors.hasError = true;

              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.projectShareInvitionDetails.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.acceptInvitation = false;
        });
    }
    function acceptTodoInvitation(uuid: any) {
      state.ui.errors.errorList.projectShareInvitionDetails = [];
      state.ui.errors.hasError = false;
      // console.log("OHH ACCEPT");
      if (state.ui.isPostLoading.acceptInvitation) return false;
      state.ui.isPostLoading.acceptInvitation = true;

      ethitransService
        .acceptToDoInvitation(uuid.toString())
        .then((res: any) => {
          // eslint-disable-next-line @typescript-eslint/camelcase
          state.data.projectShareInvitionDetails.is_complete = 1;
          state.data.showSuccesModal = !state.data.showSuccesModal;
          state.data.successFulMessage = "Channel Joined";
          // console.log("CHECK acceptInvitation:>>", res.data.data);
          // removeSelectedInvitation();
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.errors.errorList.projectShareInvitionDetails)
            .then((res: any) => {
              state.ui.errors.hasError = true;

              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.projectShareInvitionDetails.push(value[0]);
                }
              }
              // console.log("CHECK for Error >>>", res)
              // if (res) {
              //   if (res.error) {
              //     console.log("CHECK ERROR", res.error)
              //     state.ui.errors.errorList.projectShareInvitionDetails = res.error;
              //     // state.ui.errors.errorList.projectShareInvitionDetails = [...state.ui.errors.errorList.projectShareInvitionDetails]
              //   } else {
              //     // state.ui.errors.errorList.projectShareInvitionDetails.push(res);
              //   }
              // }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.acceptInvitation = false;
        });
    }
    function acceptPhaseInvitation(uuid: any) {
      state.ui.errors.errorList.projectShareInvitionDetails = [];
      state.ui.errors.hasError = false;
      // console.log("OHH ACCEPT");
      if (state.ui.isPostLoading.acceptInvitation) return false;
      state.ui.isPostLoading.acceptInvitation = true;

      ethitransService
        .acceptPhaseInvitation(uuid.toString())
        .then((res: any) => {
          // eslint-disable-next-line @typescript-eslint/camelcase
          state.data.projectShareInvitionDetails.is_complete = 1;
          state.data.showSuccesModal = !state.data.showSuccesModal;
          state.data.successFulMessage = "Channel Joined";
          // console.log("CHECK acceptInvitation:>>", res.data.data);
          // removeSelectedInvitation();
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.errors.errorList.projectShareInvitionDetails)
            .then((res: any) => {
              state.ui.errors.hasError = true;

              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.projectShareInvitionDetails.push(value[0]);
                }
              }
              // console.log("CHECK for Error >>>", res)
              // if (res) {
              //   if (res.error) {
              //     console.log("CHECK ERROR", res.error)
              //     state.ui.errors.errorList.projectShareInvitionDetails = res.error;
              //     // state.ui.errors.errorList.projectShareInvitionDetails = [...state.ui.errors.errorList.projectShareInvitionDetails]
              //   } else {
              //     // state.ui.errors.errorList.projectShareInvitionDetails.push(res);
              //   }
              // }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.acceptInvitation = false;
        });
    }
    function join() {
      // alert(invitationId);
      acceptInvitation(invitationId);
    }

    function declineProjectShareInvitation(uuid: any) {
      state.ui.errors.errorList.projectShareInvitionDetails = [];
      state.ui.errors.hasError = false;
      if (state.ui.isPostLoading.declineProject) return false;
      if (!state.data.declineMessage) return false;
      const payLoad = {
        id: state.data.projectShareInvitionDetails.allInvitation,
        description: state.data.declineMessage,
      };
      state.ui.isPostLoading.declineProject = true;
      console.log("PAYLOAD CHECK >>> ", payLoad);
      ethitransService
        .declineProjectShareInvitation(payLoad)
        .then((res: any) => {
          // eslint-disable-next-line @typescript-eslint/camelcase
          state.data.projectShareInvitionDetails.is_declined = 1;
          closeAcknowledgementInvitationModal();
          state.data.successFulMessage = "Project Declined";
          showSuccesModal()
        })
        .catch((error: any) => {
          console.log(error);
          common
            .sdCatchErr(error, state.ui.errors.errorList.projectShareInvitionDetails)
            .then((res: any) => {
              state.ui.errors.hasError = true;

              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.projectShareInvitionDetails.push(value[0]);
                }
              }

            });
        })
        .finally(() => {
          state.ui.isPostLoading.declineProject = false;
        });
    }

    function decline() {
      declineProjectShareInvitation(invitationId);
    }
    function acceptSelectedPhase() {
      state.ui.errors.errorList.projectShareInvitionDetails = [];
      state.ui.errors.hasError = false;
      // console.log("OHH ACCEPT");
      const invitations: any = [];
      const selectPhase: any = [] ;
      state.data.projectShareInvitionDetails.child_invitations.forEach(
        (item: any) => {
          if (item.isChecked) {
            selectPhase.push(item.uuid);
          }
          item.tasks.forEach((task: any)=>{
           if(task.isChecked){
            invitations.push(task.uuid);

           }
          })
        }
      );
      if (state.ui.isPostLoading.acceptSelectedPhase) return false;
      const payLoad = {
        id: invitations,
        phaseId: selectPhase,
      };
      console.log("CHECK THE PAYLOAD >>> ", payLoad);
      state.ui.isPostLoading.acceptSelectedPhase = true;
      ethitransService
        .joinProjectShareInvitation(payLoad)
        .then((res: any) => {
          state.data.projectShareInvitionDetails = res.data.data;
          hideGenerateConfirmationModal();
          state.data.showSuccesModal = !state.data.showSuccesModal;
          state.data.successFulMessage = "Joined";
          showSuccesModal()

        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.errors.errorList.projectShareInvitionDetails)
            .then((res: any) => {
              state.ui.errors.hasError = true;

              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.projectShareInvitionDetails.push(value[0]);
                }
              }
              // console.log("CHECK for Error >>>", res)
              // if (res) {
              //   if (res.error) {
              //     console.log("CHECK ERROR", res.error)
              //     state.ui.errors.errorList.projectShareInvitionDetails = res.error;
              //     // state.ui.errors.errorList.projectShareInvitionDetails = [...state.ui.errors.errorList.projectShareInvitionDetails]
              //   } else {
              //     // state.ui.errors.errorList.projectShareInvitionDetails.push(res);
              //   }
              // }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.acceptSelectedPhase = false;
        });
    }


    function declineSelectedPhase() {
      state.ui.errors.errorList.declineTaskInvitations = [];
      state.ui.errors.hasError = false;
      // console.log("OHH ACCEPT");
      const invitations: any = [];
      state.data.projectShareInvitionDetails.child_invitations.forEach(
        (item: any) => {
          // if (item.phase_detail.phase.isChecked) {
          //   invitations.push(item.uuid);
          // }
          item.tasks.forEach((task: any)=>{
           if(task.isChecked){
            invitations.push(task.uuid);

           }
          })
        }
        
      );
      if (state.ui.isPostLoading.declineSelectedPhase) return false;
      if (!state.data.declineMessage) return false;
      const payLoad = {
        id: invitations,
        description: state.data.declineMessage,
      };
      console.log("CHECK THE DECLINE PAYLOAD >>> ", payLoad);
      state.ui.isPostLoading.declineSelectedPhase = true;

      ethitransService
        .declineProjectShareInvitation(payLoad)
        .then((res: any) => {

          // eslint-disable-next-line @typescript-eslint/camelcase
          state.data.projectShareInvitionDetails.is_declined = 1;
          state.data.projectShareInvitionDetails = res.data.data;
          state.data.successFulMessage = " Declined";
          // console.log("CHECK declineSelectedPhase:>>", res.data.data);
          // removeSelectedInvitation();
          hideSelectedDeclineModal();
          showSuccesModal()
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.errors.errorList.declineTaskInvitations)
            .then((res: any) => {
              state.ui.errors.hasError = true;

              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.declineTaskInvitations.push(
                    value[0]
                  );
                }
              }
              // console.log("CHECK for Error >>>", res)
              // if (res) {
              //   if (res.error) {
              //     console.log("CHECK ERROR", res.error)
              //     state.ui.errors.errorList.declineTaskInvitations = res.error;
              //     // state.ui.errors.errorList.declineTaskInvitations = [...state.ui.errors.errorList.declineTaskInvitations]
              //   } else {
              //     // state.ui.errors.errorList.declineTaskInvitations.push(res);
              //   }
              // }
            });
          hideSelectedDeclineModal();
        })
        .finally(() => {
          state.ui.isPostLoading.declineSelectedPhase = false;
        });
    }

    function redirectToInvitation() {
      router.push({
        name: "admin-invitation",
      });
    }
    onMounted(() => {
      console.log("Check>> ", invitationId);
      projectShareInvitationDetail();
      state.data.imageBaseUrl = config.imageBaseUrl;

    });

    return {
      state,
      showGenerateConfirmationModal,
      hideGenerateConfirmationModal,
      declineSelectedPhase,
      showSelectedDeclineModal,
      hideSelectedDeclineModal,
      join,
      acceptSelectedPhase,
      isInvitatationSelected,
      check,
      acceptInvitation,
      acceptTodoInvitation,
      acceptPhaseInvitation,
      decline,
      closeAcknowledgementInvitationModal,
      showDeclineModal,
      projectShareInvitationDetail,
      acknowledgeProject,
      declineProject,
      checkTask,
      closeSuccessfulModal,
      showSuccesModal,
      redirectToInvitation
    };
  },
});
