
import { defineComponent, onMounted } from "vue";

export default defineComponent({
  name: "Button",
  props: {
    showConfirmationModal: Boolean,
    buttonLoading: Boolean,
    buttonName: String,
    message: String,
  },
  setup(props, { emit }) {
    function closeModal() {
      $("#deleteConfirmationModal").modal("hide");
      emit("hideConfirmation");
    }
    function buttonSubmit() {
      emit("buttonTrigger");
      closeModal();
    }
    
    onMounted(() => {
      if (props.showConfirmationModal) {
        $("#deleteConfirmationModal").modal("show");
      }
    });
    return {
      buttonSubmit,
      closeModal,
    };
  },
});
