import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "sb-nav-fixed" }
const _hoisted_2 = { id: "layoutSidenav" }
const _hoisted_3 = { id: "layoutSidenav_content" }
const _hoisted_4 = { class: "main-body" }
const _hoisted_5 = { class: "py-4 bg-light mt-auto main-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheNavBar = _resolveComponent("TheNavBar")
  const _component_TheSideNavBar = _resolveComponent("TheSideNavBar")
  const _component_router_view = _resolveComponent("router-view")
  const _component_TheFooter = _resolveComponent("TheFooter")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_TheNavBar),
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_TheSideNavBar),
      _createVNode("div", _hoisted_3, [
        _createVNode("main", _hoisted_4, [
          _createVNode(_component_router_view)
        ]),
        _createVNode("footer", _hoisted_5, [
          _createVNode(_component_TheFooter)
        ])
      ])
    ])
  ]))
}