<template>
  <div>
    <label for="inputField">Enter Email or US Mobile Number:</label>
    <input
      id="inputField"
      v-model="inputValue"
      @input="handleInput"
      @paste="handlePaste"
      placeholder="Enter email or mobile number"
      type="text"
    />
    <p v-if="error" style="color: red;">{{ error }}</p>
  </div>
</template>

<script>
import { ref, watch } from 'vue';

export default {
  name: 'EmailOrMobileInput',
  props: {
    modelValue: String, // Two-way binding for data exchange with parent
  },
  emits: ['update:modelValue'], // Emit value to parent
  setup(props, { emit }) {
    const inputValue = ref(props.modelValue || '');
    const error = ref('');

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const usMobileRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

    const validateInput = () => {
      const value = inputValue.value.trim();

      if (emailRegex.test(value)) {
        error.value = '';
        emit('update:modelValue', value); // Emit email if valid
      } else if (usMobileRegex.test(value)) {
        error.value = '';
        emit('update:modelValue', value); // Emit mobile if valid
      } else {
        error.value = 'Invalid email or US mobile number';
      }
    };

    const formatPhoneNumber = (value) => {
      const digits = value.replace(/\D/g, ''); // Remove all non-digit characters

      // Format the digits into US mobile phone number pattern
      if (digits.length <= 3) return digits;
      if (digits.length <= 6) return `(${digits.slice(0, 3)}) ${digits.slice(3)}`;
      return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
    };

    const handleInput = (event) => {
      const value = event.target.value;

      // Only apply phone formatting if input is all numbers
      if (/^\d*$/.test(value)) {
        inputValue.value = formatPhoneNumber(value);
        error.value = usMobileRegex.test(inputValue.value) ? '' : 'Invalid phone number format';
      } else {
        // If it contains letters or "@" symbol, treat it as an email
        inputValue.value = value;
        error.value = emailRegex.test(value) ? '' : 'Invalid email format';
      }

      validateInput();
    };

    const handlePaste = (event) => {
      event.preventDefault();

      const pasteData = event.clipboardData.getData('Text').trim();

      // Check if pasted data is a valid email or phone number
      if (emailRegex.test(pasteData)) {
        inputValue.value = pasteData;
        error.value = '';
      } else {
        inputValue.value = formatPhoneNumber(pasteData);
        error.value = usMobileRegex.test(inputValue.value) ? '' : 'Invalid phone number format';
      }

      validateInput();
    };

    watch(
      () => props.modelValue,
      (newValue) => {
        inputValue.value = newValue || '';
      }
    );

    return {
      inputValue,
      error,
      validateInput,
      handleInput,
      handlePaste,
    };
  },
};
</script>

<style scoped>
/* Custom styles */
</style>
