
import { defineComponent, reactive, computed, onMounted, watch } from "vue";
import { useRoute } from "vue-router";
import Multiselect from "@vueform/multiselect";

import { ethitransService } from "@/services/EthitransService";
import { common } from "@/common/services/Common";
import { config } from "@/Config";

export default defineComponent({
  name: "MyDriveFilesUpload",
  props: {
    imageList: {
      type: Array,
    },
    dataFrom:String,
  },
  components: {
    Multiselect,
    // RenameDocumentModal,
  },
  setup(props, { emit }) {
    const router = useRoute();
    const state = reactive({
      data: {
        showAddFileTag: false,
        showSuccesModal: false,
        showShareModal: false,
        editImageName: false,
        searchByTag: "",
        SuccessFulMessage: "",
        folderName: "",
        disabledFileTagList: [] as any,
        selectedFolders: [] as any,
        selectedFiles: [] as any,
        imagesToUpload: [] as any,
        imageBaseUrl: "",
        folderList: {
          files: [] as any,
          folders: [] as any,
        },
        folderTitle: "folder",
        folderHeading: "No folders created",
        fileTitle: "file",
        fileHeading: "No files created",
        tags: {
          mode: "tags",
          value: [] as any,
          options: [] as any,
          searchable: true,
          createTag: true,
        },
      },
      ui: {
        error: {
          hasError: false,
          errorList: {
            uploadAttachments: [] as any,
            createNewFolder: [] as any,
            getFolderList: [] as any,
          },
        },
        isLoading: {
          getFolderList: false,
        },
        isPostLoading: {
          enableSelectedFileTag: false,
          addTagsToFile: false,
          createNewFolder: false,
          uploadAttachments: false,
        },
      },
    });
    // eslint-disable-next-line vue/return-in-computed-property
    const masterTags = computed(() => {
      const array: any = [];
      state.data.tags.options.forEach((item: any) => {
        array.push(item.name);
      });

      return array;
    });
    // eslint-disable-next-line vue/return-in-computed-property
    const disabledFileTagList = computed(() => {
      return state.data.disabledFileTagList;
    });
    function masterTagList() {
      const payLoad = {
        type: "enabled",
      };
      ethitransService
        .masterFileTagList(payLoad)
        .then((res: any) => {
          // console.log("masterTagList Check:>>> ", res.data.data);
          res.data.data.forEach((item: any) => {
            item.label = item.name;
            item.value = item.code;
          });
          state.data.tags.options = res.data.data ? res.data.data : [];

          emit("latestEnabledTags", state.data.tags.options);
        })
        .catch((error: any) => {
          // common.sdCatchErr(error, state.errorList).then((res: any) => {
          //   state.isErrorStatus = true;
          //   // console.log("CHECK for Error >>>", res)
          //   for (const [key, value] of Object.entries(res)) {
          //     if (Array.isArray(value)) {
          //       state.errorList.push(value[0]);
          //     }
          //   }
          //   // if (res) {
          //   //   if (res.error) {
          //   //     console.log("CHECK for Error >>>", res.error[0])
          //   //     // state.errorList.push(res.email[0]);
          //   //     // state.errorList = [...state.errorList]
          //   //   }
          //   // }
          // });
        })
        .finally(() => {
          // state.postLoading = false;
        });
    }
    function enableSelectedFileTag(document: any) {
      // console.log("Enable Doc >> ", document.id);
      if (document.enableStatus) return false;
      const payLoad = {
        id: document.id,
      };
      state.ui.isPostLoading.enableSelectedFileTag = true;
      document.inProgress = true;
      ethitransService
        .enableMasterFileTag(payLoad)
        .then((res: any) => {
          // console.log("enable File Tag :>>> ", res.data.data);
          state.data.disabledFileTagList =
            state.data.disabledFileTagList.filter(
              (data: any) => data.id != document.id
            );
          masterTagList();
          // state.data.successFulMessage = "File Tag Enabled Successfully.";
          // state.data.showSuccesModal = !state.data.showSuccesModal;
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.enableSelectedFileTag = false;
          document.inProgress = false;
        });
    }
    function renameUploadImage(image: any) {
      image.isEdit = true;
    }
    function renameUploadImageSave(image: any) {
      image.isEdit = false;
    }
    function uploadImage() {
      emit("uploadImage", state.data.imagesToUpload);
    }
    // eslint-disable-next-line vue/return-in-computed-property
    const readyImageListToUpload = computed(() => {
      emit("uploadImage", state.data.imagesToUpload);

      return state.data.imagesToUpload;
    });
    function removeSelectedImage(image: any) {
      console.log("REMOVE IMG >> ", image);
      emit("removeSelectedImage", image);
      // if (state.postLoading) return false;
      // state.data.imagesToUpload = state.data.imagesToUpload.filter(
      //   (e: any) => e != image
      // );
    }
    function masterTagListForValidation(data: boolean) {
      const payLoad = {
        type: data ? "enabled" : "disabled",
      };
      ethitransService
        .masterFileTagList(payLoad)
        .then((res: any) => {
          // console.log("masterTagList Check:>>> ", res.data.data);
          // res.data.data.forEach((item: any) => {
          //   item.label = item.name;
          //   item.value = item.code;
          // })
          // state.data.tagListForValidation = res.data.data ? res.data.data : [];
          const disabledTagList: any = [];
          state.data.imagesToUpload.forEach((item: any) => {
            item.tag.forEach((tag: any) => {
              // console.log("CHECK Tag >>",tag);
              res.data.data.forEach((data: any) => {
                if (tag.toUpperCase() === data.name.toUpperCase()) {
                  data.inProgress = false;
                  // console.log("tag values >>> ",item )
                  // console.log("tagListForValidation  values >>> ", data.name)
                  disabledTagList.push(data);
                }
              });
            });

            //
          });
          // console.log("LIST>>> ", disabledTagList);
          if (disabledTagList && disabledTagList.length) {
            const uniqueSet = Array.from(new Set(disabledTagList));
            // const tag: any = []
            state.data.disabledFileTagList = uniqueSet ? uniqueSet : [];
          } else {
            state.data.disabledFileTagList = [];
          }
          // console.log("LIST FINAL UNIQ>>> ", state.data.disabledFileTagList);

          if (
            state.data.disabledFileTagList &&
            !state.data.disabledFileTagList.length
          ) {
            state.data.showAddFileTag = true;
          }
          // state.data.disabledFileTagList.forEach((item: any)=> {
          // state.data.showAddFileTag = true

          // })
        })
        .catch((error: any) => {
          // common.sdCatchErr(error, state.errorList).then((res: any) => {
          //   state.isErrorStatus = true;
          //   // console.log("CHECK for Error >>>", res)
          //   for (const [key, value] of Object.entries(res)) {
          //     if (Array.isArray(value)) {
          //       state.errorList.push(value[0]);
          //     }
          //   }
          //   // if (res) {
          //   //   if (res.error) {
          //   //     console.log("CHECK for Error >>>", res.error[0])
          //   //     // state.errorList.push(res.email[0]);
          //   //     // state.errorList = [...state.errorList]
          //   //   }
          //   // }
          // });
        })
        .finally(() => {
          // state.postLoading = false;
        });
    }
    function addTag(tag: any) {
      // console.log("Check the New Tag >>>", tag);
      const payLoad = {
        name: tag.toUpperCase(),
      };
      // state.postLoading = true;
      ethitransService
        .createMasterFileTag(payLoad)
        .then((res: any) => {
          if (res.data.data && res.data.data.is_active === 1) {
            state.data.tags.options.push(res.data.data);
          } else {
            state.data.tags.options.push({ name: tag.toUpperCase() });
          }
          masterTagListForValidation(false);
        })
        .catch((error: any) => {
          console.log("Erorrs:>>> ", error);
        })
        .finally(() => {
          // state.postLoading = false;
        });
    }

    function deSelectTag(data: any) {
      // console.log("DELETE>>>", data)
      // console.log("Before>>>", state.data.tags.options)
      // state.data.tags.options
      state.data.tags.options = state.data.tags.options.filter(
        (e: any) => e.name.toUpperCase() != data.toUpperCase()
      );
      // console.log("After>>>", state.data.tags.options)
      masterTagList();
      masterTagListForValidation(false);
    }
    // https://openbase.com/categories/js/best-vue-onboarding-tour-libraries
    // https://openbase.com/js/@salamander.be/vue-tour
    // https://openbase.com/js/v3-tour
    // https://pulsardev.github.io/vue-tour/
    watch(
      () => props.imageList,
      () => {
        state.data.imagesToUpload = props.imageList;
      }
    );
    onMounted(() => {
      state.data.imagesToUpload = props.imageList;
      state.data.imageBaseUrl = config.imageBaseUrl;
      state.data.imagesToUpload.forEach((item: any) => {
        item.isEdit = false;
      });
      if (state.data.imagesToUpload && state.data.imagesToUpload) {
        masterTagList();
      }
    });

    return {
      state,
      deSelectTag,
      disabledFileTagList,
      enableSelectedFileTag,
      readyImageListToUpload,
      addTag,
      masterTags,
      uploadImage,
      removeSelectedImage,
      masterTagListForValidation,
      renameUploadImage,
      renameUploadImageSave,
    };
  },
});
