
import { defineComponent, reactive, onMounted, computed, watch } from "vue";
import { useRoute ,useRouter } from "vue-router";
import EmptyData from "@/components/commonDesign/EmptyData.vue";
import { ethitransService } from "@/services/EthitransService";
import ErrorList from "@/components/commonDesign/ErrorList.vue";
import SuccessfulModalCustom from "@/components/modal/SuccessfulModalCustom.vue";
export default defineComponent({
  name: "ManageReferal",
  components: {
    SuccessfulModalCustom,
    EmptyData
  },
  setup() {
    const router = useRouter();
    const {
      params: { referralId },
    } = useRoute();
    const state = reactive({
      data: {
        verifyMessage: '' as any,
        successMessage: "",
        showSuccesCustomModal: false,
        title: "Share link",
        heading:"Share Link"
      },
      ui: {

        isLoading: {
          projectDetailsAPICalled: false,
        },
        isPostLoading: {
          sendInvitation: false,

        },
        errors: {
          hasError: false,
        },
      },
      invites: [] as any,
      userList:[] as any,
      inviteMember: "",
      errorList: [] as any,
      inputEmail: [] as any,
      isErrorStatus: false,
      error: {
        hasError: false,
        errorList: {
          createChannel: [] as any,
        },
      },
    });
    function addMember() {
      $("#inviteMember").modal("show");
    }

    function hideAddMember() {
      state.invites =[];
      $("#inviteMember").modal("hide");
    }

    function removeSelectedUser(item: any) {
      state.invites = state.invites.filter((e: any) => e != item);
    }

    function isValidEmail(email: any) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    }

    function changeInviteMember() {
      state.data.verifyMessage = "";
      if (isValidEmail(state.inviteMember)) {
        state.inputEmail = [];
        state.inputEmail.push(state.inviteMember);
      } else {
        state.data.verifyMessage = "please enter valid email";
        return
      }

      const payLoad = {
        emails: state.inputEmail
      };
      ethitransService
        .verifyReferalInvitation(payLoad)
        .then((res: any) => {
          if (res.data.data.new[0]) {
            state.data.verifyMessage = "";
            state.inviteMember = "";
            if (res.data.data.new[0].trim() !== "" && !state.invites.includes(res.data.data.new[0])) {
              state.invites.push(res.data.data.new[0])
              console.log(res.data.data.new[0]);
            }

          } else {
            state.inviteMember = "";
            state.data.verifyMessage = res.data.data.old[0] + " already exists in our system"
          }

        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          // state.ui.isPostLoading.verifyInvitation = false;
        });
    }

    function closeSuccessCustomModal() {
      state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
      $("#SuccessfulModalCustom").modal("hide");
    }


    function sendInviteMemberList() {
      ethitransService
        .sendReferalInvitationList(referralId)
        .then((res: any) => {
         state.userList = res.data.data
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          
          // state.ui.isPostLoading.sendInvitation = false;
        });
    }

    function sendInviteMember() {
      state.data.verifyMessage ='';
      if(state.invites.length==0){
        state.data.verifyMessage = "email Required";
        return
      }
      state.ui.isPostLoading.sendInvitation = true;
      const payLoad = {
        emails: state.invites
      };
      ethitransService
        .sendReferalInvitation(payLoad)
        .then((res: any) => {
          hideAddMember()
          state.data.showSuccesCustomModal = true;
          state.data.successMessage = "Invite sent sucessfully";
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          sendInviteMemberList();
          state.ui.isPostLoading.sendInvitation = false;
        });
    }

    function redirectBack() {
      router.push({
        name: "manage-referral-link",
      });
    }

    onMounted(() => {
      sendInviteMemberList();
    });

    return {
      state,
      addMember,
      hideAddMember,
      changeInviteMember,
      removeSelectedUser,
      isValidEmail,
      sendInviteMember,
      closeSuccessCustomModal,
      sendInviteMemberList,
      redirectBack
    };
  },
});
