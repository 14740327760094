
import { defineComponent, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";

// import { ethitransService } from "@/services/EthitransService";

export default defineComponent({
  name: "ToDoListBreadCrumb",
  props: {
    project: Object,
    breadCrumbEndPoint: String,
  },
  setup() {
    const router = useRouter();
    const {
      params: { projectId },
    } = useRoute();
    const state = reactive({
      data: {
        myDriveFileFolderLoad: false,
      },
      ui: {
        error: {
          hasError: false,
          errorList: {
            uploadAttachments: [] as any,
          },
        },
        isLoading: {
          getFolderList: false,
        },
        isPostLoading: {
          enableSelectedFileTag: false,
        },
      },
    });
    function redirectToProject(project: any) {
      router.push({
        name: "admin-project-details",
        params: { projectId: project.uuid },
      });
    }

    return {
      state,
      redirectToProject,
    };
  },
});
