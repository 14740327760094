
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "GoBack",
  props: {
    errors: Array,
    uuid: String,
    pathName: String,
    urlId: String,
  },
  setup(props) {
    const router = useRouter();
    function redirectBackTo() {
      const paramsName = props.urlId;

      let pushElement = {};
      if (props.pathName === "user-todo-list" || props.pathName === "smart-contract-list") {
        pushElement = {
          name: `${props.pathName}`,
          params: { projectId: props.uuid },
        };
      }
      if (props.pathName === "user-todo-details") {
        pushElement = {
          name: "user-todo-details",
          params: { phaseId: props.uuid },
        };
      }

      // console.log("CHECK", pushElement);
      // 8ef71adb-b31d-4bd9-b336-b2f9a5a3dbab
      // 8394343d-cc35-493a-aafb-9eae93220a47
      router.push(pushElement);
    }

    // console.log("CHECK ChannelCard props", props.errors);
    // console.log("CHECK props", props.hasError);
    return { redirectBackTo };
  },
});
