
import { defineComponent, reactive, onMounted, computed } from "vue";
import { ethitransService } from "@/services/EthitransService";
import { common } from "@/common/services/Common";
import { config } from "@/Config";
import { useRoute } from "vue-router";
import EmptyData from "@/components/commonDesign/EmptyData.vue";

// import FormView from "@/components/cards/FormView.vue";
export default defineComponent({
  name: "UserActivity",
  components: {
    EmptyData,
  },
  setup() {
    const {
      params: { userId },
    } = useRoute();
    const state = reactive({
      userLog: [] as any,
      user: "" as any,

      data: {
        imageBaseUrl: "",
        title: "User Log",
        heading: "No User Log found .",
        searchUser: "",
        paging: {
          clientDocumentList: {
            perPage: 10,
            totalDocs: 0,
            totalPages: 0,
            currentPage: 1,
          },
        },
      },
      ui: {
        isLoading: {
          getAccountFormList: false,
          clientDocumentList: false,
        },
        isPostLoading: {
          uploadClientDocument: false,
          uploadSharableLink: false,
        },
      },
      error: {
        hasError: false,
        erorList: {
          uploadClientDocument: [] as any,
        },
      },
    });

    function getLogDetails() {
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        user_id: userId,
      };
      ethitransService
        .userLog(payLoad)
        .then((res: any) => {
          console.log(
            "Project Details Response Check:>>> ",
            res.data.data.user_log.length
          );
          state.userLog = res.data.data.user_log ? res.data.data.user_log : [];
          state.user = res.data.data.user ? res.data.data.user : {};
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          //
        });
    }

    function exportUserLog(id: any) {
      // console.log("HELLO GROM ROLE LIST FROM PROJET DETAILS");
      // state.isLoading.getProjectRoleList = true;
      const payLoad = {
        id: id,
      };
      ethitransService
        .userLogExport(payLoad)
        .then((res: any) => {
          console.log("expord data", res.data);
          window.open(res.data);
          // state.userList = res.data.data ? res.data.data : [];
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          // state.isLoading.getProjectRoleList = false;
        });
    }

    onMounted(() => {
      getLogDetails();
    });
    return {
      state,
      getLogDetails,
      exportUserLog,
    };
  },
});
