
import { defineComponent, reactive, computed, onMounted, watch } from "vue";
import { ethitransService } from "@/services/EthitransService";

import TitleComponent from "@/components/formComponents/TitleComponent.vue";
import TextBoxComponent from "@/components/formComponents/TextBoxComponent.vue";
// import TextAreaComponent from "@/components/formComponents/TextAreaComponent.vue";
import RadioButtonComponent from "@/components/formComponents/RadioButtonComponent.vue";
import SelectOption from "@/components/formComponents/SelectOption.vue";
import CheckBox from "@/components/formComponents/CheckBox.vue";
import TextArea from "@/components/formComponents/TextArea.vue";
import ErrorList from "@/components/commonDesign/ErrorList.vue";
import { common } from "@/common/services/Common";
import { useRouter } from "vue-router";
// import Pagination from 'v-pagination-3';
import VPagination from "vue3-pagination";
import "vue3-pagination/dist/vue3-pagination.css";
import { useStore } from "vuex";
import introJs from "intro.js";

import UpgradeSubscriptionModal from '@/common/UpgradeSubscriptionModal.vue'


// import FormView from "@/components/cards/FormView.vue";
import { signOut } from "@/common/google";
// import AppTour from "@/components/modal/AppTour.vue";
export default defineComponent({
  name: "FormList",
  components: {
    UpgradeSubscriptionModal,
    // AppTour,
    ErrorList,
    TitleComponent,
    TextBoxComponent,
    // TextAreaComponent,
    RadioButtonComponent,
    SelectOption,
    CheckBox,
    TextArea,
    // 'pagination': Pagination,
    VPagination,
    // FormView,
    // Multiselect
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const state = reactive({
      userList: [] as any,
      data: {
        subscriptionDetails: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          appointment_numbers: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          archive_channel: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          channel_file_size: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          channel_message_history: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          channel_per_project: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          estimation_per_project: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          export_channel_pdf: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          files_number: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          folder_number: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          guests_per_project: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          import_option: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          invoice_payment: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          member_in_smart_contract: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          member_per_channel: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          members_per_appointment: 0,
          name: "Silver",
          // eslint-disable-next-line @typescript-eslint/camelcase
          pdf_number: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          phase_per_smart_contract: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          private_channel: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          project_number: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          sync_option: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          task_per_phase: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          template_number: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          total_channel_member: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          total_estimation: 0,
        } as any,
        formLoad: false,
        tagList: [] as any,
        selectedEdocForm: {} as any,
        templateResponse: {} as any,
        userList: [] as any,
        clientDocuments: [] as any,
        title: "No Forms",
        heading: "No forms created or shared yet.",
        additionalMessage: "",
        privacyList: [
          {
            name: "Private to this channel",
            code: "channel",
            value: "private to this channel",
          },
          {
            name: "Share within this project",
            code: "project",
            value: "share within this project",
          },
          { name: "Share to Public", code: "public", value: "share to public" },
        ],
        privacy: {
          name: "Private to this channel",
          value: "private to this channel",
          code: "channel",
        },
      },
      name: "" as any,
      email: "" as any,
      isShow: false,
      formId: "",
      selectedFormId: "",
      type: "",
      searchBy: "",
      filter: {
        type: "all",
        projectId: 0,
        channelId: 0,
        sortBy: -1,
        title: 1,
        selected: { name: "Newest First", id: -1 } as any,
        tag: "all" as any,
      },
      formData: {} as any,
      formList: {
        docs: [],
      } as any,
      invite: {
        users: [] as any,
      },
      existingMember: {} as any,
      item: {
        sendOption: {
          project: {} as any,
          channel: {} as any,
        },
        error: {
          erorListSendForm: [] as any,
        },
        hasError: false,
        selectedUserFormDetails: [] as any,
        searchUser: "",
        paging: {
          getAccountFormList: {
            perPage: 10,
            totalDocs: 0,
            totalPages: 0,
            currentPage: 1,
          },
        },
      },
      selectedElement: {
        element: {} as any,
        type: "",
        index: "",
        title: "",
        description: "",
      },
      userFormData: [] as any,
      projectList: [] as any,
      sortList: [
        {
          name: "Newest First",
          id: -1,
        },
        {
          name: "Oldest First",
          id: 1,
        },
        {
          name: "Form Name (A>Z)",
          id: 1,
        },
        {
          name: "Form Name (Z>A)",
          id: -1,
        },
      ],
      currentSendUser: false,
      selectedUser: {},
      activeUserIndex: 0,
      channelListForSendOption: [] as any,
      userListForSendOption: [] as any,
      channelList: [] as any,
      allChannelList: [] as any,
      newFormData: {
        title: "",
        description: "",
        elements: [] as any,
      },
      ui: {
        isActive: {
          upgradeSubscription: false,
        },
        isLoading: {
          packageDetailsByUser: false,
          userStatus: false,
          clientDocumentRetrieveWorkflow: false,
          getAccountFormList: false,
          clientDocumentList: false,
          accountFormResponseByEmailAndId: false,
        },
        postLoading: {
          getAccountFormList: false,
          getAccountFormRetrieveById: false,
          getprojectList: false,
          getChannelByProject: false,
          getChannelByProjectForSendOption: false,
          getChannelList: false,
        },
        isPostLoading: {
          createFormTemplate: false,
          formMessage: false,
          getUserListByProjectAndChannel: false,
          sendDigitizedForm: false,
          getAccountFormRetrieveById: false,
          selectedUserAccountFormResponse: false,
        },
        error: {
          errorsList: [] as any,
          hasError: false,
        }
      },
    });

    function formatDate(dateString: string) {
      return common.formatDateTimeWithTimeFormat(dateString);
    }

    function redirectToCreateForm() {
      // alert("Use Tempalte Modal here");
      if (state.formList && state.formList.docs.length === 0) {
        // alert("CHECK")
        router.push({
          name: "admin-channel-create-form",
        });
      } else {

        if (state.formList && state.formList.docs.length >= state.data.subscriptionDetails.template_number) {
          state.ui.isActive.upgradeSubscription = true;
          // alert("HI")

        } else {

          router.push({
            name: "admin-channel-create-form",
          });
        }
      }
    }
    function toggleTourGuide() {
      if (store.state.showTourGuide) {
        introJs()
          .setOptions({
            steps: [
              {
                title: "Welcome",
                // intro: "Welcome to Convorally! 👋 ",
                intro:
                  "Welcome to Convorally! 👋 <iframe width='426' height='240' src='https://www.youtube.com/embed/G8vkPCn2bLs' title='Slice Text in Photoshop' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>",
              },
              {
                title: "Filter Form",
                element: document.getElementById(
                  "filterFormList"
                ) as HTMLInputElement,
                intro: "Filter Form By Name, Project, Channel and Sort.",
              },
              {
                title: "Display Form",
                element: document.getElementById(
                  "displayFormBy"
                ) as HTMLInputElement,
                intro: "Display Form by All, Sent by me or Sent to me.",
              },
              {
                title: "Create Form",
                element: document.getElementById(
                  "createFormInFormList"
                ) as HTMLInputElement,
                intro: "Create Normal Form, Digitize Document.",
              },
              {
                title: "Form List",
                element: document.getElementById(
                  "formListFiltered"
                ) as HTMLInputElement,
                intro: "Form List here.",
              },

            ],
          })
          .start();
      }
    }
    watch(
      // () => watchTour,
      () => store.state.takAtour,
      (newvalue, oldvalue) => {
        if (newvalue === true) {
          console.log("check >>>", newvalue)
          toggleTourGuide();
        }
      }
    );
    // eslint-disable-next-line vue/return-in-computed-property
    const formPrivacyMessage = computed(() => {
      if (state.data.privacy.value === "private to this channel") {
        return "This form will remain private to this channel. Form will not be visible to other channels of this project.";
      } else if (
        state.data.privacy.value === "share within this project"
      ) {
        return " This form will be shared in this project so that other channels of this project can utilize this form, but will not be shared with other projects.";
      } else {
        return "This form will be shared to public.";
      }
    });
    const sentToUsers = computed(() => {
      if (state.formData.sentToUsers && state.formData.sentToUsers.length) {
        return state.formData.sentToUsers.filter((form: any) => {
          if (form.email) {
            return form.email
              .toLowerCase()
              .match(state.item.searchUser.toLowerCase());
          }
        });
      } else {
        return [];
      }
    });
    const templateList = computed(() => {
      if (state.formList.docs && state.formList.docs.length) {
        return state.formList.docs.filter((form: any) => {
          // if (form.title) {
          //   return form.title.toLowerCase().match(state.searchBy.toLowerCase());
          // }
          return form;
        });
      } else {
        return [];
      }
    });

    function getAccountFormList() {
      const projectIds = [] as any;

      state.projectList.forEach((item: any) => {
        console.log("CHECK PROJECT ID >> ", item);
        projectIds.push(item.uuid);
      });
      const channelIds = [] as any;
      state.allChannelList.forEach((data: any) => {
        console.log("ITEM Channel CHECK:>>>>>", data);
        channelIds.push(data.uuid);
      });
      // state.filter.selected
      console.log("SELECTED SORT BY : >>>>", state.filter.selected);
      if (
        state.filter.selected.name === "Newest First" ||
        state.filter.selected.name === "Oldest First"
      ) {
        state.filter.sortBy = state.filter.selected.id;
      } else {
        state.filter.title = state.filter.selected.id;
      }
      console.log("SORT : >>>>", state.filter.sortBy);
      console.log("TITLE : >>>>", state.filter.title);
      if (
        state.filter.selected.name === "Newest First" ||
        state.filter.selected.name === "Oldest First"
      ) {
        const payLoad = {
          search: {
            // eslint-disable-next-line @typescript-eslint/camelcase
            project_id: state.filter.projectId,
            // eslint-disable-next-line @typescript-eslint/camelcase
            channel_id: state.filter.channelId,
            // eslint-disable-next-line @typescript-eslint/camelcase
            project_ids: projectIds,
            // eslint-disable-next-line @typescript-eslint/camelcase
            channel_ids: channelIds,
            // email: state.email,
            // eslint-disable-next-line @typescript-eslint/camelcase
            form_name: state.searchBy,
          },
          filter: {
            type: state.filter.type,
            email: state.email,
          },
          tag: state.filter.tag,
          // eslint-disable-next-line @typescript-eslint/camelcase
          sort_by: {
            // state.filter.sortBy
            // "owner.created_by.name": state.name,
            // eslint-disable-next-line @typescript-eslint/camelcase
            created_date: state.filter.sortBy,
          },
          paging: {
            // eslint-disable-next-line @typescript-eslint/camelcase
            per_page: state.item.paging.getAccountFormList.perPage,
            // eslint-disable-next-line @typescript-eslint/camelcase
            current_page: state.item.paging.getAccountFormList.currentPage,
          },
        };
        // console.log("FINAL Payload", payLoad)
        state.ui.postLoading.getAccountFormList = true;
        ethitransService
          .accountFormListSearchList(payLoad)
          .then((res: any) => {
            // console.log("accountFormListSearchList Response WOWO: >>> ", res.data);
            state.formList = res.data ? res.data : {};
            state.data.tagList = res.data.filteredTags.docs;

            if (res.data.totalPages) {
              state.item.paging.getAccountFormList.totalPages =
                res.data.totalPages;
            }
            if (res.data.totalDocs) {
              state.item.paging.getAccountFormList.totalDocs =
                res.data.totalDocs;
            }
            // res.data.projects;
            // getChannelList(1);
            state.data.formLoad = true;

          })
          .catch((error: any) => {
            console.log(error);
          })
          .finally(() => {
            state.ui.postLoading.getAccountFormList = false;
          });
      } else {
        const payLoad = {
          search: {
            // eslint-disable-next-line @typescript-eslint/camelcase
            project_id: state.filter.projectId,
            // eslint-disable-next-line @typescript-eslint/camelcase
            channel_id: state.filter.channelId,
            // eslint-disable-next-line @typescript-eslint/camelcase
            project_ids: projectIds,
            // eslint-disable-next-line @typescript-eslint/camelcase
            channel_ids: channelIds,
            // email: state.email,
            // eslint-disable-next-line @typescript-eslint/camelcase
            form_name: state.searchBy,
          },
          filter: {
            type: state.filter.type,
            email: state.email,
          },
          // eslint-disable-next-line @typescript-eslint/camelcase
          sort_by: {
            // state.filter.sortBy
            // "owner.created_by.name": state.name,
            title: state.filter.title,
          },
          paging: {
            // eslint-disable-next-line @typescript-eslint/camelcase
            per_page: state.item.paging.getAccountFormList.perPage,
            // eslint-disable-next-line @typescript-eslint/camelcase
            current_page: state.item.paging.getAccountFormList.currentPage,
          },
        };
        // console.log("FINAL Payload", payLoad)
        state.ui.postLoading.getAccountFormList = true;
        ethitransService
          .accountFormListSearchList(payLoad)
          .then((res: any) => {
            state.formList = res.data ? res.data : {};
            // res.data.projects;
            // alert();
            // console.log("PAGINATION Response : >>> ", res);
            // state.item.paging.getAccountFormList.totalDocs = res.data.totalDocs
            // getChannelList(1);
            state.data.formLoad = true;
          })
          .catch((error: any) => {
            console.log(error);
          })
          .finally(() => {
            state.ui.postLoading.getAccountFormList = false;
          });
      }
    }
    function nextPage(event: any) {
      // console.log("NEXT PAGE >>>", event)

      state.item.paging.getAccountFormList.currentPage = event;
      getAccountFormList();
    }
    function getChannelList() {
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        // project_id: projectId,
      };
      state.ui.postLoading.getChannelList = true;
      ethitransService
        .getChannelList(payLoad)
        .then((res: any) => {
          if (res.data.data && res.data.data.length) {
            // res.data.data.forEach((item: any) => {
            console.log("ChannelList Check:>>> ", res.data.data);

            state.allChannelList = res.data.data;
            // })
          }
          // state.selectedFormForEdit = res.data ? res.data : {};
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.postLoading.getChannelList = false;
          getAccountFormList();
        });
    }
    function getprojectList() {
      console.log("getprojectList WOWO:>>>");
      const payLoad = {};
      state.ui.postLoading.getprojectList = true;
      ethitransService
        .getprojectList(payLoad)
        .then((res: any) => {
          // console.log("getprojectList Check:>>> ", res.data.data.projects);
          state.projectList = res.data.data.projects
            ? res.data.data.projects
            : [];
          // state.selectedFormForEdit = res.data ? res.data : {};
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.postLoading.getprojectList = false;
        });
    }
    function getAccountFormRetrieveByIdForSend(formId: any) {
      // console.log("Get Account form Response:>>>", formId);
      state.ui.postLoading.getAccountFormRetrieveById = true;
      ethitransService
        .accountFormRetrieveById(formId)
        .then((res: any) => {
          console.log(
            "getAccountFormRetrieveById Response Check:>>> ",
            res.data.elements
          );
          state.newFormData = res.data ? res.data : {};
          // state.selectedFormForEdit = res.data ? res.data : {};
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.postLoading.getAccountFormRetrieveById = false;
        });
    }
    function getChannelByProject() {
      // console.log("Get Account form Response:>>>", formId);
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: state.filter.projectId,
      };
      state.ui.postLoading.getChannelByProject = true;
      ethitransService
        .getChannelListByProject(payLoad)
        .then((res: any) => {
          console.log("getChannelByProject Response Check:>>> ", res.data.data);
          state.channelList = res.data.data ? res.data.data : [];
          // state.selectedFormForEdit = res.data ? res.data : {};
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.postLoading.getChannelByProject = false;
        });
    }
    function removeSelectedMember(item: any) {
      // console.log("check the sELCTIOn", item);
      state.invite.users = state.invite.users.filter((e: any) => e != item);
      state.data.userList.push(item);
      // console.log("AFTER DELECTION", state.invite.users);
    }
    function changeExistingMemberInForm() {
      if (state.invite.users.indexOf(state.existingMember) === -1) {
        state.invite.users.push(state.existingMember);
        // console.log(state.invite.users);
      }
      state.data.userList = state.data.userList.filter(
        (e: any) => e != state.existingMember
      );
      state.existingMember = {};
    }
    function getUserListByProjectAndChannel() {
      state.item.error.erorListSendForm = []
      state.item.hasError = false;
      // console.log("USERLIST check for channel ID", channelId);
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: state.item.sendOption.project.uuid,
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: state.item.sendOption.channel.uuid,
      };
      // console.log("PAYLOAD CHECK ", payLoad);
      state.ui.isPostLoading.getUserListByProjectAndChannel = true;
      ethitransService
        .projectMemberListByChannel(payLoad)
        .then((res: any) => {
          // console.log("UserList Check:>>> ", res.data.data);
          state.userListForSendOption = res.data.data ? res.data.data : [];
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.item.error.erorListSendForm)
            .then((res: any) => {
              state.item.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.item.error.erorListSendForm.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.getUserListByProjectAndChannel = false;
        });
    }
    function getChannelByProjectForSendOption() {
      // console.log("Get Account form Response:>>>", formId);
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: state.item.sendOption.project.uuid,
      };
      state.ui.postLoading.getChannelByProjectForSendOption = true;
      ethitransService
        .getChannelListByProject(payLoad)
        .then((res: any) => {
          console.log("getChannelByProject Response Check:>>> ", res.data.data);
          state.channelListForSendOption = res.data.data ? res.data.data : [];
          // state.selectedFormForEdit = res.data ? res.data : {};
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.postLoading.getChannelByProjectForSendOption = false;
        });
    }
    function hideTemplateModal() {
      $("#formPreviewModal").modal("hide");
    }
    function clientDocumentRetrieveWorkflow(
      clientDocumentId: any,
      selectedChannelId: any
    ) {
      // alert("HHHHCHECK")
      if (!clientDocumentId || !selectedChannelId) return false;
      const payLoad = {
        id: clientDocumentId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        account_form_id: selectedChannelId,
      };
      state.ui.isLoading.clientDocumentRetrieveWorkflow = true;
      ethitransService
        .clientDocumentRetrieveWorkflowLink(payLoad)
        .then((res: any) => {
          // console.log("HELLOOO");
          // console.log("clientDocumentRetrieveWorkflow :>>> ", res.data.data.docId);
          // // accountFormRetrieve(res.data.data.docId);
          // state.data.clientDocumentRetrieveWorkflowLink = res.data.data
          //   ? res.data.data
          //   : {};

          window.open(res.data.data.galleryThumbUrl, "_blank");
          // $('.nav-tabs a[href="#profile"]').tab('show')
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isLoading.clientDocumentRetrieveWorkflow = false;
        });
    }
    function clientDocumentList(edocId: any, id: any) {
      const payLoad = {
        type: "ready",
      };
      state.ui.isLoading.clientDocumentList = true;
      ethitransService
        .clientDocumentList(payLoad)
        .then((res: any) => {
          // console.log("clientDocumentList :>>> ", res.data.data);
          if (res.data.data && res.data.data.length) {
            res.data.data.forEach((item: any) => {
              item.convertedFileSize = common.formatBytes(
                parseInt(item.file_size)
              );
            });
            state.data.clientDocuments = res.data.data;
          } else {
            state.data.clientDocuments = [];
          }
          let uuId = ""
          state.data.clientDocuments.filter((data: any) => {
            if (edocId === data.edoc_id) {
              // console.log("CHECK UUID >>> ",data.uuid )
              uuId = data.uuid
            }
          })
          console.log("CHECK UUID >>>", uuId)
          // alert()
          clientDocumentRetrieveWorkflow(uuId, id)
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isLoading.clientDocumentList = false;
        });
    }
    function selectForm(form: any) {
      console.log("selected FORM >>>> ", form)
      // state.data.selectedFormData = {};
      // const formData = form.form_data;
      state.newFormData = form;
      // console.log("Selected Form:>>", formData);
      if (form.elements && form.elements.length === 0) {
        console.log("EDOC")
        clientDocumentList(form.edoc_id, form._id)
      } else {
        $("#formPreviewModal").modal("show");

        getAccountFormRetrieveByIdForSend(form._id);
      }
    }

    function userFormClick() {
      state.userFormData = state.formData.elements;
      console.log("DATA CHECK:>>", state.userFormData);
      $("#responseModal").modal("show");
    }
    function getAccountFormResponseByEmailAndId(user: any, index: any) {
      // console.log('API CALL');
      state.selectedUser = user;
      // console.log("Index", state.activeUserIndex);
      state.activeUserIndex = index;
      const email = state.email;

      // console.log("CHECK the Form ID", props.formId);
      console.log("CHECK Email Id", email);
      console.log("FORM Id", state.selectedFormId);
      state.ui.isLoading.accountFormResponseByEmailAndId = true;
      ethitransService
        .accountFormResponseByEmailAndId(email, state.selectedFormId)
        .then((res: any) => {
          console.log(
            "getAccountFormResponseByEmailAndId Response Check:>>> ",
            res.data
          );
          state.item.selectedUserFormDetails = res.data ? res.data : [];
          state.currentSendUser = true;
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isLoading.accountFormResponseByEmailAndId = false;
        });
    }
    function selectedUserAccountFormResponse() {
      const dt = new Date();
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        account_form_id: state.formData._id,
        // eslint-disable-next-line @typescript-eslint/camelcase
        filled_by: {
          name: state.name,
          email: state.email,
        },
        // eslint-disable-next-line @typescript-eslint/camelcase
        created_date:
          dt.getFullYear() + "-" + (dt.getMonth() + 1) + "-" + dt.getDate(),
        elements: state.userFormData,
      };
      console.log("selectedUserAccountFormResponse PAYLOAD CHECK ", payLoad);
      state.ui.isPostLoading.selectedUserAccountFormResponse = true;
      ethitransService
        .accountformResponseSave(payLoad)
        .then((res: any) => {
          console.log(
            "selectedUserAccountFormResponse Response Check:>>> ",
            res.data
          );
          // state.ui.template.response = res.data;
          $("#responseModal").modal("hide");
          getAccountFormResponseByEmailAndId(
            state.selectedUser,
            state.activeUserIndex
          );
          // $("#formview").modal("hide");
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.selectedUserAccountFormResponse = false;
        });
    }
    function showFormView(form: any) {
      $("#formview").modal("show");
      state.selectedFormId = form._id;
      state.ui.isPostLoading.getAccountFormRetrieveById = true;
      ethitransService
        .accountFormRetrieveById(form._id)
        .then((res: any) => {
          state.formData = res.data ? res.data : {};
          console.log("DATA CHECK", state.formData);
          if (state.formData.sentToUsers && state.formData.sentToUsers.length) {
            getAccountFormResponseByEmailAndId(
              state.formData.sentToUsers[state.activeUserIndex],
              0
            );
          }
          // state.formData.sentToUsers
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.getAccountFormRetrieveById = false;
        });
    }
    function hideFormView() {
      $("#formview").modal("hide");
    }
    function showNewElementEdit(data: any, type: any) {
      const formIndex = common.findIndex(state.newFormData, data);
      console.log("Selected Form Index", formIndex);
      state.selectedElement.element = data;
      state.selectedElement.type = type;
      state.selectedElement.index = common.findIndex(state.newFormData, data);
      state.selectedElement.title = data.title;
      $("#editSelectedElementOfCreateNewForm").modal("show");
    }
    function hideEditCreateNewForm() {
      $("#editSelectedElementOfCreateNewForm").modal("hide");
    }
    function showNewTitleInCreateFormEdit(data: any) {
      // editSelectedElementOfCreateNewForm
      console.log("Data Selected >>", data);
      state.selectedElement.type = data;
      // console.log("Title Data Check: >>", state.selectedElement.type);
      // console.log("edit Title:>>", state.selectedFormForEdit.title);
      // console.log("edit Description:>>", state.selectedFormForEdit.description);
      state.selectedElement.title = state.newFormData.title;
      state.selectedElement.description = state.newFormData.description;
      $("#editSelectedElementOfCreateNewForm").modal("show");
    }
    function editSelectedForm(form: any) {
      router.push({
        name: "admin-channel-create-form",
        query: { formId: form._id, mode: "edit" },
      });
      // $(".modal-backdrop").remove();
    }
    function sendSelectedForm(form: any) {
      state.item.sendOption.project = {}
      state.item.sendOption.channel = {}
      state.existingMember = {}
      state.invite.users = []
      state.data.additionalMessage = ''
      if (form.elements && !form.elements.length) {
        console.log("<< EDOC FORM >>");
        state.data.selectedEdocForm = form
        $("#sendoption").modal("show");
      } else {
        router.push({
          name: "admin-channel-create-form",
          query: { formId: form._id, mode: "send" },
        });
      }
      // $(".modal-backdrop").remove();
    }
    // function getAccountFormList() {
    //   state.ui.isLoading.getAccountFormList = true;
    //   ethitransService
    //     .accountFormList()
    //     .then((res: any) => {
    //       console.log("UserList Response Check:>>> ", res.data);
    //       // state.formList = res.data ? res.data : [];
    //     })
    //     .catch((error: any) => {
    //       console.log(error);
    //     })
    //     .finally(() => {
    //       state.ui.isLoading.getAccountFormList = false;
    //     });
    // }
    function searchFilterBy() {
      // console.log("Hello");
      // getAccountFormList();
    }
    function changeExistingMemberInSendOption() {
      if (state.invite.users.indexOf(state.existingMember) === -1) {
        state.invite.users.push(state.existingMember);
        // console.log(state.invite.users);
      }
      state.userList = state.userList.filter(
        (e: any) => e != state.existingMember
      );
      state.existingMember = {};
    }
    function validateSendDigitizedForm() {
      // console.log("Validate Send Digitize Form");
      state.item.error.erorListSendForm = [];
      state.item.hasError = false;

      // console.log("EMAIL CHECK", state.invite.users);

      if (state.invite.users && !state.invite.users.length) {
        state.item.error.erorListSendForm.push("Atleast 1 Email is required.");
      }

      if (
        !state.item.error.erorListSendForm &&
        !state.item.error.erorListSendForm.length
      ) {
        state.item.hasError = false;
      } else if (state.item.error.erorListSendForm.length != 0) {
        state.item.hasError = true;
      }
    }
    function channelCommunicationForm(id: any) {
      state.item.error.erorListSendForm = []
      state.item.hasError = false;
      const data: any = state.data.templateResponse;
      console.log("CHECK DATA >>>>>>>>>>", data);
      let payLoad: any = {};
      const usersArray = [] as any;
      if (state.data.selectedEdocForm.edoc_id) {
        state.invite.users.forEach((item: any) => {
          usersArray.push(item);
        });
        payLoad = {
          // eslint-disable-next-line @typescript-eslint/camelcase
          communication_id: id,
          type: "client_document",
          // eslint-disable-next-line @typescript-eslint/camelcase
          form_id: data._id,
          // eslint-disable-next-line @typescript-eslint/camelcase
          client_document_id: state.data.selectedEdocForm.form_id,
          // eslint-disable-next-line @typescript-eslint/camelcase
          client_document_name: state.data.selectedEdocForm.title,
          // eslint-disable-next-line @typescript-eslint/camelcase
          sent_to_users: usersArray,
        };
      }
      // state.owner

      console.log("<< ChannelCommunicationForm CHECK >>", payLoad);
      state.ui.isPostLoading.createFormTemplate = true;
      ethitransService
        .channelCommunicationFormData(payLoad)
        .then((res: any) => {
          // console.log("ChannelCommunicationForm Response Check:>>> ", res.data);
          state.invite.users = []
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.item.error.erorListSendForm)
            .then((res: any) => {
              state.item.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.item.error.erorListSendForm.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.createFormTemplate = false;
        });
    }
    function formMessage(msg: string) {
      state.item.error.erorListSendForm = []
      state.item.hasError = false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: state.item.sendOption.channel.uuid,
        message: msg,
        type: "form",
      };
      state.ui.isPostLoading.formMessage = true;
      ethitransService
        .createCommunication(payLoad)
        .then((res: any) => {
          // console.log(
          //   "<<<Create Communication Response Check:>>> ",
          //   res.data.data
          // );
          channelCommunicationForm(res.data.data.communication.id);
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.item.error.erorListSendForm)
            .then((res: any) => {
              state.item.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.item.error.erorListSendForm.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.formMessage = false;
        });
    }
    function sendDigitizedForm() {
      state.item.error.erorListSendForm = []
      state.item.hasError = false;
      validateSendDigitizedForm();
      // alert("HELLO FOR SEND DIGITAL FORM");
      if (
        state.ui.isPostLoading.sendDigitizedForm ||
        state.item.error.erorListSendForm.length != 0 ||
        state.item.hasError
      )
        return false;
      const usersArray = [] as any;
      state.invite.users.forEach((item: any) => {
        usersArray.push(item);
      });

      const payLoad = {
        title: state.data.selectedEdocForm.title,
        description: '',
        // eslint-disable-next-line @typescript-eslint/camelcase
        edoc_id: state.data.selectedEdocForm.edoc_id,
        // message: state.data.additionalMessage,
        elements: state.formData.elements,
        sentToUsers: usersArray,
        privacy: {
          code: state.data.privacy.code,
          name: state.data.privacy.value,
        },
        owner: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          created_by: {
            // name: state.owner.created_by.full_name,
            name: localStorage.getItem("current_user_details"),
            email: localStorage.getItem("current_user_email"),
            // email: state.owner.created_by.email,
          },
          // eslint-disable-next-line @typescript-eslint/camelcase
          project_id: state.item.sendOption.project.uuid,
          // eslint-disable-next-line @typescript-eslint/camelcase
          channel_id: state.item.sendOption.channel.uuid,
          project: {
            //added
            id: state.item.sendOption.project.id,
            uuid: state.item.sendOption.project.uuid,
            name: state.item.sendOption.project.name,
            description: state.item.sendOption.project.description,
          },
          channel: {
            //added
            id: state.item.sendOption.channel.id,
            uuid: state.item.sendOption.channel.uuid,
            name: state.item.sendOption.channel.name,
            description: state.item.sendOption.channel.description,
          },
        },
      };
      // console.log("CHECK THE Request", payLoad);
      state.ui.isPostLoading.sendDigitizedForm = true;
      ethitransService
        .accountformSave(payLoad)
        .then((res: any) => {
          // console.log("UserList Response Check:>>> ", res.data);
          state.data.templateResponse = res.data;
          if (
            state.data.additionalMessage ||
            (usersArray && usersArray.length)
          ) {
            // check this
            formMessage(state.data.additionalMessage);
            $("#sendoption").modal("hide");
          }
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.item.error.erorListSendForm)
            .then((res: any) => {
              state.item.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.item.error.erorListSendForm.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.sendDigitizedForm = false;
        });
    }
    function sendOptionBack() {
      $("#sendoption").modal("hide");
    }
    function userStatus() {
      state.ui.error.errorsList = [];
      state.ui.error.hasError = false;
      const payLoad = {
        email: localStorage.getItem("current_user_email"),
      };
      state.ui.isLoading.userStatus = true;
      ethitransService
        .userStatus(payLoad)
        .then((res: any) => {
          if (res.data.data.online_status === "offline") {
            console.log("userStatus Check:>>> ", res.data.data.online_status);
            router.push({
              name: "admin-login",
            });
            signOut();
          } else {
            getprojectList();
            getAccountFormList();
            //  toggleTourGuide();
          }
        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.ui.error.errorsList)
            .then((res: any) => {
              state.ui.error.hasError = true;
              // console.log("CHECK for Error >>>", res)
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorsList.push(value[0]);
                }
              }
              // if (res) {
              //   if (res.error) {
              //     console.log("CHECK for Error >>>", res.error[0])
              //     // state.errorList.push(res.email[0]);
              //     // state.errorList = [...state.errorList]
              //   }
              // }
            });
        })
        .finally(() => {
          state.ui.isLoading.userStatus = true;
        });
    }
    function packageDetailsByUser() {
      state.ui.error.errorsList = [];
      state.ui.error.hasError = false;
      state.ui.isLoading.packageDetailsByUser = true;
      ethitransService
        .packageDetailsByUser()
        .then((res: any) => {
          console.log("Check response >>> ", res.data.data.package_features)
          state.data.subscriptionDetails = res.data.data.package_features;
          state.ui.isLoading.packageDetailsByUser = false;

        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.ui.error.errorsList)
            .then((res: any) => {
              state.ui.error.hasError = true;
              // console.log("CHECK for Error >>>", res)
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorsList.push(value[0]);
                }
              }

            });
        })
    }

    function serarchTag(tag: any) {
      state.filter.tag = tag;
      getAccountFormList();

    }
    function hideUpdateSubscriptionModal(){
      // alert("Hello")
      state.ui.isActive.upgradeSubscription = !state.ui.isActive.upgradeSubscription;
    }
    onMounted(() => {
      // console.log("Full Name",localStorage.getItem('current_user_details'))
      // console.log("Email",localStorage.getItem('current_user_email'))
      userStatus();
      packageDetailsByUser();
      // getChannelList();
      state.name = localStorage.getItem("current_user_details");
      state.email = localStorage.getItem("current_user_email");

    });
    return {
      state,
      hideUpdateSubscriptionModal,
      redirectToCreateForm,
      packageDetailsByUser,
      toggleTourGuide,
      userStatus,
      formPrivacyMessage,
      clientDocumentList,
      clientDocumentRetrieveWorkflow,
      channelCommunicationForm,
      changeExistingMemberInSendOption,
      formMessage,
      sendOptionBack,
      removeSelectedMember,
      changeExistingMemberInForm,
      getChannelByProjectForSendOption,
      getUserListByProjectAndChannel,
      sendDigitizedForm,
      validateSendDigitizedForm,
      editSelectedForm,
      sendSelectedForm,
      userFormClick,
      selectedUserAccountFormResponse,
      getAccountFormResponseByEmailAndId,
      sentToUsers,
      showFormView,
      hideFormView,
      getChannelList,
      getChannelByProject,
      selectForm,
      getprojectList,
      showNewTitleInCreateFormEdit,
      hideEditCreateNewForm,
      showNewElementEdit,
      hideTemplateModal,
      getAccountFormRetrieveByIdForSend,
      templateList,
      getAccountFormList,
      searchFilterBy,
      nextPage,
      serarchTag,
      formatDate
    };
  },
});
