
import { defineComponent, reactive } from "vue";
import { useRouter, useRoute } from "vue-router";

import ShareModal from "@/components/modal/ShareModal.vue";
import RenameDocumentModal from "@/components/modal/RenameDocumentModal.vue";
import DeleteModal from "@/components/modal/DeleteModal.vue";
// import DeleteSelectedModal from "@/components/modal/DeleteSelectedModal.vue";
import DetailModal from "@/components/modal/DetailModal.vue";
import DetailviewModal from "@/components/modal/DetailviewModal.vue";
import MyDriveFileTags from "@/components/modal/MyDriveFileTags.vue";
// import TagsCard from "@/components/cards/TagsCard.vue";
import { ethitransService } from "@/services/EthitransService";
import { common } from "@/common/services/Common";
import { config } from "@/Config";

export default defineComponent({
  name: "MyDriveFile",
  props: {
    files: {
      type: Array,
      required: true,
    },
  },
  components: {
    RenameDocumentModal,
    // DeleteSelectedModal,
    DeleteModal,
    DetailModal,
    DetailviewModal,
    MyDriveFileTags,
    ShareModal,
    // TagsCard,
  },
  setup(props, { emit }) {
    const routerQuery = useRoute();
    const router = useRouter();
    const state = reactive({
      data: {
        folderRename: "" as any,
        folderId: "" as any,
        selectedFolder: {} as any,
        imageBaseUrl: "",
        showDeleteSelectedModal: false,
        showAddTagModal: false,
        showShareModal: false,
        showRenameFileModal: false,
        showDetailViewModal: false,
        showDetailModal: false,
        showDeleteModal: false,
        pageNumber: "" as any,
        selectedFiles: [] as any,
        showSuccesModal: false,
        folderList: {
          breadcrumbs: [] as any,
          files: [] as any,
          folders: [] as any,
        },
      },
      ui: {
        error: {
          hasError: false,
          errorList: {
            renameSelectedFolder: [] as any,
            downloadFile: [] as any,
          },
        },
        isLoading: {
          projectListActivities: false,
        },
        isPostLoading: {
          closeChannel: false,
          downloadFile: false,
          renameSelectedFolder: false,
        },
      },
    });
    function formatDate(dateString: string){
      return common.formatDateTimeWithTimeFormat(dateString);
    }
    function redirectToSelectedFolder(folder: any) {
      console.log("CHECK THE FOLDER  INPUT>>> ", folder);
      console.log("CHECK THE FOLDER  INPUT>>> ", folder.folder_uuid);
      if (!folder.folder_uuid) return false;
      router.push({
        name: "admin-document-details",
        query: { folderId: folder.folder_uuid },
      });
      console.log("Selected Folder >>> ", folder);
    }
    function redirectToDirectory(data: any) {
      if (data.breadcrumb && !data.breadcrumb.length) return false;
      // folder_uuid
      if (data.breadcrumb.length === 0) {
        redirectToSelectedFolder(data.breadcrumb[0]);
      } else {
        redirectToSelectedFolder(data.breadcrumb[data.breadcrumb.length - 1]);
      }
      console.log("CHECK FILE  length>>>", data.breadcrumb.length);
      console.log("CHECK FILE >>>", data.breadcrumb);
    }
    function showAddTag(data: any) {
      state.data.showAddTagModal = !state.data.showAddTagModal;
      // console.log("Check for Tag", data);
      state.data.selectedFolder = data;
    }
    function selectFile(item: object) {
      emit("selectedFile", item);
    }
    function showShareModal(file: any) {
      console.log("selected>>", file);
      // selectFile(file);
      file.isChecked = true;
      state.data.selectedFolder = file;
      state.data.selectedFiles.push(file);
      state.data.showShareModal = !state.data.showShareModal;
    }
    function openShareModal() {
      // alert("Hello")
      state.data.showShareModal = !state.data.showShareModal;
    }
    function closeDetailViewModal(item: any) {
      state.data.showDetailViewModal = !state.data.showDetailViewModal;
    }
    function showDetailViewModal(file: object) {
      state.data.selectedFolder = file;
      state.data.showDetailViewModal = !state.data.showDetailViewModal;
    }
    function closeDetailModal() {
      state.data.showDetailModal = !state.data.showDetailModal;
    }
    function closeMyDriveFileTags(data: any) {
      console.log("Tags Returned >>> ", data);
      emit("tagUpdate", data);
      state.data.showAddTagModal = !state.data.showAddTagModal;
      // state.data.selectedFolder = data;
      // emit("updateTags", data)
      // file_tag
    }
    function showDetailModal(data: any) {
      // console.log("Detail Modal");
      state.data.showDetailModal = !state.data.showDetailModal;
      state.data.selectedFolder = data;
    }

    function showSelectedFile(data: any) {
      // console.log("Detail Modal");
      state.data.showDetailModal = !state.data.showDetailModal;
      state.data.selectedFolder = data;
    }

    function closeShareModal(data: any) {
      state.data.showShareModal = !state.data.showShareModal;
      state.data.selectedFiles.forEach((item: any) => {
        item.isChecked = false;
      });
      state.data.selectedFiles = [];
      //show successModal
      if (data.uuid) {
        state.data.showSuccesModal = !state.data.showSuccesModal;
      }
      // data.isChecked = false;
      // state.data.selectedFiles = [];
    }

    function closeRenameModal(item: any) {
      state.data.showRenameFileModal = !state.data.showRenameFileModal;
      // console.log("REnamed Data >>>", item);
      emit("rename", item);
    }
    function showRenameModal(data: any) {
      // console.log("FILE DETAILS >>>", data);
      state.data.selectedFolder = JSON.parse(JSON.stringify(data));
      state.data.selectedFolder.filename =
        state.data.selectedFolder.filename.replaceAll(`.${data.filetype}`, "");
      state.data.showRenameFileModal = !state.data.showRenameFileModal;
    }

    function goToChatChannel(data: any) {
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: data.channel_uuid ? data.channel_uuid : "",
        // eslint-disable-next-line @typescript-eslint/camelcase
        communication_id: data.communication_id ? data.communication_id : "",
      };
      // state.postLoading = true;
      ethitransService
        .communicationPage(payLoad)
        .then((res: any) => {
          state.data.pageNumber = res.data.data;
        })
        .catch((error: any) => {
          // console.log(error);
        })
        .finally(() => {
          // state.postLoading = false;
          if(data.channel_communication_response_id){
             router.push({
            name: "admin-channel",
            params: { channelId: data.channel_uuid },
            // eslint-disable-next-line @typescript-eslint/camelcase
            query: {
              communicationId: data.communication_id,
              page: state.data.pageNumber,
              reply:data.channel_communication_response_id,
            },
          });
          }else{
          router.push({
            name: "admin-channel",
            params: { channelId: data.channel_uuid },
            // eslint-disable-next-line @typescript-eslint/camelcase
            query: {
              communicationId: data.communication_id,
              page: state.data.pageNumber,
            },
          });
          }
         
        });
    }

    function closeDeleteModal(data: any) {
      // console.log("EMIT DATA OF FOLDER CHECK >>> ", data);
      if (data === true) {
        emit("deleteFile", state.data.selectedFolder);
      }
      state.data.showDeleteModal = !state.data.showDeleteModal;
    }
    // function closeDeleteMultipleModal(data: any) {
    //   // console.log("EMIT DATA OF FOLDER CHECK >>> ", data);
    //   // if (data === true) {
    //   //   emit("deleteFile", state.data.selectedFolder);
    //   // }
    //   state.data.showDeleteSelectedModal = !state.data.showDeleteSelectedModal;
    // }
    function showDeleteFolderModal(data: object) {
      // console.log("Folder  Check >>> ", data);
      state.data.selectedFolder = data;
      state.data.showDeleteModal = !state.data.showDeleteModal;
    }
    function showPreview(file: any) {
      console.log("CHeck File Data >>", file);
      console.log("CHeck File Data >>", file.file_url);
      window.open(file.file_url, "_blank");
    }
    function downloadFile(data: any) {
      state.ui.error.errorList.downloadFile = [];
      state.ui.error.hasError = false;
      if (
        state.ui.error.errorList.renameSelectedFolder.length != 0 ||
        state.ui.error.hasError ||
        state.ui.isPostLoading.downloadFile
      )
        return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        file_id: data.uuid,
      };
      state.ui.isPostLoading.downloadFile = true;
      ethitransService
        .fileDownload(payLoad)
        .then((res: any) => {
          console.log("Project List Response Check:>>> ", res.data.data);
          window.open(res.data.data, "_blank");
          // closeRenameModal()
          // resetTheName(res.data.data)
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.downloadFile)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.downloadFile.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.downloadFile = false;
        });
    }

    return {
      state,
      // closeDeleteMultipleModal,
      redirectToDirectory,
      redirectToSelectedFolder,
      showAddTag,
      showRenameModal,
      closeRenameModal,
      closeDeleteModal,
      showDeleteFolderModal,
      downloadFile,
      showDetailModal,
      closeMyDriveFileTags,
      closeDetailModal,
      showDetailViewModal,
      closeDetailViewModal,
      selectFile,
      showShareModal,
      showPreview,
      goToChatChannel,
      showSelectedFile,
      closeShareModal,
      openShareModal,
      formatDate
    };
  },
});
