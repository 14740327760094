
import {
    defineComponent,
    onMounted,
    reactive
} from "vue";
import {
    ethitransService
} from "@/services/EthitransService";
import {
    common
} from "@/common/services/Common";
import {
    useRoute,
    useRouter
} from 'vue-router';

import SuccessfulModalCustom from "@/components/modal/SuccessfulModalCustom.vue";
import ErrorList from "@/components/commonDesign/ErrorList.vue";

// interface LabelType {
//   id: number
//   name: string
//   key: string
//   created_at: string
//   updated_at: string
// }

export default defineComponent({
    name: "CreateSubscription",
    components: {
        SuccessfulModalCustom,
        ErrorList,

    },
    setup() {
        const route = useRoute();
        const router = useRouter();
        const state = reactive({
            data: {
                subscriptionLabelList: [] as any,
                editMode: false,
                successFulMessage: 'Subscription Created',
                // subscriptionLabelList: <LabelType[]>([]),
                subscription: {
                    packageName: "",
                    project: {
                        projectNumber: 0,
                        channelPerProject: 0,
                        memberPerChannel: 0,
                        guestMember: 0,
                        privateChannel: 0,
                        totalChannelMember: 0,
                        channelMessageHistory: 0,
                        ChannelFileSize: 0,
                        exportChannelPdf: 0,
                        archiveChannel: 0,
                        estimationPerProject: 0,
                        totalEstimation: 0,
                        phasePerSmartContract: 0,
                        taskPerPhase: 0,
                        memberinSmartContract: 0,
                        invoicePayment: 0,
                    },
                    template: {
                        templateNumber: 0,
                        pdfNumber: 0,
                        folderNumber: 0,
                        filesNumber: 0,
                    },
                    mycalender: {
                        appointmentNumbers: 0,
                        membersPerAppointment: 0,
                        amount: 0,
                        importOption: "google",
                        syncOption: 0,
                        timeDuration: 0,

                    }
                },
                showSuccesCustomModal: false,
                SuccessFulMessage: "",
                notificationList: [] as any,
            },

            ui: {
                error: {
                    hasError: false,
                    errorList: {
                        delete: [] as any,
                        subscription: [] as any,
                    },
                },
                isLoading: {
                    getFolderList: false,
                },
                isPostLoading: {
                    deleteSelected: false,
                    createSubscription: false,
                },
            },
        });

        const createValidationField = (message: string) => ({
            required: false,
            message,
        });

        const validationConfigs = [{
                key: "packageName",
                message: "This field is required."
            },
            {
                key: "projectNumber",
                message: "This field is required."
            },
            {
                key: "channelPerProject",
                message: "This field is required."
            },
            {
                key: "memberPerChannel",
                message: "This field is required."
            },
            {
                key: "guestMember",
                message: "This field is required."
            },
            {
                key: "privateChannel",
                message: "This field is required."
            },
            {
                key: "totalChannelMember",
                message: "This field is required."
            },
            {
                key: "ChannelFileSize",
                message: "This field is required."
            },
            {
                key: "estimationPerProject",
                message: "This field is required."
            },
            {
                key: "phasePerSmartContract",
                message: "This field is required."
            },
            {
                key: "memberinSmartContract",
                message: "This field is required."
            },
            {
                key: "totalEstimation",
                message: "This field is required."
            },
            {
                key: "taskPerPhase",
                message: "This field is required."
            },
            {
                key: "channelMessageHistory",
                message: "This field is required."
            },
            {
                key: "templateNumber",
                message: "This field is required."
            },
            {
                key: "pdfNumber",
                message: "This field is required."
            },
            {
                key: "folderNumber",
                message: "This field is required."
            },
            {
                key: "filesNumber",
                message: "This field is required."
            },
            {
                key: "appointmentNumbers",
                message: "This field is required."
            },
            {
                key: "membersPerAppointment",
                message: "This field is  required."
            },
            {
                key: "amount",
                message: "This field is required."
            },
            {
                key: "timeDuration",
                message: "This field is required."
            },
        ];

        const validation: any = reactive({});
        validationConfigs.forEach(({
            key,
            message
        }) => {
            validation[key] = createValidationField(message);
        });

        const validateForm = () => {
            const subscription: any = state.data.subscription;
            const validations = validationConfigs.map(({
                key
            }) => {
                let value;

                if (key === "packageName") {
                    value = subscription.packageName.trim();
                } else if (key in subscription.project) {
                    value = subscription.project[key] > 0;
                } else if (key in subscription.template) {
                    value = subscription.template[key] > 0;
                } else if (key in subscription.mycalender) {
                    value = subscription.mycalender[key] > 0;
                }

                return {
                    value,
                    path: `${key}.required`,
                };
            });

            let isValid = true;

            validations.forEach(({
                value,
                path
            }) => {
                const [field, rule] = path.split('.');
                validation[field][rule] = !value;
                if (!value) isValid = false;
            });

            return isValid;
        };

        const clearValidation = (field: string) => {
            validation[field].required = false;
        };
        const resetSubscription = () => {
            state.data.subscription.packageName = "";
            state.data.subscription.project.projectNumber = 0;
            state.data.subscription.project.channelPerProject = 0;
            state.data.subscription.project.memberPerChannel = 0;
            state.data.subscription.project.guestMember = 0;
            state.data.subscription.project.totalChannelMember = 0;
        }
        
        const updateSubscription = () => {
            state.ui.error.errorList.subscription = [];
            state.ui.error.hasError = false;
            state.ui.isPostLoading.createSubscription = false;
            // subscriptionLabelList
            if (state.ui.isPostLoading.createSubscription) return false;
            const features = [] as any;

            features.push({
                'project_number': state.data.subscription.project.projectNumber
            });
            features.push({
                'channel_per_project': state.data.subscription.project.channelPerProject
            });
            features.push({
                'member_per_channel': state.data.subscription.project.memberPerChannel
            });
            features.push({
                'guests_per_project': state.data.subscription.project.guestMember
            });
            features.push({
                'private_channel': state.data.subscription.project.privateChannel
            });
            features.push({
                'total_channel_member': state.data.subscription.project.totalChannelMember
            });
            features.push({
                'channel_message_history': state.data.subscription.project.channelMessageHistory
            });
            features.push({
                'channel_file_size': state.data.subscription.project.ChannelFileSize
            });
            features.push({
                'export_channel_pdf': state.data.subscription.project.exportChannelPdf
            });
            features.push({
                'archive_channel': state.data.subscription.project.archiveChannel
            });
            features.push({
                'estimation_per_project': state.data.subscription.project.estimationPerProject
            });
            features.push({
                'total_estimation': state.data.subscription.project.totalEstimation
            });
            features.push({
                'phase_per_smart_contract': state.data.subscription.project.phasePerSmartContract
            });
            features.push({
                'task_per_phase': state.data.subscription.project.taskPerPhase
            });
            features.push({
                'member_in_smart_contract': state.data.subscription.project.memberinSmartContract
            });
            features.push({
                'invoice_payment': state.data.subscription.project.invoicePayment
            });
            features.push({
                'template_number': state.data.subscription.template.templateNumber
            });
            features.push({
                'pdf_number': state.data.subscription.template.pdfNumber
            });
            features.push({
                'folder_number': state.data.subscription.template.folderNumber
            });
            features.push({
                'files_number': state.data.subscription.template.filesNumber
            });
            features.push({
                'appointment_numbers': state.data.subscription.mycalender.appointmentNumbers
            });
            features.push({
                'members_per_appointment': state.data.subscription.mycalender.membersPerAppointment
            });
            features.push({
                'import_option': state.data.subscription.mycalender.importOption
            });
            features.push({
                'sync_option': state.data.subscription.mycalender.syncOption
            });
            features.push({
                'amount': state.data.subscription.mycalender.amount
            });
            features.push({
                'time_duration': state.data.subscription.mycalender.timeDuration
            });
            const payLoad = {
                id: route.query.subId,
                name: state.data.subscription.packageName,
                features: features

            }

            console.log("Payload >>> ", payLoad);
            state.ui.isPostLoading.createSubscription = true;
            ethitransService
                .updateSubscription(payLoad)
                .then((res: any) => {
                    state.ui.isPostLoading.createSubscription = false;
                    console.log("Check the Subscription Label List >>> ", res.data.data)
                    state.data.subscriptionLabelList = res.data.data;
                    state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
                    state.data.successFulMessage = "Subscription Updated Successfully."
                    // state.data.unitLists = res.data.data;

                    resetSubscription();
                })
                .catch((error: any) => {
                    state.ui.isPostLoading.createSubscription = false;
                    common
                        .sdCatchErr(error, state.ui.error.errorList.subscription)
                        .then((res: any) => {
                            state.ui.error.hasError = true;
                            for (const [key, value] of Object.entries(res)) {
                                if (Array.isArray(value)) {
                                    state.ui.error.errorList.subscription.push(value[0]);
                                }
                            }
                        });
                })
        };
        // list the label for subscription UI
        const createSubscription = () => {
            state.ui.error.errorList.subscription = [];
            state.ui.error.hasError = false;
            state.ui.isPostLoading.createSubscription = false;
            // subscriptionLabelList
            if (state.ui.isPostLoading.createSubscription) return false;
            const features = [] as any;

            features.push({
                'project_number': state.data.subscription.project.projectNumber
            });
            features.push({
                'channel_per_project': state.data.subscription.project.channelPerProject
            });
            features.push({
                'member_per_channel': state.data.subscription.project.memberPerChannel
            });
            features.push({
                'guests_per_project': state.data.subscription.project.guestMember
            });
            features.push({
                'private_channel': state.data.subscription.project.privateChannel
            });
            features.push({
                'total_channel_member': state.data.subscription.project.totalChannelMember
            });
            features.push({
                'channel_message_history': state.data.subscription.project.channelMessageHistory
            });
            features.push({
                'channel_file_size': state.data.subscription.project.ChannelFileSize
            });
            features.push({
                'export_channel_pdf': state.data.subscription.project.exportChannelPdf
            });
            features.push({
                'archive_channel': state.data.subscription.project.archiveChannel
            });
            features.push({
                'estimation_per_project': state.data.subscription.project.estimationPerProject
            });
            features.push({
                'total_estimation': state.data.subscription.project.totalEstimation
            });
            features.push({
                'phase_per_smart_contract': state.data.subscription.project.phasePerSmartContract
            });
            features.push({
                'task_per_phase': state.data.subscription.project.taskPerPhase
            });
            features.push({
                'member_in_smart_contract': state.data.subscription.project.memberinSmartContract
            });
            features.push({
                'invoice_payment': state.data.subscription.project.invoicePayment
            });
            features.push({
                'template_number': state.data.subscription.template.templateNumber
            });
            features.push({
                'pdf_number': state.data.subscription.template.pdfNumber
            });
            features.push({
                'folder_number': state.data.subscription.template.folderNumber
            });
            features.push({
                'files_number': state.data.subscription.template.filesNumber
            });
            features.push({
                'appointment_numbers': state.data.subscription.mycalender.appointmentNumbers
            });
            features.push({
                'members_per_appointment': state.data.subscription.mycalender.membersPerAppointment
            });
            features.push({
                'import_option': state.data.subscription.mycalender.importOption
            });
            features.push({
                'sync_option': state.data.subscription.mycalender.syncOption
            });
            features.push({
                'amount': state.data.subscription.mycalender.amount
            });
            features.push({
                'time_duration': state.data.subscription.mycalender.timeDuration
            });
            const payLoad = {
                name: state.data.subscription.packageName,
                features: features

            }

            state.ui.isPostLoading.createSubscription = true;
            ethitransService
                .createSubscription(payLoad)
                .then((res: any) => {
                    state.ui.isPostLoading.createSubscription = false;
                    console.log("Check the Subscription Label List >>> ", res.data.data)
                    state.data.subscriptionLabelList = res.data.data;
                    state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
                    state.data.successFulMessage = "Subscription Created Successfully."
                    // state.data.unitLists = res.data.data;

                    resetSubscription();
                })
                .catch((error: any) => {
                    state.ui.isPostLoading.createSubscription = false;
                    common
                        .sdCatchErr(error, state.ui.error.errorList.subscription)
                        .then((res: any) => {
                            state.ui.error.hasError = true;
                            for (const [key, value] of Object.entries(res)) {
                                if (Array.isArray(value)) {
                                    state.ui.error.errorList.subscription.push(value[0]);
                                }
                            }
                        });
                })
        };

        const saveAction = () => {
            if (validateForm()) {
                if (state.data.editMode) {
                    updateSubscription();
                } else {
                    createSubscription();
                }
            }
        };

        function closeSuccessCustomModal() {
            state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
            $("#SuccessfulModalCustom").modal("hide");
            router.push({
                name: "admin-subscription",
            });
        }
        const filterArray = (array: any, index: any) => {
            if (index > -1 && index < array.length) {
                array.splice(index, 1);
            }
            return array;
        };
        const addNotification = () => {
            // insert new notification in
            const newNotification = {
                // eslint-disable-next-line @typescript-eslint/camelcase
                notify_type: 1,
                before: 10,
                // eslint-disable-next-line @typescript-eslint/camelcase
                before_type: 1,
            };
            state.data.notificationList.push(newNotification);
        };
        const setNotificationBy = (type: number, selectedIndex: number) => {
            // insert new notification in
            // eslint-disable-next-line @typescript-eslint/camelcase
            state.data.notificationList[selectedIndex].notify_type = type;
        };
        const setUnitBy = (unit: number, selectedIndex: number) => {
            // insert new notification in
            // eslint-disable-next-line @typescript-eslint/camelcase
            state.data.notificationList[selectedIndex].before_type = unit;
        };
        const removeSelected = (selected: any) => {
            state.data.notificationList = filterArray(
                state.data.notificationList,
                selected
            );
        };

        // list the label for subscription UI
        const subscriptionLabelList = () => {
            // subscriptionLabelList
            ethitransService
                .subscriptionLabelList()
                .then((res: any) => {
                    console.log("Check the Subscription Label List >>> ", res.data.data)
                    state.data.subscriptionLabelList = res.data.data;
                    // state.data.unitLists = res.data.data;

                })
                .catch((error: any) => {
                    common
                        .sdCatchErr(error, state.ui.error.errorList.subscription)
                        .then((res: any) => {
                            state.ui.error.hasError = true;
                            for (const [key, value] of Object.entries(res)) {
                                if (Array.isArray(value)) {
                                    state.ui.error.errorList.subscription.push(value[0]);
                                }
                            }
                        });
                })
        };
        const detailsBySubscriptionId = (query: string) => {
            state.ui.error.errorList.subscription = [];
            state.ui.error.hasError = false;
            // subscriptionLabelList
            ethitransService
                .subscriptionDetails(query)
                .then((res: any) => {
                    // console.log("Check the detailsBySubscriptionId List >>> ", res.data.data)
                    // state.data.subscriptionLabelList = res.data.data;
                    // state.data.unitLists = res.data.data;
                    state.data.subscription.packageName = res.data.data.name
                    state.data.subscription.project.projectNumber = res.data.data.project_number
                    state.data.subscription.project.channelPerProject = res.data.data.channel_per_project;
                    state.data.subscription.project.memberPerChannel = res.data.data.member_per_channel;
                    state.data.subscription.project.guestMember = res.data.data.guests_per_project;
                    state.data.subscription.project.privateChannel = res.data.data.private_channel;
                    state.data.subscription.project.totalChannelMember = res.data.data.total_channel_member;
                    state.data.subscription.project.channelMessageHistory = res.data.data.channel_message_history;
                    state.data.subscription.project.ChannelFileSize = res.data.data.channel_file_size;
                    state.data.subscription.project.exportChannelPdf = res.data.data.export_channel_pdf;
                    state.data.subscription.project.archiveChannel = res.data.data.archive_channel;
                    state.data.subscription.project.estimationPerProject = res.data.data.estimation_per_project;
                    state.data.subscription.project.totalEstimation = res.data.data.total_estimation;
                    state.data.subscription.project.phasePerSmartContract = res.data.data.phase_per_smart_contract;
                    state.data.subscription.project.taskPerPhase = res.data.data.task_per_phase;
                    state.data.subscription.project.memberinSmartContract = res.data.data.member_in_smart_contract;
                    state.data.subscription.project.invoicePayment = res.data.data.invoice_payment;
                    state.data.subscription.template.templateNumber = res.data.data.template_number;
                    state.data.subscription.template.pdfNumber = res.data.data.pdf_number;
                    state.data.subscription.template.folderNumber = res.data.data.folder_number;
                    state.data.subscription.template.filesNumber = res.data.data.files_number;
                    state.data.subscription.mycalender.appointmentNumbers = res.data.data.appointment_numbers;
                    state.data.subscription.mycalender.membersPerAppointment = res.data.data.members_per_appointment;
                    state.data.subscription.mycalender.importOption = res.data.data.import_option;
                    state.data.subscription.mycalender.syncOption = res.data.data.sync_option;
                    state.data.subscription.mycalender.membersPerAppointment = res.data.data.members_per_appointment;

                    // res.data.data.template_number state.data.subscription.template.templateNumber
                    // res.data.data.pdf_number state.data.subscription.template.pdfNumber
                    // res.data.data.folder_number state.data.subscription.template.folderNumber
                    // res.data.data.files_number state.data.subscription.template.filesNumber
                    // res.data.data.appointment_numbers state.data.subscription.mycalender.appointmentNumbers
                    // res.data.data.members_per_appointment state.data.subscription.mycalender.membersPerAppointment
                    // res.data.data.import_option state.data.subscription.mycalender.importOption
                    // res.data.data.sync_option state.data.subscription.mycalender.syncOption
                    // state.data.subscription.mycalender.membersPerAppointment = res.data.data.membersPerAppointment;
                    state.data.subscription.mycalender.amount = res.data.data.amount;
                    state.data.subscription.mycalender.timeDuration = res.data.data.time_duration;

                })
                .catch((error: any) => {
                    common
                        .sdCatchErr(error, state.ui.error.errorList.subscription)
                        .then((res: any) => {
                            state.ui.error.hasError = true;
                            for (const [key, value] of Object.entries(res)) {
                                if (Array.isArray(value)) {
                                    state.ui.error.errorList.subscription.push(value[0]);
                                }
                            }
                        });
                })
        };
        

        // validateForm();
        //   if (
        //     state.errorList.length != 0 ||
        //     state.isErrorStatus ||
        //     state.postLoadingCreateProject
        //   )
        //     return false;

        

        onMounted(() => {
            subscriptionLabelList();
            const query: any = route.query.subId;
            console.log("CHECK >>> ", query)
            if (query) {
                detailsBySubscriptionId(query);
                state.data.editMode = true;
            }
            // if (props.showModal) {
            //   console.log("Props showModal");
            // }
        });

        return {
            state,
            validation,
            saveAction,
            clearValidation,
            closeSuccessCustomModal,
            resetSubscription,
            updateSubscription,
            createSubscription,
            detailsBySubscriptionId,
            subscriptionLabelList,
            removeSelected,
            addNotification,
            setNotificationBy,
            setUnitBy,
            filterArray,
        };
    },
});
