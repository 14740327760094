
import {
  defineComponent,
  reactive,
  onMounted,
  ref,
  watch,
  computed,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import Button from "@/common/Button.vue";

import { ethitransService } from "@/services/EthitransService";
import { validationService } from "@/common/ValidationService";
import { common } from "@/common/services/Common";
import moment from "moment";

import ErrorList from "@/components/commonDesign/ErrorList.vue";
import DragAndDropZone from "@/common/DragAndDropZone.vue";
import DebouncedCurrencyInput from "@/common/DebouncedCurrencyInput.vue";


export default defineComponent({
  name: "ToDoListAddPhaseModal",
  props: {
    showAddPhase: {
      type: Boolean,
      required: false,
    },
    editMode: {
      type: Boolean,
      required: false,
    },
    editFrom: {
     type: String,
    },
    taskEditMode: {
      type: Boolean,
      required: false,
    },
    projectDetail: {
      type: Object,
      required: false,
    },
    modelName: {
      type: String,
    },
    projectAcknowledgementDetail: {
      type: Object,
      required: false,
    },
    todoDetail: {
      type: Object,
      required: false,
    },
    phase: {
      type: Object,
      required: false,
    },
    editData: {
      type: Object,
      required: false,
    },
  },
  components: {
    DebouncedCurrencyInput,
    Button,
    ErrorList,
    DragAndDropZone,
  },
  setup(props, { emit }) {
    const router = useRouter();
    const {
      params: { projectId },
    } = useRoute();
    const todoData = ref({
      project: {} as any,
      payer: {} as any,
      phases: {} as any,
      todo: {} as any,
    });
    const state = reactive({
      data: {
        minDate: props.projectAcknowledgementDetail?moment(props.projectAcknowledgementDetail.start_date).format("Y-MM-DD"):'',
        maxDate: props.projectAcknowledgementDetail?moment(props.projectAcknowledgementDetail.end_date).format("Y-MM-DD"):'',
        endDateMindate: '' as any,
        imagesToUpload: [] as any,
        buttonName: "Save changes",
        invite: {
          users: [] as any,
          invites: [] as any,
          newUsers: "",
        },
        phase: {
          name: "",
          priority: "",
          description: "",
          startDatepriority: "",
          startDate: "",
          endDate: "",
          checkPercentage: false as any,
          percentage: 0,
          estBudget: 0,
          existedMembers: [] as any,
          newMembers: [] as any,
          remarks: "",
          attachments: [] as any,
        },
        existingMembers: {
          members: [],
          // eslint-disable-next-line @typescript-eslint/camelcase
          max_budget: 0,
        } as any,
        myDriveFileFolderLoad: false,
        showSuccesModal: false,
      },
      ui: {
        error: {
          hasError: false,
          errorList: {
            createPhase: [] as any,
          },
        },
        isLoading: {
          getToDoData: false,
          enableEndDate: true,
        },
        isPostLoading: {
          createPhase: false,
          getPhaseDetail: false,
        },
      },
    });
    const dispatchedAmount = computed(() => {
      let amount = 0;
      // state.data.phaseBudget
      if (
        state.data.phase.checkPercentage &&
        state.data.phase.percentage <= 100
      ) {
        amount = (state.data.existingMembers.max_budget * state.data.phase.percentage) / 100;
        // state.data.phase.estBudget = amount;
        return Number(amount);
      } else {
        return Number(state.data.phase.estBudget);
      }
    });

     watch(
      () => state.data.phase.startDate,
      (newvalue, oldvalue) => {
        state.data.endDateMindate = newvalue
        state.ui.isLoading.enableEndDate = false
      }
    );
    function closeSuccessModal() {
      state.data.showSuccesModal = !state.data.showSuccesModal;
      $("#successfulModal").modal("hide");
    }
    function removeSelectedImage(image: any) {
      state.data.imagesToUpload = state.data.imagesToUpload.filter(
        (e: any) => e != image
      );
    }
    function readImage(file: any) {
      // console.log("CHECK IMAGE>>", file);
      // let data = "";
      const data = {} as any;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        // console.log("BIT BIT>>", e);
        // console.log("FILE TO UPLOAD >>> ", file.name.toLowerCase())
        const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
        const extensionLower = extension.toLowerCase();
        data.file = e.target.result;
        // data.duplicateFile = false; // data.fileName = file.name;
        data.name = file.name.replaceAll(`.${extension}`, "");
        // let duplicateName = false;
        // state.data.folderList.files.forEach((item: any) => {
        //   // console.log("TEST loop>>>", item.filename.toLowerCase());
        //   if (
        //     file.name.toLowerCase() === item.filename.toLowerCase() &&
        //     !file.duplicateFile
        //   ) {
        //     // console.log("FOUND MATCH WITH >>> ", item.filename.toLowerCase());
        //     data.duplicateFile = true;
        //     // console.log("FOUND MATCH STATUS >>> ", data.duplicateFile);
        //   }
        //   //   }
        // });
        // console.log("Check the File Status >>>", duplicateName);
        // console.log("Attachment Name Check >>>", data.fileName)
        data.extension = extensionLower;
        // data.isuploded = false;
        // data.tag = [] as any;
        // data.size = common.formatBytes(parseInt(file.size));
        // console.log("UPLOAD READY FILE >>>", data);
        state.data.imagesToUpload.push(data);
        // console.log("CHECK DATA FOR UPLOAD>>", data);
        // state.imageList.push(data);
      };
      reader.readAsDataURL(file);
      // masterTagList();
    }
    const dropFiles = (event: any) => {
      const files = event.target.files || event.dataTransfer.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        readImage(file);
      }
    };
    function hideAddPhaseModal() {
      emit("close");
      $("#addPhaseModal").modal("hide");
      state.data.phase.name = "";
      state.data.phase.description = "";
      state.data.phase.priority = "";
      state.data.phase.startDate = "";
      state.data.phase.endDate = "";
      state.data.phase.estBudget = 0;
      state.data.imagesToUpload = [];
    }
    function changeInviteMember() {
      // console.log("CHECK MSG", state.inviteMember);

      if (state.data.invite.newUsers) {
        // alert("AA");
        // if(state.data.phase.existedMembers){
        if (validationService.isValidEmail(state.data.invite.newUsers)) {
          // console.log("CHECK THE INVITE MEMBER", state.inviteMember);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if (state.data.invite.invites.length === 0) {
            state.data.invite.invites.push({
              name: "",
              hourly: "",
              email: state.data.invite.newUsers,
              // eslint-disable-next-line @typescript-eslint/camelcase
              sub_contractor: false,
              member: false,
              error: false,
            });
          } else {
            state.data.invite.invites.forEach((member: any) => {
              // console.log("member", member);
              // console.log("member", state.data.invite.newUsers);
              if (member.member) {
                member.member = false;
              }
              if (
                member.email.toLowerCase() !=
                state.data.invite.newUsers.toLowerCase()
              ) {
                state.data.invite.invites.push({
                  name: "",
                  hourly: "",
                  email: state.data.invite.newUsers.toLowerCase(),
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  sub_contractor: false,
                  member: false,
                  error: false,
                });
              }
            });
          }
          state.data.invite.newUsers = "";
          state.data.phase.existedMembers = "";
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          state.data.invite.invites = Array.from(
            new Set(state.data.invite.invites.map((s: any) => s.email))
          ).map((email) => {
            return {
              email: email,
              // eslint-disable-next-line @typescript-eslint/camelcase
              sub_contractor: state.data.invite.invites.find(
                (s: any) => s.email === email
              ).sub_contractor,
              member: state.data.invite.invites.find(
                (s: any) => s.email === email
              ).member,
              name: state.data.invite.invites.find(
                (s: any) => s.email === email
              ).name,
              hourly: state.data.invite.invites.find(
                (s: any) => s.email === email
              ).hourly,
              error: state.data.invite.invites.find(
                (s: any) => s.email === email
              ).error,
            };
          });
          // state.data.invite.invites = Array.from(
          //   new Set(state.data.invite.invites.map((s: any) => s.email))
          // ).map((email) => {
          //   return {
          //     email: email,
          //     // eslint-disable-next-line @typescript-eslint/camelcase
          //     sub_contractor: false,
          //     member: false,
          //   };
          // });
        }
      } else {
        // alert("BB");
        if (validationService.isValidEmail(state.data.phase.existedMembers)) {
          // console.log("CHECK THE INVITE MEMBER", state.inviteMember);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if (state.data.invite.invites.length === 0) {
            state.data.invite.invites.push({
              name: "",
              hourly: "",
              email: state.data.phase.existedMembers,
              // eslint-disable-next-line @typescript-eslint/camelcase
              sub_contractor: false,
              member: false,
              error: false,
            });
          } else {
            state.data.invite.invites.forEach((member: any) => {
              console.log("member", member);
              console.log("member", state.data.phase.existedMembers);
              if (member.member) {
                member.member = false;
              }
              if (
                member.email.toLowerCase() !=
                state.data.phase.existedMembers.toLowerCase()
              ) {
                state.data.invite.invites.push({
                  name: "",
                  hourly: "",
                  email: state.data.phase.existedMembers.toLowerCase(),
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  sub_contractor: false,
                  member: false,
                  error: false,
                });
              }
            });
          }
          state.data.phase.existedMembers = "";
          // const unique = Array.from(new Set(state.data.invite.invites.map((item: any) => item.email)));
          // console.log("CHECK TEH LIST >>> ", unique)

          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          state.data.invite.invites = Array.from(
            new Set(state.data.invite.invites.map((s: any) => s.email))
          ).map((email) => {
            return {
              email: email,
              // eslint-disable-next-line @typescript-eslint/camelcase
              sub_contractor: state.data.invite.invites.find(
                (s: any) => s.email === email
              ).sub_contractor,
              member: state.data.invite.invites.find(
                (s: any) => s.email === email
              ).member,
              name: state.data.invite.invites.find(
                (s: any) => s.email === email
              ).name,
              hourly: state.data.invite.invites.find(
                (s: any) => s.email === email
              ).hourly,
              error: state.data.invite.invites.find(
                (s: any) => s.email === email
              ).error,
            };
          });
        }

        // if (validationService.isValidEmail(state.data.phase.existedMembers)) {
        //   // console.log("CHECK THE INVITE MEMBER", state.inviteMember);
        //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
        //   if (state.data.invite.invites.length === 0) {
        //     state.data.invite.invites.push({
        //       email: state.data.invite.newUsers,
        //       // eslint-disable-next-line @typescript-eslint/camelcase
        //       sub_contractor: false,
        //       member: false,
        //     });
        //   } else {
        //     state.data.invite.invites.forEach((member: any) => {
        //       // console.log("member", member);
        //       if (member != state.data.phase.existedMembers) {
        //         state.data.invite.invites.push({
        //           email: state.data.invite.newUsers,
        //           // eslint-disable-next-line @typescript-eslint/camelcase
        //           sub_contractor: false,
        //           member: false,
        //         });
        //       }
        //     });
        //   }
        //   state.data.invite.invites = state.data.invite.invites.filter(
        //     (item: any, i: any, ar: any) => ar.indexOf(item) === i
        //   );
        //   state.data.phase.existedMembers = "";
        // }
      }
      state.data.phase.existedMembers = [];
      // console.log("CHECK MSG AFTER", state.invite.invites);
    }
    function removeSelectedMember(item: any) {
      state.data.invite.users = state.data.invite.users.filter(
        (e: any) => e != item
      );
    }
    function removeSelectedUser(item: any) {
      state.data.invite.invites = state.data.invite.invites.filter(
        (e: any) => e != item
      );
    }
    function changeExistingMember() {
      state.data.invite.users.push(state.data.phase.existedMembers);
      state.data.phase.existedMembers = "";
    }
    function validateCreatePhase() {
      // console.log("*** TODO VALIDATION  ***");
      state.ui.error.hasError = false;
      state.ui.error.errorList.createPhase = [];
      let memberHourError: any = [];
      memberHourError = [];
      if (!state.data.phase.name) {
        state.ui.error.errorList.createPhase.push("Phase name is required.");
      }
      if (!state.data.phase.priority) {
        state.ui.error.errorList.createPhase.push("Priority is required.");
      }
      // if (!state.data.phase.checkPercentage) {
      //   if (!dispatchedAmount.value) {
      //     state.ui.error.errorList.createPhase.push(
      //       "Estimated Budget is required."
      //     );
      //   }
      // } else {
      //   if (!state.data.phase.percentage) {
      //     state.ui.error.errorList.createPhase.push(
      //       "Percentage of Budget is required."
      //     );
      //   }
      // }
      // if (state.data.invite.invites && state.data.invite.invites.length === 0) {
      //   state.ui.error.errorList.createPhase.push("Members is required.");
      // } else {
      //   state.data.invite.invites.forEach((item: any) => {
      //     console.log("*** TODO VALIDATION  ***", item);
      //     if (!item.hourly && !item.sub_contractor) {
      //       item.error = true;
      //       memberHourError.push(item);
      //     }
      //   });
      // }
      // if (dispatchedAmount.value) {
      //   if (
      //     state.data.existingMembers.max_budget < dispatchedAmount.value
      //   ) {
      //     // projectAcknowledgementDetail.budget
      //     state.ui.error.errorList.createPhase.push(
      //       "Your Project Budget does not match the estimate."
      //     );
      //   }
      // }
      if (memberHourError.length) {
        state.ui.error.errorList.createPhase.push("Hourly Rate is required for Member.");
      }
      if (!state.data.phase.startDate) {
        state.ui.error.errorList.createPhase.push("Start Date is required.");
      }
      if (!state.data.phase.endDate) {
        state.ui.error.errorList.createPhase.push("End Date is required.");
      }
      if (state.data.phase.startDate) {
        if (
          props.projectAcknowledgementDetail &&
          props.projectAcknowledgementDetail.start_date
        ) {
          const startDate = props.projectAcknowledgementDetail.start_date.slice(
            0,
            10
          );
          if (state.data.phase.startDate < startDate) {
            state.ui.error.errorList.createPhase.push(
              "Date Inserted does not fall within the Start Date Range."
            );
          }
        }
      }
      if (state.data.phase.endDate) {
        if (
          props.projectAcknowledgementDetail &&
          props.projectAcknowledgementDetail.end_date
        ) {
          const endDate = props.projectAcknowledgementDetail.end_date.slice(
            0,
            10
          );
          if (state.data.phase.endDate > endDate) {
            state.ui.error.errorList.createPhase.push(
              "Date Inserted does not fall within the End Date Range."
            );
          }
        }
      }
      if (
        !state.ui.error.errorList.createPhase &&
        !state.ui.error.errorList.createPhase.length
      ) {
        state.ui.error.hasError = false;
      } else if (state.ui.error.errorList.createPhase.length != 0) {
        state.ui.error.hasError = true;
      }
    }
    function createPhase() {
      // alert("HELLO");
      state.ui.error.errorList.createPhase = [];
      state.ui.error.hasError = false;
      if (state.data.invite.newUsers && state.data.invite.newUsers.length) {
        changeInviteMember();
        // if (validationService.isValidEmail(state.data.invite.newUsers)) {
        //   state.data.invite.invites.push(state.data.invite.newUsers);
        //   state.data.invite.newUsers = "";
        // }
      }

      validateCreatePhase();

      if (state.ui.isPostLoading.createPhase) return false;
      if (
        state.ui.error.hasError ||
        state.ui.error.errorList.createPhase.length != 0
      )
        return false;
      // state.data.invite.users // existed members
      // state.data.invite.invites // new members
      // state.data.imagesToUpload
      const existingMembers: any = [];
      const newMembers: any = [];
      // state.data.invite.users.forEach((existingmember: any) => {
      //   existingMembers.push(existingmember.id);
      // });

      state.data.invite.invites.forEach((newmember: any) => {
        newMembers.push(newmember);
      });

      const payLoad: any = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        // todo_id: props.todoDetail.id ? props.todoDetail.id : props.editData.todo_id,
        name: state.data.phase.name,
        description: state.data.phase.description,
        // eslint-disable-next-line @typescript-eslint/camelcase
        priority_id: parseInt(state.data.phase.priority),
        // eslint-disable-next-line @typescript-eslint/camelcase
        start_date: state.data.phase.startDate,
        // eslint-disable-next-line @typescript-eslint/camelcase
        end_date: state.data.phase.endDate,
        budget: dispatchedAmount.value,
        members: state.data.invite.invites,
        // eslint-disable-next-line @typescript-eslint/camelcase
        budget_type: state.data.phase.checkPercentage ? 2 : 1,
        // eslint-disable-next-line @typescript-eslint/camelcase
        // parent_id: props.phase.id,
        // new_members: newMembers,
        files: state.data.imagesToUpload,
      };
      if (props.phase && props.phase.id) {
        // eslint-disable-next-line @typescript-eslint/camelcase
        payLoad.parent_id = props.phase.id;
      }
      if (props.todoDetail && props.todoDetail.id) {
        // eslint-disable-next-line @typescript-eslint/camelcase
        payLoad.todo_id = props.todoDetail.id;
      } else if (props.editData && props.editData.todo_id) {
        // eslint-disable-next-line @typescript-eslint/camelcase
        payLoad.todo_id = props.editData.todo_id;
      }
      state.ui.isPostLoading.createPhase = true;
      ethitransService
        .createPhaseForToDo(payLoad)
        .then((res: any) => {
          // alert("THEN");
          console.log("Project Todo Response Check:>>> ", res.data.data);
          // todoData.value = res.data.data;
          if (res.data.data.phase) {
            res.data.data.phase.isSelected = false;
            res.data.data.phase.localStartedDate = common.localDateOnly(
              res.data.data.phase.start_date
            );
            res.data.data.phase.localEndDate = common.localDateOnly(
              res.data.data.phase.end_date
            );
            res.data.data.phase.child_phases.forEach((phase: any) => {
              phase.localStartedDate = common.localDateOnly(
                phase.start_date
              );
              phase.localEndDate = common.localDateOnly(phase.end_date);
            });
            emit("addPhase", res.data.data);
          } else if (res.data.data.phases) {
            res.data.data.phases.data.forEach((phase: any) => {
              phase.isSelected = false;
              phase.localStartedDate = common.localDateOnly(
                phase.start_date
              );
              phase.localEndDate = common.localDateOnly(phase.end_date);
            });
            emit("addPhase", res.data.data);
          }
          // emit("addPhase", res.data.data);
          hideAddPhaseModal();
        })
        .catch((error: any) => {
          // alert("CATCH WHY");
          console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.createPhase)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.createPhase.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.createPhase = false;
        });
    }
    function updatePhaseData() {
      
      state.ui.error.errorList.createPhase = [];
      state.ui.error.hasError = false;

      validateCreatePhase();

      if (state.ui.isPostLoading.createPhase) return false;
      if (
        state.ui.error.hasError ||
        state.ui.error.errorList.createPhase.length != 0
      )
        return false;
      const existingMembers: any = [];
      const newMembers: any = [];
      if (state.data.invite.newUsers) {
        changeInviteMember();
      }
      state.data.invite.invites.forEach((newmember: any) => {
        newMembers.push(newmember);
      });

      const payLoad: any = {
        name: state.data.phase.name,
        description: state.data.phase.description,
        // eslint-disable-next-line @typescript-eslint/camelcase
        priority_id: parseInt(state.data.phase.priority),
        // eslint-disable-next-line @typescript-eslint/camelcase
        start_date: state.data.phase.startDate,
        // eslint-disable-next-line @typescript-eslint/camelcase
        end_date: state.data.phase.endDate,
        budget: state.data.phase.estBudget,
        // members: existingMembers,
        members: state.data.invite.invites,
        // eslint-disable-next-line @typescript-eslint/camelcase
        // new_members: newMembers,
        files: state.data.imagesToUpload,
      };
      if (props.editData && props.editData.parent_id) {
        // eslint-disable-next-line @typescript-eslint/camelcase
        payLoad.parent_id = props.editData.parent_id;
      }
      if (props.editData && props.editData.uuid) {
        // eslint-disable-next-line @typescript-eslint/camelcase
        payLoad.phase_id = props.editData.uuid;
      }

      state.ui.isPostLoading.createPhase = true;
      ethitransService
        .updatePhaseForToDo(payLoad)
        .then((res: any) => {
          if (res.data.data.phase) {
            res.data.data.phase.isSelected = false;
            res.data.data.phase.localStartedDate = common.localDateOnly(
              res.data.data.phase.start_date
            );
            res.data.data.phase.localEndDate = common.localDateOnly(
              res.data.data.phase.end_date
            );
            res.data.data.phase.child_phases.forEach((phase: any) => {
              phase.localStartedDate = common.localDateOnly(
                phase.start_date
              );
              phase.localEndDate = common.localDateOnly(phase.end_date);
            });
            // editSelected
            emit("editSelected",res.data.data);
          } else if (res.data.data.phases) {
            // emit("updatePhaseSelected", "Hello else");
            res.data.data.phases.data.forEach((phase: any) => {
              phase.isSelected = false;
              phase.localStartedDate = common.localDateOnly(phase.start_date);
              phase.localEndDate = common.localDateOnly(phase.end_date);
            });
            emit("editSelected",res.data.data);
          }

          hideAddPhaseModal();
        })
        .catch((error: any) => {
          // alert("CATCH WHY");
          console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.createPhase)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.createPhase.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.createPhase = false;
        });
    }
    function update() {
      if (props.editMode) {
        // alert("EDIT MODE")
        updatePhaseData();
      } else {
        createPhase();
        // alert("ADD MODE")
      }
    }
    function getExistingMemberList() {
      // if(!props.projectDetail.uuid) return false;
      state.ui.error.errorList.createPhase = [];
      state.ui.error.hasError = false;

      if (state.ui.isPostLoading.createPhase) return false;

      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: props.projectDetail?props.projectDetail.uuid:'',
      };
      state.ui.isPostLoading.createPhase = true;
      ethitransService
        .memberListByProject(payLoad)
        .then((res: any) => {
          console.log("Project Todo Response Check:>>> ", res.data.data);
          // todoData.value = res.data.data;
          // eslint-disable-next-line @typescript-eslint/camelcase
          res.data.data.displayMaxbudget = common.formatCurrency(res.data.data.max_budget);
          state.data.existingMembers = res.data.data;
          // state.data.existingMembers ? res.data.data: [];
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.createPhase)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.createPhase.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.createPhase = false;
        });
    }
    function getPhaseDetail() {
      // if(!props.projectDetail.uuid) return false;
      state.ui.error.errorList.createPhase = [];
      state.ui.error.hasError = false;

      if (state.ui.isPostLoading.getPhaseDetail) return false;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const payLoad: any = {};
      if (props.projectDetail && props.projectDetail.uuid && props.phase && props.phase.id) {
        // eslint-disable-next-line @typescript-eslint/camelcase
        payLoad.phase_id = props.phase.uuid;
      } else {
        // eslint-disable-next-line @typescript-eslint/camelcase
        payLoad.project_id = props.projectDetail?props.projectDetail.uuid:'';
      }
      console.log("CHEKC THE PAYLOAD >>> ", payLoad);

      // const payLoad = {
      //   project_id: props.projectDetail.uuid,
      // };
      state.ui.isPostLoading.getPhaseDetail = true;
      ethitransService
        .getPhaseDetail(payLoad)
        .then((res: any) => {
          console.log("GetPhaseDetail Response Check:>>> ", res.data.data);
          // eslint-disable-next-line @typescript-eslint/camelcase
          res.data.data.displayMaxbudget = common.formatCurrency(res.data.data.max_budget);
          state.data.existingMembers = res.data.data;
          // todoData.value = res.data.data;
          // state.data.existingMembers = res.data.data;
          // state.data.existingMembers ? res.data.data: [];
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.createPhase)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.createPhase.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.getPhaseDetail = false;
        });
    }
    function contractorChange(data: any) {
      console.log("selected CONTRACTOR >>>", data);
      state.data.invite.invites.forEach((item: any) => {
        console.log("CHEK CONTRACTOR >>>", item);
        if (data.email.toLowerCase() != item.email.toLowerCase()) {
          // eslint-disable-next-line @typescript-eslint/camelcase
          item.sub_contractor = false;
        } else {
          item.member = true;
        }
        if (data.email.toLowerCase() === item.email.toLowerCase()) {
          // eslint-disable-next-line @typescript-eslint/camelcase
          item.member = false;
        }
      });
      // alert("HELLO")
    }
    function memberChange(data: any) {
      // alert("HELLO")
      state.data.invite.invites.forEach((item: any) => {
        console.log("CHEK CONTRACTOR >>>", item);
        if (data.email.toLowerCase() === item.email.toLowerCase()) {
          // eslint-disable-next-line @typescript-eslint/camelcase
          item.sub_contractor = false;
        }
      });
    }
    onMounted(() => {
      if (props.showAddPhase) {
        console.log("heree",props.editData);
        // getExistingMemberList();
        getPhaseDetail();
        $("#addPhaseModal").modal("show");
        // console.log("HELLO FROM PHASE MODAL >>> ", props.editMode)
        if (!props.editMode) return false;
        state.data.phase.name = props.editData?props.editData.name:'';
        state.data.phase.description = props.editData?props.editData.description:'';
        state.data.phase.estBudget =  props.editData?props.editData.budget:'';
        state.data.phase.startDate = props.editData?props.editData.start_date.slice(0, 10):'';
        state.data.phase.endDate =  props.editData?props.editData.end_date.slice(0, 10):'';
        state.data.phase.priority = props.editData?props.editData.priority_id:'';
      }
    });

    return {
      state,
      dispatchedAmount,
      contractorChange,
      memberChange,
      closeSuccessModal,
      removeSelectedImage,
      readImage,
      dropFiles,
      removeSelectedUser,
      changeInviteMember,
      removeSelectedMember,
      changeExistingMember,
      getExistingMemberList,
      createPhase,
      validateCreatePhase,
      hideAddPhaseModal,
      updatePhaseData,
      update,
    };
  },
});
