
import { defineComponent } from "vue";
// import { defineComponent, h } from "vue";
import TheChatBot from "@/includes/TheChatBot.vue";
import AppTour from "@/components/modal/AppTour.vue";

export default defineComponent({
  name: "App",
  components: {
    TheChatBot,
    AppTour,
  },
  data() {
    return {
      // Example data structure with different view classes
      // views: [
      //   { id: 1, className: 'tui-full-calendar-layout tui-view-1' },
      // ]
    };
  },
  // methods: {
  //   isView1(view: any) {
  //     // Check if the class name exactly matches 'tui-full-calendar-layout tui-view-1'
  //     return view.className === 'tui-full-calendar-layout tui-view-1';
  //   }
  // },
  // render() {
  //   // Filter views to include only the one with the specific class
  //   const filteredViews = this.views.filter((view: any) => this.isView1(view));
    
  //   // Create VNode for each filtered view
  //   const viewNodes = filteredViews.map((view: any) =>
  //     h('div', { class: view.className }, 'Content for view 1')
  //   );
    
  //   // Render the filtered view nodes
  //   return h('div', { id: 'app' }, viewNodes);
  // }
});
