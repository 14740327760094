import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "app" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppTour = _resolveComponent("AppTour")
  const _component_router_view = _resolveComponent("router-view")
  const _component_TheChatBot = _resolveComponent("TheChatBot")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", null, [
      _createVNode(_component_AppTour)
    ]),
    _createVNode(_component_router_view),
    _createVNode(_component_TheChatBot)
  ]))
}