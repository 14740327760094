
import { defineComponent, onMounted, reactive, watch, computed } from "vue";
import { useRouter } from "vue-router";
import { ethitransService } from "@/services/EthitransService";
import ErrorList from "@/components/commonDesign/ErrorList.vue";
import EmptyData from "@/components/commonDesign/EmptyData.vue";
import SuccessfulModalCustom from "@/components/modal/SuccessfulModalCustom.vue";

import MyDriveFolder from "@/components/MyDriveFolder.vue";
import MyDriveFile from "@/components/MyDriveFile.vue";
import DeleteSelectedModal from "@/components/modal/DeleteSelectedModal.vue";
import ShareModal from "@/components/modal/ShareModal.vue";
import MyDriveFileSearch from "@/components/MyDriveFileSearch.vue";
import MyDriveFilesUpload from "@/components/MyDriveFilesUpload.vue";
import DragAndDropZone from "@/common/DragAndDropZone.vue";
// import EmptyData from "@/components/commonDesign/EmptyData.vue";
import { common } from "@/common/services/Common";
import { config } from "@/Config";

import { useStore } from "vuex";
import introJs from "intro.js";

// import AppTour from "@/components/modal/AppTour.vue";


// import Multiselect from "@vueform/multiselect";

export default defineComponent({
  name: "MyDrive",
  components: {
    // AppTour,
    DragAndDropZone,
    MyDriveFolder,
    MyDriveFile,
    ErrorList,
    EmptyData,
    SuccessfulModalCustom,
    DeleteSelectedModal,
    ShareModal,
    MyDriveFileSearch,
    MyDriveFilesUpload,
    // vSelect,
    // Multiselect,
    // GoogleAddress,
    // CreateProjectModal,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const state = reactive({
      data: {
        uploadMaxSize: 25 * 1024 * 1024 as any,
        myDriveFileFolderLoad: false,
        showDeleteModal: false,
        showAddFileTag: false,
        showSuccesCustomModal: false,
        showShareModal: false,
        searchByTag: "",
        SuccessFulMessage: "",
        folderName: "",
        disabledFileTagList: [] as any,
        selectedFolders: [] as any,
        selectedFiles: [] as any,
        imagesToUpload: [] as any,
        folderList: {
          files: [] as any,
          folders: [] as any,
        },
        folderTitle: "folder",
        folderHeading: "No folders created",
        fileTitle: "file",
        fileHeading: "No files created",
        tags: {
          mode: "tags",
          value: [] as any,
          options: [] as any,
          searchable: true,
          createTag: true,
        },
      },
      ui: {
        error: {
          hasError: false,
          errorList: {
            uploadAttachments: [] as any,
            createNewFolder: [] as any,
            getFolderList: [] as any,
          },
        },
        isLoading: {
          getFolderList: false,
        },
        isPostLoading: {
          enableSelectedFileTag: false,
          addTagsToFile: false,
          createNewFolder: false,
          uploadAttachments: false,
        },
      },
      selectedStatus: 'active',
    });
    function toggleTourGuide() {
      if (store.state.showTourGuide) {
        introJs()
          .setOptions({
            steps: [
              {
                title: "Welcome ",
                intro:
                  "Welcome to My Drive! 👋 <iframe width='426' height='240' src='https://www.youtube.com/embed/G8vkPCn2bLs' title='Slice Text in Photoshop' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>",
              },
              {
                title: "Create Folder",
                element: document.getElementById(
                  "createFolderMyDrive"
                ) as HTMLInputElement,
                intro:
                  "Create a folder to organize files and folder.",
              },
              {
                title: "Upload Files",
                element: document.getElementById(
                  "create-document-upload"
                ) as HTMLInputElement,
                intro: "Upload Files, ie. .docx, .doc, .pdf, .img, etc...",
              },
              {
                title: "File Tag Settings",
                element: document.getElementById(
                  "fileTagSettings"
                ) as HTMLInputElement,
                intro: "Create new tags and manage tags.",
              },
              {
                title: "Search Files",
                element: document.getElementById(
                  "myDriveFileSearchByText"
                ) as HTMLInputElement,
                intro: "Search Files By name or Tags.",
              },
              {
                title: "Folders",
                element: document.getElementById(
                  "foldersInMyDrive"
                ) as HTMLInputElement,
                intro: "User creted folders and project's channel folder.",
              },
              {
                title: "Files",
                element: document.getElementById(
                  "filesInMyDrive"
                ) as HTMLInputElement,
                intro: "Uploaded Files",
              },
            ],
          })
          .start();
      }
    }

    watch(
      // () => watchTour,
      () => store.state.takAtour,
      (newvalue, oldvalue) => {
        if (newvalue === true) {
          console.log("check >>>", newvalue)
          toggleTourGuide();
        }
      }
    );

    const myDrive = computed(() => {
      return state.data.folderList;
    });

    function closeShareModal(data: any) {
      state.data.showShareModal = !state.data.showShareModal;
      state.data.folderList.files.forEach((item: any) => {
        item.isChecked = false;
      });
      state.data.selectedFiles = [];
      if (data.uuid) {
        state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
      }
    }
    function closeDeleteMultipleModal(data: any) {
      state.data.showDeleteModal = !state.data.showDeleteModal;
      data.forEach((item: any) => {
        // console.log("CHECK MULTIPE DETELE DATA >>> ",item)
        state.data.folderList.files = state.data.folderList.files.filter(
          (e: any) => e.uuid != item
        );

      })
      // state.data.folderList.files.forEach((item: any) => {
      //   item.isChecked = false;
      // });
      state.data.selectedFiles = [];
      // state.data.folderList.files = state.data.folderList.files.filter(
      //   (e: any) => e.uuid != data
      // );
      // if (data.uuid) {
      //   state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
      // }
      state.data.SuccessFulMessage = "Selected Files Deleted Successfully.";
      state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
    }
    function showShareModal() {
      // alert("Hello")
      state.data.showShareModal = !state.data.showShareModal;
    }

    function showDeleteSelectedModal() {
      // alert("<<< Seleted Selected SHOW >>>> ")
      state.data.showDeleteModal = !state.data.showDeleteModal;
    }
    function filterSelectedFiles(data: any) {
      if (!data.isChecked) {
        state.data.selectedFiles.push(data);
        // console.log("CHECK Data >> ", data)
      } else {
        state.data.selectedFiles = state.data.selectedFiles.filter(
          (e: any) => e.uuid != data.uuid
        );
      }
      console.log("CHECK Data >> ", state.data.selectedFiles);
    }
    function checkedSelctedFile(data: object) {
      filterSelectedFiles(data);
      // state.data.selectedFiles.push(data)
      // console.log("my drive files >>> ", state.data.selectedFiles)
    }
    function checkedSelctedFolder(data: any) {
      // state.data.folderList.folders = data
      console.log("Select >>>", data);
      // console.log("Select >>>", state.data.folderList.folders)
      // console.log("Selected >>> ", data)
      // const selectedFolder = [] as any
      //  if(data){
      //   data.forEach((item: any)=> {
      //     if(item.isChecked){
      //       selectedFolder.push(item)
      //     }
      //   })
      // }
      // console.log("CHECKED ONLY >>>", selectedFolder)
      // state.data.selectedFolders = data
    }
    function closeRenameFileModal(item: any) {
      state.data.folderList.files.filter((data: any, index: number) => {
        if (data.uuid === item.uuid) {
          console.log("FOUND RENAMED DATA >>>", data);
          state.data.folderList.files[index] = item;
        }
      });
    }
    function deleteSelectedFolder(data: object) {
      console.log("CHECK >>>", data);
      // state.data.folderList.folders.filter((data: any, index: number) => {
      //   if (data.uuid === item.uuid) {
      //     console.log("FOUND RENAMED DATA >>>", data);
      //     state.data.folderList.folders[index] = item;
      //   }
      // });
      // console.log("Deleted Folder >>> ", data)
      // state.data.folderList.folders
      state.data.folderList.folders = state.data.folderList.folders.filter(
        (e: any) => e != data
      );
    }
    function deleteSelectedFile(data: object) {
      state.data.folderList.files = state.data.folderList.files.filter(
        (e: any) => e != data
      );
    }
    function closeSuccessCustomModal() {
      state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
      $("#SuccessfulModalCustom").modal("hide");
    }
    function redirectToFileTagSettings() {
      router.push({
        name: "admin-drive-tag-settings",
      });
    }
    function redirectToDocument() {
      router.push({
        name: "admin-document-details",
      });
    }
    function showCreateFolder() {
      state.data.folderName = "";
      state.ui.error.errorList.createNewFolder = [];
      state.ui.error.hasError = false;
      $("#createFolder").modal("show");
    }
    function hideCreateFolder() {
      $("#createFolder").modal("hide");
    }
    function validateCreateNewFolder() {
      if (!state.data.folderName) {
        state.ui.error.errorList.createNewFolder.push(
          "Folder name is required."
        );
      }
      if (
        !state.ui.error.errorList.createNewFolder &&
        !state.ui.error.errorList.createNewFolder.length
      ) {
        state.ui.error.hasError = false;
      } else if (state.ui.error.errorList.createNewFolder.length != 0) {
        state.ui.error.hasError = true;
      }
    }
    // function searchFileTags() {
    //   state.ui.error.errorList.getFolderList = [];
    //   state.ui.error.hasError = false;
    //   if (state.ui.isPostLoading.createNewFolder) return false;
    //   const payLoad = {
    //     tag: state.data.searchByTag,
    //     // eslint-disable-next-line @typescript-eslint/camelcase
    //     folder_id: state.data.searchByTag,
    //   };
    //   state.ui.isPostLoading.createNewFolder = true;
    //   ethitransService
    //     .searchFileTags(payLoad)
    //     .then((res: any) => {
    //       // console.log("getFolderList Response Check:>>> ", res.data.data);
    //       if (res.data.data.folders && res.data.data.folders.length) {
    //         res.data.data.folders.forEach((folder: any) => {
    //           folder.isChecked = false;
    //           if (folder.size) {
    //             folder.convertedFileSize = common.formatBytes(
    //               parseInt(folder.size)
    //             );
    //           }
    //         });
    //       }
    //       if (res.data.data.files && res.data.data.files.length) {
    //         res.data.data.files.forEach((file: any) => {
    //           file.isChecked = false;
    //           if (file.filesize) {
    //             file.convertedFileSize = common.formatBytes(
    //               parseInt(file.filesize)
    //             );
    //           }
    //           if (file.filetype === "mov" || file.filetype === "MOV") {
    //             // eslint-disable-next-line @typescript-eslint/camelcase
    //             file.filetype = "mp4";
    //           }
    //         });
    //       }
    //       state.data.folderList = res.data.data ? res.data.data : {};
    //     })
    //     .catch((error: any) => {
    //       console.log(error);
    //       common
    //         .sdCatchErr(error, state.ui.error.errorList.getFolderList)
    //         .then((res: any) => {
    //           state.ui.error.hasError = true;
    //           for (const [key, value] of Object.entries(res)) {
    //             if (Array.isArray(value)) {
    //               state.ui.error.errorList.getFolderList.push(value[0]);
    //             }
    //           }
    //         });
    //     })
    //     .finally(() => {
    //       state.ui.isPostLoading.createNewFolder = false;
    //     });
    // }
    function createNewFolder() {
      state.ui.error.errorList.createNewFolder = [];
      state.ui.error.hasError = false;
      validateCreateNewFolder();
      if (
        state.ui.error.errorList.createNewFolder.length != 0 ||
        state.ui.error.hasError ||
        state.ui.isPostLoading.createNewFolder
      )
        return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        folder_id: "",
        name: state.data.folderName,
      };
      state.ui.isPostLoading.createNewFolder = true;
      ethitransService
        .createFolder(payLoad)
        .then((res: any) => {
          console.log("Project List Response Check:>>> ", res.data.data);
          res.data.data.isChecked = false;
          state.data.folderList.folders.unshift(res.data.data);
          hideCreateFolder();
          state.data.SuccessFulMessage = "Created Folder Successfully.";
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
          //  $("#SuccessfulModalCustom").modal("show");
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.createNewFolder)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.createNewFolder.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.createNewFolder = false;
        });
    }

    function getFolderList() {
      state.ui.error.errorList.getFolderList = [];
      state.ui.error.hasError = false;
      state.ui.isLoading.getFolderList = true;
      ethitransService
        .folderList(state.selectedStatus)
        .then((res: any) => {
          // console.log("getFolderList Response Check:>>> ", res.data.data);
          if (res.data.data.folders && res.data.data.folders.length) {
            res.data.data.folders.forEach((folder: any) => {
              folder.isChecked = false;
              if (folder.size) {
                folder.convertedFileSize = common.formatBytes(
                  parseInt(folder.size)
                );
              }
            });
          }
          if (res.data.data.files && res.data.data.files.length) {
            res.data.data.files.forEach((file: any) => {
              file.isChecked = false;
              if (file.filesize) {
                file.convertedFileSize = common.formatBytes(
                  parseInt(file.filesize)
                );
              }
              if (file.filetype === "mov" || file.filetype === "MOV") {
                // eslint-disable-next-line @typescript-eslint/camelcase
                file.filetype = "mp4";
              }
            });
          }
          state.data.folderList = res.data.data ? res.data.data : {};
          // toggleTourGuide();
          state.data.myDriveFileFolderLoad = true;
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.getFolderList)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.getFolderList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isLoading.getFolderList = false;
        });
    }
    function updateFileTag(data: any) {
      console.log("File Tag to My Drive Update >>>", data);
      getFolderList();
      // state.data.folderList.files.forEach((file: any) => {
      //   if (file.uuid === data.uuid) {
      //     console.log("CHECK >>>", file)
      //     file = data;
      //     console.log("CHECK FINAL>>>", file)
      //     // file_tag
      //   }
      // });
    }
    const isSearchByTagShort = computed(() => {
    return state.data.searchByTag.length < 2;
  });
    function searchByTag(data: any) {
      state.data.searchByTag = data;
    }
    watch(
      () => state.data.searchByTag,
      (newvalue, oldvalue) => {
        if (!newvalue) {
          getFolderList();
        }
      }
    );

    function closeRenameFolderModal(item: any) {
      state.data.folderList.folders.filter((data: any, index: number) => {
        if (data.uuid === item.uuid) {
          console.log("FOUND RENAMED DATA >>>", data);
          state.data.folderList.folders[index] = item;
        }
      });
    }
    function validateAddTagsToFile() {
      state.ui.error.errorList.getFolderList = [];
      state.ui.error.hasError = false;
    }
    function masterTagList() {
      const payLoad = {
        type: "enabled",
      };
      ethitransService
        .masterFileTagList(payLoad)
        .then((res: any) => {
          // console.log("masterTagList Check:>>> ", res.data.data);
          res.data.data.forEach((item: any) => {
            item.label = item.name;
            item.value = item.code;
          });
          state.data.tags.options = res.data.data ? res.data.data : [];
        })
        .catch((error: any) => {
          // common.sdCatchErr(error, state.errorList).then((res: any) => {
          //   state.isErrorStatus = true;
          //   // console.log("CHECK for Error >>>", res)
          //   for (const [key, value] of Object.entries(res)) {
          //     if (Array.isArray(value)) {
          //       state.errorList.push(value[0]);
          //     }
          //   }
          //   // if (res) {
          //   //   if (res.error) {
          //   //     console.log("CHECK for Error >>>", res.error[0])
          //   //     // state.errorList.push(res.email[0]);
          //   //     // state.errorList = [...state.errorList]
          //   //   }
          //   // }
          // });
        })
        .finally(() => {
          // state.postLoading = false;
        });
    }

    function addTagsToFile(data: any, attachment: any) {
      const tagIds = [] as any;
      console.log("CHECK FINAL TAG LIST>>> ", state.data.tags.options)
      //  state.data.imagesToUpload.forEach((data: any)=> {
      state.data.tags.options.forEach((item: any) => {
        console.log("Check master Tag >>>", item);
        //  console.log("Check the attachment  tag>>>", data.tag)
        attachment.tag.forEach((dataItem: any) => {
          console.log("Check attachment tag >>>", dataItem);

          //     // if(item.name === data){
          //     // console.log("check Ids ", item)

          if (item.name.toLowerCase() === dataItem.toLowerCase()) {
            tagIds.push(item.id);
          }
        });
      });
      // alert(" MY DRIVE CHECK");
      // })
      state.data.imagesToUpload = state.data.imagesToUpload.filter(
        (e: any) => e.base64 != attachment.base64
      );
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_attachment_id: "",
        // eslint-disable-next-line @typescript-eslint/camelcase
        file_id: data.id,
        // eslint-disable-next-line @typescript-eslint/camelcase
        master_file_tag_id: tagIds,
      };
      console.log("Check PayLoad >>> ", payLoad);
      state.ui.isPostLoading.addTagsToFile = false;
      ethitransService
        .createFileTags(payLoad)
        .then((res: any) => {
          console.log("addTagsToFile in MY Drive Directly:>>> ", res.data.data);
          getFolderList();
        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.ui.error.errorList.getFolderList)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.getFolderList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.addTagsToFile = false;
        });
    }
    function uploadAttachments(data: any) {
      console.log("Upload CHECK >>> ", data)
      state.ui.error.errorList.uploadAttachments = [];
      state.ui.error.hasError = false;
      if (
        state.ui.error.errorList.uploadAttachments.length != 0 ||
        state.ui.error.hasError ||
        data.isuploded
      )
        return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        folder_id: "",
        filename: data.fileName,
        extension: data.extension,
        file: data.base64,
      };
      data.isuploded = true;
      // state.ui.isPostLoading.uploadAttachments = true;
      ethitransService
        .fileUpload(payLoad)
        .then((res: any) => {
          masterTagList();
          data.isuploded = true;
          console.log("FIle upload", res.data.data);
          if (res.data.data.filesize) {
            res.data.data.convertedFileSize = common.formatBytes(
              parseInt(res.data.data.filesize)
            );
          }
          if (
            res.data.data.filetype === "mov" ||
            res.data.data.filetype === "MOV"
          ) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            res.data.data.filetype = "mp4";
          }
          state.data.folderList.files.unshift(res.data.data);
          addTagsToFile(res.data.data, data);

          if (state.data.imagesToUpload.length === 0) {
            //show successModal
            state.data.SuccessFulMessage = "Uploaded Successfully.";
            state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
          }
          // console.log("Project List Response Check:>>> ", res.data.data);
          // state.data.folderList.folders.unshift(res.data.data);
          // hideCreateFolder();
          // state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.uploadAttachments)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.uploadAttachments.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.uploadAttachments = false;
        });
    }
    function uploadImage() {
      state.data.imagesToUpload = state.data.imagesToUpload.filter(
        (e: any) => !e.duplicateFile
      );
      console.log("FINAL CHECK OM Jagri >>>> ", state.data.imagesToUpload)
      state.data.imagesToUpload.forEach((item: any) => {
        if (item.isvalidToUpload) {
          uploadAttachments(item);
        } else {
          state.data.imagesToUpload = state.data.imagesToUpload.filter((e: any) => e != item);
        }
      });
    }
    function removeSelectedImage(image: any) {
      // console.log("REMOVE", image)
      // if (state.postLoading) return false;
      state.data.imagesToUpload = state.data.imagesToUpload.filter(
        (e: any) => e != image
      );
    }
    function validateDuplicateImages(data: any) {
      return state.data.folderList.files.forEach((file: any) => {
        if (data.toLowerCase() === file.filename.toLowerCase()) {
          // console.log("file name ", file.filename);
          return true;
        } else {
          return false;
        }
      });
    }
    function imagesDropped(data: any) {
      if (data) {
        masterTagList();
      }
      state.data.imagesToUpload = data;
    }
    // eslint-disable-next-line vue/return-in-computed-property
    const imagesToUpload = computed(() => {
      return state.data.imagesToUpload;
    });
    // eslint-disable-next-line vue/return-in-computed-property
    const masterTags = computed(() => {
      const array: any = [];
      state.data.tags.options.forEach((item: any) => {
        array.push(item.name);
      });

      return array;
    });

    function readImage(file: any) {
      // console.log("CHECK IMAGE>>", file);
      // let data = "";
      const data = {} as any;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        // console.log("BIT BIT>>", e);
        // console.log("FILE TO UPLOAD >>> ", file.name.toLowerCase())
        const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
        const extensionLower = extension.toLowerCase();
        data.base64 = e.target.result;
        data.duplicateFile = false; // data.fileName = file.name;
        data.fileName = file.name.replaceAll(`.${extension}`, "");
        // let duplicateName = false;
        state.data.folderList.files.forEach((item: any) => {
          // console.log("TEST loop>>>", item.filename.toLowerCase());
          if (
            file.name.toLowerCase() === item.filename.toLowerCase() &&
            !file.duplicateFile
          ) {
            // console.log("FOUND MATCH WITH >>> ", item.filename.toLowerCase());
            data.duplicateFile = true;
            // console.log("FOUND MATCH STATUS >>> ", data.duplicateFile);
          }
          //   }
        });
        if (file.size > state.data.uploadMaxSize) {
          data.isValidToUpload = false;
        } else {
          data.isvalidToUpload = true;
        }
        // console.log("Check the File Status >>>", duplicateName);
        // console.log("Attachment Name Check >>>", data.fileName)
        data.extension = extensionLower;
        data.isuploded = false;
        data.tag = [] as any;
        data.size = common.formatBytes(parseInt(file.size));
        console.log("UPLOAD READY FILE >>>", data);
        state.data.imagesToUpload.push(data);
        // console.log("CHECK DATA FOR UPLOAD>>", data);
        // state.imageList.push(data);
      };
      reader.readAsDataURL(file);
      masterTagList();
    }
    const dropFiles = (event: any) => {
      const files = event.target.files || event.dataTransfer.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        readImage(file);
      }
    }
    function onDocumentChange(event: any) {
      const files = event.target.files || event.dataTransfer.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        // console.log("FILE CHECK >>>", file);
        // if(file.type)
        const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
        const extensionLower = extension.toLowerCase();

        readImage(file);

        // console.log("CHECK DATA WHEN UPLOADED>>", file);
        // console.log("Extension Check >>> ", extensionLower);
      }
    }
    function searchFolderList(data: any) {
      // console.log("CHeck Folder List >>>", data);
      state.data.folderList = data;
    }
    function searchErrorList(data: any) {
      console.log("CHeck Error List >>>", data);
      if (data) {
        state.ui.error.errorList.getFolderList = data;
      } else {
        state.ui.error.errorList.getFolderList = [];
      }
    }
    function searchHasError(data: any) {
      // console.log("CHeck has Error >>>", data);
      if (data) {
        state.ui.error.hasError = data;
      } else {
        state.ui.error.hasError = false;
      }
    }
    function masterTagListForValidation(data: boolean) {
      state.ui.error.errorList.getFolderList = [];
      state.ui.error.hasError = false;
      const payLoad = {
        type: data ? "enabled" : "disabled",
      };
      ethitransService
        .masterFileTagList(payLoad)
        .then((res: any) => {
          console.log("masterTagList Check:>>> ", res.data.data);
          // res.data.data.forEach((item: any) => {
          //   item.label = item.name;
          //   item.value = item.code;
          // })
          // state.data.tagListForValidation = res.data.data ? res.data.data : [];
          const disabledTagList: any = [];
          state.data.imagesToUpload.forEach((item: any) => {
            item.tag.forEach((tag: any) => {
              console.log("CHECK Tag >>", tag);
              res.data.data.forEach((data: any) => {
                console.log("CHECK THE API TAG >>>", data);

                if (tag.toLowerCase() === data.name.toLowerCase()) {
                  data.inProgress = false;
                  // console.log("tag values >>> ",item )
                  // console.log("tagListForValidation  values >>> ", data.name)
                  disabledTagList.push(data);
                }
              });
            });

            //
          });
          // console.log("LIST>>> ", disabledTagList);
          const uniqueTagList = Array.from(new Set(disabledTagList));
          // const tag: any = []
          state.data.disabledFileTagList = uniqueTagList ? uniqueTagList : [];

          if (
            state.data.disabledFileTagList &&
            !state.data.disabledFileTagList.length
          ) {
            state.data.showAddFileTag = true;
          }
          // state.data.disabledFileTagList.forEach((item: any)=> {
          // state.data.showAddFileTag = true

          // })
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.getFolderList)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.getFolderList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          // state.postLoading = false;
        });
    }
    function getReadyImageListToUpload(data: any) {
      console.log("READY IMAGES >>>", data)
      state.data.imagesToUpload = data
    }
    function latestEnabledTags(data: any) {
      // alert("HELLO");
      state.data.tags.options = data;
    }
    function addTag(tag: any) {
      // console.log("Check the New Tag >>>", tag);
      const payLoad = {
        name: tag,
      };
      // state.postLoading = true;
      ethitransService
        .createMasterFileTag(payLoad)
        .then((res: any) => {
          if (res.data.data && res.data.data.is_active === 1) {
            state.data.tags.options.push(res.data.data);
          } else {
            state.data.tags.options.push({ name: tag });
            masterTagListForValidation(false);
          }
        })
        .catch((error: any) => {
          console.log("Erorrs:>>> ", error);
        })
        .finally(() => {
          // state.postLoading = false;
        });
    }
    function enableSelectedFileTag(document: any) {
      console.log("Enable Doc >> ", document.id);
      if (document.enableStatus) return false;
      const payLoad = {
        id: document.id,
      };
      state.ui.isPostLoading.enableSelectedFileTag = true;
      document.inProgress = true;
      ethitransService
        .enableMasterFileTag(payLoad)
        .then((res: any) => {
          console.log("enable File Tag :>>> ", res.data.data);
          state.data.disabledFileTagList =
            state.data.disabledFileTagList.filter(
              (data: any) => data.id != document.id
            );
          masterTagList();
          // state.data.successFulMessage = "File Tag Enabled Successfully.";
          // state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.enableSelectedFileTag = false;
          document.inProgress = false;
        });
    }

    onMounted(() => {
      getFolderList();
    });

    return {
      state,
      dropFiles,
      imagesDropped,
      toggleTourGuide,
      closeDeleteMultipleModal,
      showDeleteSelectedModal,
      imagesToUpload,
      latestEnabledTags,
      getReadyImageListToUpload,
      enableSelectedFileTag,
      masterTagListForValidation,
      redirectToFileTagSettings,
      addTagsToFile,
      validateAddTagsToFile,
      updateFileTag,
      searchByTag,
      searchFolderList,
      searchErrorList,
      searchHasError,
      addTag,
      masterTags,
      myDrive,
      masterTagList,
      validateDuplicateImages,
      closeRenameFolderModal,
      getFolderList,
      redirectToDocument,
      hideCreateFolder,
      showCreateFolder,
      validateCreateNewFolder,
      createNewFolder,
      closeSuccessCustomModal,
      uploadAttachments,
      uploadImage,
      removeSelectedImage,
      readImage,
      onDocumentChange,
      deleteSelectedFolder,
      closeRenameFileModal,
      deleteSelectedFile,
      checkedSelctedFolder,
      checkedSelctedFile,
      filterSelectedFiles,
      showShareModal,
      closeShareModal,
      isSearchByTagShort,
    };
  },
});
