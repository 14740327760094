
import { defineComponent, onMounted, reactive, ref, onUnmounted } from "vue";
import SignaturePad from "signature_pad";
import VuePictureCropper, { cropper } from "vue-picture-cropper";
import { ethitransService } from "@/services/EthitransService";

export default defineComponent({
  name: "SignaturePadModal",
  components: {
    VuePictureCropper,
  },
  props: {
    showModal: Boolean,
    from: String,
  },
  setup(props, { emit }) {
    const wrapper = ref<HTMLElement | null>(null);
    const canvas = ref<HTMLCanvasElement | null>(null);
    let signaturePad: any | null = null;
    const containerRef = ref(null);
    const widthW = ref(window.innerWidth * 0.5 + 'px') as any;
    const heightW = ref(widthW.value * (0.4) + 'px') as any;
    const widthM = 300;
    const heightM = 500;

    const customModalStyle = ref({
      position: 'relative',
      top: '50%', // Align the top of the modal to the vertical center of the screen
      left: '50%', // Align the left side of the modal to the horizontal center of the screen
      transform: 'translate(-50%, -50%)', // Center the modal horizontally and vertically
      width: widthW.value,
      height: heightW.value,
      margin: '0.5rem',
      'pointer-events': 'none',
      'align-items': 'center',
      'min-height': `calc(100% - 3.5rem)` // Assuming header height is defined
    });
    const state = reactive({
      data: {
        uploadImageData: "" as any,
        newUploadImge: "" as any,
        cropImage: "" as any,
        uploadSignature: "" as any,
        cropSignature: "" as any,
        signatureFile: "" as any,
        isuplode: false,
        isEmpty: false,
        width: "" as any,
        height: "" as any,
      },
      ui: {
        error: {
          hasError: false,
          errorList: {
            createNewFolder: [] as any,
            getFolderList: [] as any,
          },
        },
        isLoading: {
          getFolderList: false,
        },
        isPostLoading: {
          createNewFolder: false,
        },
      },
    });

    function closeSignaturePadModal() {
      signaturePad?.clear();
      state.data.isuplode = false;
      state.data.uploadImageData = "";
      state.data.newUploadImge = "";
      state.data.uploadSignature = "";
      emit("close");
      $("#signaturePadModal").modal("hide");
      document.body.classList.remove('modal-open');

    }

    const resizeCanvas = () => {
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;

      if (canvas.value) {
        // Determine the canvas size based on the screen resolution
        const canvasWidth = screenWidth * 0.45; // Set canvas width to 90% of the screen width
        const canvasHeight = canvasWidth * (0.4); // Maintain a 16:9 aspect ratio

        // Set the canvas dimensions
        canvas.value.width = canvasWidth;
        canvas.value.height = canvasHeight;
        signaturePad?.clear();
      }
    };

    const initializeSignaturePad = () => {
      if (canvas.value) {
        signaturePad = new SignaturePad(canvas.value, {
          backgroundColor: "rgb(255, 255, 255)",
        });
        resizeCanvas();
      }
    };

    function clearCrop() {
      if (!cropper) return;
      cropper.clear();
    }
    const clearSignature = () => {
      signaturePad?.clear();
      state.data.uploadImageData = "";
      state.data.newUploadImge = "";
      state.data.uploadSignature = "";
      state.data.cropSignature = "";
    };

    const undoSignature = () => {
      const data = signaturePad?.toData();
      if (data) {
        data.pop();
        signaturePad?.fromData(data);
      }
    };

    const changePenColor = () => {
      const r = Math.round(Math.random() * 255);
      const g = Math.round(Math.random() * 255);
      const b = Math.round(Math.random() * 255);
      const color = `rgb(${r},${g},${b})`;
      if (signaturePad) {
        signaturePad.penColor = color;
      }
    };

    // const saveSignature = (type: string) => {
    //   if (!signaturePad?.isEmpty()) {
    //     const dataURL = signaturePad?.toDataURL(type === 'jpg' ? 'image/jpeg' : `image/${type}`);
    //     if (dataURL) {
    //       download(dataURL, `signature.${type}`);
    //     }
    //   } else {
    //     alert('Please provide a signature first.');
    //   }
    // };
    const dataURLToBlob = (dataURL: string) => {
      const parts = dataURL.split(";base64,");
      const contentType = parts[0].split(":")[1];
      const raw = atob(parts[1]);
      const rawLength = raw.length;
      const uInt8Array = new Uint8Array(rawLength);
      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }
      return new Blob([uInt8Array], { type: contentType });
    };

    const download = (dataURL: string, filename: string) => {
      const blob = dataURLToBlob(dataURL);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    };

    // const savePNG = () => saveSignature('png');
    // const saveJPG = () => saveSignature('jpg');
    // const saveSVG = () => saveSignature('svg+xml');

    function readImage(file: any) {
      const data = {} as any;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const extension = file.name.substring(file.naheighte = file.name);
        data.fileName = file.name.replaceAll(`.${extension}`, "");
        data.base64 = e.target.result;
        // data.extension = extensionLower;
        state.data.uploadImageData = data;
        state.data.newUploadImge = data.base64;
      };
      reader.readAsDataURL(file);
    }

    function onDocumentChange(event: any) {
      signaturePad?.clear();
      state.data.isuplode = true;
      const files = event.target.files || event.dataTransfer.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        console.log("FILE CHECK >>>", file);
        // if(file.type)
        const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
        const extensionLower = extension.toLowerCase();
        readImage(file);
      }
    }

    function saveSignature() {
      state.data.cropSignature = cropper?.getDataURL();
      state.data.uploadSignature = cropper?.getDataURL();
    }

    // function clearSignature() {
    //   state.data.cropSignature = "";
    //   signaturePad = new SignaturePad(canvas.value);
    //   signaturePad.clear();
    //   cropper?.clear();
    //   if (!cropper) return;
    // }

    /**
     * Reset the default cropping area
     */
    function resetCrop() {
      if (!cropper) return;
      cropper.reset();
    }

    function uploadUSerSignature() {
      state.data.isEmpty = false;
      if (!signaturePad.isEmpty() && signaturePad.toData()) {
        state.data.uploadSignature = signaturePad.toDataURL();
      } else if (cropper?.getDataURL()) {
        state.data.uploadSignature = cropper?.getDataURL();
      }
      if (!state.data.uploadSignature) {
        state.data.isEmpty = true;
        return;
      }
      const payLoad = {
        signature: state.data.uploadSignature,
      };
      if (props.from == 'profile' || !state.data.signatureFile) {
        ethitransService
          .uploadUSerSignature(payLoad)
          .then((res: any) => {
            emit("uploadSignature", state.data.uploadSignature);
            closeSignaturePadModal();
          })
          .catch((error: any) => {
            console.log(error);
          })
          .finally(() => {
            // state.ui.isPostLoading.uploadAttachments = false;
          });
      } else {
        emit("uploadSignature", state.data.uploadSignature);
        closeSignaturePadModal();
      }

    }

    function userSignature() {
      ethitransService
        .userSignature()
        .then((res: any) => {
          state.data.signatureFile = res.data.data.signature;
        })
        .catch((error: any) => {
          //   common
        })
        .finally(() => {
          //   state.ui.isLoading.userStatus = true;
        });
    }
    onMounted(() => {
      if (props.showModal) {
        $("#signaturePadModal").modal("show");
      }
      widthW.value = window.innerWidth * 0.5 + 'px';
      heightW.value = widthW.value * (0.4) + 'px';
      initializeSignaturePad();
      window.addEventListener('resize', resizeCanvas); // Listen for window resize events
      userSignature();
    });

    return {
      state,
      closeSignaturePadModal,
      clearSignature,
      undoSignature,
      changePenColor,
      wrapper,
      canvas,
      onDocumentChange,
      readImage,
      saveSignature,
      clearCrop,
      resetCrop,
      uploadUSerSignature,
      userSignature,
      customModalStyle,
      widthW,
      heightW
    };
  },
});
