<template>
  <div>
    <input class="form-control" id="email" name="email" v-model="inputValue" @input="onInputChange"
      @keydown="limitInput" @blur="validateInput" @paste="handlePaste" placeholder="Enter email or mobile number"
      type="text" :disabled="disabled" />
    <!-- <p v-if="error" role="alert" aria-live="assertive">{{ error }}</p> -->
  </div>
</template>

<script>
import { ref, watch } from 'vue';

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    clear: {
      type: Boolean,
      default: false, // Default is false, meaning no clearing initially
    }
  },
  emits: ['update-input', 'emit-error', 'clear-input'], // Added clear-input event
  setup(props, { emit }) {
    const inputValue = ref('');
    const error = ref('');

    const formatMobileNumber = (number) => {
      return `+1 (${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(6)}`;
    };



    // // Watch the clear prop to clear the input value when it's set to true
    // watch(() => props.clear, (newValue) => {
    //   if (newValue) {
    //     inputValue.value = ''; // Clear the input value
    //   }
    // });

    // Watch the clear prop to clear the input value when it's set to true
    watch(() => props.clear, (newValue) => {
      if (newValue) {
        inputValue.value = ''; // Clear the input value
        emit('validation-result', false); // Emit false when cleared
      }
    });
    // const handlePaste = (event) => {
    //   // Prevent the default paste behavior
    //   event.preventDefault();

    //   // Get the pasted data from clipboard
    //   let pastedData = event.clipboardData.getData('Text').trim();

    //   // Check if the pasted data starts with +1 or 1 and remove it
    //   if (pastedData.startsWith('+1')) {
    //     pastedData = pastedData.replace(/^\+1\s*/, ''); // Remove +1 and any trailing spaces
    //   } else if (pastedData.startsWith('1')) {
    //     pastedData = pastedData.replace(/^1\s*/, ''); // Remove 1 and any trailing spaces
    //   }

    //   // Remove any non-digit characters from the pasted data
    //   pastedData = pastedData.replace(/\D/g, '');

    //   // If the remaining data is a valid 10-digit number, format it
    //   if (/^\d{10}$/.test(pastedData)) {
    //     inputValue.value = formatMobileNumber(pastedData);
    //     emit('update-input', inputValue.value); // Emit the formatted value
    //   } else {
    //     // If the pasted number is not valid, set an error
    //     error.value = 'Invalid input. Please enter a valid 10-digit number.';
    //     emit('emit-error', error.value);
    //   }
    // };
    // const handlePaste = (event) => {
    //   event.preventDefault();
    //   let pastedData = event.clipboardData.getData('Text').trim();

    //   if (pastedData.startsWith('+1')) {
    //     pastedData = pastedData.replace(/^\+1\s*/, '');
    //   } else if (pastedData.startsWith('1')) {
    //     pastedData = pastedData.replace(/^1\s*/, '');
    //   }

    //   pastedData = pastedData.replace(/\D/g, '');

    //   if (/^\d{10}$/.test(pastedData)) {
    //     inputValue.value = formatMobileNumber(pastedData);
    //     emit('update-input', inputValue.value); // Emit the formatted value
    //     emit('validation-result', true); // Emit validation result as true
    //   } else {
    //     error.value = 'Invalid input. Please enter a valid 10-digit number.';
    //     emit('emit-error', error.value);
    //     emit('validation-result', false); // Emit validation result as false
    //   }
    // };
    const handlePaste = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'a') {
        return; // Do nothing, let the default Ctrl + A action happen
      }

      event.preventDefault(); // Prevent default paste behavior
      let pastedData = event.clipboardData.getData('Text').trim();

      // Check if pasted data is an email
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (emailPattern.test(pastedData)) {
        // If valid email, update the input value and emit success
        inputValue.value = pastedData;
        emit('update-input', inputValue.value);
        emit('validation-result', true); // Emit true for valid email
        return; // Exit since it's a valid email
      }

      // If pasted data starts with '+1' or '1', remove the country code
      if (pastedData.startsWith('+1')) {
        pastedData = pastedData.replace(/^\+1\s*/, '');
      } else if (pastedData.startsWith('1')) {
        pastedData = pastedData.replace(/^1\s*/, '');
      }

      // Remove any non-digit characters from the pasted data for phone numbers
      pastedData = pastedData.replace(/\D/g, '');

      // Validate if the remaining data is a valid 10-digit phone number
      if (/^\d{10}$/.test(pastedData)) {
        inputValue.value = formatMobileNumber(pastedData);
        emit('update-input', inputValue.value); // Emit formatted number
        emit('validation-result', true); // Emit true for valid phone number
      } else {
        // If neither valid phone nor email, set an error
        error.value = 'Invalid input. Please enter a valid email or 10-digit mobile number.';
        emit('emit-error', error.value);
        emit('validation-result', false); // Emit false for invalid input
      }
    };




    // const validateInput = () => {
    //   // Only validate if there's a value in inputValue
    //   if (inputValue.value.trim() === '') {
    //     error.value = ''; // Reset error if input is empty
    //     return; // Exit the function, skipping validation
    //   }

    //   const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    //   const mobilePattern = /^\+1 \(\d{3}\) \d{3}-\d{4}$/;

    //   // Reset state
    //   error.value = '';

    //   // Check for valid email
    //   if (emailPattern.test(inputValue.value)) {
    //     emit('update-input', inputValue.value); // Emit input value to parent
    //   }
    //   // Check for exactly 10 digits for mobile
    //   else if (/^\d{10}$/.test(inputValue.value)) {
    //     inputValue.value = formatMobileNumber(inputValue.value);
    //     emit('update-input', inputValue.value); // Emit formatted mobile number
    //   }
    //   // Check if formatted mobile matches the expected pattern
    //   else if (mobilePattern.test(inputValue.value)) {
    //     emit('update-input', inputValue.value); // Emit formatted mobile number
    //   }
    //   // Invalid input
    //   else {
    //     error.value = 'Invalid input. Please enter a valid email or a mobile number with exactly 10 digits.';
    //     emit('emit-error', error.value); // Emit error message to parent
    //   }
    // };
    const validateInput = () => {
      if (inputValue.value.length === 0) {
        emit('clear-input'); // Emit clear-input event to parent
        }
      if (inputValue.value.trim() === '') {
        error.value = ''; // Reset error if input is empty
        emit('update-input', '');
        emit('emit-error', '');
        emit('validation-result', false); // Emit false if empty
        return;
      }

      // const emailPattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
      const emailPattern = /^[\w.-]+@([\w-]+\.)+[\w-]{2,}$/;
      const mobilePattern = /^\+1 \(\d{3}\) \d{3}-\d{4}$/;

      error.value = '';

      if (emailPattern.test(inputValue.value)) {
        // emit('update-input', inputValue.value);
        // emit('validation-result', true); // Emit true if valid email
      } else if (/^\d{10}$/.test(inputValue.value)) {
        inputValue.value = formatMobileNumber(inputValue.value);
        emit('update-input', inputValue.value);
        // emit('validation-result', true); // Emit true if valid unformatted mobile
      } 
      else if (mobilePattern.test(inputValue.value)) {
        emit('update-input', inputValue.value);
        // emit('validation-result', true); // Emit true if valid formatted mobile
      } 
      else {
        // error.value = 'Invalid input. Please enter a valid email or a mobile number with exactly 10 digits.';
        // emit('emit-error', error.value);
        // emit('validation-result', false); // Emit false if invalid
      }
    };


    // Emit the data on each key press via @input
    const onInputChange = () => {
      emit('update-input', inputValue.value); // Emit the input value on each key press
      validateInput(); // Validate the input on each change
    };


    // const limitInput = (event) => {
    //   const allowedKeys = ['Backspace', 'Delete', 'Tab', 'ArrowLeft', 'ArrowRight'];

    //   // Allow input for email characters
    //   const isNumberKey = /^[0-9]$/.test(event.key);

    //   // Emit clear-input event if input is empty after backspace or delete
    //   if (event.key === 'Backspace' || event.key === 'Delete') {
    //     // if (inputValue.value.length === 0) {
    //     emit('clear-input'); // Emit clear-input event to parent
    //     // }
    //   }

    //   // If the input is mobile number format and has reached 10 digits, prevent further input
    //   if (inputValue.value.includes('+1') && isNumberKey) {
    //     if (inputValue.value.replace(/[^\d]/g, '').length >= 10) {
    //       event.preventDefault(); // Prevent further number input
    //     }
    //   }
    // };
    // const limitInput = (event) => {
    //   const allowedKeys = ['Backspace', 'Delete', 'Tab', 'ArrowLeft', 'ArrowRight'];

    //   // Allow control keys like backspace, delete, etc.
    //   if (allowedKeys.includes(event.key)) {
    //     return;
    //   }

    //   // Prevent typing alphabets or other non-digit characters
    //   if (!/^[0-9]$/.test(event.key)) {
    //     event.preventDefault();
    //     return;
    //   }

    //   // Handle the case for mobile number format ('+1 (xxx) xxx-xxxx')
    //   const numericValue = inputValue.value.replace(/\D/g, ''); // Strip non-numeric characters

    //   // Prevent entering more than 10 digits
    //   if (numericValue.length >= 11) {
    //     event.preventDefault(); // Prevent further number input after 10 digits
    //   }
    // };
    const limitInput = (event) => {
      // const allowedKeys = ['Backspace', 'Delete', 'Tab', 'ArrowLeft', 'ArrowRight'];

      // // Allow control keys like backspace, delete, etc.
      // if (allowedKeys.includes(event.key)) {
      //   return;
      // }

      // Strip non-numeric characters to see if the input looks like a phone number
      const numericValue = inputValue.value.replace(/\D/g, '');

      //  Emit clear-input event if input is empty after backspace or delete
      if (event.key === 'Backspace' || event.key === 'Delete') {
        if (inputValue.value.length === 0) {
          // alert("INSIDE")
        emit('clear-input'); // Emit clear-input event to parent
        }
      }

      // // If it's a phone number (contains numbers), prevent entering non-digit characters
      // if (numericValue.length > 0 && !/^[0-9]$/.test(event.key)) {
      //   event.preventDefault();
      // }

      // Prevent entering more than 10 digits for phone numbers
      if (numericValue.length >= 11 && /^[0-9]$/.test(event.key)) {
        event.preventDefault(); // Prevent further number input after 10 digits
      }
    };


    return {
      inputValue,
      error,
      onInputChange,
      validateInput,
      limitInput,
      handlePaste,
    };
  },
};
</script>
