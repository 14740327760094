
import { defineComponent, reactive, onMounted } from "vue";
import { config } from "@/Config";
import { ethitransService } from "@/services/EthitransService";
import { common } from "@/common/services/Common";
import ErrorList from "@/components/commonDesign/ErrorList.vue";

export default defineComponent({
    name: "MobileVerification",
    components: {
        ErrorList,
    },
    props: {
        showModal: Boolean
    },
    setup(props, { emit }) {
        const state = reactive({
            userInfo: '' as any,
            countryCodeList: [] as any,
            emitData: "" as any,
            data: {
                timeCount: 130,
                imageBaseUrl: "",
                phone: {
                    showStatus: false,
                    countryCode: "",
                    number: "",
                    code: "+1",
                    mobileCode: "+1",
                    otp: "",
                    isVerify: false,
                    isValid: false,
                    isOtp: false,
                },
                error: {
                    hasError: false,
                    erorList: [] as any,
                },
            },
            ui: {
                inputOtp: false,
                mobileNumberRequired: true,
                sendOtpButton: true,
                verifyButton: true,
                isLoading: {
                    masterOccupationList: false,
                },
                isPostLoading: {
                    saveEditedProfile: false,
                    sendOtp: false,
                },
            },
        });
        function getCountryCodeList() {
            const payLoad = {};
            ethitransService
                .getCountryCodeList(payLoad)
                .then((res: any) => {
                    state.countryCodeList = res.data.data ? res.data.data : [];
                })
                .catch((error: any) => {
                    console.log(error);
                })
                .finally(() => {
                    // state.ui.postLoading.getprojectList = false;
                });
        }

        function countDownTimer() {

            const interval = setInterval(() => {
                if (state.data.timeCount === 0) {
                    state.ui.sendOtpButton = true;
                    state.ui.inputOtp = false;
                    clearInterval(interval);
                    state.data.timeCount = 130;

                } else {
                    state.data.timeCount--;
                }
            }, 1000);
        }

        function sendOtp() {
            state.data.phone.isValid = false;
            if (!state.data.phone.number) {
                state.data.phone.isValid = true
                return
            }
            const payLoad = {
                // eslint-disable-next-line @typescript-eslint/camelcase
                mobile_number: state.data.phone.number,
                // eslint-disable-next-line @typescript-eslint/camelcase
                dial_code_mobile: state.data.phone.mobileCode,
            };
            state.ui.verifyButton = false;
            state.ui.isPostLoading.sendOtp = true;
            ethitransService
                .sendOtp(payLoad)
                .then((res: any) => {
                    state.countryCodeList = res.data.data ? res.data.data : [];
                    state.ui.inputOtp = true;
                    state.ui.sendOtpButton = false;
                    getCountryCodeList();
                    countDownTimer();
                })
                .catch((error: any) => {
                    common
                        .sdCatchErr(error, state.data.error.erorList)
                        .then((res: any) => {
                            state.data.error.hasError = true;
                            for (const [key, value] of Object.entries(res)) {
                                if (Array.isArray(value)) {
                                    state.data.error.erorList.push(value[0]);
                                }
                            }
                        });

                })

                .finally(() => {
                    state.ui.verifyButton = true;
                    state.ui.isPostLoading.sendOtp = false;
                });
        }

        function closeModel() {
            emit("closeModal",state.emitData);
            $("#verifyModal").modal("hide");
        }

        function loadProfileDetails() {
            ethitransService
                .userProfileDetails()
                .then((res: any) => {
                    state.emitData = res.data.data;
                    localStorage.setItem(
                        "current_user_info",
                        JSON.stringify(res.data.data)
                    );
                })
                .catch((error: any) => {
                    // common
                })
                .finally(() => {
                    //   state.ui.isLoading.loadProfileDetails = false;
                });
        }
        function verifyMobile() {
            state.data.phone.isOtp = false;
            if (!state.data.phone.mobileCode) {
                state.data.phone.isOtp = true;
                return;
            }
            // state.ui.error.hasError = false;
            const payLoad = {
                // eslint-disable-next-line @typescript-eslint/camelcase
                mobile_number: state.data.phone.number,
                // eslint-disable-next-line @typescript-eslint/camelcase
                dial_code_mobile: state.data.phone.mobileCode,
                otp: state.data.phone.otp,
            };
            ethitransService
                .verifyMobile(payLoad)
                .then((res: any) => {
                    state.countryCodeList = res.data.data ? res.data.data : [];
                    // state.data.phone.code = state.data.phone.code;
                    getCountryCodeList();
                    if (res.data.data.is_verify == true) {
                        state.data.phone.isVerify = true;
                        state.ui.inputOtp = false;
                        state.ui.sendOtpButton = true;
                        state.data.error.hasError = false;
                        state.data.phone.otp = "";
                        loadProfileDetails();
                        closeModel()
                    } else {
                        state.data.phone.isVerify = false;
                        state.data.error.erorList = [];
                        state.data.error.hasError = true;
                        state.data.error.erorList.push("OTP didnot match..");
                    }
                })
                .catch((error: any) => {
                    console.log(error);
                })
                .finally(() => {
                    // state.ui.postLoading.getprojectList = false;
                });
        }



        onMounted(() => {
            state.data.imageBaseUrl = config.imageBaseUrl;
            const userInfo: any = localStorage.getItem("current_user_info");
            state.userInfo = JSON.parse(userInfo);
            state.data.phone.number = state.userInfo.mobile_number;
            getCountryCodeList();
        });
        return {
            state,
            getCountryCodeList,
            countDownTimer,
            sendOtp,
            verifyMobile,
            closeModel,
            loadProfileDetails
        };
    },
});
