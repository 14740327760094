
import { defineComponent, reactive, onMounted, computed, watch } from "vue";
import { useRouter } from "vue-router";
import ErrorList from "@/components/commonDesign/ErrorList.vue";
import { common } from "@/common/services/Common";
import { ethitransService } from "@/services/EthitransService";

import TitleComponent from "@/components/formComponents/TitleComponent.vue";
import TextBoxComponent from "@/components/formComponents/TextBoxComponent.vue";
// import TextAreaComponent from "@/components/formComponents/TextAreaComponent.vue";
import RadioButtonComponent from "@/components/formComponents/RadioButtonComponent.vue";
import SelectOption from "@/components/formComponents/SelectOption.vue";
import CheckBox from "@/components/formComponents/CheckBox.vue";
import TextArea from "@/components/formComponents/TextArea.vue";


// import ErrorList from "@/components/commonDesign/ErrorList.vue";
// import $ from "jquery";
import VPagination from "vue3-pagination";
import "vue3-pagination/dist/vue3-pagination.css";

import { useStore } from "vuex";
import introJs from "intro.js";

// import AppTour from "@/components/modal/AppTour.vue";

export default defineComponent({
  name: "TemplateSearch",
  components: {
    // AppTour,
    TitleComponent,
    TextBoxComponent,
    RadioButtonComponent,
    SelectOption,
    CheckBox,
    TextArea,
    ErrorList,
    VPagination,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const state = reactive({
      userList: [] as any,
      newFormData: {} as any,
      formData: {} as any,
      channelListForSendOption: [] as any,
      userListForSendOption: [] as any,
      projectList: [] as any,
      invite: {
        users: [] as any,
      },
      existingMember: {} as any,
      item: {
        hasError: false,
        sendOption: {
          project: {} as any,
          channel: {} as any,
        },
        error: {
          erorListSendForm: [] as any,
        },
      },
      data: {
        templateLoad: false,
        clientDocuments: [] as any,
        userList: [] as any,
        tagList: [] as any,
        templateResponse: {} as any,
        clientDocumentRetrieveWorkflowLink: {} as any,
        privacyList: [
          {
            name: "Private to this channel",
            code: "channel",
            value: "private to this channel",
          },
          {
            name: "Share within this project",
            code: "project",
            value: "share within this project",
          },
          { name: "Share to Public", code: "public", value: "share to public" },
        ],
        privacy: {
          name: "Private to this channel",
          value: "private to this channel",
          code: "channel",
        },
        selectedEdocForm: {} as any,
        additionalMessage: '',
        name: "" as any,
        email: "" as any,
        activeElementIndex: 0,
        elementList: [] as any,
        formList: {} as any,
        sendToUserStatus: false,
        filter: {
          formName: "",
          type: "all",
          projectId: 0,
          channelId: 0,
          sortBy: -1,
          title: 1,
          selected: { name: "Newest First", id: -1 } as any,
          tag:"all" as any,
        },
        paging: {
          getAccountFormList: {
            perPage: 10,
            totalDocs: 0,
            totalPages: 0,
            currentPage: 1,
          },
        },
      },
      ui: {
        hsErrorStatus: false,
        errorList: [] as any,
        validationList: [] as any,
        isLoading: {
          clientDocumentList: false,
          getprojectList: false,
          clientDocumentRetrieveWorkflow: false,
        },
        isPostLoading: {
          formMessage: false,
          getUserListByProjectAndChannel: false,
          getprojectList: false,
          createFormTemplate: false,
          sendDigitizedForm: false,
        },
        postLoading: {
          getAccountFormRetrieveById: false,
          getAccountFormList: false,
          getChannelByProjectForSendOption: false,
        },
      },
    });
    function toggleTourGuide() {
      if (store.state.showTourGuide && !state.data.templateLoad) {
        introJs()
          .setOptions({
            steps: [
              {
                title: "Welcome",
                // intro: "Welcome to Convorally! 👋 ",
                intro:
                  "Welcome to Convorally! 👋 <iframe width='426' height='240' src='https://www.youtube.com/embed/G8vkPCn2bLs' title='Slice Text in Photoshop' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>",
              },
              {
                title: "Search",
                element: document.getElementById(
                  "searchTemplateByText"
                ) as HTMLInputElement,
                intro: "Search Form By Text.",
              },
              {
                title: "Popular Tags",
                element: document.getElementById(
                  "popularTagsForTemplateSearch"
                ) as HTMLInputElement,
                intro: "Frequently used tags shown here",
              },
              {
                title: "Display Form",
                element: document.getElementById(
                  "displayFormTemplate"
                ) as HTMLInputElement,
                intro: "Display Forms by All, Sent by Me or Sent to me.",
              },
              {
                title: "Form List",
                element: document.getElementById(
                  "displayFormTemplateSearch"
                ) as HTMLInputElement,
                intro: "Form List here.",
              },
             
            ],
          })
          .start();
      }
    }
    watch(
      // () => watchTour,
      () => store.state.takAtour,
      (newvalue, oldvalue) => {
        if (newvalue === true) {
          console.log("check >>>", newvalue)
          toggleTourGuide();
        }
      }
    );
    // eslint-disable-next-line vue/return-in-computed-property
    const formPrivacyMessage = computed(() => {
      if (state.data.privacy.value === "private to this channel") {
        return "This form will remain private to this channel. Form will not be visible to other channels of this project.";
      } else if (
        state.data.privacy.value === "share within this project"
      ) {
        return " This form will be shared in this project so that other channels of this project can utilize this form, but will not be shared with other projects.";
      } else {
        return "This form will be shared to public.";
      }
    });
    
    const templateList = computed(() => {
      if (state.data.formList.docs && state.data.formList.docs.length) {
        return state.data.formList.docs.filter((form: any) => {
          // if (form.title) {
          //   return form.title.toLowerCase().match(state.searchBy.toLowerCase());
          // }
          return form;
        });
      } else {
        return [];
      }
    });

    function formatDate(dateString: string) {
      return common.formatDateTimeWithTimeFormat(dateString);
    }

    function getAccountFormList() {
      // const projectIds = [] as any;

      // state.projectList.forEach((item: any) => {
      //   projectIds.push(item.id);
      // });
      // const channelIds = [] as any;
      // state.allChannelList.forEach((data: any) => {
      //   // console.log("ITEM CHECK:>>>>>", data.id);
      //   channelIds.push(data.id);
      // });
      // state.filter.selected
      console.log("SELECTED SORT BY : >>>>", state.data.filter.selected);
      console.log("dat tag",state.data.filter.tag);
      if (
        state.data.filter.selected.name === "Newest First" ||
        state.data.filter.selected.name === "Oldest First"
      ) {
        state.data.filter.sortBy = state.data.filter.selected.id;
      } else {
        state.data.filter.title = state.data.filter.selected.id;
      }
      console.log("SORT : >>>>", state.data.filter.sortBy);
      console.log("TITLE : >>>>", state.data.filter.title);
      if (
        state.data.filter.selected.name === "Newest First" ||
        state.data.filter.selected.name === "Oldest First"
      ) {
        const payLoad = {
          search: {
            // eslint-disable-next-line @typescript-eslint/camelcase
            form_name: state.data.filter.formName,
          },
          tag:state.data.filter.tag,
          filter: {
            type: state.data.filter.type, //options can be “sent_to_me” or “sent_by_me” or “all”
            email: state.data.email,
          },
          // eslint-disable-next-line @typescript-eslint/camelcase
          sort_by: {
            title: 1,
          },
          paging: {
            // eslint-disable-next-line @typescript-eslint/camelcase
            per_page: state.data.paging.getAccountFormList.perPage,
            // eslint-disable-next-line @typescript-eslint/camelcase
            current_page: state.data.paging.getAccountFormList.currentPage,
          },

          //  search: {
          //   // eslint-disable-next-line @typescript-eslint/camelcase
          //   form_name: state.searchBy,
          // },
          // data.filter: {
          //   type: state.data.filter.type,
          //   email: state.data.email,
          // },
          // // eslint-disable-next-line @typescript-eslint/camelcase
          // sort_by: {
          //   // eslint-disable-next-line @typescript-eslint/camelcase
          //   created_date: state.data.filter.sortBy,
          // },
          // paging: {
          //   // eslint-disable-next-line @typescript-eslint/camelcase
          //   per_page: state.item.paging.getAccountFormList.perPage,
          //   // eslint-disable-next-line @typescript-eslint/camelcase
          //   current_page: state.item.paging.getAccountFormList.currentPage,
          // },

          // search: {
          //   // eslint-disable-next-line @typescript-eslint/camelcase
          //   project_id: state.data.filter.projectId,
          //   // eslint-disable-next-line @typescript-eslint/camelcase
          //   channel_id: state.data.filter.channelId,
          //   // eslint-disable-next-line @typescript-eslint/camelcase
          //   project_ids: projectIds,
          //   // eslint-disable-next-line @typescript-eslint/camelcase
          //   channel_ids: channelIds,
          //   email: state.data.email,
          //   // eslint-disable-next-line @typescript-eslint/camelcase
          //   form_name: state.searchBy,
          // },
          // // eslint-disable-next-line @typescript-eslint/camelcase
          // sort_by: {
          //   // state.data.filter.sortBy
          //   // "owner.created_by.name": state.name,
          //   // eslint-disable-next-line @typescript-eslint/camelcase
          //   created_date: state.data.filter.sortBy,
          // },
          // paging: {
          //   // eslint-disable-next-line @typescript-eslint/camelcase
          //   per_page: state.data.paging.getAccountFormList.perPage,
          //   // eslint-disable-next-line @typescript-eslint/camelcase
          //   current_page: state.data.paging.getAccountFormList.currentPage,
          // },
        };
        // console.log("FINAL Payload", payLoad)
        state.ui.postLoading.getAccountFormList = true;
        ethitransService
          .templateSearch(payLoad)
          .then((res: any) => {
            console.log("accountFormListSearchList Response : >>> ", res.data);
            state.data.formList = res.data ? res.data : {};
            state.data.tagList = res.data.filteredTags.docs;

            if(res.data.totalPages){
              state.data.paging.getAccountFormList.totalPages = res.data.totalPages;
            }
            if(res.data.totalDocs){
              state.data.paging.getAccountFormList.totalDocs = res.data.totalDocs;
            }
            // res.data.projects;
            // getChannelList(1);
            // toggleTourGuide();
            state.data.templateLoad = true;
          })
          .catch((error: any) => {
            console.log(error);
          })
          .finally(() => {
            state.ui.postLoading.getAccountFormList = false;
          });
      } else {
        const payLoad = {
          // search: {
          //   // eslint-disable-next-line @typescript-eslint/camelcase
          //   form_name: state.searchBy,
          // },
          // data.filter: {
          //   type: state.data.filter.type,
          //   email: state.data.email,
          // },
          // // eslint-disable-next-line @typescript-eslint/camelcase
          // sort_by: {
          //   title: state.data.filter.title,
          // },
          // paging: {
          //   // eslint-disable-next-line @typescript-eslint/camelcase
          //   per_page: state.data.paging.getAccountFormList.perPage,
          //   // eslint-disable-next-line @typescript-eslint/camelcase
          //   current_page: state.data.paging.getAccountFormList.currentPage,
          // },
          // search: {
          //   // eslint-disable-next-line @typescript-eslint/camelcase
          //   project_id: state.data.filter.projectId,
          //   // eslint-disable-next-line @typescript-eslint/camelcase
          //   channel_id: state.data.filter.channelId,
          //   // eslint-disable-next-line @typescript-eslint/camelcase
          //   project_ids: projectIds,
          //   // eslint-disable-next-line @typescript-eslint/camelcase
          //   channel_ids: channelIds,
          //   email: state.data.email,
          //   // eslint-disable-next-line @typescript-eslint/camelcase
          //   form_name: state.searchBy,
          // },
          // // eslint-disable-next-line @typescript-eslint/camelcase
          // sort_by: {
          //   // state.data.filter.sortBy
          //   // "owner.created_by.name": state.name,
          //   title: state.data.filter.title,
          // },
          // paging: {
          //   // eslint-disable-next-line @typescript-eslint/camelcase
          //   per_page: state.data.paging.getAccountFormList.perPage,
          //   // eslint-disable-next-line @typescript-eslint/camelcase
          //   current_page: state.data.paging.getAccountFormList.currentPage,
          // },
        };
        // console.log("FINAL Payload", payLoad)
        state.ui.postLoading.getAccountFormList = true;
        ethitransService
          .accountFormListSearchList(payLoad)
          .then((res: any) => {
            // console.log("accountFormListSearchList Response : >>> ", res.data);
            state.data.formList = res.data ? res.data : {};
            if(res.data.totalPages){
              state.data.paging.getAccountFormList.totalPages = res.data.totalPages;
            }
            if(res.data.totalDocs){
              state.data.paging.getAccountFormList.totalDocs = res.data.totalDocs;
            }
            // res.data.projects;
            // getChannelList(1);
            // toggleTourGuide();
            state.data.templateLoad = true;
          })
          .catch((error: any) => {
            console.log(error);
          })
          .finally(() => {
            state.ui.postLoading.getAccountFormList = false;
          });
      }
    }
    function nextPage(event: any) {
      state.data.paging.getAccountFormList.currentPage = event;
      getAccountFormList();
    }
    function sendoption(){
       $("#sendoption").modal("hide");
    }
    function getprojectList() {
      console.log("getprojectList WOWO:>>>");
      const payLoad = {};
      state.ui.isPostLoading.getprojectList = true;
      ethitransService
        .getprojectList(payLoad)
        .then((res: any) => {
          // console.log("getprojectList Check:>>> ", res.data.data.projects);
          state.projectList = res.data.data.projects
            ? res.data.data.projects
            : [];
          // state.selectedFormForEdit = res.data ? res.data : {};
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.getprojectList = false;
        });
    }
    function channelCommunicationForm(id: any) {
      state.item.error.erorListSendForm = []
      state.item.hasError = false;
      const data: any = state.data.templateResponse;
      console.log("CHECK DATA >>>>>>>>>>", data);
      let payLoad: any = {};
      const usersArray = [] as any;
      if (state.data.selectedEdocForm.edoc_id) {
        state.invite.users.forEach((item: any) => {
          usersArray.push(item);
        });
        payLoad = {
          // eslint-disable-next-line @typescript-eslint/camelcase
          communication_id: id,
          type: "client_document",
          // eslint-disable-next-line @typescript-eslint/camelcase
          form_id: data._id,
          // eslint-disable-next-line @typescript-eslint/camelcase
          client_document_id: state.data.selectedEdocForm.form_id,
          // eslint-disable-next-line @typescript-eslint/camelcase
          client_document_name: state.data.selectedEdocForm.title,
          // eslint-disable-next-line @typescript-eslint/camelcase
          sent_to_users: usersArray,
        };
      }
      // state.owner

      console.log("<< ChannelCommunicationForm CHECK >>", payLoad);
      state.ui.isPostLoading.createFormTemplate = true;
      ethitransService
        .channelCommunicationFormData(payLoad)
        .then((res: any) => {
          // console.log("ChannelCommunicationForm Response Check:>>> ", res.data);
          state.invite.users = []
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.item.error.erorListSendForm)
            .then((res: any) => {
              state.item.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.item.error.erorListSendForm.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.createFormTemplate = false;
        });
    }
    function formMessage(msg: string) {
      state.item.error.erorListSendForm = []
      state.item.hasError = false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: state.item.sendOption.channel.uuid,
        message: msg,
        type: "form",
      };
      state.ui.isPostLoading.formMessage = true;
      ethitransService
        .createCommunication(payLoad)
        .then((res: any) => {
          // console.log(
          //   "<<<Create Communication Response Check:>>> ",
          //   res.data.data
          // );
          channelCommunicationForm(res.data.data.communication.id);
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.item.error.erorListSendForm)
            .then((res: any) => {
              state.item.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.item.error.erorListSendForm.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.formMessage = false;
        });
    }
    function removeSelectedMember(item: any) {
      // console.log("check the sELCTIOn", item);
      state.invite.users = state.invite.users.filter((e: any) => e != item);
      state.data.userList.push(item);
      // console.log("AFTER DELECTION", state.invite.users);
    }
    function changeExistingMemberInSendOption() {
      if (state.invite.users.indexOf(state.existingMember) === -1) {
        state.invite.users.push(state.existingMember);
        // console.log(state.invite.users);
      }
      state.userList = state.userList.filter(
        (e: any) => e != state.existingMember
      );
      state.existingMember = {};
    }
    function validateSendDigitizedForm() {
      // console.log("Validate Send Digitize Form");
      state.item.error.erorListSendForm = [];
      state.item.hasError = false;

      // console.log("EMAIL CHECK", state.invite.users);

      if (state.invite.users && !state.invite.users.length) {
        state.item.error.erorListSendForm.push("Atleast 1 Email is required.");
      }

      if (
        !state.item.error.erorListSendForm &&
        !state.item.error.erorListSendForm.length
      ) {
        state.item.hasError = false;
      } else if (state.item.error.erorListSendForm.length != 0) {
        state.item.hasError = true;
      }
    }
    function sendDigitizedForm() {
      state.item.error.erorListSendForm = []
      state.item.hasError = false;
      validateSendDigitizedForm();
      // alert("HELLO FOR SEND DIGITAL FORM");
      if (
        state.ui.isPostLoading.sendDigitizedForm ||
        state.item.error.erorListSendForm.length != 0 ||
        state.item.hasError
      )
        return false;
      const usersArray = [] as any;
      state.invite.users.forEach((item: any) => {
        usersArray.push(item);
      });

      const payLoad = {
        title: state.data.selectedEdocForm.title,
        description: '',
        // eslint-disable-next-line @typescript-eslint/camelcase
        edoc_id: state.data.selectedEdocForm.edoc_id,
        // message: state.data.additionalMessage,
        elements: state.formData.elements,
        sentToUsers: usersArray,
        privacy: {
          code: state.data.privacy.code,
          name: state.data.privacy.value,
        },
        owner: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          created_by: {
            // name: state.owner.created_by.full_name,
            name: localStorage.getItem("current_user_details"),
            email: localStorage.getItem("current_user_email"),
            // email: state.owner.created_by.email,
          },
          // eslint-disable-next-line @typescript-eslint/camelcase
          project_id: state.item.sendOption.project.uuid,
          // eslint-disable-next-line @typescript-eslint/camelcase
          channel_id: state.item.sendOption.channel.uuid,
          project: {
            //added
            id: state.item.sendOption.project.id,
            uuid: state.item.sendOption.project.uuid,
            name: state.item.sendOption.project.name,
            description: state.item.sendOption.project.description,
          },
          channel: {
            //added
            id: state.item.sendOption.channel.id,
            uuid: state.item.sendOption.channel.uuid,
            name: state.item.sendOption.channel.name,
            description: state.item.sendOption.channel.description,
          },
        },
      };
      // console.log("CHECK THE Request", payLoad);
      state.ui.isPostLoading.sendDigitizedForm = true;
      ethitransService
        .accountformSave(payLoad)
        .then((res: any) => {
          // console.log("UserList Response Check:>>> ", res.data);
          state.data.templateResponse = res.data;
          if (
            state.data.additionalMessage ||
            (usersArray && usersArray.length)
          ) {
            // check this
            formMessage(state.data.additionalMessage);
            $("#sendoption").modal("hide");
          }
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.item.error.erorListSendForm)
            .then((res: any) => {
              state.item.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.item.error.erorListSendForm.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.sendDigitizedForm = false;
        });
    }
    function getChannelByProjectForSendOption() {
      // console.log("Get Account form Response:>>>", formId);
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: state.item.sendOption.project.uuid,
      };
      state.ui.postLoading.getChannelByProjectForSendOption = true;
      ethitransService
        .getChannelListByProject(payLoad)
        .then((res: any) => {
          console.log("getChannelByProject Response Check:>>> ", res.data.data);
          state.channelListForSendOption = res.data.data ? res.data.data : [];
          // state.selectedFormForEdit = res.data ? res.data : {};
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.postLoading.getChannelByProjectForSendOption = false;
        });
    }
    function getUserListByProjectAndChannel() {
      state.item.error.erorListSendForm = []
      state.item.hasError = false;
      // console.log("USERLIST check for channel ID", channelId);
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: state.item.sendOption.project.uuid,
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: state.item.sendOption.channel.uuid,
      };
      // console.log("PAYLOAD CHECK ", payLoad);
      state.ui.isPostLoading.getUserListByProjectAndChannel = true;
      ethitransService
        .projectMemberListByChannel(payLoad)
        .then((res: any) => {
          // console.log("UserList Check:>>> ", res.data.data);
          state.userListForSendOption = res.data.data ? res.data.data : [];
        })
         .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.item.error.erorListSendForm)
            .then((res: any) => {
              state.item.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.item.error.erorListSendForm.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.getUserListByProjectAndChannel = false;
        });
    }
    function sendSelectedForm(form: any) {
      state.item.sendOption.project = {}
      state.item.sendOption.channel = {}
      state.existingMember = {}
      state.invite.users = []
      state.data.additionalMessage = ''
      if (form.elements && !form.elements.length) {
        getprojectList()
        console.log("<< EDOC FORM >>");
        state.data.selectedEdocForm = form
        $("#sendoption").modal("show");
      } else {
        router.push({
          name: "admin-channel-create-form",
          query: { formId: form._id, mode: "send" },
        });
    }
    }
    function editSelectedForm(form: any) {
      router.push({
        name: "admin-channel-create-form",
        query: { formId: form._id, mode: "edit" },
      });
      // $(".modal-backdrop").remove();
    }
    function clientDocumentRetrieveWorkflow(
      clientDocumentId: any,
      selectedChannelId: any
    ) {
      // alert("HHHHCHECK")
      if (!clientDocumentId || !selectedChannelId) return false;
      const payLoad = {
        id: clientDocumentId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        account_form_id: selectedChannelId,
      };
      state.ui.isLoading.clientDocumentRetrieveWorkflow = true;
      ethitransService
        .clientDocumentRetrieveWorkflowLink(payLoad)
        .then((res: any) => {
          // console.log("HELLOOO");
          // console.log("clientDocumentRetrieveWorkflow :>>> ", res.data.data.docId);
          // // accountFormRetrieve(res.data.data.docId);
          // state.data.clientDocumentRetrieveWorkflowLink = res.data.data
          //   ? res.data.data
          //   : {};
            
            window.open(res.data.data.galleryThumbUrl, "_blank");
          // $('.nav-tabs a[href="#profile"]').tab('show')
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isLoading.clientDocumentRetrieveWorkflow = false;
        });
    }
    function getAccountFormRetrieveByIdForSend(formId: any) {
      // console.log("Get Account form Response:>>>", formId);
      state.ui.postLoading.getAccountFormRetrieveById = true;
      ethitransService
        .accountFormRetrieveById(formId)
        .then((res: any) => {
          console.log(
            "getAccountFormRetrieveById Response Check:>>> ",
            res.data
          );
          // clientDocumentRetrieveWorkflow(res.data.form_id, res.data._id)
          state.newFormData = res.data ? res.data : {};
          // state.selectedFormForEdit = res.data ? res.data : {};
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.postLoading.getAccountFormRetrieveById = false;
        });
    }
    function hideTemplateModal() {
      // alert();
      $("#formPreviewModal").modal("hide");
    }
    // function accountFormRetrieve(formId: any) {
    //   state.formData = {};
    //   // state.ui.isPostLoading.getAccountFormRetrieveById = true;
    //   ethitransService
    //     .accountFormRetrieveById(formId)
    //     .then((res: any) => {
    //       // state.formResponse = res.data
    //       console.log(
    //         "HELLO FROM COMMUNICATION Response Check:>>> ",
    //         res.data
    //       );
    //       state.formData = res.data;
    //       if (state.formData.sentToUsers && state.formData.sentToUsers.length) {
    //         getAccountFormResponseByEmailAndId(
    //           state.formData.sentToUsers[state.activeUserIndex],
    //           0
    //         );
    //       }
    //     })
    //     .catch((error: any) => {
    //       console.log(error);
    //     })
    //     .finally(() => {
    //       // state.ui.isPostLoading.getAccountFormRetrieveById = false;
    //     });
    // }
    function clientDocumentList(edocId: any, id: any) {
      const payLoad = {
        type: "ready",
      };
      state.ui.isLoading.clientDocumentList = true;
      ethitransService
        .clientDocumentList(payLoad)
        .then((res: any) => {
          // console.log("clientDocumentList :>>> ", res.data.data);
          if (res.data.data && res.data.data.length) {
            res.data.data.forEach((item: any) => {
              item.convertedFileSize = common.formatBytes(
                parseInt(item.file_size)
              );
            });
            state.data.clientDocuments = res.data.data;
          } else {
            state.data.clientDocuments = [];
          }
          let uuId = ""
          state.data.clientDocuments.filter((data: any) => {
            if(edocId === data.edoc_id){
              // console.log("CHECK UUID >>> ",data.uuid )
              uuId = data.uuid
            }
          })
          console.log("CHECK UUID >>>", uuId)
          // alert()
          clientDocumentRetrieveWorkflow( uuId, id) 
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isLoading.clientDocumentList = false;
        });
    }

     function serarchTag(tag: any){
      state.data.filter.tag = tag;
    getAccountFormList();

    }

    function selectForm(form: any) {
      console.log("selected FORM >>>> ", form)
      // state.data.selectedFormData = {};
      // const formData = form.form_data;
      state.newFormData = form;
      // console.log("Selected Form:>>", formData);
      if(form.elements && form.elements.length === 0){
        console.log("EDOC")
        clientDocumentList(form.edoc_id, form._id)
      } else {
        $("#formPreviewModal").modal("show");

        getAccountFormRetrieveByIdForSend(form._id);
      }
    }
    onMounted(() => {
      state.data.name = localStorage.getItem("current_user_details");
      state.data.email = localStorage.getItem("current_user_email");
      // getAccountFormList();
      getAccountFormList();
      // toggleTourGuide();
    });

    return {
      state,
      selectForm,
      toggleTourGuide,
      clientDocumentList,
      // accountFormRetrieve,
      clientDocumentRetrieveWorkflow,
      hideTemplateModal,
      getAccountFormRetrieveByIdForSend,
      sendoption,
      getprojectList,
      sendSelectedForm,
      editSelectedForm,
      getAccountFormList,
      templateList,
      nextPage,
      validateSendDigitizedForm,
      getChannelByProjectForSendOption,
      getUserListByProjectAndChannel,
      sendDigitizedForm,
      formPrivacyMessage,
      channelCommunicationForm,
      formMessage,
      changeExistingMemberInSendOption,
      removeSelectedMember,
      serarchTag,
      formatDate,
    };
  },
});
