<template>
  <div>
    <input class="form-control" :disabled="disabled" v-model="emailInput" @input="onInputChange"
      placeholder="Enter your email" />
    <!-- <p v-if="!isValid && emailInput">Invalid Email</p> -->
  </div>
</template>

<script>
import { ref, watch } from 'vue';

export default {
  props: {
    email: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['validEmail', 'error', 'clearError', 'validationStatus'], // Add clearError event
  setup(props, { emit }) {
    const emailInput = ref(props.email); // Local email input state
    const isValid = ref(true);

    // Validation method
    const validateEmail = () => {
      // Clear error if input is empty
      if (!emailInput.value) {
        isValid.value = true; // Set valid if empty (no validation needed)
        emit('clearError');
        emit('validationStatus', false); // Emit clearError event to notify parent
        return;
      }

      // const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const emailPattern = /^[\w.-]+@([\w-]+\.)+[\w-]{2,}$/;
      isValid.value = emailPattern.test(emailInput.value);

      if (isValid.value) {
        // Emit the valid email event when validation passes
        emit('validEmail', emailInput.value);
        emit('validationStatus', isValid.value);
        emit('clearError');
      } else {
        // Emit the error event when validation fails
        emit('error', 'Invalid email format');
      }
    };

    // Watch for changes in props (email) to sync with the child component input
    watch(() => props.email, (newVal) => {
      emailInput.value = newVal;
      validateEmail(); // Revalidate on prop change
    });

    // Emit the data on each key press via @input
    const onInputChange = () => {
      emit('validationStatus', isValid.value); // Emit the input value on each key press
      validateEmail(); // Validate the input on each change
    };

    return {
      emailInput,
      isValid,
      validateEmail,
      onInputChange,
    };
  },
};
</script>
