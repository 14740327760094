
import { defineComponent, reactive, onMounted } from "vue";
import { useRoute } from "vue-router";
import TitleComponent from "@/components/formComponents/TitleComponent.vue";
import TextBoxComponent from "@/components/formComponents/TextBoxComponent.vue";
// import TextAreaComponent from "@/components/formComponents/TextAreaComponent.vue";
import RadioButtonComponent from "@/components/formComponents/RadioButtonComponent.vue";
import SelectOption from "@/components/formComponents/SelectOption.vue";
import CheckBox from "@/components/formComponents/CheckBox.vue";
import TextArea from "@/components/formComponents/TextArea.vue";

import { ethitransService } from "@/services/EthitransService.ts";

export default defineComponent({
  name: "CreateTemplate",
  components: {
    TitleComponent,
    TextBoxComponent,
    // TextAreaComponent,
    RadioButtonComponent,
    SelectOption,
    CheckBox,
    TextArea,
  },
  setup() {
    const {
      params: { formId },
    } = useRoute();
    const state = reactive({
      type: "",
      selected: "",
      typePosition: "",

      form: {
        textField: {
          title: "",
          description: "",
        },
        textArea: {
          title: "",
          text: "",
        },
        textbox: {
          title: "",
        },
        radioButton: {
          type: "radioButton",
          title: "",
          // eslint-disable-next-line @typescript-eslint/camelcase
          select_values: [] as any,
        },
        selectOption: {
          type: "single_select",
          title: "",
          // eslint-disable-next-line @typescript-eslint/camelcase
          select_values: [] as any,
        },
        checkBox: {
          type: "checkBox",
          title: "",
          // eslint-disable-next-line @typescript-eslint/camelcase
          select_values: [] as any,
        },
      },
      ui: {
        isLoading: {},
        isPostLoading: {
          createFormTemplate: false,
          getAccountFormRetrieveById: false,
        },
      },
      data: [] as any,
      // data: [
      //   {
      //     type: "title",
      //     title: "Kitchen Revamping Project",
      //     description: "Kitchen Revamping Description Here",
      //     style: {
      //       // eslint-disable-next-line @typescript-eslint/camelcase
      //       font_size: 13,
      //       // eslint-disable-next-line @typescript-eslint/camelcase
      //       font_name: "Arial",
      //       // eslint-disable-next-line @typescript-eslint/camelcase
      //       background_color: "Red",
      //     },
      //   },
      //   {
      //     type: "textbox",
      //     title: "Your Name",
      //     tooltip: "Please write your full name",
      //     placeholder: "Write your name here",
      //     validation: [
      //       {
      //         type: "required",
      //         // eslint-disable-next-line @typescript-eslint/camelcase
      //         error_message: "Your name is required",
      //       },
      //       {
      //         type: "regex",
      //         pattern: "/dfdfdfdfdf/",
      //         // eslint-disable-next-line @typescript-eslint/camelcase
      //         error_message:
      //           "Name must not contain numbers and special characters",
      //       },
      //     ],
      //   },
      //   {
      //     type: "textbox",
      //     title: "Your Name",
      //     tooltip: "Please write your full name",
      //     placeholder: "Write your name here",
      //     validation: [
      //       {
      //         type: "required",
      //         // eslint-disable-next-line @typescript-eslint/camelcase
      //         error_message: "Your name is required",
      //       },
      //     ],
      //   },
      //   {
      //     type: "radioButton",
      //     text: "Kitchen Revamping Project",
      //     style: {
      //       // eslint-disable-next-line @typescript-eslint/camelcase
      //       font_size: 13,
      //       // eslint-disable-next-line @typescript-eslint/camelcase
      //       font_name: "Arial",
      //       // eslint-disable-next-line @typescript-eslint/camelcase
      //       background_color: "Red",
      //     },
      //   },
      //   {
      //     type: "radioButton",
      //     text: "Kitchen Revamping Project",
      //     style: {
      //       // eslint-disable-next-line @typescript-eslint/camelcase
      //       font_size: 13,
      //       // eslint-disable-next-line @typescript-eslint/camelcase
      //       font_name: "Arial",
      //       // eslint-disable-next-line @typescript-eslint/camelcase
      //       background_color: "Red",
      //     },
      //   },
      //   {
      //     type: "single_select",
      //     title: "Your Country",
      //     // eslint-disable-next-line @typescript-eslint/camelcase
      //     select_values: [],
      //   },
      // ] as any,
    });
    function addOptions() {
      state.form.radioButton.select_values.push({
        text: "",
        value: "",
        selected: false,
      });
    }
    function addSelectOptions() {
      state.form.selectOption.select_values.push({
        text: "",
        value: "",
        selected: false,
      });
    }
    function addCheckBox() {
      state.form.checkBox.select_values.push({
        text: "",
        value: "",
        selected: false,
      });
    }
    function getAccountFormRetrieveById() {
      state.ui.isPostLoading.getAccountFormRetrieveById = true;
      ethitransService
        .accountFormRetrieveById(formId)
        .then((res: any) => {
          console.log("getAccountFormRetrieveById Response Check:>>> ", res.data);
          state.data = res.data.elements
          // state.data = 
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.getAccountFormRetrieveById = false;
        });
    }
    function saveForm() {
      // accountformSave
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        elements: state.data,
        // eslint-disable-next-line @typescript-eslint/camelcase
        account_form_id: formId,
      };
      console.log("PAYLOAD CHECK ", payLoad);
      state.ui.isPostLoading.createFormTemplate = true;
      ethitransService
        .accountformResponseSave(payLoad)
        .then((res: any) => {
          console.log("GET Response Check:>>> ", res.data);
          // state.userList = res.data.data ? res.data.data : [];
          state.data = res.data.elements
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.createFormTemplate = false;
        });
    }
    onMounted(() => {
      if(formId){
        console.log("Form Id: >>> ", formId);
        getAccountFormRetrieveById()
      }
      // getAccountFormList();
    });
    return {
      state,
      saveForm,
      addOptions,
      addSelectOptions,
      addCheckBox,
      getAccountFormRetrieveById
    };
  },
});
