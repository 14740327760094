
import { useStore } from "vuex";
import { defineComponent, reactive, onMounted, watch } from "vue";
import DocusignDocumentListTable from "@/components/commonDesign/AppDocusignTable.vue";
import { ethitransService } from "@/services/EthitransService";

export default defineComponent({
  name: "AppDocusignModel",
  components: {
    DocusignDocumentListTable
  },
  props: {
    showModal: Boolean,
    channelMemberList: Array,
    channelId: String
  },
  setup(props, { emit }) {
    const store = useStore();
    const state = reactive({
      data: {
        from: 'DocumentListModel',
        hearder: [
          {
            name: 'Title'
          },
          {
            name: 'Action'
          }
        ],
        clientDocuments: {} as any,

      }
    });
    function closeSuccessfulModal() {
      emit("close");
      $("#showDocusignListModel").modal("hide");
    }

    function clientDocumentList() {
      //   state.ui.isLoading.clientDocumentList = true;
      ethitransService
        .getDocusignTemplate()
        .then((res: any) => {
          state.data.clientDocuments = res.data.data;
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          //   state.ui.isLoading.clientDocumentList = false;
        });
    }

    watch(
      () => store.state.saveSignedData,
      (newvalue, oldvalue) => {
        if (newvalue) {
          closeSuccessfulModal()
        }
      }
    );

    onMounted(() => {
      if (props.showModal) {
        $("#showDocusignListModel").modal("show");

      }
      clientDocumentList();
    });

    return {
      state,
      closeSuccessfulModal,
      clientDocumentList,
    };
  },
});
