
import { defineComponent, reactive, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";

import { config } from "@/Config";

export default defineComponent({
  name: "ToDoDetailsAttachment",
  props: {
    todoAttachments: {
      type: Array,
      required: true,
    },
  },
  components: {},
  setup(props, { emit }) {
    const router = useRouter();
    const {
      params: { projectId },
    } = useRoute();
    const state = reactive({
      data: {
        imageBaseUrl: "",
      },
      ui: {
        error: {
          hasError: false,
          errorList: {
            createPhase: [] as any,
          },
        },
        isLoading: {
          getToDoData: false,
        },
        isPostLoading: {
          createPhase: false,
        },
      },
    });
    function viewAttachment(data: string){
      console.log("ATTACHMENT LINK >>> ", data);
      window.open(data, "_blank");
    }
    onMounted(() => {
      state.data.imageBaseUrl = config.imageBaseUrl;
    });

    return {
      state,
      viewAttachment
    };
  },
});
