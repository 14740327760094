
import { defineComponent, reactive, onMounted, onBeforeMount, computed, ref } from "vue";
import { useRoute } from "vue-router";
// import ErrorList from "@/components/commonDesign/ErrorList.vue";

import { ethitransService } from "@/services/EthitransService";
// import { authService } from "@/login/Services/AuthService";

import { validationService } from "@/common/ValidationService";
import { common } from "@/common/services/Common";
// import CustomEventCalender from "@/components/projectDetail/CustomEventCalender.vue";
import TostCalender from "@/components/todo/TostCalender.vue";

// import { Data } from "@/components/todo/AddMember.ts";

export default defineComponent({
  name: "CheckAvaibilityByUser",
  props: {
    activePhaseId: {
      type: String,
    },
    isActive: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    static: {
      type: Boolean,
    },
    selectedEmail: {
      type: String,
    },
    startDate: {
      type: String,
    },
    endDate: {
      type: String,
    },
    activeTab: {
      type: String,
    },
    phaseDetails: {
      type: Object,
    },
    userDetails: {
      type: Object,
    },
    tasks: {
      type: Array,
    },
  },
  components: {
    // CustomEventCalender,
    TostCalender,
    // ErrorList,
  },
  setup(props, { emit }) {
    // console.log("startDate:", props.startDate);
    // console.log("endDate:", props.endDate);
    const {
      params: { id },
    } = useRoute();
    const childComponentKey = ref(0);
    const state = reactive({
      data: {
        timeSlots: [] as any,
        deletedSlots: [] as any,
        agreeToInvitation: false,
        filterBy: {
          startDate: "",
          endDate: "",
        },
        dates: {
          email: "",
          // eslint-disable-next-line @typescript-eslint/camelcase
          start_date: "",
          // eslint-disable-next-line @typescript-eslint/camelcase
          end_date: "",
          availability: [] as any,
          formattedDate: [] as any,
        },
      },
      ui: {
        error: {
          hasError: false,
          errorList: {
            availabilityCheck: [] as any,
          },
        },
        isLoading: {
          availabilityCheck: false,
        },
        isPostLoading: {
          createPhase: false,
        },
      },
    });
    const filteredEvents = computed(() => {
      const dates: any = [];
      const selectedObject: any = props.phaseDetails;
      if (props.activeTab === "edit") {
        if (props.userDetails) {
          const selecteduserDetails: any = props.userDetails;
          console.log("CHECK >>> ", selecteduserDetails);
          if (selecteduserDetails.slots) {
            selecteduserDetails.slots.forEach((slot: any) => {
              (slot.titleEditable = false),
                // if (props.disabled) {
                dates.push(slot);
              // console.log("CHECK SLOTS >>>", slot);
            });
          }
        }
        state.data.dates.availability.forEach((item: any) => {
          if (item.status != 0) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            if (item.time_slots && item.time_slots.length) {
              // eslint-disable-next-line @typescript-eslint/camelcase
              item.time_slots.forEach((slot: any) => {
                // if (props.disabled) {
                dates.push({
                  start: `${common.formatDateAndTimeWithOutSeconds(
                    slot.start_time
                  )}`,
                  end: `${common.formatDateAndTimeWithOutSeconds(
                    slot.end_time
                  )}`,
                  title: `${slot.title}`,
                  deletable: false,
                  resizable: false,
                  draggable: false,
                  titleEditable: false,
                  // content: '<i class="icon material-icons">sentiment_satisfied_alt</i>',
                  // class: 'health'
                });
                // } else {
                //   dates.push({
                //     start: `${common.formatDateAndTimeWithOutSeconds(
                //       slot.start_time
                //     )}`,
                //     end: `${common.formatDateAndTimeWithOutSeconds(
                //       slot.end_time
                //     )}`,
                //     title: `${selectedObject.phase.name}/${slot.title}`,
                //     // content: '<i class="icon material-icons">sentiment_satisfied_alt</i>',
                //     // class: 'health'
                //   });
                // }
              });
            }
          }
        });
      } else if (props.activeTab === "phase") {
        if (props.userDetails) {
          const selecteduserDetails: any = props.userDetails;
          console.log("CHECK >>> ", selecteduserDetails);
          if (selecteduserDetails.slots) {
            selecteduserDetails.slots.forEach((slot: any) => {
              (slot.titleEditable = false),
                // if (props.disabled) {
                dates.push(slot);
              // console.log("CHECK SLOTS >>>", slot);
            });
          }
        }
        state.data.dates.availability.forEach((item: any) => {
          if (item.status != 0) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            if (item.time_slots && item.time_slots.length) {
              // eslint-disable-next-line @typescript-eslint/camelcase
              item.time_slots.forEach((slot: any) => {
                // if (props.disabled) {
                dates.push({
                  start: `${common.formatDateAndTimeWithOutSeconds(
                    slot.start_time
                  )}`,
                  end: `${common.formatDateAndTimeWithOutSeconds(
                    slot.end_time
                  )}`,
                  title: `${slot.title}`,
                  deletable: false,
                  resizable: false,
                  draggable: false,
                  titleEditable: false,
                  // content: '<i class="icon material-icons">sentiment_satisfied_alt</i>',
                  // class: 'health'
                });
                // } else {
                //   dates.push({
                //     start: `${common.formatDateAndTimeWithOutSeconds(
                //       slot.start_time
                //     )}`,
                //     end: `${common.formatDateAndTimeWithOutSeconds(
                //       slot.end_time
                //     )}`,
                //     title: `${selectedObject.phase.name}/${slot.title}`,
                //     // content: '<i class="icon material-icons">sentiment_satisfied_alt</i>',
                //     // class: 'health'
                //   });
                // }
              });
            }
          }
        });
      } else {
        state.data.dates.availability.forEach((item: any) => {
          if (item.status != 0) {
            if (item.time_slots && item.time_slots.length) {
              item.time_slots.forEach((slot: any) => {
                dates.push({
                  start: `${common.formatDateAndTimeWithOutSeconds(
                    slot.start_time
                  )}`,
                  end: `${common.formatDateAndTimeWithOutSeconds(
                    slot.end_time
                  )}`,
                  title: `${slot.title}`,
                  deletable: false,
                  resizable: false,
                  draggable: false,
                  titleEditable: false,
                  // content: '<i class="icon material-icons">sentiment_satisfied_alt</i>',
                  // class: 'health'
                });
              });
            }
          }
        });
        if (props.userDetails) {
          const selecteduserDetails: any = props.userDetails;
          selecteduserDetails.slots.forEach((slot: any) => {
            (slot.titleEditable = false),
              // if (props.disabled) {
              dates.push(slot);
            // console.log("CHECK SLOTS >>>", slot);
          });
        }

        // old code
        // const tasks: any = props.tasks;

        // tasks.forEach((child: any) => {
        //   if (child.edit_end_date != null || child.edit_start_date != null) {
        //     dates.push({
        //       start: `${common.formatDateDash(child.edit_start_date)} 10:00`,
        //       end: `${common.formatDateDash(child.edit_end_date)} 17:00`,
        //       title: `${child.name}`,
        //       class: "sport",
        //       // content: '<i class="icon material-icons">sentiment_satisfied_alt</i>',
        //       // class: 'health'
        //     });
        //     // state.data.dates.availability
        //     state.data.dates.availability.forEach((avD: any) => {
        //       if (
        //         avD.day === common.formatDateDash(child.edit_start_date) &&
        //         avD.status === 0
        //       ) {
        //         dates.push({
        //           start: `${common.formatDateDash(
        //             child.edit_start_date
        //           )} 11:00`,
        //           end: `${common.formatDateDash(child.edit_end_date)} 13:00`,
        //           content: '<i class="icon material-icons">directions_run</i>',
        //           title: "Available",
        //           class: "sport",
        //           // content: '<i class="icon material-icons">sentiment_satisfied_alt</i>',
        //           // class: 'health'
        //         });
        //       } else {
        //         dates.push({
        //           start: `${common.formatDateDash(
        //             child.edit_start_date
        //           )} 11:00`,
        //           end: `${common.formatDateDash(child.edit_end_date)} 13:00`,
        //           content: '<i class="icon material-icons">directions_run</i>',
        //           title: "Not Available",
        //           // content: '<i class="icon material-icons">sentiment_satisfied_alt</i>',
        //           // class: 'health'
        //         });
        //       }
        //     });

        //     //   dates.push({
        //     //   start: `${common.formatDateDash(child.edit_start_date)} 11:00`,
        //     //   end: `${common.formatDateDash(child.edit_end_date)} 13:00`,
        //     //   content: '<i class="icon material-icons">directions_run</i>',
        //     //   title: 'Available',
        //     //   class: 'sport'
        //     //   // content: '<i class="icon material-icons">sentiment_satisfied_alt</i>',
        //     //   // class: 'health'
        //     // })
        //   }
        // });
      }
      return dates;
      // return state.data.dates.availability;
    });
    function createdEvent(event: any) {
      console.log("CHECK THE CREATED EVENT >>>> ", event);
      event.formatedStart = common.formatDateAndTimeSlot(event.start);
      event.formatedEnd = common.formatDateAndTimeSlot(event.end);
      // console.log("DATE TIME FORMAT START >> ", common.formatDateAndTime(event.start));
      // console.log("DATE TIME FORMAT END >> ", common.formatDateAndTime(event.end));
      state.data.timeSlots.push(event);
      // emit process here
      // emit("createdEvent", event);
    }
    function deletedEvent(event: any) {
      // if(!state.data.timeSlots.length) return false;
      event.formatedStart = common.formatDateAndTimeSlot(event.start);
      event.formatedEnd = common.formatDateAndTimeSlot(event.end);
      console.log("CHECK DELETED MODAL >>> ", event);
      state.data.deletedSlots.push(event);

      // console.log("CHECK ALL SLOTS >>> ", state.data.timeSlots);

      // // state.data.timeSlots[state.data.selectedMemberIndex].slots =
      // //   state.data.timeSlots.filter(
      //   //     (e: any) =>
      //   //       e.title !== event.title &&
      //   //       e.start !== event.start &&
      //   //       e.end !== event.end
      //   //   );
      //   state.data.timeSlots = state.data.timeSlots.filter((person: any) => person._eid !== event._eid)
      // console.log("CHECK FINAL SLOTS >>> ", state.data.timeSlots.filter((person: any) => person._eid !== event._eid));
      // console.log("CHECK FINEA ALL SLOTS >>> ", state.data.timeSlots);
      console.log("FINAL DELETED SLOTS >>> ", state.data.deletedSlots);
      console.log("CHECK FINAL SLOTS >>> ", state.data.timeSlots);
      // console.log("DATE TIME FORMAT START >> ", common.formatDateAndTime(event.start));
      // console.log("DATE TIME FORMAT END >> ", common.formatDateAndTime(event.end));
      // delete emit process here
      // emit("deletedEvent", event);
    }
    // const disabledDates = ref([
    //   {
    //     month: {
    //       october: [2, 3],
    //     },
    //   },
    // ]);
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    const attributes = ref([
      // {
      //   key: "today",
      //   highlight: {
      //     color: "red",
      //     fillMode: "solid",
      //     contentClass: "italic",
      //   },
      //   dates: new Date(year, month, 12),
      // },
      // {
      //   highlight: {
      //     color: "purple",
      //     fillMode: "light",
      //   },
      //   dates: new Date(year, month, 13),
      // },
      {
        dot: true,
        highlight: {
          color: "purple",
          fillMode: "outline",
        },
        dates: new Date(year, month, 14),
      },
    ]);
    function availabilityCheck() {
      state.ui.error.errorList.availabilityCheck = [];
      state.ui.error.hasError = false;
      const payLoad = {
        email: props.selectedEmail,
        // eslint-disable-next-line @typescript-eslint/camelcase
        start_date: props.startDate,
        // eslint-disable-next-line @typescript-eslint/camelcase
        end_date: props.endDate,
        // eslint-disable-next-line @typescript-eslint/camelcase
        phase_id: props.activePhaseId,
      };

      if (state.ui.isLoading.availabilityCheck) return false;
      state.ui.isLoading.availabilityCheck = true;
      ethitransService
        .availabilityCheckByUser(payLoad)
        .then((res: any) => {
          // const dates = [] as any;
          // state.data.dates = res.data.data;
          // if (res.data.data.availability && res.data.data.availability.length) {
          //   res.data.data.availability.forEach((item: any) => {
          //     if (item.status === 0) {
          //       dates.push({
          //         dot: true,
          //         highlight: {
          //           color: "purple",
          //           fillMode: "outline",
          //         },
          //         dates: new Date(common.formatDate(item.day)),
          //       });
          //     } else {
          //       // if(item.time_slots && item.time_slots.length){
          //       item.time_slots.forEach((slot: any) => {
          //         dates.push({
          //           start: `${common.formatDateAndTimeWithOutSeconds(
          //             slot.start_time
          //           )}`,
          //           end: `${common.formatDateAndTimeWithOutSeconds(
          //             slot.end_time
          //           )}`,
          //           title: slot.title,
          //           // content: '<i class="icon material-icons">sentiment_satisfied_alt</i>',
          //           // class: 'health'
          //         });
          //       });
          //       // }
          //     }
          //   });
          // }
          console.log("PRA CHECK THE DATED>>> ", res.data.data);

          // if(selecteduserDetails.slots && selecteduserDetails.slots.lenth){
          //   selecteduserDetails.slots.forEach((slot: any)=> {
          //     console.log("CHECK Slots >>> ", slot)
          //   })
          // }
          // console.log("Formated:>>> ", dates);
          // res.data.data.formattedDate = dates;
          // // console.log("getToDoData Check:>>> ", res.data.data);
          // state.data.dates = res.data.data;
          $("#availibilityFilterByUser").modal("show");
          state.ui.isLoading.availabilityCheck = false;
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.availabilityCheck)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.availabilityCheck.push(value[0]);
                }
              }
            });
        });
    }

    // function hideCheckAvaibilityModal() {
    //   $("#availibilityFilterByUser").modal("hide");
    //   console.log("TESTING >>>> ", state.data.timeSlots);
    //   // if(state.data.timeSlots && state.data.timeSlots.length > 0){
    //     emit("createdEvent", state.data.timeSlots);
    //     // emit("deletedEvent", state.data.deletedSlots);
    //   // }
    //   emit("hideAvaibilityModal");
    // }
    function hideAvaibilityModal() {
      $("#availibilityFilterByUser").modal("hide");
      console.log("TESTING >>>> ", state.data.timeSlots);
      // if(state.data.timeSlots && state.data.timeSlots.length > 0){
      // emit("createdEvent", state.data.timeSlots);
      // emit("deletedEvent", state.data.deletedSlots);
      // }
      emit("hideAvaibilityModal");
    }
    function hideCheckAvaibilityModal(data: any) {
      $("#availibilityFilterByUser").modal("hide");
      console.log("Slots >>> ", data);
      console.log("TESTING >>>> ", data);
      // if(state.data.timeSlots && state.data.timeSlots.length > 0){
      emit("createdEvent", data);
      // emit("deletedEvent", state.data.deletedSlots);
      // }
      // emit("hideAvaibilityModal");
      hideAvaibilityModal();
    }
    const reloadChildComponent = () => {
      setTimeout(() => {
        childComponentKey.value += 1; // Change the key to force re-render
      }, 0); // Adjust the timeout duration as needed
    };
    onBeforeMount(() => {
      reloadChildComponent();
    });

    onMounted(() => {
      if (props.isActive) {
        // alert("Active here");
        // availabilityCheck();
        $("#availibilityFilterByUser").modal("show");
      }

      // smartContractCalender()
    });

    return {
      state,
      reloadChildComponent,
      hideAvaibilityModal,
      deletedEvent,
      createdEvent,
      filteredEvents,
      availabilityCheck,
      hideCheckAvaibilityModal,
      // disabledDates,
      attributes,
    };
  },
});
