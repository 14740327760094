
import { defineComponent, onMounted, reactive } from "vue";
// import ErrorList from "@/components/commonDesign/ErrorList.vue";
// import Axios from "axios";
// @ts-ignore-start
// @ts-ignore-end
// const handleClientLoad =  import("../../../public/js/calendarAuth");

export default defineComponent({
  name: "AddNotification",
  props: {
    showModal: Boolean,
    notifications: Array,
  },
  components: {
    // ErrorList,
  },
  setup(props, { emit }) {
    const state = reactive({
      data: {
        showSuccesModal: false,
        SuccessFulMessage: "",
        notificationList: [] as any,
      },

      ui: {
        error: {
          hasError: false,
          errorList: {
            delete: [] as any,
          },
        },
        isLoading: {
          getFolderList: false,
        },
        isPostLoading: {
          deleteSelected: false,
        },
      },
    });
    const filterArray = (array: any, index: any) => {
      if (index > -1 && index < array.length) {
        array.splice(index, 1);
      }
      return array;
    };
    const addNotification = () => {
      // insert new notification in
      const newNotification = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        notify_type: 1,
        before: 10,
        // eslint-disable-next-line @typescript-eslint/camelcase
        before_type: 1,
      };
      state.data.notificationList.push(newNotification);
      emit("notificationList", state.data.notificationList);
    };
    const setNotificationBy = (type: number, selectedIndex: number) => {
      // insert new notification in
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.data.notificationList[selectedIndex].notify_type = type;
      emit("notificationList", state.data.notificationList);
    };
    const setUnitBy = (unit: number, selectedIndex: number) => {
      // insert new notification in
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.data.notificationList[selectedIndex].before_type = unit;
      emit("notificationList", state.data.notificationList);
    };
    const removeSelected = (selected: any) => {
      state.data.notificationList = filterArray(
        state.data.notificationList,
        selected
      );
      emit("notificationList", state.data.notificationList);
    };

    onMounted(() => {
      // if(props.notifications && props.notifications.length){
        if(props.notifications){
          setTimeout(() => {
            state.data.notificationList = props.notifications;
          }, 300);
        }

      // }
      if (props.showModal) {
        console.log("Props showModal");
      }
    });

    return {
      state,
      removeSelected,
      addNotification,
      setNotificationBy,
      setUnitBy,
      filterArray,
    };
  },
});
