import axios from "axios";
/* eslint-disable @typescript-eslint/class-name-casing */
/* eslint-disable @typescript-eslint/camelcase */
let is_user_signed_in = false

function showSignIn() {
  $('.g-signin2').show();
  $('#linkSignOut').hide();
}

function showSignOut() {
  $('.g-signin2').hide();
  $('#linkSignOut').show();
}

function signOut() {
    // console.log("HELLO");
  gapi.auth2.getAuthInstance().disconnect();
  const auth2 = gapi.auth2.getAuthInstance();
  auth2.signOut().then(function () {
      console.log('User signed out.');
 
      is_user_signed_in = false;

      showSignIn();
  });
  // var revokeAllScopes = function() {
    gapi.auth2.getAuthInstance().disconnect();
  // }
}
// function onLoad() {
//   gapi.load('auth2', function() {
//     gapi.auth2.init();
//   });
// }


export  { signOut }

