class ValidationService {
    public isValidEmail(email: string) {
      // tslint:disable-next-line:max-line-length
      // eslint-disable-next-line no-useless-escape
      const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
      return regexp.test(email);
    }
    public validateMobileNumber(mobileNumber: string) {
      // Regular expression for validating mobile numbers
      // const mobileRegex = /^\+?\d{11}$/;
      const mobileRegex = /^\+?[1-9]\d{10}$/; // Supports international numbers with a leading "+" and up to 15 digits
      return mobileRegex.test(mobileNumber);
    }
}
export const validationService = new ValidationService();
