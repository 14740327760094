
import { defineComponent, reactive, onMounted, ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import Button from "@/common/Button.vue";
import SuccessfulModalCustom from "@/components/modal/SuccessfulModalCustom.vue";

import { ethitransService } from "@/services/EthitransService";
import { validationService } from "@/common/ValidationService";
import { common } from "@/common/services/Common";

import ErrorList from "@/components/commonDesign/ErrorList.vue";
import ToDoPhasePaymentHistory from "@/components/todo/ToDoPhasePaymentHistory.vue";

export default defineComponent({
  name: "ToDoPhasePaymentApproval",
  props: {
    showAddPhase: {
      type: Boolean,
      required: true,
    },
    phase: {
      type: Object,
      required: true,
    },
    role: {
      type: Number,
      required: true,
    },
  },
  components: {
    Button,
    ErrorList,
    SuccessfulModalCustom,
    ToDoPhasePaymentHistory,
  },
  setup(props, { emit }) {
    const router = useRouter();
    const {
      params: { projectId },
    } = useRoute();
    const state = reactive({
      data: {
        paymentHistory: {
          contractorPaymentRequests: [] as any,
          payerPaymentRequests: [] as any,
          latest: {} as any,
        },
        percentage: 0,
        phaseBudget: 0,
        remarkDetails: "",
        paymentType: "",
        buttonName: "Approve",
        log: {
          todayDate: "",
        },
        imagesToUpload: [] as any,
        showSuccesCustomModal: false,
        successFulMessage: "",
      },
      ui: {
        error: {
          hasError: false,
          errorList: {
            paymentReleaseByPhase: [] as any,
          },
        },
        isLoading: {
          getPaymentHistoryByPhase: false,
        },
        isPostLoading: {
          paymentReleaseByPhase: false,
        },
      },
    });
    const dispatchedAmount = computed(() => {
      let amount = 0;
      // state.data.phaseBudget
      if (state.data.paymentType === "2") {
        amount = (state.data.phaseBudget * state.data.percentage) / 100;
        return amount;
      } else if (state.data.paymentType === "1") {
        return state.data.percentage;
      } else {
        return state.data.phaseBudget;
      }
    });
    function closeSuccessCustomModal() {
      state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
      $("#SuccessfulModalCustom").modal("hide");
    }
    function removeSelectedImage(image: any) {
      state.data.imagesToUpload = state.data.imagesToUpload.filter(
        (e: any) => e != image
      );
    }
    function hideTaskLogModal() {
      emit("close");
      $("#phaseTaskPaymentApprovalModal").modal("hide");
    }
    function update() {
      // if (props.editMode) {
      alert("EDIT MODE");
      //   updatePhase();
      // } else {
      //   createPhase();
      //   // alert("ADD MODE")
      // }
    }
    function validatePaymentRelease() {
      state.ui.error.errorList.paymentReleaseByPhase = [];
      state.ui.error.hasError = false;
      // if (
      //   state.data.selectedChannelByProject.channel &&
      //   !state.data.selectedChannelByProject.channel.uuid
      // ) {
      //   state.ui.error.errorList.paymentReleaseByPhase.push("Channel is required.");
      // }

      if (
        !state.ui.error.errorList.paymentReleaseByPhase &&
        !state.ui.error.errorList.paymentReleaseByPhase.length
      ) {
        state.ui.error.hasError = false;
      } else if (state.ui.error.errorList.paymentReleaseByPhase.length != 0) {
        state.ui.error.hasError = true;
      }
      console.log("Validation Here");
    }
    function paymentReleaseByPhase(status: number) {
      validatePaymentRelease();
      if (state.ui.isPostLoading.paymentReleaseByPhase) return false;
      if (
        state.ui.error.hasError ||
        state.ui.error.errorList.paymentReleaseByPhase != 0
      )
        return false;

      const payLoad = {
        id: state.data.paymentHistory.latest.uuid,
        status: status, // 1 accept, 2 declined
        type: state.data.paymentType, // 1 partial, 2 percentage, 3 full
        amount: dispatchedAmount.value, // 1 partial, 2 percentage, 3 full
        detail: state.data.remarkDetails, // 1 partial, 2 percentage, 3 full
      };
      console.log("CHECK PAYLOAD >>>>** ", payLoad);

      state.ui.isPostLoading.paymentReleaseByPhase = true;
      ethitransService
        .paymentRequestAcceptByPhase(payLoad)
        .then((res: any) => {
          // console.log("paymentReleaseByPhase:>>> ", res.data.data);
          hideTaskLogModal();
          // state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
          // state.data.successFulMessage = "Todo Created with Payer's Email Successfully.";
          // state.roleList = filteredRoles ? filteredRoles : [];
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.paymentReleaseByPhase)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.paymentReleaseByPhase.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.paymentReleaseByPhase = false;
        });
    }
    function getPaymentHistoryByPhase() {
      state.ui.error.errorList.paymentReleaseByPhase = [];
      state.ui.error.hasError = false;

      if (state.ui.isLoading.getPaymentHistoryByPhase) return false;
      state.ui.isLoading.getPaymentHistoryByPhase = true;
      ethitransService
        .paymentHistoryByPhase(props.phase.uuid.toString(), props.role)
        .then((res: any) => {
          console.log(
            "PaymentHistoryByPhase Response Check:>>> ",
            res.data.data
          );
          if (
            res.data.data.contractorPaymentRequests &&
            res.data.data.contractorPaymentRequests.length
          ) {
            res.data.data.contractorPaymentRequests.forEach((item: any) => {
              // eslint-disable-next-line @typescript-eslint/camelcase
              item.created_at = common.localDateAndTime(item.created_at);
            });
          }
          if (
            res.data.data.payerPaymentRequests &&
            res.data.data.payerPaymentRequests.length
          ) {
            res.data.data.payerPaymentRequests.forEach((item: any) => {
              // eslint-disable-next-line @typescript-eslint/camelcase
              item.created_at = common.localDateAndTime(item.created_at);
            });
          }
          state.data.paymentHistory = res.data.data;
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.paymentReleaseByPhase)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.paymentReleaseByPhase.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isLoading.getPaymentHistoryByPhase = false;
        });
    }
    onMounted(() => {
      if (props.showAddPhase) {
        const data = new Date();
        state.data.log.todayDate = data.toDateString();
        state.data.phaseBudget = props.phase.budget;
        state.data.paymentType = "2";
        $("#phaseTaskPaymentApprovalModal").modal("show");
        getPaymentHistoryByPhase();
      }
    });

    return {
      state,
      closeSuccessCustomModal,
      validatePaymentRelease,
      paymentReleaseByPhase,
      removeSelectedImage,
      hideTaskLogModal,
      update,
      dispatchedAmount,
    };
  },
});
