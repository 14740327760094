<template>
  <!-- <div class="send-schedule-container"> -->
  <!-- <div class="send-schedule-arrow" v-if="showEnterSetting">
                <a
                  title="Input Options"
                  href="javascript:void(0)"
                  @click="toogleEnterFeature"
                  ><i class="fas fa-cog"></i
                ></a>
              </div> -->
  <div
    :class="
      toogleEnterKey
        ? 'arrow-tooltip card'
        : 'arrow-tooltip card disappear-block'
    "
  >
    <p>
      When writing a message, press
      <span class="enter-btn">
        Enter <i class="fas fa-level-down-alt fa-rotate-90"></i
      ></span>
      to ...
    </p>
    <div class="form-check">
      <input
        class="form-check-input"
        type="radio"
        name="flexRadioDefault"
        id="flexRadioDefault1"
        v-model="enterSetting"
        value="message"
        @change="onChange($event)"
      />
      <label class="form-check-label" for="flexRadioDefault1">
        Send the message
      </label>
    </div>
    <div class="form-check">
      <input
        class="form-check-input"
        type="radio"
        name="flexRadioDefault"
        id="flexRadioDefault2"
        v-model="enterSetting"
        value="line"
        @change="onChange($event)"
      />
      <label class="form-check-label" for="flexRadioDefault2">
        Start a new line (use
        <span class="enter-btn"> Ctrl </span> +
        <span class="enter-btn">
          Enter <i class="fas fa-level-down-alt fa-rotate-90"></i
        ></span>
        to send)
      </label>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import { ethitransService } from "@/services/EthitransService";

export default {
  props: {
    toogleEnterKey: {
      type: Boolean,
      required: true,
    },
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data() {
    return {
      enterSetting: "line",
      // toogleEnterKey: false,
    };
  },

  // watch: {
  //   // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  //   items() {
  //     this.selectedIndex = 0;
  //   },
  // },

  methods: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    // toogleEnterFeature() {
    //   console.log(
    //     "CHECK VALUE >>> ",
    //     JSON.parse(localStorage.getItem("current_user_info")).enter_key
    //   );
    //   alert("check")
    //   this.toogleEnterKey = !this.toogleEnterKey;
    //   // if (
    //   //   JSON.parse(localStorage.getItem("current_user_info")).enter_key === 0
    //   // ) {
    //   //   // if (localStorage.getItem("enterKeySetting") === "true") {
    //   //   console.log("Message");
    //   //   this.enterSetting = "message";
    //   // } else {
    //   //   console.log("Line");
    //   //   this.enterSetting = "line";
    //   // }
    // }
    onChange(event) {
      const value = this.enterSetting;
      // console.log("CHECK THE VALUE >>> ", value);
      if (value === "message") {
        this.shiftEnter = !this.shiftEnter;

        const payLoad = {
          // eslint-disable-next-line @typescript-eslint/camelcase
          enter_key: 1,
        };
        // state.ui.isLoading.getSharedFiles = true;
        ethitransService
          .userEnterSettingUpdate(payLoad)
          .then((res) => {
            // localStorage.setItem("enterKeySetting", true);
            this.$emit("editorEnterSetting", true);
            console.log("sharedFormList Response Check:>>> ", res.data.data);
            localStorage.setItem(
              "current_user_info",
              JSON.stringify(res.data.data)
            );
            console.log("KEY CHECK  ALL>>>", JSON.parse(localStorage.getItem("current_user_info")))
            console.log("KEY CHECK >>>", JSON.parse(localStorage.getItem("current_user_info")).enter_key)
            // setTimeout(this.toogleEnterKey = !this.toogleEnterKey, 1000);
            // this.toogleEnterKey = !this.toogleEnterKey;
            // state.data.sharedFiles = res.data.data ? res.data.data : {};
            // // state.sharedFormList = res.data ? res.data : {};
          })
          .catch((error) => {
            // console.log(error);
            // common
            //   .sdCatchErr(error, state.item.error.erorList)
            //   .then((res: any) => {
            //     state.item.hasError = true;
            //     for (const [key, value] of Object.entries(res)) {
            //       if (Array.isArray(value)) {
            //         state.item.error.erorList.push(value[0]);
            //       }
            //     }
            //   });
          })
          .finally(() => {
            // state.ui.isLoading.getSharedFiles = false;
          });
      } else {
        this.shiftEnter = !this.shiftEnter;
        const payLoad = {
          // eslint-disable-next-line @typescript-eslint/camelcase
          enter_key: 0,
        };
        // state.ui.isLoading.getSharedFiles = true;
        ethitransService
          .userEnterSettingUpdate(payLoad)
          .then((res) => {
            this.$emit("editorEnterSetting", false);
            // localStorage.setItem("enterKeySetting", false);
            console.log("sharedFormList Response Check:>>> ", res.data.data);
            localStorage.setItem(
              "current_user_info",
              JSON.stringify(res.data.data)
            );
            console.log("KEY CHECK  ALL>>>", JSON.parse(localStorage.getItem("current_user_info")))
            console.log("KEY CHECK >>>", JSON.parse(localStorage.getItem("current_user_info")).enter_key)
            // setTimeout(this.toogleEnterKey = !this.toogleEnterKey, 1000);
            // state.data.sharedFiles = res.data.data ? res.data.data : {};
            // // state.sharedFormList = res.data ? res.data : {};
          })
          .catch((error) => {
            // console.log(error);
            // common
            //   .sdCatchErr(error, state.item.error.erorList)
            //   .then((res: any) => {
            //     state.item.hasError = true;
            //     for (const [key, value] of Object.entries(res)) {
            //       if (Array.isArray(value)) {
            //         state.item.error.erorList.push(value[0]);
            //       }
            //     }
            //   });
          })
          .finally(() => {
            // state.ui.isLoading.getSharedFiles = false;
          });
      }
    },
  },
  mounted() {
    if (JSON.parse(localStorage.getItem("current_user_info")).enter_key === 1) {
      this.enterSetting = "message";
      // this.$emit("editorEnterSetting", true);
    } else {
      console.log("Line");
      this.enterSetting = "line";
      // this.$emit("editorEnterSetting", false);
    }
    // console.log("KEY CHECK  ALL>>>", JSON.parse(localStorage.getItem("current_user_info")))
    // console.log("KEY CHECK >>>", JSON.parse(localStorage.getItem("current_user_info")).enter_key)
  },
};
</script>