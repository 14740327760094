
import { defineComponent, reactive } from "vue";
export default defineComponent({
  name: "TitleComponent",
  props: {
    title: String,
    description: String,
    editStatus: Boolean,
  },
  setup(props) {
    const state = reactive({
      title: "" as any,
      description: "" as any,
    });
    function showTextFieldAdd() {
      state.title = props.title;
      state.description = props.description;
      $("#editTitle").modal("show");
    }
    function addTextField() {
      console.log("Add the Edit Title and Description");
    }
    return { state, showTextFieldAdd, addTextField };
  },
});
