
import { defineComponent, reactive, onMounted, computed } from "vue";
import { ethitransService } from "@/services/EthitransService.ts";
import { common } from "@/common/services/Common.ts";
import { config } from "@/Config.ts";

import EmptyData from "@/components/commonDesign/EmptyData.vue";

// import FormView from "@/components/cards/FormView.vue";
export default defineComponent({
  name: "DigitizationLibrary",
  components: {
    EmptyData,
  },
  setup() {
    const state = reactive({
      data: {
        imageBaseUrl: '',
        title: "pdf",
        heading: "No pdf uploaded to digitize the form.",
        clientDocuments: [] as any,
        searchUser: "",
        paging: {
          clientDocumentList: {
            perPage: 10,
            totalDocs: 0,
            totalPages: 0,
            currentPage: 1,
          },
        },
      },
      ui: {
        isLoading: {
          getAccountFormList: false,
          clientDocumentList: false,
        },
        isPostLoading: {
          uploadClientDocument: false,
          uploadSharableLink: false,
        },
      },
      error: {
          hasError: false,
          erorList: {
            uploadClientDocument : [] as any
          },
      }
    });

    const pdfDigitizeList = computed(()=> {
      if(state.data.clientDocuments && state.data.clientDocuments.length){
        state.data.clientDocuments.forEach((item: any)=> {
          item.showSaveButton = false;
          if(item.tempShareableLink){
            item.showSaveButton = true;
          }
          if(item.tempEdocId){
            item.edocIdSave = true;

          }

        })
      }
      return state.data.clientDocuments;
    })
    function clientDocumentList(){
      const payLoad = {
        type:"all" 
      }
      state.ui.isLoading.clientDocumentList = true;
      ethitransService
        .clientDocumentList(payLoad)
        .then((res: any) => {
          // console.log("clientDocumentList :>>> ", res.data.data);
          if(res.data.data && res.data.data.length){
            res.data.data.forEach((item: any) => {
              // item.edoc_id = '602601e328d8de01d89e5a9e';
              item.tempShareableLink = '';
              item.tempEdocId = '';
              item.edocIdSave = false;
              item.enableSave = false;
              item.convertedFileSize = common.formatBytes(
                      parseInt(
                        item.file_size
                      )
                    );
            })
            state.data.clientDocuments = res.data.data;
          } else {
            state.data.clientDocuments = [];
          }
        })
        .catch((error: any) => {
          console.log(error)
        })
        .finally(() => {
          state.ui.isLoading.clientDocumentList = false;
        });

    }
    function openPdf(pdfLink: any) {
      // alert(pdfLink)
      window.open(pdfLink);
    }
    function uploadSharableLink(item: any){
      console.log("upload shareable Link", item.id)
      if(state.ui.isPostLoading.uploadSharableLink || !item.uuid ) return false;
      // if(state.ui.isPostLoading.uploadSharableLink || !item.uuid || !item.tempShareableLink) return false;
      const payLoad = {
        id : item.uuid,
        // eslint-disable-next-line @typescript-eslint/camelcase
        edoc_id : item.tempShareableLink,
        // edoc_id : '602601e328d8de01d89e5a9e',
        // eslint-disable-next-line @typescript-eslint/camelcase
        // shareable_link : item.tempShareableLink,
      };
      state.ui.isPostLoading.uploadSharableLink = true;
      // document.deleteStatus = true;
      ethitransService
        .clientDocumentStatusUpdate(payLoad)
        .then((res: any) => {
          console.log("uploadSharableLink :>>> ", res.data.data);
          if(res.data.data && res.data.data.length){
            res.data.data.forEach((item: any) => {
              item.tempShareableLink = '';
              item.enableSave = false;
              item.convertedFileSize = common.formatBytes(
                      parseInt(
                        item.file_size
                      )
                    );
            })
            state.data.clientDocuments = res.data.data;
          } else {
            state.data.clientDocuments = [];
          }
        })
        .catch((error: any) => {
          console.log(error)
        })
        .finally(() => {
          state.ui.isPostLoading.uploadSharableLink = false;
        });

    }
    function copyDocumentSharableLink(inputId: any) {
      // console.log("CHECK INPUT ID >>>", inputId)
      const copyText: any = document.getElementById(inputId);

      // console.log("CHECK INPUT >>>", copyText)
      /* Select the text field */
      copyText.select(); 
      copyText.setSelectionRange(0, 99999); /* For mobile devices */

      // /* Copy the text inside the text field */
      document.execCommand("copy");
    }
    function showEditable(document: any) {
      console.log("check for Editable", document )
      document.enableSave = true;
      document.tempShareableLink = document.edoc_id ;
      // document.tempShareableLink = document.shareable_link ;
    }
    function resetSharableLink(document: any){
      // console.log("CHECK", document)
      if(!document.shareable_link){
        document.tempShareableLink = "";
      }
      document.enableSave = false;
      // document.enableSave = false;
    }
    function resetEdocId(document: any){
      // console.log("CHECK", document)
      if(!document.edoc_id){
        document.tempEdocId = "";
      }
      document.edocIdSave = false;
      // document.enableSave = false;
    }
    onMounted(() => {
      state.data.imageBaseUrl = config.imageBaseUrl;
      clientDocumentList()
    });
    return {
      state,resetEdocId,uploadSharableLink,openPdf, copyDocumentSharableLink, pdfDigitizeList, showEditable, resetSharableLink
    };
  },
});
