
import { defineComponent, onMounted, reactive } from "vue";
import ErrorList from "@/components/commonDesign/ErrorList.vue";
import { ethitransService } from "@/services/EthitransService";
import { common } from "@/common/services/Common";
import Multiselect from "@vueform/multiselect";
import SuccessfulModalCustom from "@/components/modal/SuccessfulModalCustom.vue";
import { config } from "@/Config";
import { useRoute } from "vue-router";
// import Axios from "axios";
// @ts-ignore-start
import handleClientLoad = require("../../../public/js/calendarAuth.js");
// @ts-ignore-end
// const handleClientLoad =  import("../../../public/js/calendarAuth");

export default defineComponent({
  name: "AppointmentDetailModal",
  props: {
    showModal: Boolean,
    eventId: Number
  },
  components: {
    ErrorList,
    Multiselect,
    SuccessfulModalCustom,
  },
  setup(props, { emit }) {
    const gapi = window.gapi;
    const router = useRoute();
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const moment = require("moment");

    const state = reactive({
      data: {
        showSuccesCustomModal: false,
        SuccessFulMessage: "",

        range: {
          start: new Date(),
          end: new Date(),
        },
        tags: {
          mode: "tags",
          value: [] as any,
          options: [] as any,
          searchable: true,
          createTag: true,
        },
        masks: {
          input: "YYYY-MM-DD h:mm A",
        },
        meetingVia: [
          {
            name: "Google",
            value: "google",
          },
          {
            name: "Yahoo",
            value: "yahoo",
          },
          {
            name: "Outlook",
            value: "outlook",
          },
          {
            name: "ICloud",
            value: "icloud",
          },
          {
            name: "Physical",
            value: "physical",
          },
        ],
      },

      ui: {
        error: {
          hasError: false,
          errorList: {
            delete: [] as any,
          },
        },
        isLoading: {
          getFolderList: false,
        },
        isPostLoading: {
          deleteSelected: false,
        },
      },
      routeValue: router.query.showModel,
      errorList: [] as any,
      isErrorStatus: false,
      postLoadingCreateAppointmen: false,
      meetingType: "" as any,
      title: "" as string,
      location: "" as string,
      note: "" as string,
      inviteUesr: [] as any,
      meetingUrl: "" as string,
      meetingCode: "" as string,
      googleMeetURL: "" as any,
    });

    function getEventDetail(eventId: number) {
      ethitransService
        .getAppointmentDetail(eventId)
        .then((res: any) => {
          debugger;
          console.log("invite users:>>> ", res.data.data);
          state.title = res.data.data.title;
          state.meetingType = res.data.data.appointment_via;
          state.note = res.data.data.note;
          state.data.range.start = res.data.data.start_time;
          state.data.range.end = res.data.data.end_time;
        })
        .catch((error: any) => {

          //consoloe.log(error);
        })
        .finally(() => {
          // state.postLoading = false;
        });
    }

    function saveEventGoogleCalendar() {
      // state.postLoadingCreateAppointmen = true;

      return new Promise((resolve, reject) => {
        const bolVal = true as any;
        const starTime = state.data.range.start as any;
        const endTime = state.data.range.end as any;
        const emailLists = [] as any;
        state.data.tags.value.length > 0 &&
          state.data.tags.value.forEach((element: any) => {
            emailLists.push({ email: element });
          });
        const event = {
          summary: state.title,
          location: state.location,
          description: state.note,
          conferenceData: {
            conferenceSolution: bolVal,
          },
          start: {
            dateTime: moment(starTime).format(),
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
          end: {
            dateTime: moment(endTime).format(),
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
          recurrence: ["RRULE:FREQ=DAILY;COUNT=1"],
          attendees: emailLists,
          reminders: {
            useDefault: false,
            overrides: [
              {
                method: "email",
                minutes: 24 * 60,
              },
              {
                method: "popup",
                minutes: 10,
              },
            ],
          },
        };
        const request = gapi.client.calendar.events.insert({
          calendarId: "primary",
          resource: event,
        });
        request.execute(function (event: any) {
          const eventPatch = {
            conferenceData: {
              createRequest: {
                requestId: "7qxalsvy90",
                conferenceSolutionKey: {
                  type: "hangoutsMeet",
                },
              },
            },
          };

          gapi.client.calendar.events
            .patch({
              calendarId: "primary",
              eventId: event.id,
              resource: eventPatch,
              sendNotifications: true,
              conferenceDataVersion: 1,
            })
            .execute((data: any) => {
              console.log("%%%%%%%%%", data);
              console.log("hangout link", data.hangoutLink);
              state.googleMeetURL = data.hangoutLink;
              resolve(state.googleMeetURL);
              // state.postLoadingCreateAppointmen = false;
            });
        });
      });
    }

    //add new meeting using zoom

    function addZoomMeeting() {
      // state.postLoadingCreateAppointmen = true;

      return new Promise((resolve, reject) => {
        const payLoad = {
          title: state.title,
          note: state.note,
          startTime: state.data.range.start,
          endTime: state.data.range.end,
          token: localStorage.getItem("zoom_access_token"),
        };
        ethitransService
          .createZoomMeeting(payLoad)
          .then((res: any) => {
            console.log("zoom res", res);
            console.log("zoom res", res.data.join_url);
            state.googleMeetURL = res.data.join_url;
            resolve(state.googleMeetURL);
          })
          .catch((error: any) => {
            console.log(error);
          })
          .finally(() => {
            // state.postLoadingCreateAppointmen = false;
          });
      });
    }

    function closeAppointmentModal() {
      emit("addAppointment", "addAppointment success");
      $("#appointmentCreateModel").modal("hide");
    }

    function addAppointment() {
      emit("addAppointment", "addAppointment success");
      $("#appointmentCreateModel").modal("hide");
    }

    function validateForm() {
      state.errorList = [];
      state.isErrorStatus = false;

      if (!state.title) {
        state.errorList.push("Appointment title is required.");
      }
      if (!state.meetingType) {
        state.errorList.push("Meeting via is required.");
      }

      if (state.data.tags.value.length == 0) {
        state.errorList.push("Invite user is required.");
      }

      if (!state.errorList && !state.errorList.length) {
        state.isErrorStatus = false;
      } else if (state.errorList.length != 0) {
        state.isErrorStatus = true;
      }
    }
    function scrollToTop() {
      $("#appointmentModelScroll").animate({
        scrollTop: $("#appointmentModelScroll")[0],
      });
    }

    function addNewAppointment() {
      if (
        state.errorList.length != 0 ||
        state.isErrorStatus ||
        state.postLoadingCreateAppointmen
      )
        return false;
      const payLoad = {
        title: state.title,
        note: state.note,
        location: state.location,
        meetingType: state.meetingType,
        inviteUser: state.data.tags.value,
        meetingUrl: state.meetingUrl ? state.meetingUrl : state.googleMeetURL,
        meetingCode: state.meetingCode,
        startTime: state.data.range.start,
        endTime: state.data.range.end,
      };
      state.postLoadingCreateAppointmen = true;
      ethitransService
        .createAppointment(payLoad)
        .then((res: any) => {
          $("#appointmentCreateModel").modal("hide");
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
          const data = {
            start: res.data.data.start_time,
            end: res.data.data.end_time,
            title: res.data.data.title,
            class: "blue-event",
            content: "Meeting Details:" + res.data.data.note,
          };
          emit("calendarData", data);

          state.data.SuccessFulMessage = "Created Project Successfully.";
        })
        .catch((error: any) => {
          console.log(error);
          common.sdCatchErr(error, state.errorList).then((res: any) => {
            state.isErrorStatus = true;
            for (const [key, value] of Object.entries(res)) {
              if (Array.isArray(value)) {
                state.errorList.push(value[0]);
              }
            }
          });
        })
        .finally(() => {
          state.postLoadingCreateAppointmen = false;
        });
    }

    async function saveAppointment() {
      scrollToTop();
      validateForm();
      if (state.meetingType === "google" && !state.errorList.length) {
        state.postLoadingCreateAppointmen = true;
        const a = await saveEventGoogleCalendar();
        state.postLoadingCreateAppointmen = false;

        console.log("await here", a);
      }
      if (state.meetingType === "zoom" && !state.errorList.length) {
        state.postLoadingCreateAppointmen = true;
        await addZoomMeeting();
        state.postLoadingCreateAppointmen = false;
      }
      addNewAppointment();
    }

    onMounted(() => {
      console.log("CHECK >> ", typeof props.showModal);
       const eId = props.eventId?props.eventId:0;
      //  console.log('hello test',moment(state.data.range.start).format());

      if (props.showModal && eId > 0) {
        $("#appointmentCreateModel").modal("show");
        console.log("route here", router.query.code);
        getEventDetail(eId);
      }
    });

    return {
      state,
      addAppointment,
      closeAppointmentModal,
      getEventDetail,
      validateForm,
      addNewAppointment,
      scrollToTop,
      // checkGoolgeLogin,
      saveEventGoogleCalendar,
      saveAppointment,
      addZoomMeeting
    };
  },
});
