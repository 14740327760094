
import { useCurrencyInput } from 'vue-currency-input'
import { watchDebounced } from '@vueuse/core'

export default {
  name: 'DebouncedCurrencyInput',
  props: {
    modelValue: Number, // Vue 2: value
    options: Object
  } as any,
  // setup (props: any, { emit }) {
  //   // const { inputRef, numberValue } = useCurrencyInput(props.options, false)

  //   // watchDebounced(numberValue, (value) => emit('update:modelValue', value), { debounce: 1000 }) // Vue 2: emit('input', value)

  //   // return { inputRef }
  // }
}
