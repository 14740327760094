
import { defineComponent, onMounted, reactive } from "vue";
// import { ethitransService } from "@/services/EthitransService";
import { config } from "@/Config";
import ToDoListUserPermissionDetail from '@/components/todo/ToDoListUserPermissionDetail.vue';

export default defineComponent({
  name: "ToDoMembersListForTaskTable",
  components: {ToDoListUserPermissionDetail,},
  props: {
    projectMembers: {
      type: Array,
      required: true,
    },
    activePhase: {
      type: Object,
      required: true,
    }
  },
  setup() {
    const state = reactive({
      data: {
        imageBaseUrl: "",
        showUesrDetails: false,
        myDriveFileFolderLoad: false,
        selectedMember:{} as any,
      },
      ui: {
        error: {
          hasError: false,
          errorList: {
            uploadAttachments: [] as any,
          },
        },
        isLoading: {
          getFolderList: false,
        },
        isPostLoading: {
          enableSelectedFileTag: false,
        },
      },
    });
    function showUserDetails(member: any) {
      console.log("User Details >>> ", member)
      state.data.selectedMember = member;
      state.data.showUesrDetails = true;
    }
    function closeUserPermissionDetail() {
      state.data.showUesrDetails = !state.data.showUesrDetails;
    }
    onMounted(() => {
      state.data.imageBaseUrl = config.imageBaseUrl;
    });

    return {
      state,
      closeUserPermissionDetail,
      showUserDetails,
    };
  },
});
