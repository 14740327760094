import { renderSlot as _renderSlot, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal-wrap default-modal" }
const _hoisted_2 = {
  class: "modal fade",
  id: "renameModal",
  tabindex: "-1",
  "aria-labelledby": "renameModalLabel",
  "aria-hidden": "true"
}
const _hoisted_3 = { class: "modal-dialog modal-dialog-centered modal-sm" }
const _hoisted_4 = { class: "modal-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "default")
        ])
      ])
    ])
  ]))
}