<template>
  <div>
    <h2>Enter Email or US Mobile Number:</h2>
    <EmailOrMobileInput v-model="validatedInput" />
    <p v-if="validatedInput">Validated Input: {{ validatedInput }}</p>
  </div>
</template>

<script>
import { ref } from 'vue';
import EmailOrMobileInput from '@/components/commonDesign/EmailOrMobileInput.vue';

export default {
  name: 'UserInputTest',
  components: {
    EmailOrMobileInput,
  },
  setup() {
    const validatedInput = ref(''); // Stores validated value from child component

    return {
      validatedInput,
    };
  },
};
</script>

<style scoped>
/* Custom styles */
</style>
