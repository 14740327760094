
import { defineComponent, onMounted, reactive, computed } from "vue";
import { useRouter } from "vue-router";
import EmptyData from "@/components/commonDesign/EmptyData.vue";
import AppDeleteConfirmationModal from "@/common/AppDeleteConfirmationModal.vue";


// import ErrorList from "@/components/commonDesign/ErrorList.vue";
import { ethitransService } from "@/services/EthitransService";
import { common } from "@/common/services/Common";

export default defineComponent({
  name: "Subscription",
  components: {
    EmptyData,
    AppDeleteConfirmationModal
    // ErrorList,
  },
  setup() {
    const router = useRouter();
    const state = reactive({
      data: {
        showSuccesModal: false,
        SuccessFulMessage: "",
        notificationList: [] as any,
        showDeleteConfirmationModal: false,
        deleteModalMessage: "Selected Subscription will be Delete. Please Confirm.",
        selectedIndex: 0,
        selectedSubscription: {} as any,
        title: "subscriptions",
        heading: "Get started by creating a new subscription.",
        searchBy: "",
        subscriptionList: [] as any,
      },

      ui: {
        error: {
          hasError: false,
          errorList: {
            delete: [] as any,
            subscription: [] as any,
          },
        },
        isLoading: {
          getFolderList: false,
        },
        isPostLoading: {
          deleteSelected: false,
        },
      },
    });
    const redirectToCreateSubscription = () => {
      router.push({
        name: "admin-create-subscription",
      });
    }
    const filterArray = (array: any, index: any) => {
      if (index > -1 && index < array.length) {
        array.splice(index, 1);
      }
      return array;
    };
    const addNotification = () => {
      // insert new notification in
      const newNotification = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        notify_type: 1,
        before: 10,
        // eslint-disable-next-line @typescript-eslint/camelcase
        before_type: 1,
      };
      state.data.notificationList.push(newNotification);
    };
    const setNotificationBy = (type: number, selectedIndex: number) => {
      // insert new notification in
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.data.notificationList[selectedIndex].notify_type = type;
    };
    const setUnitBy = (unit: number, selectedIndex: number) => {
      // insert new notification in
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.data.notificationList[selectedIndex].before_type = unit;
    };
    const removeSelected = (selected: any) => {
      state.data.notificationList = filterArray(
        state.data.notificationList,
        selected
      );
    };

    const removeSelectedMember = () => {
      ethitransService
        .deleteSubscription(state.data.selectedSubscription.uuid)
        .then((res: any) => {
          filterArray(state.data.subscriptionList, state.data.selectedIndex);
          state.data.showDeleteConfirmationModal = !state.data.showDeleteConfirmationModal;
          // console.log("Check the detailsBySubscriptionId List >>> ", res.data.data)

        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.ui.error.errorList.subscription)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.subscription.push(value[0]);
                }
              }
            });
        })


    }
    // const addNotification = () => {
    //   // insert new notification in
    //   const newNotification = {
    //     // eslint-disable-next-line @typescript-eslint/camelcase
    //     notify_type: 1,
    //     before: 10,
    //     // eslint-disable-next-line @typescript-eslint/camelcase
    //     before_type: 1,
    //   };
    //   state.data.notificationList.push(newNotification);
    // };
    // const setNotificationBy = (type: number, selectedIndex: number) => {
    //   // insert new notification in
    //   // eslint-disable-next-line @typescript-eslint/camelcase
    //   state.data.notificationList[selectedIndex].notify_type = type;
    // };
    // const setUnitBy = (unit: number, selectedIndex: number) => {
    //   // insert new notification in
    //   // eslint-disable-next-line @typescript-eslint/camelcase
    //   state.data.notificationList[selectedIndex].before_type = unit;
    // };
    // const removeSelected = (selected: any) => {
    //   state.data.notificationList = filterArray(
    //     state.data.notificationList,
    //     selected
    //   );
    // };\
    const selecteSubscriptionForDelete = (index: number, selectedSubscription: any) => {
      console.log("Hello");
      state.data.showDeleteConfirmationModal = true;
      state.data.selectedIndex = index;
      state.data.selectedSubscription = selectedSubscription
    }

    const redirectToDetailsSubscription = (subscription: string) => {

      router.push({
        name: "admin-create-subscription",
        query: { subId: subscription, mode: 'details' },
      });
    }
    // list the label for subscription UI
    const subscriptionList = () => {
      // subscriptionLabelList
      ethitransService
        .subscriptionList()
        .then((res: any) => {
          console.log("Check the Subscription List >>> ", res.data.data)
          state.data.subscriptionList = res.data.data;
          if (state.data.subscriptionList.length === 0) {
            state.data.subscriptionList.push({
              'name': 'Free',
              'subscriptionBy': 'Prabhu',
              'uuid': 1234,
            })
          }
        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.ui.error.errorList.subscription)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.subscription.push(value[0]);
                }
              }
            });
        })
    };
    const filteredPackages = computed(() => {
      if (!state.data.searchBy) {
        return state.data.subscriptionList;
      }
      return state.data.subscriptionList.filter((pkg: any) =>
        pkg.name.toLowerCase().includes(state.data.searchBy.toLowerCase())
      );
    });

    onMounted(() => {
      subscriptionList();
      // if (props.showModal) {
      //   console.log("Props showModal");
      // }
    });

    return {
      state,
      redirectToCreateSubscription,
      removeSelected,
      addNotification,
      setNotificationBy,
      setUnitBy,
      filterArray,
      removeSelectedMember,
      selecteSubscriptionForDelete,
      filteredPackages,
      subscriptionList,
      redirectToDetailsSubscription,
      // removeSelected,
      // addNotification,
      // setNotificationBy,
      // setUnitBy,
      // filterArray,
    };
  },
});
