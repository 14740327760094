
import {
    defineComponent,
    reactive,
    onMounted,
    ref,
    watch,
    computed,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { ethitransService } from "@/services/EthitransService";
import { common } from "@/common/services/Common";
import { config } from "@/Config";

export default defineComponent({
    name: "SmartContractPreview",

    setup(props, { emit }) {
        const router = useRouter();
        const {
            params: { contractId },
        } = useRoute();
        const state = reactive({
            contractId: contractId,
            data: {
                smartContractDtail: {} as any,
                companyInfo: {} as any,
                phases: [] as any,
                imageBaseUrl: '' as any,
            },
            ui: {
                error: {
                    hasError: false,
                    errorList: {
                        createPhase: [] as any,
                    },
                },
                isLoading: {
                    getToDoData: false,
                    enableEndDate: true,
                    getSmartContractDetails: false,
                },
                isPostLoading: {
                    createPhase: false,
                    getPhaseDetail: false,
                },
            },
        });

        function getSmartContractDetails() {
            state.ui.isLoading.getSmartContractDetails = true
            ethitransService
                .getSmartContractDetails(state.contractId)
                .then((res: any) => {

                    state.data.smartContractDtail = res.data.data;
                    state.data.smartContractDtail.budget = common.usdPriceFormate(state.data.smartContractDtail.budget);
                    // eslint-disable-next-line @typescript-eslint/camelcase  
                    state.data.smartContractDtail.start_date = common.localDateOnly(state.data.smartContractDtail.start_date);
                    // eslint-disable-next-line @typescript-eslint/camelcase  
                    state.data.smartContractDtail.end_date = common.localDateOnly(state.data.smartContractDtail.end_date);

                    // eslint-disable-next-line @typescript-eslint/camelcase  
                    state.data.smartContractDtail.signature_date = common.localDateOnly(res.data.data.signature_date)
                    // eslint-disable-next-line @typescript-eslint/camelcase  
                    state.data.smartContractDtail.singed_customers.forEach((customer: any) => {
                        // eslint-disable-next-line @typescript-eslint/camelcase  
                        customer.signaute_date = common.localDateOnly(customer.signaute_date)
                    })
                    state.data.phases = res.data.data.phases;
                    state.data.phases.forEach((phase: any) => {
                        phase.budget = common.usdPriceFormate(phase.budget);

                        // eslint-disable-next-line @typescript-eslint/camelcase  
                        phase.start_date = common.localDateOnly(phase.start_date);
                        // eslint-disable-next-line @typescript-eslint/camelcase  
                        phase.end_date = common.localDateOnly(phase.end_date);
                        phase.child_phases.forEach((child: any) => {
                            child.budget = common.usdPriceFormate(child.budget);
                            child.startDate = child.start_date ? common.localDateOnly(child.start_date) : '-';
                            child.endDate = child.end_date ? common.localDateOnly(child.end_date) : '-';
                        });

                    });
                    state.data.companyInfo = res.data.data.company;
                })
                .catch((error: any) => {
                    console.log(error);
                })
                .finally(() => {
                    state.ui.isLoading.getSmartContractDetails = false;
                });
        }

        function downoadPdf(url: any) {

            window.open(url, '_blank');
        }

        function goToSmartContract() {
            router.push({
                name: "smart-contract-list",
                params: { projectId: state.data.smartContractDtail.project_id },
            });
        }
        function handleImageInNewTab(url: any){
            window.open(config.liveImageBaseUrl+url, '_blank');
        }


    function usdPriceFormate(data: any) {
            if (data != null) {
                return data.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                });
            }

        }

        onMounted(() => {
            state.data.imageBaseUrl = config.imageBaseUrl;
            getSmartContractDetails();
        });

        return {
            state,
            handleImageInNewTab,
            getSmartContractDetails,
            downoadPdf,
            goToSmartContract,
            usdPriceFormate
        };
    },
});
