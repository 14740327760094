
import { defineComponent, reactive } from "vue";
import { ethitransService } from "@/services/EthitransService";


export default defineComponent({
  name: "ToDoPhasePaymentHistory",
  props: {
    payerContractor: {
      type: Boolean,
      required: true,
    },
    history: {
      type: Array,
      required: true,
    },
  },
  components: {},
  setup(props, { emit }) {
    const state = reactive({
      data: {
        buttonName: "Request",
      },
      ui: {
        error: {
          hasError: false,
          errorList: {
            paymentRequestByPhase: [] as any,
          },
        },
        isLoading: {
          getPaymentHistoryByPhase: false,
        },
        isPostLoading: {
          acknowledgePayment: false,
        },
      },
    });
    function acknowledgePayment(data: any) {
      console.log("CHECK DATA >>> ", data)
      
      // validatePaymentRelease();
      if (state.ui.isPostLoading.acknowledgePayment) return false;
      // if (state.ui.error.hasError || state.ui.error.errorList.acknowledgePayment != 0) return false;
      // if (state.ui.error.hasError || state.ui.error.errorList.acknowledgePayment != 0) return false;

      const payLoad = {
        id: data.uuid,
        status: 4, // 1 accept, 2 declined, 4 accepted
        type: data.payment.type, // 1 partial, 2 percentage, 3 full
        amount: data.payment.amount, // 1 partial, 2 percentage, 3 full
        detail: 'Acknowledged', // 1 partial, 2 percentage, 3 full
      };
      console.log("CHECK PAYLOAD >>>>** ", payLoad);

      state.ui.isPostLoading.acknowledgePayment = true;
      ethitransService
        .paymentRequestAcceptByPhase(payLoad)
        .then((res: any) => {
          // emit("close");
          emit("acknowledgeData", res.data.data);
          // console.log("acknowledgePayment:>>> ", res.data.data);
          // hideTaskLogModal();
          // state.data.showSuccesModal = !state.data.showSuccesModal;
          // state.data.successFulMessage = "Todo Created with Payer's Email Successfully.";
          // state.roleList = filteredRoles ? filteredRoles : [];
        })
        .catch((error: any) => {
          console.log(error);
          // common
          //   .sdCatchErr(error, state.ui.error.errorList.acknowledgePayment)
          //   .then((res: any) => {
          //     state.ui.error.hasError = true;
          //     for (const [key, value] of Object.entries(res)) {
          //       if (Array.isArray(value)) {
          //         state.ui.error.errorList.acknowledgePayment.push(value[0]);
          //       }
          //     }
          //   });
        })
        .finally(() => {
          state.ui.isPostLoading.acknowledgePayment = false;
        });
    }

    return {
      state,
      acknowledgePayment,
    };
  },
});
