<script>
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
export default {
  components: {
    VueCal,
  },
  props: {
    minDate: {
      type: String,
      required: true,
    },
    maxDate: {
      type: String,
      required: true,
    },
    events: {
      type: Array,
    },
    tasks: {
      type: Array,
    },
    phaseDetails: {
      type: Object,
    },
    activeTab: {
      type: String,
    },
  },
  // Properties returned from data() become reactive state
  // and will be exposed on `this`.
  data() {
    return {
      count: 0,
      dynamicEventTitle: "Phase Prabhu 1",
      appointmentList: [],
    };
  },

  // Methods are functions that mutate state and trigger updates.
  // They can be bound as event handlers in templates.
  methods: {
    increment() {
      this.count++;
    },
    createDynamicEvent(event) {
      let slotTitle = "";
      const selectedPhase = this.$props.phaseDetails;
      if (this.$props.activeTab === "edit") {
        slotTitle = `${selectedPhase.phase.parent_phase.name} - ${selectedPhase.phase.name}`;
        console.log("TEST SLOT NAME >>> ", slotTitle);
        // console.log("TEST NAME >>> ", selectedPhase.phase.name)
      } else if (this.$props.activeTab === "phase") {
        slotTitle = selectedPhase.phase.name;
        console.log("TEST SLOT NAME >>> ", slotTitle);
        console.log("TEST NAME >>> ", selectedPhase.phase.name);
      } else {
        slotTitle = `${selectedPhase.phase.name} - ${this.$props.tasks[0].name}`;
        console.log("else TEST NAME >>> ", slotTitle);
      }
      // Use the dynamic title for the event
      this.$refs.vuecal.createEvent(event, 120, {
        title: slotTitle,
        class: "blue-event",
      });
      // console.log("CHECK >>>> ", event)
    },
    createdEvent(data) {
      // console.log("CHECK CLICK EVENT CREATED>>>> ", data.event)
      this.$emit("createdEvent", data.event);
    },
    onEventDelete(event) {
      console.log("DELETE >> ", event);
      // console.log("<<< Deleted >> ", event);
      this.$emit("deletedEvent", event);
    },
  },

  // Lifecycle hooks are called at different stages
  // of a component's lifecycle.
  // This function will be called when the component is mounted.
  mounted() {
    console.log(`The initial count is ${this.count}.`);
    this.appointmentList = this.$props.events;
  },
};
</script>

<template>
  <vue-cal
    :events="appointmentList"
    ref="vuecal"
    :min-date="minDate"
    :max-date="maxDate"
    :time="true"
    :time-from="0 * 60"
    :time-to="23 * 60"
    :time-step="30"
    style="height: 77vh"
    class="vuecal--blue-theme vuecal--full-height-delete"
    :disable-views="['years', 'year']"
    :cell-click-hold="false"
    :drag-to-create-event="false"
    events-on-month-view="short"
    active-view="month"
    editable-events
    @cell-dblclick="createDynamicEvent"
    @event-change="createdEvent"
    @event-delete="onEventDelete"
  >
  </vue-cal>
</template>
