
  import { defineComponent, ref, onMounted, watch } from 'vue';
  import { Loader } from '@googlemaps/js-api-loader';
  
  export default defineComponent({
    name: 'GoogleMap',
    props: {
      latitude: {
        type: Number,
        required: true
      },
      longitude: {
        type: Number,
        required: true
      },
      address: {
        type: String,
        default: 'No Address Provided'
      },
      visible: {
        type: Boolean,
        default: true
      }
    },
    setup(props) {
      const mapContainer = ref<HTMLElement | null>(null);
      let map: google.maps.Map | null = null;
      let marker: google.maps.Marker | null = null;
      let infoWindow: google.maps.InfoWindow | null = null;
  
      const initializeMap = async () => {
        const loader = new Loader({
          apiKey: 'AIzaSyD9947HYdSc5v8mEFxoEsHFjcQXF5P_jmc',
          version: 'weekly',
        });
  
        try {
          const google = await loader.load();
          if (mapContainer.value) {
            map = new google.maps.Map(mapContainer.value, {
              center: { lat: props.latitude, lng: props.longitude },
              zoom: 15,
            });
  
            const coordinate = { lat: props.latitude, lng: props.longitude };
            const address = props.address;
  
            marker = new google.maps.Marker({
              position: coordinate,
              map: map,
              title: address,
            });
  
            infoWindow = new google.maps.InfoWindow({
              content: `<div><strong>${address}</strong></div>`,
            });
  
            marker.addListener('click', () => {
              // infoWindow?.open(map!, marker!);
              window.open(`https://www.google.com/maps?q=${props.address}`, '_blank');

            });
          //   map.addListener('click', () => {
          //   window.open(`https://www.google.com/maps?q=${props.address}`, '_blank');
          // });
  
            map.setCenter(coordinate);
          }
        } catch (error) {
          console.error('Error loading Google Maps API:', error);
        }
      };
  
      onMounted(() => {
        if (props.visible) {
          initializeMap();
        }
      });
  
      watch(() => props.visible, async (newVal) => {
        if (newVal) {
          await initializeMap();
        } else {
          map = null;
          marker = null;
          infoWindow = null;
        }
      });
  
      watch([() => props.latitude, () => props.longitude, () => props.address], async () => {
        if (map && marker && infoWindow) {
          const coordinate = { lat: props.latitude, lng: props.longitude };
          const address = props.address;
  
          marker.setPosition(coordinate);
          map.setCenter(coordinate);
          infoWindow.setContent(`<div><strong>${address}</strong></div>`);
        }
      });
  
      return {
        mapContainer
      };
    }
  });
  