
import { defineComponent, onMounted, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ethitransService } from "@/services/EthitransService";
import { common } from "@/common/services/Common";
import ErrorList from "@/components/commonDesign/ErrorList.vue";

export default defineComponent({
  name: "PaymentSuccess",
  components: {
    ErrorList,
  },
  setup() {
    const router = useRouter();
    const routerQuery = useRoute();
    const state = reactive({
      data: {
        invoiceDetails: {} as any,
      },
      ui: {
        error: {
          hasError: false,
          errorList: {
            getInvoiceCompletedById: [] as any,
          },
        },
        isLoading: {
          getInvoiceCompletedById: false,
        },
        isPostLoading: {
          generateInvoice: false,
        },
      },
    });
    // phase_uuid
    function redirectToDashboard() {
      router.push({
        name: "admin-dashboard",
      });
    }
    function redirectToReceivedInvoice() {
      router.push({
        name: "admin-billing-received-invoice",
        params: { phaseId: state.data.invoiceDetails.phase_uuid},
        query: { mode: 1, invoiceId: state.data.invoiceDetails.uuid },
        // query: { mode: selectedMode, invoiceId: invoiceId },
      });
    }
    function getInvoiceCompletedById(invoiceId: any) {
      console.log("Choose Payment Method", invoiceId);
      state.ui.error.errorList.getInvoiceCompletedById = [];
      state.ui.error.hasError = false;

      if (state.ui.isLoading.getInvoiceCompletedById) return false;
      state.ui.isLoading.getInvoiceCompletedById = true;
      ethitransService
        .invoiceCompletedById(invoiceId.toString())
        .then((res: any) => {
          console.log(
            "getInvoiceCompletedById Response Check:>>> ",
            res.data.data
          );
          state.data.invoiceDetails = res.data.data ? res.data.data : {};
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.getInvoiceCompletedById)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.getInvoiceCompletedById.push(
                    value[0]
                  );
                }
              }
            });
        })
        .finally(() => {
          state.ui.isLoading.getInvoiceCompletedById = false;
        });
    }
    onMounted(() => {
      const invoiceId: any = routerQuery.query.id;
      console.log("CHECK ID >>> ", routerQuery.query.id);
      //  if(!invoiceId) return false;
      getInvoiceCompletedById(invoiceId);
    });
    return {
      state,
      redirectToDashboard,
      getInvoiceCompletedById,
      redirectToReceivedInvoice,
    };
  },
});
