
import { defineComponent, reactive, onMounted } from "vue";
import { config } from "@/Config";

export default defineComponent({
  name: "UpgradeSubscription",
  props: {
    buttonLoading: Boolean,
  },
  setup(props, { emit }) {
    const state = reactive({
      data: {
        imageBaseUrl: "",
      },
    });
    function closeModal() {
      emit("closeModal");
      $("#subscriptionModal").modal("hide");
    }
    onMounted(() => {
      // $("#subscriptionModal").modal("show");
      state.data.imageBaseUrl = config.imageBaseUrl;
    });
    return {
      state,
      closeModal,
    };
  },
});
