
import { defineComponent, reactive } from "vue";
import { ethitransService } from "@/services/EthitransService";
import { config } from "@/Config";

export default defineComponent({
  name: "TheChatBot",
  components: {
  
  },
  setup() {
    const state = reactive({
      data: {
        question: "",
        imageBaseUrl: "",
        answers: [] as any,
      },
      ui: {
        isLoading: {},
        postLoading: {
          sendQuestion: false,
        },
      },
      
    });
    state.data.imageBaseUrl = config.imageBaseUrl;

    function scrollToLast() {
      $("#botMessage").animate(
        {
          scrollTop: $("#botMessage")[0].scrollHeight,
        },
        1000
      );
    }
    function redirectToRoute(url: string) {
      // console.log("URL", url)
      location.replace(url)
    }
    function sendQuestion() {
      if (state.data.question === "") return false;
      state.ui.postLoading.sendQuestion = true;
      scrollToLast();
      const questions = state.data.question;
      const today = new Date();
      const time = today.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      state.data.answers.push({
        
        question: questions,
        // eslint-disable-next-line @typescript-eslint/camelcase
        question_time: time,
      });
      const user = localStorage.current_user_token ? "Dashboard" : "Login";
      const payLoad = {
        uid: localStorage._hjid,
        state: user,
        message: state.data.question,
         // eslint-disable-next-line @typescript-eslint/camelcase
        base_url:  window.location.origin,
      };
      state.data.question = "";
      ethitransService
        .chatBoat(payLoad)
        .then((res: any) => {
          state.data.question = "";
          state.data.answers.push({
            redirectTo: res.data.base_url,
            answer: res.data.reply,
            type: res.data.filetype,
            file: `${config.ChatBotUrl}` + res.data.filepath,
            // eslint-disable-next-line @typescript-eslint/camelcase
            answer_time: today.toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            }),
          });
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          scrollToLast();
          state.ui.postLoading.sendQuestion = false;
        });
    }

    return {
      state,
      redirectToRoute,
      sendQuestion,
      scrollToLast,
    };
  },
});
