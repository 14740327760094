
import { defineComponent, reactive, onMounted, watch } from "vue";
import { config } from "@/Config";
import { useRoute, useRouter } from "vue-router";
import { ethitransService } from "@/services/EthitransService";
import { validationService } from "@/common/ValidationService";
import Multiselect from "@vueform/multiselect";
import ErrorList from "@/components/commonDesign/ErrorList.vue";
import { common } from "@/common/services/Common";
import ToDoListBreadCrumb from "@/components/todo/ToDoListBreadCrumb.vue";

export default defineComponent({
  name: "ShareInvitation",
  props: {
    history: {
      type: Array,
      required: true,
    },
  },
  components: {
    Multiselect,
    ErrorList,
    ToDoListBreadCrumb
  },
  setup() {
    const router = useRouter();
    const {
      params: { projectId },
    } = useRoute();
    const state = reactive({
      data: {
        endPoint: 'Invitation',
        project: {} as any,
        imageBaseUrl: "",
        exitingUserList: [] as any,
        selectExitingUser: [] as any,
        phaseList: [] as any,
        inviteNewMember: [] as any,
        selectPhase: [] as any,
        selected: {} as any,
        phaseTaskList: [] as any,
        isIndividualAccept: false,
        showSuccesModal: false,
        successFulMessage: "",
        invitationProjectVerify: {
          showStatus: false,
          newMemberList: [] as any,
          registeredMemberList: [] as any,
          pendingMemberList: [] as any,
        },
      },
      invite: {
        users: [] as any,
        invites: [] as any,
        filteredUsers: [] as any,
        selectedTask: [] as any,
        selectedPhaseTask: [] as any,
      },
      ui: {
        error: {
          hasError: false,
        },
        isLoading: {
        },
        isPostLoading: {
          verifyProjectInvitation: false,
          sendInvitation: false,
        },
      },
      errorList: [] as any,
      isErrorStatus: false,
    });

    function getPhaseList() {
      ethitransService
        .shareProjectPhaseList(projectId.toString())
        .then((res: any) => {
          state.data.exitingUserList = res.data.data.members ? res.data.data.members : [];
          state.data.project = res.data.data.project;
          state.data.phaseList = [];
          res.data.data.phases.forEach((phase: any) => {
            state.data.phaseList.push({
              'value': phase.uuid,
              'label': phase.name,
            });
          });
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          console.log("conda", state.data.phaseList)
        });
    }

    function changeExistingMember() {
      (state.invite.users.includes(state.data.selectExitingUser)) ? '' : state.invite.users.push(state.data.selectExitingUser);
      state.data.selectExitingUser = "";
    }

    function removeSelectedMember(item: any) {
      state.invite.users = state.invite.users.filter((e: any) => e != item);
    }

    function changeInviteMember() {
      if (validationService.isValidEmail(state.data.inviteNewMember)) {
        if (state.invite.invites.length === 0) {
          state.invite.invites.push(state.data.inviteNewMember);
        } else {
          state.invite.invites.forEach((member: any) => {
            if (member != state.data.inviteNewMember) {
              state.invite.invites.push(state.data.inviteNewMember);
            }
          });
        }
        state.invite.invites = state.invite.invites.filter(
          (item: any, i: any, ar: any) => ar.indexOf(item) === i
        );
        state.data.inviteNewMember = "";
      }
    }

    function removeSelectedUser(item: any) {
      state.invite.invites = state.invite.invites.filter((e: any) => e != item);
    }
    function selectPhase(projectId: any) {
      ethitransService
        .getPhaseByTask(projectId.toString())
        .then((res: any) => {
          state.data.phaseTaskList.push(res.data.data);
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          console.log("conda", state.data.phaseTaskList)
        });
    }

    function removeUnSelect(uuid: any) {
      const objWithIdIndex = state.data.phaseTaskList.findIndex((obj: any) => obj.uuid === uuid);
      state.data.phaseTaskList.splice(objWithIdIndex, 1);
      return state.data.phaseTaskList;
    }

    function phaseCheck(selectedIndex: any, checkData: any) {
      const updateIndex = state.data.phaseTaskList[selectedIndex].attributes.findIndex((att: any) => {
        return att.code === "name";
      });
      if (state.data.phaseTaskList[selectedIndex].attributes[updateIndex].isChecked) {
        state.data.phaseTaskList[selectedIndex].attributes[updateIndex].isChecked = false;

      } else {
        state.data.phaseTaskList[selectedIndex].attributes[updateIndex].isChecked = true;
      }
      if(checkData==false){
        state.data.phaseTaskList[selectedIndex].attributes.forEach((att: any) => {
          att.isChecked = false;
        });

        state.data.phaseTaskList[selectedIndex].tasks.forEach((task: any) => {
          task.isChecked = false;
        });
        state.data.phaseTaskList[selectedIndex].tasks.forEach((task: any) => {
          task.attributes.forEach((att: any)=>{
            att.isChecked = false;
          })
        });
      }

    }

    function checkTask(parentSelectIndex: any, selectedIndex: any, checkData: any) {
      const updateIndex = state.data.phaseTaskList[parentSelectIndex].tasks[selectedIndex].attributes.findIndex((att: any) => {
        return att.code === "name";
      });
      if (state.data.phaseTaskList[parentSelectIndex].tasks[selectedIndex].attributes[updateIndex].isChecked) {
        state.data.phaseTaskList[parentSelectIndex].tasks[selectedIndex].attributes[updateIndex].isChecked = false;

      } else {
        state.data.phaseTaskList[parentSelectIndex].tasks[selectedIndex].attributes[updateIndex].isChecked = true;
      }
      if(checkData==false){
        state.data.phaseTaskList[parentSelectIndex].tasks[selectedIndex].attributes.forEach((task: any) => {
          task.isChecked = false;
        });
      }
        if(checkData==true){
          state.data.phaseTaskList[parentSelectIndex].isChecked = true;
      }

      const updateId = state.data.phaseTaskList[parentSelectIndex].attributes.findIndex((att: any) => {
        return att.code === "name";
      });
      if (!state.data.phaseTaskList[parentSelectIndex].attributes[updateId].isChecked) {
        state.data.phaseTaskList[parentSelectIndex].attributes[updateId].isChecked = true;

      }
      // const checkedTask = state.data.phaseTaskList[parentSelectIndex].tasks.filter((task: any) => task.isChecked == true)
      // if (checkedTask.length == state.data.phaseTaskList[parentSelectIndex].tasks.length) {
      //   state.data.phaseTaskList[parentSelectIndex].isChecked = true
      // } else {
      //   state.data.phaseTaskList[parentSelectIndex].isChecked = false
      // }
    }

    function checkPhaseRole(selectPhase: any, selectedIndex: any) {
      
      state.data.phaseTaskList[selectPhase].attributes[selectedIndex].status = !state.data.phaseTaskList[selectPhase].attributes[selectedIndex].status;
    }

    function checkTaskRole(selectPhase: any, selectTask: any, selectedIndex: any) {
      const updateIndex = state.data.phaseTaskList[selectPhase].tasks[selectTask].attributes.findIndex((att: any) => {
        return att.code === "name";
      });
      state.data.phaseTaskList[selectPhase].attributes[updateIndex].isChecked = true;
      state.data.phaseTaskList[selectPhase].isChecked = true;
      state.data.phaseTaskList[selectPhase].tasks[selectTask].isChecked = true;
      state.data.phaseTaskList[selectPhase].tasks[selectTask].attributes[updateIndex].isChecked = true;
      state.data.phaseTaskList[selectPhase].tasks[selectTask].attributes[selectedIndex].status = !state.data.phaseTaskList[selectPhase].tasks[selectTask].attributes[selectedIndex].status;
    }

    function invitationValidation() {
      state.errorList = [];
      state.isErrorStatus = false;

      if (!state.invite.users.length && !state.invite.invites.length) {
        state.errorList.push("Invite member is required.");
      }
      if (state.data.inviteNewMember.length > 0) {
        if (!validationService.isValidEmail(state.data.inviteNewMember)) {
          state.errorList.push("Invalid email.");
        }
      }

      if (state.invite.selectedTask.length == 0) {
        state.errorList.push("At least 1 phase  task is required.");
      }
      if (!state.errorList && !state.errorList.length) {
        state.isErrorStatus = false;
      } else if (state.errorList.length != 0) {
        state.isErrorStatus = true;
      }
    }

    function showSendInvitation() {
      $("#sendInvitation").modal("show");
      $("#sendInvitation").modal({
        backdrop: "static",
        keyboard: true,
        show: true,
      });
    }

    function hideSendInvitation() {
      $("#sendInvitation").modal("hide");
      $("#sendInvitation").modal({
        backdrop: "static",
        keyboard: true,
        show: true,
      });
      state.data.invitationProjectVerify.newMemberList = [];
    }

    function verifyInvitation() {
      state.invite.filteredUsers = [];
      state.data.invitationProjectVerify.newMemberList = [];
      state.invite.selectedTask = [];
      state.invite.users.forEach((item: any) => {
        state.invite.filteredUsers.push(item.email);
      });

      state.invite.invites.forEach((item: any) => {
        state.invite.filteredUsers.push(item);
      });
      state.data.phaseTaskList.forEach((phase: any) => {
        phase.tasks.forEach((task: any) => {
          if (task.isChecked) {
            state.invite.selectedTask.push(task.uuid);
          }
        })
      })

      invitationValidation()
      if (
        state.errorList.length != 0 ||
        state.isErrorStatus)
        return false;

      const payLoad = {
        emails: common.removeDuplicate(state.invite.filteredUsers),
        phases: state.invite.selectedTask,
      };
      state.ui.isPostLoading.verifyProjectInvitation = true;
      ethitransService
        .verifyProjectInvitation(payLoad)
        .then((res: any) => {
          state.data.invitationProjectVerify.newMemberList = res.data.data
            ? res.data.data
            : [];
          state.data.invitationProjectVerify.registeredMemberList = res.data.data
            .shared_members
            ? res.data.data.shared_members
            : [];
          state.data.invitationProjectVerify.pendingMemberList = res.data.data
            .pending_members
            ? res.data.data.pending_members
            : [];
          showSendInvitation();
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.verifyProjectInvitation = false;
        });


    }
    function redirectToProject() {
      router.push({
        name: "admin-project-details",
        params: { projectId: projectId },
      });
    }
    function openSuccessModal() {
      hideSendInvitation();
      $("#successfulModal").modal("show");
    }

    function closeSuccessModal() {
      $("#successfulModal").modal("hide");
      redirectToProject();
    }


    function sendInvitation() {
      state.invite.selectedPhaseTask = [];
      state.data.phaseTaskList.forEach((phase: any) => {
        const phaseAtt = [] as any;
        phase.attributes.forEach((att: any) => {
          if (att.isChecked) {
            phaseAtt.push({
              'id': att.id,
              'status': 1,
            })
          }
        })
        const selectTask = [] as any;
        phase.tasks.forEach((task: any) => {
          const taskAtt = [] as any;
          task.attributes.forEach((att: any) => {
            if (att.isChecked) {
              taskAtt.push({
                'id': att.id,
                'status': 1,
              })
            }
          });
          if (task.isChecked) {
            selectTask.push({
              'id': task.uuid,
              'attributes': taskAtt,
            })
          }
        })
        if (phase.isChecked) {
          state.invite.selectedPhaseTask.push({
            'id': phase.uuid,
            'attributes': phaseAtt,
            'task': selectTask,
          })
        }

      })
      if (state.errorList.length != 0 || state.isErrorStatus || state.invite.selectedPhaseTask.length == 0) return false;
      const payLoad = {
        emails: common.removeDuplicate(state.invite.filteredUsers),
        phases: state.invite.selectedPhaseTask,
        individual: state.data.isIndividualAccept,
      };
      state.ui.isPostLoading.sendInvitation = true;
      ethitransService
        .inviteProjectShare(payLoad)
        .then((res: any) => {
          state.data.successFulMessage = "Invitation send successfullly"
          openSuccessModal();

        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.sendInvitation = false;
          state.invite.selectedPhaseTask = [];
        });
    }

    function redirectToInvitation() {
      router.push({
        name: "admin-project-details",
        params: { projectId: state.data.project.uuid },
      });
    }

    watch(
      () => state.data.selectPhase,
      (newvalue, oldvalue) => {
        const selectValue = newvalue.filter((value: any) => !oldvalue.includes(value))
        const unSelectValue = oldvalue.filter((value: any) => !newvalue.includes(value))
        if (selectValue.length > 0) {
          selectPhase(selectValue[0]);
        }
        if (unSelectValue.length > 0) {
          removeUnSelect(unSelectValue[0]);
        }

        if (state.data.selectPhase.length == 0) {
          state.data.phaseTaskList = [];
        }

      }
    );
    

    onMounted(() => {
      state.data.imageBaseUrl = config.imageBaseUrl;
      getPhaseList();
    });

    return {
      state,
      getPhaseList,
      changeExistingMember,
      removeSelectedMember,
      changeInviteMember,
      removeSelectedUser,
      selectPhase,
      removeUnSelect,
      phaseCheck,
      checkTask,
      checkPhaseRole,
      checkTaskRole,
      invitationValidation,
      verifyInvitation,
      showSendInvitation,
      hideSendInvitation,
      sendInvitation,
      closeSuccessModal,
      openSuccessModal,
      redirectToProject,
      redirectToInvitation

    };
  },
});
