
import { useStore } from "vuex";
import { defineComponent, reactive, onMounted, watch, ref } from "vue";
import EmptyData from "@/components/commonDesign/EmptyData.vue";
import { ethitransService } from "@/services/EthitransService";
import ErrorList from "@/components/commonDesign/ErrorList.vue";
import { config } from "@/Config";

export default defineComponent({
    name: "AppDocusignTable",
    components: {
        EmptyData,
        ErrorList
    },
    props: {
        docs: Array,
        memberList: Array,
        heading: {
            type: Array,
            required: true
        },
        from: String,
        channelUuid: String
    },
    setup(props, {emit}) {
        const store = useStore();
        // const documentList = ref(props.originalProp);
        const state = reactive({
            existingMember: "",
            newMemberList: [] as any,
            data: {
                selectDocument: {} as any,
                templateId: "" as any,
                channelId: props.channelUuid,
                recipients: [] as any,
                title: "pdf",
                heading: "Get started by uploading pdf to digitize the form.",
                documentSentUser: [] as any,
                imageBaseUrl: '',
                delteDocumentUuid: "",
                documentList: [] as any
            },
            sendData: {
                title: "",
                message: "",
            },
            errors: {
                errorList: {
                    createToDo: [] as any,
                    saveEditChannel: [] as any,
                    errorList: [] as any,
                    resendInvitation: [] as any,
                    removeProjectRole: [] as any,
                },
                hasError: false,
            },
            ui: {
                isLoading: {
                    getAccountFormList: false,
                    clientDocumentList: false,
                    showDocument: false
                },
                error: {
                    hasError: false,
                    errorList: {
                        uploadAttachments: [] as any,
                        sendDocusignDocument: [] as any,
                        getFolderList: [] as any,
                    },
                },
                isPostLoading: {
                    templateCreating: false,
                    sendDocument: false,
                    uploadClientDocument: false,
                    enableClientDocument: false,
                    disableClientDocument: false,
                    deleteClientDocument: false,
                },
            },
        })

        function validateSendNewDocusignDocument() {
            if (!state.sendData.title) {
                state.ui.error.errorList.sendDocusignDocument.push(
                    "Document name is required."
                );
            }
            if (state.data.recipients.length == 0) {
                state.ui.error.errorList.sendDocusignDocument.push(
                    "Recipient is required."
                );
            }
            if (
                !state.ui.error.errorList.sendDocusignDocument &&
                !state.ui.error.errorList.sendDocusignDocument.length
            ) {
                state.ui.error.hasError = false;
            } else if (state.ui.error.errorList.sendDocusignDocument.length != 0) {
                state.ui.error.hasError = true;
            }
        }

        function hideDocusignDocumentModel() {
            state.ui.error.errorList.sendDocusignDocument = [];
            state.ui.error.hasError = false;
            state.data.documentSentUser = [];
            state.newMemberList = [];
            state.sendData.message = "";
            $("#sendDocument").modal("hide");
            $("#sendDocument").modal({
                backdrop: "static",
                keyboard: true,
                show: true,
            });
            state.ui.isPostLoading.sendDocument = false;
        }

        function showSentDocusignDocumentModel(document: any) {
            state.data.documentSentUser = [];
            state.newMemberList = [];
            state.data.templateId = document.uuid;
            state.sendData.title = document.name;
            state.newMemberList = props.memberList ? props.memberList : [];
            // state.newMemberList = props.memberList ? props.memberList.filter((e: any) => e.user.email != localStorage.getItem("current_user_email")) : [];
            $("#sendDocument").modal("show");
            $("#sendDocument").modal({
                backdrop: "static",
                keyboard: true,
                show: true,
            });
        }


        function changeExistingMember() {
            state.data.documentSentUser = state.data.documentSentUser.filter((e: any) => e != state.existingMember);
            state.data.documentSentUser.push(state.existingMember);
            state.existingMember = "";
        }

        function removeSelectedMember(item: any) {
            state.data.documentSentUser = state.data.documentSentUser.filter((e: any) => e != item);
        }

        function createEvelopeDocument() {
            state.ui.error.errorList.sendDocusignDocument = [];
            state.ui.error.hasError = false;
            state.data.recipients = [];
            state.data.documentSentUser.forEach((data: any) => {
                state.data.recipients.push(data.email);
            });
            validateSendNewDocusignDocument();
            if (state.ui.error.errorList.sendDocusignDocument.length != 0 || state.ui.error.hasError)
                return false;
            state.ui.isPostLoading.sendDocument = true;
            const payLoad = {
                name: state.sendData.title,
                // eslint-disable-next-line @typescript-eslint/camelcase
                template_id: state.data.templateId,
                recipients: state.data.recipients,
                // eslint-disable-next-line @typescript-eslint/camelcase
                channel_id: state.data.channelId,
                message: state.sendData.message
            };
            ethitransService.envelopeCreate(payLoad)
                .then((res: any) => {
                    // window.location.href = res.data.data.url;
                    store.dispatch("saveDocusignSignInfo", res.data.data.url);
                })
                .catch((error: any) => {
                    console.log(error);
                })
                .finally(() => {
                    hideDocusignDocumentModel();
                    // state.ui.isPostLoading.sendDocument = false;
                    // state.ui.isPostLoading.uploadClientDocument = false;
                });
        }

        
    function clientDocumentList() {
      state.ui.isLoading.clientDocumentList = true;
      ethitransService
        .getDocusignTemplate()
        .then((res: any) => {
          state.data.documentList = res.data.data;
        //   store.dispatch("docusignDocumentList", res.data.data);
        //   state.data.edocUploadedList = true;
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isLoading.clientDocumentList = false;
        //   state.data.edocUploadedList = false;
        });
    }

        function closeDocumebntDeleteModal() {
            state.data.delteDocumentUuid = "";
            $("#deleteModal").modal("hide");
        }

        function openDocumentDeleteModel(uuid: any) {
            state.data.delteDocumentUuid = uuid;
            $("#deleteModal").modal("show");
        }

        function editEnvelopeDocument(uuid: any) {
            state.ui.isPostLoading.sendDocument = true;
            ethitransService
                .envelopeEdit(uuid)
                .then((res: any) => {
                    store.dispatch("editDocusignSignInfo", res.data.data.url);
                })
                .catch((error: any) => {
                    console.log(error);
                })
                .finally(() => {
                    state.ui.isPostLoading.sendDocument = false;
                });
        }

        function deleteDocuignDocument() {
            state.ui.isPostLoading.deleteClientDocument = true;
            ethitransService
                .envelopeDelete(state.data.delteDocumentUuid)
                .then((res: any) => {

                    // // props.docs.splice(1, 1)
                    // location.reload();
                })
                .catch((error: any) => {
                    console.log(error);
                })
                .finally(() => {

                    clientDocumentList();
                    state.ui.isPostLoading.deleteClientDocument = false;
                    closeDocumebntDeleteModal();
                    // state.ui.isPostLoading.sendDocument = false;
                });
        }

        function docusignTempleteDetails(uuid: any) {
            $("#responseModal").modal("show");
            state.ui.isLoading.showDocument = true;
            ethitransService
                .docusignTempleteDetails(uuid)
                .then((res: any) => {
                    state.data.selectDocument = res.data.data;
                })
                .catch((error: any) => {
                    console.log(error);
                })
                .finally(() => {
                    state.ui.isLoading.showDocument = false;
                });
        }


        function docusignTempleteStatus(uuid: any) {
            ethitransService
                .docusignDocumentStatus(uuid)
                .then((res: any) => {
                    // state.data.selectDocument = res.data.data;
                })
                .catch((error: any) => {
                    console.log(error);
                })
                .finally(() => {
                    // state.ui.isLoading.showDocument = false;
                });
        }

        function closeDocumentDetails() {
            state.data.selectDocument = {};
            $("#responseModal").modal("hide");
        }

        watch(
            () => store.state.saveSignedData,
            (newvalue, oldvalue) => {
                if (newvalue) {
                    hideDocusignDocumentModel();
                    state.ui.isPostLoading.sendDocument = false;
                }
            }
        );

        onMounted(() => {
            clientDocumentList();
            state.data.imageBaseUrl = config.imageBaseUrl;
            // state.data.documentList =store.state.docusignDocumentList;

        window.addEventListener('message', event => {
        if (event.data.event === 'taskDone') {
          if (event.data.data.envelopeId != undefined) {
            docusignTempleteStatus(event.data.data.envelopeId);
            // sendMessageFromEnvelope(event.data.data.envelopeId)
            // state.ui.isLoading.docuSignIframe = false;
            // state.ui.isLoading.docuSignIframeOpen = false;
            // getCommunicationList(channelId);
          }

        }
      });
        });

        return {
            state,
            hideDocusignDocumentModel,
            removeSelectedMember,
            changeExistingMember,
            showSentDocusignDocumentModel,
            createEvelopeDocument,
            validateSendNewDocusignDocument,
            editEnvelopeDocument,
            deleteDocuignDocument,
            closeDocumebntDeleteModal,
            openDocumentDeleteModel,
            docusignTempleteDetails,
            closeDocumentDetails,
            clientDocumentList,
            docusignTempleteStatus
        };
    },
});
