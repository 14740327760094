
import { defineComponent, reactive, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
// import Button from "@/common/Button.vue";

import { ethitransService } from "@/services/EthitransService";
import { validationService } from "@/common/ValidationService";
import { common } from "@/common/services/Common";

import ErrorList from "@/components/commonDesign/ErrorList.vue";

export default defineComponent({
  name: "ToDoTaskLogModal",
  props: {
    showAddPhase: {
      type: Boolean,
      required: true,
    },
    editMode: {
      type: Boolean,
      required: true,
    },
    projectDetail: {
      type: Object,
      required: true,
    },
    todoDetail: {
      type: Object,
      required: true,
    },
    phase: {
      type: Object,
      required: true,
    },
    selectedTask: {
      type: Object,
      required: true,
    },
  },
  components: {
    // Button,
    ErrorList,
  },
  setup(props, { emit }) {
    const router = useRouter();
    const state = reactive({
      data: {
        buttonName: 'Update Log',
        log: {
          maintainedBy : false,
          todayDate : '',
          detail : '',
          hour : 0,
        },
        imagesToUpload: [] as any,
        showSuccesModal: false,
      },
      ui: {
        error: {
          hasError: false,
          errorList: {
            createPhase: [] as any,
          },
        },
        isLoading: {
          getToDoData: false,
        },
        isPostLoading: {
          createPhase: false,
        },
      },
    });
    function closeSuccessModal() {
      state.data.showSuccesModal = !state.data.showSuccesModal;
      $("#successfulModal").modal("hide");
    }
    function removeSelectedImage(image: any) {
      state.data.imagesToUpload = state.data.imagesToUpload.filter(
        (e: any) => e != image
      );
    }
    function hideTaskLogModal() {
      emit("close");
      $("#taskLogModal").modal("hide");
    }
    // function update() {
    //   // if (props.editMode) {
    //     alert("EDIT MODE")
    //   //   updatePhase();
    //   // } else {
    //   //   createPhase();
    //   //   // alert("ADD MODE")
    //   // }
    // }
    function update(type: number) {
      // alert("HELLO");
      state.ui.error.errorList.createPhase = [];
      state.ui.error.hasError = false;
      if (state.ui.isPostLoading.createPhase) return false;
      if (
        state.ui.error.hasError ||
        state.ui.error.errorList.createPhase.length != 0
      )
        return false;    
      const payLoad: any = {
        id: props.selectedTask.uuid,
        type: type,
        detail: state.data.log.detail,
        hour: state.data.log.hour,
      };
      console.log(" Update PAYLOAD CHECK ><><><><> ", payLoad);
      state.ui.isPostLoading.createPhase = true;
      ethitransService
        .setPhaselog(payLoad)
        .then((res: any) => {
          // alert("THEN");
          console.log("Project Todo Response Check:>>> ", res.data.data);
          emit("logData", res.data.data);
          hideTaskLogModal();
        })
        .catch((error: any) => {
          // alert("CATCH WHY");
          console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.createPhase)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.createPhase.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.createPhase = false;
        });
    }
    onMounted(() => {
      if (props.showAddPhase) {
        const data = new Date();
        state.data.log.todayDate = data.toDateString();
        $("#taskLogModal").modal("show");
      }
    });

    return {
      state,
      closeSuccessModal,
      removeSelectedImage,
      hideTaskLogModal,
      update,
    };
  },
});
