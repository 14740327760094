
import { defineComponent, reactive, onMounted, computed, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import { ethitransService } from "@/services/EthitransService";
import { common } from "@/common/services/Common";
import { config } from "@/Config";

import ProjectApprovalModalEdit from "@/components/projectDetail/ProjectApprovalModalEdit.vue";
import EmptyData from "@/components/commonDesign/EmptyData.vue";
import HistoryTimeLine from "@/components/projectDetail/HistoryTimeLine.vue";

import Pusher from "pusher-js";

export default defineComponent({
  name: "ProjectDetailsDetailHistorys",
  props: {
    msg: String,
  },
  components: {
    ProjectApprovalModalEdit,
    EmptyData,
    HistoryTimeLine,
    // 'v-select' : vSelect
    // Multiselect
  },
  setup() {
    const router = useRouter();
    const {
      params: { projectId },
    } = useRoute();
    // console.log("CHECK ID, >>", projectId);
    const state = reactive({
      titleMember: "pending members",
      headingMember: "Get started by inviting users in your project.",
      data: {
        projectAcknowledgementDetails: {
          attachments: [] as any,
          payerRequest: {} as any,
          project: {} as any,
          payers: [] as any,
        },
        description: '',
        declineTitle: 'decline History',
        declineHeading: 'No Decline resons yet for this project.',
        historyTitle: 'Acknowledgement History',
        historyHeading: 'No Acknowledgement history yet for this project.',
        selectedDeclineHistory: {},
      },
      ui: {
        modal: {
          showProjectAcknowledgementModal: false,
          showEditProjectAcknowledgementModal: false,
        },
        isLoading: {
          getProjectAcknowledgementApprovalDetail: false,
        },
        isPostLoading: {
          createProjectToDo: false,
        },
        errors: {
          errorList: {
            getProjectAcknowledgementApprovalDetail: [] as any,
          },
          hasError: false,
        },
      },
    });
    function redirectToProject(project: any) {
      router.push({
        name: "admin-project-details",
        params: { projectId: project.uuid },
      });
    }
    function hideEditProjectAcknowledgement() {
      state.data.selectedDeclineHistory = {};
      state.ui.modal.showEditProjectAcknowledgementModal =
        !state.ui.modal.showEditProjectAcknowledgementModal;
    }
    function showEditProjectAcknowledgementByDeclineSelected(decline: any) {
      state.data.selectedDeclineHistory = decline;
      state.ui.modal.showEditProjectAcknowledgementModal =
        !state.ui.modal.showEditProjectAcknowledgementModal;
      // hideProjectAcknowledgementRequestDetails();
    }
    function showEditProjectAcknowledgement() {
      state.ui.modal.showEditProjectAcknowledgementModal =
        !state.ui.modal.showEditProjectAcknowledgementModal;
      // hideProjectAcknowledgementRequestDetails();
    }
    function redirectToDoc() {
      window.open(
        state.data.projectAcknowledgementDetails.payerRequest.edoc_id,
        "_blank"
      );
    }
    function getProjectAcknowledgementApprovalDetail() {
      state.ui.errors.errorList.getProjectAcknowledgementApprovalDetail = [];
      state.ui.errors.hasError = false;
      if (state.ui.isLoading.getProjectAcknowledgementApprovalDetail)
        return false;
      state.ui.isLoading.getProjectAcknowledgementApprovalDetail = true;

      ethitransService
        .projectAcknowledgementApprovalDetail(projectId.toString())
        .then((res: any) => {
          console.log(
            "CHECK getProjectAcknowledgementApprovalDetail >>> ",
            res.data.data
          );
          if (res.data.data && res.data.data.attachments) {
            res.data.data.attachments.forEach((attachment: any) => {
              // console.log("Attachment Check:>>> ", attachment);
              attachment.activeLink =
                config.liveImageBaseUrl + attachment.file_link;
              attachment.convertedFileSize = common.formatBytes(
                parseInt(attachment.file_size)
              );
            });
          }
          // eslint-disable-next-line @typescript-eslint/camelcase
          res.data.data.payerRequest.convStartDate = common.localDateOnly(
            res.data.data.payerRequest.start_date
          );
          // eslint-disable-next-line @typescript-eslint/camelcase
          res.data.data.payerRequest.convEndDate = common.localDateOnly(
            res.data.data.payerRequest.end_date
          );
          if(res.data.data.payerRequest.declineReasons && res.data.data.payerRequest.declineReasons.length){
            res.data.data.payerRequest.declineReasons.forEach((item: any)=> {
              // eslint-disable-next-line @typescript-eslint/camelcase
              item.created_at = common.localDateAndTime(item.created_at);
            });
          }
          if(res.data.data.payerRequest.history && res.data.data.payerRequest.history.length){
            res.data.data.payerRequest.history.forEach((item: any)=> {
              // alert('CHECK');
              console.log("DATE CHANGE >>> ", item);
              if(item.changes && item.changes.length){
                item.changes.forEach((change: any)=> {
                  // console.log("DATE CHANGE >>> ", change);
                    // eslint-disable-next-line @typescript-eslint/camelcase
                  if(change.new_end_date){
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    change.new_end_date = common.localDateOnly(change.new_end_date);
                  }
                    // eslint-disable-next-line @typescript-eslint/camelcase
                  if(change.new_start_date){
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    change.new_start_date = common.localDateOnly(change.new_start_date);
                  }
                    // eslint-disable-next-line @typescript-eslint/camelcase
                  if(change.old_end_date){
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    change.old_end_date = common.localDateOnly(change.old_end_date);
                  }
                    // eslint-disable-next-line @typescript-eslint/camelcase
                  if(change.old_start_date){
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    change.old_start_date = common.localDateOnly(change.old_start_date);
                  }
            })  
              }
            })
          }
          if (
            res.data.data.payerRequest &&
            !res.data.data.payerRequest.description
          ) {
            if (res.data.data.project && res.data.data.project.description) {
              // alert("HELLO")
              state.data.description = res.data.data.project.description;
            }
          } else {
            state.data.description = res.data.data.payerRequest.description;
          }

          state.data.projectAcknowledgementDetails = res.data.data
            ? res.data.data
            : {};

          // res.data.data.forEach((item: any) => {
          //   item.isJoinStatus = false;
          //   item.isDeclineStatus = false;
          // });
          // state.pendingList = res.data.data ? res.data.data : [];
        })
        .catch((error: any) => {
          common
            .sdCatchErr(
              error,
              state.ui.errors.errorList.getProjectAcknowledgementApprovalDetail
            )
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.getProjectAcknowledgementApprovalDetail.push(
                    value[0]
                  );
                }
              }
            });
        })
        .finally(() => {
          state.ui.isLoading.getProjectAcknowledgementApprovalDetail = false;
        });
    }
    function setAcknowledgedData(){
    getProjectAcknowledgementApprovalDetail();
    }
    // private channel pusher implemented
    const token = localStorage.getItem("current_user_token");
    let newToken = "";
    if (token) {
      newToken = token.replace(/"/gi, "");
    }
    const pusher = new Pusher(`${process.env.VUE_APP_WEBSOCKETS_KEY}`, {
      cluster: "ap2",
      authEndpoint: `${config.privateChannel}broadcasting/auth`,
      auth:{
        headers: {
          Accept: 'application/json',
          Authorization : "Bearer "+newToken
        }
      }
    });
    
    const projectAcknowledgment = pusher.subscribe(`private-projectAcknowledgment.${projectId}`);

    projectAcknowledgment.bind("projectAcknowledgment.updated", function (data: any) {
      if (JSON.stringify(data)) {
        const url: any = window.location.href;
        const page: any = url.split("#/").pop().split("/")[0];
        if (page === "project-acknowledgement-info") {
          getProjectAcknowledgementApprovalDetail();
        }
      }
    });

    onMounted(() => {
      // console.log("Hello from ProjectDetailsDetailHistorys");
      getProjectAcknowledgementApprovalDetail();
    });
    return {
      state,
      redirectToProject,
      getProjectAcknowledgementApprovalDetail,
      setAcknowledgedData,
      redirectToDoc,
      showEditProjectAcknowledgement,
      hideEditProjectAcknowledgement,
      showEditProjectAcknowledgementByDeclineSelected,
    };
  },
});
