
import { defineComponent, reactive, onMounted, toRefs } from "vue";
// import ErrorList from "@/components/commonDesign/ErrorList.vue";
import { ethitransService } from "@/services/EthitransService.ts";
import { common } from "@/common/services/Common.ts";
import { config } from "@/Config";

export default defineComponent({
  name: "ActivityCard",
  components: {
    // ErrorList, // googleAuth
    // facebookLogin
  },
  props: {
    activity: Object,
  },
  setup() {
    const state = reactive({
      data: {
        imageBaseUrl: "",
      },
      ui: {
        postLoading: {
          processRegistration: false,
        },
      },
    });
    onMounted(() => {
      state.data.imageBaseUrl = config.imageBaseUrl;
    });
    return {
      ...toRefs(state),
    };
  },
});
