
import { defineComponent, onMounted, reactive, watch } from "vue";
import Editor from "@tinymce/tinymce-vue";
import { ethitransService } from "@/services/EthitransService";
import SuccessfulModalCustom from "@/components/modal/SuccessfulModalCustom.vue";
import ErrorList from "@/components/commonDesign/ErrorList.vue";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "EditBusinessMailTemplate",
  props: {
    showModal: Boolean,
  },
  components: {
     Editor,
    SuccessfulModalCustom,
    ErrorList,
  },
  setup(props, { emit }) {
    
 const {
      params: { tempId },
    } = useRoute();  
    const router = useRouter();
      const state = reactive({
      title: "" as any,
      template: "" as any,
      tempId: "" as any,
      data: {
        SuccessFulMessage: "",
        showSuccesCustomModal: false,
      },
      ui: {
        isLoading: {},
        isPostLoading: {
          getUserActivityList: false,
          saveEditUser: false,
        },
         error: {
          validationList: [] as any,
          hasError: false,
        },
      },

      newSearchData: {
        emailError: false,
      },
      error: {
        loginFromError: "" as any,
        loginToError: "" as any,
        createdFromError: "" as any,
        createdToError: "" as any,
      },
    });
    function serachValidation() {
      state.ui.error.validationList = [];
      state.ui.error.hasError = false;
      if (!state.title) {
        state.ui.error.hasError = true;
        state.ui.error.validationList.push("Title is required.");
      }
       if (!state.template) {
        state.ui.error.hasError = true;
        state.ui.error.validationList.push("Template is required.");
      }
    }

 function tempDetails() {
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        id: tempId,
      };
      ethitransService
        .editBusinessMailTemplate(payLoad)
        .then((res: any) => {
          console.log(
            "Project Details Response Check:>>> ",
            res.data.data
          );
          state.title = res.data.data.title ? res.data.data.title : {};
          state.template = res.data.data.mail_template ? res.data.data.mail_template : {};
          state.tempId = res.data.data.id ? res.data.data.id:{};
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          //
        });
    }
 
    function updateBusinessMailTemplate() {
      state.ui.isPostLoading.getUserActivityList = true;
      // console.log("HELLO GROM ROLE LIST FROM PROJET DETAILS");
      serachValidation();
      const payLoad = {
        title: state.title,
      // eslint-disable-next-line @typescript-eslint/camelcase
        mail_template: state.template,
        id:state.tempId
      };
      ethitransService
        .updateBusinessMailTemplate(payLoad)
        .then((res: any) => {
          console.log("getProjectRoleList Response Check:>>> ", res.data.data);
          // state.title = "";
          // state.template = "";
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
          state.data.SuccessFulMessage = "Mail Template update successfully.";
          // backToBusinessMail();
        })
        .catch((error: any) => {     
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.getUserActivityList = false;
        });
    }

   function backToBusinessMail(){
      router.push({
          name: "business-mail-template",
        });
    }

    onMounted(() => {
      Editor;
      tempDetails();
    });

    return {
      state,
      serachValidation,
      updateBusinessMailTemplate,
      backToBusinessMail,
      tempDetails
    };
  },
});
