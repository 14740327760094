
import { defineComponent, reactive, onMounted, toRefs, watch } from "vue";
import { useRouter, useRoute } from "vue-router";

import EmptyData from "@/components/commonDesign/EmptyData.vue";
import ErrorList from "@/components/commonDesign/ErrorList.vue";
import SuccessfulModalCustom from "@/components/modal/SuccessfulModalCustom.vue";
import { ethitransService } from "@/services/EthitransService";

import { config } from "@/Config";
import { common } from "@/common/services/Common";

export default defineComponent({
  name: "ChannelInvitation",
  components: {
    EmptyData,
    ErrorList,
    SuccessfulModalCustom,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const state = reactive({
      data: {
        selectedInvition: {} as any,
        selectInvite: [] as any,
        acceptInviteChannel: [] as any,
        recentJoinChannel: [] as any,
        acceptChannel: "" as any,
        showSuccesCustomModal: false,
        declineShareProject: true,
        selectedUuid: "" as any,
        projectUuid: "" as any,
        description: "",
        successFulMessage: "",
        loggedInUserUUID: "",
        imageBaseUrl: "",
        error: {
          errorsList: [] as any,
          hasError: false,
        },
      },
      ui: {
        isLoading: {
          userStatus: false,
        },
        isPostLoading: {
          redirectToChat: false,
        },
        errors: {
          errorList: {
            projectAcknowledgementDetails: [] as any,
          },
          hasError: false,
        },
      },
      title: "invitations",
      heading: "Get started by creating invitation in your project.",
      userName: "",
      uuId: "",
      postLoading: {
        acceptInvitation: false,
        declineInvitation: false,
      },
      isLoading: false,
      pendingList: [],
    });
    function hideDeleteInvitation() {
      $("#deleteInvitation").modal("hide");
    }
    function hideInvitationDetails() {
      $("#showInvitationDetails").modal("hide");
    }
    function showDeclineModal(selectedInvition: any) {

      state.data.selectInvite = [];
      if (Array.isArray(selectedInvition)) {
        selectedInvition.forEach((invitation: any) => {
          state.data.selectInvite.push(invitation.uuid)
        })
      } else {
        state.data.selectInvite.push(selectedInvition.uuid)
      }
      state.data.selectedInvition = {};
      state.data.selectedInvition = selectedInvition;
      state.data.description = '';
      hideInvitationDetails();
      $("#deleteInvitation").modal("show");
    }


    function removeSelectedInvitation() {
      // state.data.selectedInvition
      state.pendingList = state.pendingList.filter(
        (e: any) => e.uuid != state.data.selectedInvition.uuid
      );
      state.data.selectedInvition = {};
    }

    function showSuccesCustomModal() {
      state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
      $("#SuccessfulModalCustom").modal("hide");
    }

    function getInvitationPending() {
      state.data.error.errorsList = [];
      state.data.error.hasError = false;
      state.isLoading = true;

      ethitransService
        .pendingChannelInvitation(state.data.projectUuid)
        .then((res: any) => {
          res.data.data.forEach((response: any) => {
            response.createdLatest = common.localDateAndTime(response.created_at);
          });
          res.data.data.forEach((item: any) => {
            item.isJoinStatus = false;
            item.isDeclineStatus = false;
          });
          state.pendingList = res.data.data ? res.data.data : [];

        })
        .catch((error: any) => {
          // console.log("WOWOW",error);
          common
            .sdCatchErr(error, state.data.error.errorsList)
            .then((res: any) => {
              state.data.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.data.error.errorsList.push(value[0]);
                }
              }

            });
        })
        .finally(() => {
          state.isLoading = false;
        });
    }
    function acceptInvitation() {
      state.data.error.errorsList = [];
      state.data.error.hasError = false;
      if (state.postLoading.acceptInvitation) return false;
      const payLoad = {
        uuid: state.data.selectInvite,
      };
      state.postLoading.acceptInvitation = true;

      ethitransService
        .joinInvitation(payLoad)
        .then((res: any) => {
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
          state.data.successFulMessage = "Channel Accepted Successfully.";
          removeSelectedInvitation();
        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.data.error.errorsList)
            .then((res: any) => {
              state.data.error.hasError = true;

              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.data.error.errorsList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          getInvitationPending();
          state.postLoading.acceptInvitation = false;
        });
    }

    function declineInvitation() {
      state.data.error.errorsList = [];
      state.data.error.hasError = false;
      if (state.postLoading.declineInvitation) return false;
      state.postLoading.declineInvitation = true;
      const payLoad = {
        uuid: state.data.selectInvite,
        description: state.data.description
      };
      ethitransService
        .declineInvitation(payLoad)
        .then((res: any) => {
          removeSelectedInvitation();
          hideDeleteInvitation();
        })
        .catch((error: any) => {
          console.log(error);
          common
            .sdCatchErr(error, state.data.error.errorsList)
            .then((res: any) => {
              state.data.error.hasError = true;

              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.data.error.errorsList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.postLoading.declineInvitation = false;
          getInvitationPending();
        });
    }

    function join(selectedInvition: any) {
      state.data.selectInvite = [];
      if (Array.isArray(selectedInvition)) {
        selectedInvition.forEach((invitation: any) => {
          state.data.selectInvite.push(invitation.uuid);
          state.data.recentJoinChannel.push(invitation);

        })
        acceptInvitation();
      } else {
        state.data.acceptChannel = "",
          state.data.selectInvite.push(selectedInvition.uuid)
        acceptInvitation();
        state.data.acceptChannel = selectedInvition;
        state.data.recentJoinChannel.push(selectedInvition);
      }

    }
    function decline() {
      declineInvitation();

    }

    function backInvitation() {
      router.push({
        name: "admin-invitation"
      });
    }

    function goToChannel(uuid: any) {
      showSuccesCustomModal();
      router.push({
        name: "admin-channel",
        params: { channelId: uuid },
      });
    }

    function goToProjectDahboard() {
      showSuccesCustomModal();
      router.push({
        name: "admin-project-details",
        params: { projectId: state.data.projectUuid },

      });
    }
    onMounted(() => {
      state.data.imageBaseUrl = config.imageBaseUrl;
      state.data.projectUuid = route.params.id;
      getInvitationPending();
    });
    return {
      state,
      hideDeleteInvitation,
      showDeclineModal,
      hideInvitationDetails,
      removeSelectedInvitation,
      ...toRefs(state),
      getInvitationPending,
      acceptInvitation,
      declineInvitation,
      showSuccesCustomModal,
      join,
      decline,
      backInvitation,
      goToChannel,
      goToProjectDahboard
    };
  },
});
