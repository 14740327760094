
import { defineComponent, reactive, onMounted, ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";

import { common } from "@/common/services/Common.ts";
import { ethitransService } from "@/services/EthitransService.ts";
import { validationService } from "@/common/ValidationService.ts";

// import CommunicationCard from "@/components/cards/CommunicationCard.vue";
// import MemberCard from "@/components/cards/MemberCard.vue";
// import EmptyData from "@/components/commonDesign/EmptyData.vue";
// import ErrorList from "@/components/commonDesign/ErrorList.vue";

// import Multiselect from "vue-multiselect";

// import TitleComponent from "@/components/formComponents/TitleComponent.vue";
// import TextBoxComponent from "@/components/formComponents/TextBoxComponent.vue";
// // import TextAreaComponent from "@/components/formComponents/TextAreaComponent.vue";
// import RadioButtonComponent from "@/components/formComponents/RadioButtonComponent.vue";
// import SelectOption from "@/components/formComponents/SelectOption.vue";
// import CheckBox from "@/components/formComponents/CheckBox.vue";
// import TextArea from "@/components/formComponents/TextArea.vue";

export default defineComponent({
  name: "Channel",
  components: {
    // CommunicationCard,
    // MemberCard,
    // EmptyData,
    // ErrorList,
    // TitleComponent,
    // TextBoxComponent,
    // // TextAreaComponent,
    // RadioButtonComponent,
    // SelectOption,
    // CheckBox,
    // TextArea,
    // Multiselect
  },
  setup() {
    const {
      params: { channelId },
    } = useRoute();
    const router = useRouter();
    // console.log("Router Check >>>", router)
    const tempImageList = ref([]);
    const state = reactive({
      email: "" as any,
      filter: {
        type: "all",
        projectId: 0,
        channelId: 0,
        sortBy: -1,
        title: 1,
        selected: { name: "Newest First", id: -1 } as any,
      },
      type: "",
      selected: "",
      typePosition: "bottom",
      errorList: [] as any,
      sharedFormList: {} as any,
      formList: {} as any,
      isErrorStatus: false,
      projectId: 0,
      pendingMembersCount: 0,
      approvedMembersCount: 0,
      isCreateProjectModalShow: false,
      isEditCreatenewFormFromChannel: false,
      postLoading: false,
      communicationList: [],
      channel: {} as any,
      userRole: {} as any,
      selectedElement: {
        element: {} as any,
        type: "",
        index: "",
        title: "",
        description: "",
      },
      memberList: [],
      pendingList: [],
      imageList: [] as any[],
      searchBy: "",
      message: "",
      image: "",
      title: "communications",
      heading: "Get started by creating communication in your chat room.",
      titleMember: "members",
      headingMember: "Get started by adding members in your chat room.",
      existingMember: {} as any,
      inviteMember: "",
      invitationMessage: "",
      userList: [] as any,
      formData: {} as any,
      owner: {} as any,
      item: {
        error: {
          erorList: [] as any,
          erorListNewForm: [] as any,
          erorListSendForm: [] as any,
          erorListSaveCustomSelectedForm: [] as any,
        },
        hasError: false,
      },
      privacyList: [
        {
          name: "Private to this channel",
          code: "channel",
          value: "private to this channel",
        },
        {
          name: "Share within this project",
          code: "project",
          value: "share within this project",
        },
        { name: "Share to Public", code: "public", value: "share to public" },
      ],
      newFormData: {
        title: "",
        description: "",
        elements: [] as any,
        privacy: {
          name: "Private to this channel",
          value: "private to this channel",
          code: "channel",
        },
      },
      selectedFormForEdit: {
        id: "",
        title: "",
        description: "",
        elements: [] as any,
      } as any,
      invite: {
        users: [] as any,
        invites: [] as any[],
        filteredUsers: [] as any[],
        filteredInvites: [] as any[],
      },
      form: {
        textField: {
          title: "",
          description: "",
        },
        textArea: {
          title: "",
          text: "",
        },
        textbox: {
          title: "",
        },
        radioButton: {
          type: "radioButton",
          title: "",
          // eslint-disable-next-line @typescript-eslint/camelcase
          select_values: [] as any,
        },
        selectOption: {
          type: "single_select",
          title: "",
          selectedOption: {},
          // eslint-disable-next-line @typescript-eslint/camelcase
          select_values: [] as any,
        },
        checkBox: {
          type: "checkBox",
          title: "",
          // eslint-disable-next-line @typescript-eslint/camelcase
          select_values: [] as any,
        },
      },
      ui: {
        template: {
          msg: "",
          response: {} as any,
        },
        isLoading: {
          getAccountFormList: false,
          accountFormResponseByEmailAndId: false,
          getChannelFormList: false,
        },
        isPostLoading: {
          createFormTemplate: false,
          getAccountFormRetrieveById: false,
          createNewForm: false,
          selectedUserAccountFormResponse: false,
        },
      },
      isLoading: {
        getUserList: false,
        inviteAllUsers: false,
      },
    });
     // eslint-disable-next-line vue/return-in-computed-property
    const listTemp = computed(() => {
      const array = [] as any;
      state.form.checkBox.select_values.forEach((data: any) => {
        console.log("Data CHECKS: >>>>", data);
        array.push(data);
      });
      return array;
    });
     // eslint-disable-next-line vue/return-in-computed-property
    const templateList = computed(() => {
      if (state.formList.docs && state.formList.docs.length) {
        return state.formList.docs.filter((form: any) => {
          // if (form.title) {
          //   return form.title.toLowerCase().match(state.searchBy.toLowerCase());
          return form;
          // }
        });
      } else {
        return [];
      }
    });
    // const removeStatus = computed(() => {
    //   if (state.form.selectOption.select_values && state.form.selectOption.select_values.length === 1) {
    //       return 0
    //   } else {
    //     return
    //   }
    // });
    // const radioButtonList = computed(() => {
    //   if (state.form.radioButton.select_values && state.form.radioButton.select_values.length) {
    //     return state.form.radioButton.select_values.forEach((form: any) => {
    //       if(form.test) {
    //         form.value = form.test
    //         console.log("FINAL CHANGE",form);
    //       }
    //       // if (form.title) {
    //       //   return form.title.toLowerCase().match(state.searchBy.toLowerCase());
    //       return form;
    //       // }
    //     });
    //   }
    // });
     // eslint-disable-next-line vue/return-in-computed-property
    const selectedForm = computed(() => {
      if (
        state.selectedFormForEdit.elements &&
        state.selectedFormForEdit.elements.length
      ) {
        return state.selectedFormForEdit.elements.filter((form: any) => {
          console.log("Check SelectForm >>>", form);
        });
      }
    });
     // eslint-disable-next-line vue/return-in-computed-property
    const formPrivacyMessage = computed(() => {
      if (state.newFormData.privacy.value === "private to this channel") {
        return "This form will remain private to this channel. Form will not be visible to other channels of this project.";
      } else if (
        state.newFormData.privacy.value === "share within this project"
      ) {
        return " This form will be shared in this project so that other channels of this project can utilize this form, but will not be shared with other projects.";
      } else {
        return "This form will be shared to public.";
      }
    });
     // eslint-disable-next-line vue/return-in-computed-property
    const isUserStatus = computed(() => {
      if (state.userRole) {
        if (state.userRole.level === 1 || state.userRole.level === 2) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    });
    function radioChange(element: any, option: any) {
      console.log("Check Element >>>", element);
      console.log("Check Form Data >>>", option);
      // state.userFormData = state.userFormData.filter(
      //   (item: any) => item.text != removeText
      // );
      // if (element.type === "radioButton") {
      //   state.newFormData.elements.forEach((item: any) => {
      //     if (
      //       item.title === element.title &&
      //       item.type === "radioButton" &&
      //       element.type === "radioButton"
      //     ) {
      //       if (item.type === "radioButton") {
      //         item.select_values.filter((data: any) => {
      //           if (option.text != data.text && option.value != data.value) {
      //             data.selected = "";
      //           }
      //         });
      //       }
      //       // console.log("Form Data elements >>>> ", item);
      //       // console.log("Check selected Element >>>", element);
      //       // console.log("Check selected Element's Option >>>", option);
      //     }
      //   });
      // }
    }
    function redirectToDynamicForm() {
      console.log("<<< Redirecting to Dynamic Form >>>", channelId);
      // with query, resulting in /channel?channelId=private
      router.push({
        name: "admin-channel-create-form",
        query: { projectId: state.projectId, channelId: channelId },
      });
      $(".modal-backdrop").remove();
      // router.push({ path: 'channel', query: { channelId: 'private' } })
    }

    function getUserList() {
      console.log("USERLIST check for channel ID", channelId)
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: state.projectId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: channelId,
      };
      console.log("PAYLOAD CHECK ", payLoad);
      state.isLoading.getUserList = true;
      ethitransService
        .userList(payLoad)
        .then((res: any) => {
          console.log("UserList Check:>>> ", res.data.data);
          state.userList = res.data.data ? res.data.data : [];
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.isLoading.getUserList = false;
        });
    }
    function addOptions() {
      console.log("CHECK  ADD");
      state.form.radioButton.select_values.push({
        text: "",
        value: "",
        selected: "",
      });
    }
    function addSelectOptions() {
      state.form.selectOption.select_values.push({
        text: "",
        value: "",
        selectedOption: {},
        selected: false,
      });
    }
    function addCheckBox() {
      state.form.checkBox.select_values.push({
        text: "",
        value: "",
        selected: false,
      });
    }
    function removeCheckBox(removeText: string) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.form.checkBox.select_values = state.form.checkBox.select_values.filter(
        (item: any) => item.text != removeText
      );
    }
    function removeSelectOption(removeText: string) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.form.selectOption.select_values = state.form.selectOption.select_values.filter(
        (item: any) => item.text != removeText
      );
    }
    function removeOptions(removeText: string) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.form.radioButton.select_values = state.form.radioButton.select_values.filter(
        (item: any) => item.text != removeText
      );
    }
    function showCreateNewForm() {
      // state.formList = []
      state.item.error.erorListNewForm = [];
      state.newFormData.elements = [];
      state.newFormData.title = "";
      state.newFormData.description = "";
      state.newFormData.privacy.code = "channel";
      state.newFormData.privacy.value = "private to this channel";
      state.item.error.erorList = [];
      state.item.hasError = false;
      $("#innerchannelModal").modal("show");
    }
    function showTextFieldAdd(type: string, heading: string) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.form.checkBox.select_values = [];
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.form.radioButton.select_values = [];
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.form.selectOption.select_values = [];
      state.typePosition = "bottom";
      // console.log("CHECK THE TYPE:>>>>", type);
      // console.log("CHECK THE Heading:>>>>", heading);
      state.selected = heading;
      state.type = type;
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.form.radioButton.select_values = [];
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.form.selectOption.select_values = [];
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.form.checkBox.select_values = [];
      if (type === "radioButton") {
        addOptions();
      } else if (type === "single_select") {
        addSelectOptions();
      } else if (type === "checkBox") {
        addCheckBox();
      }
      state.form.textField.title = "";
      state.form.textField.description = "";
      state.form.textbox.title = "";
      state.form.textArea.title = "";
      state.form.radioButton.title = "";
      state.form.selectOption.title = "";
      state.form.checkBox.title = "";
      state.item.error.erorList = [];
      state.item.hasError = false;
      $("#addfieldModal").modal("show");
    }
    function addOptionsinCustom() {
      state.form.radioButton.select_values.push({
        text: "",
        value: "",
        selected: "",
      });
    }
    function addOptionsinCustomEdit() {
      if (
        state.selectedElement.element.select_values &&
        state.selectedElement.element.select_values.length
      ) {
        if (
          state.selectedElement.element.select_values[
            state.selectedElement.element.select_values.length - 1
          ].text &&
          state.selectedElement.element.select_values[
            state.selectedElement.element.select_values.length - 1
          ].value
        ) {
          // alert()
          state.selectedElement.element.select_values.push({
            text: "",
            value: "",
            selected: false,
          });
          //     state.form.radioButton.select_values.push({
          //   text: "",
          //   value: "",
          //   selected: false,
          // });
        }
      }
    }
    function addSelectOptionsinCustomEdit() {
      if (
        state.selectedElement.element.select_values &&
        state.selectedElement.element.select_values.length
      ) {
        if (
          state.selectedElement.element.select_values[
            state.selectedElement.element.select_values.length - 1
          ].text &&
          state.selectedElement.element.select_values[
            state.selectedElement.element.select_values.length - 1
          ].value
        ) {
          // alert();
          state.selectedElement.element.select_values.push({
            text: "",
            value: "",
            selectedOption: {},
            selected: false,
          });
        }
      }
    }
    function addSelectOptionsinCustom() {
      state.form.selectOption.select_values.push({
        text: "",
        value: "",
        selectedOption: {},
        selected: false,
      });
    }
    function addCheckBoxinCustom() {
      state.form.checkBox.select_values.push({
        text: "",
        value: "",
        selected: false,
      });
    }
    function addCheckBoxinCustomEdit() {
      if (
        state.selectedElement.element.select_values &&
        state.selectedElement.element.select_values.length
      ) {
        if (
          state.selectedElement.element.select_values[
            state.selectedElement.element.select_values.length - 1
          ].text &&
          state.selectedElement.element.select_values[
            state.selectedElement.element.select_values.length - 1
          ].value
        ) {
          state.selectedElement.element.select_values.push({
            text: "",
            value: "",
            selected: false,
          });
        }
      }
    }
    function showCustomEdit(type: string, heading: string) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.form.checkBox.select_values = [];
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.form.radioButton.select_values = [];
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.form.selectOption.select_values = [];
      state.typePosition = "bottom";
      state.selected = heading;
      // console.log("CHECK THE TYPE:>>>>", type);
      // console.log("CHECK THE Heading:>>>>", heading);
      state.type = type;
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.form.radioButton.select_values = [];
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.form.selectOption.select_values = [];
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.form.checkBox.select_values = [];
      if (type === "radioButton") {
        addOptionsinCustom();
      } else if (type === "single_select") {
        addSelectOptionsinCustom();
      } else if (type === "checkBox") {
        console.log("Hello CheckBox");
        addCheckBoxinCustom();
      }
      state.form.textbox.title = "";
      state.form.textArea.title = "";
      state.form.radioButton.title = "";
      state.form.selectOption.title = "";
      state.form.checkBox.title = "";
      state.form.textField.description = "";
      state.form.textField.title = "";
      state.item.error.erorList = [];
      $("#addfieldModal1").modal("show");
    }

    function removeCheckBoxinCustomEdit(removeText: string) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.selectedElement.element.select_values = state.selectedElement.element.select_values.filter(
        (item: any) => item.text != removeText
      );
    }
    function removeSelectOptioninCustomEdit(removeText: string) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.selectedElement.element.select_values = state.selectedElement.element.select_values.filter(
        (item: any) => item.text != removeText
      );
    }
    function removeOptionsinCustomEdit(removeText: string) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.selectedElement.element.select_values = state.selectedElement.element.select_values.filter(
        (item: any) => item.text != removeText
      );
    }
    function titleSectionValiation() {
      state.item.error.erorList = [];
      state.item.hasError = false;

      if (!state.form.textField.title) {
        state.item.error.erorList.push("Project name is required.");
      }

      if (!state.item.error.erorList && !state.item.error.erorList.length) {
        state.item.hasError = false;
      } else if (state.item.error.erorList.length != 0) {
        state.item.hasError = true;
      }
    }
    function editElementsCustomValiation(type: string) {
      console.log("WOWOW", type);
      state.item.error.erorList = [];
      state.item.hasError = false;

      if (type === "section") {
        if (!state.form.textField.title) {
          console.log("Alert SSS");
          state.item.error.erorList.push("Section title is required.");
        }
      }
      if (type === "textbox") {
        console.log("Alert TB");
        if (!state.form.textbox.title) {
          state.item.error.erorList.push("Title is required.");
        }
      }
      if (type === "textArea") {
        if (!state.form.textArea.title) {
          state.item.error.erorList.push("Title is required.");
        }
      }
      if (type === "radioButton") {
        console.log("Radio Values Check", state.form.radioButton.select_values);
        if (!state.form.radioButton.title) {
          state.item.error.erorList.push("Title is required.");
        }
        if (
          state.form.radioButton.select_values &&
          state.form.radioButton.select_values.length
        ) {
          state.form.radioButton.select_values.forEach((item: any) => {
            if (!item.text) {
              state.item.error.erorList.push("Options is required.");
            }
          });
        }
      }
      if (type === "single_select") {
        console.log(
          "Radio Values Check",
          state.form.selectOption.select_values
        );
        if (!state.form.selectOption.title) {
          state.item.error.erorList.push("Title is required.");
        }
        if (
          state.form.selectOption.select_values &&
          state.form.selectOption.select_values.length
        ) {
          state.form.selectOption.select_values.forEach((item: any) => {
            if (!item.text) {
              state.item.error.erorList.push("Options is required.");
            }
          });
        }
      }
      if (type === "checkBox") {
        console.log("Radio Values Check", state.form.checkBox.select_values);
        if (!state.form.checkBox.title) {
          state.item.error.erorList.push("Title is required.");
        }
        if (
          state.form.checkBox.select_values &&
          state.form.checkBox.select_values.length
        ) {
          state.form.checkBox.select_values.forEach((item: any) => {
            if (!item.text) {
              state.item.error.erorList.push("Options is required.");
            }
          });
        }
      }

      if (!state.item.error.erorList && !state.item.error.erorList.length) {
        state.item.hasError = false;
      } else if (state.item.error.erorList.length != 0) {
        state.item.hasError = true;
      }
    }

    function addTextFieldInCustomEdit() {
      if (state.type === "section") {
        editElementsCustomValiation("section");
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        if (state.typePosition === "top") {
          state.selectedFormForEdit.elements.unshift({
            type: "section",
            title: state.form.textField.title,
            description: state.form.textField.description,
          });
        }
        if (state.typePosition === "bottom") {
          if (state.selectedFormForEdit.elements.length === 0) {
            state.selectedFormForEdit.elements.push({
              type: "section",
              title: state.form.textField.title,
              description: state.form.textField.description,
            });
          } else {
            state.selectedFormForEdit.elements = state.selectedFormForEdit.elements.concat(
              [
                {
                  type: "section",
                  title: state.form.textField.title,
                  description: state.form.textField.description,
                },
              ]
            );
          }
        }
      }
      if (state.type === "textbox") {
        editElementsCustomValiation("textbox");
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        console.log("FORM POSITION:>>", state.typePosition);
        if (state.typePosition === "top") {
          state.selectedFormForEdit.elements.unshift({
            type: "textbox",
            title: state.form.textbox.title,
            answer: "",
            tooltip: "Please write your full name",
            placeholder: "Write your name here",
            validation: [
              {
                type: "required",
                // eslint-disable-next-line @typescript-eslint/camelcase
                error_message: "Your name is required",
              },
            ],
          });
        }
        if (state.typePosition === "bottom") {
          if (state.selectedFormForEdit.elements.length === 0) {
            state.selectedFormForEdit.elements.unshift({
              type: "textbox",
              title: state.form.textbox.title,
              answer: "",
              tooltip: "Please write your full name",
              placeholder: "Write your name here",
              validation: [
                {
                  type: "required",
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  error_message: "Your name is required",
                },
              ],
            });
          } else {
            state.selectedFormForEdit.elements = state.selectedFormForEdit.elements.concat(
              [
                {
                  type: "textbox",
                  title: state.form.textbox.title,
                  answer: "",
                  tooltip: "Please write your full name",
                  placeholder: "Write your name here",
                  validation: [
                    {
                      type: "required",
                      // eslint-disable-next-line @typescript-eslint/camelcase
                      error_message: "Your name is required",
                    },
                  ],
                },
              ]
            );
          }
        }
      }
      if (state.type === "title") {
        editElementsCustomValiation("title");
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        console.log("FORM POSITION:>>", state.typePosition);
        titleSectionValiation();
        if (
          state.item.error.erorList.length != 0 ||
          state.item.hasError ||
          !state.typePosition
        )
          return false;
        if (state.typePosition === "top") {
          state.selectedFormForEdit.elements.unshift({
            type: "title",
            title: state.form.textField.title,
            description: state.form.textField.description,
          });
        }
        if (state.typePosition === "bottom") {
          if (state.selectedFormForEdit.elements.length === 0) {
            state.selectedFormForEdit.elements.unshift({
              type: "title",
              title: state.form.textField.title,
              description: state.form.textField.description,
            });
          } else {
            state.selectedFormForEdit.elements = state.selectedFormForEdit.elements.concat(
              [
                {
                  type: "title",
                  title: state.form.textField.title,
                  description: state.form.textField.description,
                },
              ]
            );
          }
        }
      }
      if (state.type === "textArea") {
        editElementsCustomValiation("textArea");
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        if (state.typePosition === "top") {
          if (
            state.selectedFormForEdit &&
            state.selectedFormForEdit.elements.length
          ) {
            state.selectedFormForEdit.elements.unshift({
              type: "textArea",
              title: state.form.textArea.title,
              answer: state.form.textArea.text,
              tooltip: "Please write your full name",
              placeholder: "Write your name here",
              validation: [
                {
                  type: "required",
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  error_message: "Your name is required",
                },
              ],
            });
          } else {
            state.selectedFormForEdit.elements.push({
              type: "textArea",
              title: state.form.textArea.title,
              answer: state.form.textArea.text,
              tooltip: "Please write your full name",
              placeholder: "Write your name here",
              validation: [
                {
                  type: "required",
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  error_message: "Your name is required",
                },
              ],
            });
          }
        }
        if (state.typePosition === "bottom") {
          if (state.selectedFormForEdit.elements.length === 0) {
            state.selectedFormForEdit.elements.push({
              type: "textArea",
              title: state.form.textArea.title,
              answer: state.form.textArea.text,
              tooltip: "Please write your full name",
              placeholder: "Write your name here",
              validation: [
                {
                  type: "required",
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  error_message: "Your name is required",
                },
              ],
            });
          } else {
            state.selectedFormForEdit.elements = state.selectedFormForEdit.elements.concat(
              [
                {
                  type: "textArea",
                  title: state.form.textArea.title,
                  answer: state.form.textArea.text,
                  tooltip: "Please write your full name",
                  placeholder: "Write your name here",
                  validation: [
                    {
                      type: "required",
                      // eslint-disable-next-line @typescript-eslint/camelcase
                      error_message: "Your name is required",
                    },
                  ],
                },
              ]
            );
          }
        }
      }
      if (state.type === "radioButton") {
        editElementsCustomValiation("radioButton");
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        state.form.radioButton.select_values.forEach((item: any) => {
          if (item.text) {
            // console.log("CHECK FOR WHITE SPACE", hasWhiteSpace(item.text.toLowerCase()));
            item.value = common.hasWhiteSpace(item.text);
          }
        });
        if (state.typePosition === "top") {
          if (state.form.radioButton) {
            state.selectedFormForEdit.elements.unshift({
              type: "radioButton",
              title: state.form.radioButton.title,
              // eslint-disable-next-line @typescript-eslint/camelcase
              select_values: state.form.radioButton.select_values,
            });
            // state.selectedFormForEdit
            // .elements.unshift( { state.form.radioButton
            // });
          }
        }
        if (state.typePosition === "bottom") {
          if (state.selectedFormForEdit.elements.length === 0) {
            state.selectedFormForEdit.elements.unshift({
              type: "radioButton",
              title: state.form.radioButton.title,
              // eslint-disable-next-line @typescript-eslint/camelcase
              select_values: state.form.radioButton.select_values,
            });
          } else {
            state.selectedFormForEdit.elements = state.selectedFormForEdit.elements.concat(
              [
                {
                  type: "radioButton",
                  title: state.form.radioButton.title,
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  select_values: state.form.radioButton.select_values,
                },
              ]
            );
          }
        }
      }
      if (state.type === "single_select") {
        editElementsCustomValiation("single_select");
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        state.form.selectOption.select_values.forEach((item: any) => {
          if (item.text) {
            // console.log("CHECK FOR WHITE SPACE", hasWhiteSpace(item.text.toLowerCase()));
            item.value = common.hasWhiteSpace(item.text);
          }
        });
        if (state.typePosition === "top") {
          if (state.form.selectOption) {
            state.selectedFormForEdit.elements.unshift({
              type: "single_select",
              selectedOption: {},
              title: state.form.selectOption.title,
              // eslint-disable-next-line @typescript-eslint/camelcase
              select_values: state.form.selectOption.select_values,
            });
          }
        }
        if (state.typePosition === "bottom") {
          if (state.selectedFormForEdit.elements.length === 0) {
            state.selectedFormForEdit.elements.unshift({
              type: "single_select",
              selectedOption: {},
              title: state.form.selectOption.title,
              // eslint-disable-next-line @typescript-eslint/camelcase
              select_values: state.form.selectOption.select_values,
            });
          } else {
            state.selectedFormForEdit.elements = state.selectedFormForEdit.elements.concat(
              [
                {
                  type: "single_select",
                  selectedOption: {},
                  title: state.form.selectOption.title,
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  select_values: state.form.selectOption.select_values,
                },
              ]
            );
          }
        }
      }
      if (state.type === "checkBox") {
        editElementsCustomValiation("checkBox");
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        state.form.checkBox.select_values.forEach((item: any) => {
          if (item.text) {
            item.value = common.hasWhiteSpace(item.text);
          }
        });
        if (state.typePosition === "top") {
          if (state.form.selectOption) {
            state.selectedFormForEdit.elements.unshift({
              type: "checkBox",
              title: state.form.checkBox.title,
              // eslint-disable-next-line @typescript-eslint/camelcase
              select_values: state.form.checkBox.select_values,
            });
            // state..unshift( { state.form.checkBox
            // });
          }
        }
        if (state.typePosition === "bottom") {
          if (state.selectedFormForEdit.elements.length === 0) {
            state.selectedFormForEdit.elements.unshift({
              type: "checkBox",
              title: state.form.checkBox.title,
              // eslint-disable-next-line @typescript-eslint/camelcase
              select_values: state.form.checkBox.select_values,
            });
          } else {
            state.selectedFormForEdit.elements = state.selectedFormForEdit.elements.concat(
              [
                {
                  type: "checkBox",
                  title: state.form.checkBox.title,
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  select_values: state.form.checkBox.select_values,
                },
              ]
            );
          }
        }
      }

      $("#addfieldModal1").modal("hide");
      // console.log("CHECK CUstom Edit Data", state.selectedFormForEdit);
    }
    function addElementsCreateNewValiation(type: string) {
      state.item.error.erorList = [];
      state.item.hasError = false;

      if (type === "section") {
        if (!state.form.textField.title) {
          state.item.error.erorList.push("Section title is required.");
        }
      }
      if (type === "textbox") {
        if (!state.form.textbox.title) {
          state.item.error.erorList.push("Title is required.");
        }
      }
      if (type === "textArea") {
        if (!state.form.textArea.title) {
          state.item.error.erorList.push("Title is required.");
        }
      }
      if (type === "radioButton") {
        console.log("Radio Values Check", state.form.radioButton.select_values);
        if (!state.form.radioButton.title) {
          state.item.error.erorList.push("Title is required.");
        }
        if (
          state.form.radioButton.select_values &&
          state.form.radioButton.select_values.length
        ) {
          state.form.radioButton.select_values.forEach((item: any) => {
            if (!item.text) {
              state.item.error.erorList.push("Options is required.");
            }
          });
        }
      }
      if (type === "single_select") {
        console.log(
          "Radio Values Check",
          state.form.selectOption.select_values
        );
        if (!state.form.selectOption.title) {
          state.item.error.erorList.push("Title is required.");
        }
        if (
          state.form.selectOption.select_values &&
          state.form.selectOption.select_values.length
        ) {
          state.form.selectOption.select_values.forEach((item: any) => {
            if (!item.text) {
              state.item.error.erorList.push("Options is required.");
            }
          });
        }
      }
      if (type === "checkBox") {
        console.log("Radio Values Check", state.form.checkBox.select_values);
        if (!state.form.checkBox.title) {
          state.item.error.erorList.push("Title is required.");
        }
        if (
          state.form.checkBox.select_values &&
          state.form.checkBox.select_values.length
        ) {
          state.form.checkBox.select_values.forEach((item: any) => {
            if (!item.text) {
              state.item.error.erorList.push("Options is required.");
            }
          });
        }
      }

      if (!state.item.error.erorList && !state.item.error.erorList.length) {
        state.item.hasError = false;
      } else if (state.item.error.erorList.length != 0) {
        state.item.hasError = true;
      }
    }
    function addTextField() {
      if (state.type === "section") {
        addElementsCreateNewValiation("section");
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        console.log("Section FORM POSITION:>>", state.typePosition);
        if (state.typePosition === "top") {
          state.newFormData.elements.unshift({
            type: "section",
            title: state.form.textField.title,
            description: state.form.textField.description,
          });
        }
        if (state.typePosition === "bottom") {
          if (state.newFormData.elements.length === 0) {
            state.newFormData.elements.push({
              type: "section",
              title: state.form.textField.title,
              description: state.form.textField.description,
            });
          } else {
            state.newFormData.elements = state.newFormData.elements.concat([
              {
                type: "section",
                title: state.form.textField.title,
                description: state.form.textField.description,
              },
            ]);
          }
        }
      }
      if (state.type === "textbox") {
        addElementsCreateNewValiation("textbox");
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        console.log("FORM POSITION:>>", state.typePosition);
        if (state.typePosition === "top") {
          state.newFormData.elements.unshift({
            type: "textbox",
            title: state.form.textbox.title,
            answer: "",
            tooltip: "Please write your full name",
            placeholder: "Write your name here",
            validation: [
              {
                type: "required",
                // eslint-disable-next-line @typescript-eslint/camelcase
                error_message: "Your name is required",
              },
            ],
          });
        }
        if (state.typePosition === "bottom") {
          console.log(
            "Check Array length >>>",
            state.newFormData.elements.length
          );
          if (state.newFormData.elements.length === 0) {
            state.newFormData.elements.push({
              type: "textbox",
              title: state.form.textbox.title,
              answer: "",
              tooltip: "Please write your full name",
              placeholder: "Write your name here",
              validation: [
                {
                  type: "required",
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  error_message: "Your name is required",
                },
              ],
            });
          } else {
            state.newFormData.elements = state.newFormData.elements.concat([
              {
                type: "textbox",
                title: state.form.textbox.title,
                answer: "",
                tooltip: "Please write your full name",
                placeholder: "Write your name here",
                validation: [
                  {
                    type: "required",
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    error_message: "Your name is required",
                  },
                ],
              },
            ]);
          }
        }
      }
      if (state.type === "textArea") {
        addElementsCreateNewValiation("textArea");
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        if (state.typePosition === "top") {
          if (state.newFormData && state.newFormData.elements.length) {
            state.newFormData.elements.unshift({
              type: "textArea",
              title: state.form.textArea.title,
              answer: state.form.textArea.text,
              tooltip: "Please write your full name",
              placeholder: "Write your name here",
              validation: [
                {
                  type: "required",
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  error_message: "Your name is required",
                },
              ],
            });
          } else {
            state.newFormData.elements.push({
              type: "textArea",
              title: state.form.textArea.title,
              answer: state.form.textArea.text,
              tooltip: "Please write your full name",
              placeholder: "Write your name here",
              validation: [
                {
                  type: "required",
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  error_message: "Your name is required",
                },
              ],
            });
          }
        }
        if (state.typePosition === "bottom") {
          console.log(
            "Check Array length >>>",
            state.newFormData.elements.length
          );
          if (state.newFormData.elements.length === 0) {
            state.newFormData.elements.unshift({
              type: "textArea",
              title: state.form.textArea.title,
              answer: state.form.textArea.text,
              tooltip: "Please write your full name",
              placeholder: "Write your name here",
              validation: [
                {
                  type: "required",
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  error_message: "Your name is required",
                },
              ],
            });
          } else {
            state.newFormData.elements = state.newFormData.elements.concat([
              {
                type: "textArea",
                title: state.form.textArea.title,
                answer: state.form.textArea.text,
                tooltip: "Please write your full name",
                placeholder: "Write your name here",
                validation: [
                  {
                    type: "required",
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    error_message: "Your name is required",
                  },
                ],
              },
            ]);
          }
        }
      }
      if (state.type === "radioButton") {
        addElementsCreateNewValiation("radioButton");
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        // console.log("FORM POSITION:>>", state.typePosition);
        // console.log("Check Radio Button:>>", state.form.radioButton);
        state.form.radioButton.select_values.forEach((item: any) => {
          if (item.text) {
            // console.log("CHECK FOR WHITE SPACE", hasWhiteSpace(item.text.toLowerCase()));
            item.value = common.hasWhiteSpace(item.text);
          }
        });
        if (state.typePosition === "top") {
          if (state.form.radioButton) {
            state.newFormData.elements.unshift({
              type: "radioButton",
              title: state.form.radioButton.title,
              // eslint-disable-next-line @typescript-eslint/camelcase
              select_values: state.form.radioButton.select_values,
            });
            // state.newFormData.elements.unshift( { state.form.radioButton
            // });
          }
        }
        if (state.typePosition === "bottom") {
          console.log(
            "Check Array length >>>",
            state.newFormData.elements.length
          );
          if (state.newFormData.elements.length === 0) {
            if (state.form.radioButton) {
              state.newFormData.elements.unshift({
                type: "radioButton",
                title: state.form.radioButton.title,
                // eslint-disable-next-line @typescript-eslint/camelcase
                select_values: state.form.radioButton.select_values,
              });
              // state.newFormData.elements.unshift( { state.form.radioButton
              // });
            }
          } else {
            state.newFormData.elements = state.newFormData.elements.concat([
              {
                type: "radioButton",
                title: state.form.radioButton.title,
                // eslint-disable-next-line @typescript-eslint/camelcase
                select_values: state.form.radioButton.select_values,
              },
            ]);
          }
        }
        console.log(
          "Check the radio data",
          state.form.radioButton.select_values
        );
      }
      if (state.type === "single_select") {
        console.log("SELECT OPTION >>>", state.form.selectOption.select_values);
        addElementsCreateNewValiation("single_select");
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        state.form.selectOption.select_values.forEach((item: any) => {
          if (item.text) {
            // console.log("CHECK FOR WHITE SPACE", hasWhiteSpace(item.text.toLowerCase()));
            item.value = common.hasWhiteSpace(item.text);
          }
        });
        console.log("FORM POSITION:>>", state.typePosition);
        if (state.typePosition === "top") {
          if (state.form.selectOption) {
            state.newFormData.elements.unshift({
              type: "single_select",
              selectedOption: {},
              title: state.form.selectOption.title,
              // eslint-disable-next-line @typescript-eslint/camelcase
              select_values: state.form.selectOption.select_values,
            });
          }
        }
        if (state.typePosition === "bottom") {
          if (state.newFormData.elements.length === 0) {
            state.newFormData.elements.unshift({
              type: "single_select",
              selectedOption: {},
              title: state.form.selectOption.title,
              // eslint-disable-next-line @typescript-eslint/camelcase
              select_values: state.form.selectOption.select_values,
            });
          } else {
            state.newFormData.elements = state.newFormData.elements.concat([
              {
                type: "single_select",
                selectedOption: {},
                title: state.form.selectOption.title,
                // eslint-disable-next-line @typescript-eslint/camelcase
                select_values: state.form.selectOption.select_values,
              },
            ]);
          }
        }
      }
      if (state.type === "checkBox") {
        addElementsCreateNewValiation("checkBox");
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        state.form.checkBox.select_values.forEach((item: any) => {
          if (item.text) {
            item.value = common.hasWhiteSpace(item.text);
          }
        });
        console.log("FORM POSITION:>>", state.typePosition);
        if (state.typePosition === "top") {
          if (state.form.selectOption) {
            state.newFormData.elements.unshift({
              type: "checkBox",
              title: state.form.checkBox.title,
              // eslint-disable-next-line @typescript-eslint/camelcase
              select_values: state.form.checkBox.select_values,
            });
            // state..unshift( { state.form.checkBox
            // });
          }
        }
        if (state.typePosition === "bottom") {
          if (state.newFormData.elements.length === 0) {
            if (state.form.selectOption) {
              state.newFormData.elements.unshift({
                type: "checkBox",
                title: state.form.checkBox.title,
                // eslint-disable-next-line @typescript-eslint/camelcase
                select_values: state.form.checkBox.select_values,
              });
              // state..unshift( { state.form.checkBox
              // });
            }
          } else {
            state.newFormData.elements = state.newFormData.elements.concat({
              type: "checkBox",
              title: state.form.checkBox.title,
              // eslint-disable-next-line @typescript-eslint/camelcase
              select_values: state.form.checkBox.select_values,
            });
          }
        }
      }

      $("#addfieldModal").modal("hide");
      console.log("CHECK", state.newFormData);
    }

    function scrollAtButton() {
      // location.reload();
      window.onload = function () {
        const objDiv: any = document.getElementById("chat-body-content");
        objDiv.scrollTop = objDiv.scrollHeight;
      };
      // return false;
    }
    function cancelEditSelectedForm() {
      // state.selectedFormForEdit.title = state.selectedFormForEdit.title
      //   state.selectedFormForEdit.description = state.selectedFormForEdit.description
      $("#editSelectedElement").modal("hide");
    }
    function updateEditFormElementsValidation(type: string) {
      state.item.error.erorList = [];
      state.item.hasError = false;

      if (type === "title") {
        if (!state.selectedElement.title) {
          state.item.error.erorList.push("Form title is required.");
        }
      }
      if (type === "section" || type === "textbox" || type === "textArea") {
        if (!state.selectedElement.title) {
          state.item.error.erorList.push("Title is required.");
        }
      }
      if (
        type === "radioButton" ||
        type === "single_select" ||
        type === "checkBox"
      ) {
        console.log("Radio Values Check", state.form.radioButton.select_values);
        if (!state.selectedElement.element.title) {
          state.item.error.erorList.push("Title is required.");
        }
        if (
          state.selectedElement.element.select_values &&
          state.selectedElement.element.select_values.length
        ) {
          state.selectedElement.element.select_values.forEach((item: any) => {
            if (!item.text) {
              state.item.error.erorList.push("Options is required.");
            }
          });
        }
      }
      if (!state.item.error.erorList && !state.item.error.erorList.length) {
        state.item.hasError = false;
      } else if (state.item.error.erorList.length != 0) {
        state.item.hasError = true;
      }
    }
    function editSelectedForm() {
      if (state.selectedElement.type === "title") {
        updateEditFormElementsValidation("title");
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        // console.log("<<< Title Change >>>");
        state.selectedFormForEdit.title = state.selectedElement.title;
        state.selectedFormForEdit.description =
          state.selectedElement.description;
      } else if (
        state.selectedElement.type === "textbox" ||
        state.selectedElement.type === "section"
      ) {
        updateEditFormElementsValidation(state.selectedElement.type);
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        // state.selectedFormForEdit.title = state.selectedElement.title;
        state.selectedFormForEdit.elements[state.selectedElement.index].title =
          state.selectedElement.title;
        state.selectedFormForEdit.elements[
          state.selectedElement.index
        ].description = state.selectedElement.description;
        // console.log("<<< Title Change >>>");
      } else if (state.selectedElement.type === "textArea") {
        updateEditFormElementsValidation(state.selectedElement.type);
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        // state.selectedFormForEdit.title = state.selectedElement.title;
        // console.log("<<< Title Change >>>");
        state.selectedFormForEdit.elements[state.selectedElement.index].title =
          state.selectedElement.title;
      } else if (
        state.selectedElement.type === "radioButton" ||
        state.selectedElement.type === "single_select" ||
        state.selectedElement.type === "checkBox"
      ) {
        // console.log("<<< Title Change >>>");
        updateEditFormElementsValidation(state.selectedElement.type);
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;

        state.selectedFormForEdit.elements[state.selectedElement.index] =
          state.selectedElement.element;
      }
      // state.selectedElement.index

      // state.selectedFormForEdit.elements.splice(
      //   state.selectedElement.index,
      //   1,
      //   state.selectedElement.element
      // );
      state.item.error.erorList = [];
      state.item.hasError = false;
      $("#editSelectedElement").modal("hide");
    }
    function showEditFormName(data: any) {
      state.selectedElement.type = data;
      state.item.error.erorList = [];
      state.item.hasError = false;
      $("#editSelectedElement").modal("show");
      console.log("Title Data Check: >>", state.selectedElement.type);
      console.log("edit Title:>>", state.selectedFormForEdit.title);
      console.log("edit Description:>>", state.selectedFormForEdit.description);
      state.selectedElement.title = state.selectedFormForEdit.title;
      state.selectedElement.description = state.selectedFormForEdit.description;
    }
    function showDeleteElement(form: any, elementType: string) {
      // addTextField;
      console.log("Element Selected for Delete: >>> ", form);
      console.log("Element Type: >>> ", elementType);
      const formIndex = common.findIndex(
        state.selectedFormForEdit.elements,
        form
      );
      console.log("Selected Element Index For Delete", formIndex);
      state.selectedElement.element = form;
      $("#deleteSelectedElement").modal("show");
    }
    function cancelDeleteSelectedForm() {
      state.selectedElement.element = {};
      $("#deleteSelectedElement").modal("hide");
    }
    function confirmDeleteElement() {
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.selectedFormForEdit.elements = state.selectedFormForEdit.elements.filter(
        (item: any) => item != state.selectedElement.element
      );
      $("#deleteSelectedElement").modal("hide");
    }
    function showEditElement(form: any, elementType: string) {
      // addTextField;
      console.log("Edit Element Selected: >>> ", form);
      console.log("Element Type: >>> ", elementType);
      const formIndex = common.findIndex(
        state.selectedFormForEdit.elements,
        form
      );
      console.log("Selected Form Index", formIndex);
      $("#editSelectedElement").modal("show");
      state.selectedElement.element = form;
      state.selectedElement.type = elementType;
      state.selectedElement.index = common.findIndex(
        state.selectedFormForEdit.elements,
        form
      );
      state.selectedElement.title = form.title;
      state.selectedElement.description = form.description;
    }
    function selectedElement(form: any) {
      console.log("Selected element>>>:", form);
    }
    function handleClick() {
      console.log("EMIT Check");
    }
    function getAccountFormRetrieveById(formId: any) {
      // console.log("Get Account form Response:>>>", formId);
      state.ui.isPostLoading.getAccountFormRetrieveById = true;
      ethitransService
        .accountFormRetrieveById(formId)
        .then((res: any) => {
          console.log(
            "getAccountFormRetrieveById Response Check:>>> ",
            res.data.elements
          );
          // state.formData = res.data;
          state.selectedFormForEdit = res.data ? res.data : {};
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.getAccountFormRetrieveById = false;
        });
    }
    function getAccountFormRetrieveByIdForSend(formId: any) {
      // console.log("Get Account form Response:>>>", formId);
      state.ui.isPostLoading.getAccountFormRetrieveById = true;
      ethitransService
        .accountFormRetrieveById(formId)
        .then((res: any) => {
          console.log(
            "getAccountFormRetrieveById Response Check:>>> ",
            res.data.elements
          );
          state.formData = res.data ? res.data : {};
          // state.selectedFormForEdit = res.data ? res.data : {};
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.getAccountFormRetrieveById = false;
        });
    }
    function hideFormView() {
      $("#formview").modal("hide");
    }
    function showMentionUser() {
      getUserList();
      state.invite.users = [];
      state.ui.template.msg = "";
      state.item.error.erorListSendForm = [];
      state.item.hasError = false;
      $("#mentionuser").modal("show");
    }
    function showFormView(formId: any) {
      $("#formview").modal("show");
      console.log("Get Account form Response:>>>", formId);
      state.ui.isPostLoading.getAccountFormRetrieveById = true;
      ethitransService
        .accountFormRetrieveById(formId)
        .then((res: any) => {
          // state.formResponse = res.data
          // console.log(
          //   "HELLO FROM COMMUNICATION Response Check:>>> ",
          //   res.data
          // );
          state.formData = res.data;
          console.log("DATA CHECK", state.formData);
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.getAccountFormRetrieveById = false;
        });
    }
    function hideTemplateModal() {
      $("#exampleModal").modal("hide");
    }
    function hideEditTemplateModal() {
      $("#editExampleModal").modal("hide");
    }
    function showEditTemplateModal() {
      $("#editExampleModal").modal("show");
    }
    function selectEditForm(form: any) {
      state.invite.users = [];
      state.ui.template.msg = "";
      state.newFormData.privacy.code = "channel";
      state.newFormData.privacy.value = "private to this channel";
      $("#createnewformModal").modal("show");
      // $("#editExampleModal").modal("show");
      // createnewformModal
      console.log("Selected Form:>>", form);
      // state.selectedFormForEdit.id = form._id
      getAccountFormRetrieveById(form._id);
      // console.log("Selected FOR FOR EDIT:>>", state.selectedFormForEdit.id);
    }
    function selectForm(form: any) {
      state.newFormData.privacy.code = "channel";
      state.newFormData.privacy.value = "private to this channel";
      $("#exampleModal").modal("show");
      console.log("Selected Form:>>", form);
      getAccountFormRetrieveByIdForSend(form._id);
    }
    function scrollToLast() {
      $("#chat-body-content").animate(
        { scrollTop: $("#chat-body-content")[0].scrollHeight },
        1000
      );
    }
    function getAccountFormList() {
      const payLoad = {
        search: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          project_id: state.projectId,
          // eslint-disable-next-line @typescript-eslint/camelcase
          channel_id: channelId,
          // eslint-disable-next-line @typescript-eslint/camelcase
          form_name: state.searchBy,
        },
        filter: {
          type: state.filter.type,
          email: state.owner.created_by.email
            ? state.owner.created_by.email
            : "",
        },
        // eslint-disable-next-line @typescript-eslint/camelcase
        sort_by: {
          title: -1,
          // "owner.created_by.name": state.owner.created_by.full_name
          //   ? state.owner.created_by.full_name
          //   : "",
        },
        paging: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          per_page: 1000,
          // eslint-disable-next-line @typescript-eslint/camelcase
          current_page: 1,
        },
      };
      state.ui.isLoading.getAccountFormList = true;
      ethitransService
        .projectAccountFormListSearch(payLoad)
        .then((res: any) => {
          console.log("sharedFormList Response Check:>>> ", res.data);
          state.sharedFormList = res.data ? res.data : {};
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isLoading.getAccountFormList = false;
        });
    }
    function getCommunicationList() {
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: channelId,
      };
      state.postLoading = true;
      ethitransService
        .createCommunicationList(payLoad)
        .then((res: any) => {
          console.log("CommunicationList Response Check:>>> ", res.data.data);
          state.channel = res.data.data.channel ? res.data.data.channel : {};
          state.pendingMembersCount = res.data.data.pending_members_count
            ? res.data.data.pending_members_count
            : 0;
          state.approvedMembersCount = res.data.data.approved_members_count
            ? res.data.data.approved_members_count
            : 0;
          state.pendingList = res.data.data.pending_members
            ? res.data.data.pending_members
            : [];
          state.userRole = res.data.data.project_role
            ? res.data.data.project_role
            : {};
          state.projectId = res.data.data.channel.project.uuid
            ? res.data.data.channel.project.uuid
            : "";
          // state.projectId = res.data.data.channel.project_id
          //   ? res.data.data.channel.project_id
          //   : 0;
          res.data.data.messages.forEach((item: any) => {
            if (item.form_data != null) {
              // console.log("check msg :>>", item.form_data);
              if (item.form_data && item.form_data.sent_to_users) {
                // console.log(
                //   "Final :>>",
                //   JSON.parse(item.form_data.sent_to_users)
                // );
                // eslint-disable-next-line @typescript-eslint/camelcase
                item.form_data.sent_to_users = JSON.parse(
                  item.form_data.sent_to_users
                );
              }
            }
          });
          state.communicationList = res.data.data.messages
            ? res.data.data.messages
            : [];
          if(state.communicationList && state.communicationList.length){
            state.communicationList.forEach((data: any)=> {
              data.allowEdit = false
            })
          }
          state.memberList = res.data.data.channel.members
            ? res.data.data.channel.members
            : [];

          // console.log("WOW PROJECT ID", state.projectId);
          state.owner = res.data.data.channel;
          // scrollToLast()
      //     $("#chat-body-content").animate(
      //   { scrollTop: $("#chat-body-content")[0].scrollHeight },
      //   500
      // );
      //  scrollToLast();
      // scrollAtButton()
          getAccountFormList();
          // getUserList();
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
                    // scrollAtButton();
          state.postLoading = false;
        });
    }
    function addNewMemberToChannel() {
      getUserList()
      $("#successModal").modal("hide");
      $("#inviteMember").modal("show");
      state.invite.users = [];
      state.ui.template.msg = "";
      state.invite.invites = [];
      state.invite.filteredUsers = [];
      state.invite.filteredInvites = [];
    }
    function hideAddMember() {
      // alert("Add New Members");
      $("#inviteMember").modal("hide");
    }
    function changeExistingMember() {
      // // console.log("CHECK MSG", state.existingMember);
      // state.invite.users.push(state.existingMember);
      // // console.log("CHECK MSG AFTER", state.invite.users);
      // state.existingMember = "";
      if (state.invite.users.indexOf(state.existingMember) === -1) {
        state.invite.users.push(state.existingMember);
        // console.log(state.invite.users);
      }
      state.userList = state.userList.filter(
        (e: any) => e != state.existingMember
      );
      state.existingMember = {};
    }
    function changeExistingMemberInForm() {
      if (state.invite.users.indexOf(state.existingMember) === -1) {
        state.invite.users.push(state.existingMember);
        // console.log(state.invite.users);
      }
      state.userList = state.userList.filter(
        (e: any) => e != state.existingMember
      );
      state.existingMember = {};
    }
    function removeSelectedMember(item: any) {
      // console.log("check the sELCTIOn", item);
      state.invite.users = state.invite.users.filter((e: any) => e != item);
      state.userList.push(item);
      // console.log("AFTER DELECTION", state.invite.users);
    }
    function changeInviteMember() {
      if (validationService.isValidEmail(state.inviteMember)) {
        // console.log("CHECK THE INVITE MEMBER", state.inviteMember);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (state.invite.invites.length === 0) {
          state.invite.invites.push(state.inviteMember);
        } else {
          state.invite.invites.forEach((member: any) => {
            console.log("member", member);
            if (member != state.inviteMember) {
              state.invite.invites.push(state.inviteMember);
            }
          });
        }
        state.inviteMember = "";
      }
    }
    function removeSelectedUser(item: any) {
      console.log("check the sELCTIOn", item);
      state.invite.invites = state.invite.invites.filter((e: any) => e != item);
      console.log("AFTER DELECTION", state.invite.invites);
    }
    function downloadFiles() {
      alert("Download Files");
    }
    function closeChannel() {
      alert("Close Channel");
    }
    function showSuccessModal() {
      $("#successModal").modal("show");
    }
    function removeSelectedImage(image: any) {
      console.log("check the sELCTIOn", image);
      state.imageList = state.imageList.filter((e: any) => e != image);
      console.log("AFTER DELECTION", state.imageList);
    }
    function validateInviteForm() {
      state.errorList = [];
      state.isErrorStatus = false;

      if (!state.invite.users.length && !state.invite.filteredInvites.length) {
        state.errorList.push("Invite member is required.");
      }
      if (state.inviteMember) {
        if (!validationService.isValidEmail(state.inviteMember)) {
          state.errorList.push("Invalid email.");
        }
      }

      if (!state.errorList && !state.errorList.length) {
        state.isErrorStatus = false;
      } else if (state.errorList.length != 0) {
        state.isErrorStatus = true;
      }

      console.log("CHECK BOOLEAN", state.isErrorStatus);
    }
    function inviteAllUsers() {
      state.invite.users.forEach((item: any) => {
        state.invite.filteredUsers.push(item.email);
      });
      state.invite.invites.forEach((item: any) => {
        console.log("CHECK email", item);
        state.invite.filteredInvites.push(item);
      });
      if (state.inviteMember && state.invite.invites.length === 0) {
        if (validationService.isValidEmail(state.inviteMember)) {
          console.log("CHECK THE INVITE MEMBER", state.inviteMember);
          state.invite.filteredInvites.push(state.inviteMember);
        }
      }
      validateInviteForm();
      if (
        state.errorList.length != 0 ||
        state.isErrorStatus ||
        state.isLoading.inviteAllUsers
      )
        return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: state.channel.project.uuid,
        // eslint-disable-next-line @typescript-eslint/camelcase
        invitation_type: "channel_invitation",
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: channelId,
        users: state.invite.filteredUsers,
        invites: state.invite.filteredInvites,
      };
      console.log("CHECK PAYLOAD ON API CALL", payLoad);
      state.isLoading.inviteAllUsers = true;
      ethitransService
        .invitation(payLoad)
        .then((res: any) => {
          console.log("Users Invite Check:>>> ", res.data.message);
          hideAddMember();
          showSuccessModal();
          state.invitationMessage = res.data.message ? res.data.message : "";
          getCommunicationList();
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.isLoading.inviteAllUsers = false;
        });
    }
    
    function uploadAttachmentByChannel(
      imageLoaded: any,
      selectedChannelId: number
    ) {
      console.log("PAYLOAD>>>>>>>>>", imageLoaded);
      console.log("PAYLOAD CHECK CHANNEL ID>>", selectedChannelId);
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        communication_id: selectedChannelId,
        attachment: imageLoaded,
      };
      console.log("PAYLOAD CHECK >>", payLoad);
      ethitransService
        .uploadAttachment(payLoad)
        .then((res: any) => {
          console.log(
            "<<<Create Communication Response Check:>>> ",
            res.data.data
          );
          state.imageList = state.imageList.filter(
            (e: any) => e != imageLoaded
          );
          scrollToLast();
          // state.message = "";
          getCommunicationList();
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.postLoading = false;
        });
    }
    function readImage(file: any) {
      console.log("CHECK IMAGE>>", file);
      let data = "";
      const reader = new FileReader();
      reader.onload = (e: any) => {
        console.log("BIT BIT>>", e);
        const extension = file.name.substring(file.name.lastIndexOf(".") + 1);

        if (
          extension === "jpg" ||
          extension === "jpeg" ||
          extension === "pdf" ||
          extension === "png"
        ) {
          data = e.target.result;
          console.log("CHECK DATA FOR UPLOAD>>", data);
          state.imageList.push(data);
          // tempImageList.push
        }
      };
      reader.readAsDataURL(file);
    }
    function onDocumentChange(event: any) {
      const files = event.target.files || event.dataTransfer.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
        const extensionLower = extension.toLowerCase();
        if (
          extensionLower === "jpg" ||
          extensionLower === "jpeg" ||
          extensionLower === "pdf" ||
          extensionLower === "png"
        )
          readImage(file);
        // console.log("CHECK DATA WHEN UPLOADED>>", file);
      }
      // $("#gallery-photo-add").val("")
    }
    function createCommunicationList() {
      // if (state.postLoading) return false;
      // console.log("AAYo 2", state.postLoading);
      if (state.imageList.length === 0) {
        if (!state.message) return false;
        // console.log("AAYo", state.postLoading);
      }
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: channelId,
        message: state.message,
      };
      state.postLoading = true;
      ethitransService
        .createCommunication(payLoad)
        .then((res: any) => {
          console.log(
            "<<<Create Communication Response Check:>>> ",
            res.data.data
          );
          if (state.imageList && state.imageList.length) {
            console.log(
              "<<<<**READY FOR UPLOAD**>>>>",
              res.data.data.messages[0].channel_id
            );
            state.imageList.filter((item: any) => {
              // console.log("CHECK ITEM");
              uploadAttachmentByChannel(item, res.data.data.communication.id);
            });
          }
          state.message = "";
          // console.log("CommunicationList Response Check:>>> ", res.data.data);
          state.channel = res.data.data.channel ? res.data.data.channel : {};
          res.data.data.messages.forEach((item: any) => {
            if (item.form_data != null) {
              console.log("check msg :>>", item.form_data);
              if (item.form_data && item.form_data.sent_to_users) {
                console.log(
                  "Final :>>",
                  JSON.parse(item.form_data.sent_to_users)
                );
                // eslint-disable-next-line @typescript-eslint/camelcase
                item.form_data.sent_to_users = JSON.parse(
                  item.form_data.sent_to_users
                );
              }
            }
          });
          state.communicationList = res.data.data.messages
            ? res.data.data.messages
            : [];
            if(state.communicationList && state.communicationList.length){
            state.communicationList.forEach((data: any)=> {
              data.allowEdit = false
            })
          }
          state.memberList = res.data.data.channel.members
            ? res.data.data.channel.members
            : [];
          // scrollAtButton();
          scrollToLast();
          // $("#chat-body").animate({ scrollTop: $("#chat-body")[0].scrollHeight}, 1000);

          // $('#chat-body')[0].scrollTop = $('#chat-body')[0].scrollHeight;
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.postLoading = false;
        });
    }
    function getChannelFormList() {
      const payLoad = {
        search: {
          // project_id: '278241ec-7e7f-468f-a17b-9504773225c0',
          // eslint-disable-next-line @typescript-eslint/camelcase
          project_id: state.projectId,
          // eslint-disable-next-line @typescript-eslint/camelcase
          channel_id: channelId,
          // eslint-disable-next-line @typescript-eslint/camelcase
          form_name: state.searchBy,
        },
        filter: {
          type: state.filter.type,
          email: state.email,
        },
        // eslint-disable-next-line @typescript-eslint/camelcase
        sort_by: {
          title: 1,
          // "owner.created_by.name": state.owner.created_by.full_name,
        },
        paging: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          per_page: 1000,
          // eslint-disable-next-line @typescript-eslint/camelcase
          current_page: 1,
        },
      };
      state.ui.isLoading.getChannelFormList = true;
      ethitransService
        .channelAccountFormListSearch(payLoad)
        .then((res: any) => {
          console.log("getChannelFormList Response Check:>>> ", res.data);
          state.formList = res.data ? res.data : [];
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isLoading.getChannelFormList = false;
        });
    }
    // function getChannelFormList() {
    //   state.ui.isLoading.getChannelFormList = true;
    //   ethitransService
    //     .accountFormList()
    //     .then((res: any) => {
    //       console.log("getChannelFormList Response Check:>>> ", res.data);
    //       state.formList = res.data ? res.data : [];
    //     })
    //     .catch((error: any) => {
    //       console.log(error);
    //     })
    //     .finally(() => {
    //       state.ui.isLoading.getChannelFormList = false;
    //     });
    // }
    function channelCommunicationForm(id: any) {
      const data = state.ui.template.response;
      // state.owner
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        communication_id: id,
        // eslint-disable-next-line @typescript-eslint/camelcase
        form_data: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          form_id: data._id,
          // eslint-disable-next-line @typescript-eslint/camelcase
          form_name: data.title,
          // eslint-disable-next-line @typescript-eslint/camelcase
          form_desc: data.description,
          // eslint-disable-next-line @typescript-eslint/camelcase
          sent_to_users: data.sentToUsers,
        },
      };
      console.log("<< ChannelCommunicationForm CHECK >>", payLoad);
      state.ui.isPostLoading.createFormTemplate = true;
      ethitransService
        .channelCommunicationFormData(payLoad)
        .then((res: any) => {
          console.log("ChannelCommunicationForm Response Check:>>> ", res.data);
          state.message = "";
          // console.log("CommunicationList Response Check:>>> ", res.data.data);
          state.channel = res.data.data.channel ? res.data.data.channel : {};
          res.data.data.messages.forEach((item: any) => {
            if (item.form_data != null) {
              console.log("check msg :>>", item.form_data);
              if (item.form_data && item.form_data.sent_to_users) {
                console.log(
                  "Final :>>",
                  JSON.parse(item.form_data.sent_to_users)
                );
                // eslint-disable-next-line @typescript-eslint/camelcase
                item.form_data.sent_to_users = JSON.parse(
                  item.form_data.sent_to_users
                );
              }
            }
          });
          state.communicationList = res.data.data.messages
            ? res.data.data.messages
            : [];
            if(state.communicationList && state.communicationList.length){
            state.communicationList.forEach((data: any)=> {
              data.allowEdit = false
            })
          }
          state.memberList = res.data.data.channel.members
            ? res.data.data.channel.members
            : [];
          // scrollAtButton();
          scrollToLast();
          $("#templateupload").modal("hide");
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.createFormTemplate = false;
        });
    }
    function formMessage(msg: string) {
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: channelId,
        message: msg,
      };
      state.postLoading = true;
      ethitransService
        .createCommunication(payLoad)
        .then((res: any) => {
          console.log(
            "<<<Create Communication Response Check:>>> ",
            res.data.data
          );
          state.message = "";
          // console.log("CommunicationList Response Check:>>> ", res.data.data);
          state.channel = res.data.data.channel ? res.data.data.channel : {};
          res.data.data.messages.forEach((item: any) => {
            if (item.form_data != null) {
              console.log("check msg :>>", item.form_data);
              if (item.form_data && item.form_data.sent_to_users) {
                console.log(
                  "Final :>>",
                  JSON.parse(item.form_data.sent_to_users)
                );
                // eslint-disable-next-line @typescript-eslint/camelcase
                item.form_data.sent_to_users = JSON.parse(
                  item.form_data.sent_to_users
                );
              }
            }
          });
          state.communicationList = res.data.data.messages
            ? res.data.data.messages
            : [];
            if(state.communicationList && state.communicationList.length){
            state.communicationList.forEach((data: any)=> {
              data.allowEdit = false
            })
          }
          state.memberList = res.data.data.channel.members
            ? res.data.data.channel.members
            : [];
          // scrollAtButton();
          scrollToLast();
          channelCommunicationForm(res.data.data.communication.id);
          // $("#chat-body").animate({ scrollTop: $("#chat-body")[0].scrollHeight}, 1000);

          // $('#chat-body')[0].scrollTop = $('#chat-body')[0].scrollHeight;
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.postLoading = false;
        });
    }

    function createNewFormValiation() {
      state.item.error.erorListNewForm = [];
      state.item.hasError = false;
      console.log("data check", state.newFormData.title);
      console.log("data Array List", state.newFormData.elements);

      if (!state.newFormData.title) {
        state.item.error.erorListNewForm.push("Form name is required.");
      }
      if (!state.newFormData.privacy.value) {
        state.item.error.erorListNewForm.push("Please select the privacy.");
      }
      if (
        !state.newFormData.elements ||
        state.newFormData.elements.length === 0
      ) {
        state.item.error.erorListNewForm.push(
          "Please select At least 1 element from the left side Elements."
        );
      }

      if (
        !state.item.error.erorListNewForm &&
        !state.item.error.erorListNewForm.length
      ) {
        state.item.hasError = false;
      } else if (state.item.error.erorListNewForm.length != 0) {
        state.item.hasError = true;
      }
      console.log("Error List", state.item.error.erorListNewForm);
      console.log("Error Has error", state.item.hasError);
    }
    function showUserModal() {
      // console.log("Hello");
      state.invite.users = [];
      state.ui.template.msg = "";
      state.item.error.erorListSendForm = [];
      state.item.hasError = false;
      createNewFormValiation();
      if (
        state.item.error.erorListNewForm &&
        state.item.error.erorListNewForm.length &&
        state.item.hasError &&
        state.newFormData.privacy.value.length
      )
        return false;
      getUserList();
      // if (
      //   (state.item.error.erorListNewForm &&
      //   state.item.error.erorListNewForm.length != 0) &&
      //   state.item.hasError &&
      //   !state.newFormData.privacy
      // )
      //   return false
      $("#senduserdModal").modal("show");
    }
    function showNewFormConformation() {
      createNewFormValiation();
      if (
        state.item.error.erorListNewForm &&
        state.item.error.erorListNewForm.length &&
        state.item.hasError
      )
        return false;
      $("#confirmNewForm").modal("show");
    }
    function hideNewFormConformation() {
      $("#confirmNewForm").modal("hide");
    }
    function showNewTitleInCreateFormEdit(data: any) {
      // editSelectedElementOfCreateNewForm
      console.log("Data Selected >>", data);
      state.selectedElement.type = data;
      // console.log("Title Data Check: >>", state.selectedElement.type);
      // console.log("edit Title:>>", state.selectedFormForEdit.title);
      // console.log("edit Description:>>", state.selectedFormForEdit.description);
      state.selectedElement.title = state.newFormData.title;
      state.selectedElement.description = state.newFormData.description;
      state.item.error.erorList = [];
      state.item.hasError = false;
      $("#editSelectedElementOfCreateNewForm").modal("show");
    }
    function showNewElementEdit(data: any, type: any) {
      console.log("EDIT SECTION CHECK >>> ", data);
      const formIndex = common.findIndex(state.newFormData.elements, data);
      console.log("Selected Form Index", formIndex);
      state.selectedElement.element = data;
      state.selectedElement.type = type;
      state.selectedElement.index = common.findIndex(
        state.newFormData.elements,
        data
      );
      state.selectedElement.title = data.title;
      state.selectedElement.description = data.description;
      state.item.error.erorList = [];
      state.item.hasError = false;
      $("#editSelectedElementOfCreateNewForm").modal("show");
    }
    function hideEditCreateNewForm() {
      $("#editSelectedElementOfCreateNewForm").modal("hide");
    }
    function updateEditNewForm() {
      // alert()
      console.log("IN");
      console.log("Check:>>>", state.selectedElement.type);
      if (state.selectedElement.type === "title") {
        updateEditFormElementsValidation("title");
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        // console.log("<<< Title Change >>>");
        state.newFormData.title = state.selectedElement.title;
        state.newFormData.description = state.selectedElement.description;
      } else if (
        state.selectedElement.type === "textbox" ||
        state.selectedElement.type === "section"
      ) {
        updateEditFormElementsValidation(state.selectedElement.type);
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        // state.selectedFormForEdit.title = state.selectedElement.title;
        state.newFormData.elements[state.selectedElement.index].title =
          state.selectedElement.title;
        state.newFormData.elements[state.selectedElement.index].description =
          state.selectedElement.description;
        // console.log("<<< Title Change >>>");
      } else if (state.selectedElement.type === "textArea") {
        updateEditFormElementsValidation(state.selectedElement.type);
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        // state.selectedFormForEdit.title = state.selectedElement.title;
        // console.log("<<< Title Change >>>");
        state.newFormData.elements[state.selectedElement.index].title =
          state.selectedElement.title;
      } else if (
        state.selectedElement.type === "radioButton" ||
        state.selectedElement.type === "single_select" ||
        state.selectedElement.type === "checkBox"
      ) {
        updateEditFormElementsValidation(state.selectedElement.type);
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        // console.log("<<< Title Change >>>");

        state.newFormData.elements[state.selectedElement.index] =
          state.selectedElement.element;
      }
      state.item.error.erorList = [];
      state.item.hasError = false;
      $("#editSelectedElementOfCreateNewForm").modal("hide");
    }
    function showNewElementDelete(data: any, type: any) {
      $("#deleteSelectedElementInCreateNewForm").modal("show");
      state.selectedElement.element = data;
      state.selectedElement.type = type;
    }
    function deleteSelectedElementOfCreateForm() {
      state.newFormData.elements = state.newFormData.elements.filter(
        (item: any) => item != state.selectedElement.element
      );
      $("#deleteSelectedElementInCreateNewForm").modal("hide");
    }
    function hideDeleteSelectedElementInCreateNew() {
      $("#deleteSelectedElementInCreateNewForm").modal("hide");
    }
    function createNewFormEmailValiation() {
      console.log("ALL  IN");
      state.item.error.erorListSendForm = [];
      state.item.hasError = false;

      console.log("EMAIL CHECK", state.invite.users);

      if (state.invite.users && !state.invite.users.length) {
        state.item.error.erorListSendForm.push("Atleast 1 Email is required.");
      }

      if (
        !state.item.error.erorListSendForm &&
        !state.item.error.erorListSendForm.length
      ) {
        state.item.hasError = false;
      } else if (state.item.error.erorListSendForm.length != 0) {
        state.item.hasError = true;
      }
      console.log("Error List", state.item.error.erorListSendForm);
      console.log("Error Has error", state.item.hasError);
    }

    function createNewForm() {
      // erorListSendForm
      // console.log("owner Check check:>>>", state.owner)
      state.item.error.erorListNewForm = [];
      state.item.error.erorListSendForm = [];
      state.item.hasError = false;

      // console.log("Before");
      // createNewFormEmailValiation();

      if (state.ui.isPostLoading.createNewForm) return false;
      console.log("After");
      const usersArray = [] as any;
      state.invite.users.forEach((item: any) => {
        usersArray.push(item);
      });
      const payLoad = {
        title: state.newFormData.title,
        description: state.newFormData.description,
        elements: state.newFormData.elements,
        sentToUsers: usersArray,
        privacy: {
          code: state.newFormData.privacy.code,
          name: state.newFormData.privacy.value,
        },
        owner: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          created_by: {
            name: state.owner.created_by.full_name,
            email: state.owner.created_by.email,
          },
          // eslint-disable-next-line @typescript-eslint/camelcase
          project_id: state.projectId,
          // eslint-disable-next-line @typescript-eslint/camelcase
          channel_id: channelId,
        },
      };
      console.log("New Form PAYLOAD CHECK ", payLoad);
      state.ui.isPostLoading.createNewForm = true;
      ethitransService
        .accountformSave(payLoad)
        .then((res: any) => {
          console.log("createNewForm Response Check:>>> ", res.data);
          state.ui.template.response = res.data;
          if (state.formList.docs && state.formList.docs.length) {
            state.formList.docs.unshift(res.data);
          }
          $("#senduserdModal").modal("hide");
          $("#innerchannelModal").modal("hide");
          if (state.ui.template.msg && usersArray && usersArray.length) {
            formMessage(state.ui.template.msg);
            $("#templateupload").modal("hide");
          }
          hideNewFormConformation();
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.createNewForm = false;
        });
    }
    function sendCreateNewForm() {
      createNewFormEmailValiation();
      if (state.item.error.erorListSendForm.length != 0 || state.item.hasError)
        return false;
      createNewForm();
    }
    function sendTemplateFile() {
      createNewFormEmailValiation();
      if (
        state.ui.isPostLoading.createFormTemplate ||
        state.item.error.erorListSendForm.length != 0 ||
        state.item.hasError
      )
        return false;
      const usersArray = [] as any;
      state.invite.users.forEach((item: any) => {
        usersArray.push(item);
      });

      const payLoad = {
        title: state.formData.title,
        description: state.formData.description,
        message: state.ui.template.msg,
        // eslint-disable-next-line @typescript-eslint/camelcase
        elements: state.formData.elements,
        sentToUsers: usersArray,
        privacy: {
          code: state.newFormData.privacy.code,
          name: state.newFormData.privacy.value,
        },
        owner: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          created_by: {
            name: state.owner.created_by.full_name,
            email: state.owner.created_by.email,
          },
          // eslint-disable-next-line @typescript-eslint/camelcase
          project_id: state.projectId,
          // eslint-disable-next-line @typescript-eslint/camelcase
          channel_id: channelId,
        },
      };

      // const payLoad = {
      //   title: state.formData.title,
      //   description: state.formData.description,
      //   message: state.ui.template.msg,
      //   // eslint-disable-next-line @typescript-eslint/camelcase
      //   elements: state.formData.elements,
      //   owner: state.owner,
      //   privacy: {
      //     code: "channel",
      //     name: state.newFormData.privacy,
      //   },
      //   sentToUsers: usersArray,
      //   // eslint-disable-next-line @typescript-eslint/camelcase
      //   master_form_id: state.formData.master_form_id,
      // };
      // console.log("PAYLOAD CHECK ", payLoad);
      state.ui.isPostLoading.createFormTemplate = true;
      ethitransService
        .accountformSave(payLoad)
        .then((res: any) => {
          console.log("UserList Response Check:>>> ", res.data);
          state.ui.template.response = res.data;
          if (state.formList.docs && state.formList.docs.length) {
            state.formList.docs.unshift(res.data);
          }
          $("#mentionuser").modal("hide");
          $("#exampleModal").modal("hide");
          $("#editExampleModal").modal("hide");
          // channelCommunicationForm(res.data, state.ui.template.msg)
          // channelCommunicationForm(state.ui.template.msg)
          if (state.ui.template.msg || (usersArray && usersArray.length)) {
            formMessage(state.ui.template.msg);
            $("#templateupload").modal("hide");
          }
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.createFormTemplate = false;
        });
    }
    function editNewFormValiation() {
      state.item.error.erorListSaveCustomSelectedForm = [];
      state.item.hasError = false;
      console.log("data check", state.newFormData.title);
      console.log("data Array List", state.selectedFormForEdit.elements);
      if (!state.selectedFormForEdit.title) {
        state.item.error.erorListSaveCustomSelectedForm.push(
          "Form name is required."
        );
      }
      if (!state.newFormData.privacy.value) {
        state.item.error.erorListSaveCustomSelectedForm.push(
          "Please select the privacy."
        );
      }
      if (
        !state.selectedFormForEdit.elements &&
        state.selectedFormForEdit.elements.length === 0
      ) {
        state.item.error.erorListSaveCustomSelectedForm.push(
          "Please select At least 1 element from the left side Elements."
        );
      }

      if (
        !state.item.error.erorListSaveCustomSelectedForm &&
        !state.item.error.erorListSaveCustomSelectedForm.length
      ) {
        state.item.hasError = false;
      } else if (state.item.error.erorListSaveCustomSelectedForm.length != 0) {
        state.item.hasError = true;
      }
      console.log(
        "Error List",
        state.item.error.erorListSaveCustomSelectedForm
      );
      console.log("Error Has error", state.item.hasError);
    }
    function showCustomFormConformation() {
      editNewFormValiation();
      if (
        (state.item.error.erorListSaveCustomSelectedForm &&
          state.item.error.erorListSaveCustomSelectedForm.length) ||
        state.item.hasError ||
        !state.newFormData.privacy.value
      )
        return false;
      $("#confirmEditForm").modal("show");
    }
    function hideConfirmEditForm() {
      $("#confirmEditForm").modal("hide");
    }
    function saveCustomSelectedForm() {
      // console.log("Save the custom Edit form 1", state.selectedFormForEdit);
      // console.log("Save the custom Edit form", state.selectedFormForEdit._id);
      // editNewFormValiation();
      if (state.ui.isPostLoading.createFormTemplate) return false;
      // console.log("saveCustomSelectedForm CHECK");
      const usersArray = [] as any;
      state.invite.users.forEach((item: any) => {
        usersArray.push(item);
      });
      const payLoad = {
        title: state.selectedFormForEdit.title,
        description: state.selectedFormForEdit.description,
        message: state.ui.template.msg,
        // eslint-disable-next-line @typescript-eslint/camelcase
        elements: state.selectedFormForEdit.elements,
        sentToUsers: usersArray,
        privacy: {
          code: state.newFormData.privacy.code,
          name: state.newFormData.privacy.value,
        },
        owner: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          created_by: {
            name: state.owner.created_by.full_name,
            email: state.owner.created_by.email,
          },
          // eslint-disable-next-line @typescript-eslint/camelcase
          project_id: state.projectId,
          // eslint-disable-next-line @typescript-eslint/camelcase
          channel_id: channelId,
        },
      };
      console.log("PAYLOAD CHECK ", payLoad);
      state.ui.isPostLoading.createFormTemplate = true;
      ethitransService
        .accountformSave(payLoad)
        .then((res: any) => {
          console.log("saveCustomSelectedForm Response Check:>>> ", res.data);
          state.ui.template.response = res.data;
          if (state.formList.docs && state.formList.docs.length) {
            state.formList.docs.unshift(res.data);
          }
          // state.ui.template.response = res.data;
          $("#sendformModal").modal("hide");
          $("#createnewformModal").modal("hide");
          if (state.ui.template.msg && usersArray && usersArray.length) {
            formMessage(state.ui.template.msg);
            $("#templateupload").modal("hide");
          }
          hideConfirmEditForm();
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.createFormTemplate = false;
        });
    }
    function emailValidationCustomEdit() {
      createNewFormEmailValiation();
      if (
        state.ui.isPostLoading.createFormTemplate ||
        state.item.error.erorListSendForm.length != 0 ||
        state.item.hasError
      )
        return false;
      saveCustomSelectedForm();
    }
    function showSendTemplate() {
      // console.log("Show send Template");
      $("#templateupload").modal("show");
      getChannelFormList();
    }
    function showSendFormModal() {
      // console.log("Show send Template");
      getUserList();
      state.invite.users = [];
      state.ui.template.msg = "";
      state.item.error.erorListSendForm = [];
      state.item.hasError = false;
      $("#sendformModal").modal("show");
    }
    onMounted(() => {
      // console.log("CHECK THE Query", this.$route.query.page);
      // console.log("CHECK Channel ID", channelId)
      state.email = localStorage.getItem("current_user_email");
      getCommunicationList();
      scrollAtButton()
    });
    return {
      state,
      scrollAtButton,
      redirectToDynamicForm,
      formPrivacyMessage,
      emailValidationCustomEdit,
      updateEditFormElementsValidation,
      sendCreateNewForm,
      radioChange,
      createNewFormEmailValiation,
      addElementsCreateNewValiation,
      showSendFormModal,
      deleteSelectedElementOfCreateForm,
      hideDeleteSelectedElementInCreateNew,
      showNewElementEdit,
      showNewElementDelete,
      hideConfirmEditForm,
      hideNewFormConformation,
      showNewFormConformation,
      showCustomFormConformation,
      selectedForm,
      showDeleteElement,
      cancelDeleteSelectedForm,
      confirmDeleteElement,
      getAccountFormRetrieveByIdForSend,
      editNewFormValiation,
      cancelEditSelectedForm,
      editSelectedForm,
      saveCustomSelectedForm,
      addTextFieldInCustomEdit,
      addOptionsinCustomEdit,
      addSelectOptionsinCustomEdit,
      addCheckBoxinCustomEdit,
      removeCheckBoxinCustomEdit,
      removeSelectOptioninCustomEdit,
      removeOptionsinCustomEdit,
      showEditFormName,
      showEditElement,
      showUserModal,
      showSendTemplate,
      sendTemplateFile,
      getAccountFormRetrieveById,
      selectForm,
      getCommunicationList,
      getChannelFormList,
      createCommunicationList,
      uploadAttachmentByChannel,
      onDocumentChange,
      addNewMemberToChannel,
      downloadFiles,
      closeChannel,
      readImage,
      tempImageList,
      removeSelectedImage,
      hideAddMember,
      removeSelectedMember,
      changeExistingMember,
      changeInviteMember,
      removeSelectedUser,
      inviteAllUsers,
      showSuccessModal,
      validateInviteForm,
      scrollToLast,
      isUserStatus,
      templateList,
      hideEditTemplateModal,
      hideTemplateModal,
      selectEditForm,
      channelCommunicationForm,
      changeExistingMemberInForm,
      showFormView,
      hideFormView,
      showTextFieldAdd,
      addTextField,
      addSelectOptions,
      removeCheckBox,
      removeSelectOption,
      removeOptions,
      addOptions,
      addCheckBox,
      createNewForm,
      showEditTemplateModal,
      showMentionUser,
      titleSectionValiation,
      createNewFormValiation,
      selectedElement,
      showCustomEdit,
      showCreateNewForm,
      showNewTitleInCreateFormEdit,
      hideEditCreateNewForm,
      updateEditNewForm,

      // onDocumentChange,
    };
  },
});
