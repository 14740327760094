
import { defineComponent, onMounted, reactive, computed } from "vue";
import { ethitransService } from "@/services/EthitransService";
import { useRouter } from "vue-router";
import EmptyData from "@/components/commonDesign/EmptyData.vue";
import YouTube from 'vue3-youtube'

export default defineComponent({
  name: "Video",
  components: {
    EmptyData,
    YouTube
  },

  setup() {
    const router = useRouter();
    const state = reactive({
      videoList: [] as any,
      data: {
        imageBaseUrl: "",
        title: "Video",
        heading: "No Video found .",
        selectVideo: {} as any,
      },
      ui: {
        isLoading: {},
        isPostLoading: {
          createTutorial: false,
          saveEditUser: false,
        },
        error: {
          validationList: [] as any,
          hasError: false,
        },
      },

      newSearchData: {
        emailError: false,
      },
      error: {
        loginFromError: "" as any,
        loginToError: "" as any,
        createdFromError: "" as any,
        createdToError: "" as any,
      },
    });

    function getFaq() {
      // state.ui.isLoading.clientDocumentList = true;
      const type = 'video';
      ethitransService
        .getTutorial(type)
        .then((res: any) => {
          state.videoList = res.data.data;
          console.log('res', res)
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          // state.ui.isLoading.clientDocumentList = false;
        });
    }

    function openVideoModel(data: any) {
      state.data.selectVideo = data
      $("#videoModal").modal("show");
    }
    function closeVidoeModel() {
      $("#videoModal").modal("hide");
    }

    onMounted(() => {
      getFaq();
    });

    return {
      state,
      getFaq,
      openVideoModel,
      closeVidoeModel

    };
  },
});
