
import { defineComponent, reactive, onMounted, ref, computed } from "vue";
import { useRoute } from "vue-router";
// import ErrorList from "@/components/commonDesign/ErrorList.vue";

import { ethitransService } from "@/services/EthitransService";
import { validationService } from "@/common/ValidationService";
import { common } from "@/common/services/Common";

// import { Data } from "@/components/todo/AddMember.ts";

export default defineComponent({
  name: "UpdateTaskFromAddMember",
  props: {
    isActive: {
      type: Boolean,
    },
    selectedTask: {
      type: Object,
    },
    minDate: {
      type: String,
    },
    maxDate: {
      type: String,
    },
  },
  components: {
    // ErrorList,
  },
  setup(props, { emit }) {
    const {
      params: { id },
    } = useRoute();
    
    const state = reactive({
      data: {
        updates: {
          uuid: "",
          // eslint-disable-next-line @typescript-eslint/camelcase
          edit_budget: "",
          // eslint-disable-next-line @typescript-eslint/camelcase
          priority_id: "",
          // eslint-disable-next-line @typescript-eslint/camelcase
          edit_start_date: "" as any,
          // eslint-disable-next-line @typescript-eslint/camelcase
          edit_end_date: "" as any,
        } as any,
      },
      ui: {
        error: {
          hasError: false,
          errorList: {
            updateSelectedTask: [] as any,
          },
        },
        isLoading: {
          updateSelectedTask: false,
        },
        isPostLoading: {
          createPhase: false,
        },
      },
    });

    // validation
    const updateStatus = computed(() => {
      const errorList = [];
      if(!state.data.updates.edit_start_date){
        errorList.push('Start Date Missing');
      }
      if(!state.data.updates.edit_end_date){
        errorList.push('End Date Missing');
      }
      if(!state.data.updates.priority_id){
        errorList.push('Priority Missing');
      }
      if(errorList.length) return true;
      return false;
    });
    // const disabledDates = ref([
    //   {
    //     month: {
    //       october: [2, 3],
    //     },
    //   },
    // ]);
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    const attributes = ref([
      // {
      //   key: "today",
      //   highlight: {
      //     color: "purple",
      //     fillMode: "solid",
      //     contentClass: "italic",
      //   },
      //   dates: new Date(year, month, 12),
      // },
      // {
      //   highlight: {
      //     color: "purple",
      //     fillMode: "light",
      //   },
      //   dates: new Date(year, month, 13),
      // },
      {
        dot: true,
        highlight: {
          color: "purple",
          fillMode: "outline",
        },
        dates: new Date(year, month, 14),
      },
    ]);
    function hideCheckAvaibilityModal() {
      $("#edit-phase-Modal").modal("hide");
      emit("hideUpdateTaskModal");
    }
    function setUpdatedTask(data: any) {
      emit("setUpdatedTask", data);
    }
    function updateSelectedTask() {
      state.ui.error.errorList.updateSelectedTask = [];
      state.ui.error.hasError = false;
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.data.updates.start_date = state.data.updates.edit_start_date;
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.data.updates.end_date = state.data.updates.edit_end_date;
      // if(common.isSlashDateFormat(state.data.updates.edit_end_date)){
        console.log("CHECK >> ", common.isSlashDateFormat(state.data.updates.edit_end_date))
      // }
      const payLoad = {
        uuid: state.data.updates.uuid,
        budget: state.data.updates.budget,
        // eslint-disable-next-line @typescript-eslint/camelcase
        priority_id: state.data.updates.priority_id,
        // eslint-disable-next-line @typescript-eslint/camelcase
        start_date: state.data.updates.edit_start_date,
        // eslint-disable-next-line @typescript-eslint/camelcase
        end_date: state.data.updates.edit_end_date,
      };
      console.log("CHECK updateSelectedTask PAYLOAD >> ", payLoad);

      if (state.ui.isLoading.updateSelectedTask) return false;
      state.ui.isLoading.updateSelectedTask = true;
      ethitransService
        .updateTask(payLoad)
        .then((res: any) => {
          console.log("updateSelectedTask Check:>>> ", res.data.data);
          hideCheckAvaibilityModal();
          setUpdatedTask(payLoad);
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.updateSelectedTask)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.updateSelectedTask.push(value[0]);
                }
              }
            });
        });
    }
   

    onMounted(() => {
      if (props.isActive) {
        $("#edit-phase-Modal").modal("show");
        if(!props.selectedTask) return false;
        state.data.updates = JSON.parse(
              JSON.stringify(props.selectedTask)
            );
      }
    });

    return {
      state,
      updateStatus,
      setUpdatedTask,
      updateSelectedTask,
      hideCheckAvaibilityModal,
      // disabledDates,
      attributes,
    };
  },
});
