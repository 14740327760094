
import { defineComponent, onMounted, reactive, computed } from "vue";
import { ethitransService } from "@/services/EthitransService";
import { useRouter } from "vue-router";
import EmptyData from "@/components/commonDesign/EmptyData.vue";

export default defineComponent({
  name: "Faq",
  components: {
    EmptyData
  },

  setup() {
    const router = useRouter();
    const state = reactive({
      faqList: [] as any,
      data: {
        imageBaseUrl: "",
        title: "Faq",
        heading: "No faq found .",
        searchUser: "",
      },
      ui: {
        isLoading: {},
        isPostLoading: {
          createTutorial: false,
          saveEditUser: false,
        },
        error: {
          validationList: [] as any,
          hasError: false,
        },
      },

      newSearchData: {
        emailError: false,
      },
      error: {
        loginFromError: "" as any,
        loginToError: "" as any,
        createdFromError: "" as any,
        createdToError: "" as any,
      },
    });

    function getFaq() {
      // state.ui.isLoading.clientDocumentList = true;
      const type = 'faq';
      ethitransService
        .getTutorial(type)
        .then((res: any) => {
          state.faqList = res.data.data;
          console.log('res', res)
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          // state.ui.isLoading.clientDocumentList = false;
        });
    }
    const splitFaq = computed(() => {
      const midpoint = Math.ceil(state.faqList.length / 2);
      const firstHalf = state.faqList.slice(0, midpoint);
      const secondHalf = state.faqList.slice(midpoint);
      return { firstHalf, secondHalf };
    });
    onMounted(() => {
      getFaq();
    });

    return {
      state,
      getFaq,
      splitFaq

    };
  },
});
