
import { defineComponent, reactive, onMounted, ref, watch, PropType } from 'vue';
import { useRoute, useRouter } from 'vue-router';
// import ToDoListBreadCrumb from '@/components/todo/ToDoListBreadCrumb.vue';
// import ToDoListPhaseBlock from '@/components/todo/ToDoListPhaseBlock.vue';
// import EmptyData from '@/components/commonDesign/EmptyData.vue';
// import ErrorList from '@/components/commonDesign/ErrorList.vue';
import { config } from '@/Config';

import { ethitransService } from '@/services/EthitransService';
import { common } from '@/common/services/Common';
// import ToDoListAddPhaseModal from '@/components/todo/ToDoListAddPhaseModal.vue';
// import SuccessfulModal from '@/components/modal/SuccessfulModal.vue';

// import AppGoBack from '@/components/commonDesign/AppGoBack.vue';

import Pusher from 'pusher-js';

interface UserData {
  email: string;
  full_name: string;
  id: number;
  image: string | null;
  status: number;
}

export default defineComponent({
  name: 'ToDoListUserPermissionDetail',
  components: {
    // ErrorList,
    // EmptyData,
    // ToDoListBreadCrumb,
    // ToDoListPhaseBlock,
    // ToDoListAddPhaseModal,
    // SuccessfulModal,
    // AppGoBack,
  },
  props: {
    isShowModal: Boolean,
    activeUuid: String,
    activeMember: {
      type: Object as PropType<UserData>,
      required: true,
    }
  },
  setup(props, { emit }) {
    const router = useRouter();
    const {
      params: { projectId },
    } = useRoute();
    const state = reactive({
      data: {
        userDetails: {} as any,
        taskDetails: {} as any,
        userInput: true,
        isChecked: true,
        roles: [] as any,
        amountTypes: [] as any,
      },
      ui: {
        error: {
          hasError: false,
          errorList: {
            getMemberPhaseDetails: [] as any,
          },
        },
        isLoading: {
          showShareEstimation: false,
        },
        isPostLoading: {
          getMemberPhaseDetails: false,
        },
      },
    });
    function closeModal() {
      emit("closeModal");
      $("#userPermissionDetailsModal").modal("hide");
    }
    function getMemberPhaseDetails() {
      state.ui.error.errorList.getMemberPhaseDetails = [];
      state.ui.error.hasError = false;

      if (state.ui.isPostLoading.getMemberPhaseDetails) return false;
      state.ui.isPostLoading.getMemberPhaseDetails = true;
      const payLoad = {
        id: props.activeUuid,
        email: props.activeMember.email,
      }
      ethitransService
        .memberPhaseDetails(payLoad)
        .then((res: any) => {
          console.log("Project Todo Response Check:>>> ", res.data.data);
          state.data.userDetails = res.data.data.member;
          state.data.taskDetails = res.data.data.phase;
          // roles

          state.data.roles = res.data.data.roles;
          state.data.amountTypes = res.data.data.amount_depends;
          state.ui.isPostLoading.getMemberPhaseDetails = false;
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.getMemberPhaseDetails)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.getMemberPhaseDetails.push(value[0]);
                }
              }
            });
        })
    }
    onMounted(() => {
      console.log("Check the page")
      if (props.isShowModal) {
        getMemberPhaseDetails();
        $("#userPermissionDetailsModal").modal("show");
      }
    });

    return {
      state,
      closeModal,
      getMemberPhaseDetails,
    };
  },
});
