
import { computed, defineComponent, reactive, onMounted } from "vue";
import { ethitransService } from "@/services/EthitransService";
import { validationService } from "@/common/ValidationService";
import ErrorList from "@/components/commonDesign/ErrorList.vue";
import { passwordStrength } from "check-password-strength";

import { useRouter, useRoute } from "vue-router";
import { common } from "@/common/services/Common";
import { config } from "@/Config";
import SuccessfulModal from "@/components/modal/SuccessfulModal.vue";

import { Details } from "@/login/interfaces/Details"

export default defineComponent({
  name: "SignUpOTP",
  components: {
    ErrorList,
    SuccessfulModal,
  },
  setup() {
    const getRouter = useRoute();
    const router = useRouter();
    const state = reactive({
      data: {
        registrationDetails: {} as Details,
        selectedSubscriptionId: 0,
        showSuccesModal: false,
        successFulMessage: "",
        phone: {
          countryCode: "",
          collection: [] as any,
          number: "",
          code: "+1",
          mobileCode: "+1",
          otp: "" as any,
          options: [] as any,
          isVerify: false,
        },
        imageBaseUrl: "",
        registration: {
          signByInput: "",
          firstName: "",
          middleName: "",
          lastName: "",
          email: "" as any,
          password: "",
          passwordConfirmation: "",
          mobile: "",
          phone: "",
          notification: {
            sms: false,
            email: false,
          },
        },
        success: false,
        passwordFieldType: "password",
        passwordFieldTypeConfirm: "password",
        passwordClass: "fas fa-eye-slash",
        passwordClassConfirm: "fas fa-eye-slash",
        timeCount: 130,
      },
      ui: {
        error: {
          validationList: [] as any,
          registerReplaceList: [] as any,
          hasError: false,
        },
        isLoading: {
          register: false,
        },
        postLoading: {
          register: false,
          replaceRegister: false,
        },
        inputOtp: false,
        sendOtpButton: true,
        verifyButton: true,
      },
      countryCodeList: [] as any,
      errorList: [] as any,
      isErrorStatus: false,
    });

    const isEmail = computed(() => {
      const input = state.data.registration.signByInput;

      // Check if the input is a valid email
      if (validationService.isValidEmail(input)) {
        return true; // It is a valid email
      }

      // Check if the input is a valid mobile number
      if (validationService.validateMobileNumber(input)) {
        return false; // It is a valid mobile number
      }

      return false;

    });

    const passwordStrengthCheck: any = computed(() => {
      if (state.data.registration.password) {
        return passwordStrength(`${state.data.registration.password}`).value;
      } else {
        return "";
      }
    });
    const passwordConfirmationStrengthCheck: any = computed(() => {
      if (state.data.registration.passwordConfirmation) {
        return passwordStrength(
          `${state.data.registration.passwordConfirmation}`
        ).value;
      } else {
        return "";
      }
    });
    const errorList = computed(() => {
      return state.ui.error.validationList;
    });
    const passwordStatus = computed(() => {
      return state.data.passwordClass;
    });
    function insertContryCode() {
      console.log("insert");
      if (!state.data.registration.mobile) {
        state.data.registration.mobile = `+1${state.data.registration.mobile}`;
      }
    }
    function onInput(event: any) {
      // console.log("EVENT DATA >>>> ", event.data)
      // if(!state.data.editUserDetails.contactNumber){
      if (event.data != undefined) {
        state.data.phone.collection.push(event.data);
      }
      if (event.data === null) {
        if (state.data.phone.collection) {
          state.data.phone.collection.pop();
        }
      }
      // console.log("CHECK ARRAY >>> ", state.data.phone.collection)
      state.data.registration.mobile = state.data.phone.collection.toString();
      state.data.registration.mobile = state.data.registration.mobile.replace(
        /,/g,
        ""
      );
      // console.log("CHECK NUMBER STRING >>> ", state.data.registration.mobile )
      // }

      // console.log("INPUT ARRAY", state.data.phone.collection)
      // let string ="";
      // string += event.data
      // if(state.data.editUserDetails.contactNumber){

      //   // console.log("FINAL UNEDIT", state.data.editUserDetails.contactNumber.replace(/null/g, ''));
      //   state.data.phone.number =  state.data.editUserDetails.contactNumber.replace(/null/g, '')
      //   if(state.data.phone.number && event.data === null ){
      //     // state.data.phone.number.slice(0, -1)
      //     state.data.phone.number.substring(0, state.data.phone.number.length - 1)
      //   }
      // }
      // console.log("FINAL NUMBER CHECK", state.data.phone.number);
    }
    function countryChanged(country: any) {
      // console.log("Country the input", country)
      if (country.dialCode) {
        // console.log("Country the input", country.dialCode)
        state.data.phone.countryCode = country.dialCode;
        // console.log("Country FINAL CHECK >>>", state.data.phone.countryCode )
      }
    }
    function hideReplaceRegister() {
      state.ui.error.registerReplaceList = [];
      state.ui.error.hasError = true;
      $("#replaceRegister").modal("hide");
    }
    function validatePhoneNumber(phone: string) {
      // link https://regex101.com/r/QXAhGV/1
      // return /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/.test(phone);
      // return /^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/.test(phone);
      return /(^\+[0-9]{2}|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9\-\s]{11}$)/.test(
        phone
      );
      // return /(^\+[2-9][0-9][0-9]|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9\-\s]{11}$)/.test(phone);
    }
    function switchVisibility() {
      // alert()
      state.data.passwordFieldType =
        state.data.passwordFieldType === "password" ? "text" : "password";
      if (state.data.passwordClass === "fas fa-eye") {
        state.data.passwordClass = "fas fa-eye-slash";
      } else {
        state.data.passwordClass = "fas fa-eye";
      }
      $("#password").toggleClass("fas fa-eye-slash").toggleClass("fas fa-eye");
    }
    function switchVisibilityConfirm() {
      // alert()
      state.data.passwordFieldTypeConfirm =
        state.data.passwordFieldTypeConfirm === "password"
          ? "text"
          : "password";
      if (state.data.passwordClass === "fas fa-eye-slash") {
        state.data.passwordClass = "fas fa-eye";
      } else {
        state.data.passwordClass = "fas fa-eye-slash";
      }
      $("#password").toggleClass("fas fa-eye-slash").toggleClass("fas fa-eye");
    }
    function validateRegistration() {
      state.ui.error.validationList = [];
      state.ui.error.hasError = false;

      if (!state.data.registration.firstName) {
        state.ui.error.validationList.push("First name is required.");
      }
      //  if (!state.data.phone.isVerify) {
      //   state.ui.error.validationList.push("Please verify your mobile number.");
      // }
      if (!state.data.registration.lastName) {
        state.ui.error.validationList.push("Last name is required.");
      }
      if (!state.data.registration.email) {
        state.ui.error.validationList.push("Email address is required.");
      } else if (
        !validationService.isValidEmail(common.lowerCase(state.data.registration.email))
      ) {
        state.ui.error.validationList.push("Email address is not valid.");
      }
      if (!state.data.registration.phone) {
        state.ui.error.validationList.push("Phone Number is required.");
      } else {
        if (!validatePhoneNumber(state.data.phone.code + '' + state.data.registration.phone)) {
          state.ui.error.validationList.push("Not Valid Number.");
        }
      }

      if (state.data.registration.notification.sms && !state.data.registration.phone) {
        state.ui.error.validationList.push("Mobile Number is required.");
      } else {
        if (state.data.registration.notification.sms && !validatePhoneNumber(state.data.phone.mobileCode + '' + state.data.registration.mobile)) {
          state.ui.error.validationList.push("Not Valid Number.");
        }
      }

      if (state.data.registration.notification.sms && !state.data.phone.isVerify) {
        state.ui.error.validationList.push("Please verify your mobile number.");
      }
      if (!state.data.registration.password) {
        state.ui.error.validationList.push("Password is required.");
      }
      if (!state.data.registration.passwordConfirmation) {
        state.ui.error.validationList.push(
          "Password Confirmation is required."
        );
      }
      if (
        state.data.registration.password &&
        state.data.registration.passwordConfirmation
      ) {
        if (
          state.data.registration.password !=
          state.data.registration.passwordConfirmation
        ) {
          state.ui.error.validationList.push(
            "Password & Password Confirmation doesnot match."
          );
        }
      }

      if (
        !state.ui.error.validationList &&
        !state.ui.error.validationList.length
      ) {
        state.ui.error.hasError = false;
      } else if (state.ui.error.validationList.length != 0) {
        state.ui.error.hasError = true;
      }
    }
    function redirectToLogIn() {
      if (getRouter.query.uuid && getRouter.query.type === "invitation") {
        router.push({
          name: "admin-login",
          query: { type: getRouter.query.type, uuid: getRouter.query.uuid, email: getRouter.query.email },
        });
      } else if (getRouter.query.ref) {
        router.push({
          name: "admin-login",
          query: { ref: getRouter.query.ref },
        });
      } else {
        router.push({
          name: "admin-login",
        });
      }
    }
    function closeSuccessModal() {
      state.data.showSuccesModal = !state.data.showSuccesModal;
      $("#successfulModal").modal("hide");
    }
    function hideRegisterSuccessModal() {
      $("#registerSuccessModal").modal("hide");
      if (state.data.success) {
        redirectToLogIn();
      }
    }

    function resetCheck() {
      state.data.registration.mobile = "";
      state.data.phone.isVerify = false;
      state.data.phone.otp = "";
    }

    function register() {
      // console.log(
      //   "Check the format",
      //   validatePhoneNumber(state.data.registration.mobile)
      // );
      validateRegistration();
      if (
        state.ui.error.validationList.length != 0 ||
        state.ui.postLoading.register ||
        state.ui.error.hasError
      )
        return false;
      let payLoad = {};
      if (getRouter.query.uuid && getRouter.query.type === "invitation") {
        payLoad = {
          // eslint-disable-next-line @typescript-eslint/camelcase
          invitation_id: getRouter.query.uuid,
          // eslint-disable-next-line @typescript-eslint/camelcase
          first_name: state.data.registration.firstName,
          // eslint-disable-next-line @typescript-eslint/camelcase
          middle_name: state.data.registration.middleName,
          // eslint-disable-next-line @typescript-eslint/camelcase
          last_name: state.data.registration.lastName,
          // eslint-disable-next-line @typescript-eslint/camelcase
          dial_code: state.data.phone.code,
          // eslint-disable-next-line @typescript-eslint/camelcase
          dial_code_mobile: state.data.phone.mobileCode,
          // eslint-disable-next-line @typescript-eslint/camelcase
          mobile_number: state.data.registration.mobile,
          // eslint-disable-next-line @typescript-eslint/camelcase
          is_mobile_verified: state.data.phone.isVerify,
          // contact_number: `+${state.data.phone.countryCode}${state.data.registration.mobile}`,
          // eslint-disable-next-line @typescript-eslint/camelcase
          contact_number: state.data.registration.phone,
          email: state.data.registration.email,
          password: state.data.registration.password,
          // eslint-disable-next-line @typescript-eslint/camelcase
          password_confirmation: state.data.registration.passwordConfirmation,
          // eslint-disable-next-line @typescript-eslint/camelcase
          sms_notification: state.data.registration.notification.sms ? 1 : 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          email_notification: state.data.registration.notification.email
            ? 1
            : 0,
          ref: getRouter.query.ref ? getRouter.query.ref : '',
          // eslint-disable-next-line @typescript-eslint/camelcase
          package_id: state.data.selectedSubscriptionId,
        };
      } else {
        payLoad = {
          // eslint-disable-next-line @typescript-eslint/camelcase
          first_name: state.data.registration.firstName,
          // eslint-disable-next-line @typescript-eslint/camelcase
          middle_name: state.data.registration.middleName,
          // eslint-disable-next-line @typescript-eslint/camelcase
          last_name: state.data.registration.lastName,
          // eslint-disable-next-line @typescript-eslint/camelcase
          dial_code: state.data.phone.code,
          // eslint-disable-next-line @typescript-eslint/camelcase
          is_mobile_verified: state.data.phone.isVerify,
          // contact_number: `+${state.data.phone.countryCode}${state.data.registration.mobile}`,
          // eslint-disable-next-line @typescript-eslint/camelcase
          contact_number: state.data.registration.phone,
          // eslint-disable-next-line @typescript-eslint/camelcase
          dial_code_mobile: state.data.phone.mobileCode,
          // eslint-disable-next-line @typescript-eslint/camelcase
          mobile_number: state.data.registration.notification.sms ? state.data.registration.mobile : "",
          email: state.data.registration.email,
          password: state.data.registration.password,
          // eslint-disable-next-line @typescript-eslint/camelcase
          password_confirmation: state.data.registration.passwordConfirmation,
          // eslint-disable-next-line @typescript-eslint/camelcase
          sms_notification: state.data.registration.notification.sms ? 1 : 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          email_notification: state.data.registration.notification.email
            ? 1
            : 0,
          ref: getRouter.query.ref ? getRouter.query.ref : '',
          // eslint-disable-next-line @typescript-eslint/camelcase
          package_id: state.data.selectedSubscriptionId,
        };
      }
      console.log("PAYLOAD CHECK ", payLoad);
      state.ui.postLoading.register = true;
      ethitransService
        .registration(payLoad)
        .then((res: any) => {
          // console.log("Registration Response Check:>>> ", res.data.data);
          // state.userList = res.data.data ? res.data.data : [];
          state.data.success = true;
          state.data.registration.firstName = "";
          state.data.registration.middleName = "";
          state.data.registration.lastName = "";
          state.data.registration.phone = "";
          state.data.registration.mobile = "";
          state.data.registration.email = "";
          state.data.registration.password = "";
          state.data.registration.passwordConfirmation = "";
          state.data.registration.notification.sms = false;
          state.data.registration.notification.email = false;
          state.data.selectedSubscriptionId = 0;
          $("#registerSuccessModal").modal("show");
          // router.push({
          //   name: "admin-login",
          // });
        })
        .catch((error: any) => {
          if (error.response.status === 406) {
            $("#replaceRegister").modal("show");
            common
              .sdCatchErr(error, state.ui.error.registerReplaceList)
              .then((res: any) => {
                state.ui.error.hasError = true;
                for (const [key, value] of Object.entries(res)) {
                  if (Array.isArray(value)) {
                    state.ui.error.registerReplaceList.push(value[0]);
                  }
                }
              });
          }
          common
            .sdCatchErr(error, state.ui.error.validationList)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.validationList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.postLoading.register = false;
        });
    }
    function getCountryCodeList() {
      console.log("getprojectList WOWO:>>>");
      const payLoad = {};
      ethitransService
        .getCountryCodeList(payLoad)
        .then((res: any) => {
          console.log("getprojectList Check:>>> ", res.data.data);
          state.countryCodeList = res.data.data ? res.data.data : [];
          // state.selectedFormForEdit = res.data ? res.data : {};
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          // state.ui.postLoading.getprojectList = false;
        });
    }
    // registrationsDetailList
    function getRegistrationsDetailList(data: any) {
      console.log("getprojectList WOWO:>>>");
      ethitransService
        .registrationsDetailList(data)
        .then((res: any) => {
          console.log("getRegistrationsDetailList Check:>>> ", res.data.data);
          state.data.registrationDetails = res.data.data;
          if (res.data.data.refferal != undefined) {
            state.data.selectedSubscriptionId = res.data.data.refferal.package_id;
          }
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          // state.ui.postLoading.getprojectList = false;
        });
    }
    function countDownTimer() {
      const interval = setInterval(() => {
        if (state.data.timeCount === 0) {
          state.ui.sendOtpButton = true;
          state.ui.inputOtp = false;
          clearInterval(interval);
          state.data.timeCount = 130;
          // state.data.phone.code = state.data.phone.code;
          // getCountryCodeList();
        } else {
          state.data.timeCount--;
        }
      }, 1000);
    }

    function sendOtp() {
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        mobile_number: state.data.registration.mobile,
        // eslint-disable-next-line @typescript-eslint/camelcase
        dial_code_mobile: state.data.phone.mobileCode,
      };
      state.ui.verifyButton = false;

      ethitransService
        .sendOtp(payLoad)
        .then((res: any) => {
          console.log("meesaom", res.code);

          state.countryCodeList = res.data.data ? res.data.data : [];
          // state.data.phone.code = res.data.data.dial_code;
          state.ui.inputOtp = true;
          state.ui.sendOtpButton = false;
          getCountryCodeList();
          countDownTimer();
          state.ui.error.validationList = [];
          state.data.phone.otp = "";

          // interval;
        })
        .catch((error: any) => {
          console.log(error);
          common
            .sdCatchErr(error, state.ui.error.validationList)
            .then((res: any) => {
              state.ui.error.validationList = [];
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.validationList.push(value[0]);
                }
              }
            });
          // state.ui.error.hasError = true;
          // state.ui.error.validationList = [];
          // state.ui.error.validationList.push(
          //   "pleae check your mobile number or country code."
          // );
        })
        .finally(() => {
          state.ui.verifyButton = true;
        });
    }

    function verifyMobile() {
      state.ui.error.hasError = false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        mobile_number: state.data.registration.mobile,
        // eslint-disable-next-line @typescript-eslint/camelcase
        dial_code_mobile: state.data.phone.mobileCode,
        otp: state.data.phone.otp,
      };
      ethitransService
        .verifyMobile(payLoad)
        .then((res: any) => {
          state.countryCodeList = res.data.data ? res.data.data : [];
          // state.data.phone.mobileCode = state.data.phone.mobileCode;
          getCountryCodeList();
          if (res.data.data.is_verify == true) {
            state.data.phone.isVerify = true;
            state.ui.inputOtp = false;
            state.ui.error.hasError = false;
          } else {
            state.data.phone.isVerify = false;
            state.ui.error.hasError = true;
            state.ui.error.validationList = [];
            state.ui.error.validationList.push("OTP didnot match.");
          }
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          // state.ui.postLoading.getprojectList = false;
        });
    }

    function checkInputValue() {
      console.log("Checking input value", state.data.phone.otp.length);
      if (state.data.phone.otp.length == 5) {
        verifyMobile();
      }
    }

    function registerReplace() {
      state.ui.error.registerReplaceList = [];
      state.ui.error.hasError = true;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        invitation_id: getRouter.query.uuid,
        // eslint-disable-next-line @typescript-eslint/camelcase
        first_name: state.data.registration.firstName,
        // eslint-disable-next-line @typescript-eslint/camelcase
        middle_name: state.data.registration.middleName,
        // eslint-disable-next-line @typescript-eslint/camelcase
        last_name: state.data.registration.lastName,
        // eslint-disable-next-line @typescript-eslint/camelcase
        dial_code: state.data.phone.code,
        // eslint-disable-next-line @typescript-eslint/camelcase
        is_mobile_verified: state.data.phone.isVerify,
        // contact_number: `+${state.data.phone.countryCode}${state.data.registration.mobile}`,
        // eslint-disable-next-line @typescript-eslint/camelcase
        contact_number: state.data.registration.phone,
        // eslint-disable-next-line @typescript-eslint/camelcase
        dial_code_mobile: state.data.phone.mobileCode,
        // eslint-disable-next-line @typescript-eslint/camelcase
        mobile_number: state.data.registration.mobile,
        email: state.data.registration.email,
        password: state.data.registration.password,
        // eslint-disable-next-line @typescript-eslint/camelcase
        password_confirmation: state.data.registration.passwordConfirmation,
        // eslint-disable-next-line @typescript-eslint/camelcase
        sms_notification: state.data.registration.notification.sms ? 1 : 0,
        // eslint-disable-next-line @typescript-eslint/camelcase
        email_notification: state.data.registration.notification.email ? 1 : 0,
      };
      ethitransService
        .registerReplace(payLoad)
        .then((res: any) => {
          // console.log("Registration Response Check:>>> ", res.data.data);
          // state.userList = res.data.data ? res.data.data : [];
          state.data.success = true;
          state.data.registration.firstName = "";
          state.data.registration.middleName = "";
          state.data.registration.lastName = "";
          state.data.registration.phone = "";
          state.data.registration.mobile = "";
          state.data.registration.email = "";
          state.data.registration.password = "";
          state.data.registration.passwordConfirmation = "";
          state.data.registration.notification.sms = false;
          state.data.registration.notification.email = false;
          state.data.showSuccesModal = !state.data.showSuccesModal;
          state.data.successFulMessage = "Registation resend successfully.";
          hideReplaceRegister();
          state.ui.error.validationList = [];
          state.ui.error.hasError = false;
          // $("#registerSuccessModal").modal("show");
          // router.push({
          //   name: "admin-login",
          // });
        })
        .catch((error: any) => {
          if (error.response.status === 406) {
            $("#replaceRegister").modal("show");
          }
          common
            .sdCatchErr(error, state.ui.error.registerReplaceList)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.registerReplaceList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.postLoading.register = false;
        });
    }
    onMounted(() => {
      // state.data.registration.notification.email = true;
      // state.data.registration.notification.sms = true;
      state.data.imageBaseUrl = config.imageBaseUrl;
      state.data.registration.email = getRouter.query.email;
      getCountryCodeList();

      if (getRouter.query.ref) {
        getRegistrationsDetailList({ 'ref': getRouter.query.ref });
      } else {
        getRegistrationsDetailList({});
      }
      // insertContryCode()
      //  $(".modal-backdrop").remove();
    });
    return {
      state,
      isEmail,
      getRegistrationsDetailList,
      closeSuccessModal,
      registerReplace,
      hideReplaceRegister,
      insertContryCode,
      passwordStrengthCheck,
      passwordConfirmationStrengthCheck,
      onInput,
      countryChanged,
      validatePhoneNumber,
      errorList,
      register,
      hideRegisterSuccessModal,
      validateRegistration,
      redirectToLogIn,
      switchVisibility,
      passwordStatus,
      switchVisibilityConfirm,
      getCountryCodeList,
      sendOtp,
      verifyMobile,
      checkInputValue,
      countDownTimer,
      resetCheck

    };
  },
});
