
import { defineComponent, reactive, onMounted } from "vue";
import { authService } from "@/login/Services/AuthService.ts";
import ErrorList from "@/components/commonDesign/ErrorList.vue";
import { common } from "@/common/services/Common.ts";

import { useRoute, useRouter } from "vue-router";


export default defineComponent({
  name: "ResetPassword",
  components: {
    ErrorList,
  },
  setup() {
    const router = useRouter();
    const userouter = useRoute();
    const state = reactive({
      ui: {
        error: {
          validationList: [] as any,
          hasError: false,
        },
        postLoading: {
          sendResetPassword: false,
        },
      },
      data: {
        isPasswordShow: false,
        isConfirmPasswordShow: false,
        resetPassword: {
          password: "",
          confirmPassword: "",
        },
        list: [] as any,
      },
    });
    function redirectToLogIn() {
      router.push({
        name: "admin-login",
      });
    }

    function hideResetSuccessModal() {
      $("#resetSuccessModal").modal("hide");
      redirectToLogIn();
    }
    function validateEmail() {
      state.ui.error.validationList = [];
      state.ui.error.hasError = false;

      if (!state.data.resetPassword.password) {
        state.ui.error.validationList.push("Password is required.");
      }
      if (!state.data.resetPassword.confirmPassword) {
        state.ui.error.validationList.push(
          "Password Confirmation is required."
        );
      } else if (
        state.data.resetPassword.password &&
        state.data.resetPassword.confirmPassword
      ) {
        if (
          state.data.resetPassword.password !=
          state.data.resetPassword.confirmPassword
        ) {
          state.ui.error.validationList.push(
            "Password & Password Confirmation doesnot match."
          );
        }
      }

      if (
        !state.ui.error.validationList &&
        !state.ui.error.validationList.length
      ) {
        state.ui.error.hasError = false;
      } else if (state.ui.error.validationList.length != 0) {
        state.ui.error.hasError = true;
      }

    }
    function sendResetPassword() {
      console.log("CHECK")
      validateEmail()
      if (
        state.ui.error.validationList.length != 0 ||
        state.ui.error.hasError ||
        state.ui.postLoading.sendResetPassword || !userouter.query.uuid
      )
        return false;
      const payLoad = {
        uuid: userouter.query.uuid,
        password: state.data.resetPassword.password,
        // eslint-disable-next-line @typescript-eslint/camelcase
        password_confirmation: state.data.resetPassword.confirmPassword,
      };
      state.ui.postLoading.sendResetPassword = true;
      authService
        .resetPassword(payLoad)
        .then((res: any) => {
          // console.log("sendResetPassword Response Check:>>> ", res.data.data);
          $("#resetSuccessModal").modal("show");
          state.data.resetPassword.password = "";
          state.data.resetPassword.confirmPassword = "";
        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.ui.error.validationList)
            .then((res: any) => {
              state.ui.error.hasError = true;
              // console.log("CHECK for Error >>>", res)
              if (res) {
                if (res.uuid) {
                  state.ui.error.validationList.push(res.uuid[0]);
                  // state.ui.error.validationList = [...state.ui.error.validationList]
                }
                if (res.password) {
                  state.ui.error.validationList.push(res.password[0]);
                  // state.ui.error.validationList = [...state.ui.error.validationList]
                }
              }
            });
        })
        .finally(() => {
          state.ui.postLoading.sendResetPassword = false;
        });
    }

    function showPassword() {
      state.data.isPasswordShow = !state.data.isPasswordShow;
    }

    function showConfirmPassword() {
      state.data.isConfirmPasswordShow = !state.data.isConfirmPasswordShow;
    }
    onMounted(() => {
      console.log("UUID Check >>>", userouter.query.uuid);
    });

    return {
      state,
      hideResetSuccessModal,
      redirectToLogIn,
      sendResetPassword,
      showPassword,
      showConfirmPassword
    };
  },
});
