
import { defineComponent, reactive } from "vue";
// import { ethitransService } from "@/services/EthitransService.ts";
export default defineComponent({
  name: "FormRequest",
  setup() {
    const state = reactive({
      type: "",
      formList: [] as any,
      ui: {
        isLoading: {
          getAccountFormList: false,
        },
        isPostLoading: {},
      },
    });
    return {
      state,
    };
  },
});
