
import { defineComponent, ref, watch, onMounted, nextTick } from 'vue';
import { Loader, LoaderOptions } from 'google-maps';

export default defineComponent({
  name: "GoogleAddressCustomer",
  props: {
    latitude: {
      type: Number,
      required: true
    },
    longitude: {
      type: Number,
      required: true
    },
    address: {
      type: String,
      default: "No Address Provided"
    },
    visible: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:latitude', 'update:longitude', 'update:address'],
  setup(props, { emit }) {
    const mapContainer = ref<HTMLElement | null>(null);
    const autocompleteInput = ref<HTMLInputElement | null>(null);
    const displayAddress = ref(props.address);
    let map: google.maps.Map | null = null;
    let marker: google.maps.Marker | null = null;
    let infoWindow: google.maps.InfoWindow | null = null;
    let autocomplete: google.maps.places.Autocomplete | null | any = null;

    const updateMap = (lat: number, lng: number, address: string) => {
      if (map && marker) {
        const coordinate = { lat, lng };
        marker.setPosition(coordinate);
        map.setCenter(coordinate);

        if (infoWindow) {
          infoWindow.setContent(`<div><strong>${address}</strong></div>`);
        }
      }
    };

    const initializeAutocomplete = (google: any) => {
      if (autocompleteInput.value) {
        autocomplete = new google.maps.places.Autocomplete(autocompleteInput.value);
        autocomplete.addListener('place_changed', () => {
          const place = autocomplete!.getPlace();
          if (place.geometry) {
            const location = place.geometry.location;
            updateMap(location.lat(), location.lng(), place.formatted_address || "No Address Provided");
            displayAddress.value = place.formatted_address || "No Address Provided"; 
            emit('update:latitude', location.lat());
            emit('update:longitude', location.lng());
            emit('update:address', place.formatted_address || "No Address Provided");
          }
        });
      }
    };
    
    const initializeMap = async () => {
      const options: LoaderOptions = { libraries: ['places'] };
      const loader = new Loader('AIzaSyD9947HYdSc5v8mEFxoEsHFjcQXF5P_jmc', options);

      try {
        const google = await loader.load();

        initializeAutocomplete(google);

        if (props.visible && mapContainer.value) {
          map = new google.maps.Map(mapContainer.value, {
            center: { lat: props.latitude, lng: props.longitude },
            zoom: 15,
          });

          const coordinate = { lat: props.latitude, lng: props.longitude };
          const address = props.address;

          marker = new google.maps.Marker({
            position: coordinate,
            map: map,
            title: address,
          });

          infoWindow = new google.maps.InfoWindow({
            content: `<div><strong>${address}</strong></div>`,
          });

          marker.addListener('click', () => {
            window.open(`https://www.google.com/maps?q=${props.address}`, '_blank');
          });

          map.setCenter(coordinate);
        }
      } catch (error) {
        console.error('Error loading Google Maps API:', error);
      }
    };

    const resetMap = () => {
      if (map) {
        mapContainer.value && (mapContainer.value.innerHTML = '');
        map = null;
        marker = null;
        infoWindow = null;
      }
    };

    watch(() => props.visible, async (newVal) => {
      if (newVal) {
        await nextTick(); 
        initializeMap();
      } else {
        resetMap();
      }
    });

    watch([() => props.latitude, () => props.longitude], () => {
      updateMap(props.latitude, props.longitude, props.address);
    });

    watch(() => props.address, (newAddress) => {
      if (newAddress === '') {
        if (autocompleteInput.value) {
          autocompleteInput.value.value = '';
        }
      }
    });

    onMounted(initializeMap);

    return {
      mapContainer,
      autocompleteInput,
      displayAddress
    };
  },
});
