
import { defineComponent, onMounted, reactive, ref, onUnmounted } from "vue";
import VuePictureCropper, { cropper } from "vue-picture-cropper";
import { ethitransService } from "@/services/EthitransService";

export default defineComponent({
  name: "SignatureUploadModal",
  components: {
    VuePictureCropper,
  },
  props: {
    showModal: Boolean,
    signatureFile: String,
    from: String,
  },
  setup(props, { emit }) {

    const state = reactive({
      data: {
        uploadImageData: "" as any,
        newUploadImge: "" as any,
        uploadSignature: "" as any,
        cropSignature: "" as any,
        signatureFile: "" as any,
        isuplode: false,
        isEmpty: false
      },
      ui: {
        error: {
          hasError: false,
          errorList: {
            createNewFolder: [] as any,
            getFolderList: [] as any,
          },
        },
        isLoading: {
          getFolderList: false,
        },
        isPostLoading: {
          createNewFolder: false,
        },
      },
    });

    function closeSignaturePadModal() {
      state.data.isuplode= false;
      state.data.uploadImageData= "";
      state.data.newUploadImge= "";
      state.data.uploadSignature= "";
      emit("close");
      $("#signatureUploadModal").modal("hide");
    }


  

    function clearCrop() {
      if (!cropper) return;
      cropper.clear();
    }
    const clearSignature = () => {
      state.data.isuplode= false;
    
      state.data.uploadImageData= "";
      state.data.newUploadImge= "";
      state.data.uploadSignature= "";
      state.data.cropSignature = "";
       
    };


    function readImage(file: any) {
      const data = {} as any;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const extension = file.name.substring(file.naheighte = file.name);
        data.fileName = file.name.replaceAll(`.${extension}`, "");
        data.base64 = e.target.result;
        // data.extension = extensionLower;
        state.data.uploadImageData = data;
        state.data.newUploadImge = data.base64;
      };
      reader.readAsDataURL(file);
    }

    function onDocumentChange(files: any) {
      state.data.isuplode = true;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        console.log("FILE CHECK >>>", file);
        // if(file.type)
        const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
        const extensionLower = extension.toLowerCase();
        readImage(file);
      }
    }

    function saveSignature() {
      state.data.cropSignature = cropper?.getDataURL();
      state.data.uploadSignature = cropper?.getDataURL();
    }

    function resetCrop() {
      if (!cropper) return;
      cropper.reset();
    }

    function uploadUSerSignature() {
      const payLoad = {
        signature: state.data.uploadSignature?state.data.uploadSignature:state.data.newUploadImge,
      };
      if(props.from=='profile' || !state.data.signatureFile){
        ethitransService
        .uploadUSerSignature(payLoad)
        .then((res: any) => {
          emit("uploadSignature", state.data.uploadSignature?state.data.uploadSignature:state.data.newUploadImge);
          closeSignaturePadModal();
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          // state.ui.isPostLoading.uploadAttachments = false;
        });
      }else{
        emit("uploadSignature", state.data.uploadSignature?state.data.uploadSignature:state.data.newUploadImge);
        closeSignaturePadModal();
      }
    }

    function userSignature() {
      ethitransService
        .userSignature()
        .then((res: any) => {
          state.data.signatureFile = res.data.data.signature;
        })
        .catch((error: any) => {
          //   common
        })
        .finally(() => {
          //   state.ui.isLoading.userStatus = true;
        });
    }

    onMounted(() => {
      if (props.showModal) {
        $("#signatureUploadModal").modal("show");
      }
      onDocumentChange(props.signatureFile);
      userSignature();
    });

    return {
      state,
      closeSignaturePadModal,
      clearSignature,
      onDocumentChange,
      readImage,
      saveSignature,
      clearCrop,
      resetCrop,
      uploadUSerSignature,
      userSignature
    };
  },
});
