<template>
  <div class="items">
    <template v-if="items.length">
      <button
        class="item"
        :class="{ 'is-selected': index === selectedIndex }"
        v-for="(item, index) in items"
        :key="index"
        @click="selectItem(index)"
      >
        {{ item.full_name }}
      </button>
    </template>
    <div class="item" v-else>No result</div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },

    command: {
      type: Function,
      required: true,
    },
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data() {
    return {
      selectedIndex: 0,
    };
  },

  watch: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    items() {
      this.selectedIndex = 0;
    },
  },

  methods: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    onKeyDown({ event }) {
      if (event.key === "ArrowUp") {
        this.upHandler();
        return true;
      }

      if (event.key === "ArrowDown") {
        this.downHandler();
        return true;
      }

      if (event.key === "Enter") {
        this.enterHandler();
        return true;
      }

      return false;
    },

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    upHandler() {
      this.selectedIndex =
        (this.selectedIndex + this.items.length - 1) % this.items.length;
    },

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    downHandler() {
      this.selectedIndex = (this.selectedIndex + 1) % this.items.length;
    },

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    enterHandler() {
      this.selectItem(this.selectedIndex);
    },

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    selectItem(index) {
      const item = this.items[index];
      if (item) {
        this.command({ id: item.id, label: item.full_name, userId: item.id, 'data-mention-userId': item.id });
      }
    },
  },
};
</script>