
import { defineComponent, reactive, onMounted } from "vue";

import EmptyData from "@/components/commonDesign/EmptyData.vue";
import { config } from "@/Config";

export default defineComponent({
  name: "HistoryTimeLine",
  props: {
    history: {
      type: Array,
      required: true,
    },
  },
  components: {
    EmptyData,
  },
  setup() {
    const state = reactive({
      data: {
        imageBaseUrl: "",
        myDriveFileFolderLoad: false,
      },
      ui: {
        error: {
          hasError: false,
          errorList: {
            uploadAttachments: [] as any,
          },
        },
        isLoading: {
          getFolderList: false,
        },
        isPostLoading: {
          enableSelectedFileTag: false,
        },
      },
    });
    onMounted(() => {
      state.data.imageBaseUrl = config.imageBaseUrl;
    });

    return {
      state,
    };
  },
});
