
import { defineComponent, reactive  } from "vue";
export default defineComponent({
  name: "PrivacyPolicyTermsAndConditions",
  components: {
  },
  setup() {
    // const router = useRouter();
    const state = reactive({
      data: {
        imageBaseUrl: "",
        activities: [] as any,
      },
      ui: {
        isLoading: {
          projectListActivities: false,
        },
        isPostLoading: {
          closeChannel: false,
        },
      },
    });

    return {
      state,
    };
  },
});
