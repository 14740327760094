
import { defineComponent, reactive, onMounted, watch } from "vue";
import { ethitransService } from "@/services/EthitransService";
import ErrorList from "@/components/commonDesign/ErrorList.vue";
import DragAndDropZone from "@/common/DragAndDropZone.vue";
import DebouncedCurrencyInput from "@/common/DebouncedCurrencyInput.vue";

import Button from "@/common/Button.vue";

import { validationService } from "@/common/ValidationService";
import { common } from "@/common/services/Common";
import { config } from "@/Config";
import { now } from "moment";
export default defineComponent({
  name: "ProjectApprovalModal",
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    selectedProject: {
      type: Object,
      required: true,
    },
  },
  components: {
    DragAndDropZone,
    DebouncedCurrencyInput,
    ErrorList,
    Button,
  },
  setup(props, { emit }) {
    const state = reactive({
      titleMember: "pending members",
      headingMember: "Get started by inviting users in your project.",
      data: {
        buttonName: "Save As Draft",
        todayData: new Date,
        projectAckowledgement: {
          payer: "",
          payers: [] as any,
          name: "",
          description: "",
          estimatedBudget: 0,
          startDate: "",
          endDate: "",
          attachments: [] as any,
          edocLink: "",
        },
      },
      ui: {
        isLoading: {
          projectDetailsAPICalled: false,
          enableEndDate: true,
        },
        isPostLoading: {
          acknowledgeRequest: false,
        },
        errors: {
          errorList: {
            approval: [] as any,
          },
          hasError: false,
        },
      },
    });
    function setData(inputVal: any) {
      state.data.projectAckowledgement.estimatedBudget = inputVal;
    }
    function formatNumber(n: any) {
      // format number 1000000 to 1,234,567
      return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    // function formatCurrency() {
    //   console.log("Key Up")
    // // function formatCurrency() {
    // //   // appends $ to value, validates decimal side
    // //   // and puts cursor back in right position.
    // //   const blur = "blur";

    // //   // get input value
    // //   const input: any = document.getElementById(
    // //     "currency-field"
    // //   ) as HTMLInputElement | null;
    //   let inputVal = state.data.projectAckowledgement.estimatedBudget;
    // //   // let inputVal = state.data.projectAckowledgement.estimatedBudget;

    // //   // don't validate empty input
    // //   if (inputVal === "") {
    // //     return;
    // //   }

    // //   // original length
    // //   // const originalLen = inputVal.length;

    // //   // initial caret position
    // //   // let caretPos = input.prop("selectionStart");

    // //   // check for decimal
    //   if (inputVal.indexOf(".") >= 0) {
    //     // get position of first decimal
    //     // this prevents multiple decimals from
    //     // being entered
    //     const decimalPos = inputVal.indexOf(".");

    //     // split number by decimal point
    //     let leftSide = inputVal.substring(0, decimalPos);
    //     let rightSide = inputVal.substring(decimalPos);

    //     // add commas to left side of number
    //     leftSide = formatNumber(leftSide);

    //     // validate right side
    //     rightSide = formatNumber(rightSide);

    //     // On blur make sure 2 numbers after decimal
    //     // if (blur === "blur") {
    //     //   rightSide += "00";
    //     // }

    //     // Limit decimal to only 2 digits
    //     rightSide = rightSide.substring(0, 2);

    //     // join number by .
    //     inputVal = "$" + leftSide + "." + rightSide;
    //   } else {
    //     // no decimal entered
    //     // add commas to number
    //     // remove all non-digits
    //     inputVal = formatNumber(inputVal);
    //     inputVal = "$" + inputVal;

    //     // final formatting
    //     // if (blur === "blur") {
    //       //   inputVal += ".00";
    //     // }
    //   }
    //       state.data.projectAckowledgement.estimatedBudget = inputVal;

    // //   // send updated string to input
    // //   // input.value(inputVal);
    // //   console.log("CHECK THE INPUT >>>", inputVal);
    // //   // setTimeout( function() {
    // //   // //   input.value = inputVal;
    // //   // // input.setAttribute("value", inputVal);
    // //   // state.data.projectAckowledgement.estimatedBudget = inputVal;
    // //   // }, 1000)
    // //   // input.value = inputVal;
    // //   // input.setAttribute("value", inputVal);
    // //   // setData(inputVal);

    // //   // put caret back in the right position
    // //   // const updatedLen = inputVal.length;
    // //   // caretPos = updatedLen - originalLen + caretPos;
    // //   // input[0].setSelectionRange(caretPos, caretPos);
    // }
    function onPageDown() {
      // alert("Hello");
      // console.log("CHECK DATA >>>", state.data.projectAckowledgement.estimatedBudget)
      // formatCurrency(state.data.projectAckowledgement.estimatedBudget, 'blur');
    }
    function readImage(file: any) {
      const data = {} as any;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
        const extensionLower = extension.toLowerCase();
        data.file = e.target.result;
        data.name = file.name.replaceAll(`.${extension}`, "");
        data.extension = extensionLower;
        state.data.projectAckowledgement.attachments.push(data);
        console.log("CHECK DATA FOR UPLOAD>>", data);
        // state.imageList.push(data);
      };
      reader.readAsDataURL(file);
    }
    const dropFiles = (event: any) => {
      const files = event.target.files || event.dataTransfer.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        readImage(file);
      }
    };
    function removeSelectedImage(image: any) {
      state.data.projectAckowledgement.attachments =
        state.data.projectAckowledgement.attachments.filter(
          (e: any) => e != image
        );
    }
    function removeSelectedUser(payer: any) {
      state.data.projectAckowledgement.payers =
        state.data.projectAckowledgement.payers.filter(
          (e: string) => e != payer
        );
    }
    function addPayers() {
      if (
        validationService.isValidEmail(state.data.projectAckowledgement.payer)
      ) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (state.data.projectAckowledgement.payers.length === 0) {
          state.data.projectAckowledgement.payers.push(
            common.lowerCase(state.data.projectAckowledgement.payer)
          );
        } else {
          state.data.projectAckowledgement.payers.forEach((member: any) => {
            // console.log("member", member);
            if (member != state.data.projectAckowledgement.payer) {
              state.data.projectAckowledgement.payers.push(
                common.lowerCase(state.data.projectAckowledgement.payer)
              );
            }
          });
        }
        state.data.projectAckowledgement.payers =
          state.data.projectAckowledgement.payers.filter(
            (item: any, i: number, ar: any) => ar.indexOf(item) === i
          );
        state.data.projectAckowledgement.payer = "";
      }
    }
    function hideProjectAcknowledgementModal() {
      $("#projectAcknowledgement").modal("hide");
      emit("close");
    }
    function validateForm() {
      // console.log("VALIDATE")
      state.ui.errors.errorList.approval = [];
      state.ui.errors.hasError = false;

      // if (!state.data.projectAckowledgement.payers.length) {
      //   state.ui.errors.errorList.approval.push("Payer is required.");
      // }
      // if (!state.data.projectAckowledgement.estimatedBudget) {
      //   state.ui.errors.errorList.approval.push(
      //     "Estimated Budget is required."
      //   );
      // }
      if (!state.data.projectAckowledgement.startDate) {
        state.ui.errors.errorList.approval.push("Start Date is required.");
      }
      if (!state.data.projectAckowledgement.endDate) {
        state.ui.errors.errorList.approval.push("End Date is required.");
      }
      if (
        state.data.projectAckowledgement.startDate &&
        state.data.projectAckowledgement.endDate
      ) {
        // console.log("CHECK DATE CHECK >>> ")
        const d1 = new Date(
          state.data.projectAckowledgement.startDate
        ).getTime();
        const d2 = new Date(state.data.projectAckowledgement.endDate).getTime();
        // console.log("CHECK DATE START >>> ", d1)
        // console.log("CHECK DATE END >>> ", d2)
        if (d2 < d1) {
          state.ui.errors.errorList.approval.push(
            "Date is not in Valid Range."
          );
        }
      }

      if (
        !state.ui.errors.errorList.approval &&
        !state.ui.errors.errorList.approval.length
      ) {
        state.ui.errors.hasError = false;
      } else if (state.ui.errors.errorList.approval.length != 0) {
        state.ui.errors.hasError = true;
      }
    }
    function acknowledgeRequest() {
      // state.error.errorList.createChannel = [];
      // state.error.hasError = false;
      if (state.data.projectAckowledgement.payer) {
        state.data.projectAckowledgement.payers.push(
          state.data.projectAckowledgement.payer
        );
        state.data.projectAckowledgement.payer = "";
      }
      validateForm();
      if (
        state.ui.isPostLoading.acknowledgeRequest ||
        state.ui.errors.errorList.approval.length != 0 ||
        state.ui.errors.hasError
      )
        return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: props.selectedProject.uuid,
        name: state.data.projectAckowledgement.name,
        description: state.data.projectAckowledgement.description,
        // eslint-disable-next-line @typescript-eslint/camelcase
        payer_emails: state.data.projectAckowledgement.payers,
        budget: state.data.projectAckowledgement.estimatedBudget,
        // eslint-disable-next-line @typescript-eslint/camelcase
        start_date: state.data.projectAckowledgement.startDate,
        // eslint-disable-next-line @typescript-eslint/camelcase
        end_date: state.data.projectAckowledgement.endDate,
        files: state.data.projectAckowledgement.attachments,
        // eslint-disable-next-line @typescript-eslint/camelcase
        edoc_id: state.data.projectAckowledgement.edocLink,
      };
      console.log("CHECK PAYLOAD >>> ", payLoad);
      state.ui.isPostLoading.acknowledgeRequest = true;
      ethitransService
        .projectAcknowledgement(payLoad)
        .then((res: any) => {
          console.log("acknowledgeRequest Response Check:>>> ", res.data.data);
          hideProjectAcknowledgementModal();
          emit("acknowledgedData", res.data.data);
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.errors.errorList.approval)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.approval.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.acknowledgeRequest = false;
        });
    }

    watch(
      () => state.data.projectAckowledgement.startDate,
      (newvalue, oldvalue) => {
        state.ui.isLoading.enableEndDate = false
      }
    );

    onMounted(() => {
      if (props.showModal) {
        $("#projectAcknowledgement").modal("show");
        state.data.projectAckowledgement.name = props.selectedProject.name;
        state.data.projectAckowledgement.description =
          props.selectedProject.description;
      }
    });
    return {
      state,
      setData,
      onPageDown,
      formatNumber,
      // formatCurrency,
      removeSelectedImage,
      dropFiles,
      addPayers,
      removeSelectedUser,
      validateForm,
      acknowledgeRequest,
      hideProjectAcknowledgementModal,
    };
  },
});
