import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "col-lg-9" }
const _hoisted_2 = {
  key: 0,
  class: "card drag-wrapper"
}
const _hoisted_3 = { class: "form-wrap pt-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TitleComponent = _resolveComponent("TitleComponent")
  const _component_TextBoxComponent = _resolveComponent("TextBoxComponent")
  const _component_RadioButtonComponent = _resolveComponent("RadioButtonComponent")
  const _component_SelectOption = _resolveComponent("SelectOption")
  const _component_CheckBox = _resolveComponent("CheckBox")
  const _component_TextArea = _resolveComponent("TextArea")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_ctx.state.data && _ctx.state.data.length)
      ? (_openBlock(), _createBlock("div", _hoisted_2, [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.state.data, (form, formIndex) => {
            return (_openBlock(), _createBlock("div", { key: formIndex }, [
              (form.type === 'title')
                ? (_openBlock(), _createBlock(_component_TitleComponent, {
                    key: 0,
                    title: form.title,
                    description: form.description,
                    editStatus: false
                  }, null, 8, ["title", "description"]))
                : _createCommentVNode("", true),
              _createVNode("div", _hoisted_3, [
                (form.type === 'textbox')
                  ? (_openBlock(), _createBlock(_component_TextBoxComponent, {
                      key: 0,
                      data: form,
                      editStatus: false
                    }, null, 8, ["data"]))
                  : _createCommentVNode("", true),
                (form.type === 'radioButton')
                  ? (_openBlock(), _createBlock(_component_RadioButtonComponent, {
                      key: 1,
                      data: form,
                      editStatus: false
                    }, null, 8, ["data"]))
                  : _createCommentVNode("", true),
                (form.type === 'single_select')
                  ? (_openBlock(), _createBlock(_component_SelectOption, {
                      key: 2,
                      data: form
                    }, null, 8, ["data"]))
                  : _createCommentVNode("", true),
                (form.type === 'checkBox')
                  ? (_openBlock(), _createBlock(_component_CheckBox, {
                      key: 3,
                      data: form
                    }, null, 8, ["data"]))
                  : _createCommentVNode("", true),
                (form.type === 'textArea')
                  ? (_openBlock(), _createBlock(_component_TextArea, {
                      key: 4,
                      data: form
                    }, null, 8, ["data"]))
                  : _createCommentVNode("", true)
              ])
            ]))
          }), 128)),
          _createVNode("button", {
            disabled: !_ctx.state.data || !_ctx.state.data.length,
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.createFormTemplate && _ctx.createFormTemplate(...args)))
          }, " Submit ", 8, ["disabled"])
        ]))
      : _createCommentVNode("", true)
  ]))
}