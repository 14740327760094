
  import { useStore } from "vuex";
  import { config } from "@/Config";
  import { useRoute, useRouter } from "vue-router";
  import { computed, defineComponent, reactive, onMounted, watch } from "vue";
  export default defineComponent({
    name: "DocuSignIframe",
    components: {
     
    },
    
    setup() {
      const routerQuery = useRoute();
      const store = useStore();

      const state = reactive({
        data:{
          imageBaseUrl: "",
          docuSignSignOpenUrl:"",
          docuSignSignUrl:"",

        },
        ui:{
          isLoading:{
            docuSignIframe:false,
            docuSignIframeOpen:false
          }

        }
       
      });

      watch(
      () => store.state.saveSignedData,
      (newvalue, oldvalue) => {
        if (newvalue) {
          state.data.docuSignSignUrl =store.state.saveSignedData;
        }
      }
    );

      watch(
      () => store.state.openSignDocument,
      (newvalue, oldvalue) => {
        if (newvalue) {
          state.data.docuSignSignOpenUrl =store.state.openSignDocument;
        }
      }
    );

      onMounted(() => {
        state.data.imageBaseUrl = config.imageBaseUrl;
        window.parent.postMessage({ event: 'taskDone', data: routerQuery.query }, '*');
        debugger
      });
      return {
        state,
       
        
      };
    },
  });
  