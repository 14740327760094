
import { defineComponent, reactive, onMounted, toRefs } from "vue";
import { useRouter } from "vue-router";
import { config } from "@/Config";

export default defineComponent({
  name: "DiscussionChannels",
  components: {
    // ErrorList, // googleAuth
    // facebookLogin
  },
  props: {
    activity: Object,
  },
  setup() {
    const router = useRouter();
    const state = reactive({
      data: {
        imageBaseUrl: "",
      },
      ui: {
        postLoading: {
          processRegistration: false,
        },
      },
    });
    function redirectToChannel(form: any) {
      if(!form.channel.uuid) return false
      router.push({
        name: "admin-channel",
        params: { channelId: form.channel.uuid},
      });
    }
    onMounted(() => {
      state.data.imageBaseUrl = config.imageBaseUrl;
    });
    return {
      redirectToChannel,
      ...toRefs(state),
    };
  },
});
