
import { defineComponent, reactive } from "vue";
import { useRouter } from "vue-router";
import { common } from "@/common/services/Common.ts";
import { ethitransService } from "@/services/EthitransService.ts";

import TitleComponent from "@/components/formComponents/TitleComponent.vue";
import TextBoxComponent from "@/components/formComponents/TextBoxComponent.vue";
import RadioButtonComponent from "@/components/formComponents/RadioButtonComponent.vue";
import SelectOption from "@/components/formComponents/SelectOption.vue";
import CheckBox from "@/components/formComponents/CheckBox.vue";
import TextArea from "@/components/formComponents/TextArea.vue";

export default defineComponent({
  name: "TemplateCard",
   components: {
    TitleComponent,
    TextBoxComponent,
    RadioButtonComponent,
    SelectOption,
    CheckBox,
    TextArea,
  },
  props: {
    template: Object,
    isUserStatus: Boolean,
  },
  setup(props) {
    const router = useRouter();
    const state = reactive({
      data: {
        FormData: {} as any,
      },
      ui: {
        isLoading: {
          getProjectDetail: false,
        },
        postLoading: {
          getAccountFormRetrieveById: false,
        },
      },
    })
    // console.log("CHECK props", props);
    // console.log("CHECK TemplateCard props", props.template);
    // console.log("CHECK props", props.member.id);
    function getAccountFormRetrieveByIdForSend(formId: any) {
      // console.log("Get Account form Response:>>>", formId);
      state.ui.postLoading.getAccountFormRetrieveById = true;
      ethitransService
        .accountFormRetrieveById(formId)
        .then((res: any) => {
          // console.log(
          //   "getAccountFormRetrieveById Response Check:>>> ",
          //   res.data.elements
          // );
          state.data.FormData = res.data ? res.data : {};
          // state.selectedFormForEdit = res.data ? res.data : {};
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.postLoading.getAccountFormRetrieveById = false;
        });
    }

   function hideTemplateModal() {
      $("#exampleModal").modal("hide");
    }
    function selectForm(form: any) {
      $("#exampleModal").modal("show");
      // console.log("Selected Form:>>", form);
      getAccountFormRetrieveByIdForSend(form._id);
    }

    function sendSelectedForm(form: any) {
      console.log("CHECK ", form)
      router.push({
        name: "admin-channel-create-form",
         query: { formId: form._id, mode: "send" },
      });
      // $(".modal-backdrop").remove();
    }
    return {state, sendSelectedForm, selectForm, hideTemplateModal, getAccountFormRetrieveByIdForSend};
  },
});
