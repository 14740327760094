
import { defineComponent, onMounted, reactive } from "vue";
import ErrorList from "@/components/commonDesign/ErrorList.vue";
import { ethitransService } from "@/services/EthitransService";
import { common } from "@/common/services/Common";
// import SuccessfulModal from "@/components/modal/SuccessfulModal.vue";
import { config } from "@/Config";
import { useRoute } from "vue-router";
// import Axios from "axios";
// @ts-ignore-start
// @ts-ignore-end
// const handleClientLoad =  import("../../../public/js/calendarAuth");

export default defineComponent({
  name: "FeedbackModal",
  props: {
    showFeedBackModal: Boolean,
  },
  components: {
    ErrorList,
    // SuccessfulModal,
  },
  setup(props, { emit }) {
    const state = reactive({
      data: {
        showSuccesModal: false,
        currentYear: new Date().getFullYear(),
        successFulMessage: "",
        title: "",
        description: "",
        file: "",
      },
      ui: {
        isLoading: {
          getAccountFormList: false,
        },
        isPostLoading: {
          createUserIssue: false,
        },
      },
      error: {
        hasError: false,
        erorList: {
          uploadClientDocument: [] as any,
          errorsList: [] as any,
        },
      },
    });
    function readCurrentIssueImage(file: any) {
      // console.log("CHECK IMAGE>>", file);
      // let data = "";
      const data = {} as any;
      const reader = new FileReader();
      console.log("FILE READER CHECK >>>", reader);
      reader.onload = (e: any) => {
        // console.log("BIT BIT>>", e);
        const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
        // console.log("Extension Check >>>", extension)
        // console.log("File Name >>>", file.name)
        // console.log("File Size Check >>>", file.size)

        // if (
        //   extension === "jpg" ||
        //   extension === "jpeg" ||
        //   extension === "pdf" ||
        //   extension === "png"
        // ) {
        // data = e.target.result;
        data.base64 = e.target.result;
        data.fileName = file.name;
        data.extension = extension;
        data.size = common.formatBytes(parseInt(file.size));
        console.log("UPLOAD READY FILE >>>", data);
        state.data.file = data.base64;
        // console.log("CHECK DATA FOR UPLOAD>>", data);
        // state.imageList.push(data);
        // // tempImageList.push
        // // chat-screen
        // if (state.imageList && state.imageList.length) {
        //   $("#chat-screen-content").animate(
        //     { scrollTop: $("#chat-screen-content")[0].scrollHeight },
        //     1000
        //   );
        // }
        // }
      };
      reader.readAsDataURL(file);
    }
    function onCurrentIssueUpload(event: any) {
      // state.item.error.videoError = false;
      const files = event.target.files || event.dataTransfer.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        // console.log("FILE CHECK >>>", file);
        // if(file.type)
        const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
        const extensionLower = extension.toLowerCase();
        // if (
        //   extensionLower === "jpg" ||
        //   extensionLower === "jpeg" ||
        //   extensionLower === "pdf" ||
        //   extensionLower === "png"
        // )
        if (file.type.includes("video")) {
          if (
            extension === "mp4" ||
            extension === "webm" ||
            extension === "ogg"
          ) {
            readCurrentIssueImage(file);
            // console.log("Check for Extension >>>", extension);
            // state.item.error.videoError = false;
          } else {
            // state.item.error.videoError = true;
            // $("#chat-screen-content").animate(
            //   { scrollTop: $("#chat-screen-content")[0].scrollHeight },
            //   1000
            // );
          }
          // if()
        } else {
          readCurrentIssueImage(file);
        }
        // console.log("CHECK DATA WHEN UPLOADED>>", file);
      }
      // $("#gallery-photo-add").val("")
    }
    function issueDataClear() {
      state.data.title = "";
      state.data.description = "";
      state.data.file = "";
      state.error.erorList.errorsList = [];
      state.error.hasError = false;
      $("#feedbackModal").modal("hide");
    }
    function validateCreateUserIssue() {
      state.error.erorList.errorsList = [];
      state.error.hasError = false;

      if (!state.data.title) {
        state.error.erorList.errorsList.push("Title is required.");
      }
      if (!state.data.description) {
        state.error.erorList.errorsList.push("Description is required.");
      }

      if (
        !state.error.erorList.errorsList &&
        !state.error.erorList.errorsList.length
      ) {
        state.error.hasError = false;
      } else if (state.error.erorList.errorsList.length != 0) {
        state.error.hasError = true;
      }
    }
    function createUserIssue() {
      state.error.erorList.errorsList = [];
      state.error.hasError = false;
      validateCreateUserIssue();
      if (
        (state.error.erorList.errorsList &&
          state.error.erorList.errorsList.length) ||
        state.error.hasError
      )
        return false;
      if (state.ui.isPostLoading.createUserIssue) return false;
      const payLoad = {
        title: state.data.title,
        description: state.data.description,
        file: state.data.file,
      };
      state.ui.isPostLoading.createUserIssue = true;
      ethitransService
        .createIssue(payLoad)
        .then((res: any) => {
          console.log("CreateUserIssue Response : >>> ", res.data);
          issueDataClear();
          emit("closeModal");
          // state.templateList = res.data.docs ? res.data.docs : [];
        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.error.erorList.errorsList)
            .then((res: any) => {
              state.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.error.erorList.errorsList.push(value[0]);
                }
              }
            });
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.createUserIssue = false;
        });
    }

    onMounted(() => {
      if(props.showFeedBackModal){
        $("#feedbackModal").modal("show");
      }
    });

    return {
      state,
      validateCreateUserIssue,
      issueDataClear,
      createUserIssue,
      onCurrentIssueUpload,
      readCurrentIssueImage,
    };
  },
});
