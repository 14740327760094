<template>
  <div>
    <input type="text" id="otp" name="otp" class="form-control" v-model="inputValue" @input="onInput" @paste="onPaste"
      maxlength="6" placeholder="Enter up to 6 digits OTP" />
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'AppOTPInput',
  emits: ['sixDigitInput'],

  setup(props, { emit }) {
    const inputValue = ref('');

    // Method to handle typing input (limits to 6 digits)
    const onInput = (event) => {
      let value = event.target.value;
      value = value.replace(/\D/g, ''); // Remove non-numeric characters

      if (value.length > 6) {
        value = value.slice(0, 6)
      }

      if (value.length === 6) {
        emit('sixDigitInput', value);
      }

      inputValue.value = value;
    };

    // Method to handle pasting input (limits to 6 digits)
    const onPaste = (event) => {
      let pastedText = (event.clipboardData || window.clipboardData).getData('text');
      pastedText = pastedText.replace(/\D/g, ''); // Remove non-numeric characters

      if (pastedText.length > 6) {
        pastedText = pastedText.slice(0, 6); // Limit to 6 digits
      }

      if (pastedText.length === 6) {
        emit('sixDigitInput', pastedText);
      }

      inputValue.value = pastedText;
      event.preventDefault();
    };

    return {
      inputValue,
      onInput,
      onPaste,
    };
  },
};
</script>
