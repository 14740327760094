
import { defineComponent, reactive, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import "vue3-pagination/dist/vue3-pagination.css";
import TutorialStep from "@/includes/TutorialStep.vue";
import { config } from "@/Config";

export default defineComponent({
  name: "TutorialEight",
  components: {
   TutorialStep
  },
  setup() {
    const router = useRouter();
    const state = reactive({
        
      userList: [] as any,
      data: {
        selectedEdocForm: {} as any,
        imageBaseUrl: "",
        
      },
      ui: {
        isLoading: {
          clientDocumentRetrieveWorkflow: false,
        },
        isPostLoading: {
          createFormTemplate: false,
        },
      },
    });
    state.data.imageBaseUrl = config.imageBaseUrl;

    onMounted(() => {
      console.log("Hello from Tutorial")
    });
     function redirectToStep7() {
     router.push({
        name: "admin-tutorial-six",
      });
    }

     function redirectToStep9() {
      router.push({
        name: "admin-tutorial-end",
      });
    }
    return {
      state,
      redirectToStep7,
      redirectToStep9,
    };
  },
});
