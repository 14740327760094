
import { defineComponent, onMounted, reactive, computed, watch} from "vue";
import { common } from "@/common/services/Common";
import { ethitransService } from "@/services/EthitransService";
import { useRoute, useRouter } from "vue-router";
// import PaymentHistory from "@/common/PaymentHistory.vue";
import BreadcrumbRequestedInvoice from "@/components/invoice/BreadcrumbRequestedInvoice.vue";
import ErrorList from "@/components/commonDesign/ErrorList.vue";
import SuccessfulModalCustom from "@/components/modal/SuccessfulModalCustom.vue";

export default defineComponent({
  name: "Invoice",
  props: {
    showAddPhase: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    ErrorList,
    SuccessfulModalCustom,
    BreadcrumbRequestedInvoice,
    // PaymentHistory,
  },
  setup() {
    const router = useRouter();
    const routerQuery = useRoute();
    const {
      params: { invoiceId },
    } = useRoute();
    const state = reactive({
      data: {
        invoiceDetails: {} as any,
        breadcrumb: [] as any,
        addItemInInvoice: false,
        isPayActive: false,
        stripeSuccess: false,
        isIndividual: false,
        showSuccesCustomModal: false,
        stripeAccount: false,
        successFulMessage: "",
        stripeUrl: "",
        invoice: {
          total: 0,
          subTotal: 0,
          tax: 0,
          discount: 0,
          status: 1,
        },
      },
      ui: {
        error: {
          hasError: false,
          errorList: {
            getInvoiceByPhaseOrTask: [] as any,
          },
        },
        isLoading: {
          getInvoiceByPhaseOrTask: false,
          stripeAccountCheck: false,
          phaseInvoiceListById: false,
        },
        isPostLoading: {
          generateInvoice: false,
          stripeAccount: false,
          confirmPayment: false,
          saveAsDraft: false,
        },
      },
    });
    // eslint-disable-next-line vue/return-in-computed-property
    const invoiceTotal = computed(() => {
      return state.data.invoice.total;
    });
    // eslint-disable-next-line vue/return-in-computed-property
    const invoiceCalculate = computed(() => {
      if (
        state.data.invoiceDetails.invoice_items &&
        state.data.invoiceDetails.invoice_items.length
      ) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        state.data.invoice.subTotal = 0;
        state.data.invoiceDetails.invoice_items.forEach((invoice: any) => {
          invoice.amount = invoice.cost * invoice.quantity;
          const number = invoice.amount;
          state.data.invoice.subTotal += number;
        });
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        state.data.invoice.total =
          state.data.invoice.subTotal +
          (state.data.invoice.subTotal * state.data.invoice.tax) / 100 -
          state.data.invoice.discount;

        return state.data.invoiceDetails.invoice_items;
      }
    });
    // const invoiceSubtotal = invoiceCalculate.value.reduce((accumulator: any, selected: any)=> {
    //   return accumulator + selected.amount;
    // }, 0)
    // eslint-disable-next-line vue/return-in-computed-property
    const isAuthEdit = computed(() => {
      if (state.data.invoiceDetails && state.data.invoiceDetails.from_role) {
        // 1 Payer
        // 2 Owner
        // 3 Sub Contractor
        // 4 Member/Employee
        if (
          state.data.invoiceDetails.from_role === 2
        ) {
          return false;
        } else if (
          state.data.invoiceDetails.billed_from === state.data.invoiceDetails.user_id
        ) {
          // if (state.data.isIndividual) {
            return true;
          // } else {
          //   return false;
          // }
        }
      }
    });
    function downloadFile() {
      window.open(state.data.invoiceDetails.pdf_url, "_blank");
    }
    function closeSuccessCustomModal() {
      state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
      state.data.stripeSuccess = false;
      $("#SuccessfulModalCustom").modal("hide");
    }
    function isAddItemInInvoice() {
     state.data.addItemInInvoice = false;
    }
    function addItemInInvoice() {
      state.data.addItemInInvoice = true;
      state.data.invoiceDetails.invoice_items.push({
        amount: 0,
        cost: 0,
        quantity: 0,
        title: "",
      });
      setTimeout(isAddItemInInvoice, 2000);
    }
    function removeSelectedItemInInvoice(item: any) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.data.invoiceDetails.invoice_items =
        state.data.invoiceDetails.invoice_items.filter((e: any) => e != item);
    }
    function choosePaymentMode() {
      state.ui.error.errorList.getInvoiceByPhaseOrTask = [];
      state.ui.error.hasError = false;

      if (!state.data.invoiceDetails.uuid) return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        invoice_id: state.data.invoiceDetails.uuid,
        amount: state.data.invoiceDetails.total,
      };
      console.log("Paylaod Check >>> ", payLoad);
      state.ui.isPostLoading.confirmPayment = true;
      ethitransService
        .payInvoiceByStripe(payLoad)
        .then((res: any) => {
          // alert("THEN");
          // emit("logData", res.data.data);
          if (res.data.data.status === 1) {
            console.log("Project Todo Response Check:>>> ", res.data.data.link);
            window.location.replace(`${res.data.data.link}`);
          }
          // logData(res.data.data);
          // hideTaskLogModal();
        })
        .catch((error: any) => {
          // alert("CATCH WHY");
          console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.getInvoiceByPhaseOrTask)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.getInvoiceByPhaseOrTask.push(
                    value[0]
                  );
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.confirmPayment = false;
        });
    }
    function invoiceSuccess() {
      state.data.successFulMessage = "Invoice Generated Successfully.";
      // state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
      if (routerQuery.query.account === "success") {
        router.push({
          name: "admin-billing-invoice",
          params: { invoiceId: invoiceId },
          query: { account: "" },
        });
      }
    }
    function generateInvoice() {
      state.ui.error.errorList.getInvoiceByPhaseOrTask = [];
      state.ui.error.hasError = false;
      let phaseId = "";
      // invoiceCalculate.value.forEach((item: any) => {
      //   delete item.created_at;
      //   delete item.deleted_at;
      //   delete item.id;
      //   delete item.updated_at;
      //   delete item.uuid;
      // });
      if (state.data.invoiceDetails && state.data.invoiceDetails.phase_id) {
        phaseId = state.data.invoiceDetails.phase_id;
      } else {
        phaseId = state.data.invoiceDetails.invoice_phase.phase.uuid;
      }

      if (state.ui.isPostLoading.generateInvoice) return false;
      if (state.ui.isPostLoading.saveAsDraft) return false;
      if (!phaseId) return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        receiver_id: state.data.invoiceDetails.billed_to,
        // eslint-disable-next-line @typescript-eslint/camelcase
        phase_id: phaseId,
        items: invoiceCalculate.value,
        // eslint-disable-next-line @typescript-eslint/camelcase
        sub_total: state.data.invoice.subTotal,
        tax: state.data.invoice.tax,
        discount: state.data.invoice.discount,
        total: state.data.invoice.total,
        status: state.data.invoice.status,
      };
      console.log("ID CHECK  WITH PAYLOAD>>>", payLoad);
      if(state.data.invoice.status === 4){

        state.ui.isPostLoading.saveAsDraft = true;
      } else {

        state.ui.isPostLoading.generateInvoice = true;
      }
      ethitransService
        .phaseGenerateInvoice(payLoad)
        .then((res: any) => {
          console.log(
            "getInvoiceByPhaseOrTask Response Check:>>> ",
            res.data.data
          );
          // eslint-disable-next-line @typescript-eslint/camelcase
          if (res.data.data.invoice.invoice_date) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            res.data.data.invoice.invoice_date = common.localDateAndTime(
              res.data.data.invoice.invoice_date
            );
          }
              // eslint-disable-next-line @typescript-eslint/camelcase
          if(res.data.data.new_items && res.data.data.new_items.length){
            console.log("New Items")
            if( res.data.data.invoice && res.data.data.invoice.invoice_items && res.data.data.invoice.invoice_items.length){
              console.log("invoice items")
              res.data.data.new_items.forEach((item: any)=> {
                item.newAdded = true;
                res.data.data.invoice.invoice_items.push(item);
              })

            }
            
          }

            state.data.invoiceDetails = res.data.data.invoice;
          // if(state.data.invoiceDetails.invoice_items && !state.data.invoiceDetails.invoice_items.length){
          //   addItemInInvoice();
          // }
          // if (
          //   localStorage.getItem("current_user_id") &&
          //   state.data.invoiceDetails.billed_from
          // ) {
          //   const userId: any = localStorage.getItem("current_user_id");
          //   if (
          //     parseInt(userId) !=
          //     parseInt(state.data.invoiceDetails.billed_from)
          //   ) {
          //     state.data.isPayActive = true;
          //   } else {
          //     state.data.isPayActive = false;
          //   }
          // }
          closeSuccessCustomModal();
          console.log("generateInvoice Response Check:>>> ", res.data.data);
          setTimeout(invoiceSuccess, 1000);
        })
        // .then((res: any) => {
        //   closeSuccessCustomModal();
        //   console.log("generateInvoice Response Check:>>> ", res.data.data);
        //   setTimeout(invoiceSuccess, 1000);
        // })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.getInvoiceByPhaseOrTask)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.getInvoiceByPhaseOrTask.push(
                    value[0]
                  );
                }
              }
            });
        })
        .finally(() => {
          if(state.data.invoice.status === 4){

        state.ui.isPostLoading.saveAsDraft = false;
      } else {

        state.ui.isPostLoading.generateInvoice = false;
      }
        });
    }
    function redirectToStripeAccount() {
      state.ui.isPostLoading.stripeAccount = true;
      window.location.replace(`${state.data.stripeUrl}`);
      state.ui.isPostLoading.stripeAccount = false;
    }
    function stripeAccountCheck() {
      // alert("HELLO");
      state.ui.error.errorList.getInvoiceByPhaseOrTask = [];
      state.ui.error.hasError = false;
      if (state.ui.isLoading.stripeAccountCheck) return false;
      if (
        state.ui.error.hasError ||
        state.ui.error.errorList.getInvoiceByPhaseOrTask.length != 0
      )
        return false;
      state.ui.isLoading.stripeAccountCheck = true;
      ethitransService
        .stripeAccountCheck(state.data.invoiceDetails.uuid)
        .then((res: any) => {
          // alert("THEN");
          // emit("logData", res.data.data);
          if (res.data.data.status === 0) {
            state.data.stripeUrl = res.data.data.link;
            console.log("Project Todo Response Check:>>> ", res.data.data);
            state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
            state.data.successFulMessage = "Stripe Account Checked";
          } else if (res.data.data.status === 1) {
            generateInvoice();
          }
          // logData(res.data.data);
          // hideTaskLogModal();
        })
        .catch((error: any) => {
          // alert("CATCH WHY");
          console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.getInvoiceByPhaseOrTask)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.getInvoiceByPhaseOrTask.push(
                    value[0]
                  );
                }
              }
            });
        })
        .finally(() => {
          state.ui.isLoading.stripeAccountCheck = false;
        });
    }
    function getInvoiceByPhaseOrTask() {
      console.log("Choose Payment Method", invoiceId);
      state.ui.error.errorList.getInvoiceByPhaseOrTask = [];
      state.ui.error.hasError = false;

      if (state.ui.isLoading.getInvoiceByPhaseOrTask) return false;
      state.ui.isLoading.getInvoiceByPhaseOrTask = true;
      ethitransService
        .invoiceByPhaseOrTask(invoiceId.toString(), 1)
        .then((res: any) => {
          console.log(
            "getInvoiceByPhaseOrTask Response Check:>>> ",
            res.data.data
          );
          // eslint-disable-next-line @typescript-eslint/camelcase
          if (res.data.data.invoice.invoice_date) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            res.data.data.invoice.invoice_date = common.localDateAndTime(
              res.data.data.invoice.invoice_date
            );
          }
              // eslint-disable-next-line @typescript-eslint/camelcase
          if(res.data.data.new_items && res.data.data.new_items.length){
            console.log("New Items")
            if( res.data.data.invoice && res.data.data.invoice.invoice_items && res.data.data.invoice.invoice_items.length){
              console.log("invoice items")
              res.data.data.new_items.forEach((item: any)=> {
                item.newAdded = true;
                res.data.data.invoice.invoice_items.push(item);
              })

            }
            
          }
          res.data.data.breadcrumb.reverse();

            state.data.invoiceDetails = res.data.data.invoice ? res.data.data.invoice : {};
            state.data.breadcrumb = res.data.data.breadcrumb ? res.data.data.breadcrumb: [];
          if(state.data.invoiceDetails.invoice_items && !state.data.invoiceDetails.invoice_items.length){
            addItemInInvoice();
          }
          if (
            localStorage.getItem("current_user_id") &&
            state.data.invoiceDetails.billed_from
          ) {
            const userId: any = localStorage.getItem("current_user_id");
            console.log("USER ID >>> ", userId)
            if (
              parseInt(userId) !=
              parseInt(state.data.invoiceDetails.billed_from)
            ) {
              state.data.isPayActive = true;
            } else {
              state.data.isPayActive = false;
            }
          }
          if (routerQuery.query.account === "success") {
            state.data.stripeSuccess = true;

            state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
            state.data.successFulMessage = "Stripe Account Setup Completed.";
            // generateInvoice();
          }
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.getInvoiceByPhaseOrTask)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.getInvoiceByPhaseOrTask.push(
                    value[0]
                  );
                }
              }
            });
        })
        .finally(() => {
          state.ui.isLoading.getInvoiceByPhaseOrTask = false;
        });
    }
    function saveAsDraft(){
      state.data.invoice.status = 4;
      generateInvoice();
    }
    watch(
      // () => watchTour,
      () => state.data.addItemInInvoice,
      (newvalue: any, oldvalue: any) => {
        if (newvalue === true) {
          console.log("INVOICE CHANGED >>>", newvalue)
          state.data.invoiceDetails.status = 1;
          // toggleTourGuide();
        }
      }
    );
    onMounted(() => {
      if (routerQuery.query.mode === "1") {
        state.data.isIndividual = true;
      } else {
        state.data.isIndividual = false;
      }
      getInvoiceByPhaseOrTask();
    });
    return {
      state,
      isAddItemInInvoice,
      downloadFile,
      saveAsDraft,
      invoiceTotal,
      redirectToStripeAccount,
      stripeAccountCheck,
      closeSuccessCustomModal,
      choosePaymentMode,
      isAuthEdit,
      invoiceCalculate,
      addItemInInvoice,
      removeSelectedItemInInvoice,
      generateInvoice,
      invoiceSuccess,
    };
  },
});
