
import { useStore } from "vuex";
import {
  defineComponent,
  reactive,
  onMounted,
  ref,
  computed,
  watch,
} from "vue";
import { useRouter } from "vue-router";
import SuccessfulModalCustom from "@/components/modal/SuccessfulModalCustom.vue";
import SignaturePadModal from "@/components/modal/SignaturePadModal.vue";
import SignatureUploadModal from "@/components/modal/SignatureUploadModal.vue";
import { config } from "@/Config";
import { common } from "@/common/services/Common";
import { ethitransService } from "@/services/EthitransService";
import { validationService } from "@/common/ValidationService";
import ErrorList from "@/components/commonDesign/ErrorList.vue";
import Multiselect from "@vueform/multiselect";
import VuePictureCropper, { cropper } from "vue-picture-cropper";
import Editor from "@/components/mention/Editor.vue";
import AppPhoneNumberInput from "@/components/commonDesign/AppPhoneNumberInput.vue"



export default defineComponent({
  name: "Profile",
  components: {
    Multiselect,
    Editor,
    AppPhoneNumberInput,
    // 'vue-phone-number-input' :VuePhoneNumberInput,
    // VueTelInput,
    // intlTelInput,
    // CommunicationCard,
    // MemberCard,
    // EmptyData,
    ErrorList,
    SuccessfulModalCustom,
    VuePictureCropper,
    SignaturePadModal,
    SignatureUploadModal
  },
  setup(props, { emit }) {
    const store = useStore();
    const phone = ref("");
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const moment = require("moment");
    const router = useRouter();
    const enableEnterKey = ref(false);
    const state = reactive({
      countryCodeList: [] as any,
      data: {
        showCopyMessage: false,
        showSuccesCustomModal: false,
        showSignaturePad: false,
        showSignatureUpload: false,
        uploadSignatureFile: "" as any,
        cropImage: "",
        signature: "" as any,
        timeCount: 130,
        newUploadImge: "" as any,
        SuccessFulMessage: "",
        uploadImageData: {} as any,
        imageBaseUrl: "",
        showEditProfile: false,
        userList: [] as any,
        occupation: {
          value: "" as any,
          options: [],
        } as any,
        phone: {
          showStatus: false,
          countryCode: "",
          collection: [] as any,
          number: "",
          code: "+1",
          mobileCode: "+1",
          otp: "",
          isVerify: false,
        },
        number: [] as any,
        finalNumber: "",
        userdetails: {} as any,
        userdetailsTemp: {} as any,
        editUserDetails: {
          whiteList: ["US"],
          occupation: "",
          otherOccupation: "",
          firstName: "",
          middleName: "",
          lastName: "",
          emailAddress: "",
          contactNumber: "",
          mobileNumber: "",
          gender: "",
          dob: "",
          avatarImage: "",
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          zipCode: "",
          country: "",
          smsNotification: "",
          companyName: "",
          companyAddress: "",
          logo: {} as any,
          acknowledgement: "",
          legalStatement: "",
          companyPhoneCode: "",
          companyPhoneNumber: "",
          companyEmail: "",
        } as any,
        error: {
          companyEroor: "",
          hasError: false,
          erorList: [] as any,
        },
      },
      ui: {
        inputOtp: false,
        mobileNumberRequired: true,
        sendOtpButton: true,
        verifyButton: true,
        isLoading: {
          loadProfileDetails: false,
          masterOccupationList: false,
        },
        isPostLoading: {
          saveEditedProfile: false,
        },
      },
    });
    function redirectBack() {
      router.go(-1)
    }
    function readLogo(file: any) {
      const data = {} as any;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
        const extensionLower = extension.toLowerCase();
        data.file = e.target.result;
        data.filename = file.name.replaceAll(`.${extension}`, "");
        data.extension = extensionLower;
        data.isuploded = false;
        data.size = common.formatBytes(parseInt(file.size));
        state.data.editUserDetails.logo = data;
      };
      reader.readAsDataURL(file);
    }


    function uploadLogo(event: any) {
      const files = event.target.files || event.dataTransfer.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        // console.log("FILE CHECK >>>", file);
        // if(file.type)
        const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
        const extensionLower = extension.toLowerCase();
        readLogo(file);
      }
    }

    function setLocalStorageCompanyProfile() {
      const userInfo: any = localStorage.getItem("current_user_info");
      userInfo.company = {
        acknowledgement: state.data.editUserDetails.acknowledgement ? state.data.editUserDetails.acknowledgement : "",
        // eslint-disable-next-line @typescript-eslint/camelcase
        company_address: state.data.editUserDetails.companyAddress ? state.data.editUserDetails.companyAddress : "",
        // eslint-disable-next-line @typescript-eslint/camelcase
        company_email: state.data.editUserDetails.companyEmail ? state.data.editUserDetails.companyEmail : "",
        // eslint-disable-next-line @typescript-eslint/camelcase
        company_name: state.data.editUserDetails.companyName ? state.data.editUserDetails.companyName : "",
        // eslint-disable-next-line @typescript-eslint/camelcase
        company_phone_number: state.data.editUserDetails.companyPhoneNumber ? state.data.editUserDetails.companyPhoneNumber : "",
        // eslint-disable-next-line @typescript-eslint/camelcase
        legal_statement: state.data.editUserDetails.legalStatement ? state.data.editUserDetails.legalStatement : "",
        // logo: state.data.companyInfo.logo? state.data.companyInfo.logo: "",
      };
      localStorage.setItem("current_user_info", userInfo);
      // localStorage.setItem("current_user_info", res.data.data.full_name);
    }

    function validateEmail() {
      // Regular expression for basic email validation
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      state.data.error.hasError = false;

      if (!state.data.editUserDetails.companyEmail.match(emailPattern)) {
        state.data.error.companyEroor = 'Please enter a valid email address';
        state.data.error.hasError = true;

      } else {
        state.data.error.companyEroor = '';
      }
    }

    function enterSetting(data: boolean) {
      // console.log("enter settings");
      if (data === true) {
        enableEnterKey.value = true;
      } else {
        enableEnterKey.value = false;
      }
      console.log("enter settings", enableEnterKey.value);
    }
    function showTelInput() {
      state.data.phone.showStatus = !state.data.phone.showStatus;
    }
    const countryCode = computed(() => {
      return state.data.phone.countryCode;
    });
    function onClose(event: any) {
      // console.log("Check on CLOSE", event)
    }
    function validatePhoneNumber(phone: string) {
      // link https://regex101.com/r/QXAhGV/1
      // return /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/.test(phone);
      // return /^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/.test(phone);
      return /(^\+[0-9]{2}|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9\-\s]{11}$)/.test(
        phone
      );
      // return /(^\+[2-9][0-9][0-9]|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9\-\s]{11}$)/.test(phone);
    }
    function countryChanged(country: any) {
      // console.log("Country the input", country)
      if (country.dialCode) {
        // console.log("Country the input", country.dialCode)
        state.data.phone.countryCode = country.dialCode;
        // console.log("Country FINAL CHECK >>>", state.data.phone.countryCode )
      }
    }
    function onInput(event: any) {
      // console.log("EVENT DATA >>>> ", event.data)
      // if(!state.data.editUserDetails.contactNumber){
      // if (event.data != undefined) {
      //   state.data.editUserDetails.contactNumber += event.data;
      //   state.data.phone.collection.push(event.data);
      // }
      // if (event.data === null) {
      //   if (state.data.phone.collection) {
      //     state.data.phone.collection.pop();
      //   }
      // }
      // // console.log("CHECK ARRAY >>> ", state.data.phone.collection)
      // state.data.phone.number = state.data.phone.collection.toString();
      // state.data.phone.number = state.data.phone.number.replace(/,/g, "");
      // console.log("CHECK NUMBER STRING >>> ", state.data.phone.number )
      // }
      // console.log("INPUT ARRAY", state.data.phone.collection)
      // let string ="";
      // string += event.data
      // if(state.data.editUserDetails.contactNumber){
      //   // console.log("FINAL UNEDIT", state.data.editUserDetails.contactNumber.replace(/null/g, ''));
      //   state.data.phone.number =  state.data.editUserDetails.contactNumber.replace(/null/g, '')
      //   if(state.data.phone.number && event.data === null ){
      //     // state.data.phone.number.slice(0, -1)
      //     state.data.phone.number.substring(0, state.data.phone.number.length - 1)
      //   }
      // }
      // console.log("FINAL NUMBER CHECK", state.data.phone.number);
    }
    function cancelEditProfile() {
      state.data.showEditProfile = !state.data.showEditProfile;
      state.data.editUserDetails.firstName = "";
      state.data.editUserDetails.middleName = "";
      state.data.editUserDetails.lastName = "";
      state.data.editUserDetails.contactNumber = "";
      state.data.editUserDetails.mobileNumber = "";
      state.data.editUserDetails.emailAddress = "";
      state.data.editUserDetails.gender = "";
      state.data.editUserDetails.dob = "";
      state.data.editUserDetails.addressLine1 = "";
      state.data.editUserDetails.addressLine2 = "";
      state.data.editUserDetails.city = "";
      state.data.editUserDetails.state = "";
      state.data.editUserDetails.country = "";
      state.data.editUserDetails.zipCode = "";
      state.data.occupation.value = "";
      state.data.editUserDetails.otherOccupation = "";
      state.data.phone.otp = "";
    }
    function editProfileMapping() {
      state.data.editUserDetails.logo.file =
        state.data.userdetailsTemp.image;
      state.data.editUserDetails.firstName =
        state.data.userdetailsTemp.first_name;
      state.data.editUserDetails.middleName =
        state.data.userdetailsTemp.middle_name;
      state.data.editUserDetails.lastName =
        state.data.userdetailsTemp.last_name;
      state.data.editUserDetails.contactNumber =
        state.data.userdetailsTemp.contact_number;
      state.data.editUserDetails.mobileNumber =
        state.data.userdetailsTemp.mobile_number;
      state.data.editUserDetails.emailAddress =
        state.data.userdetailsTemp.email;
      state.data.editUserDetails.smsNotification =
        state.data.userdetailsTemp.sms_notification;
      state.data.editUserDetails.gender = state.data.userdetailsTemp.gender;
      state.data.editUserDetails.dob = state.data.userdetailsTemp.dob;
      state.data.editUserDetails.addressLine1 =
        state.data.userdetailsTemp.address_line1;
      state.data.editUserDetails.addressLine2 =
        state.data.userdetailsTemp.address_line2;
      state.data.editUserDetails.city = state.data.userdetailsTemp.city;
      state.data.editUserDetails.state = state.data.userdetailsTemp.state;
      state.data.editUserDetails.country = state.data.userdetailsTemp.country;
      state.data.editUserDetails.zipCode = state.data.userdetailsTemp.zip;
      state.data.editUserDetails.companyName =
        state.data.userdetailsTemp.company.company_name;
      state.data.editUserDetails.companyAddress =
        state.data.userdetailsTemp.company.company_address;
      state.data.editUserDetails.acknowledgement =
        state.data.userdetailsTemp.company.acknowledgement;
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.data.editUserDetails.legalStatement =
        state.data.userdetailsTemp.company.legal_statement;
      // state.data.editUserDetails.companyPhoneCode = state.data.userdetailsTemp.zip;
      state.data.editUserDetails.companyPhoneNumber =
        state.data.userdetailsTemp.company.company_phone_number;
      state.data.editUserDetails.companyEmail =
        state.data.userdetailsTemp.company.company_email;
      state.data.phone.isVerify = state.data.userdetailsTemp.is_mobile_verified;
      state.data.signature = state.data.userdetailsTemp.signature;

      state.data.occupation.options.forEach((item: any) => {
        // console.log("Occupation >>> ", item);
        // if(item != 'Other'){
        //   console.log("Occupation IN>>> ", item)
        //   state.data.occupation.value = item;
        // }
        // else {
        //   state.data.occupation.value = item;
        // }
      });
    }

    function validateEditProfile() {
      // state.data.showEditProfile =!state.data.showEditProfile;
      // console.log("Validate Edit Profile");
      if (!state.data.editUserDetails.firstName) {
        state.data.error.erorList.push("First Name is required.");
      }
      if (!state.data.editUserDetails.lastName) {
        state.data.error.erorList.push("Last Name is required.");
      }
      // if (!state.data.editUserDetails.dob) {
      //   state.data.error.erorList.push("Date of Birth is required.");
      // }
      if (!state.data.editUserDetails.city) {
        state.data.error.erorList.push("City is required.");
      }
      if (!state.data.editUserDetails.addressLine1) {
        state.data.error.erorList.push("Adddress Line 1 is required.");
      }
      if (!state.data.editUserDetails.contactNumber) {
        state.data.error.erorList.push("Contact Number is required.");
      }
      //   if (!state.data.phone.isVerify) {
      //   state.data.error.erorList.push("Plese verify your contact number.");
      // }

      if (
        state.data.editUserDetails.mobileNumber &&
        !state.data.phone.isVerify
      ) {
        state.data.error.erorList.push("Mobile  verification is required.");
      }

      if (
        state.data.editUserDetails.mobileNumber &&
        !state.data.phone.isVerify &&
        !state.data.phone.otp
      ) {
        state.data.error.erorList.push("please enter OTP .");
      }
      if (!state.data.editUserDetails.country) {
        state.data.error.erorList.push("Country is required.");
      }
      // if (!state.data.editUserDetails.gender) {
      //   state.data.error.erorList.push("Gender is required.");
      // }
      if (!state.data.editUserDetails.state) {
        state.data.error.erorList.push("State is required.");
      }
      if (!state.data.editUserDetails.zipCode) {
        state.data.error.erorList.push("Zip Code is required.");
      }
      if (!state.data.error.erorList && !state.data.error.erorList.length) {
        state.data.error.hasError = false;
      } else if (state.data.error.erorList.length != 0) {
        state.data.error.hasError = true;
      }
    }
    function checkOther() {
      if (state.data.occupation.value === "Other") {
        state.data.editUserDetails.otherOccupation = "";
      }
    }
    function masterOccupationList() {
      state.ui.isLoading.masterOccupationList = true;
      ethitransService
        .masterOccupationList()
        .then((res: any) => {
          // console.log("masterOccupationList Check:>>> ", res.data.data);
          res.data.data.forEach((data: any) => {
            state.data.occupation.options.push(data.name);
          });
          state.data.occupation.options.push("Other");
          state.data.occupation.options.forEach((item: any) => {
            if (state.data.userdetailsTemp.occupation === item) {
              console.log("Occupation >>> ", item);
              state.data.occupation.value = item;
            } else {
              // state.data.occupation.value = 'Other'
              state.data.editUserDetails.otherOccupation =
                state.data.userdetailsTemp.occupation;
            }
            // console.log("User OCC >> ", state.data.userdetailsTemp.occupation)
          });
          // state.data.occupation.options = res.data.data ? res.data.data : [];
          // state.data.userdetails = res.data.data ? res.data.data : {};
        })
        .catch((error: any) => {
          // common
          //   .sdCatchErr(error, state.errorList)
          //   .then((res: any) => {
          //     state.isErrorStatus = true;
          //     // console.log("CHECK for Error >>>", res)
          //     if (res) {
          //       if (res.error) {
          //         console.log("CHECK for Error >>>", res.error[0])
          //         // state.errorList.push(res.email[0]);
          //         // state.errorList = [...state.errorList]
          //       }
          //     }
          //   });
        })
        .finally(() => {
          state.ui.isLoading.masterOccupationList = false;
        });
    }
    function showEditProfile() {
      masterOccupationList();
      state.data.showEditProfile = !state.data.showEditProfile;
      state.data.userdetailsTemp = JSON.parse(
        JSON.stringify(state.data.userdetails)
      );
      editProfileMapping();
    }
    function saveEditedProfile() {
      // console.log("FINAL NUMBER CHECK >>>>>>", `+${state.data.phone.countryCode}${state.data.phone.number}`)
      state.data.error.erorList = [];
      state.data.error.hasError = false;
      validateEditProfile();
      if (
        state.ui.isPostLoading.saveEditedProfile ||
        state.data.error.erorList.length ||
        state.data.error.hasError
      )
        return false;
      let selectedOccupation = "";
      if (state.data.occupation.value != "Other") {
        selectedOccupation = state.data.occupation.value;
      } else {
        selectedOccupation = state.data.editUserDetails.otherOccupation;
      }
      const payLoad = {
        logo: state.data.editUserDetails.logo,
        // eslint-disable-next-line @typescript-eslint/camelcase
        company_name: state.data.editUserDetails.companyName,
        // eslint-disable-next-line @typescript-eslint/camelcase
        company_address: state.data.editUserDetails.companyAddress,
        // eslint-disable-next-line @typescript-eslint/camelcase
        acknowledgement: state.data.editUserDetails.acknowledgement,
        // eslint-disable-next-line @typescript-eslint/camelcase
        legal_statement: state.data.editUserDetails.legalStatement,
        // eslint-disable-next-line @typescript-eslint/camelcase
        company_phone_number: state.data.editUserDetails.companyPhoneNumber,
        // eslint-disable-next-line @typescript-eslint/camelcase
        company_email: state.data.editUserDetails.companyEmail,
        // eslint-disable-next-line @typescript-eslint/camelcase
        first_name: state.data.editUserDetails.firstName,
        // eslint-disable-next-line @typescript-eslint/camelcase
        middle_name: state.data.editUserDetails.middleName,
        // eslint-disable-next-line @typescript-eslint/camelcase
        last_name: state.data.editUserDetails.lastName,
        // eslint-disable-next-line @typescript-eslint/camelcase
        contact_number: state.data.editUserDetails.contactNumber,
        // eslint-disable-next-line @typescript-eslint/camelcase
        mobile_number: state.data.editUserDetails.mobileNumber,
        // eslint-disable-next-line @typescript-eslint/camelcase
        dial_code_mobile: state.data.phone.mobileCode,
        // eslint-disable-next-line @typescript-eslint/camelcase
        dial_code: state.data.phone.code,
        // eslint-disable-next-line @typescript-eslint/camelcase
        is_mobile_verified: state.data.editUserDetails.mobileNumber ? 1 : 0,
        // eslint-disable-next-line @typescript-eslint/camelcase
        sms_notification: state.data.editUserDetails.mobileNumber ? 1 : 0,
        // contact_number: state.data.phone.number,
        // gender: state.data.editUserDetails.gender,
        // dob: state.data.editUserDetails.dob,
        occupation: selectedOccupation,
        // eslint-disable-next-line @typescript-eslint/camelcase
        address_line1: state.data.editUserDetails.addressLine1,
        // eslint-disable-next-line @typescript-eslint/camelcase
        address_line2: state.data.editUserDetails.addressLine2,
        city: state.data.editUserDetails.city,
        state: state.data.editUserDetails.state,
        country: state.data.editUserDetails.country,
        zip: state.data.editUserDetails.zipCode,
      };
      console.log("Check Payload", payLoad);
      state.ui.isPostLoading.saveEditedProfile = true;
      ethitransService
        .updateUserProfileDetails(payLoad)
        .then((res: any) => {
          state.data.userdetails = res.data.data ? res.data.data : {};
          state.data.showEditProfile = false;
          localStorage.setItem(
            "current_user_info",
            JSON.stringify(res.data.data)
          );
          // showEditProfile();
        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.data.error.erorList)
            .then((res: any) => {
              state.data.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.data.error.erorList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.saveEditedProfile = false;
        });
    }
    function loadProfileDetails() {
      state.ui.isLoading.loadProfileDetails = true;
      ethitransService
        .userProfileDetails()
        .then((res: any) => {
          state.data.userdetails = {};
          // console.log("Project List Response Check:>>> ", res.data.data);
          state.data.userdetails = res.data.data ? res.data.data : {};
          state.data.newUploadImge = res.data.data.image
            ? res.data.data.image
            : `${state.data.imageBaseUrl}img/no-user.jpg`;
          state.data.phone.code = res.data.data.dial_code
            ? res.data.data.dial_code
            : "+1";
          state.data.phone.mobileCode = res.data.data.dial_code_mobile
            ? res.data.data.dial_code_mobile
            : "+1";
          state.data.phone.isVerify = res.data.data.is_mobile_verified;
        })
        .catch((error: any) => {
          // common
          //   .sdCatchErr(error, state.errorList)
          //   .then((res: any) => {
          //     state.isErrorStatus = true;
          //     // console.log("CHECK for Error >>>", res)
          //     if (res) {
          //       if (res.error) {
          //         console.log("CHECK for Error >>>", res.error[0])
          //         // state.errorList.push(res.email[0]);
          //         // state.errorList = [...state.errorList]
          //       }
          //     }
          //   });
        })
        .finally(() => {
          state.ui.isLoading.loadProfileDetails = false;
        });
    }
    function getCountryCodeList() {
      console.log("getprojectList WOWO:>>>");
      const payLoad = {};
      ethitransService
        .getCountryCodeList(payLoad)
        .then((res: any) => {
          console.log("getprojectList Check:>>> ", res.data.data);
          state.countryCodeList = res.data.data ? res.data.data : [];
          // state.selectedFormForEdit = res.data ? res.data : {};
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          // state.ui.postLoading.getprojectList = false;
        });
    }
    function countDownTimer() {
      const interval = setInterval(() => {
        if (state.data.timeCount === 0) {
          state.ui.sendOtpButton = true;
          state.ui.inputOtp = false;
          clearInterval(interval);
          state.data.timeCount = 130;

          // state.data.phone.code = state.data.phone.code;
          // getCountryCodeList();
        } else {
          state.data.timeCount--;
        }
      }, 1000);
    }
    function validateForm() {
      state.data.error.erorList = [];
      state.data.error.hasError = false;

      if (!state.data.editUserDetails.mobileNumber) {
        state.data.error.erorList.push("Mobile Number is required.");
      } else if (state.data.editUserDetails.mobileNumber.length != 11) {
        state.data.error.erorList.push("Invalid Mobile Number.");
      }

      if (!state.data.error.erorList && !state.data.error.erorList.length) {
        state.data.error.hasError = false;
      } else if (state.data.error.erorList.length != 0) {
        state.data.error.hasError = true;
      }
    }

    function sendOtp() {
      validateForm()
      if (state.data.error.erorList && state.data.error.erorList.length) return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        client: state.data.editUserDetails.mobileNumber,
      };
      // const payLoad = {
      //   // eslint-disable-next-line @typescript-eslint/camelcase
      //   mobile_number: state.data.editUserDetails.mobileNumber,
      //   // eslint-disable-next-line @typescript-eslint/camelcase
      //   dial_code_mobile: state.data.phone.mobileCode,
      // };
      state.ui.verifyButton = false;
      ethitransService
        .sendOtp(payLoad)
        .then((res: any) => {
          state.countryCodeList = res.data.data ? res.data.data : [];
          // state.data.phone.code = res.data.data.dial_code;
          state.ui.inputOtp = true;
          state.ui.sendOtpButton = false;
          getCountryCodeList();
          countDownTimer();
          // interval;
        })
        .catch((error: any) => {
          // console.log("hello",JSON.stringify(err));
          // console.log("erro message om",JSON.stringify(err));
          common
            .sdCatchErr(error, state.data.error.erorList)
            .then((res: any) => {
              state.data.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.data.error.erorList.push(value[0]);
                }
              }
            });
          //    state.data.error.erorList = [];
          //     state.data.error.hasError = true;
          //  state.data.error.erorList.push("pleae check your mobile number or country code.");
        })

        .finally(() => {
          state.ui.verifyButton = true;
        });
    }

    function verifyMobile() {
      console.log("CHECK")
      // state.ui.error.hasError = false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        client: state.data.editUserDetails.mobileNumber,
        otp: state.data.phone.otp,
      };
      ethitransService
        .verifyOtpCode(payLoad)
        .then((res: any) => {
          state.countryCodeList = res.data.data ? res.data.data : [];
          // state.data.phone.code = state.data.phone.code;
          getCountryCodeList();
          if (res.data.data.is_verify == true) {
            state.data.phone.isVerify = true;
            state.ui.inputOtp = false;
            state.ui.sendOtpButton = true;
            state.data.error.hasError = false;
            state.data.phone.otp = "";
          } else {
            state.data.phone.isVerify = false;
            state.data.error.erorList = [];
            state.data.error.hasError = true;
            state.data.error.erorList.push("OTP didnot match..");
          }
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          // state.ui.postLoading.getprojectList = false;
        });
    }
    function handlePaste(event: any) {
        // Access the pasted data
        const pastedData = (event.clipboardData).getData('text');
        
        // Check if the pasted data is numeric
        if (!/^\d+$/.test(pastedData)) {
            // If not numeric, prevent the paste
            event.preventDefault();
            // alert('Only numeric values are allowed.');
            
        } else {
            // Optionally process the pasted data
            console.log('Pasted data:', pastedData);
            state.data.phone.otp = pastedData;
            verifyMobile();
        }
    }

    function checkInputValue() {
      // alert('hi');
      console.log("Checking input value", state.data.phone.otp.length);
      if (state.data.phone.otp && state.data.phone.otp.length == 5) {
        verifyMobile();
      }
    }

    function checkMobile() {
      // state.ui.error.hasError = false;
      const payLoad = {
        // // eslint-disable-next-line @typescript-eslint/camelcase
        // mobile_number: state.data.editUserDetails.mobileNumber,
        // // eslint-disable-next-line @typescript-eslint/camelcase
        // dial_code_mobile: state.data.phone.mobileCode,
        // eslint-disable-next-line @typescript-eslint/camelcase
        client: state.data.editUserDetails.mobileNumber,
      };
      ethitransService
        .checkMobile(payLoad)
        .then((res: any) => {
          // state.countryCodeList = res.data.data ? res.data.data : [];
          // state.data.phone.code = state.data.phone.code;
          // getCountryCodeList();
          if (res.data.data.is_mobile_verified == true) {
            state.data.phone.isVerify = true;
            state.ui.inputOtp = false;

            //  state.ui.error.hasError = false;
          } else {
            state.data.phone.isVerify = false;
            state.data.error.erorList = [];
            state.data.error.hasError = false;
            //  state.data.error.erorList.push("OTP not match.");
          }
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          // state.ui.postLoading.getprojectList = false;
        });
    }

    function showUploadImageModel() {
      $("#updateUpload").modal("show");
    }

    function hideShowUploadImageModel() {
      state.data.cropImage = "";
      $("#profileUpdate").val("");
      state.data.newUploadImge = "";
      $("#updateUpload").modal("hide");
      loadProfileDetails();
    }

    function readImage(file: any) {
      const data = {} as any;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
        const extensionLower = extension.toLowerCase();
        data.base64 = e.target.result;
        data.duplicateFile = false; // data.fileName = file.name;
        data.fileName = file.name.replaceAll(`.${extension}`, "");
        data.extension = extensionLower;
        data.isuploded = false;
        data.tag = [] as any;
        data.size = common.formatBytes(parseInt(file.size));
        state.data.uploadImageData = data;
        state.data.newUploadImge = data.base64;
        state.data.cropImage = data.base64;
      };
      reader.readAsDataURL(file);
    }

    function onDocumentChange(event: any) {
      const files = event.target.files || event.dataTransfer.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        // console.log("FILE CHECK >>>", file);
        // if(file.type)
        const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
        const extensionLower = extension.toLowerCase();
        readImage(file);
      }
    }

    function closeSuccessCustomModal() {
      state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
      $("#SuccessfulModalCustom").modal("hide");
    }
    function uploadProfileImage() {
      if (!state.data.uploadImageData) return false;
      const payLoad = {
        filename: state.data.uploadImageData.fileName,
        extension: state.data.uploadImageData.extension,
        file: cropper?.getDataURL(),
      };
      ethitransService
        .profilePicUpload(payLoad)
        .then((res: any) => {
          hideShowUploadImageModel();
          state.data.userdetails.image = "";
          state.data.userdetails.image = cropper?.getDataURL();
          localStorage.setItem("current_user_image", res.data.data.image);
          store.dispatch("saveProfileImage", cropper?.getDataURL());
          state.data.newUploadImge = cropper?.getDataURL();
          state.data.SuccessFulMessage = "Profile Image Update Successfully.";
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          // state.ui.isPostLoading.uploadAttachments = false;
        });
    }


    function checkMobileNumber() {
      // if (
      //   validateLength.value
      // ) {
          if (
          state.data.editUserDetails.mobileNumber &&
          state.data.editUserDetails.mobileNumber.length >= 11
        ) {
        checkMobile();
      }
      if (
        (state.data.editUserDetails.mobileNumber &&
          state.data.editUserDetails.mobileNumber.length == 0) ||
        (state.data.editUserDetails.mobileNumber &&
          state.data.editUserDetails.mobileNumber.length < 10)
      ) {
        state.ui.mobileNumberRequired = true;
      }
      if (
        state.data.editUserDetails.mobileNumber &&
        state.data.editUserDetails.mobileNumber.length >= 10
      ) {
        state.ui.mobileNumberRequired = false;
      }
    }

    watch(
      () => state.data.editUserDetails.mobileNumber,
      () => {
        checkMobileNumber();
      }
    );
    // watch(
    //   () => state.data.phone.otp,
    //   () => {
    //     checkInputValue()
    //   }
    // );

    function dataEnter(data: any) {
      // state.phoneValid = data.valid
      if (data.valid) {
        console.log("DATA CHECK", data.number);
        state.data.phone.number = data.number;
      }
    }

    function copySharableLink(inputId: any) {
      const generatedUrl = config.logOutUrl + `#/signup?ref=${inputId}`;
      const input = document.createElement('input');
      input.value = generatedUrl;
      document.body.appendChild(input);
      input.select();
      document.execCommand('copy');
      document.body.removeChild(input);
      state.data.showCopyMessage = true;

      // Hide the message after 3 seconds
      setTimeout(() => {
        state.data.showCopyMessage = false;
      }, 5000);
    }

    function showSignaturePad() {
      state.data.showSignaturePad = !state.data.showSignaturePad;
    }

    function hideSignaturePad() {
      state.data.showSignaturePad = !state.data.showSignaturePad;
      $("#signaturePadModal").modal("hide");
    }

    function hideSignatureUpload() {
      state.data.showSignatureUpload = !state.data.showSignatureUpload;
      $("#signatureUploadModal").modal("hide");
    }
    function uploadSignature(data: any) {
      state.data.signature = data;
      loadProfileDetails();
    }

    function uploadSignatureFile(event: any) {
      state.data.showSignatureUpload = true;
      state.data.uploadSignatureFile = event.target.files || event.dataTransfer.files;
    }

    onMounted(() => {
      // const input = document.querySelector("#phone");
      // intlTelInput(input, {
      //     // any initialisation options go here
      // });
      state.data.imageBaseUrl = config.imageBaseUrl;
      loadProfileDetails();
      getCountryCodeList();
    });

    return {
      state,
      handlePaste,
      redirectBack,
      readLogo,
      uploadLogo,
      setLocalStorageCompanyProfile,
      phone,
      checkOther,
      dataEnter,
      showTelInput,
      countryCode,
      countryChanged,
      onClose,
      loadProfileDetails,
      showEditProfile,
      saveEditedProfile,
      editProfileMapping,
      cancelEditProfile,
      onInput,
      masterOccupationList,
      getCountryCodeList,
      sendOtp,
      verifyMobile,
      checkInputValue,
      countDownTimer,
      checkMobile,
      checkMobileNumber,
      validatePhoneNumber,
      readImage,
      onDocumentChange,
      showUploadImageModel,
      hideShowUploadImageModel,
      uploadProfileImage,
      closeSuccessCustomModal,
      enterSetting,
      validateEmail,
      copySharableLink,
      showSignaturePad,
      hideSignaturePad,
      uploadSignature,
      uploadSignatureFile,
      hideSignatureUpload
    };
  },
});
