
import { defineComponent, reactive, onMounted, computed, watch } from "vue";
import { ethitransService } from "@/services/EthitransService";
import SuccessfulModalCustom from "@/components/modal/SuccessfulModalCustom.vue";
import { common } from "@/common/services/Common";
import { config } from "@/Config";
import EmptyData from "@/components/commonDesign/EmptyData.vue";
import { useRouter } from "vue-router";
import { each } from "jquery";
import NotifyMailSendModel from "@/components/modal/NotifyMailSendModel.vue";
import YouTube from 'vue3-youtube'

// import FormView from "@/components/cards/FormView.vue";
export default defineComponent({
    name: "UserActivity",
    components: {
        EmptyData,
        SuccessfulModalCustom,
        NotifyMailSendModel,
        YouTube
    },
    setup() {
        const router = useRouter();
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const moment = require("moment");
        const state = reactive({
            tutorialAllList: [] as any,
            checkUser: [] as any,
            totalUser: "" as any,
            onlineUser: "" as any,
            isCheckedAll: false,
            isExport: false,
            loginFrom: "" as any,
            loginTo: "" as any,
            createdFrom: "" as any,
            createdTo: "" as any,
            totalPdf: 0,
            totalVideo: 0,
            totalFaq: 0,
            selectedData: {} as any,
            filter: {
                selected: { name: "All" } as any,
                type: { name: "All" } as any,
            },
            tutorialList: [
                {
                    name: "All",
                },
                {
                    name: "FAQ",
                },
                {
                    name: "Video",
                },
                {
                    name: "PDF",
                },
            ],
            sortList: [
                {
                    name: "All",
                },
                {
                    name: "Enable",
                },
                {
                    name: "Disable",
                },
            ],
            masks: {
                input: "MM-DD-YYYY",
            },
            url: `${config.apiBaseUrl}tutorial/export`,
            countryCodeList: [] as any,
            data: {
                tuorialType: "",
                successMessage: "",
                question: "",
                answer: "",
                videoUrl: "",
                showSuccesCustomModal: false,
                mailData: {} as any,
                selectId: '' as any,
                showNotificationMailModel: false,
                selectedUser: {} as any,
                selectedEditUser: {
                    phoneDialcode: "+1",
                    mobileDialCode: "+1",
                    phoneNumber: "" as any,
                    mobileNumber: "" as any,
                } as any,
                imageBaseUrl: "",
                title: "Tutorial",
                heading: "No tutorial found .",
                searchUser: "",
                paging: {
                    clientDocumentList: {
                        perPage: 10,
                        totalDocs: 0,
                        totalPages: 0,
                        currentPage: 1,
                    },
                },
            },
            ui: {
                isLoading: {},
                isPostLoading: {
                    getTutorialList: false,
                    saveEditUser: false,
                    deleteTutorial: false,
                    updateStatusTutorial: false
                },
                errors: {
                    errorList: {
                        saveEditUser: [] as any,
                        errorList: [] as any,
                    },
                    hasError: false,
                },
            },

            newSearchData: {
                loginFromError: false,
                loginToError: false,
                createdFromError: false,
                createdToError: false,
                loginToDisable: true,
                createdToDisable: true,
            },
            error: {
                loginFromError: "" as any,
                loginToError: "" as any,
                createdFromError: "" as any,
                createdToError: "" as any,
            },
        });

        function serachValidation() {
            console.log("date", moment(state.loginFrom) > moment(state.loginTo));

            state.newSearchData.loginFromError = false;
            state.newSearchData.loginToError = false;
            state.newSearchData.createdFromError = false;
            state.newSearchData.createdToError = false;
            if (state.loginFrom != "" && state.loginTo == "") {
                state.error.loginToError = "Login to date is required";
                state.newSearchData.loginToError = true;
            }

            if (state.loginFrom == "" && state.loginTo != "") {
                state.error.loginFromError = "Login from date is required";
                state.newSearchData.loginFromError = true;
            }

            if (moment(state.loginFrom) > moment(state.loginTo)) {
                state.error.loginFromError =
                    "Login from date is less then login to date";
                state.newSearchData.loginFromError = true;
            }
            if (state.createdFrom != "" && state.createdTo == "") {
                state.error.createdToError = "Join to date is required";
                state.newSearchData.createdToError = true;
            }

            if (state.createdFrom == "" && state.createdTo != "") {
                state.error.createdFromError = "Join from date is required";
                state.newSearchData.createdFromError = true;
            }

            if (moment(state.createdFrom) > moment(state.createdTo)) {
                state.error.createdFromError =
                    "Join from date is less then Join to date";
                state.newSearchData.createdFromError = true;
            }
        }

        function allTutorial() {
            state.ui.isPostLoading.getTutorialList = true;
            // console.log("HELLO GROM ROLE LIST FROM PROJET DETAILS");
            serachValidation();
            const payLoad = {
                sort: state.filter.selected.name,
                type: state.filter.type.name,

            };
            ethitransService
                .getAllTutorial(payLoad)
                .then((res: any) => {
                    state.tutorialAllList = res.data.data.list ? res.data.data.list : [];
                    state.totalFaq = res.data.data.faq;
                    state.totalPdf = res.data.data.pdf;
                    state.totalVideo = res.data.data.video;

                })
                .catch((error: any) => {
                    console.log(error);
                })
                .finally(() => {
                    state.ui.isPostLoading.getTutorialList = false;
                });
        }

        function closeSuccessCustomModal() {
            state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
            $("#SuccessfulModalCustom").modal("hide");
        }

        watch(
            () => [state.loginTo, state.loginFrom],
            (first, second) => {
                if (first.length) {
                    state.newSearchData.loginToDisable = false;
                }
                allTutorial();
            }
        );

        watch(
            () => [state.createdTo, state.createdFrom],
            (first, second) => {
                if (first.length) {
                    state.newSearchData.createdToDisable = false;
                }
                allTutorial();
            }
        );

        function clearSearch() {
            (state.filter.type = { name: "All" } as any);
            (state.filter.selected = { name: "All" } as any);
            allTutorial();
        }

        function redireToUserLog(tutorialId: any) {
            router.push({
                name: "admin-tutorial-log",
                params: tutorialId,
            });
        }
        function getCountryCodeList() {
            console.log("getprojectList WOWO:>>>");
            const payLoad = {};
            ethitransService
                .getCountryCodeList(payLoad)
                .then((res: any) => {
                    console.log("getprojectList Check:>>> ", res.data.data);
                    state.countryCodeList = res.data.data ? res.data.data : [];
                    // state.selectedFormForEdit = res.data ? res.data : {};
                })
                .catch((error: any) => {
                    console.log(error);
                })
                .finally(() => {
                    // state.ui.postLoading.getprojectList = false;
                });
        }
        function openTutorialDeleteModel(id: any) {
            state.data.selectId = id;
            $("#deleteTutorialModal").modal("show");
        }
        function hideTutorialDeleteModel() {
            state.data.selectId = '';
            $("#deleteTutorialModal").modal("hide");
        }

        function openShowModel(data: any) {
            state.selectedData = data;
            $("#showModel").modal("show");
        }
        function closeShowModel() {
            state.selectedData = {};
            $("#showModel").modal("hide");
        }

        function openTutorialStatusModel(id: any) {
            state.data.selectId = id;
            $("#updateStatusTutorialModal").modal("show");
        }
        function hideTutorialStatusModel() {
            state.data.selectId = '';
            $("#updateStatusTutorialModal").modal("hide");
        }

        function deleteTutorial() {
            state.ui.isPostLoading.deleteTutorial = true;
            const payLoad = {
                id: state.data.selectId,
            };
            ethitransService
                .deleteTutorial(payLoad)
                .then((res: any) => {
                    state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
                    state.data.successMessage = "Tutorial Delete Successfully.";
                })
                .catch((error: any) => {
                    console.log(error);
                })
                .finally(() => {
                    state.ui.isPostLoading.deleteTutorial = false;
                    allTutorial();
                    hideTutorialDeleteModel();
                });
        }
        function statusUpdateTutorial() {
            state.ui.isPostLoading.updateStatusTutorial = true;
            const payLoad = {
                id: state.data.selectId,
            };
            ethitransService
                .updateStatusTutorial(payLoad)
                .then((res: any) => {
                    state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
                    state.data.successMessage = "Tutorial Status Update Successfully.";
                })
                .catch((error: any) => {
                    console.log(error);
                })
                .finally(() => {
                    state.ui.isPostLoading.updateStatusTutorial = false;
                    allTutorial();
                    hideTutorialStatusModel();
                });
        }
        function mailData(data: any) {
            console.log("emit data to here", data);
            state.data.mailData.push(data);
        }


        function editTutorial(id: any) {
            const tutorialId = id;
            router.push({
                name: "edit-tutorial",
                params: { tutorialId: tutorialId },
            });

        }

        function showNotificationMailModel() {
            state.data.showNotificationMailModel =
                !state.data.showNotificationMailModel;
        }
        function closeAppointmentModal() {
            $("#showNotificationMailModel").modal("hide");
        }
        onMounted(() => {
            state.data.imageBaseUrl = config.imageBaseUrl;
            allTutorial();
            getCountryCodeList();
        });
        return {
            state,
            allTutorial,
            redireToUserLog,
            clearSearch,
            serachValidation,
            getCountryCodeList,
            openTutorialDeleteModel,
            hideTutorialDeleteModel,
            mailData,
            showNotificationMailModel,
            closeAppointmentModal,
            openTutorialStatusModel,
            hideTutorialStatusModel,
            deleteTutorial,
            statusUpdateTutorial,
            closeSuccessCustomModal,
            editTutorial,
            openShowModel,
            closeShowModel
        };
    },
});
