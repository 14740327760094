
import { defineComponent,reactive, onMounted} from "vue";
import { useRoute, useRouter } from "vue-router";
import { common } from "@/common/services/Common";
import { ethitransService } from "@/services/EthitransService";
import ErrorList from "@/components/commonDesign/ErrorList.vue";


export default defineComponent({
  name: "ChoosePaymentMethod",
  components: {
    ErrorList,
  },
  setup() {
    const {
      params: { invoiceId },
    } = useRoute();
    const router = useRouter();
    const routerQuery = useRoute();
    const state = reactive({
      invoice: {
        amount: 0,
        subTotal: 0,
        discount: 0,
        tax: 0,
        taxAmount: 0,
      },
      ui: {
        error: {
          hasError: false,
          errorList: {
            billingErrors: [] as any,
          },
        },
        isLoading: {
          stripeAccountCheck: false,
        },
        isPostLoading: {
          confirmPayment: false,
        },
      },

    });
    function confirmPayment() {
      console.log("Choose Payment Method");
      // router.push({
      //   name: "admin-payment-success",
      // });
      state.ui.error.errorList.billingErrors = [];
      state.ui.error.hasError = false;
      if (state.ui.isPostLoading.confirmPayment) return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        invoice_id: invoiceId,
        amount: state.invoice.amount,
      };
      state.ui.isPostLoading.confirmPayment = true;
      ethitransService
        .payInvoiceByStripe(payLoad)
        .then((res: any) => {
          // alert("THEN");
          // emit("logData", res.data.data);
          if(res.data.data.status === 1){
            console.log("Project Todo Response Check:>>> ", res.data.data.link);
            window.location.replace(`${res.data.data.link}`);
          }
          // logData(res.data.data);
          // hideTaskLogModal();
        })
        .catch((error: any) => {
          // alert("CATCH WHY");
          console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.billingErrors)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.billingErrors.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.confirmPayment = false;
        });
    }
    
     onMounted(() => {
       console.log("INVOICE ID >>> ", invoiceId)
       const amount: any = routerQuery.query.amount;
       const subTotal: any = routerQuery.query.subTotal;
       const discount: any = routerQuery.query.discount;
       const tax: any = routerQuery.query.tax;
       state.invoice.amount = parseInt(amount);
       state.invoice.subTotal = parseInt(subTotal);
       state.invoice.tax = parseInt(tax);
       state.invoice.taxAmount = state.invoice.tax * state.invoice.subTotal;
       state.invoice.discount = parseInt(discount);
       // routerQuery.query.amount
       // routerQuery.query.subTotal
       // routerQuery.query.discount
       // routerQuery.query.tax
     });
    return {
      state,
      confirmPayment,
    };
  },
});
