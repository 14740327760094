
import { defineComponent } from "vue";
export default defineComponent({
  name: "EmptyData",
    props: {
      title: String,
      heading: String,
    },
  setup(props) {
    // console.log("CHECK Title props", props.title);
    // console.log("CHECK Heading props", props.heading);
    // console.log("CHECK props", props.member.id);
    return {};
  },
});
