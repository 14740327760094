
import { defineComponent, onMounted, reactive } from "vue";
import ErrorList from "@/components/commonDesign/ErrorList.vue";
import { ethitransService } from "@/services/EthitransService";
import { common } from "@/common/services/Common";

export default defineComponent({
  name: "DeleteModal",
  props: {
    showModal: Boolean,
    folderId: String,
    fileId: String,
  },
  components: {
    ErrorList,
  },
  setup(props, { emit }) {
    // const router = useRouter();
    const state = reactive({
      data: {
        folderRename: "" as any,
        folderList: [] as any,
        folderTitle: "folder",
        folderHeading: "No folders created",
      },
      ui: {
        error: {
          hasError: false,
          errorList: {
            delete: [] as any,
          },
        },
        isLoading: {
          getFolderList: false,
        },
        isPostLoading: {
          deleteSelected: false,
        },
      },
    });
    function closeModal(data: any) {
      emit("close", data);
      $("#deleteFolderModal").modal("hide");
    }
    function deleteFolder() {
      state.ui.error.errorList.delete = [];
      state.ui.error.hasError = false;
      if (
       state.ui.error.errorList.delete.length != 0 ||
        state.ui.error.hasError ||
        state.ui.isPostLoading.deleteSelected
      )
        return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        folder_id: props.folderId,
      };
      state.ui.isPostLoading.deleteSelected = true;
      ethitransService
        .folderDelete(payLoad)
        .then((res: any) => {
          console.log("Project List Response Check:>>> ", res.data.data);
          closeModal(true)
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error,state.ui.error.errorList.delete)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                 state.ui.error.errorList.delete.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.deleteSelected = false;
        });
    }
    function deleteFile() {
      state.ui.error.errorList.delete = [];
      state.ui.error.hasError = false;
      if (
       state.ui.error.errorList.delete.length != 0 ||
        state.ui.error.hasError ||
        state.ui.isPostLoading.deleteSelected
      )
        return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        file_id:  [ props.fileId ],
      };
      state.ui.isPostLoading.deleteSelected = true;
      ethitransService
        .fileDelete(payLoad)
        .then((res: any) => {
          console.log("Project List Response Check:>>> ", res.data.data);
           closeModal(true)
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error,state.ui.error.errorList.delete)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                 state.ui.error.errorList.delete.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.deleteSelected = false;
        });
    }
    function deleteSelected() {
    if(props.folderId){
      deleteFolder()
    } else {
      deleteFile()
    }
    }
    onMounted(() => {
      if (props.showModal) {
        $("#deleteFolderModal").modal("show");
      }
      if(props.fileId){
        console.log("FILE")
      }
      if(props.folderId){
        console.log("FOLER")
      }
      //   getFolderList();
    });

    return {
      state,
      closeModal,
      deleteSelected,deleteFolder,deleteFile,
    };
  },
});
