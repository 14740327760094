
import { defineComponent, onMounted, reactive } from "vue";

export default defineComponent({
  name: "DetailviewModal",
  props : {
      showModal : Boolean,
      selected : Object,
  },
  setup(props, {emit}) {
    // const router = useRouter();
    const state = reactive({
      data: {
        folderRename: "" as any,
      },
      ui: {
        error: {
          hasError: false,
          errorList: {
            createNewFolder: [] as any,
          },
        },
        isLoading: {
          getFolderList: false,
        },
        isPostLoading: {
          createNewFolder: false,
        },
      },
    });
    function closeDetailModal() {
      emit("close");
      $("#detailviewModal").modal("hide");
    }
    onMounted(() => {
        if(props.showModal){
            $("#detailviewModal").modal("show");
        }
      //   getFolderList();
    });

    return {
      state,
      closeDetailModal,
    };
  },
});
