import { createI18n } from "vue-i18n";

import en from './locales/en.json';
import es from './locales/es.json';

const savedLan = localStorage.getItem('language');
let lan = 'en'
if(savedLan){
  lan = savedLan;
}

const i18n = createI18n({
    locale: lan, // Default locale
    fallbackLocale: 'en', // Fallback locale
    messages: {
      en,
      es
    }
  });
  
  export default i18n;