
import { defineComponent, reactive, onMounted } from "vue";
import { ethitransService } from "@/services/EthitransService";
import ErrorList from "@/components/commonDesign/ErrorList.vue";
import DragAndDropZone from "@/common/DragAndDropZone.vue";

import Button from "@/common/Button.vue";


import { validationService } from "@/common/ValidationService";
import { common } from "@/common/services/Common";
import { config } from "@/Config";
export default defineComponent({
  name: "ProjectApprovalModalEdit",
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    selectedProject: {
      type: Object,
      required: true,
    },
    selectedDecline: {
      type: Object,
      required: true,
    },
  },
  components: {
    DragAndDropZone,
    ErrorList,
    Button,
  },
  setup(props, { emit }) {
    const state = reactive({
      titleMember: "pending members",
      headingMember: "Get started by inviting users in your project.",
      data: {
        buttonName:"request",
        projectAckowledgement: {
          payer: "",
          payers: [] as any,
          name: "",
          description: "",
          estimatedBudget: "",
          startDate: "",
          endDate: "",
          attachments: [] as any,
          edocLink: "",
        },
      },
      ui: {
        isLoading: {
          projectDetailsAPICalled: false,
        },
        isPostLoading: {
          removeSelectedImage: false,
          acknowledgeRequest: false,
        },
        errors: {
          errorList: {
            approval: [] as any,
          },
          hasError: false,
        },
      },
    });
    function readImage(file: any) {
      const data = {} as any;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
        const extensionLower = extension.toLowerCase();
        data.file = e.target.result;
        data.name = file.name.replaceAll(`.${extension}`, "");
        data.extension = extensionLower;
        state.data.projectAckowledgement.attachments.push(data);
        console.log("CHECK DATA FOR UPLOAD>>", data);
        // state.imageList.push(data);
      };
      reader.readAsDataURL(file);
    }
    const dropFiles = (event: any) => {
      const files = event.target.files || event.dataTransfer.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        readImage(file);
      }
    };
    function removeSelectedImage(image: any) {
      // console.log("Check Delete >>> ", image)
      state.ui.errors.errorList.approval = [];
      state.ui.errors.hasError = false;
      if (state.ui.isPostLoading.removeSelectedImage) return false;
      state.ui.isPostLoading.removeSelectedImage = true;
      ethitransService
        .projectDocumentDelete(image.uuid.toString())
        .then((res: any) => {
          // router.push({
          //   name: 'admin-channel',
          //   params: { channelId: res.data.data.channel.uuid.toString() },
          // });
          // console.log("Acknowledge Project Response:>>> ", res.data.data.channel);
          state.data.projectAckowledgement.attachments = state.data.projectAckowledgement.attachments.filter(
        (e: any) => e != image
      );
        })
        .catch((error: any) => {
          common
            .sdCatchErr(
              error,
              state.ui.errors.errorList.approval
            )
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.approval.push(
                    value[0]
                  );
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.removeSelectedImage = false;
        });
      
    }
    function removeSelectedUser(payer: any){
      state.data.projectAckowledgement.payers = state.data.projectAckowledgement.payers.filter((e: string) => e != payer);
    }
    function addPayers(){
      if (validationService.isValidEmail(state.data.projectAckowledgement.payer)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (state.data.projectAckowledgement.payers.length === 0) {
          state.data.projectAckowledgement.payers.push(common.lowerCase(state.data.projectAckowledgement.payer));
        } else {
          state.data.projectAckowledgement.payers.forEach((member: any) => {
            // console.log("member", member);
            if (member != state.data.projectAckowledgement.payer) {
              state.data.projectAckowledgement.payers.push(common.lowerCase(state.data.projectAckowledgement.payer));
            }
          });
        }
        
        state.data.projectAckowledgement.payers = state.data.projectAckowledgement.payers.filter(
          (item: any, i: number, ar: any) => ar.indexOf(item) === i
        );
        state.data.projectAckowledgement.payer = "";
      }
    }
    function hideProjectAcknowledgementModal() {
      $("#resendProjectAcknowledgement").modal("hide");
      emit("close");
    }
    function validateForm(){
      // console.log("VALIDATE")
      state.ui.errors.errorList.approval = [];
      state.ui.errors.hasError = false;

      if (!state.data.projectAckowledgement.payers.length) {
        state.ui.errors.errorList.approval.push("Payer is required.");
      }
      if (!state.data.projectAckowledgement.estimatedBudget) {
        state.ui.errors.errorList.approval.push("Estimated Budget is required.");
      }
      if (!state.data.projectAckowledgement.startDate) {
        state.ui.errors.errorList.approval.push("Start Date is required.");
      }
      if (!state.data.projectAckowledgement.endDate) {
        state.ui.errors.errorList.approval.push("End Date is required.");
      }
      if (state.data.projectAckowledgement.startDate && state.data.projectAckowledgement.endDate) {
        // console.log("CHECK DATE CHECK >>> ")
        const d1 = new Date(state.data.projectAckowledgement.startDate).getTime();
        const d2 = new Date(state.data.projectAckowledgement.endDate).getTime();
        // console.log("CHECK DATE START >>> ", d1)
        // console.log("CHECK DATE END >>> ", d2)
        if(d2 < d1){
          state.ui.errors.errorList.approval.push("Date is not in Valid Range.");
        }
      }
  

      if (!state.ui.errors.errorList.approval && !state.ui.errors.errorList.approval.length) {
        state.ui.errors.hasError = false;
      } else if (state.ui.errors.errorList.approval.length != 0) {
        state.ui.errors.hasError = true;
      }
    }
    function acknowledgeRequest() {
      // state.error.errorList.createChannel = [];
      // state.error.hasError = false;
      if(state.data.projectAckowledgement.payer){
        state.data.projectAckowledgement.payers.push(state.data.projectAckowledgement.payer);
        state.data.projectAckowledgement.payer = '';
      }
      validateForm();
      if (state.ui.isPostLoading.acknowledgeRequest || state.ui.errors.errorList.approval.length != 0 || state.ui.errors.hasError) return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: props.selectedProject.project.uuid,
        name: state.data.projectAckowledgement.name,
        description: state.data.projectAckowledgement.description,
        // eslint-disable-next-line @typescript-eslint/camelcase
        payer_emails: state.data.projectAckowledgement.payers,
        budget: state.data.projectAckowledgement.estimatedBudget,
        // eslint-disable-next-line @typescript-eslint/camelcase
        start_date: state.data.projectAckowledgement.startDate,
        // eslint-disable-next-line @typescript-eslint/camelcase
        end_date: state.data.projectAckowledgement.endDate,
        files: state.data.projectAckowledgement.attachments,
        // eslint-disable-next-line @typescript-eslint/camelcase
        edoc_id: state.data.projectAckowledgement.edocLink,
      };
      console.log("CHECK PAYLOAD >>> ", payLoad)
      state.ui.isPostLoading.acknowledgeRequest = true;
      ethitransService
        .projectAcknowledgement(payLoad)
        .then((res: any) => {
          console.log("acknowledgeRequest Response Check:>>> ", res.data.data);
          hideProjectAcknowledgementModal()
          emit("acknowledgedData", res.data.data);
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.errors.errorList.approval)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.approval.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.acknowledgeRequest = false;
        });
    }

    onMounted(() => {
      if (props.showModal) {
        $("#resendProjectAcknowledgement").modal("show");
        console.log("CHECK START DATE >>> ", props.selectedProject.payerRequest.start_date.slice(0, 10))
        console.log("CHECK START DATE >>> ", props.selectedProject.payerRequest.end_date.slice(0, 10))
        state.data.projectAckowledgement.startDate = props.selectedProject.payerRequest.start_date.slice(0, 10);
        state.data.projectAckowledgement.endDate = props.selectedProject.payerRequest.end_date.slice(0, 10);
        state.data.projectAckowledgement.name = props.selectedProject.project.name;
        if(props.selectedProject && props.selectedProject.payerRequest && props.selectedProject.payerRequest.description){
          state.data.projectAckowledgement.description = props.selectedProject.payerRequest.description;
        } else {
          state.data.projectAckowledgement.description = props.selectedProject.project.description;
        }
        state.data.projectAckowledgement.estimatedBudget = props.selectedProject.payerRequest.budget;
        state.data.projectAckowledgement.edocLink = props.selectedProject.payerRequest.edoc_id;
        if(props.selectedProject && props.selectedProject.payers && props.selectedProject.payers.length){
          props.selectedProject.payers.forEach((payer: any)=> {
            // console.log("CHECK PAYER>>> ", payer)
            if(payer.user && payer.user.email){
              state.data.projectAckowledgement.payers.push(payer.user.email);
            }

          })
        }
        if(props.selectedProject && props.selectedProject.attachments && props.selectedProject.attachments.length){
          props.selectedProject.attachments.forEach((attachment: any) => {
            // attachment.file_size
            attachment.activeLink =
              config.liveImageBaseUrl + attachment.file_link;
            attachment.convertedFileSize = common.formatBytes(
              parseInt(attachment.file_size)
            );
            state.data.projectAckowledgement.attachments.push({
              'uuid': attachment.uuid,
              'extension': attachment.file_type,
              'file': attachment.activeLink,
              'name': attachment.file_name,
            })
            // console.log("HELLO ATTACHMENT >>> ", attachment.activeLink)
          });
        }
        // state.data.projectAckowledgement.edocLink = props.selectedProject.approval.payerRequest.payers.user;
        
      }
    });
    return {
      state,
      removeSelectedImage,
      dropFiles,
      addPayers,
      removeSelectedUser,
      validateForm,
      acknowledgeRequest,
      hideProjectAcknowledgementModal,
    };
  },
});
