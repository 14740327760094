
import { defineComponent, reactive } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "TheSteps",
  setup() {
    const router = useRouter();
    const state = reactive({
      userRole: "" as any,
      userDetails: {} as any,
      data: {
        activeTab: 0,
        currentRoute:useRouter().currentRoute.value.name,
      },

    });
   
    function redirectToStep1() {
      router.push({
        name: "admin-tutorial-start",
      });
    }
     function redirectToStep2() {
      router.push({
        name:"admin-tutorial-one",
      });
    }

    function redirectToStep3() {
      router.push({
        name: "admin-tutorial-two",
      });
    }

     function redirectToStep4() {
      router.push({
        name: "admin-tutorial-three",
      });
    }

     function redirectToStep5() {
      router.push({
        name: "admin-tutorial-four",
      });
    }

     function redirectToStep6() {
      router.push({
        name: "admin-tutorial-five",
      });
    }

     function redirectToStep7() {
     router.push({
        name: "admin-tutorial-six",
      });
    }

     function redirectToStep8() {
     router.push({
        name: "admin-tutorial-eight",
      });
    }

     function redirectToStep9() {
      router.push({
        name: "admin-tutorial-end",
      });
    }
    
    return {
      state,
      redirectToStep1,
      redirectToStep2,
      redirectToStep3,
      redirectToStep4,
      redirectToStep5,
      redirectToStep6,
      redirectToStep7,
      redirectToStep8,
      redirectToStep9

    };
  },
});
