
import { defineComponent, reactive, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ethitransService } from "@/services/EthitransService";
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import moment from "moment";
import { useStore } from "vuex";
import introJs from "intro.js";

export default defineComponent({
  name: "CalendarTest",
  components: {
    VueCal,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const state: any = reactive({
      data: {
        appointmentLoad: false,
        showAppointCreateModel: false,
        appointmentList: [
        {
        id: 1,
        title: 'Main Event',
        start: '2023-06-01',
        end: '2023-06-07',
        color: '#FF4500',
      },
      {
        id: 2,
        title: 'Sub-Event',
        start: '2023-06-03',
        end: '2023-06-05',
        parentId: 1,
        color: '#42B983',
      },
        ],
        appointmet: [] as any,
      },
      ui: {
        isLoading: {
          getAccountFormList: false,
        },
        isPostLoading: {
          createUserIssue: false,
        },
      },
      error: {
        hasError: false,
        erorList: {
          uploadClientDocument: [] as any,
          errorsList: [] as any,
        },
      },
    });
 

    function getaddAppointment(data: any) {
      console.log("emit data:::", data);
      state.data.showAppointCreateModel = !state.data.showAppointCreateModel;
    }
    function showAppointmentModal() {
      state.data.showAppointCreateModel = !state.data.showAppointCreateModel;
    }
    if (route.query.zoomMeeting === "true") {
      setTimeout(showAppointmentModal, 1000);
    }
    function handleEventSelected(event: any) {
      alert("hello anuj" )
      if (event.parentId) {
        return
      }

      // const subEvents = $refs.calendar.getEventsInPeriod({
      //   start: event.start,
      //   end: event.end,
      // }).filter((e: any) => e.parentId === event.id)

      console.log("Trigger Gun", event)
    }
    // function getUserAppointment() {
    //   ethitransService
    //     .getUserAppointment()
    //     .then((res: any) => {
    //       console.log("get user appointment", res.data.data);
    //       const dateNow = moment(new Date()).format("Y-MM-DD HH:MM:SS");
    //       console.log("now date tiem", dateNow);
    //       res.data.data.forEach((appointmentData: any) => {
    //         const data = {
    //           start: appointmentData.start_time,
    //           end: appointmentData.end_time,
    //           title: appointmentData.title,
    //           class:
    //             appointmentData.end_time > dateNow ? "blue-event" : "red-event",
    //           content: "Meeting Details:" + appointmentData.note,
    //         };
    //         state.data.appointmentList.push(data);
    //       });
    //       // toggleTourGuide();
    //       state.data.appointmentLoad = true;
    //     })
    //     .catch((error: any) => {
    //       //consoloe.log(error);
    //     })
    //     .finally(() => {
    //       // state.postLoading = false;
    //     });
    // }

    function appointData(data: any) {
      console.log("emit data to here", data);
      state.data.appointmentList.push(data);
    }

    function openEventDetails(){
        alert('hi');
    }

    onMounted(() => {
      // getUserAppointment();
    });

    function closeAppointmentModal() {
      state.data.title = "";
      state.data.description = "";
      state.data.file = "";
      state.error.erorList.errorsList = [];
      state.error.hasError = false;
      $("#addAppointment").modal("hide");
    }
    return {
      state,
      getaddAppointment,
      showAppointmentModal,
      closeAppointmentModal,
      // getUserAppointment,
      appointData,
      openEventDetails,
      handleEventSelected
    };
  },
});
