
import { useStore } from "vuex";
import { defineComponent, onMounted, reactive, computed } from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "TheSideNavBar",
  setup() {
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      userRole: "" as any,
      userDetails: {} as any,
      data: {
        activeTab: 0,
      },
    });
    const syntheticEvent = {
      preventDefault() {
        console.log("Prevented default action");
      },
    } as Event;
    const isMobileDevice = computed(() => {
      return /Mobi|Android/i.test(navigator.userAgent) || window.innerWidth <= 768;
    });
    function toggleTheSideBar(event: any) {
      event.preventDefault();
      $("body").toggleClass("sb-sidenav-toggled");
    }
    function checkMobile() {
      if (isMobileDevice.value) {
        toggleTheSideBar(syntheticEvent);
      }
    }
    function redirectToDashboard() {
      state.data.activeTab = 1;
      router.push({
        name: "admin-dashboard",
      });
      checkMobile();
    }
    function redirectToInvitation(event: any) {
      state.data.activeTab = 2;
      router.push({
        name: "admin-invitation",
      });
      checkMobile();
    }
    function redirectToMyTemplate() {
      state.data.activeTab = 3;
      router.push({
        name: "admin-create-formList",
      });
      checkMobile();
    }
    function redirectToLibrary() {
      state.data.activeTab = 4;
      router.push({
        name: "admin-template-search",
      });
      checkMobile();
    }
    function redirectToUploadPdf() {
      state.data.activeTab = 5;
      router.push({
        name: "user-upload-pdf-digitize",
      });
      checkMobile();
    }
    function redirectToDocusignDocument() {
      state.data.activeTab = 18;
      router.push({
        name: "user-docusign-document",
      });
      checkMobile();
    }
    function redirectToEnvelopeDocument() {
      state.data.activeTab = 19;
      router.push({
        name: "user-envelope-document",
      });
      checkMobile();
    }
    function redirectToMyDrive() {
      state.data.activeTab = 6;
      router.push({
        name: "admin-drive",
      });
      checkMobile();
    }
    function redirectToManageGuest() {
      state.data.activeTab = 7;
      router.push({
        name: "admin-manage-guest-user",
      });
      checkMobile();
    }
    function redirectToManageLineItems() {
      state.data.activeTab = 22;
      router.push({
        name: "list-line-item",
      });
      checkMobile();
    }
    function redirectToEdocDigitize() {
      state.data.activeTab = 8;
      router.push({
        name: "admin-verify-digitize-library",
      });
      checkMobile();
    }
    function redirectToTutorial() {
      state.data.activeTab = 9;
      router.push({
        name: "admin-tutorial",
      });
      checkMobile();
    }
    function redirectToPdfTutorial() {
      state.data.activeTab = 9;
      router.push({
        name: "admin-tutorial-pdf",
      });
      checkMobile();
    }
    function redirectToVideoTutorial() {
      state.data.activeTab = 9;
      router.push({
        name: "admin-tutorial-video",
      });
      checkMobile();
    }
    function redirectToFaqTutorial() {
      state.data.activeTab = 9;
      router.push({
        name: "admin-tutorial-faq",
      });
      checkMobile();
    }
    function redirectToAppointment() {
      state.data.activeTab = 10;
      router.push({
        name: "admin-appointment",
      });
      checkMobile();
    }
    function redirectToSubscription() {
      state.data.activeTab = 22;
      router.push({
        name: "admin-subscription",
      });
      checkMobile();
    }

    function redirectToAppointmentForm() {
      state.data.activeTab = 11;
      router.push({
        name: "admin-appointment-form",
      });
      checkMobile();
    }

    function redirectToUserActivity() {
      state.data.activeTab = 12;
      router.push({
        name: "admin-user-activity",
      });
      checkMobile();
    }


    function redirectToBusinessMailTemplate() {
      state.data.activeTab = 13;
      router.push({
        name: "business-mail-template"
      });
      checkMobile();
    }
    function redirectToMailEditor() {
      state.data.activeTab = 14;
      router.push({
        name: "mail-editor"
      });
      checkMobile();
    }

    function redirectToMangaeReferal() {
      state.data.activeTab = 20;
      router.push({
        name: "manage-referal"
      });
      checkMobile();
    }

    function redirectToMangaeReferralLink() {
      state.data.activeTab = 21;
      router.push({
        name: "manage-referral-link"
      });
      checkMobile();
    }

    function redirectToBusinessMailLog() {
      state.data.activeTab = 15;
      router.push({
        name: "business-mail-log"
      });
      checkMobile();
    }

    function redirectToManageProjectTag() {
      state.data.activeTab = 16;
      router.push({
        name: "manage-project-tag"
      });
      checkMobile();
    }
    function redirectToManageAllTag() {
      state.data.activeTab = 17;
      router.push({
        name: "admin-manage-all-tag"
      });
      checkMobile();
    }

    function redirectToTutorialManagement() {
      state.data.activeTab = 19;
      router.push({
        name: "admin-tutorial-management"
      });
      checkMobile();
    }
    const invitationCounts = computed(() => store.state.invitationCount);
    onMounted(() => {
      state.userRole = localStorage.getItem("current_user_role");

    });
    return {
      state,
      checkMobile,
      syntheticEvent,
      isMobileDevice,
      toggleTheSideBar,
      redirectToManageLineItems,
      redirectToSubscription,
      redirectToTutorial,
      invitationCounts,
      redirectToDashboard,
      redirectToInvitation,
      redirectToMyTemplate,
      redirectToLibrary,
      redirectToUploadPdf,
      redirectToMyDrive,
      redirectToManageGuest,
      redirectToEdocDigitize,
      redirectToAppointment,
      redirectToAppointmentForm,
      redirectToUserActivity,
      redirectToMailEditor,
      redirectToBusinessMailTemplate,
      redirectToBusinessMailLog,
      redirectToManageProjectTag,
      redirectToManageAllTag,
      redirectToDocusignDocument,
      redirectToEnvelopeDocument,
      redirectToTutorialManagement,
      redirectToPdfTutorial,
      redirectToVideoTutorial,
      redirectToFaqTutorial,
      redirectToMangaeReferal,
      redirectToMangaeReferralLink
    };
  },
});
