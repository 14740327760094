
import { defineComponent, onMounted, reactive } from "vue";
import Editor from "@/components/mention/Editor.vue";
import { ethitransService } from "@/services/EthitransService";
import SuccessfulModalCustom from "@/components/modal/SuccessfulModalCustom.vue";
import ErrorList from "@/components/commonDesign/ErrorList.vue";
import { anyTypeAnnotation } from "@babel/types";
import { useRouter, useRoute } from "vue-router";

export default defineComponent({
    name: "CreateTutorial",
    props: {
        showModal: Boolean,
    },
    components: {
        Editor,
        SuccessfulModalCustom,
        ErrorList,
    },

    setup(props, { emit }) {
        const router = useRouter();
        const route = useRoute();
        const state = reactive({
            userList: [] as any,
            templateList: [] as any,
            checkUser: [] as any,
            isCheckedAll: false,
            email: "" as any,
            subject: "" as any,
            message: "" as any,
            selectedFileName:'' as any,
            selectedFileUrl:'' as any,
            data: {
                tuorialType: "",
                question: "",
                answer: "",
                videoUrl: "",
                sortOrder: '',
                editId:'',
                SuccessFulMessage: "",
                uploadFIle: {} as any,
                showSuccesCustomModal: false,
                mailData: {} as any,
                showNotificationMailModel: false,
                selectedUser: {} as any,
                imageBaseUrl: "",
                title: "User",
                heading: "No User found .",
                searchUser: "",
                paging: {
                    clientDocumentList: {
                        perPage: 10,
                        totalDocs: 0,
                        totalPages: 0,
                        currentPage: 1,
                    },
                },
            },
            ui: {
                isLoading: {},
                isPostLoading: {
                    createTutorial: false,
                    saveEditUser: false,
                },
                error: {
                    validationList: [] as any,
                    hasError: false,
                },
            },

            newSearchData: {
                emailError: false,
            },
            error: {
                loginFromError: "" as any,
                loginToError: "" as any,
                createdFromError: "" as any,
                createdToError: "" as any,
            },
        });
        function createTutorialValidation() {
            state.ui.error.validationList = [];
            state.ui.error.hasError = false;
            if (!state.data.question) {
                state.ui.error.hasError = true;
                state.ui.error.validationList.push("Question is required.");
            }
            if (!state.data.tuorialType) {
                state.ui.error.hasError = true;
                state.ui.error.validationList.push("Tutorial Type is required.");
            }
            if (state.data.tuorialType && state.data.tuorialType == 'faq' && !state.data.answer) {
                state.ui.error.hasError = true;
                state.ui.error.validationList.push("Answer is required.");
            }

            if (state.data.tuorialType && state.data.tuorialType == 'video' && !state.data.videoUrl) {
                state.ui.error.hasError = true;
                state.ui.error.validationList.push("Video URL is required.");
            }

            if (!state.data.sortOrder) {
                state.ui.error.hasError = true;
                state.ui.error.validationList.push("Sort order is required.");
            }
        }


        function readImage(file: any) {
            const data = {} as any;
            const reader = new FileReader();
            reader.onload = (e: any) => {
                const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
                const extensionLower = extension.toLowerCase();
                data.base64 = e.target.result;
                data.duplicateFile = false; // data.fileName = file.name;
                data.fileName = file.name.replaceAll(`.${extension}`, "");
                data.extension = extensionLower;
                data.isuploded = false;
                data.tag = [] as any;
                state.data.uploadFIle = data;
                state.selectedFileName = data.fileName;
                state.selectedFileUrl =data.base64;
            };
            reader.readAsDataURL(file);

        }

        function onDocumentChange(event: any) {
            const files = event.target.files || event.dataTransfer.files;
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
                const extensionLower = extension.toLowerCase();
                readImage(file);
            }
        }

        function closeSuccessCustomModal() {
            router.push({
                name: "admin-tutorial-management"
            });
            state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
            $("#SuccessfulModalCustom").modal("hide");
        }

        function editTutorial() {
            const uuid = route.params.tutorialId;
            ethitransService
                .editTutorial(uuid)
                .then((res: any) => {
                   state.data.question = res.data.data.question;
                   state.data.tuorialType= res.data.data.type;
                   state.data.answer= res.data.data.answer;
                   state.data.videoUrl= res.data.data.url;
                   state.data.sortOrder= res.data.data.sort_order;
                   state.data.editId =res.data.data.id;
                   state.data.uploadFIle.fileName = res.data.data.file_name;
                   state.selectedFileName = res.data.data.file_name;
                   state.selectedFileUrl = res.data.data.file;
                    console.log('res', res)
                })
                .catch((error: any) => {
                    console.log(error);
                })
                .finally(() => {
                    // state.ui.isLoading.clientDocumentList = false;
                });
        }

        function saveTutorial() {
            state.ui.isPostLoading.createTutorial = true;
            createTutorialValidation();
            if (state.ui.error.validationList.length != 0)
        return false;
            const payLoad = {
                id:state.data.editId,
                question: state.data.question,
                type: state.data.tuorialType,
                answer: state.data.answer,
                url: state.data.videoUrl,
                // eslint-disable-next-line @typescript-eslint/camelcase
                sort_order: state.data.sortOrder,
                filename: state.data.uploadFIle.fileName,
                extension: state.data.uploadFIle.extension,
                file: state.data.uploadFIle.base64,
            };
            ethitransService
                .updateTutorial(payLoad)
                .then((res: any) => {
                    state.checkUser = [];
                    state.subject = "";
                    state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
                    state.data.SuccessFulMessage = "Tutorial Update Successfully.";
                })
                .catch((error: any) => {
                    console.log(error);
                })
                .finally(() => {
                    state.ui.isPostLoading.createTutorial = false;

                });
        }


        function openShowModel(data: any) {
           
            $("#showModel").modal("show");
        }
        function closeShowModel() {
           
            $("#showModel").modal("hide");
        }

        onMounted(() => {
            Editor;
            editTutorial();
        });

        return {
            state,
            createTutorialValidation,
            saveTutorial,
            closeSuccessCustomModal,
            onDocumentChange,
            editTutorial,
            openShowModel,
            closeShowModel
        };
    },
});
