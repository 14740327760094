
import { defineComponent, computed, reactive, watch, onMounted } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "AppTour",
  props: {
    showModal: Boolean,
    toogleTour: Boolean,
  },
  components: {},
  setup(props) {
    const store = useStore();
    const state = reactive({
      data: {
        showModal: false,
        SuccessFulMessage: "",
      },

      ui: {
        error: {
          hasError: false,
          errorList: {
            delete: [] as any,
          },
        },
        isLoading: {
          getFolderList: false,
        },
        isPostLoading: {
          deleteSelected: false,
        },
      },
    });
    // eslint-disable-next-line vue/return-in-computed-property
    const showTour = computed(() => {
      return store.state.defaultTour;
    });
    function showTourGuidePopUp() {
      // state.data.showModal = !state.data.showModal;n
      store.dispatch("takeATourGuide", false);
      store.dispatch("showModalTourGuide");
    }
    // eslint-disable-next-line vue/return-in-computed-property
    const toggleTour = computed(() => {
      return store.state.showTakAtourModal;
    });
    const takeATour = () => {
      store.dispatch("takeATourGuide", true);
      store.dispatch("showModalTourGuide");
      localStorage.setItem("isTourActive", 'true');
    };
    const noThanks = () => {
      showTourGuidePopUp();
       localStorage.setItem("isTourActive", 'false');
    };
    watch(
      () => window.location.href,
      (newvalue, oldvalue) => {
        // if (newvalue) {
        console.log("check URL >>>", window.location.href);
        // toggleTourGuide();
        // }
      }
    );
    function resetGuideTour() {
      noThanks();
    }
    onMounted(() => {
      if(localStorage.getItem("isTourActive") === 'false'){
        showTourGuidePopUp();
      } else {
        setTimeout(noThanks, 10000);
      }
      // reset start Tour Guide
      // setTimeout(resetGuideTour, 10000);
    });

    return {
      state,
      showTour,
      showTourGuidePopUp,
      resetGuideTour,
      toggleTour,
      takeATour,
      noThanks,
    };
  },
});
