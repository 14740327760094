import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "text-center section calendar-wrap" }
const _hoisted_2 = { class: "v-event-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_cal = _resolveComponent("vue-cal")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_vue_cal, {
      id: "vueCalenderAppointmentList",
      "disable-views": ['years', 'year'],
      "editable-events": "",
      "active-view": "month",
      "events-on-month-view": "short",
      events: _ctx.state.data.appointmentList,
      style: {"height":"77vh"},
      "on-event-click": _ctx.openEventDetails,
      "event-selected": _ctx.handleEventSelected
    }, {
      "day-content": _withCtx(({ events, date }) => [
        _createVNode("div", _hoisted_2, [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(events, (event) => {
            return (_openBlock(), _createBlock("div", {
              key: event.id,
              class: [
            'v-event',
            event.color && 'v-event-color-' + event.color.replace('#', ''),
            event.parentId && 'v-event-sub',
          ]
            }, _toDisplayString(event.title) + " " + _toDisplayString(date), 3))
          }), 128))
        ])
      ]),
      _: 1
    }, 8, ["events", "on-event-click", "event-selected"])
  ]))
}