
import { defineComponent, reactive, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { config } from "@/Config";
import ToDoMembersList from "@/components/todo/ToDoMembersList.vue";


import { ethitransService } from "@/services/EthitransService";
import { common } from "@/common/services/Common";
// import AppDeleteConfirmationModal from "@/common/AppDeleteConfirmationModal.vue";

export default defineComponent({
  name: "ToDoPhaseAddMemberTask",
  props: {
    tasks: {
      type: Array,
      required: true,
    },
    activeTab:{
      type: String,
      required: true,
    }
  },
  components: {
    ToDoMembersList,
    // AppDeleteConfirmationModal,
  },
  setup(props,{emit}) {
    const {
      params: { projectId },
    } = useRoute();
    const router = useRoute();
    const selectAll = ref(false);
    const todoData = ref({
      project: {} as any,
    });
    const state = reactive({
      data: {
        selectedToRemove: {},
        showDeleteConfirmationModal: false,
        deleteModalMessage: "Selected Member will be Delete. Please Confirm.",
        members: [] as any,
      },
      ui: {
        error: {
          hasError: false,
          errorList: {
            getToDoData: [] as any,
          },
        },
        isLoading: {
          showShareEstimation: false,
        },
        isPostLoading: {
          createPhase: false,
        },
      },
    });

    function isActive(role: number, permissionList: any, action: string){
      return common.isActionAllowded(role, permissionList, action)
    }
    function showUpdateTaskModal(selectedTask: any){
      console.log("Logged >>");
      emit("showTaskUpdateModal");
      emit("selectedTask", selectedTask);
    }
    function addMemberToTask(taskSelected: any){
      if(taskSelected.isCheckedDisabled) return false;
      // taskSelected.isChecked = true;
      // taskSelected.isChecked = !taskSelected.isChecked;
      emit("showAddMemberToTaskModal");
      emit("selectedChecked", taskSelected);
    }
    onMounted(() => {
      // if(router.query.taskId){
        
      //   setTimeout(function() {
      //     console.log("Add Member, Select Task and Edit Check");
      //   // Trigger a click event on the button
      //     // button.click();
      //   }, 1000);
      //   // console.log("CLICKED>>")
      
      // }
      // state.data.members = props.members;
    });

    return {
      state,
      isActive,
      addMemberToTask,
      showUpdateTaskModal
    };
  },
});
