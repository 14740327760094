
import { defineComponent, onMounted, reactive, computed } from "vue";
import { ethitransService } from "@/services/EthitransService";
import { common } from "@/common/services/Common";
import Multiselect from "@vueform/multiselect";
import ErrorList from "@/components/commonDesign/ErrorList.vue";

import TagsCard from "@/components/cards/TagsCard.vue";

export default defineComponent({
  name: "MyDriveFileTags",
  components: {
    TagsCard,
    Multiselect,
    ErrorList,
  },
  props: {
    showModal: Boolean,
    selected: Object,
  },
  setup(props, { emit }) {
    // const router = useRouter();
    const state = reactive({
      data: {
        showAddFileTag: false,
        folderRename: "" as any,
        recentlyUsedTags: [] as any,
        disabledFileTagList: [] as any,
        tags: {
          mode: "tags",
          value: [] as any,
          options: [] as any,
          searchable: true,
          createTag: true,
        },
      },
      ui: {
        error: {
          hasError: false,
          errorList: {
            fileTags: [] as any,
          },
        },
        isLoading: {
          getTags: false,
          getFolderList: false,
        },
        isPostLoading: {
          enableSelectedFileTag: false,
          fileTags: false,
          addTagsToFile: false,
        },
      },
    });
    // eslint-disable-next-line vue/return-in-computed-property
    const masterTags = computed(() => {
      const array: any = [];
      state.data.tags.options.forEach((item: any) => {
        array.push(item.name);
      });

      return array;
    });
    function closeAddTagsModal(data: any) {
      emit("close", data);
      $("#addTagsModal").modal("hide");
    }
    function masterTagListForValidation(data: boolean) {
      state.ui.error.hasError = false;
      state.ui.error.errorList.fileTags = [];
      const payLoad = {
        type: data ? "enabled" : "disabled",
      };
      ethitransService
        .masterFileTagList(payLoad)
        .then((res: any) => {
          // console.log("masterTagList Check:>>> ", res.data.data);
          // res.data.data.forEach((item: any) => {
          //   item.label = item.name;
          //   item.value = item.code;
          // })
          // state.data.tagListForValidation = res.data.data ? res.data.data : [];
          const disabledTagList: any = [];
          state.data.tags.value.forEach((item: any) => {
            res.data.data.forEach((data: any) => {
              if (item.toLowerCase() === data.name.toLowerCase()) {
                data.inProgress = false;
                // console.log("tag values >>> ",item )
                // console.log("tagListForValidation  values >>> ", data.name)
                disabledTagList.push(data);
              }
            });
          });
          // console.log("LIST>>> ", disabledTagList);
          const uniqueSet = Array.from(new Set(disabledTagList));
          // const tag: any = []
          state.data.disabledFileTagList = uniqueSet ? uniqueSet : [];

          if (
            state.data.disabledFileTagList &&
            !state.data.disabledFileTagList.length
          ) {
            state.data.showAddFileTag = true;
          }
          // state.data.disabledFileTagList.forEach((item: any)=> {
          // state.data.showAddFileTag = true

          // })
        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.ui.error.errorList.fileTags)
            .then((res: any) => {
              state.ui.error.hasError = true;
              // console.log("CHECK for Error >>>", res)
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.fileTags.push(value[0]);
                }
              }
              // if (res) {
              //   if (res.error) {
              //     console.log("CHECK for Error >>>", res.error[0])
              //     // state.errorList.push(res.email[0]);
              //     // state.errorList = [...state.errorList]
              //   }
              // }
            });
        })
        .finally(() => {
          // state.postLoading = false;
        });
    }

    function validateAddTagsToFile() {
      state.ui.error.hasError = false;
      state.ui.error.errorList.fileTags = [];
    }
    function addTagsToFile() {
      validateAddTagsToFile();
      // masterTagListForValidation(false);
      if (
        state.data.disabledFileTagList &&
        state.data.disabledFileTagList.length
      ) {
        state.ui.error.hasError = true;
        state.ui.error.errorList.fileTags.push("Disabled Tag Exist.");
      }
      if (
        state.data.disabledFileTagList &&
        state.data.disabledFileTagList.length
      )
        return false;
      if (
        state.ui.isPostLoading.addTagsToFile ||
        (state.ui.error.hasError && state.ui.error.errorList.fileTags.length)
      )
        return false;
      // console.log("Check the New Tag >>>", tag);
      let fileId: any;
      if (props.selected && props.selected.id) {
        fileId = props.selected ? props.selected.id : "";
      }
      const tagIds = [] as any;
      state.data.tags.options.filter((item: any) => {
        state.data.tags.value.forEach((data: any) => {
          if (item.name.toUpperCase() === data.toUpperCase()) {
            // console.log("check Ids ", item);
            tagIds.push(item.id);
          }
        });
      });
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_attachment_id: "",
        // eslint-disable-next-line @typescript-eslint/camelcase
        file_id: fileId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        master_file_tag_id: tagIds,
      };
      console.log("Check PayLoad >>> ", payLoad);
      state.ui.isPostLoading.addTagsToFile = false;
      ethitransService
        .createFileTags(payLoad)
        .then((res: any) => {
          // console.log("addTagsToFile:>>> ", res.data.data);
          closeAddTagsModal(res.data.data);
        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.ui.error.errorList.fileTags)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.fileTags.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.addTagsToFile = false;
        });
    }
    function masterTagList() {
      const payLoad = {
        type: "enabled",
      };
      ethitransService
        .masterFileTagList(payLoad)
        .then((res: any) => {
          // console.log("masterTagList Check:>>> ", res.data.data);
          // res.data.data.forEach((item: any) => {
          //   item.label = item.name;
          //   item.value = item.code;
          // })
          state.data.tags.options = res.data.data ? res.data.data : [];
        })
        .catch((error: any) => {
          // common.sdCatchErr(error, state.errorList).then((res: any) => {
          //   state.isErrorStatus = true;
          //   // console.log("CHECK for Error >>>", res)
          //   for (const [key, value] of Object.entries(res)) {
          //     if (Array.isArray(value)) {
          //       state.errorList.push(value[0]);
          //     }
          //   }
          //   // if (res) {
          //   //   if (res.error) {
          //   //     console.log("CHECK for Error >>>", res.error[0])
          //   //     // state.errorList.push(res.email[0]);
          //   //     // state.errorList = [...state.errorList]
          //   //   }
          //   // }
          // });
        })
        .finally(() => {
          // state.postLoading = false;
        });
    }
    function enableSelectedFileTag(document: any) {
      // console.log("Enable Doc >> ", document.id);
      if (document.enableStatus) return false;
      const payLoad = {
        id: document.id,
      };
      state.ui.isPostLoading.enableSelectedFileTag = true;
      document.inProgress = true;
      ethitransService
        .enableMasterFileTag(payLoad)
        .then((res: any) => {
          // console.log("enable File Tag :>>> ", res.data.data);
          state.data.disabledFileTagList =
            state.data.disabledFileTagList.filter(
              (data: any) => data.id != document.id
            );
          masterTagList();
          // if(state.ui.error.hasError && state.ui.error.errorList.fileTags && state.ui.error.errorList.fileTags.length){

            validateAddTagsToFile()
          // }
          // state.data.successFulMessage = "File Tag Enabled Successfully.";
          // state.data.showSuccesModal = !state.data.showSuccesModal;
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.enableSelectedFileTag = false;
          document.inProgress = false;
        });
    }

    function addTag(tag: any) {
      state.ui.error.hasError = false;
      state.ui.error.errorList.fileTags = [];
      // console.log("Check the New Tag >>>", tag);
      const payLoad = {
        name: tag.toUpperCase(),
      };
      // state.postLoading = true;
      ethitransService
        .createMasterFileTag(payLoad)
        .then((res: any) => {
          // console.log("createMasterTag:>>> ", res.data.data);
          if (res.data.data && res.data.data.is_active === 1) {
            state.data.tags.options.push(res.data.data);
          } else {
            state.data.tags.options.push({ name: tag.toUpperCase() });
          }
          masterTagListForValidation(false);
        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.ui.error.errorList.fileTags)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.fileTags.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          // state.postLoading = false;
        });
    }
    function selectedTag(data: any) {
      // console.log("Selected Recent Tag", data.master_file_tags.name);
      // if(data.channel)(
      // addTag(data.channel.name)
      state.data.tags.value.push(data.master_file_tags.name);
      // console.log("FINAL tag list >>", state.data.tags.value);
      // state.data.tags.value.concat([data.channel.name])
      // )
    }

    function getTags() {
      state.ui.error.hasError = false;
      state.ui.error.errorList.fileTags = [];
      state.ui.isLoading.getTags = true;
      // state.postLoading = true;
      ethitransService
        .recentlyUsedTags()
        .then((res: any) => {
          // console.log("Check recentyly used Tags >>>", res.data.data);
          state.data.recentlyUsedTags = res.data.data ? res.data.data : [];
        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.ui.error.errorList.fileTags)
            .then((res: any) => {
              state.ui.error.hasError = true;
              // console.log("CHECK for Error >>>", res)
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.fileTags.push(value[0]);
                }
              }
              // if (res) {
              //   if (res.error) {
              //     console.log("CHECK for Error >>>", res.error[0])
              //     // state.errorList.push(res.email[0]);
              //     // state.errorList = [...state.errorList]
              //   }
              // }
            });
        })
        .finally(() => {
          state.ui.isLoading.getTags = false;
          // state.postLoading = false;
        });
    }
    function selectTag(selectedData: any){
      // console.log("selectedData >>> ", selectedData)
      state.data.tags.options = state.data.tags.options.filter(
        (e: any) => e.name.toUpperCase() != selectedData.toUpperCase()
      );
       masterTagListForValidation(false);
       masterTagList();
    }
    function deSelectTag(data: any) {
      // console.log("DELETE>>>", data)
      // console.log("Before>>>", state.data.tags.options)
      // state.data.tags.options
      state.data.tags.options = state.data.tags.options.filter(
        (e: any) => e.name.toUpperCase() != data.toUpperCase()
      );
      // console.log("After>>>", state.data.tags.options)
      masterTagList();
      masterTagListForValidation(false);
    }
    onMounted(() => {
      if (props.showModal) {
        $("#addTagsModal").modal("show");
        getTags();
        masterTagList();
      }
      //   getFolderList();
    });

    return {
      state,
      deSelectTag,
      selectTag,
      enableSelectedFileTag,
      masterTagListForValidation,
      validateAddTagsToFile,
      addTagsToFile,
      masterTags,
      closeAddTagsModal,
      getTags,
      addTag,
      selectedTag,
    };
  },
});
