import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form-block text-field" }
const _hoisted_2 = { for: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("label", _hoisted_2, _toDisplayString(_ctx.data.title), 1),
    _createVNode("img", {
      src: _ctx.data.signatureBase64,
      alt: ""
    }, null, 8, ["src"])
  ]))
}