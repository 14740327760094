
import { defineComponent } from "vue";
export default defineComponent({
  name: "ErrorList",
  props: {
    errors: Array,
    hasError: Boolean,
  },
  setup(props) {
    // console.log("CHECK ChannelCard props", props.errors);
    // console.log("CHECK props", props.hasError);
    return {};
  },
});
