import Axios from "axios";
import { authapi } from "./AuthApi";
class AuthService {
  public async callApi1(method: string, url: any, data: any = []) {
    const testapi = Axios.create({
      baseURL: "",
    });
    switch (method) {
      case "get":
        return await new Promise((resolve, reject) => {
          testapi
            .get(url)
            .then((res: any) => {
              resolve(res);
            })
            .catch((error) => {
              reject(error);
            });
        });

      case "put":
        return await new Promise((resolve, reject) => {
          testapi
            .put(url, data)
            .then((res: any) => {
              //   this.successresponse = this.sd_GetResponse(res);
              resolve(res);
            })
            .catch((error: any) => {
              reject(error);
            });
        });

      case "post":
        return await new Promise((resolve, reject) => {
          testapi
            .post(url, data)
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              reject(error);
            });
        });

      default:
        return;
      }
    }
    public async sendOtp(data: any) {
        // console.log("UUID CHECK", data);
        return await new Promise((resolve, reject) => {
            this.callApi1("post", authapi.sendOtp(), data)
                .then((res: any) => {
                    resolve(res);
                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    }
    public async sendTokenLinks(data: any) {
      // console.log("UUID CHECK", data);
      return await new Promise((resolve, reject) => {
          this.callApi1("post", authapi.sendTokenLinks(), data)
              .then((res: any) => {
                  resolve(res);
              })
              .catch((error: any) => {
                  reject(error);
              });
      });
  }
    public async forgetPassword(data: any) {
        // console.log("UUID CHECK", data);
        return await new Promise((resolve, reject) => {
            this.callApi1("post", authapi.forgetPassword(), data)
                .then((res: any) => {
                    resolve(res);
                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    }

  public async smartContractCalender(
    contractId: string,
    startDate: string,
    endDate: string
  ) {
    // console.log("UUID CHECK", data);
    return await new Promise((resolve, reject) => {
      this.callApi1(
        "get",
        authapi.smartContractCalender(contractId, startDate, endDate)
      )
        .then((res: any) => {
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async logIn(data: any) {
    // console.log("UUID CHECK", data);
    return await new Promise((resolve, reject) => {
      this.callApi1("post", authapi.logIn(), data)
        .then((res: any) => {
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async logInPublicUser(data: any) {
    // console.log("UUID CHECK", data);
    return await new Promise((resolve, reject) => {
      this.callApi1("post", authapi.logInPublicUser(), data)
        .then((res: any) => {
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async tokenLogin(data: any) {
    // console.log("UUID CHECK", data);
    return await new Promise((resolve, reject) => {
      this.callApi1("post", authapi.tokenLogin(), data)
        .then((res: any) => {
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async resendMail(data: any) {
    // console.log("UUID CHECK", data);
    return await new Promise((resolve, reject) => {
      this.callApi1("post", authapi.resendMail(), data)
        .then((res: any) => {
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async resetPassword(data: any) {
    // console.log("UUID CHECK", data);
    return await new Promise((resolve, reject) => {
      this.callApi1("post", authapi.resetPassword(), data)
        .then((res: any) => {
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

    
    public async logInWithOtp(data: any) {
        return await new Promise((resolve, reject) => {
            this.callApi1("post", authapi.logInWithOtp(), data)
                .then((res: any) => {
                    resolve(res);
                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    }
}

export const authService = new AuthService();