
import { defineComponent } from "vue";

export default defineComponent({
  name: "Button",
  props: {
    buttonLoading: Boolean,
    buttonName: String,
  },
  setup(props, { emit }) {
    function buttonSubmit() {
      emit("buttonTrigger");
    }
    return {
      buttonSubmit,
    };
  },
});
