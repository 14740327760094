
import { defineComponent, reactive, onMounted, ref, watch, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ethitransService } from "@/services/EthitransService";
import { common } from "@/common/services/Common";
import ErrorList from "@/components/commonDesign/ErrorList.vue";
import EmptyData from "@/components/commonDesign/EmptyData.vue";

import ToDoListBreadCrumb from "@/components/todo/ToDoListBreadCrumb.vue";
import Pusher from "pusher-js";
import { config } from "@/Config";
// import AppImageCarousel from "@/common/AppImageCarousel.vue"


// import AppImageList from "@/common/AppImageList.vue";


// import ToDoListBreadCrumb from "@/components/todo/ToDoListBreadCrumb.vue";
// import ToDoListPhaseBlock from "@/components/todo/ToDoListPhaseBlock.vue";
// import EmptyData from "@/components/commonDesign/EmptyData.vue";

// import ToDoListAddPhaseModal from "@/components/todo/ToDoListAddPhaseModal.vue";
// import SuccessfulModal from "@/components/modal/SuccessfulModal.vue";

export default defineComponent({
  name: "ToDoTaskLogList",
  components: {
    ErrorList,
    EmptyData,
    ToDoListBreadCrumb,
    // AppImageList,
    // ToDoListPhaseBlock,
    // ToDoListAddPhaseModal,
    // SuccessfulModal,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const {
      params: { phaseId },
    } = useRoute();
    const todoTaskLogList = ref({
      project: {} as any,
    });
    const state = reactive({
      data: {
        imageBaseUrl: "",
        selectedUsersAttachments: [],
        endPoint: "Logs",
        title: "Logs",
        heading: "Get started by creating a new Log.",
        logList: {
          logs: [] as any,
          phase: {} as any,
          project: {} as any,
          // eslint-disable-next-line @typescript-eslint/camelcase
          parent_phase: {} as any,
        },
        successFullMessage: "",
        filterBy: {
          name: "",
          fromDate: "",
          toDate: "",
        },
        showGetToDoList: false,
      },
      ui: {
        error: {
          hasError: false,
          errorList: {
            getPhaseLogList: [] as any,
          },
        },
        isLoading: {
          getPhaseLogList: false,
        },
        isPostLoading: {
          createPhase: false,
        },
      },
    });

    function selectedUser(data: any){
      state.data.selectedUsersAttachments = data;
      $("#taskCompleteImages").modal("show");
    }

    function getPhaseLogList() {
      state.ui.error.errorList.getPhaseLogList = [];
      state.ui.error.hasError = false;
      if (state.ui.isLoading.getPhaseLogList) return false;
      state.ui.isLoading.getPhaseLogList = true;
      ethitransService
        .phaseLogList(
          phaseId.toString(),
          state.data.filterBy.name.toString(),
          state.data.filterBy.fromDate.toString(),
          state.data.filterBy.toDate.toString()
        )
        .then((res: any) => {
          console.log("getPhaseLogList  Response:>>> ", res.data.data);
          if(res.data.data.logs && res.data.data.logs.length){
            res.data.data.logs.forEach((item: any)=> {
              item.formatedDate = common.formatDateAndTime(item.date)
              if(item.out){
                item.formatedOut = common.formatDateAndTimeSlot(item.out)
              } else {
                item.formatedOut = "";
              }
              if(item.in){
                item.formatedIn = common.formatDateAndTimeSlot(item.in)
              } else {
                item.formatedIn = "";
              }
            })
          }
          state.data.logList = res.data.data;
          console.log("UUID CHECK  Response:>>> ", state.data.logList.project.uuid);
          // state.data.phaseChannelList ? res.data.data.channels : [];
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.getPhaseLogList)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.getPhaseLogList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isLoading.getPhaseLogList = false;
        });
    }
    function hideTaskCompleteImages(){
      $("#taskCompleteImages").modal("hide");
    }
    function clearGetData() {
      state.data.filterBy.name = "";
      state.data.filterBy.fromDate = "";
      state.data.filterBy.toDate = "";
      getPhaseLogList();
    }
    watch(
      // () => watchTour,
      () => state.data.filterBy.toDate,
      (newvalue, oldvalue) => {
        if (newvalue) {
          if (!state.data.filterBy.fromDate) return false;
          getPhaseLogList();
        }
      }
    );
    // private channel pusher implemented
    const token = localStorage.getItem("current_user_token");
    let newToken = "";
    if (token) {
      newToken = token.replace(/"/gi, "");
    }
    const pusher = new Pusher(`${process.env.VUE_APP_WEBSOCKETS_KEY}`, {
      cluster: "ap2",
      // authEndpoint: 'http://localhost:8000/api/broadcasting/auth',
      authEndpoint: `${config.privateChannel}broadcasting/auth`,
      auth:{
        headers: {
          Accept: 'application/json',
          Authorization : "Bearer "+newToken
        }
      }

    });
    
    const logsDashboard = pusher.subscribe(`private-todoDailyLog.${route.query.id}`);

    logsDashboard.bind("todoDailyLog.updated", function (data: any) {
      // alert("BEfore");
      if (JSON.stringify(data)) {
        // alert("After");
        const url: any = window.location.href;
        // const url: any = window.location.href.split("#/").pop();
        const page: any = url.split("#/").pop().split("/")[0];
        // const channelId: any = url.split("/").pop();
        // state.data.channelId = "";
        // if (page === "project-detail") {
          console.log("LOGS DASHBOARD TRIGGERED", page);
          // state.data.channelId = channelId;
          getPhaseLogList();
        // }
      }
    });
    function redirectToToDoDetails(){
      router.push({
        name: "user-todo-details",
        params: { phaseId: state.data.logList.parent_phase.uuid },
      });
    }

    onMounted(() => {
      state.data.imageBaseUrl = config.imageBaseUrl;
      // state.imageBaseUrl = config.test;
      // console.log("HELLO FROM TODO TASK LOG LIST", phaseId);
      getPhaseLogList();
    });

    return {
      state,
      redirectToToDoDetails,
      hideTaskCompleteImages,
      selectedUser,
      getPhaseLogList,
      clearGetData,
    };
  },
});
