
import { defineComponent, reactive, computed, onMounted, watch } from "vue";
import { ethitransService } from "@/services/EthitransService";
import SuccessfulModalCustom from "@/components/modal/SuccessfulModalCustom.vue";
import { common } from "@/common/services/Common";
import { useRouter } from "vue-router";
import "vue3-pagination/dist/vue3-pagination.css";
import { useStore } from "vuex";
import introJs from "intro.js";

export default defineComponent({
  name: "ManageProjectTags",
  components: {
    SuccessfulModalCustom,
  },
  setup() {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const moment = require("moment");
    const store = useStore();
    const router = useRouter();
    const state = reactive({
      userList: [] as any,
      data: {
        projectTagListLoad: false,
        showSuccesCustomModal: false,
        successFulMessage: "",
        selectedDate: "" as any,
        projectTagList: [] as any,
      },
      name: "" as any,
      email: "" as any,
      isShow: false,
      formId: "",
      selectedFormId: "",
      type: "",
      searchBy: "",
      filter: {
        type: "all",
        projectId: "" as any,
        channelId: "" as any,
        sortBy: -1,
        title: 1,
        selected: { name: "Newest First", id: -1 } as any,
        defaultValue: false
      },
      formData: {} as any,
      formList: {} as any,
      invite: {
        users: [] as any,
      },
      existingMember: {} as any,
      item: {
        sendOption: {
          project: {} as any,
          channel: {} as any,
        },
        error: {
          erorListSendForm: [] as any,
          updateValidity: [] as any,
        },
        hasError: false,
        selectedUserFormDetails: [] as any,
        searchUser: "",
        paging: {
          getAccountFormList: {
            perPage: 10,
            totalDocs: 0,
            totalPages: 0,
            currentPage: 1,
          },
        },
      },
      selectedElement: {
        element: {} as any,
        type: "",
        index: "",
        title: "",
        description: "",
      },
      userFormData: [] as any,
      projectList: [] as any,
      currentSendUser: false,
      selectedUser: {},
      activeUserIndex: 0,
      channelListForSendOption: [] as any,
      userListForSendOption: [] as any,
      channelList: [] as any,
      allChannelList: [] as any,
      newFormData: {
        title: "",
        description: "",
        elements: [] as any,
      },
      ui: {
        isLoading: {
          clientDocumentRetrieveWorkflow: false,
          getAccountFormList: false,
          clientDocumentList: false,
          accountFormResponseByEmailAndId: false,
        },
        postLoading: {
          setNewValidity: false,
          getAccountFormList: false,
          getAccountFormRetrieveById: false,
          getprojectTagList: false,
          getChannelByProject: false,
          getChannelByProjectForSendOption: false,
          getChannelList: false,
        },
        isPostLoading: {
        disableTag: false,
        enableTag: false,
        },
      },
    });

    function closeSuccessCustomModal() {
      state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
      $("#SuccessfulModalCustom").modal("hide");
    }
   
    function getProjectTagList() {

      state.ui.postLoading.getprojectTagList = true;
      ethitransService
        .projectTagList()
        .then((res: any) => {

          state.data.projectTagList = res.data.data;
          state.data.projectTagList.forEach((data: any) => {
            data.created = common.localDateAndTime(data.created_at);
          });
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.postLoading.getprojectTagList = false;
        });
    }

    function enableTag(tag: any) {
      if (tag.enableStatus) return false;
      const payLoad = {
        id: tag.id,
        // eslint-disable-next-line @typescript-eslint/camelcase
        is_active:tag.is_active==1?0:1,
      };
      state.ui.isPostLoading.enableTag = true;
      ethitransService
        .updateTagStatus(payLoad)
        .then((res: any) => {
            getProjectTagList();
          state.data.successFulMessage = "Status Update Successfully.";
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.enableTag = false;
        });
    }

    onMounted(() => {
        getProjectTagList();
    });
    return {
      state,
      getProjectTagList,
      closeSuccessCustomModal,
      enableTag,
    };
  },
});
