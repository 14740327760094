
import { defineComponent, ref, onMounted } from 'vue';

export default defineComponent({
  setup() {
    const browserLanguage = ref<string>('Unknown');
    const detectedLanguage = ref<string>('Unknown');

   

    const setInitialLanguage = () => {
      if (browserLanguage.value.startsWith('en')) detectedLanguage.value = 'English';
      else if (browserLanguage.value.startsWith('es')) detectedLanguage.value = 'Spanish';
      else if (browserLanguage.value.startsWith('de')) detectedLanguage.value = 'German';
      else if (browserLanguage.value.startsWith('fr')) detectedLanguage.value = 'French';
      else if (browserLanguage.value.startsWith('it')) detectedLanguage.value = 'Italian';
      else if (browserLanguage.value.startsWith('pt')) detectedLanguage.value = 'Portuguese';
      else if (browserLanguage.value.startsWith('ru')) detectedLanguage.value = 'Russian';
      else detectedLanguage.value = 'Unknown';
    };

    const detectKeyEvent = (event: KeyboardEvent): void => {
      switch (event.key) {
        // Spanish-specific characters
        case 'ñ':
        case 'Ñ':
        case '¡':
        case '¿':
          detectedLanguage.value = 'Spanish';
          break;
        // German-specific characters
        case 'ß':
        case 'ä':
        case 'ö':
        case 'ü':
          detectedLanguage.value = 'German';
          break;
        // French-specific characters
        case 'é':
        case 'è':
        case 'ê':
        case 'ç':
          detectedLanguage.value = 'French';
          break;
        // Italian-specific characters
        case 'ò':
        case 'à':
        case 'ù':
          detectedLanguage.value = 'Italian';
          break;
        // Portuguese-specific characters
        case 'ã':
        case 'õ':
          detectedLanguage.value = 'Portuguese';
          break;
        // Russian-specific Cyrillic characters
        case 'й':
        case 'ц':
        case 'у':
        case 'к':
        case 'е':
        case 'н':
        case 'г':
        case 'ш':
        case 'щ':
        case 'з':
        case 'х':
        case 'ъ':
          detectedLanguage.value = 'Russian';
          break;
        default:
          setInitialLanguage();
          break;
      }
      console.log("Detected Language:", detectedLanguage.value);
    };
     onMounted(() => {
      browserLanguage.value = navigator.language || navigator.languages[0] || 'Unknown';
      setInitialLanguage();
    });

    return {
      browserLanguage,
      detectedLanguage,
      detectKeyEvent
    };
  }
});
