import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "badge badge-dim badge-success ml-1"
}
const _hoisted_2 = {
  key: 1,
  class: "badge badge-soft-warning"
}
const _hoisted_3 = {
  key: 2,
  class: "badge rounded-pill bg-danger text-white ml-1"
}
const _hoisted_4 = {
  key: 3,
  class: "badge badge-soft-success"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.phase === 'Open' || _ctx.phase === 'open')
    ? (_openBlock(), _createBlock("span", _hoisted_1, "Open"))
    : (_ctx.phase === 'Pending' || _ctx.phase === 'pending')
      ? (_openBlock(), _createBlock("span", _hoisted_2, "Pending"))
      : (_ctx.phase === 'Close' || _ctx.phase === 'close')
        ? (_openBlock(), _createBlock("span", _hoisted_3, "Close"))
        : (_openBlock(), _createBlock("span", _hoisted_4, _toDisplayString(_ctx.phase.detail), 1))
}