
import { defineComponent } from "vue";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  name: "StripePaymentSuccess",
  setup() {
    const router = useRouter();
    function redirectToDashboard() {
      router.push({
        name: "admin-dashboard",
      });
    }
    return {
      redirectToDashboard,
    };
  },
});
