import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "shadowed-card bg-white" }
const _hoisted_2 = { class: "file-name bg-light p-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.todoAttachments, (attachment, attachmentIndex) => {
    return (_openBlock(), _createBlock("li", { key: attachmentIndex }, [
      _createVNode("div", _hoisted_1, [
        _createVNode("div", {
          class: "media-holder",
          style: {"cursor":"pointer"},
          onClick: ($event: any) => (_ctx.viewAttachment(attachment.activeLink))
        }, [
          _createVNode("img", {
            src: attachment.activeLink,
            alt: "",
            height: "150",
            width: "180"
          }, null, 8, ["src"])
        ], 8, ["onClick"]),
        _createVNode("div", _hoisted_2, [
          _createVNode("h5", null, _toDisplayString(attachment.file_name), 1),
          _createVNode("p", null, "(" + _toDisplayString(attachment.convertedFileSize) + ")", 1)
        ])
      ])
    ]))
  }), 128))
}