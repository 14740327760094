
import { defineComponent } from "vue";
export default defineComponent({
  name: "CheckBox",
  props: {
    data: Object,
    editStatus: Boolean,
  },
  setup() {
    return {};
  },
});
