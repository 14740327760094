
import { defineComponent, onMounted, reactive } from "vue";

export default defineComponent({
  name: "RenameModal",
  props : {
      showModal : Boolean,
      rename : String
  },
  setup(props, { emit }) {
    // const router = useRouter();
    const state = reactive({
      data: {
        folderRename: "" as any,
        folderList: [] as any,
        folderTitle: "folder",
        folderHeading: "No folders created",
      },
      ui: {
        error: {
          hasError: false,
          errorList: {
            createNewFolder: [] as any,
            getFolderList: [] as any,
          },
        },
        isLoading: {
          getFolderList: false,
        },
        isPostLoading: {
          createNewFolder: false,
        },
      },
    });
    // function closeRenameModal() {
    //   emit("close");
    //   $("#renameModal").modal("hide");
    // }
    onMounted(() => {
        if(props.showModal){
          state.data.folderRename = props.rename;
            $("#renameModal").modal("show");
        }
      //   getFolderList();
    });

    return {
      state,
      // closeRenameModal,
    };
  },
});
