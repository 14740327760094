
import { defineComponent, reactive } from "vue";
export default defineComponent({
  name: "DigitalSignature",
  props: {
    data: Object,
  },
  setup() {
    const state = reactive({
      title: "" as any,
    });
    // function showTextFieldAdd() {
    //   if (props.data) {
    //     state.title = props.data.title;
    //     // $("#editTitle").modal("show");
    //   }
    // }
    return { state };
  },
});
