<template>
  <div>
    <article class="content">
      <section class="app-column">
        <div class="card pb-1">
          <div class="row align-items-center">
            <div class="col-md-6">
              <div class="inline-items align-middle">
                <!-- <p v-if="activeTab">{{ activeTab }}</p> -->
                <ul>
                  <li v-if="activeScreen === 'mycalender'">
                    <div class="dropdown month-dropdown">
                      <button class="btn dropdown-toggle bg-white border btn-pill" type="button"
                        id="dropdownMenuButton1" data-toggle="dropdown" aria-expanded="false">
                        {{ activeCategory.title }}
                      </button>
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li v-for="(category, categoryIndex) in categoryList" :key="categoryIndex">
                          <a class="dropdown-item" href="javascript:void(0)" data-view-name="month"
                            @click="selectByCategory(category)">{{ category.title }}</a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div class="dropdown month-dropdown">
                      <button class="btn dropdown-toggle bg-white border btn-pill title-case" type="button"
                        id="dropdownMenuButton1" data-toggle="dropdown" aria-expanded="false">
                        {{ calenderView }}
                      </button>
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li>
                          <a class="dropdown-item" href="javascript:void(0)" data-view-name="month"
                            @click="setCalenderViewBy('month', $event)">Monthly</a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="javascript:void(0)" data-view-name="week"
                            @click="setCalenderViewBy('week', $event)">Weekly</a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="javascript:void(0)" data-view-name="day"
                            @click="setCalenderViewBy('day', $event)">Daily</a>
                        </li>
                      </ul>
                    </div>
                  </li>

                  <li>
                    <button class="btn bg-white border btn-pill" @click="todaysEvent">
                      Today
                    </button>
                  </li>
                  <li>
                    <a href="javascript:void(0)" class="prev-btn" @click="prevButton"><i
                        class="fa fa-chevron-left"></i></a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" class="next-btn" @click="nextButton"><i
                        class="fa fa-chevron-right"></i></a>
                  </li>
                  <!-- <li>2024-03-02</li> -->
                  <!-- <li>
                    <div class="dropdown color-select-wrap">
                      <button
                        class="dropdown-toggle"
                        type="button"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span class="color-eclipse"></span>
                      </button>
                      <div class="dropdown-menu">
                        <a class="dropdown-item color-selected" href="#"
                          ><span class="color-eclipse">
                            <i class="fa fa-check"></i> </span
                        ></a>
                        <a class="dropdown-item" href="#"
                          ><span
                            class="color-eclipse"
                            style="background-color: #33b679"
                          ></span
                        ></a>
                        <a class="dropdown-item" href="#"
                          ><span
                            class="color-eclipse"
                            style="background-color: #d50000"
                          ></span
                        ></a>
                        <a class="dropdown-item" href="#"
                          ><span
                            class="color-eclipse"
                            style="background-color: #f6bf26"
                          ></span
                        ></a>
                        <a class="dropdown-item" href="#"
                          ><span
                            class="color-eclipse"
                            style="background-color: #3f51b5"
                          ></span
                        ></a>
                        <a class="dropdown-item" href="#"
                          ><span
                            class="color-eclipse"
                            style="background-color: #8e24aa"
                          ></span
                        ></a>
                      </div>
                    </div>
                  </li> -->
                  <li v-if="state.data.selectByCategory">
                    <p>{{ activeDate() }}</p>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-6 text-end">
              <!-- <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="customCheck1">
                <label class="custom-control-label" for="customCheck1">Collapse duplicate events and disable the detail
                  popup</label>
              </div> -->
            </div>
          </div>
        </div>
        <!-- Split dropright button -->
        <div class="dropright">
          <div :class="scheduleDetails
            ? 'dropdown-menu calendar-toast show'
            : 'dropdown-menu calendar-toast'
            " :style="{ top: `${state.data.modalPosition.clientY}px`, left: `${state.data.modalPosition.clientX}px` }">
            <div class="d-flex justify-content-between align-items-center">
              <h6 class="calendar-heading mr-3">{{ selectedData.title }}</h6>
              <button class="close text-danger" @click="hideDetailsPopup">
                <i class="fa fa-times"></i>
              </button>
            </div>
            <!-- <p>2024.04.07 9am - 2pm</p> -->
            <p>{{ selectedData.formatedDate }}</p>
            <p>{{ selectedData.title }}</p>
            <div class="d-flex mt-2">
              <div class="icon-holder mr-2"><i class="fa fa-user"></i></div>
              <div class="type-desc" v-if="selectedData.attendees && selectedData.attendees.length > 0
              ">
                <p v-for="(member, memberIndex) in selectedData.attendees" :key="memberIndex">
                  {{ member }}
                </p>
              </div>
            </div>
            <!-- <div class="d-flex mt-2">
              <div class="icon-holder mr-2"><i class="fa fa-lock"></i></div>
              <div class="type-desc">
                <p>Free</p>
              </div>
            </div> -->
            <div class="footer-actions border-top p-2 d-flex justify-content-around text-center mt-2"
              v-if="!selectedData.isReadOnly">
              <div class="col border-right">
                <a href="javascript:void(0)" @click="editSelectedSchedule"><i class="fa fa-pencil-alt mr-1"></i>
                  Edit</a>
              </div>
              <div class="col">
                <a href="javascript:void(0)" @click="deleteSelectedScheduleConfirmation"><i
                    class="fa fa-trash-alt mr-1"></i> Delete</a>
              </div>
            </div>
          </div>
        </div>
        <main id="toastCalender"></main>
        <div class="modal-footer" v-if="activeTab">
          <button type="button" class="btn default-btn mr-3" @click="insertTimeSlotToMember">
            Update
          </button>
        </div>
        <!-- <main id="popupapp"></main> -->

        <!-- <button @click="createNewData">Inject Test</button> -->
      </section>
    </article>
    <AppointmentCreateModalCalender v-if="state.data.showAppointCreateModel"
      :showModal="state.data.showAppointCreateModel" :startDateTime="date.start" :endDateTime="date.end"
      @addAppointment="getaddAppointment" @calendarData="appointData" @closeModal="closeModal">
    </AppointmentCreateModalCalender>
    <CreateTimeSlot v-if="state.data.showCreateTimeSlot" :showModal="state.data.showCreateTimeSlot"
      :timeSlot="state.data.newTimeSlot" :action="state.data.action" @addAppointment="getaddTimeSlot"
      @editAppointment="editTimeSlot" @closeModal="closeTimeSlotModal">
    </CreateTimeSlot>
    <AppointmentEditModal :activeSchedule="filteredSelectedData" v-if="state.data.isEditSelectedSchedule"
      :showModal="state.data.isEditSelectedSchedule" @calendarData="scheduleUpdate" @closeModal="closeEditModal">
    </AppointmentEditModal>
    <!-- Delete Modal -->
    <div class="modal-wrap default-modal">
      <div class="modal fade" id="deleteScheduleModal" tabindex="-1" aria-labelledby="deleteScheduleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-sm">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="deleteScheduleModalLabel">
                Delete Schedule
              </h5>
              <button type="button" class="close" data-dismiss="modal" @click="closeDeleteScheduleModal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="alert alert-danger mb-0" role="alert">
                Are you sure ? You want to delete the Schedule.
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn default-btn" @click="deleteSchedule">
                {{
                  ui.isLoading.deleteSelectedSchedule ? "loading..." : "Delete"
                }}
              </button>
              <button type="button" class="btn secondary-btn" data-dismiss="modal" @click="closeDeleteScheduleModal">
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <UpgradeSubscriptionModal v-if="ui.isActive.upgradeSubscription" @closeModal="hideUpdateSubscriptionModal" />
  </div>
</template>

<script>
import "tui-calendar/dist/tui-calendar.css"; // Import calendar CSS
import Calendar from "tui-calendar"; // Import calendar library

import "tui-date-picker/dist/tui-date-picker.css";
import "tui-time-picker/dist/tui-time-picker.css";

import moment from "moment"; // Import moment library
import { config } from "@/Config";
import Chance from "chance";

import { common } from "@/common/services/Common";

import { ethitransService } from "@/services/EthitransService";
import UpgradeSubscriptionModal from '@/common/UpgradeSubscriptionModal.vue'



import AppointmentCreateModalCalender from "@/components/modal/AppointmentCreateModalCalender.vue";
import AppointmentEditModal from "@/components/modal/AppointmentEditModal.vue";
import CreateTimeSlot from "@/components/modal/CreateTimeSlot.vue";
import { TZDate } from '@toast-ui/calendar';

// appointment

export default {
  components: {
    AppointmentCreateModalCalender,
    AppointmentEditModal,
    CreateTimeSlot,
    UpgradeSubscriptionModal,
  },
  props: [
    "activeScreen",
    "data",
    "activeComponent",
    "selectedEmail",
    "startDate",
    "endDate",
    "activePhaseId",
    "phaseDetails",
    "activeTab",
    "slots",
  ],
  data() {
    return {
      views: [
        { id: 1, className: 'tui-full-calendar-layout tui-view-1' },
      ],
      state: {
        data: {
          modalPosition: {
            clientX: 0,
            clientY: 0,
          },
          subscriptionDetails: {
            // eslint-disable-next-line @typescript-eslint/camelcase
            appointment_numbers: 0,
            // eslint-disable-next-line @typescript-eslint/camelcase
            archive_channel: 0,
            // eslint-disable-next-line @typescript-eslint/camelcase
            channel_file_size: 0,
            // eslint-disable-next-line @typescript-eslint/camelcase
            channel_message_history: 0,
            // eslint-disable-next-line @typescript-eslint/camelcase
            channel_per_project: 0,
            // eslint-disable-next-line @typescript-eslint/camelcase
            estimation_per_project: 0,
            // eslint-disable-next-line @typescript-eslint/camelcase
            export_channel_pdf: 0,
            // eslint-disable-next-line @typescript-eslint/camelcase
            files_number: 0,
            // eslint-disable-next-line @typescript-eslint/camelcase
            folder_number: 0,
            // eslint-disable-next-line @typescript-eslint/camelcase
            guests_per_project: 0,
            // eslint-disable-next-line @typescript-eslint/camelcase
            import_option: 0,
            // eslint-disable-next-line @typescript-eslint/camelcase
            invoice_payment: 0,
            // eslint-disable-next-line @typescript-eslint/camelcase
            member_in_smart_contract: 0,
            // eslint-disable-next-line @typescript-eslint/camelcase
            member_per_channel: 0,
            // eslint-disable-next-line @typescript-eslint/camelcase
            members_per_appointment: 0,
            name: "Silver",
            // eslint-disable-next-line @typescript-eslint/camelcase
            pdf_number: 0,
            // eslint-disable-next-line @typescript-eslint/camelcase
            phase_per_smart_contract: 0,
            // eslint-disable-next-line @typescript-eslint/camelcase
            private_channel: 0,
            // eslint-disable-next-line @typescript-eslint/camelcase
            project_number: 0,
            // eslint-disable-next-line @typescript-eslint/camelcase
            sync_option: 0,
            // eslint-disable-next-line @typescript-eslint/camelcase
            task_per_phase: 0,
            // eslint-disable-next-line @typescript-eslint/camelcase
            template_number: 0,
            // eslint-disable-next-line @typescript-eslint/camelcase
            total_channel_member: 0,
            // eslint-disable-next-line @typescript-eslint/camelcase
            total_estimation: 0,
          },
          showAppointCreateModel: false,
          showCreateTimeSlot: false,
          action: "new",
          newTimeSlot: {},
          selectByCategory: false,
        },
      },
      ui: {
        isActive: {
          upgradeSubscription: false,
        },
        isLoading: {
          packageDetailsByUser: false,
          deleteSelectedSchedule: false,
          availabilityCheck: false,
          selectByCategory: false,
        },
        error: {
          errorList: {
            availabilityCheck: [],
            getPhaseDetails: [],
          },
          hasError: false,
        },
      },
      imageBaseUrl: "",
      scheduleDetails: false,
      filteredSelectedData: {},
      selectedData: {},
      scheduleLists: [],
      today: new Date(),
      activeCategory: {
        title: "Appointment",
        uuid: 0,
      },
      date: {
        start: new Date(),
        end: new Date(),
        isEditSelectedSchedule: false,
        isDeleteSelectedSchedule: false,
      },
      filterByView: {
        startDate: "",
        endDate: "",
      },
      calenderView: "week",
      activeView: "Weekly",
      cal: null, // Calendar instance
      categoryList: [],
      selectTitle: [
        // Mock calendar data
        {
          id: "1",
          name: "View All",
          color: "#ffffff",
          borderColor: "#9e5fff",
          backgroundColor: "#9e5fff",
          dragBackgroundColor: "#9e5fff",
        },
        {
          id: "2",
          name: "My Calendar",
          color: "#ffffff",
          borderColor: "#9e5fff",
          backgroundColor: "#9e5fff",
          dragBackgroundColor: "#9e5fff",
        },
        {
          id: "3",
          name: "Work",
          color: "#ffffff",
          borderColor: "#00a9ff",
          backgroundColor: "#00a9ff",
          dragBackgroundColor: "#00a9ff",
        },
        {
          id: "4",
          name: "Smart Contract 1",
          color: "#ffffff",
          borderColor: "#00a9ff",
          backgroundColor: "#00a9ff",
          dragBackgroundColor: "#00a9ff",
        },
        {
          id: "5",
          name: "Smart Contract 2",
          color: "#ffffff",
          borderColor: "#00a9ff",
          backgroundColor: "#00a9ff",
          dragBackgroundColor: "#00a9ff",
        },
      ],
      MOCK_CALENDARS: [
        // Mock calendar data
        {
          id: "1",
          name: "My Calendar",
          color: "#ffffff",
          borderColor: "#9e5fff",
          backgroundColor: "#9e5fff",
          dragBackgroundColor: "#9e5fff",
        },
        {
          id: "2",
          name: "Work",
          color: "#ffffff",
          borderColor: "#00a9ff",
          backgroundColor: "#00a9ff",
          dragBackgroundColor: "#00a9ff",
        },
      ],
    };
  },
  watch: {
    data(newValue, oldValue) {
      // Trigger a method or perform actions when propName1 changes
      // this.methodToTrigger(newValue);
      if (newValue) {
        // alert("Changes");
        this.changeDate();
        // this.cal.prev();
        this.fetchEventData();
      }
    },
    calenderView: function (newValue, oldValue) {
      if (newValue === "week") {
        const weekDates = common.getWeekDates(
          this.cal.getDate() ? this.cal.getDate() : new Date()
        );
        this.filterByView.startDate = weekDates.start;
        this.filterByView.endDate = weekDates.end;

        console.log("WEEK DATES ", weekDates);
        // console.log("WEEK DATES ", this.filterByView.startDate);
      } else if (newValue === "month") {
        const monthDates = common.getMonthDates();
        this.filterByView.startDate = monthDates.start;
        this.filterByView.endDate = monthDates.end;
      } else {
        // for today
        const today = this.cal.getDate() ? this.cal.getDate() : new Date();

        const day = today.getDate();
        const month = today.getMonth() + 1; // Months are zero-indexed, so we add 1
        const year = today.getFullYear();

        const formattedDate =
          year +
          "-" +
          (month < 10 ? "0" : "") +
          month +
          "-" +
          (day < 10 ? "0" : "") +
          day;

        this.filterByView.startDate = formattedDate;
        this.filterByView.endDate = formattedDate;
      }
    },
  },

  mounted() {
    console.log("<<HI>>")
    // Check if the page has been reloaded
    // if (!localStorage.getItem('pageReloaded')) {
    //   // Set the flag in localStorage
    //   localStorage.setItem('pageReloaded', 'true');
    //   // Reload the page
    //   window.location.reload();
    // }
    this.imageBaseUrl = config.imageBaseUrl;
    // week start and end date set
    const weekDates = common.getWeekDates();
    if (this.activeComponent === "timeslot") {
      this.filterByView.startDate = weekDates.start;
      this.filterByView.endDate = weekDates.end;
      // this.insertEventsIntoCalendar(this.fetchEventData());
      // this.checkAvaibility();
    } else {
      this.filterByView.startDate = this.startDate;
      this.filterByView.endDate = this.endDate;
    }
    this.filterByView.startDate = weekDates.start;
    this.filterByView.endDate = weekDates.end;

    this.initCalendar();
    this.packageDetailsByUser(); // Initialize calendar on component mount
    // const customDate = '2024-07-24'; // Custom start date (YYYY-MM-DD format)
    // const customDate = common.formatDateForToastCalender(this.startDate); // Custom start date (YYYY-MM-DD format)
    // startDate-endDate
    // this.setCustomWeekView(customDate);
    // Create a new Date object

    // Usage example:
    // console.log("Today >>", this.cal.getDate());
    // Get the element by its ID
    // const element = document.getElementById('toastCalender');
    // // Attach the click event listener
    // element.addEventListener('click', this.handleClick);

    // testing the code to remove duplicate calender
    // this.$nextTick(() => {
    //   // Remove elements with classes starting with 'tui-full-calendar-layout tui-view-' except 'tui-full-calendar-layout tui-view-1'
    //   const allElements = document.querySelectorAll('.tui-full-calendar-layout');
    //   allElements.forEach(el => {
    //     if (el.classList.contains('tui-full-calendar-layout') &&
    //       !el.classList.contains('tui-view-1')) {
    //       el.remove();
    //     }
    //   });
    // });

    this.$nextTick(() => {
      // Select all elements with classes starting with 'tui-full-calendar-layout tui-view-'
      const allElements = document.querySelectorAll('.tui-full-calendar-layout');

      let view1Element = null;

      allElements.forEach(el => {
        const hasView1Class = el.classList.contains('tui-view-1');
        const hasOtherViewClass = Array.from(el.classList).some(className =>
          className.startsWith('tui-view-') && className !== 'tui-view-1'
        );

        if (hasView1Class) {
          view1Element = el;
        } else if (hasOtherViewClass && view1Element) {
          // If other view class is found, remove the element with 'tui-view-1'
          view1Element.remove();
          view1Element = null;
        }
      });

      // If 'tui-view-1' element was removed or not found, enable calendar interactions
      if (!view1Element) {
        const calendarElement = document.querySelector('.tui-full-calendar');
        if (calendarElement) {
          calendarElement.style.pointerEvents = 'auto';
        }
      }
    });

  },
  // beforeUnmount() {
  //   // Clear the flag in localStorage when the component is about to be destroyed
  //   localStorage.removeItem('pageReloaded');
  // },
  // beforeDestroy() {
  //   // Remove the event listener to prevent memory leaks
  //   const element = document.getElementById('toastCalender');
  //   element.removeEventListener('click', this.handleClick);
  // },
  methods: {
    hideUpdateSubscriptionModal() {
      this.ui.isActive.upgradeSubscription = !this.ui.isActive.upgradeSubscription;
    },
    packageDetailsByUser() {
      // this.state.ui.error.errorsList = [];
      // this.state.ui.error.hasError = false;
      // this.state.ui.isLoading.packageDetailsByUser = true;
      ethitransService
        .packageDetailsByUser()
        .then((res) => {
          console.log("TESTING TOST CALENDER response >>> ", res.data.data.package_features)
          this.state.data.subscriptionDetails = res.data.data.package_features;
          // this.state.ui.isLoading.packageDetailsByUser = false;

        })
        .catch((error) => {
          // common
          //   .sdCatchErr(error, this.state.ui.error.errorsList)
          //   .then((res) => {
          //     this.state.ui.error.hasError = true;
          //     // console.log("CHECK for Error >>>", res)
          //     for (const [key, value] of Object.entries(res)) {
          //       if (Array.isArray(value)) {
          //         this.state.ui.error.errorsList.push(value[0]);
          //       }
          //     }

          //   });
        })
    },
    activeDate() {
      if (this.calenderView === "week") {
        return `${common.formatDateAndTimeInWords(this.cal.getDateRangeStart()._date)} - ${common.formatDateAndTimeInWords(this.cal.getDateRangeEnd()._date)}`
      } else if (this.calenderView === "month") {
        return `${common.formatDateAndTimeInWords(this.cal.getDateRangeStart()._date)} - ${common.formatDateAndTimeInWords(this.cal.getDateRangeEnd()._date)}`
      } else {
        return `${common.formatDateAndTimeInWords(this.cal.getDateRangeStart()._date)}`
      }
      // const date = new Date();
      // const options = { year: 'numeric', month: 'long', day: 'numeric' };
      // return date.toLocaleDateString('en-US', options);
    },
    replaceById(array, idToReplace, newObj) {
      const index = array.findIndex((item) => item.id === idToReplace);
      if (index !== -1) {
        array[index] = newObj;
      }
      return array;
    },
    editTimeSlot(data) {

      const newObject = data;

      // Example usage:
      const idToReplace = newObject.id;
      this.scheduleLists = this.replaceById(
        this.scheduleLists,
        idToReplace,
        newObject
      );
      // this.scheduleLists.push(data);
      console.log("Hello  CM  your CHECK LIST >>> ", this.scheduleLists);
      this.insertEventsIntoCalendar(this.scheduleLists);
    },
    getaddTimeSlot(data) {
      this.scheduleLists.push(data);
      console.log("CHECK LIST >>> ", this.scheduleLists);
      this.insertEventsIntoCalendar(this.scheduleLists);
      this.state.data.showCreateTimeSlot = false;
    },
    appointData(data) {
      console.log("DATA CHECK >>>> ", data);
      this.insertEventsIntoCalendar(this.fetchEventData());
    },
    insertTimeSlotToMember() {
      this.$emit('hideCheckAvaibilityModal', this.scheduleLists);
    },
    getaddAppointment(data) {
      console.log("emit data:::", data);
      this.state.data.showAppointCreateModel =
        !this.state.data.showAppointCreateModel;
    },
    delete() {
      this.cal.deleteEvent("event1", "cal1");
    },
    createNewData() {
      this.cal.createEvents([
        {
          id: "555c28bc-0471-5803-9548-9af223f1c4fa",
          calendarId: "1",
          title: "Vofap erobi lu.",
          body: "",
          isReadOnly: false,
          isPrivate: false,
          location: "1550 Mubugi Court",
          attendees: [
            "Christina Robertson",
            "Lawrence Shelton",
            "Lester Simmons",
          ],
          recurrenceRule: "",
          state: "Free",
          goingDuration: 0,
          comingDuration: 0,
          raw: {
            memo: "Cowrovca jelhe adodebi po pe konuhi daihno uv uri inrablo zugwehu ho reitoviv tamevit abuw ve bor.",
            creator: {
              name: "Lily Thompson",
              avatar:
                "//www.gravatar.com/avatar/49604855d26621098bf82c1cda92ac72",
              email: "nezwoew@gosce.tm",
              phone: "(445) 632-7003",
            },
          },
          isAllday: false,
          category: "task",
          dueDateClass: "morning",
          start: "2024-04-4T23:15:00.000Z",
          end: "2024-04-6T00:15:00.000Z",
        },
      ]);
    },
    // tui-full-calendar-button tui-full-calendar-confirm tui-full-calendar-popup-save
    popuptest() {
      console.log("Testing");
    },
    setCalenderViewBy(view, e) {
      let targetViewName;
      this.calenderView = view;
      this.activeView = this.getReadableViewName(view);

      if ("viewName" in e.target.dataset) {
        targetViewName = e.target.dataset.viewName;
        console.log("CHECK THE ACTIVE VIEW >>>> ", targetViewName);
        this.cal.changeView(targetViewName);
        // checkboxCollapse.disabled = targetViewName === 'month';
        // toggleDropdownState();
        this.fetchEventData();
      }

      // this.initCalendar();
    },
    getReadableViewName(viewType) {
      switch (viewType) {
        case "month":
          return "Monthly";
        case "week":
          return "Weekly";
        case "day":
          return "Daily";
        default:
          throw new Error("no view type");
      }
    },
    changeDate() {
      if (this.calenderView === "week") {
        const weekDates = common.getWeekDates(this.cal.getDate());
        this.filterByView.startDate = weekDates.start;
        this.filterByView.endDate = weekDates.end;
      } else if (this.calenderView === "month") {
        const monthDates = common.getMonthDates();
        this.filterByView.startDate = monthDates.start;
        this.filterByView.endDate = monthDates.end;
      } else {
        // for today
        const today = this.cal.getDate();

        const day = today.getDate();
        const month = today.getMonth() + 1; // Months are zero-indexed, so we add 1
        const year = today.getFullYear();

        const formattedDate =
          year +
          "-" +
          (month < 10 ? "0" : "") +
          month +
          "-" +
          (day < 10 ? "0" : "") +
          day;

        this.filterByView.startDate = formattedDate;
        this.filterByView.endDate = formattedDate;
      }
    },
    todaysEvent() {
      console.log("Today");
      console.log("Today >>", this.cal.getDate());
      this.changeDate();
      this.cal.today();

      if (this.activeComponent === "timeslot") {
        console.log("<<< API CALL >>> ");
        this.checkAvaibility();
        // this.insertEventsIntoCalendar(this.fetchEventData());
        // this.checkAvaibility();
      } else {
        this.fetchEventData();
      }
    },
    prevButton() {
      this.changeDate();
      this.cal.prev();
      if (this.activeComponent === "timeslot") {
        this.checkAvaibility();
        // this.insertEventsIntoCalendar(this.fetchEventData());
        // this.checkAvaibility();
      } else {
        this.fetchEventData();
      }
    },
    nextButton() {
      this.changeDate();
      // this.cal.move(1);
      this.cal.next();
      // console.log("Today >>", this.cal.getDate())

      if (this.activeComponent === "timeslot") {
        this.checkAvaibility();
        // this.insertEventsIntoCalendar(this.fetchEventData());
        // this.checkAvaibility();
      } else {
        this.fetchEventData();
      }
    },
    getEventTemplate(event, isAllday) {
      console.log("");
      const html = [];
      const start = moment(event.start.toDate().toUTCString());
      if (!isAllday) {
        html.push("<strong>" + start.format("HH:mm") + "</strong> ");
      }

      if (event.isPrivate) {
        html.push('<span class="calendar-font-icon ic-lock-b"></span>');
        html.push(" Private");
      } else {
        if (event.recurrenceRule) {
          html.push('<span class="calendar-font-icon ic-repeat-b"></span>');
        } else if (event.attendees.length > 0) {
          html.push('<span class="calendar-font-icon ic-user-b"></span>');
        } else if (event.location) {
          html.push('<span class="calendar-font-icon ic-location-b"></span>');
        }
        html.push(" " + event.title);
      }

      return html.join("");
    },
    checkAvaibility() {
      const payLoad = {
        email: this.selectedEmail,
        // eslint-disable-next-line @typescript-eslint/camelcase
        start_date: this.filterByView.startDate,
        // eslint-disable-next-line @typescript-eslint/camelcase
        end_date: this.filterByView.endDate,
        // eslint-disable-next-line @typescript-eslint/camelcase
        phase_id: this.activePhaseId,
      };
      ethitransService.availabilityCheckByUser(payLoad).then((res) => {
        console.log("PRABHU Response Check:>>> ", res.data.data.availability);
        this.scheduleLists = res.data.data.availability;
        this.scheduleLists.forEach((item) => {
          item.new = false;
        })
        if (this.slots) {
          this.slots.forEach((item) => {

            this.scheduleLists.push(item);
          })
        }
        this.insertEventsIntoCalendar(res.data.data.availability);
      });
    },
    hideDetailsPopup() {
      this.scheduleDetails = false;
    },
    generateUUID() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          const r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    },
    filterById(array, idToFilter) {
      return array.filter((item) => item.id !== idToFilter);
    },
    isSameDay(date1, date2) {
      return date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate();
    },
    getWeekRange(date) {
      const currentDate = new Date(date);
      const start = new Date(currentDate);
      const end = new Date(currentDate);

      // Set start to the previous Sunday
      start.setDate(currentDate.getDate() - currentDate.getDay());

      // Set end to the next Saturday
      end.setDate(currentDate.getDate() + (6 - currentDate.getDay()));

      return { start, end };
    },
    isDateInRange(date, start, end) {
      return date >= start && date <= end;
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      // Extract the date part in 'YYYY-MM-DD' format
      return date.toISOString().split('T')[0];
    },
    setCustomWeekView(customDate) {
      const today = new Date();
      const targetDate = this.isSameDay(new Date(customDate), today) ? today : new Date(customDate);
      const { start, end } = this.getWeekRange(targetDate);

      // Check if customDate falls within the week range
      const isCustomDateInRange = this.isDateInRange(targetDate, start, end);

      if (isCustomDateInRange) {
        // Set the range of dates to display
        this.cal.setDate(start);

        // Override the renderRange method to display the custom date range
        this.cal.renderRange = function () {
          return {
            start: start,
            end: end
          };
        };

        // Force a render to apply the new date range
        this.cal.render();

        // Disable interactions on dates outside the custom date range
        // disableDates(start, end);
      } else {
        console.error('The custom date is not within the week range.');
      }
    },

    initCalendar() {
      this.cal = new Calendar("#toastCalender", {
        // Initialize calendar instance
        defaultView: this.calenderView,
        calendars: this.MOCK_CALENDARS,
        useFormPopup: false,
        useCreationPopup: false,
        useDetailPopup: false,
        eventFilter: function (event) {
          const currentView = this.cal.getViewName();
          if (currentView === "month") {
            return (
              ["allday", "time"].includes(event.category) && event.isVisible
            );
          }

          return event.isVisible;
        },

        // template: {
        //   allday: function (event) {
        //     return this.getEventTemplate(event, true);
        //   },
        //   time: function (event) {
        //     return this.getEventTemplate(event, false);
        //   },
        // },
        // bindInstanceEvents();
      });
      this.cal.on("beforeCreateSchedule", (eventObj) => {
        // MM/DD/YYYY
        function formatDate(date) {
          const d = new Date(date);
          const year = d.getFullYear().toString();
          const month = ('0' + (d.getMonth() + 1)).slice(-2);
          const day = ('0' + d.getDate()).slice(-2);
          return `${month}/${day}/${year}`;
        }
        function resetTime(date) {
          const d = new Date(date);
          d.setHours(0, 0, 0, 0);
          return d;
        }

        const selectedStartDate = resetTime(eventObj.start._date);
        const phaseStartDate = resetTime(this.startDate);
        const selectedEndDate = resetTime(eventObj.end._date);
        const phaseEndDate = resetTime(this.endDate);

        if (selectedStartDate < phaseStartDate || selectedEndDate > phaseEndDate) {
          const phaseStartDateStr = formatDate(this.startDate);
          const phaseEndDateStr = formatDate(this.endDate);
          alert(`Date out of range: ${phaseStartDateStr} to ${phaseEndDateStr}`);
          return;
        }

        // function formatDate(date) {
        //             const d = new Date(date);
        //             const year = d.getFullYear().toString();
        //             const month = ('0' + (d.getMonth() + 1)).slice(-2);
        //             const day = ('0' + d.getDate()).slice(-2);
        //             return `${day}-${month}-${year}`;
        //         }

        //         const selectedStartDateStr = formatDate(eventObj.start._date);
        //         const phaseStartDateStr = formatDate(this.startDate);
        //         const selectedEndDateStr = formatDate(eventObj.end._date);
        //         const phaseEndDateStr = formatDate(this.endDate);

        //         if (selectedStartDateStr < phaseStartDateStr || selectedEndDateStr > phaseEndDateStr) {
        //             alert(`Date out of range: ${phaseStartDateStr} to ${phaseEndDateStr}`);
        //             return;
        //         }

        if (this.scheduleLists.length >= this.state.data.subscriptionDetails.appointment_numbers) {
          // if (this.scheduleLists.length >= 1) {
          this.ui.isActive.upgradeSubscription = true;
        } else {


          this.date.start = eventObj.start._date;
          this.date.end = eventObj.end._date;
          const url = window.location.href.split("#/").pop();
          const page = url
            .split("#/")
            .pop()
            .split("/")[0];
          const pageNext = url
            .split("#/")
            .pop()
            .split("/")[1];

          // console.log("CHECK THE EDIT >>> ", page)
          // console.log("CHECK THE TASK >>> ", pageNext)

          if (this.activeComponent === "timeslot") {
            this.state.data.showCreateTimeSlot = false;
            let data = {};

            if (this.activeTab === "task") {

              if (eventObj.isAllDay) {
                data = {
                  id: this.generateUUID(),
                  // calendarId: "1",
                  title: `${this.phaseDetails.phase.name} - ${this.phaseDetails.phase.child_phases.find(
                    (item) => item.isChecked === true
                  ).name
                    }`,
                  body: "",
                  new: true,
                  isReadOnly: false,
                  isPrivate: false,
                  // location: "1550 Mubugi Court",
                  attendees: [`${this.selectedEmail}`],
                  recurrenceRule: "",
                  state: "Free",
                  goingDuration: 0,
                  comingDuration: 0,
                  backgroundColor: "#33b679",
                  // raw: {
                  //   memo: "Cowrovca jelhe adodebi po pe konuhi daihno uv uri inrablo zugwehu ho reitoviv tamevit abuw ve bor.",
                  //   creator: {
                  //     name: "Lily Thompson",
                  //     avatar:
                  //       "//www.gravatar.com/avatar/49604855d26621098bf82c1cda92ac72",
                  //     email: "nezwoew@gosce.tm",
                  //     phone: "(445) 632-7003",
                  //   },
                  // },
                  isAllday: eventObj.isAllDay,
                  category: eventObj.category,
                  dueDateClass: "morning",
                  start: eventObj.start._date,
                  end: eventObj.end._date,
                  bgColor: "#fdc689"
                };
              } else {
                data = {
                  id: this.generateUUID(),
                  // calendarId: "1",
                  title: `${this.phaseDetails.phase.name} - ${this.phaseDetails.phase.child_phases.find(
                    (item) => item.isChecked === true
                  ).name
                    }`,
                  body: "",
                  new: true,
                  isReadOnly: false,
                  isPrivate: false,
                  // location: "1550 Mubugi Court",
                  attendees: [`${this.selectedEmail}`],
                  recurrenceRule: "",
                  state: "Free",
                  goingDuration: 0.00000,
                  comingDuration: 0,
                  backgroundColor: "#33b679",
                  // raw: {
                  //   memo: "Cowrovca jelhe adodebi po pe konuhi daihno uv uri inrablo zugwehu ho reitoviv tamevit abuw ve bor.",
                  //   creator: {
                  //     name: "Lily Thompson",
                  //     avatar:
                  //       "//www.gravatar.com/avatar/49604855d26621098bf82c1cda92ac72",
                  //     email: "nezwoew@gosce.tm",
                  //     phone: "(445) 632-7003",
                  //   },
                  // },
                  isAllday: false,
                  category: "time",
                  dueDateClass: "morning",
                  start: eventObj.start._date,
                  end: eventObj.end._date,
                  bgColor: "#fdc689"
                };
              }
            } else if (this.activeTab === "phase") {
              if (eventObj.isAllDay) {
                data = {
                  id: this.generateUUID(),
                  // calendarId: "1",
                  title: this.phaseDetails.phase.name,
                  body: "",
                  new: true,
                  isReadOnly: false,
                  isPrivate: false,
                  // location: "1550 Mubugi Court",
                  attendees: [`${this.selectedEmail}`],
                  recurrenceRule: "",
                  state: "Free",
                  goingDuration: 0,
                  comingDuration: 0,
                  // raw: {
                  //   memo: "Cowrovca jelhe adodebi po pe konuhi daihno uv uri inrablo zugwehu ho reitoviv tamevit abuw ve bor.",
                  //   creator: {
                  //     name: "Lily Thompson",
                  //     avatar:
                  //       "//www.gravatar.com/avatar/49604855d26621098bf82c1cda92ac72",
                  //     email: "nezwoew@gosce.tm",
                  //     phone: "(445) 632-7003",
                  //   },
                  // },
                  isAllday: true,
                  category: eventObj.category,
                  dueDateClass: "morning",
                  start: eventObj.start._date,
                  end: eventObj.end._date,
                  bgColor: "#fdc689"
                };
              } else {
                data = {
                  id: this.generateUUID(),
                  // calendarId: "1",
                  title: this.phaseDetails.phase.name,
                  body: "",
                  new: true,
                  isReadOnly: false,
                  isPrivate: false,
                  // location: "1550 Mubugi Court",
                  attendees: [`${this.selectedEmail}`],
                  recurrenceRule: "",
                  state: "Free",
                  goingDuration: 0,
                  comingDuration: 0,
                  // raw: {
                  //   memo: "Cowrovca jelhe adodebi po pe konuhi daihno uv uri inrablo zugwehu ho reitoviv tamevit abuw ve bor.",
                  //   creator: {
                  //     name: "Lily Thompson",
                  //     avatar:
                  //       "//www.gravatar.com/avatar/49604855d26621098bf82c1cda92ac72",
                  //     email: "nezwoew@gosce.tm",
                  //     phone: "(445) 632-7003",
                  //   },
                  // },
                  isAllday: false,
                  category: "time",
                  dueDateClass: "morning",
                  start: eventObj.start._date,
                  end: eventObj.end._date,
                  bgColor: "#fdc689"
                };
              }
            } else if (page === "edit" && pageNext === "task") {
              if (eventObj.isAllDay) {
                data = {
                  id: this.generateUUID(),
                  title: `${this.phaseDetails.phase.name} - ${this.phaseDetails.phase.parent_phase.name}`,
                  body: "",
                  new: true,
                  isReadOnly: false,
                  isPrivate: false,
                  attendees: [`${this.selectedEmail}`],
                  recurrenceRule: "",
                  state: "Free",
                  goingDuration: 0,
                  comingDuration: 0,
                  backgroundColor: "#33b679",
                  isAllday: eventObj.isAllDay,
                  category: eventObj.category,
                  dueDateClass: "morning",
                  start: eventObj.start._date,
                  end: eventObj.end._date,
                  bgColor: "#fdc689"
                };
              } else {
                data = {
                  id: this.generateUUID(),
                  title: `${this.phaseDetails.phase.name} - ${this.phaseDetails.phase.parent_phase.name}`,
                  body: "",
                  new: true,
                  isReadOnly: false,
                  isPrivate: false,
                  attendees: [`${this.selectedEmail}`],
                  recurrenceRule: "",
                  state: "Free",
                  goingDuration: 0.00000,
                  comingDuration: 0,
                  backgroundColor: "#33b679",
                  isAllday: false,
                  category: "time",
                  dueDateClass: "morning",
                  start: eventObj.start._date,
                  end: eventObj.end._date,
                  bgColor: "#fdc689"
                };
              }

            }
            // console.log("<<< NEW MODAL HERE REQUIRED >>> ");
            // testing the modal popup
            // this.scheduleDetails = true;
            // common.formatDateAndTimeSlot
            // this.selectedData = data;
            // this.selectedData.formatedDate = common.formatDateWithTimes(
            //   this.selectedData.start._date,
            //   this.selectedData.end._date
            // );

            // previous code
            // console.log("CHECK >>> ", data);
            // console.log(" Your data is >>>", JSON.stringify(data, null, 2));


            // this.scheduleLists.push(data);
            // console.log("CHECK LIST >>> ", this.scheduleLists);
            // this.insertEventsIntoCalendar(this.scheduleLists);
            // this.checkAvaibility();

            // new modal code
            this.state.data.action = "new";
            this.state.data.newTimeSlot = data;

            this.state.data.showCreateTimeSlot = true;

            // testing codes
          } else {
            this.state.data.showAppointCreateModel =
              !this.state.data.showAppointCreateModel;
          }
        }
      });
      this.cal.on("beforeUpdateSchedule", (eventObj) => {
        // console.log("Update Schedule >>> ", eventObj.schedule);
      });
      // for default preview modal and delete trigger
      // this.cal.on('beforeDeleteSchedule',(eventObj) =>{
      //   console.log("Delete Schedule >>> ", eventObj.schedule);
      // });
      this.cal.on("clickSchedule", (eventObj) => {
        console.log("Click Schedule >>> ", eventObj)
        if (this.scheduleLists.length >= this.state.data.subscriptionDetails.appointment_numbers) {
          // if (this.scheduleLists.length >= 1) {
          this.ui.isActive.upgradeSubscription = true;
        } else {
          // console.log("CLICkj READ >>> ", eventObj.schedule.isReadOnly);
          // if(eventObj.schedule.isReadOnly) return false;
          if (this.scheduleDetails) {
            this.scheduleDetails = false;
          } else {
            // const menuHeight = dropdownMenu.value ? dropdownMenu.value.clientHeight : 0;
            console.log("ELEMENT TEST >>> ", eventObj.event.toElement.getBoundingClientRect())
            const boundReact = eventObj.event.toElement.getBoundingClientRect();
            // console.log("CLICK FOR SCHEDULE Client X >>> ", eventObj.event);
            // console.log("CLICK FOR SCHEDULE Client Y >>> ", eventObj.event.clientY);
            this.state.data.modalPosition.clientX = boundReact.left;
            this.state.data.modalPosition.clientY = boundReact.top - 180;
            this.scheduleDetails = true;
          }
          // common.formatDateAndTimeSlot
          this.selectedData = eventObj.schedule;
          eventObj.schedule.formatedDate = common.formatDateWithTimes(
            this.selectedData.start._date,
            this.selectedData.end._date
          );
        }
      });
      if (this.activeComponent === "timeslot") {
        console.log("<<< API CALL >>> ");
        this.checkAvaibility();
        // this.insertEventsIntoCalendar(this.fetchEventData());
        // this.checkAvaibility();
      } else {
        this.insertEventsIntoCalendar(this.fetchEventData());
      }
      // Run the disableDates function when the calendar is rendered
      // this.cal.on('afterRenderSchedule', disableDates);
      // this.cal.on('afterRender', disableDates);
    },
    editSelectedSchedule() {
      this.scheduleDetails = false;
      if (this.activeComponent === "timeslot") {
        this.state.data.showCreateTimeSlot = true;
        const selected = this.scheduleLists.filter(
          (item) => item.id === this.selectedData.id
        );
        console.log("HELLO SELECRED >>>> ", selected[0]);
        this.state.data.action = "edit";
        this.state.data.newTimeSlot = selected[0];
      } else {
        const selected = this.scheduleLists.filter(
          (item) => item.id === this.selectedData.id
        );
        console.log("HELLO SELECRED >>>> ", selected[0]);
        this.filteredSelectedData = selected[0];

        this.state.data.isEditSelectedSchedule = true;
      }

      // id
    },
    closeEditModal() {
      // this.scheduleDetails = false;
      this.state.data.isEditSelectedSchedule = false;
    },
    closeDeleteScheduleModal() {
      $("#deleteScheduleModal").modal("hide");
      this.filteredSelectedData = {};
      this.state.data.isDeleteSelectedSchedule = false;
    },
    deleteSelectedScheduleConfirmation() {
      this.scheduleDetails = false;
      this.state.data.isDeleteSelectedSchedule = true;
      const selected = this.scheduleLists.filter(
        (item) => item.id === this.selectedData.id
      );
      console.log("HELLO Selected is >>>> ", selected[0]);
      this.filteredSelectedData = selected[0];
      $("#deleteScheduleModal").modal("show");
    },
    closeModal() {
      this.state.data.showAppointCreateModel =
        !this.state.data.showAppointCreateModel;
    },
    closeTimeSlotModal() {
      this.state.data.showCreateTimeSlot = false;
      this.state.data.closeTimeSlotModal = !this.state.data.closeTimeSlotModal;

    },
    // Define other methods for handling calendar behaviors

    insertEventsIntoCalendar(events) {
      // events.forEach((event) => {
      //   console.log("INSIDE >> ", event)
      //   // Add event to the calendar
      this.cal.clear();
      this.cal.createSchedules(events);
      // });
    },
    selectByCategory(data) {
      this.activeCategory = data;
      this.ui.isLoading.selectByCategory = true;
      this.state.data.selectByCategory = false;
      // console.log("Date CHECK  >>> ", common.dateFormated(this.cal.getDateRangeStart()._date));
      ethitransService
        .eventsByCalender(
          data.uuid,
          common.dateFormated(this.cal.getDateRangeStart()._date),
          common.dateFormated(this.cal.getDateRangeEnd()._date)
        )
        .then((res) => {
          const newData = res.data.data.events.map((i) => {
            i.start = common.localDateAndTime(i.start);
            i.end = common.localDateAndTime(i.end);
            return i;
          });
          this.state.data.selectByCategory = true;
          console.log("eventsByCalender Response Check:>>> ", newData);
          res.data.data.filteredEvents = [];
          if (res.data.data.events) {
            this.scheduleLists = newData;
            this.insertEventsIntoCalendar(newData);
          }
          this.ui.isLoading.selectByCategory = false;
        });
    },

    fetchEventData() {
      this.ui.error.errorList.getPhaseDetails = [];
      this.ui.error.hasError = false;
      // this.cal.clear();

      ethitransService.calenderGroupList().then((res) => {
        console.log("getPhaseDetails Response Check:>>> ", res.data.data);
        this.categoryList = res.data.data;
        const data = {
          title: "Appointment",
          uuid: "",
        };
        this.selectByCategory(data);
      });
    },
    scheduleUpdate() {
      this.changeDate();
      this.fetchEventData();
    },
    deleteSchedule() {
      const selectedSchedule = this.filteredSelectedData;
      this.ui.isLoading.deleteSelectedSchedule = true;
      if (this.activeComponent === "timeslot") {
        // this.filterById(this.scheduleLists, selectedSchedule.id)
        this.scheduleLists = this.filterById(
          this.scheduleLists,
          selectedSchedule.id
        );
        this.insertEventsIntoCalendar(this.scheduleLists);
        this.ui.isLoading.deleteSelectedSchedule = false;
        this.closeDeleteScheduleModal();
      } else {
        // for appointment delete
        ethitransService.deleteScheduleById(selectedSchedule.id).then((res) => {
          this.ui.isLoading.deleteSelectedSchedule = false;
          console.log("eventsByCalender Response Check:>>> ", res.data.data);
          this.changeDate();
          this.fetchEventData();
          this.closeDeleteScheduleModal();
        });
      }
    },
  },
};
</script>

<style scoped>
.title-case {
  text-transform: capitalize;
}

/* Add any necessary styles here */
.navbar .dropdown {
  margin-right: 1rem;
}

.navbar .dropdown .toastui-calendar-icon {
  margin-left: 0.5rem;
}

.button.prev,
.button.next {
  padding: 0.8rem;
}

.navbar .button+.button {
  margin-left: 0.25rem;
}

.toastui-calendar-dropdown-menu {
  position: absolute;
  width: 100%;
  top: 31px;
  z-index: 1;
  padding: 4px 0;
  background-color: #fff;
  border: 1px solid #d5d5d5;
  border-top: none;
  border-radius: 0 0 2px 2px;
}

.toastui-calendar-dropdown-menu.toastui-calendar-open {
  display: block;
}

.toastui-calendar-dropdown-menu-item {
  width: 100%;
  height: 30px;
  border: none;
  padding: 0 9px 0 12px;
  font-size: 0;
  border-radius: 2px;
  cursor: pointer;
}

.toastui-calendar-popup-section-item.toastui-calendar-dropdown-menu-item {
  display: block;
  height: 30px;
  border: none;
  cursor: pointer;
}

.toastui-calendar-dropdown-menu-item .toastui-calendar-content {
  display: inline-block;
  position: relative;
  padding-left: 8px;
  text-align: left;
  font-size: 12px;
  vertical-align: middle;
}

.dropdown-menu {
  position: absolute;
  width: 100%;
  top: 31px;
  z-index: 1;
  padding: 4px 0;
  background-color: #fff;
  border: 1px solid #d5d5d5;
  border-top: none;
  border-radius: 0 0 2px 2px;
}

.dropdown-menu.open {
  display: block;
}

.dropdown-menu-item {
  width: 100%;
  height: 30px;
  border: none;
  padding: 0 9px 0 12px;
  font-size: 0;
  border-radius: 2px;
  cursor: pointer;
}

.dropdown-menu.calendar-toast {
  padding: 15px 15px;
  border-top: 3px solid #58bd35;
  width: 280px;
  right: auto;
  left: 50%;
  transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
}

.disabled-date {
  background-color: rgba(255, 0, 0, 0.1) !important;
  pointer-events: none;
}
</style>