<template>
  <div class="otp-inputs">
    <input
      v-for="(value, index) in otp"
      :key="index"
      v-model="otp[index]"
      @input="handleInput(index)"
      @paste="handlePaste($event)"
      maxlength="1"
      type="text"
      class="otp-box"
    />
  </div>
</template>
<script>
import { ref } from 'vue';

export default {
  setup() {
    const otp = ref(['', '', '', '']); // Initialize OTP array with 4 empty strings
    // Move focus to the next input
    const focusNextInput = (index) => {
      const nextInput = document.querySelectorAll('.otp-box')[index + 1];
      if (nextInput) {
        nextInput.focus();
      }
    };

    // Handle single character input
    const handleInput = (index, event) => {
      const value = event.target.value;

      if (value.length > 1) {
        otp.value[index] = value.charAt(0); // Keep only the first character
      }

      // Automatically move to the next input when a digit is entered
      if (index < otp.value.length - 1 && value) {
        focusNextInput(index);
      }
    };

    

    // Handle OTP paste
    const handlePaste = (event) => {
      const pasteData = event.clipboardData.getData('text');
      const otpArray = pasteData.split('').slice(0, 4); // Split the pasted OTP and limit to 4 digits

      otp.value = otpArray; // Update the OTP array with pasted digits

      // Focus the first empty input after paste
      for (let i = 0; i < otpArray.length; i++) {
        if (otpArray[i] === '') {
          document.querySelectorAll('.otp-box')[i].focus();
          break;
        }
      }

      event.preventDefault(); // Prevent default paste behavior
    };

    return {
      otp,
      handleInput,
      handlePaste,
    };
  },
};
</script>



<style scoped>
.otp-box {
  width: 40px;
  height: 40px;
  margin: 0 5px;
  text-align: center;
  font-size: 18px;
}
</style>

