
import { defineComponent } from "vue";
export default defineComponent({
  name: "Loader",
  props: {
    msg: String,
  },
  setup() {
    return {};
  },
});
