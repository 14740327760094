
import { defineComponent, reactive, onMounted, computed, watch } from "vue";
import { ethitransService } from "@/services/EthitransService";
import SuccessfulModalCustom from "@/components/modal/SuccessfulModalCustom.vue";
import EmptyData from "@/components/commonDesign/EmptyData.vue";
import { useRouter } from "vue-router";

// import FormView from "@/components/cards/FormView.vue";
export default defineComponent({
  name: "BusinessMailTemplate",
  components: {
    EmptyData,
    SuccessfulModalCustom,
  },
  setup() {
    const router = useRouter();
    const state = reactive({
      mailTemplateList: [] as any,
      message:"" as any,
      data: {
        successMessage: "",
        showSuccesCustomModal: false,
        title: "Business Mail Template List",
        heading: "No Mail Template found .",
        paging: {
          clientDocumentList: {
            perPage: 10,
            totalDocs: 0,
            totalPages: 0,
            currentPage: 1,
          },
        },
      },
      ui: {
        isLoading: {},
        isPostLoading: {
          getUserActivityList: false,
          saveEditUser: false,
        },
          errors: {
          errorList: {
            errorList: [] as any,
          },
          hasError: false,
        },
      },
       
    });

 
 function showMailTemplate(template: any) {
       state.message = template.mail_template;
         return $("#mailTemplate").modal("show");
     
    }

   function hideMailTemplate() {
      $("#mailTemplate").modal("hide");
    }

    function allBusinessTemplate() {
      ethitransService
        .businessMailTemplateList()
        .then((res: any) => {
          console.log("getProjectRoleList Response Check:>>> ", res.data.data);
          state.mailTemplateList = res.data.data ? res.data.data : [];
      
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          // state.ui.isPostLoading.getUserActivityList = false;
        });
    }

    function createBusinessMailTemplate(){
      router.push({
          name: "create-business-mail-template",
        });
    }

   function editBusinessMailTemplate(temId: any) {
      router.push({
        name: "edit-business-mail-template",
        params: temId,
      });
    }
  
    onMounted(() => {
      allBusinessTemplate();

    });
    return {
      state,
      allBusinessTemplate,
      createBusinessMailTemplate,
      editBusinessMailTemplate,
      showMailTemplate,
      hideMailTemplate

    };
  },
});
