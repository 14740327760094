
import { defineComponent, reactive, watch } from "vue";
import { useRoute } from "vue-router";

import { ethitransService } from "@/services/EthitransService";
import { common } from "@/common/services/Common";

export default defineComponent({
  name: "MyDriveFileSearch",
  props: {
    folerId: {
      type: String,
    },
  },
  components: {
    // RenameDocumentModal,
  },
  setup(props, { emit }) {
    const router = useRoute();
    const state = reactive({
      data: {
        searchByTag: "" as any,
        folderId: "" as any,
      },
      ui: {
        error: {
          hasError: false,
          errorList: {
            searchFileTags: [] as any,
          },
        },
        isLoading: {
          projectListActivities: false,
        },
        isPostLoading: {
          searchFileTags: false,
        },
      },
    });
    function searchFileTags() {
      state.ui.error.errorList.searchFileTags = [];
      state.ui.error.hasError = false;
      // console.log("Check the Length >>> ", state.data.searchByTag.length);
      emit("searchByTag", state.data.searchByTag);
      if (state.data.searchByTag.length < 2) return false;
      if (state.ui.isPostLoading.searchFileTags) return false;
      // if(state.ui.isPostLoading.searchFileTags && state.data.searchByTag.length > 1) return false
      const payLoad = {
        tag: state.data.searchByTag,
        // eslint-disable-next-line @typescript-eslint/camelcase
        folder_id: props.folerId ? props.folerId : '',
      };
      state.ui.isPostLoading.searchFileTags = true;
      ethitransService
        .searchFileTags(payLoad)
        .then((res: any) => {
          // console.log("searchFileTags Response Check:>>> ", res.data.data);
          if (res.data.data.folders && res.data.data.folders.length) {
            res.data.data.folders.forEach((folder: any) => {
              folder.isChecked = false;
              if (folder.size) {
                folder.convertedFileSize = common.formatBytes(
                  parseInt(folder.size)
                );
              }
            });
          }
          if (res.data.data.files && res.data.data.files.length) {
            res.data.data.files.forEach((file: any) => {
              file.isChecked = false;
              if (file.filesize) {
                file.convertedFileSize = common.formatBytes(
                  parseInt(file.filesize)
                );
              }
              if (file.filetype === "mov" || file.filetype === "MOV") {
                // eslint-disable-next-line @typescript-eslint/camelcase
                file.filetype = "mp4";
              }
            });
          }
          emit("folderList", res.data.data);
          // state.data.folderList = res.data.data ? res.data.data : {};
        })
        .catch((error: any) => {
          console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.searchFileTags)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.searchFileTags.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.searchFileTags = false;
          if (
            state.ui.error.errorList.searchFileTags &&
            state.ui.error.errorList.searchFileTags.length &&
            state.ui.error.hasError
          ) {
            emit("errorList", state.ui.error.errorList.searchFileTags);
            emit("hasError", state.ui.error.hasError);
          }
        });
      // watch(
      //   () => state.ui.error.errorList.searchFileTags,
      //   (newvalue, oldvalue) => {
      //     if (state.ui.error.errorList.searchFileTags.length === 0) {
      //       emit("errorList", state.ui.error.errorList.searchFileTags);
      //     }
      //   }
      // );
      // watch(
      //   () => state.ui.error.hasError,
      //   (newvalue, oldvalue) => {
      //     if (state.ui.error.hasError === false) {
      //       emit("hasError", state.ui.error.hasError);
      //     }
      //   }
      // );
    }
    watch(
      () => router.query.folderId,
      (newvalue, oldvalue) => {
        // if (newvalue) {
          console.log("CHECK THE FOLDER ID >>> ",newvalue);
          state.data.folderId = newvalue;
        // }
      }
    );
    // if (router.query.folderId) {
    //     console.log("CHECK THE FOLDER ID >>> ",router.query.folderId);
    //     // getFolderDetails(router.query.folderId);
    //   }
    // onMounted(() => {
    //   if (router.query.folderId) {
    //     console.log("CHECK THE FOLDER ID >>> ",router.query.folderId);
    //     // getFolderDetails(router.query.folderId);
    //   }
    //   //   console.log("API CALL");
    // });

    return {
      state,
      searchFileTags,
    };
  },
});
