
import { defineComponent, onMounted, reactive, watch, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ethitransService } from "@/services/EthitransService";

import ErrorList from "@/components/commonDesign/ErrorList.vue";
import MyDriveFolder from "@/components/MyDriveFolder.vue";
import MyDriveFile from "@/components/MyDriveFile.vue";
import SuccessfulModalCustom from "@/components/modal/SuccessfulModalCustom.vue";
import ShareModal from "@/components/modal/ShareModal.vue";
import MyDriveFileSearch from "@/components/MyDriveFileSearch.vue";
import MyDriveFilesUpload from "@/components/MyDriveFilesUpload.vue";
import DeleteSelectedModal from "@/components/modal/DeleteSelectedModal.vue";
import DragAndDropZone from "@/common/DragAndDropZone.vue";

// import RenameModal from '@/components/modal/RenameModal.vue'

import EmptyData from "@/components/commonDesign/EmptyData.vue";
import { common } from "@/common/services/Common";
// import Multiselect from "@vueform/multiselect";

export default defineComponent({
  name: "DocumentDetails",
  components: {
    ErrorList,
    DragAndDropZone,
    DeleteSelectedModal,
    SuccessfulModalCustom,
    MyDriveFolder,
    MyDriveFile,
    EmptyData,
    ShareModal,
    MyDriveFileSearch,
    MyDriveFilesUpload,
    // RenameModal,
    // vSelect,
    // Multiselect,
    // GoogleAddress,
    // CreateProjectModal,
  },
  setup() {
    const router = useRoute();
    const routerRedirect = useRouter();
    const state = reactive({
      data: {
        uploadMaxSize: 25 * 1024 * 1024 as any,
        showDeleteModal: false,
        showAddFileTag: false,
        folderId: "" as any,
        search: "",
        successFulMessage: "",
        searchByTag: "",
        folderTitle: "folder",
        folderHeading: "No folders created",
        fileTitle: "file",
        fileHeading: "No files created",
        disabledFileTagList: [] as any,
        imagesToUpload: [] as any,
        selectedFiles: [] as any,
        folderList: {
          breadcrumbs: [] as any,
          files: [] as any,
          folders: [] as any,
        },
        // folderList: {} as any,
        showShareModal: false,
        showSuccesCustomModal: false,
        showRenameModal: false,
        folderRename: "",
        imageBaseUrl: "",
        folderName: "",
        tags: {
          mode: "tags",
          value: [] as any,
          options: [] as any,
          searchable: true,
          createTag: true,
        },
      },
      ui: {
        error: {
          hasError: false,
          errorList: {
            createNewFolder: [] as any,
            getFolderDetails: [] as any,
          },
        },
        isLoading: {
          projectListActivities: false,
          getFolderDetails: false,
        },
        isPostLoading: {
          enableSelectedFileTag: false,
          createNewFolder: false,
          addTagsToFile: false,
        },
      },
    });
    function showDeleteSelectedModal() {
      // alert("<<< Seleted Selected SHOW >>>> ")
      state.data.showDeleteModal = !state.data.showDeleteModal;
    }
    function closeDeleteMultipleModal(data: any) {
      state.data.showDeleteModal = !state.data.showDeleteModal;
      data.forEach((item: any) => {
        // console.log("CHECK MULTIPE DETELE DATA >>> ",item)
        state.data.folderList.files = state.data.folderList.files.filter(
          (e: any) => e.uuid != item
        );

      })
      // state.data.folderList.files.forEach((item: any) => {
      //   item.isChecked = false;
      // });
      state.data.selectedFiles = [];
      // state.data.folderList.files = state.data.folderList.files.filter(
      //   (e: any) => e.uuid != data
      // );
      // if (data.uuid) {
      //   state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
      // }
      state.data.successFulMessage = "Selected Files Deleted Successfully.";
      state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
    }
    function masterTagListForValidation(data: boolean) {
      state.ui.error.errorList.getFolderDetails = [];
      state.ui.error.hasError = false;
      const payLoad = {
        type: data ? "enabled" : "disabled",
      };
      ethitransService
        .masterFileTagList(payLoad)
        .then((res: any) => {
          console.log("masterTagList Check:>>> ", res.data.data);
          // res.data.data.forEach((item: any) => {
          //   item.label = item.name;
          //   item.value = item.code;
          // })
          // state.data.tagListForValidation = res.data.data ? res.data.data : [];
          const disabledTagList: any = [];
          state.data.imagesToUpload.forEach((item: any) => {
            item.tag.forEach((tag: any) => {
              // console.log("CHECK Tag >>",tag);
              res.data.data.forEach((data: any) => {
                // console.log("CHECK THE API TAG >>>", data);

                if (tag.toLowerCase() === data.name.toLowerCase()) {
                  data.inProgress = false;
                  // console.log("tag values >>> ",item )
                  // console.log("tagListForValidation  values >>> ", data.name)
                  disabledTagList.push(data);
                }
              });

            })

            // 
          });
          // console.log("LIST>>> ", disabledTagList);
          const uniqueTagList = Array.from(new Set(disabledTagList));
          // const tag: any = []
          state.data.disabledFileTagList = uniqueTagList
            ? uniqueTagList
            : [];

          if (
            state.data.disabledFileTagList &&
            !state.data.disabledFileTagList.length
          ) {
            state.data.showAddFileTag = true;
          }
          // state.data.disabledFileTagList.forEach((item: any)=> {
          // state.data.showAddFileTag = true

          // })
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.getFolderDetails)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.getFolderDetails.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          // state.postLoading = false;
        });
    }
    // eslint-disable-next-line vue/return-in-computed-property
    const masterTags = computed(() => {
      const array: any = [];
      state.data.tags.options.forEach((item: any) => {
        array.push(item.name);
      });

      return array;
    });
    const folderList = computed(() => {
      console.log(
        "WOWO",
        state.data.folderList.folders.filter((item: any) => {
          return item.name.toLowerCase() == state.data.search.toLowerCase();
        })
      );
      return state.data.folderList.folders.filter((item: any) => {
        return item.name.toLowerCase() == state.data.search.toLowerCase();
      });
      // return state.data.folderList.folders.filter.search(state.data.search.toLowerCase()) !== -1
      // return state.data.folderList.folders.filter((word: any) => {
      //   console.log("CHECK >>>", word)
      //   word.name.toLowerCase() === state.data.search.toLowerCase()});
      // return state.data.folderList;
    });
    function redirectToFileTagSettings() {
      routerRedirect.push({
        name: "admin-drive-tag-settings",
      });
    }
    function masterTagList() {
      const payLoad = {
        type: 'enabled'
      }
      ethitransService
        .masterFileTagList(payLoad)
        .then((res: any) => {
          // console.log("masterTagList Check:>>> ", res.data.data);
          res.data.data.forEach((item: any) => {
            item.label = item.name;
            item.value = item.code;
          });
          state.data.tags.options = res.data.data ? res.data.data : [];
        })
        .catch((error: any) => {
          // common.sdCatchErr(error, state.errorList).then((res: any) => {
          //   state.isErrorStatus = true;
          //   // console.log("CHECK for Error >>>", res)
          //   for (const [key, value] of Object.entries(res)) {
          //     if (Array.isArray(value)) {
          //       state.errorList.push(value[0]);
          //     }
          //   }
          //   // if (res) {
          //   //   if (res.error) {
          //   //     console.log("CHECK for Error >>>", res.error[0])
          //   //     // state.errorList.push(res.email[0]);
          //   //     // state.errorList = [...state.errorList]
          //   //   }
          //   // }
          // });
        })
        .finally(() => {
          // state.postLoading = false;
        });
    }
    function addTag(tag: any) {
      // console.log("Check the New Tag >>>", tag);
      const payLoad = {
        name: tag,
      };
      // state.postLoading = true;
      ethitransService
        .createMasterFileTag(payLoad)
        .then((res: any) => {
          // console.log("createMasterTag:>>> ", res.data.data);
          if (res.data.data && res.data.data.is_active === 1) {
            state.data.tags.options.push(res.data.data);
          } else {
            state.data.tags.options.push({ name: tag });
            masterTagListForValidation(false);
          }
        })
        .catch((error: any) => {
          console.log("Erorrs:>>> ", error);
        })
        .finally(() => {
          // state.postLoading = false;
        });
    }
    const fileList = computed(() => {
      // return state.data.folderList.files.filter((word: any )=> {word.length === state.data.search});
      return state.data.folderList;
    });
    function closeShareModal(data: any) {
      // console.log("DATA CHECK >>>", data)
      state.data.showShareModal = !state.data.showShareModal;
      state.data.folderList.files.forEach((item: any) => {
        item.isChecked = false;
      });
      state.data.selectedFiles = [];
      //show successModal
      if (data.uuid) {
        state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
      }
    }
    function showShareModal() {
      // alert("Hello")
      state.data.showShareModal = !state.data.showShareModal;
    }
    function filterSelectedFiles(data: any) {
      if (!data.isChecked) {
        state.data.selectedFiles.push(data);
        // console.log("CHECK Data >> ", data)
      } else {
        state.data.selectedFiles = state.data.selectedFiles.filter(
          (e: any) => e.uuid != data.uuid
        );
      }
      console.log("CHECK Data >> ", state.data.selectedFiles);
      // state.data.selectedFiles.forEach((item: any, index: number)=> {
      //   if(item.uuid != data.uuid && data.isChecked){
      //     state.data.selectedFiles.push(data)
      //   } else {
      //       state.data.selectedFiles = state.data.selectedFiles.filter(
      //   (e: any) => e.uuid != data.uuid
      // );
      //   }

      // })
    }
    function checkedSelctedFile(data: object) {
      filterSelectedFiles(data);
      // state.data.selectedFiles.push(data)
      // console.log("my drive files >>> ", state.data.selectedFiles)
    }
    function deleteSelectedFolder(data: object) {
      // alert("HDHDHD")
      // state.data.folderList.folders.filter((data: any, index: number) => {
      //   if (data.uuid === item.uuid) {
      //     console.log("FOUND RENAMED DATA >>>", data);
      //     state.data.folderList.folders[index] = item;
      //   }
      // });
      console.log("Deleted Folder >>> ", data);
      // state.data.folderList.folders
      state.data.folderList.folders = state.data.folderList.folders.filter(
        (e: any) => e != data
      );
    }
    function closeRenameFileModal(item: any) {
      state.data.folderList.files.filter((data: any, index: number) => {
        if (data.uuid === item.uuid) {
          console.log("FOUND RENAMED DATA >>>", data);
          state.data.folderList.files[index] = item;
        }
      });
    }
    function redirectingToMyDrive() {
      routerRedirect.push({
        name: "admin-drive",
      });
    }
    function redirectToSelectedFolder(folder: any) {
      if (folder.folder_uuid === router.query.folderId) return false;
      console.log("Selected Folder >>> ", folder);
      routerRedirect.push({
        name: "admin-document-details",
        query: { folderId: folder.folder_uuid },
      });
    }
    const folderActive = computed(() => {
      return state.data.folderList.breadcrumbs.length - 1;
    });
    function deleteSelectedFile(data: object) {
      console.log("Data CHECK FOR DELETE FILE>>>", data);
      state.data.folderList.files = state.data.folderList.files.filter(
        (e: any) => e != data
      );
    }
    function renameSelectedFolder(item: any) {
      state.data.folderList.folders.filter((data: any, index: number) => {
        if (data.uuid === item.uuid) {
          console.log("FOUND RENAMED DATA >>>", data);
          state.data.folderList.folders[index] = item;
        }
      });
    }
    // function closeRenameModal() {
    //   state.data.showRenameModal = !state.data.showRenameModal;
    //   // emit("close");
    //   $("#renameModal").modal("hide");
    // }
    function closeSuccessCustomModal() {
      state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
      $("#SuccessfulModalCustom").modal("hide");
    }
    // function showRenameModal() {
    //   console.log("rename Modal opended")
    //   state.data.showRenameModal = !state.data.showRenameModal;
    //   // $("#renameModal").modal("hide");
    // }
    // function closeRenameModal() {
    //   state.data.showRenameModal = !state.data.showRenameModal;
    //   // $("#renameModal").modal("hide");
    // }
    function showCreateFolder() {
      state.data.folderName = "";
      state.ui.error.errorList.createNewFolder = [];
      state.ui.error.hasError = false;
      $("#createSubFolder").modal("show");
    }
    function hideCreateFolder() {
      $("#createSubFolder").modal("hide");
    }
    function validateCreateNewFolder() {
      if (!state.data.folderName) {
        state.ui.error.errorList.createNewFolder.push(
          "Folder name is required."
        );
      }
      if (
        !state.ui.error.errorList.createNewFolder &&
        !state.ui.error.errorList.createNewFolder.length
      ) {
        state.ui.error.hasError = false;
      } else if (state.ui.error.errorList.createNewFolder.length != 0) {
        state.ui.error.hasError = true;
      }
    }
    function createNewFolder() {
      state.ui.error.errorList.createNewFolder = [];
      state.ui.error.hasError = false;
      validateCreateNewFolder();
      if (
        state.ui.error.errorList.createNewFolder.length != 0 ||
        state.ui.error.hasError ||
        state.ui.isPostLoading.createNewFolder
      )
        return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        folder_id: router.query.folderId,
        name: state.data.folderName,
      };
      state.ui.isPostLoading.createNewFolder = true;
      ethitransService
        .createFolder(payLoad)
        .then((res: any) => {
          console.log("Project List Response Check:>>> ", res.data.data);
          state.data.folderList.folders.unshift(res.data.data);
          hideCreateFolder();
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
          state.data.successFulMessage = "Created Folder Successfully.";
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.createNewFolder)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.createNewFolder.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.createNewFolder = false;
        });
    }
    function getFolderDetails(folderId: any) {
      state.ui.error.errorList.getFolderDetails = [];
      state.ui.error.hasError = false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        folder_id: folderId,
      };

      state.ui.isLoading.getFolderDetails = true;
      ethitransService
        .folderDetails(payLoad)
        .then((res: any) => {
          console.log("getFolderDetails Response Check:>>> ", res.data.data);
          if (res.data.data) {
            if (res.data.data.folders && res.data.data.folders.length) {
              res.data.data.folders.forEach((folder: any) => {
                if (folder.size) {
                  folder.convertedFileSize = common.formatBytes(
                    parseInt(folder.size)
                  );
                }
              });
            }
            if (res.data.data.files && res.data.data.files.length) {
              res.data.data.files.forEach((file: any) => {
                if (file.filesize) {
                  file.convertedFileSize = common.formatBytes(
                    parseInt(file.filesize)
                  );
                }
              });
            }
            state.data.folderList = res.data.data ? res.data.data : {};
          }
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.getFolderDetails)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.getFolderDetails.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isLoading.getFolderDetails = false;
        });
    }
    function latestEnabledTags(data: any) {
      // alert("HELLO");
      state.data.tags.options = data;
    }
    function addTagsToFile(data: any, attachment: any) {
      // alert('INSIDE TAG')
      const tagIds = [] as any;

      state.data.tags.options.forEach((item: any) => {
        //  console.log("Check the Tag >>>", item)
        //  console.log("Check the attachment  tag>>>", data.tag)
        attachment.tag.forEach((dataItem: any) => {
          //     // if(item.name === data){
          //     // console.log("check Ids ", item)
          // console.log("Check Selected Tag on file >>>", dataItem);
          // console.log("check Tag ", item);
          if (item.name.toLowerCase() === dataItem.toLowerCase()) {
            tagIds.push(item.id);
          }
        });
      });
      // alert("CHECK");
      state.data.imagesToUpload = state.data.imagesToUpload.filter(
        (e: any) => e.base64 != attachment.base64
      );

      // alert("DONE");

      // })
      // console.log("Check the tag ID COllection >>> ", tagIds);

      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_attachment_id: "",
        // eslint-disable-next-line @typescript-eslint/camelcase
        file_id: data.id,
        // eslint-disable-next-line @typescript-eslint/camelcase
        master_file_tag_id: tagIds,
      };
      console.log("Check PayLoad >>> ", payLoad);
      state.ui.isPostLoading.addTagsToFile = false;
      ethitransService
        .createFileTags(payLoad)
        .then((res: any) => {

          console.log("addTagsToFile in MY Drive Directly:>>> ", res.data.data);
          getFolderDetails(router.query.folderId);
          // state.data.imagesToUpload = state.data.imagesToUpload.filter(
          //   (e: any) => e.base64 != attachment.base64
          // );
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.getFolderDetails)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.getFolderDetails.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.addTagsToFile = false;
        });
    }

    function uploadAttachments(data: any) {
      //  state.data.imagesToUpload = state.data.imagesToUpload.filter(
      //         (e: any) => !e.duplicateFile
      //       );
      state.ui.error.errorList.createNewFolder = [];
      state.ui.error.hasError = false;
      if (
        state.ui.error.errorList.createNewFolder.length != 0 ||
        state.ui.error.hasError ||
        data.isuploded
      )
        return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        folder_id: router.query.folderId,
        filename: data.fileName,
        extension: data.extension,
        file: data.base64,
        // eslint-disable-next-line @typescript-eslint/camelcase
        // master_file_tag_id: tagIds,
      };
      data.isuploded = true;
      state.ui.isPostLoading.createNewFolder = true;
      ethitransService
        .fileUpload(payLoad)
        .then((res: any) => {
          masterTagList();
          data.isuploded = false;
          console.log("File upload", res.data);
          if (res.data.data.filesize) {
            res.data.data.convertedFileSize = common.formatBytes(
              parseInt(res.data.data.filesize)
            );
          }
          if (
            res.data.data.filetype === "mov" ||
            res.data.data.filetype === "MOV"
          ) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            res.data.data.filetype = "mp4";
          }
          state.data.folderList.files.unshift(res.data.data);
          console.log("ATTTCH CHECK >>>", data)
          if (data.tag && data.tag.length) {

            addTagsToFile(res.data.data, data);
          } else {

            state.data.imagesToUpload = state.data.imagesToUpload.filter(
              (e: any) => e.base64 != data.base64
            );
          }
          if (state.data.imagesToUpload.length === 0) {
            //show successModal
            state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
            state.data.successFulMessage = "Uploaded Successfully.";
          }
          // console.log("Project List Response Check:>>> ", res.data.data);
          // state.data.folderList.folders.unshift(res.data.data);
          // hideCreateFolder();
          // state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.createNewFolder)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.createNewFolder.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.createNewFolder = false;
        });
    }
    function removeSelectedImage(image: any) {
      // if (state.postLoading) return false;
      state.data.imagesToUpload = state.data.imagesToUpload.filter(
        (e: any) => e != image
      );
    }
    function uploadImage() {
      console.log("INITIAL CHECK >>>> ", state.data.imagesToUpload)
      const filtered: any = state.data.imagesToUpload.filter((data: any) => {
        return data.duplicateFile === false
      });
      state.data.imagesToUpload = filtered
      console.log("FINAL CHECK >>>> ", state.data.imagesToUpload)
      state.data.imagesToUpload.forEach((item: any, i: any) => {
        console.log("CHECK THE ITEM >>> ", item)
        if (item.isvalidToUpload) {
          uploadAttachments(item);
        } else {
          state.data.imagesToUpload = state.data.imagesToUpload.filter((e: any) => e != item);
        }
      });

    }

    function readImage(file: any) {
      // console.log("CHECK IMAGE>>", file);
      // let data = "";
      const data = {} as any;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        // console.log("BIT BIT>>", e);
        const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
        const extensionLower = extension.toLowerCase();
        data.base64 = e.target.result;
        data.duplicateFile = false;
        // data.fileName = file.name;
        data.fileName = file.name.replaceAll(`.${extension}`, "");
        state.data.folderList.files.forEach((item: any) => {
          // console.log("TEST loop>>>", item.filename.toLowerCase());
          if (
            file.name.toLowerCase() === item.filename.toLowerCase() &&
            !file.duplicateFile
          ) {
            // console.log("FOUND MATCH WITH >>> ", item.filename.toLowerCase());
            data.duplicateFile = true;
            // console.log("FOUND MATCH STATUS >>> ", data.duplicateFile);
          }
          //   }
        });
        // console.log("Attachment Name Check >>>", data.fileName)

        if (file.size > state.data.uploadMaxSize) {
          data.isValidToUpload = false;
        } else {
          data.isvalidToUpload = true;
        }
        data.extension = extensionLower;
        data.isuploded = false;
        data.size = common.formatBytes(parseInt(file.size));
        // console.log("UPLOAD READY FILE >>>", data);
        state.data.imagesToUpload.push(data);
        // console.log("CHECK DATA FOR UPLOAD>>", data);
        // state.imageList.push(data);
      };
      reader.readAsDataURL(file);
      masterTagList();
    }
    function onDocumentChange(event: any) {
      const files = event.target.files || event.dataTransfer.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        console.log("FILE CHECK >>>", file);
        // if(file.type)
        const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const extensionLower = extension.toLowerCase();

        readImage(file);

        // console.log("CHECK DATA WHEN UPLOADED>>", file);
        // console.log("Extension Check >>> ", extensionLower);
      }
    }
    const dropFiles = (event: any) => {
      const files = event.target.files || event.dataTransfer.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        readImage(file);
      }
    }

    watch(
      () => router.query.folderId,
      (newvalue, oldvalue) => {
        // console.log("Data Check new >>>", newvalue);
        // console.log("Data Check Old >>>", oldvalue);
        if (newvalue) {
          getFolderDetails(newvalue);
        }
      }
    );
    function searchFolderList(data: any) {
      console.log("CHeck Folder List >>>", data);
      state.data.folderList = data;
    }
    function searchErrorList(data: any) {
      console.log("CHeck Error List >>>", data);
      if (data) {
        state.ui.error.errorList.getFolderDetails = data;
      } else {
        state.ui.error.errorList.getFolderDetails = [];
      }
    }
    function searchHasError(data: any) {
      console.log("CHeck has Error >>>", data);
      if (data) {
        state.ui.error.hasError = data;
      } else {
        state.ui.error.hasError = false;
      }
    }
    function updateFileTag(data: any) {
      console.log("File Tag to My Drive Update >>>", data);
      getFolderDetails(router.query.folderId);
      // state.data.folderList.files.forEach((file: any) => {
      //   if (file.uuid === data.uuid) {
      //     console.log("CHECK >>>", file)
      //     file = data;
      //     console.log("CHECK FINAL>>>", file)
      //     // file_tag
      //   }
      // });
    }
    function searchByTag(data: any) {
      state.data.searchByTag = data;
    }

    watch(
      () => state.data.searchByTag,
      (newvalue, oldvalue) => {
        if (!newvalue) {
          getFolderDetails(router.query.folderId);
        }
      }
    );
    function enableSelectedFileTag(document: any) {
      console.log("Enable Doc >> ", document.id);
      if (document.enableStatus) return false;
      const payLoad = {
        id: document.id,
      };
      state.ui.isPostLoading.enableSelectedFileTag = true;
      document.inProgress = true;
      ethitransService
        .enableMasterFileTag(payLoad)
        .then((res: any) => {
          console.log("enable File Tag :>>> ", res.data.data);
          state.data.disabledFileTagList = state.data.disabledFileTagList.filter(
            (data: any) => data.id != document.id
          );
          masterTagList();
          // state.data.successFulMessage = "File Tag Enabled Successfully.";
          // state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;

        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.enableSelectedFileTag = false;
          document.inProgress = false;
        });
    }
    function getReadyImageListToUpload(data: any) {
      // console.log("READY IMAGES >>>", data)
      state.data.imagesToUpload = data
    }
    watch(
      () => router.query.folderId,
      (newFolderId: any, oldFolderId: any) => {
        if (newFolderId && newFolderId !== oldFolderId) {
          state.data.folderId = newFolderId as string;
        }
      }
    );
    onMounted(() => {
      if (router.query.folderId) {
        getFolderDetails(router.query.folderId);
        state.data.folderId = router.query.folderId;
      }
      //   console.log("API CALL");
    });

    return {
      state,
      dropFiles,
      showDeleteSelectedModal,
      closeDeleteMultipleModal,
      latestEnabledTags,
      getReadyImageListToUpload,
      enableSelectedFileTag,
      masterTagListForValidation,
      redirectToFileTagSettings,
      addTag,
      masterTagList,
      masterTags,
      searchByTag,
      updateFileTag,
      searchFolderList,
      searchErrorList,
      searchHasError,
      folderList,
      fileList,
      getFolderDetails,
      showCreateFolder,
      createNewFolder,
      validateCreateNewFolder,
      hideCreateFolder,
      closeSuccessCustomModal,
      readImage,
      onDocumentChange,
      removeSelectedImage,
      uploadAttachments,
      uploadImage,
      renameSelectedFolder,
      deleteSelectedFolder,
      folderActive,
      redirectingToMyDrive,
      closeRenameFileModal,
      deleteSelectedFile,
      redirectToSelectedFolder,
      closeShareModal,
      showShareModal,
      filterSelectedFiles,
      checkedSelctedFile,
    };
  },
});
