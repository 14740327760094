
import { defineComponent } from "vue";

export default defineComponent({
  name: "ToDoPhaseStatus",
  props: {
    // phase: {
    //   type: String,
    // },
    phase: {
      type: Object,
    },
  },
});
