
import { defineComponent,reactive, onMounted } from "vue";
import { config } from "@/Config.ts";

export default defineComponent({
  name: "HistoryCard",
  props: {
    activity: Object,
  },
  setup(props) {
    const state = reactive({
      titleMember: "pending members",
      headingMember: "Get started by inviting users in your project.",
      data: {
        imageBaseUrl: "",
      },
    })
    onMounted(() => {
      state.data.imageBaseUrl = config.imageBaseUrl;
      //  $(".modal-backdrop").remove();
    });
    // console.log("CHECK HistoryCard props", props.activity);
    // console.log("CHECK props", props.member.id);
    return {state};
  },
});
