
import { defineComponent, onMounted, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import { ethitransService } from "@/services/EthitransService";
import { common } from "@/common/services/Common";

import { config } from "@/Config";

import ErrorList from "@/components/commonDesign/ErrorList.vue";
import GoogleMapView from "@/components/commonDesign/GoogleMapView.vue";
import GoogleMap from "@/components/commonDesign/GoogleMap.vue";
import SuccessfulModalCustom from "@/components/modal/SuccessfulModalCustom.vue";
// import Multiselect from "@vueform/multiselect";
import Multiselect from 'vue-multiselect';
// import VueGoogleAutocomplete from "vue-google-autocomplete";
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';

export default defineComponent({
  name: "ProjectCard",
  props: {
    projectList: Array,
    channelLimit: Number,
  },
  components: {
    ErrorList,
    SuccessfulModalCustom,
    Multiselect,
    // VueGoogleAutocomplete,
    GoogleMapView,
    GoogleMap
  },
  setup(props, { emit }) {
    const scrollPosition = ref(0);
    const router = useRouter();
    // console.log("Check the props", props.projectList)
    const options = ref([]) as any;
    const latitude = ref<number>(36.0755227);  // Default latitude
    const longitude = ref<number>(-79.8203066);  // Default longitude
    const address = ref<string>('');
    const showMap = ref<boolean>(false);

    const updateLatitude = (newLatitude: number) => {
      latitude.value = newLatitude;
    };

    const updateLongitude = (newLongitude: number) => {
      longitude.value = newLongitude;
    };

    const updateAddress = (newAddress: string) => {
      address.value = newAddress;
    };
    const selectedOptions = ref([]) as any;
    const state = reactive({
      membersList: [] as any,
      coOwner: [] as any,
      isIcludeCoOwner: false,
      data: {
        tags: {
          mode: "tags",
          value: [] as any,
          options: [] as any,
          searchable: true,
          createTag: true,
        },
        selectedProject: {} as any,
        selectedProjectMap: {} as any,
        selectedProjectEdit: {
          projectName: "",
          projectDescription: "",
          projectTags: "",
          projectAddress: "",
        } as any,
        membersList: [] as any,
        showSuccesCustomModal: false,
        successFulMessage: "",
        imageBaseUrl: "",
        projectId: "",
        createChannel: {
          name: "",
          description: "",
        },
      },
      googleAddress: {
        streetAddress: "",
        route: "",
        city: "",
        state: "",
        postalCode: "",
        country: "",
        latitude: "",
        longitude: "",

      },
      ui: {
        postLoading: {
          createChannel: false,
          projectArchive: false,
          saveEditProject: false,
          projectFav: false,
          projectFavRemove: false,
          projectShow: false,
          projectHide: false,
        },
        error: {
          errorList: [] as any,
          errorsList: {
            masterTagList: [] as any,
            projectArchive: [] as any,
            projectFav: [] as any,
            projectFavRemove: [] as any,
            saveEditProject: [] as any,
            projectShow: [] as any,
            projectHide: [] as any,
          },
          isErrorStatus: false,
        },
      },
    });
    function masterTagList() {
      state.ui.error.errorsList.masterTagList = [];
      state.ui.error.isErrorStatus = false;
      ethitransService
        .masterTagList()
        .then((res: any) => {
          // console.log("masterTagList Check:>>> ", res.data.data);
          res.data.data.forEach((item: any) => {
            item.label = item.name;
            item.value = item.code;
          });
          state.data.tags.options = res.data.data ? res.data.data : [];
          options.value = res.data.data ? res.data.data : [];
        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.ui.error.errorsList.masterTagList)
            .then((res: any) => {
              state.ui.error.isErrorStatus = true;
              // console.log("CHECK for Error >>>", res)
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorsList.masterTagList.push(value[0]);
                }
              }
              // if (res) {
              //   if (res.error) {
              //     console.log("CHECK for Error >>>", res.error[0])
              //     // state.errorList.push(res.email[0]);
              //     // state.errorList = [...state.errorList]
              //   }
              // }
            });
        })
        .finally(() => {
          // state.postLoading = false;
        });
    }
    function addTag(tag: any) {
      // console.log("Check the New Tag >>>", tag);
      const payLoad = {
        name: tag,
      };
      // state.postLoading = true;
      ethitransService
        .createMasterTag(payLoad)
        .then((res: any) => {
          // console.log("createMasterTag:>>> ", res.data.data);
          options.value.push(res.data.data);
          selectedOptions.value.push(res.data.data);
        })
        .catch((error: any) => {
          // common.sdCatchErr(error, state.errorList).then((res: any) => {
          //   state.isErrorStatus = true;
          //   // console.log("CHECK for Error >>>", res)
          //   for (const [key, value] of Object.entries(res)) {
          //     if (Array.isArray(value)) {
          //       state.errorList.push(value[0]);
          //     }
          //   }
          //   // if (res) {
          //   //   if (res.error) {
          //   //     console.log("CHECK for Error >>>", res.error[0])
          //   //     // state.errorList.push(res.email[0]);
          //   //     // state.errorList = [...state.errorList]
          //   //   }
          //   // }
          // });
        })
        .finally(() => {
          // state.postLoading = false;
        });
    }
    // function clearAddressData() {
    //   (document.getElementById("autocomplete") as HTMLInputElement).value = "";
    //   (document.getElementById("street_number") as HTMLInputElement).value = "";
    //   (document.getElementById("route") as HTMLInputElement).value = "";
    //   (document.getElementById("locality") as HTMLInputElement).value = "";
    //   (
    //     document.getElementById(
    //       "administrative_area_level_1"
    //     ) as HTMLInputElement
    //   ).value = "";
    //   (document.getElementById("postal_code") as HTMLInputElement).value = "";
    //   (document.getElementById("country") as HTMLInputElement).value = "";
    // }
    const setScrollPosition = (position: number) => {
      console.log("Number  >>> ", position)
      window.scrollTo({
        top: position,
        behavior: 'smooth' // Optional: smooth scrolling
      });
    };
    function threeDot() {
      const number: any = window.scrollY;
      scrollPosition.value = Number(number);
      console.log("CHECK >>> ", number)
      // localStorage.setItem("scroll-Height", scrollPosition.value);
      // console.log("Whole Height Check >>> ", document.documentElement.scrollHeight || document.body.scrollHeight)
      // console.log('Current Scroll Position:', scrollPosition.value);
      // const storedScrollPosition =scrollPosition.value;
      // // if (storedScrollPosition > 0) {
      //   setScrollPosition(storedScrollPosition);
      // }
    }
    function renameProjectModal(data: any) {



      masterTagList();
      selectedOptions.value = [];
      state.data.selectedProject = JSON.parse(JSON.stringify(data));
      address.value = state.data.selectedProject.full_address;
      state.data.selectedProjectEdit.projectName =
        state.data.selectedProject.name;
      state.data.selectedProjectEdit.projectDescription =
        state.data.selectedProject.description;
      latitude.value = state.data.selectedProject.latitude;
      longitude.value = state.data.selectedProject.longitude;
      // eslint-disable-next-line @typescript-eslint/camelcase

      // state.googleAddress.latitude= state.data.selectedProject.latitude;
      // state.googleAddress.longitude = state.data.selectedProject.longitude;
      state.data.selectedProject.tags.forEach((tag: any) => {
        // eslint-disable-next-line @typescript-eslint/camelcase
        // state.data.tags.value.push(tag.master_tag.name);
        // eslint-disable-next-line @typescript-eslint/camelcase
        selectedOptions.value.push(tag.master_tag);
      });

      console.log("edit data ", state.data.selectedProject);
      // state.data.selectedProjectEdit.projectAddress = state.data.selectedProject.name;
      $("#renameModal").modal("show");
    }
    function hideRenameProjectModal() {
      state.data.selectedProject = {};
      state.data.tags.value = [];
      // (address as any).value.clear();
      // clearAddressData();
      $("#renameModal").modal("hide");
    }
    function saveEditProject() {
      state.data.tags.value = [];
      selectedOptions.value.forEach((tag: any) => {
        state.data.tags.value.push(tag.code)
      });
      state.ui.error.errorList = [];
      state.ui.error.isErrorStatus = false;

      if (
        state.ui.error.errorList.length != 0 ||
        state.ui.error.isErrorStatus ||
        state.ui.postLoading.saveEditProject
      )
        return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        id: state.data.selectedProject.id,
        name: state.data.selectedProjectEdit.projectName,
        description: state.data.selectedProjectEdit.projectDescription,
        // streetAddress: state.googleAddress.streetAddress,
        // city: state.googleAddress.city,
        // route: state.googleAddress.route,
        // state: state.googleAddress.state,
        // postalCode: state.googleAddress.postalCode,
        // country: state.googleAddress.country,
        tags: state.data.tags.value,
        latitude: latitude.value,
        longitude: longitude.value,
        // eslint-disable-next-line @typescript-eslint/camelcase
        full_address: address.value
      };
      console.log("Payload Check >>> ", payLoad);
      state.ui.postLoading.saveEditProject = true;
      ethitransService
        .projectUpdate(payLoad)
        .then((res: any) => {
          console.log("saveEditProject Response Check:>>> ", res.data.data);
          emit("updateProjectDetails", res.data.data);
          // getProjectDetail();
          // if (res) {
          //   // state.channelList.unshift(res.data.data)
          // }
          hideRenameProjectModal();
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList)
            .then((res: any) => {
              state.ui.error.isErrorStatus = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.postLoading.saveEditProject = false;
        });
    }
    function closeSuccessCustomModal() {
      state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
      $("#SuccessfulModalCustom").modal("hide");
    }
    function hideMemberListModal() {
      $("#showMemberList").modal("hide");
    }

    function allMembers(membersList: any) {
      if (membersList && !membersList.length) return false;
      // console.log("CHECK LIST", membersList)
      $("#showMemberList").modal("show");
      state.membersList = membersList;

      // project.members
    }
    function validateForm() {
      state.ui.error.errorList = [];
      state.ui.error.isErrorStatus = false;

      if (!state.data.createChannel.name) {
        state.ui.error.errorList.push("Channel name is required.");
      }
      if (!state.data.createChannel.description) {
        state.ui.error.errorList.push("Channel description is required.");
      }
      if (!state.ui.error.errorList && !state.ui.error.errorList.length) {
        state.ui.error.isErrorStatus = false;
      } else if (state.ui.error.errorList.length != 0) {
        state.ui.error.isErrorStatus = true;
      }
    }
    function hideCreateChannel() {
      state.data.createChannel.name = "";
      state.data.createChannel.description = "";
      $("#createChannel").modal("hide");
    }
    function showCreateChannel(selectedProject: any) {
      state.isIcludeCoOwner = false;
      state.coOwner = selectedProject.members.filter((item: any) => item.project_role.code == 'co-owner')
        .map((item: any) => ({ ...item, isChecked: false }));
      state.ui.error.errorList = [];
      state.ui.error.isErrorStatus = false;
      if (!selectedProject.uuid) return false;
      state.data.projectId = selectedProject.uuid;
      state.data.createChannel.name = "";
      state.data.createChannel.description = "";
      if (selectedProject.channels.length === props.channelLimit) {
        // alert("HI");
        emit("showUpgrade");
      } else {
        $("#createChannel").modal("show");
      }
    }
    function createChannel() {
      state.ui.error.errorList = [];
      state.ui.error.isErrorStatus = false;

      validateForm();
      if (
        state.ui.error.errorList.length != 0 ||
        state.ui.error.isErrorStatus ||
        state.ui.postLoading.createChannel
      )
        return false;
      const userList = state.coOwner.filter((item: any) => item.isChecked).map((item: any) => item.user.id);
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: state.data.projectId,
        name: state.data.createChannel.name,
        description: state.data.createChannel.description,
        member: state.isIcludeCoOwner ? userList : [],

      };
      state.ui.postLoading.createChannel = true;
      ethitransService
        .createChannel(payLoad)
        .then((res: any) => {
          emit("customChannel", res.data.data);
          hideCreateChannel();
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList)
            .then((res: any) => {
              state.ui.error.isErrorStatus = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.postLoading.createChannel = false;
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
          state.data.successFulMessage = "Channel Created Successfully.";
        });
    }
    function selectedProjectArchive(project: any) {
      // state.data.projectId = project.uuid;
      emit("showCustomModal");
      emit("activeProjectId", project.uuid);
      // $("#deleteModal").modal("show");
    }
    function selectedProjectRestore(project: any) {
      state.data.projectId = project.uuid;
      $("#restoreModal").modal("show");
    }
    function closeProjectArchiveModal() {
      state.data.projectId = "";
      $("#deleteModal").modal("hide");
    }
    function closeProjectRestoreModal() {
      state.data.projectId = "";
      $("#restoreModal").modal("hide");
    }

    function addFavProjectShow(project: any) {
      state.data.projectId = "";
      state.data.projectId = project.id;
      $("#addFavProjectModal").modal("show");
    }
    function addFavProjectClose() {
      state.data.projectId = "";
      $("#addFavProjectModal").modal("hide");
    }

    function removeFavProjectShow(project: any) {
      state.data.projectId = "";
      state.data.projectId = project.id;
      $("#removeFavProjectModal").modal("show");
    }
    function removeFavProjectClose() {
      state.data.projectId = "";
      $("#removeFavProjectModal").modal("hide");
    }

    function hideProjectShow(project: any) {
      state.data.projectId = project.id;
      $("#hideProjectModal").modal("show");
    }
    function hideProjectClose() {
      state.data.projectId = "";
      $("#hideProjectModal").modal("hide");
    }

    function showProjectShow(project: any) {
      state.data.projectId = project.id;
      $("#showProjectModal").modal("show");
    }
    function showProjectClose() {
      state.data.projectId = "";
      $("#showProjectModal").modal("hide");
    }

    function projectArchive() {
      state.ui.error.errorsList.projectArchive = [];
      state.ui.error.isErrorStatus = false;

      // validateForm();
      if (
        state.ui.error.errorsList.projectArchive.length != 0 ||
        state.ui.error.isErrorStatus ||
        state.ui.postLoading.projectArchive
      )
        return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: state.data.projectId,
      };
      state.ui.postLoading.projectArchive = true;
      ethitransService
        .projectDelete(payLoad)
        .then((res: any) => {
          console.log("Project List Response Check:>>> ", res.data.data);
          closeProjectArchiveModal();
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
          state.data.successFulMessage = "Project Successfully Archived.";
          emit("selectedProject", res.data.data);
          // getProjectDetail();
          // if (res) {
          //   // state.channelList.unshift(res.data.data)
          // }
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorsList.projectArchive)
            .then((res: any) => {
              state.ui.error.isErrorStatus = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorsList.projectArchive.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.postLoading.projectArchive = false;
        });
    }
    function projectRestore() {
      state.ui.error.errorsList.projectArchive = [];
      state.ui.error.isErrorStatus = false;

      // validateForm();
      if (
        state.ui.error.errorsList.projectArchive.length != 0 ||
        state.ui.error.isErrorStatus ||
        state.ui.postLoading.projectArchive
      )
        return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: state.data.projectId,
      };
      state.ui.postLoading.projectArchive = true;
      ethitransService
        .projectRestore(payLoad)
        .then((res: any) => {
          closeProjectRestoreModal();
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
          state.data.successFulMessage = "Project Successfully Restored.";
          emit("selectedProject", res.data.data);
          // getProjectDetail();
          // if (res) {
          //   // state.channelList.unshift(res.data.data)
          // }
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorsList.projectArchive)
            .then((res: any) => {
              state.ui.error.isErrorStatus = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorsList.projectArchive.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.postLoading.projectArchive = false;
        });
    }

    function projectFav() {
      state.ui.error.errorsList.projectFav = [];
      state.ui.error.isErrorStatus = false;

      // validateForm();
      if (
        state.ui.error.errorsList.projectFav.length != 0 ||
        state.ui.error.isErrorStatus ||
        state.ui.postLoading.projectFav
      )
        return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: state.data.projectId,
      };
      state.ui.postLoading.projectFav = true;
      ethitransService
        .projectFav(payLoad)
        .then((res: any) => {
          addFavProjectClose();
          emit("updateProjectList", res.data.data);
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
          state.data.successFulMessage = " Project successfully pinned.";
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorsList.projectArchive)
            .then((res: any) => {
              state.ui.error.isErrorStatus = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorsList.projectArchive.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.postLoading.projectFav = false;
        });
    }

    function projectFavRemove() {
      state.ui.error.errorsList.projectFavRemove = [];
      state.ui.error.isErrorStatus = false;

      // validateForm();
      if (
        state.ui.error.errorsList.projectFav.length != 0 ||
        state.ui.error.isErrorStatus ||
        state.ui.postLoading.projectFavRemove
      )
        return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: state.data.projectId,
      };
      state.ui.postLoading.projectFavRemove = true;
      ethitransService
        .projectFavRemove(payLoad)
        .then((res: any) => {
          removeFavProjectClose();
          emit("updateProjectList", res.data.data);
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
          state.data.successFulMessage = "Project Unpinned.";

        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorsList.projectArchive)
            .then((res: any) => {
              state.ui.error.isErrorStatus = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorsList.projectArchive.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.postLoading.projectFavRemove = false;
        });
    }

    function projectHide() {
      state.ui.error.errorsList.projectHide = [];
      state.ui.error.isErrorStatus = false;
      if (
        state.ui.error.errorsList.projectHide.length != 0 ||
        state.ui.error.isErrorStatus ||
        state.ui.postLoading.projectFav
      )
        return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: state.data.projectId,
      };
      state.ui.postLoading.projectHide = true;
      ethitransService
        .projectHide(payLoad)
        .then((res: any) => {
          hideProjectClose();
          emit("updateProjectList", res.data.data);
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
          state.data.successFulMessage = "Project Successfully Hide.";
        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.ui.error.errorsList.projectArchive)
            .then((res: any) => {
              state.ui.error.isErrorStatus = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorsList.projectArchive.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.postLoading.projectHide = false;
        });
    }

    function projectShow() {
      state.ui.error.errorsList.projectShow = [];
      state.ui.error.isErrorStatus = false;

      // validateForm();
      if (
        state.ui.error.errorsList.projectShow.length != 0 ||
        state.ui.error.isErrorStatus ||
        state.ui.postLoading.projectShow
      )
        return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: state.data.projectId,
      };
      state.ui.postLoading.projectShow = true;
      ethitransService
        .projectShow(payLoad)
        .then((res: any) => {
          showProjectClose();
          emit("updateProjectList", res.data.data);
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
          state.data.successFulMessage = "Project Successfully Show.";
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorsList.projectArchive)
            .then((res: any) => {
              state.ui.error.isErrorStatus = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorsList.projectArchive.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.postLoading.projectShow = false;
        });
    }

    function getAddressData(addressData: any, placeResultData: any, id: any) {
      console.log("Updated data is ", addressData);
      state.googleAddress.streetAddress = addressData.street_number;
      state.googleAddress.city = addressData.locality + addressData.administrative_area_level_2;
      state.googleAddress.route = addressData.route;
      state.googleAddress.state = addressData.administrative_area_level_1;
      state.googleAddress.postalCode = addressData.postal_code;
      state.googleAddress.country = addressData.country;
      state.googleAddress.latitude = addressData.latitude;
      state.googleAddress.longitude = addressData.longitude;
      state.data.selectedProjectEdit.projectAddress = addressData.street_number + ' ' + addressData.route + ',' + '' + addressData.locality + ',' + addressData.administrative_area_level_2 + ',' + addressData.administrative_area_level_1 + ',' + addressData.country;
      console.log("Updated Google Address in State:", state.googleAddress);
    }

    function truncate(data: any, limit: any) {
      if (data.length > limit) {
        return data.substring(0, limit) + "...";
      }
      return data;
    }

    function showProjectLocation(project: any) {
      state.data.selectedProjectMap = project;
      $("#showLocation").modal("show");
    }
    function hideProjectLocation() {
      state.data.selectedProjectMap = {};
      $("#showLocation").modal("hide");
    }
    function redirectToProjectDetail(projectId: any) {
      console.log("Hello >>> ", projectId)
      router.push({
        name: "admin-project-details",
        params: { projectId: projectId },
      });
    }

    onMounted(() => {
      state.data.imageBaseUrl = config.imageBaseUrl;
      $('[data-toggle="tooltip"]').tooltip();
      console.log("Initial Google Address:", state.googleAddress);
    });



    return {
      state,
      redirectToProjectDetail,
      threeDot,
      setScrollPosition,
      hideMemberListModal,
      addTag,
      masterTagList,
      saveEditProject,
      renameProjectModal,
      hideRenameProjectModal,
      selectedProjectArchive,
      closeSuccessCustomModal,
      closeProjectArchiveModal,
      projectArchive,
      allMembers,
      showCreateChannel,
      hideCreateChannel,
      createChannel,
      validateForm,
      addFavProjectClose,
      addFavProjectShow,
      projectFav,
      removeFavProjectClose,
      removeFavProjectShow,
      projectFavRemove,
      hideProjectShow,
      hideProjectClose,
      showProjectShow,
      showProjectClose,
      projectHide,
      projectShow,
      options,
      selectedOptions,
      getAddressData,
      address,
      truncate,
      selectedProjectRestore,
      projectRestore,
      closeProjectRestoreModal,
      latitude,
      longitude,
      showMap,
      updateLatitude,
      updateLongitude,
      updateAddress,
      showProjectLocation,
      hideProjectLocation
    };
  },
});
