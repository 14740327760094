
import { defineComponent, reactive, onMounted, computed, ref } from "vue";
import { ethitransService } from "@/services/EthitransService";
import { common } from "@/common/services/Common";
import { config } from "@/Config";
import { useRoute, useRouter } from "vue-router";
import EmptyData from "@/components/commonDesign/EmptyData.vue";
import { VueDraggableNext } from 'vue-draggable-next'
export default defineComponent({
  name: "ListLineItem",
  components: {
    draggable: VueDraggableNext,
    EmptyData
  },
  setup() {
    const router = useRouter();
    const {
      params: { projectId },
    } = useRoute();
    const isInvalid = ref(false);
    const state = reactive({
      projectId: projectId,
      selectUnitId: '' as any,
      selectSubItemIndex: '' as any,
      selectItemIndex: '' as any,
      selectSubItem: '' as any,
      data: {
        activeItemTitle: "Active Item",
        activeItemHeading: "Active Item",
        archiveItemTitle: "Deactive Item",
        archiveItemHeading: "Deactive Item",
        imageBaseUrl: "" as any,
        activeLineItems: [] as any,
        archiveLineItems: [] as any,
        unitLists: [] as any,
        unit: {
          name: '',
          abbreviation: '',
        },
      },
      ui: {
        isLoading: {
          isEdit: false,
          getItemList: false,
          isUnitEdit: false,
          getUnit: false,

        },
        isPostLoading: {
          delete: false,
          addUnite: false,
        },
        errors: {
          errorList: {
            createUnit: [] as any,
          },
          validationErrorList: {
            itemName: "",
            unitName: "",
          },
          hasError: false,
        },
      },
    });

    function addUnitShow() {
      $("#addUnite").modal("show");
    }
    function addUniteClose() {
      state.ui.errors.validationErrorList.unitName = "";
      state.data.unit.abbreviation = "";
      state.data.unit.name = "";
      $("#addUnite").modal("hide");
      state.ui.isLoading.isUnitEdit = false;
    }

    function deleteSubItemShow(itemIndex: any, subItemIndex: any, value: any) {
      state.selectSubItemIndex = '';
      state.selectItemIndex = '';
      state.selectItemIndex = itemIndex;
      state.selectSubItemIndex = subItemIndex;
      state.selectSubItem = value;
      $("#deleteSubItem").modal("show");
    }
    function deleteSubItemClose() {
      $("#deleteSubItem").modal("hide");
    }

    function changeUnit(itemIndex: any, subItemIndex: any, value: any) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.data.activeLineItems[itemIndex].subitems[subItemIndex].unit_id = value.id;
      state.data.activeLineItems[itemIndex].subitems[subItemIndex].selectUnit = value;
    }

    function getAllUnits() {

      state.ui.isLoading.getUnit = true
      ethitransService
        .getUnitList()
        .then((res: any) => {
          state.data.unitLists = res.data.data;

        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isLoading.getUnit = false;
        });

    }

    function createUnitValidation() {
      state.ui.errors.validationErrorList.unitName = "";
      if (!state.data.unit.name) {
        state.ui.errors.validationErrorList.unitName = "Ener valid unit";
        state.ui.errors.hasError = true;
      }
    }

    function createUnit() {
      state.ui.isPostLoading.addUnite = false;
      state.ui.errors.hasError = false;
      createUnitValidation();
      if (state.ui.isPostLoading.addUnite || state.ui.errors.hasError) return false;
      const payLoad = {

        name: state.data.unit.name,
        abbreviation: state.data.unit.abbreviation,

      };
      state.ui.isPostLoading.addUnite = true;
      ethitransService
        .createUnit(payLoad)
        .then((res: any) => {
          state.data.activeLineItems[state.selectItemIndex].subitems[state.selectSubItemIndex].unit = res.data.data[0].id;
          state.data.activeLineItems[state.selectItemIndex].subitems[state.selectSubItemIndex].selectUnit = res.data.data[0];
          state.data.unitLists = res.data.data;
        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.ui.errors.errorList.createUnit)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.createUnit.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.addUnite = false;
          addUniteClose();

        });
    }

    function updateUnit() {
      state.ui.isPostLoading.addUnite = false;
      state.ui.errors.hasError = false;
      createUnitValidation();
      if (state.ui.isPostLoading.addUnite || state.ui.errors.hasError) return false;
      const payLoad = {

        name: state.data.unit.name,
        abbreviation: state.data.unit.abbreviation,

      };
      state.ui.isPostLoading.addUnite = true;
      ethitransService
        .updateUnit(state.selectUnitId, payLoad)
        .then((res: any) => {
          state.data.unitLists = res.data.data;

        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.ui.errors.errorList.createUnit)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.createUnit.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.addUnite = false;
          state.selectUnitId = '';
          addUniteClose();

        });
    }

    function addNewUnit(itemIndex: any, subItemIndex: any,) {
      state.selectSubItemIndex = '';
      state.selectItemIndex = '';
      state.selectItemIndex = itemIndex;
      state.selectSubItemIndex = subItemIndex;
      addUnitShow();
    }

    function deleteUnitList(itemIndex: any, subItemIndex: any, unitList: any) {
      state.selectSubItemIndex = '';
      state.selectItemIndex = '';
      state.selectItemIndex = itemIndex;
      state.selectSubItemIndex = subItemIndex;
      ethitransService
        .deleteUnite(unitList.id)
        .then((res: any) => {
          if (state.data.activeLineItems[itemIndex].subitems[subItemIndex].selectUnit) {
            state.data.activeLineItems[itemIndex].subitems[subItemIndex].selectUnit = '';
          }
          state.data.unitLists = res.data.data;


        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          // state.ui.isLoading.getUnit = false;
        });

    }

    function deleteUnit(itemIndex: any, subItemIndex: any, unitList: any) {
      const result = window.confirm('Do you want to delete?');
      if (result) {
        deleteUnitList(itemIndex, subItemIndex, unitList);
        console.log('User clicked "Yes"');
      } else {

        console.log('User clicked "No"');
      }
    }

    function editUnit(item: any) {
      state.data.unit.name = item.name;
      state.data.unit.abbreviation = item.abbreviation;
      state.selectUnitId = item.id;
      state.ui.isLoading.isUnitEdit = true;
      addUnitShow();
    }

    function gotoCreateLineItem() {
      router.push({
        name: "create-line-item",
      });
    }
    function getItemList() {
      state.ui.isLoading.getItemList = true
      ethitransService
        .getItemList('')
        .then((res: any) => {
          res.data.data.active.forEach((item: any) => {
            item.selectUnit = state.data.unitLists.filter((unit: any) => unit.id === item.unit_id)[0];
            if (item.markup_type == 1) {
              const parCal = (parseFloat(item.cost) * parseFloat(item.markup)) / 100;
              item.total = common.usdPriceFormate(parCal + parseFloat(item.cost));
            } else {
              item.total = common.usdPriceFormate(parseFloat(item.cost) + parseFloat(item.markup));
            }
            item.cost = common.usdPriceFormate(item.cost ? item.cost : 0);
            item.subitems.forEach((subItem: any) => {
              subItem.selectUnit = state.data.unitLists.filter((unit: any) => unit.id === subItem.unit_id)[0];
              if (subItem.markup_type == 1) {
                const parCal = (parseFloat(subItem.cost) * parseFloat(subItem.markup)) / 100;
                subItem.total = common.usdPriceFormate(parCal + parseFloat(subItem.cost));
              } else {
                subItem.total = common.usdPriceFormate(parseFloat(subItem.cost) + parseFloat(subItem.markup));
              }
              subItem.cost = common.usdPriceFormate(subItem.cost ? subItem.cost : 0);
            });
          });
          res.data.data.archive.forEach((item: any) => {
            item.selectUnit = state.data.unitLists.filter((unit: any) => unit.id === item.unit_id)[0];
            if (item.markup_type == 1) {
              const parCal = (parseFloat(item.cost) * parseFloat(item.markup)) / 100;
              item.total = common.usdPriceFormate(parCal + parseFloat(item.cost));
            } else {
              item.total = common.usdPriceFormate(parseFloat(item.cost) + parseFloat(item.markup));
            }
            item.cost = common.usdPriceFormate(item.cost ? item.cost : 0);
            item.subitems.forEach((subItem: any) => {
              subItem.selectUnit = state.data.unitLists.filter((unit: any) => unit.id === subItem.unit_id)[0];
              if (subItem.markup_type == 1) {
                const parCal = (parseFloat(subItem.cost) * parseFloat(subItem.markup)) / 100;
                subItem.total = common.usdPriceFormate(parCal + parseFloat(subItem.cost));
              } else {
                subItem.total = common.usdPriceFormate(parseFloat(subItem.cost) + parseFloat(subItem.markup));
              }
              subItem.cost = common.usdPriceFormate(subItem.cost ? subItem.cost : 0);
            });
          });
          state.data.activeLineItems = res.data.data.active;
          state.data.archiveLineItems = res.data.data.archive;
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isLoading.getItemList = false;
        });
    }

    function editSubItem(itemIndex: any, subItemIndex: any) {
      state.data.activeLineItems[itemIndex].subitems[subItemIndex].isEdit = true;
    }


    function updateSubItemList(item: any) {
      state.ui.errors.hasError = false;
      const payLoad = {
        name: item.name,
        description: item.description,
        cost: item.cost,
        markup: item.markup,
        allowance: item.allowance,
        // eslint-disable-next-line @typescript-eslint/camelcase
        unit_id: item.selectUnit.id,
        // eslint-disable-next-line @typescript-eslint/camelcase
        markup_type: item.markup_type,
      };
      ethitransService
        .updateSubItem(item.id, payLoad)
        .then((res: any) => {
          // state.data.unitLists = res.data.data;

        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.ui.errors.errorList.createUnit)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.createUnit.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          // state.ui

        });
    }

    function updateSubItem(itemIndex: any, subItemIndex: any, subItem: any) {
      state.data.activeLineItems[itemIndex].subitems[subItemIndex].isEdit = false;
      updateSubItemList(subItem);
    }



    function checkInputNumber(item: any) {
      const data = item.toString().replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
      return data;
    }

    function calculateTotal(itemIndex: any, subItemIndex: any) {
      state.data.activeLineItems[itemIndex].subitems[subItemIndex].cost = checkInputNumber(state.data.activeLineItems[itemIndex].subitems[subItemIndex].cost);
      state.data.activeLineItems[itemIndex].subitems[subItemIndex].markup = checkInputNumber(state.data.activeLineItems[itemIndex].subitems[subItemIndex].markup);
      const total = computed(() => {
        if (state.data.activeLineItems[itemIndex].subitems[subItemIndex].markup_type == 1) {
          const prCal = ((state.data.activeLineItems[itemIndex].subitems[subItemIndex].cost ? parseFloat(state.data.activeLineItems[itemIndex].subitems[subItemIndex].cost) : 0) * (state.data.activeLineItems[itemIndex].subitems[subItemIndex].markup ? parseFloat(state.data.activeLineItems[itemIndex].subitems[subItemIndex].markup) : 0)) / 100;
          return prCal + (parseFloat(state.data.activeLineItems[itemIndex].subitems[subItemIndex].cost) ? parseFloat(state.data.activeLineItems[itemIndex].subitems[subItemIndex].cost) : 0);
        } else {

          return (state.data.activeLineItems[itemIndex].subitems[subItemIndex].cost ? parseFloat(state.data.activeLineItems[itemIndex].subitems[subItemIndex].cost) : 0) + (state.data.activeLineItems[itemIndex].subitems[subItemIndex].markup ? parseFloat(state.data.activeLineItems[itemIndex].subitems[subItemIndex].markup) : 0);
        }

      });
      state.data.activeLineItems[itemIndex].subitems[subItemIndex].total = total;

    }

    function changeMarkupType(itemIndex: any, subItemIndex: any, value: any) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.data.activeLineItems[itemIndex].subitems[subItemIndex].markup_type = value;
      calculateTotal(itemIndex, subItemIndex);

    }

    function deleteSubItemApi() {

      ethitransService
        .deleteSubItem(state.selectSubItem.id)
        .then((res: any) => {

          state.data.activeLineItems[state.selectItemIndex].subitems.splice(state.selectSubItemIndex, 1)

        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          // state.ui.isLoading.getUnit = false;
        });

    }

    function itemDetails(itemUUid: any) {
      router.push({
        name: "line-item-detail",
        params: { itemUUId: itemUUid },
      });

    }

    function itemEdit(itemUUid: any) {
      router.push({
        name: "line-item-edit",
        params: { itemUUId: itemUUid },
      });

    }


    function updateItemPostion(item: any) {
      const payLoad = {
        mainitems: item,
      };
      ethitransService
        .updateItemPostion(payLoad)
        .then((res: any) => {
          // state.data.unitLists = res.data.data;

        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.ui.errors.errorList.createUnit)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.createUnit.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          // state.ui

        });
    }

    function cheageStatus(itemToRemove: any, status: any) {
      ethitransService
        .changeItemStatus(itemToRemove.uuid, status)
        .then((res: any) => {
          if (status == 1) {
            state.data.archiveLineItems = state.data.archiveLineItems.filter((item: any) => item !== itemToRemove);
            state.data.activeLineItems.push(itemToRemove);
          } else {
            state.data.activeLineItems = state.data.activeLineItems.filter((item: any) => item !== itemToRemove);
            state.data.archiveLineItems.push(itemToRemove);
          }
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isLoading.getUnit = false;
        });
    }
    function orderChange() {
      const itemPostion = computed(() => {
        return state.data.activeLineItems.map((item: any, index: any) => ({ id: item.id, index }));

      });
      updateItemPostion(itemPostion.value);
    }
    function orderChangeArchiveItem() {
      const itemPostion = computed(() => {
        return state.data.archiveLineItems.map((item: any, index: any) => ({ id: item.id, index }));

      });
      updateItemPostion(itemPostion.value);
    }

    onMounted(() => {
      getAllUnits();
      getItemList();
      state.data.imageBaseUrl = config.imageBaseUrl;


    });
    return {
      state,
      getItemList,
      gotoCreateLineItem,
      editSubItem,
      updateSubItem,
      calculateTotal,
      checkInputNumber,
      changeMarkupType,
      addNewUnit,
      addUniteClose,
      addUnitShow,
      getAllUnits,
      createUnitValidation,
      createUnit,
      changeUnit,
      deleteUnit,
      deleteUnitList,
      editUnit,
      updateUnit,
      deleteSubItemShow,
      deleteSubItemClose,
      deleteSubItemApi,
      itemDetails,
      itemEdit,
      orderChange,
      updateItemPostion,
      orderChangeArchiveItem,
      cheageStatus
    };
  },
});
