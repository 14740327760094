import { vModelText as _vModelText, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", null, [
    _withDirectives(_createVNode("input", {
      type: "text",
      class: _ctx.parentClass,
      id: "price",
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.newPrice = $event)),
      onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.formatAndAssign && _ctx.formatAndAssign(...args))),
      onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.formatAndAssign && _ctx.formatAndAssign(...args))),
      onInput: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.emitUpdate && _ctx.emitUpdate(...args)))
    }, null, 34), [
      [_vModelText, _ctx.newPrice]
    ])
  ]))
}