
import { defineComponent, reactive, onMounted } from "vue";
import { useRoute } from "vue-router";
import TitleComponent from "@/components/formComponents/TitleComponent.vue";
import TextBoxComponent from "@/components/formComponents/TextBoxComponent.vue";
// import TextAreaComponent from "@/components/formComponents/TextAreaComponent.vue";
import RadioButtonComponent from "@/components/formComponents/RadioButtonComponent.vue";
import SelectOption from "@/components/formComponents/SelectOption.vue";
import CheckBox from "@/components/formComponents/CheckBox.vue";
import TextArea from "@/components/formComponents/TextArea.vue";

import { ethitransService } from "@/services/EthitransService.ts";

export default defineComponent({
  name: "CreateTemplate",
  components: {
    TitleComponent,
    TextBoxComponent,
    // TextAreaComponent,
    RadioButtonComponent,
    SelectOption,
    CheckBox,
    TextArea,
  },
  setup() {
    const {
      params: { formId },
    } = useRoute();
    const state = reactive({
      type: "",
      selected: "",
      typePosition: "",

      form: {
        textField: {
          title: "",
          description: "",
        },
        textArea: {
          title: "",
          text: "",
        },
        textbox: {
          title: "",
        },
        radioButton: {
          type: "radioButton",
          title: "",
          // eslint-disable-next-line @typescript-eslint/camelcase
          select_values: [] as any,
        },
        selectOption: {
          type: "single_select",
          title: "",
          // eslint-disable-next-line @typescript-eslint/camelcase
          select_values: [] as any,
        },
        checkBox: {
          type: "checkBox",
          title: "",
          // eslint-disable-next-line @typescript-eslint/camelcase
          select_values: [] as any,
        },
      },
      ui: {
        isLoading: {},
        isPostLoading: {
          createFormTemplate: false,
          getAccountFormRetrieveById: false,
        },
      },
      data: [] as any,
      // data: [
      //   {
      //     type: "title",
      //     title: "Kitchen Revamping Project",
      //     description: "Kitchen Revamping Description Here",
      //     style: {
      //       // eslint-disable-next-line @typescript-eslint/camelcase
      //       font_size: 13,
      //       // eslint-disable-next-line @typescript-eslint/camelcase
      //       font_name: "Arial",
      //       // eslint-disable-next-line @typescript-eslint/camelcase
      //       background_color: "Red",
      //     },
      //   },
      //   {
      //     type: "textbox",
      //     title: "Your Name",
      //     tooltip: "Please write your full name",
      //     placeholder: "Write your name here",
      //     validation: [
      //       {
      //         type: "required",
      //         // eslint-disable-next-line @typescript-eslint/camelcase
      //         error_message: "Your name is required",
      //       },
      //       {
      //         type: "regex",
      //         pattern: "/dfdfdfdfdf/",
      //         // eslint-disable-next-line @typescript-eslint/camelcase
      //         error_message:
      //           "Name must not contain numbers and special characters",
      //       },
      //     ],
      //   },
      //   {
      //     type: "textbox",
      //     title: "Your Name",
      //     tooltip: "Please write your full name",
      //     placeholder: "Write your name here",
      //     validation: [
      //       {
      //         type: "required",
      //         // eslint-disable-next-line @typescript-eslint/camelcase
      //         error_message: "Your name is required",
      //       },
      //     ],
      //   },
      //   {
      //     type: "radioButton",
      //     text: "Kitchen Revamping Project",
      //     style: {
      //       // eslint-disable-next-line @typescript-eslint/camelcase
      //       font_size: 13,
      //       // eslint-disable-next-line @typescript-eslint/camelcase
      //       font_name: "Arial",
      //       // eslint-disable-next-line @typescript-eslint/camelcase
      //       background_color: "Red",
      //     },
      //   },
      //   {
      //     type: "radioButton",
      //     text: "Kitchen Revamping Project",
      //     style: {
      //       // eslint-disable-next-line @typescript-eslint/camelcase
      //       font_size: 13,
      //       // eslint-disable-next-line @typescript-eslint/camelcase
      //       font_name: "Arial",
      //       // eslint-disable-next-line @typescript-eslint/camelcase
      //       background_color: "Red",
      //     },
      //   },
      //   {
      //     type: "single_select",
      //     title: "Your Country",
      //     // eslint-disable-next-line @typescript-eslint/camelcase
      //     select_values: [],
      //   },
      // ] as any,
    });
    function addOptions() {
      state.form.radioButton.select_values.push({
        text: "",
        value: "",
        selected: false,
      });
    }
    function addSelectOptions() {
      state.form.selectOption.select_values.push({
        text: "",
        value: "",
        selected: false,
      });
    }
    function addCheckBox() {
      state.form.checkBox.select_values.push({
        text: "",
        value: "",
        selected: false,
      });
    }
    function removeCheckBox(removeText: string) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.form.checkBox.select_values = state.form.checkBox.select_values.filter(
        (item: any) => item.text != removeText
      );
    }
    function removeSelectOption(removeText: string) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.form.selectOption.select_values = state.form.selectOption.select_values.filter(
        (item: any) => item.text != removeText
      );
    }
    function removeOptions(removeText: string) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.form.radioButton.select_values = state.form.radioButton.select_values.filter(
        (item: any) => item.text != removeText
      );
    }
    function showTextFieldAdd(type: string, heading: string) {
      console.log("CHECK THE TYPE:>>>>", type);
      console.log("CHECK THE Heading:>>>>", heading);
      state.selected = heading;
      state.type = type;
      if (type === "radioButton") {
        addOptions();
      } else if (type === "single_select") {
        addSelectOptions();
      } else if (type === "checkBox") {
        addCheckBox();
      }
      $("#beforeAdd").modal("show");
    }
    function addTextField() {
      if (state.type === "textbox") {
        console.log("FORM POSITION:>>", state.typePosition);
        if (state.typePosition === "top") {
          state.data.unshift({
            type: "textbox",
            title: state.form.textbox.title,
            answer: "",
            tooltip: "Please write your full name",
            placeholder: "Write your name here",
            validation: [
              {
                type: "required",
                // eslint-disable-next-line @typescript-eslint/camelcase
                error_message: "Your name is required",
              },
            ],
          });
        }
        if (state.typePosition === "bottom") {
          state.data.concat([
            {
              type: "textbox",
              title: state.form.textbox.title,
              answer: "",
              tooltip: "Please write your full name",
              placeholder: "Write your name here",
              validation: [
                {
                  type: "required",
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  error_message: "Your name is required",
                },
              ],
            },
          ]);
        }
      }
      if (state.type === "title") {
        console.log("FORM POSITION:>>", state.typePosition);
        if (state.typePosition === "top") {
          state.data.unshift({
            type: "title",
            title: state.form.textField.title,
            description: state.form.textField.description,
          });
        }
        if (state.typePosition === "bottom") {
          state.data.concat([
            {
              type: "title",
              title: state.form.textField.title,
              description: state.form.textField.description,
            },
          ]);
        }
      }
      if (state.type === "textArea") {
        console.log("FORM POSITION:>>", state.typePosition);
        console.log("Title :>>", state.form.textArea.title);
        console.log("Anaswer :>>", state.form.textArea.text);
        // if (state.typePosition === "top") {
        //   state.data.unshift({
        //     type: "textArea",
        //     title: state.form.textArea.title,
        //     text: state.form.textArea.text,
        //   });
        // }
        // if (state.typePosition === "bottom") {
        //   state.data.concat([
        //     {
        //       type: "textArea",
        //       title: state.form.textArea.title,
        //       text: state.form.textArea.text,
        //     },
        //   ]);
        // }
        if (state.typePosition === "top") {
          if (state.data && state.data.length) {
            state.data.unshift({
              type: "textArea",
              title: state.form.textArea.title,
              answer: state.form.textArea.text,
              tooltip: "Please write your full name",
              placeholder: "Write your name here",
              validation: [
                {
                  type: "required",
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  error_message: "Your name is required",
                },
              ],
            });
          } else {
            state.data.push({
              type: "textArea",
              title: state.form.textArea.title,
              answer: state.form.textArea.text,
              tooltip: "Please write your full name",
              placeholder: "Write your name here",
              validation: [
                {
                  type: "required",
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  error_message: "Your name is required",
                },
              ],
            });
          }
        }
        if (state.typePosition === "bottom") {
          state.data.concat([
            {
              type: "textArea",
              title: state.form.textArea.title,
              answer: state.form.textArea.text,
              tooltip: "Please write your full name",
              placeholder: "Write your name here",
              validation: [
                {
                  type: "required",
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  error_message: "Your name is required",
                },
              ],
            },
          ]);
        }
      }
      if (state.type === "radioButton") {
        console.log("FORM POSITION:>>", state.typePosition);
        console.log("Check Radio Button:>>", state.form.radioButton);
        if (state.typePosition === "top") {
          if (state.form.radioButton) {
            state.data.unshift({
              type: "radioButton",
              title: state.form.radioButton.title,
              // eslint-disable-next-line @typescript-eslint/camelcase
              select_values: state.form.radioButton.select_values,
            });
            // state.data.unshift( { state.form.radioButton
            // });
          }
        }
        if (state.typePosition === "bottom") {
          state.data.concat([
            {
              type: "radioButton",
              title: state.form.radioButton.title,
              // eslint-disable-next-line @typescript-eslint/camelcase
              select_values: state.form.radioButton.select_values,
            },
          ]);
        }
      }
      if (state.type === "single_select") {
        console.log("FORM POSITION:>>", state.typePosition);
        if (state.typePosition === "top") {
          if (state.form.selectOption) {
            state.data.unshift({
              type: "single_select",
              title: state.form.selectOption.title,
              // eslint-disable-next-line @typescript-eslint/camelcase
              select_values: state.form.selectOption.select_values,
            });
            // state.data.unshift( { state.form.selectOption
            // });
          }
        }
        if (state.typePosition === "bottom") {
          state.data.concat([
            {
              type: "single_select",
              title: state.form.selectOption.title,
              // eslint-disable-next-line @typescript-eslint/camelcase
              select_values: state.form.selectOption.select_values,
            },
          ]);
        }
      }
      if (state.type === "checkBox") {
        console.log("FORM POSITION:>>", state.typePosition);
        if (state.typePosition === "top") {
          if (state.form.selectOption) {
            state.data.unshift({
              type: "checkBox",
              title: state.form.checkBox.title,
              // eslint-disable-next-line @typescript-eslint/camelcase
              select_values: state.form.checkBox.select_values,
            });
            // state.data.unshift( { state.form.checkBox
            // });
          }
        }
        if (state.typePosition === "bottom") {
          state.data.concat([
            {
              type: "checkBox",
              title: state.form.checkBox.title,
              // eslint-disable-next-line @typescript-eslint/camelcase
              select_values: state.form.checkBox.select_values,
            },
          ]);
        }
      }

      $("#beforeAdd").modal("hide");
      console.log("CHECK", state.data);
    }
    function addTextArea() {
      // alert('addTextField');
      state.data.push({
        type: "textbox",
        title: "Dummy Title",
        tooltip: "Please write your full name",
        placeholder: "Write your dummy msg here",
        validation: [
          {
            type: "required",
            // eslint-disable-next-line @typescript-eslint/camelcase
            error_message: "Your name is required",
          },
          {
            type: "regex",
            pattern: "/dfdfdfdfdf/",
            // eslint-disable-next-line @typescript-eslint/camelcase
            error_message:
              "Name must not contain numbers and special characters",
          },
        ],
      });
    }
    function addSelectOption() {
      // alert('addTextField');
      $("#edittext").modal("show");
      // state.data.push({
      //     type: "textbox",
      //     title: "Dummy Title",
      //     tooltip: "Please write your full name",
      //     placeholder: "Write your dummy msg here",
      //     validation: [
      //       {
      //         type: "required",
      //         // eslint-disable-next-line @typescript-eslint/camelcase
      //         error_message: "Your name is required",
      //       },
      //       {
      //         type: "regex",
      //         pattern: "/dfdfdfdfdf/",
      //         // eslint-disable-next-line @typescript-eslint/camelcase
      //         error_message:
      //           "Name must not contain numbers and special characters",
      //       },
      //     ],
      //   })
    }
    function createFormTemplate() {
       const form = {
        title: "",
        description: "",
      };
      state.data.forEach((item: any) => {
        if (item.type === "title") {
          // console.log("check for Title", item);
          form.title = item.title
          form.description = item.description
        }
      });
      if(!form.title || !form.description) return false
      const payLoad = {
        title: form.title,
        description: form.description,
        // eslint-disable-next-line @typescript-eslint/camelcase
        elements: state.data,
      };
      console.log("PAYLOAD CHECK ", payLoad);
      state.ui.isPostLoading.createFormTemplate = true;
      ethitransService
        .accountFormEditById(formId, payLoad)
        .then((res: any) => {
          console.log("UserList Response Check:>>> ", res.data.data);
          // state.userList = res.data.data ? res.data.data : [];
          state.data = []
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.createFormTemplate = false;
        });
    }
    // function createFormTemplate() {
    //   console.log("Check for Title", state.data);
    //   // accountformSave
    //   const payLoad = {
    //     title: "rest",
    //     description: "resting",
    //     // eslint-disable-next-line @typescript-eslint/camelcase
    //     elements: state.data,
    //     // eslint-disable-next-line @typescript-eslint/camelcase
    //     master_form_id: "5fb77e6f10975d0e5895c290",
    //   };
    //   console.log("PAYLOAD CHECK ", payLoad);
    //   state.ui.isPostLoading.createFormTemplate = true;
    //   ethitransService
    //     .accountformSave(payLoad)
    //     .then((res: any) => {
    //       console.log("UserList Response Check:>>> ", res.data.data);
    //       // state.userList = res.data.data ? res.data.data : [];
    //       state.data = []
    //     })
    //     .catch((error: any) => {
    //       console.log(error);
    //     })
    //     .finally(() => {
    //       state.ui.isPostLoading.createFormTemplate = false;
    //     });
    // }
    function getAccountFormRetrieveById() {
      state.ui.isPostLoading.getAccountFormRetrieveById = true;
      ethitransService
        .accountFormRetrieveById(formId)
        .then((res: any) => {
          console.log("getAccountFormRetrieveById Response Check:>>> ", res.data.elements);
          state.data = res.data.elements ? res.data.elements : [];
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.getAccountFormRetrieveById = false;
        });
    }
    onMounted(() => {
      if(formId){
        console.log("Form Id: >>> ", formId);
        getAccountFormRetrieveById()
      }
      // getAccountFormList();
    });
    return {
      state,
      // getAccountFormRetrieveById,
      showTextFieldAdd,
      addTextField,
      addTextArea,
      addSelectOption,
      addOptions,
      createFormTemplate,
      removeOptions,
      addSelectOptions,
      removeSelectOption,
      addCheckBox,
      removeCheckBox,
    };
  },
});
