
import { defineComponent, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";

// import ToDoListMembersList from "@/components/todo/ToDoListMembersList.vue";
import ToDoMembersList from "@/components/todo/ToDoMembersList.vue";
import ToDoPhaseDetailTasks from "@/components/todo/ToDoPhaseDetailTasks.vue";
import ToDoListAddPhaseModal from "@/components/todo/ToDoListAddPhaseModal.vue";
import ToDoPhaseStatus from "@/components/todo/ToDoPhaseStatus.vue";

import SuccessfulModalCustom from "@/components/modal/SuccessfulModalCustom.vue";


// import { ethitransService } from "@/services/EthitransService";
import { common } from "@/common/services/Common";


export default defineComponent({
  name: "ToDoListPhaseBlock",
  components: {
    // ToDoListMembersList,
    ToDoMembersList,
    ToDoPhaseDetailTasks,
    ToDoListAddPhaseModal,
    ToDoPhaseStatus,
    SuccessfulModalCustom
  },
  props: {
    phases: {
      type: Array,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
    details: {
      type: Object,
      required: false,
    },
    role:{
      type: Number,
      required: false,

    }
  },
  setup(props, { emit }) {
    const router = useRouter();
    const phaseData = ref(props.phases);
    const selectAll = ref(false);

    const state = reactive({
      data: {
        selectedToEdit: {} as any,
        successFullMessage: '',
        editMode: false,
        showAddPhaseModal: false,
        myDriveFileFolderLoad: false,
        selectedPhase: {} as any,
        taskLists: [] as any,
        showSuccesCustomModal: false,
        editFrom:'phase'
      },
      ui: {
        error: {
          hasError: false,
          errorList: {
            uploadAttachments: [] as any,
          },
        },
        isLoading: {
          getFolderList: false,
        },
        isPostLoading: {
          enableSelectedFileTag: false,
        },
      },
    });
    watch(() => props.phases, (newVal: any)=> {
      phaseData.value = newVal
    })
    // const phaseList = computed(() => {
    //   return props.phases;
    // });
    function isActive(permissionList: any, action: string){
      return common.isActionAllowded(props.role, permissionList, action)
    }
    function updateSelected(data: any){
      console.log("updateSelected",data);
      phaseData.value = data.phases.data;
      emit("updatePhaseList", data)
    }

    function showAddPhaseModal() {
      state.data.editMode = !state.data.editMode;
      state.data.showAddPhaseModal = !state.data.showAddPhaseModal;
    }
    // function addNewPhase(data: any) {
    //   // todoData.value = data;
    //   emit("addPhase", data);
    // }

    // function updateNewPhase(data: any) {
    //   // todoData.value = data;
    //   emit("updatePhase", data);
    // }
    // function updatePhase(data: any) {
    //   // todoData.value = data;
    //   emit("updatePhase", data);
    // }

    function closeSuccessCustomModal() {
      state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
      $("#SuccessfulModalCustom").modal("hide");
    }
    // function updatePhase(data: any) {
    //   console.log("updatePhase", data);
    //   emit("updateNewPhase", data);
    //   phaseData.value = data;
    //   state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
    //   state.data.successFullMessage = "Phase Update Successfully.";
    // }
    function phaseEdit(childPhase: any) {
      state.data.selectedToEdit = childPhase;
      // console.log("Selected Task  for Edit>>> ",childPhase)
      showAddPhaseModal();
    }
    function viewSelectedPhaseDetails(phaseDetails: any) {
      console.log("viewSelectedPhaseDetails");
      router.push({
        name: "user-todo-details",
        params: { phaseId: phaseDetails.uuid },
      });
    }
    function redirectToAddMember(phaseId: any) {
      console.log("Add Member");
      router.push({
        name: "user-todo-add-member",
        params: { id: phaseId },
      });
    }
    function hideTasksModal() {
      $("#showTasks").modal("hide");
    }

    function viewTasksByPhase(task: any) {
      if (!task.child_phases) return false;
      state.data.selectedPhase = task;
      task.child_phases.forEach((child: any) => {
        child.localStartedDate = child.start_date ? common.localDateOnly(child.start_date) : '';
        child.localEndDate = child.end_date ? common.localDateOnly(child.end_date) : '';
      });
      state.data.taskLists = task.child_phases;
      $("#showTasks").modal("show");
    }

    function toggleItemCheck(item: any) {
      // phaseData.value[item] = false;
      const totalReadyToSendPhase = phaseData.value.filter((phase: any) => phase.status_detail.status.id==1 || phase.status_detail.status.id==3 || phase.status_detail.status.id==5);
      const totalCheckToSendPhase = phaseData.value.filter((phase: any) => phase.isSelected);
      if(totalReadyToSendPhase.length===totalCheckToSendPhase.length){
        selectAll.value = true;
      }else{
        selectAll.value = false;
      }
      const checkPhase = phaseData.value.filter((phase: any) => phase.isSelected === true).length;
      emit('updateCheckSelectPhase', selectAll.value, checkPhase);

    }


    return {
      // phaseList,
      state,
      isActive,
      redirectToAddMember,
      updateSelected,
      viewSelectedPhaseDetails,
      viewTasksByPhase,
      hideTasksModal,
      showAddPhaseModal,
      phaseData,
      // addNewPhase,
      phaseEdit,
      // updatePhase,
      closeSuccessCustomModal,
      selectAll,
      toggleItemCheck,
      // updateNewPhase

    };
  },
});
