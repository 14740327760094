
import { defineComponent, reactive } from "vue";
import { authService } from "@/login/Services/AuthService.ts"; 
import { useRouter } from "vue-router";
//  import GSignInButton from 'vue-google-signin-button'
// import GoogleSignInButton from 'vue-google-signin-button-directive'


export default defineComponent({
  name: "CallBack",
  components :{
    // facebookLogin
  },
  setup() {
    const router = useRouter();
    const state = reactive({
    });
    
    function onSignIn(googleUser: any) {
      console.log("CALL BACK PAGE CALLED");
      console.log("GOOGLE DATA CHECK", googleUser)
      const profile = googleUser.getBasicProfile();
      // console.log('ID: ' + profile.getId()); // Do not send to your backend! Use an ID token instead.
      // console.log('Name: ' + profile.getName());
      // console.log('Image URL: ' + profile.getImageUrl());
      // console.log('Email: ' + profile.getEmail()); // This is null if the 'email' scope is not present.
      console.log('ALL DATA: ', profile); // This is null if the 'email' scope is not present.
      console.log('ALL CHECK: ', googleUser.getBasicProfile()); // This is null if the 'email' scope is not present.

      // The ID token you need to pass to your backend:
      /* eslint-disable @typescript-eslint/class-name-casing */
        /* eslint-disable @typescript-eslint/camelcase */
        const id_token = googleUser.getAuthResponse().id_token;
        console.log("ID Token: " + id_token);
    }

    
    return {
      state,
      onSignIn
    };
  },
});
