
import { defineComponent, reactive, onMounted } from "vue";
import { authService } from "@/login/Services/AuthService.ts";
import { validationService } from "@/common/ValidationService.ts";
import ErrorList from "@/components/commonDesign/ErrorList.vue";
import { useRouter } from "vue-router";

import { common } from "@/common/services/Common.ts";


export default defineComponent({
  name: "ForgetPassword",
  components: {
    ErrorList,

    // facebookLogin
  },
  setup() {
    const router = useRouter();
    const state = reactive({
      ui: {
        error: {
          validationList: [] as any,
          hasError: false,
        },
        postLoading: {
          sendForgetPasswordForVerification: false,
        },
      },
      data: {
        emailAddress: "",
        list: [] as any,
      },
    });
    
    function redirectToLogIn() {
      router.push({
        name: "admin-login",
      });
    }

    function hideSuccessForgetPassword() {
      $("#forgetSuccessModal").modal("hide");
      redirectToLogIn()
    }
    
    function validateEmail() {
      state.ui.error.validationList = [];
      state.ui.error.hasError = false;

      if (!state.data.emailAddress) {
        state.ui.error.validationList.push("Email address is required.");
      } else if (!validationService.isValidEmail(state.data.emailAddress)) {
        state.ui.error.validationList.push("Email address is not valid.");
      }

      if (
        !state.ui.error.validationList &&
        !state.ui.error.validationList.length
      ) {
        state.ui.error.hasError = false;
      } else if (state.ui.error.validationList.length != 0) {
        state.ui.error.hasError = true;
      }
    }
    function sendForgetPasswordForVerification() {
      validateEmail();
      if (
        state.ui.error.validationList.length != 0 ||
        state.ui.error.hasError ||
        state.ui.postLoading.sendForgetPasswordForVerification
      )
        return false;
      const payLoad = {
        email: state.data.emailAddress,
      };
      state.ui.postLoading.sendForgetPasswordForVerification = true;
      authService
        .forgetPassword(payLoad)
        .then((res: any) => {
          // console.log("sendForgetPasswordForVerification Response Check:>>> ", res.data.data);
          $("#forgetSuccessModal").modal("show");
          state.data.emailAddress = "";
        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.ui.error.validationList)
            .then((res: any) => {
              state.ui.error.hasError = true;
              // console.log("CHECK for Error >>>", res)
              if (res) {
                if (res.email) {
                  state.ui.error.validationList.push(res.email[0]);
                  // state.ui.error.validationList = [...state.ui.error.validationList]
                }
              }
            });
        })
        .finally(() => {
          state.ui.postLoading.sendForgetPasswordForVerification = false;
        });
    }

    onMounted(() => {
      // console.log("V3 mounted!");
      // $("#forgetSuccessModal").modal("hide");
    });

    return {
      state, sendForgetPasswordForVerification, validateEmail, redirectToLogIn, hideSuccessForgetPassword
    };
  },
});
