
import { defineComponent, onMounted, reactive } from "vue";
import { common } from "@/common/services/Common";
import { ethitransService } from "@/services/EthitransService";

export default defineComponent({
  name: "DetailModal",
  props : {
      showModal : Boolean,
      selected : Object,
  },
  setup(props, {emit}) {
    // const router = useRouter();
    const state = reactive({
      data: {
        folderRename: "" as any,
        selectedFolder: {} as any,
        showShareModal: false,

      },
      ui: {
        error: {
          hasError: false,
          errorList: {
            createNewFolder: [] as any,
            downloadFile: [] as any,
            renameSelectedFolder: [] as any,

          },
        },
        isLoading: {
          getFolderList: false,
        },
        isPostLoading: {
          downloadFile: false,
          createNewFolder: false,
          renameSelectedFolder: false,
        },
      },
    });
    function closeDetailModal() {
      emit("close");
      $("#detailModal").modal("hide");
    }
    function selectFile(item: object) {
      emit("selectedFile", item);
    }
    function showShareModal(file: any) {
      closeDetailModal();
      console.log("selected>>", file);
      selectFile(file);
      file.isChecked = true;
      state.data.selectedFolder = file;
      state.data.showShareModal = !state.data.showShareModal;
    }
    function downloadFile(data: any) {
      state.ui.error.errorList.downloadFile = [];
      state.ui.error.hasError = false;
      if (
        state.ui.error.errorList.renameSelectedFolder.length != 0 ||
        state.ui.error.hasError ||
        state.ui.isPostLoading.downloadFile
      )
        return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        file_id: data.uuid,
      };
      state.ui.isPostLoading.downloadFile = true;
      ethitransService
        .fileDownload(payLoad)
        .then((res: any) => {
          console.log("Project List Response Check:>>> ", res.data.data);
          window.open(res.data.data, "_blank");
          // closeRenameModal()
          // resetTheName(res.data.data)
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.downloadFile)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.downloadFile.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.downloadFile = false;
        });
    }
    onMounted(() => {
        if(props.showModal){
            $("#detailModal").modal("show");
        }
      //   getFolderList();
    });

    return {
      state,
      closeDetailModal,
      selectFile,
      showShareModal,
      downloadFile,

    };
  },
});
