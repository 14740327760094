
import { defineComponent, reactive, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
// import Button from "@/common/Button.vue";
import SuccessfulModalCustom from "@/components/modal/SuccessfulModalCustom.vue";

import { ethitransService } from "@/services/EthitransService";
import { validationService } from "@/common/ValidationService";
import { common } from "@/common/services/Common";

import ErrorList from "@/components/commonDesign/ErrorList.vue";

export default defineComponent({
  name: "ToDoPhaseChatChannelModel",
  props: {
    showPhaseChannel: {
      type: Boolean,
      required: true,
    },
    projectDetail: {
      type: Object,
      required: true,
    },
    todoDetail: {
      type: Object,
      required: true,
    },
    phase: {
      type: Object,
      required: true,
    },
    editData: {
      type: Object,
      required: true,
    },
  },
  components: {
    // Button,
    ErrorList,
    SuccessfulModalCustom,
  },
  setup(props, { emit }) {
    const router = useRouter();
    const {
      params: { projectId },
    } = useRoute();
    const todoData = ref({
      project: {} as any,
      payer: {} as any,
      phases: {} as any,
      todo: {} as any,
    });
    const state = reactive({
      data: {
        phaseChannelList: [] as any,
        successFulMessage: "",
        buttonName: "Approve",
        channelType: {
          type: "",
          channelSelected: "",
          channelName: "",
        },
        imagesToUpload: [] as any,
        showSuccesCustomModal: false,
      },
      ui: {
        error: {
          hasError: false,
          errorList: {
            phaseChatChannel: [] as any,
          },
        },
        isLoading: {
          getPhaseChannelList: false,
        },
        isPostLoading: {
          proccedForPhaseChannel: false,
        },
      },
    });
    function closeSuccessCustomModal() {
      state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
      // $("#SuccessfulModalCustom").modal("hide");
    }

    function hidePhaseChannel() {
      emit("close");
      $("#phaseChatChannelModel").modal("hide");
      // closeSuccessCustomModal();
    }
    function proccedForPhaseChannel() {
      state.ui.error.errorList.phaseChatChannel = [];
      state.ui.error.hasError = false;
      if (state.ui.isPostLoading.proccedForPhaseChannel) return false;
      state.ui.isPostLoading.proccedForPhaseChannel = true;
      ethitransService
        .phaseDiscussionChanelByPhaseAndChannelId(
          props.phase.uuid.toString(),
          state.data.channelType.channelName.toString(),
          state.data.channelType.channelSelected.toString()
        )
        .then((res: any) => {
          console.log(
            "proccedForPhaseChannel  Response:>>> ",
            res.data.data.channel
          );
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
          state.data.successFulMessage = "Channel Successfully Created.";
          hidePhaseChannel();
          if (res.data.data.channel && res.data.data.channel.uuid) {
            router.push({
              name: "admin-channel",
              params: { channelId: res.data.data.channel.uuid.toString() },
            });
          } 
          // state.data.phaseChannelList ? res.data.data.channels : [];
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.phaseChatChannel)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.phaseChatChannel.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.proccedForPhaseChannel = false;
        });
    }
    function getPhaseChannelList() {
      state.ui.error.errorList.phaseChatChannel = [];
      state.ui.error.hasError = false;
      if (state.ui.isLoading.getPhaseChannelList) return false;
      state.ui.isLoading.getPhaseChannelList = true;
      ethitransService
        .channelsByPhaseId(props.phase.uuid.toString())
        .then((res: any) => {
          console.log(
            "getPhaseChannelList  Response:>>> ",
            res.data.data.channels
          );
          state.data.phaseChannelList = res.data.data.channels
            ? res.data.data.channels
            : [];
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.phaseChatChannel)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.phaseChatChannel.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isLoading.getPhaseChannelList = false;
        });
    }
    watch(
      () => state.data.channelType.type,
      () => {
        if (state.data.channelType.type === "EXISTING") {
          console.log("CHANNEL LIST LOADING...");
          getPhaseChannelList();
        }
      }
    );
    onMounted(() => {
      if (props.showPhaseChannel) {
        $("#phaseChatChannelModel").modal("show");
      }
    });

    return {
      state,
      closeSuccessCustomModal,
      proccedForPhaseChannel,
      getPhaseChannelList,
      hidePhaseChannel,
    };
  },
});
