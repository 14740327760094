
import { defineComponent, reactive, onMounted, ref } from "vue";
export default defineComponent({
  name: "VoiceToText",
  components: {

  },
  setup(props, { emit }) {
    let recognition = '' as any;
    const transcription = ref('');
    const isRecording = ref(false);



    const state = reactive({
      isFirefox: false,
      userLog: [] as any,
      user: "" as any,

      data: {
        imageBaseUrl: "",

      },
    });

    function stopRecording() {
      let timeoutId: number | undefined; // Define the type explicitly
      recognition.stop();
      clearTimeout(timeoutId); // Clear the timeout
    }
    function startRecording() {
      isRecording.value = true;
      recognition = new window.webkitSpeechRecognition();
      recognition.lang = 'en-US';
      recognition.continuous = true;
      recognition.interimResults = false;
      recognition.start();

      let timeoutId: number | undefined; // Define the type explicitly

      recognition.onresult = (event: any) => {
        const transcript = event.results[event.results.length - 1][0].transcript;
        transcription.value = transcript;
        // state.message = state.message + transcript;
        emit('startRecording', transcript);

        // Reset the timeout whenever there's a new result
        clearTimeout(timeoutId);
        timeoutId = setTimeout(stopRecording, 300000); // 5 seconds timeout
      };

      recognition.onend = () => {
        isRecording.value = false;
      };

      // Set a timeout to stop recording after 30 seconds of inactivity
      timeoutId = setTimeout(stopRecording, 30000); // 30 seconds timeout
    }



    onMounted(() => {
      const userAgent = navigator.userAgent;
      state.isFirefox = /Firefox/.test(userAgent);
    });
    return {
      state,
      transcription,
      isRecording,
      startRecording,
      stopRecording
    };
  },
});
