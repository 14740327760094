
import { defineComponent, onMounted, reactive, ref, computed } from "vue";

export default defineComponent({
    name: "PriceInputField",
    props: {
        price: String,
        parentClass: String,
        from: String,
    },
    setup(props, { emit }) {
        const newPrice = ref(0) as any;
        const state = reactive({
            data: {},
        });

        function formatUSDPrice(value: string) {
            // Convert string to number, remove non-numeric characters
            const numericValue = parseFloat(value.replace(/[^\d.]/g, '')) || 0;

            // Format as currency using Intl.NumberFormat
            const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
            });

            return formatter.format(numericValue);
        }

      
        function formatAndAssign() {
            if(props.from =='markUp'){
                newPrice.value = Number(newPrice.value).toFixed(2);

            }else{
                newPrice.value = formatUSDPrice(newPrice.value);

            }
        }

        function emitUpdate() {
            emit('updateItem', newPrice.value);
        }
        onMounted(() => {
            setTimeout(() => {
                newPrice.value = props.price;
            }, 1000);
            setTimeout(() => {
                if(props.from =='markUp'){
                    newPrice.value = newPrice.value.toFixed(2)
                }else{
                    newPrice.value = newPrice.value.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                });
                }                
            }, 1000);
        });

        return {
            state,
            formatUSDPrice,
            formatAndAssign,
            newPrice,
            emitUpdate,
        };
    },
});
