
import { defineComponent, reactive, onMounted, computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import ErrorList from "@/components/commonDesign/ErrorList.vue";

import { ethitransService } from "@/services/EthitransService";
import { validationService } from "@/common/ValidationService";
import { common } from "@/common/services/Common";
import AppDeleteConfirmationModal from "@/common/AppDeleteConfirmationModal.vue";
// import ToDoPhaseAddMemberTask from "@/components/todo/ToDoPhaseAddMemberTask.vue";
import SuccessfulModalCustom from "@/components/modal/SuccessfulModalCustom.vue";
import CheckAvaibilityByUser from "@/components/todo/CheckAvaibilityByUser.vue";
import AddMemberToTaskEditPhase from "@/components/todo/AddMemberToTaskEditPhase.vue";
import AddMembeToTask from "@/components/todo/AddMembeToTask.vue";
import RequestedInvoice from "@/components/todo/RequestedInvoice.vue";
import EmptyData from "@/components/commonDesign/EmptyData.vue";
// import QCalender from "@/components/projectDetail/QCalender.vue";
// import ProCalender from "@/components/projectDetail/ProCalender.vue";
// import CustomEventCalender from "@/components/projectDetail/CustomEventCalender.vue";

import GeneratedInvoice from "@/components/todo/GeneratedInvoice.vue";
import AppGoBack from "@/components/commonDesign/AppGoBack.vue";

import moment from "moment";

// import { Data } from "@/components/todo/AddMember.ts";

import { config } from "@/Config";
import Pusher from "pusher-js";

export default defineComponent({
  name: "ToDoPhaseInvoice",
  components: {
    RequestedInvoice,
    // ToDoPhaseAddMemberTask,
    ErrorList,
    AppDeleteConfirmationModal,
    SuccessfulModalCustom,
    CheckAvaibilityByUser,
    AddMemberToTaskEditPhase,
    AddMembeToTask,
    AppGoBack,
    GeneratedInvoice,
    // CustomEventCalender,
    // QCalender,
    // ProCalender,
    EmptyData,
    // ToDoListBreadCrumb,
    // ToDoListPhaseBlock,
    // ToDoListAddPhaseModal,
    // SuccessfulModalCustom,
  },
  setup() {
    const {
      params: { id },
    } = useRoute();
    const router = useRoute();
    const route = useRouter();
    const phaseDetails = ref({
      phase: {
        // eslint-disable-next-line @typescript-eslint/camelcase
        child_phases: [] as any,
      } as any,
      roles: [] as any,
      // eslint-disable-next-line @typescript-eslint/camelcase
      smart_contract: {
        uuid: "",
      },
      // eslint-disable-next-line @typescript-eslint/camelcase
      amount_types: [] as any,
      // eslint-disable-next-line @typescript-eslint/camelcase
      phase_members: [] as any,
      members: [] as any,
    });
    const state = reactive({
      data: {
        phaseDetails: {} as any,
        activeUUID: "" as any,
        isPayActive: false,
        selectedInvoice: {} as any,
        title: "Received Invoices",
        heading: "Invoices not Requested",
        loggedInUserUUID: "",
        selectedMemberIndex: 0,
        activeTab: "generte-invoice",
        minDate: "",
        maxDate: "",
        filterReceivedInvoiceList: {
          list: [] as any,
          id: "",
          listByStatus: 1,
          listByTitle: "",
        },
        membersAdded: false,
        phaseDetailsStatus: true,
        agreeToInvitation: false,
        showAddMemberModal: false,
        showSuccesCustomModal: false,
        showAvailibilityModal: false,
        selectedEmail: "",
        selectedUser: {},
        successFulMessage:
          "Selected Members are added with roles and amount type in Phase.",
        deleteModalMessage: "Selected Member will be Delete. Please Confirm.",
        selectedMemberForRole: {},
        selectedToRemove: {},
        selectedRole: {},
        selectedTask: {},
        showDeleteConfirmationModal: false,
        showTaskUpdateModal: false,
        invite: {
          newUsers: "",
          existingMembers: [] as any,
          existingMemberSelected: [] as any,
          selectedMembers: [] as any,
        },
      },
      ui: {
        error: {
          hasError: false,
          errorList: {
            phaseDetails: [] as any,
            getInvoiceByPhaseOrTask: [] as any,
            getPhaseDetails: [] as any,
          },
        },
        isLoading: {
          phaseDetails: false,
          changeDetailsByEmail: false,
          getInvoiceByPhaseOrTask: false,
          getPhaseDetails: false,
        },
        isPostLoading: {
          confirmPayment: false,
          receivedInvocieByPhaseId: false,
          addMembersInPhases: false,
        },
      },
    });
    function checkPermissionExists(permissions: any, permissionToCheck: any) {
      return permissions.some(
        (permission: any) =>
          permission.id === permissionToCheck.id &&
          permission.code === permissionToCheck.code &&
          permission.type === permissionToCheck.type &&
          permission.name === permissionToCheck.name
      );
    }
    const taskId = computed(() => {
      // if(router.query.taskId){
      //   state.data.activeTab = 'task'
      // }
      return id;
    });
    const tabRoleStatus = computed(() => {
      if(state.data.phaseDetails && state.data.phaseDetails.role === 1){
        return true;
      } else if(state.data.phaseDetails && state.data.phaseDetails.role === 3) {
        const permissionToCheck = {
          id: 8,
          code: "invoice_receiver",
          type: 0,
          name: "Invoice Receiver",
        };
        const isAllowded: any = checkPermissionExists( state.data.phaseDetails.permissions, permissionToCheck);
        //
        if (isAllowded) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    });
    const filteredChildPhases = computed(() => {
      return phaseDetails.value.phase.child_phases.filter(
        (childPhase: any) => childPhase.isChecked
      );
    });
    // function hasValidFilteredSlots(member: any) {
    //   return Array.isArray(member.filteredSlots) && member.filteredSlots.length > 0;
    // }
    function hasValidFilteredSlots(member: any) {
      return (
        Array.isArray(member.filteredSlots) &&
        member.filteredSlots.length > 0 &&
        member.amountTypeNumber !== undefined &&
        member.amountTypeNumber !== null &&
        member.amountTypeNumber !== "" &&
        typeof member.amountType === "object" &&
        member.amountType !== null &&
        member.role &&
        member.role.permissions &&
        member.role.permissions.some(
          (permission: any) => permission.isChecked === true
        )
      );
    }
    const memberFilteredSlotsValid = computed(() => {
      return (
        state.data.invite.selectedMembers.length > 0 &&
        state.data.invite.selectedMembers.every((member: any) =>
          hasValidFilteredSlots(member)
        )
      );
    });

    const filteredPhases = computed(() => {
      const searchTerm = "";
      // const searchTerm = state.data.filterBySearch.toLowerCase();
      phaseDetails.value.phase.child_phases.forEach((child: any) => {
        child.isCheckedDisabled = false;
        if (child.start_date === null || child.end_date === null) {
          child.isCheckedDisabled = true;
        }
      });

      return phaseDetails.value.phase.child_phases.filter((item: any) =>
        item.name.toLowerCase().startsWith(searchTerm)
      );

      // return phaseDetails.value.phase.child_phases.filter((item: any) =>
      //           item.name.toLowerCase().includes(searchTerm)
      // );
    });
    const filteredEvents = computed(() => {
      const dates: any = [
        {
          start: "2018-11-21 09:00",
          end: "2018-11-21 17:00",
          title: `ABC`,
          // content: '<i class="icon material-icons">sentiment_satisfied_alt</i>',
          // class: 'health'
        },
        {
          start: "2018-11-22 09:00",
          end: "2018-11-21 17:00",
          title: `DEF`,
          // content: '<i class="icon material-icons">sentiment_satisfied_alt</i>',
          // class: 'health'
        },
      ];
      // phaseDetails.value.phase.child_phases.forEach((child: any)=> {
      //   if(child.edit_end_date === null || child.edit_start_date === null){
      //     dates.push({
      //     start: '2018-11-21 09:00',
      //     end: '2018-11-21 17:00',
      //     title: `${child.name}`,
      //     // content: '<i class="icon material-icons">sentiment_satisfied_alt</i>',
      //     // class: 'health'
      //   })

      //   }
      // })
      return dates;

      // return phaseDetails.value.phase.child_phases.filter((item: any) =>
      //           item.name.toLowerCase().includes(searchTerm)
      // );
    });
    function hideSelectedReceivedInvoice() {
      $("#selectedReceivedInvoice").modal("hide");
    }
    function selectedInvoice(data: any) {
      state.data.selectedInvoice = {};
      state.data.selectedInvoice = data;
      let invoiceId = "";
      invoiceId = data.uuid;
      // console.log("Choose Payment Method", invoiceId);
      state.ui.error.errorList.getInvoiceByPhaseOrTask = [];
      state.ui.error.hasError = false;

      if (state.ui.isLoading.getInvoiceByPhaseOrTask) return false;

      state.ui.isLoading.getInvoiceByPhaseOrTask = true;
      ethitransService
        // .invoiceByPhaseOrTask(phaseId.toString())
        .invoiceByPhaseOrTask(invoiceId.toString(), 1)
        .then((res: any) => {
          console.log(
            "getInvoiceByPhaseOrTask Response Check:>>> ",
            res.data.data
          );
          // eslint-disable-next-line @typescript-eslint/camelcase
          if (res.data.data.invoice && res.data.data.invoice.invoice_date) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            res.data.data.invoice.invoice_date = common.localDateOnly(
              res.data.data.invoice.invoice_date
            );
          }
          // eslint-disable-next-line @typescript-eslint/camelcase
          if (res.data.data.new_items && res.data.data.new_items.length) {
            console.log("New Items");
            if (
              res.data.data.invoice &&
              res.data.data.invoice.invoice_items &&
              res.data.data.invoice.invoice_items.length
            ) {
              console.log("invoice items");
              res.data.data.new_items.forEach((item: any) => {
                item.newAdded = true;
                res.data.data.invoice.invoice_items.push(item);
              });
            }
          }
          // res.data.data.breadcrumb.reverse();
          state.data.selectedInvoice = res.data.data.invoice
            ? res.data.data.invoice
            : {};
          // state.data.breadcrumb = res.data.data.breadcrumb
          //   ? res.data.data.breadcrumb
          //   : [];

          if (
            localStorage.getItem("current_user_id") &&
            state.data.selectedInvoice.billed_from
          ) {
            const userId: any = localStorage.getItem("current_user_id");
            console.log("USER ID >>> ", userId);
            if (
              state.data.selectedInvoice.status === 1 ||
              state.data.selectedInvoice.status === 4
            ) {
              // if (
              //   parseInt(userId) !=
              //   parseInt(state.data.invoiceDetails.billed_from)
              // ) {
              state.data.isPayActive = true;
            } else {
              state.data.isPayActive = false;
            }
          }
          // if (routerQuery.query.account === "success") {
          //   state.data.stripeSuccess = true;

          //   state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
          //   state.data.successFulMessage = "Stripe Account Setup Completed.";
          //   // generateInvoice();
          // }
          $("#selectedReceivedInvoice").modal("show");
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.getInvoiceByPhaseOrTask)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.getInvoiceByPhaseOrTask.push(
                    value[0]
                  );
                }
              }
            });
        })
        .finally(() => {
          state.ui.isLoading.getInvoiceByPhaseOrTask = false;
        });
    }
    function choosePaymentMode() {
      state.ui.error.errorList.getInvoiceByPhaseOrTask = [];
      state.ui.error.hasError = false;
      if (state.ui.isPostLoading.confirmPayment) return false;

      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        invoice_id: state.data.selectedInvoice.uuid,
        amount: state.data.selectedInvoice.total,
      };
      console.log("Paylaod Check >>> ", payLoad);
      state.ui.isPostLoading.confirmPayment = true;
      ethitransService
        .payInvoiceByStripe(payLoad)
        .then((res: any) => {
          console.log("PAYMENT DONE >>> ", res.data.data);
          // alert("THEN");
          // emit("logData", res.data.data);
          if (res.data.data.status === 1) {
            console.log("Project Todo Response Check:>>> ", res.data.data.link);
            window.location.replace(`${res.data.data.link}`);
          }
          // logData(res.data.data);
          // hideTaskLogModal();
        })
        .catch((error: any) => {
          // alert("CATCH WHY");
          console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.getInvoiceByPhaseOrTask)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.getInvoiceByPhaseOrTask.push(
                    value[0]
                  );
                }
              }
            });
        })
    }
    function clickEvent(data: any) {
      console.log("PRABHU >>> ", data);
    }
    function createdEvent(event: any) {
      console.log("CHECK Phase CREATED >>> ", event);
      event.formatedStart = common.formatDateAndTime(event.start);
      event.formatedEnd = common.formatDateAndTime(event.end);
      // console.log("DATE TIME FORMAT START >> ", common.formatDateAndTime(event.start));
      // console.log("DATE TIME FORMAT END >> ", common.formatDateAndTime(event.end));

      // latest update code
      state.data.invite.selectedMembers[
        state.data.selectedMemberIndex
      ].slots.push(event);

      // Create an object to store the latest end_time for each unique date and time
      const latestEndTimes: any = {};

      state.data.invite.selectedMembers[
        state.data.selectedMemberIndex
      ].slots.forEach((slot: any) => {
        const date = slot.formatedStart.split(" ")[0]; // Extract the date part
        const time = slot.formatedStart.split(" ")[1]; // Extract the time part
        const formatedEnd = new Date(slot.formatedEnd);

        const dateTimeKey = `${date} ${time}`;

        if (
          !latestEndTimes[dateTimeKey] ||
          formatedEnd > latestEndTimes[dateTimeKey]
        ) {
          latestEndTimes[dateTimeKey] = formatedEnd;
        }
      });

      // Create a new array with the updated time slots
      const updatedTimeSlots = Object.values(latestEndTimes).map(
        (latestEndTime: any) => {
          return state.data.invite.selectedMembers[
            state.data.selectedMemberIndex
          ].slots.find(
            (slot: any) =>
              new Date(slot.formatedEnd).getTime() === latestEndTime.getTime()
          );
        }
      );

      // Filter the old slots to keep only the ones that are in updatedTimeSlots
      state.data.invite.selectedMembers[state.data.selectedMemberIndex].slots =
        state.data.invite.selectedMembers[
          state.data.selectedMemberIndex
        ].slots.filter((slot: any) => {
          const date = slot.formatedStart.split(" ")[0]; // Extract the date part
          const time = slot.formatedStart.split(" ")[1]; // Extract the time part
          const dateTimeKey = `${date} ${time}`;
          return (
            updatedTimeSlots.includes(slot) ||
            latestEndTimes[dateTimeKey] === new Date(slot.formatedEnd)
          );
        });

      // eslint-disable-next-line @typescript-eslint/camelcase
      state.data.invite.selectedMembers[
        state.data.selectedMemberIndex
      ].filteredSlots = updatedTimeSlots;
    }
    function deletedEvent(event: any) {
      console.log("CHECK Phase DELETED >>> ", event);
      console.log(
        "DATE TIME FORMAT START >> ",
        common.formatDateAndTime(event.start)
      );
      console.log(
        "DATE TIME FORMAT END >> ",
        common.formatDateAndTime(event.end)
      );
      event.formatedStart = common.formatDateAndTime(event.start);
      event.formatedEnd = common.formatDateAndTime(event.end);
      state.data.invite.selectedMembers[state.data.selectedMemberIndex].slots =
        state.data.invite.selectedMembers[
          state.data.selectedMemberIndex
        ].slots.filter(
          (e: any) =>
            e.title !== event.title &&
            e.start !== event.start &&
            e.end !== event.end
        );
    }

    // Define a function to find the index of an object by its 'id' property
    function findIndexById(
      array: { id: number; name: string }[],
      targetId: number
    ): number {
      return array.findIndex((obj) => obj.id === targetId);
    }
    function checkWithRow(item: any) {
      console.log(
        "ITEM INDEX >>",
        state.data.invite.selectedMembers.findIndex(
          (member: any) => member === item
        )
      );
      state.data.invite.selectedMembers.forEach((member: any) => {
        // console.log("Member ITEM >>", member.role.permissions)
        if (member.email === item.row.email) {
          member.role.permissions.forEach((permission: any) => {
            if (permission.id === item.check.id) {
              console.log(" IN CHECK LIST>>>", permission);
              permission.isChecked = true;
            }
          });
        }
      });
    }

    function addMemberToTaskSuccess() {
      state.data.showSuccesCustomModal = true;
      state.data.successFulMessage = "Members in Task Added Successfully";
    }
    function setUpdatedTask(udpatedTask: any) {
      console.log("UPDATE Check >>> ", udpatedTask);
      // eslint-disable-next-line @typescript-eslint/camelcase
      phaseDetails.value.phase.child_phases.forEach((item: any) => {
        if (item.uuid === udpatedTask.uuid) {
          // Replace the object with the updated object
          item.budget = udpatedTask.budget;
          // eslint-disable-next-line @typescript-eslint/camelcase
          item.edit_budget = udpatedTask.budget;
          if (typeof udpatedTask.priority_id === "string") {
            // eslint-disable-next-line @typescript-eslint/camelcase
            item.priority_id = parseInt(udpatedTask.priority_id);
            console.log("<< TYPE CHECK >>", item.priority_id);
          } else {
            // eslint-disable-next-line @typescript-eslint/camelcase
            item.priority_id = udpatedTask.priority_id;
          }
          // eslint-disable-next-line @typescript-eslint/camelcase
          item.priority_id = udpatedTask.priority_id;
          // eslint-disable-next-line @typescript-eslint/camelcase
          item.edit_start_date = common.localDateOnly(udpatedTask.start_date);
          // eslint-disable-next-line @typescript-eslint/camelcase
          item.edit_end_date = common.localDateOnly(udpatedTask.end_date);
          // eslint-disable-next-line @typescript-eslint/camelcase
          item.start_date = common.localDateOnly(udpatedTask.start_date);
          // eslint-disable-next-line @typescript-eslint/camelcase
          item.end_date = common.localDateOnly(udpatedTask.end_date);
        }
      });
      state.data.showSuccesCustomModal = true;
      state.data.successFulMessage = "Task Updated";

      // filteredPhases.value.forEach((item: any)=> {
      //   if (item.uuid === udpatedTask.uuid) {
      //       // Replace the object with the updated object
      //       item.budget = udpatedTask.budget;
      //       // eslint-disable-next-line @typescript-eslint/camelcase
      //       item.priority_id = udpatedTask.priority_id;
      //       // eslint-disable-next-line @typescript-eslint/camelcase
      //       item.edit_start_date = common.localDateOnly(udpatedTask.start_date );
      //       // eslint-disable-next-line @typescript-eslint/camelcase
      //       item.edit_end_date = common.localDateOnly(udpatedTask.end_date);
      //     }
      // })
    }
    function setMemberUpdatedTask(udpatedTask: any) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      phaseDetails.value.phase.child_phases.forEach((item: any) => {
        if (item.uuid === udpatedTask.uuid) {
          item.allMembers = udpatedTask.allMembers;
          // Replace the object with the updated object
          item.budget = udpatedTask.budget;
          // eslint-disable-next-line @typescript-eslint/camelcase
          item.priority_id = udpatedTask.priority_id;
          // eslint-disable-next-line @typescript-eslint/camelcase
          item.edit_start_date = common.localDateOnly(udpatedTask.start_date);
          // eslint-disable-next-line @typescript-eslint/camelcase
          item.edit_end_date = common.localDateOnly(udpatedTask.end_date);
        }
      });

      // filteredPhases.value.forEach((item: any)=> {
      //   if (item.uuid === udpatedTask.uuid) {
      //       // Replace the object with the updated object
      //       item.budget = udpatedTask.budget;
      //       // eslint-disable-next-line @typescript-eslint/camelcase
      //       item.priority_id = udpatedTask.priority_id;
      //       // eslint-disable-next-line @typescript-eslint/camelcase
      //       item.edit_start_date = common.localDateOnly(udpatedTask.start_date );
      //       // eslint-disable-next-line @typescript-eslint/camelcase
      //       item.edit_end_date = common.localDateOnly(udpatedTask.end_date);
      //     }
      // })
    }
    function addMemberToTaskData(tasks: any) {
      console.log("Check Tasks>>> ", tasks);
      tasks.forEach((task: any, index: any) => {
        setMemberUpdatedTask(task);
        if (tasks.length === index + 1) {
          state.data.showSuccesCustomModal = true;
          state.data.successFulMessage = "Members Added To Selected Task";
        }
      });
    }
    function hideUpdateTaskModal() {
      state.data.showTaskUpdateModal = !state.data.showTaskUpdateModal;
    }
    function showTaskUpdateModal() {
      console.log("IN PARENT >> ");
      state.data.showTaskUpdateModal = true;
    }
    function hideAddMemberToTaskModal() {
      state.data.showAddMemberModal = !state.data.showAddMemberModal;
    }
    function showAddMemberToTaskModal() {
      state.data.showAddMemberModal = true;
    }

    function selectedTask(selected: any) {
      console.log("IN PARENT >> ");
      state.data.selectedTask = selected;
    }
    function selectedChecked(selected: any) {
      phaseDetails.value.phase.child_phases.forEach((item: any) => {
        if (item.uuid === selected.uuid) {
          item.isChecked = true;
          state.data.selectedTask = item;
        } else {
          item.isChecked = false;
        }
      });
    }
    function hideAvaibilityModal() {
      state.data.showAvailibilityModal = false;
    }
    function selectedMemberForCheckAvaibility(member: any) {
      state.data.selectedEmail = member.email;
      state.data.selectedUser = member;
      state.data.showAvailibilityModal = true;
    }
    function deleteAmountType(data: any, item: any) {
      // console.log("Triggered Type >> ", item);
      // eslint-disable-next-line @typescript-eslint/camelcase
      phaseDetails.value.amount_types = phaseDetails.value.amount_types.filter(
        (e: any) => e != item
      );
    }
    function selectedMemberIndex(memberIndex: number) {
      state.data.selectedMemberIndex = memberIndex;
    }
    function deleteRole(sata: any, item: any) {
      console.log("Triggered Delete Role >> ", item);

      phaseDetails.value.roles = phaseDetails.value.roles.filter(
        (e: any) => e != item
      );
    }
    function closeSuccessCustomModal() {
      state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
      $("#SuccessfulModalCustom").modal("hide");
    }
    function selectedAmountTypeForMember(selectedMember: any) {
      // console.log("CHECK MEMBER >> ", selectedMember.member.email);
      // console.log("ROLE INFO >> ", selectedMember.amountType);
      selectedMember.member.amountType = selectedMember.amountType;
      // console.log("MEMBERS >> ", state.data.invite.selectedMembers);
      // console.log("FINAL >> ", selectedMember.member);
      state.data.selectedMemberForRole = selectedMember;
      state.data.invite.selectedMembers.forEach((item: any) => {
        if (item.email === selectedMember.member.email) {
          item.amountType = selectedMember.amountType;
        }
      });
    }
    function addNewRole(newRole: any) {
      phaseDetails.value.roles = newRole;
    }
    function addNewAmountType(newAmountTypes: any) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      phaseDetails.value.amount_types = newAmountTypes;
    }
    function selectedRoleForMember(selectedMember: any) {
      // console.log("CHECK MEMBER >> ", selectedMember.member.email);
      // console.log("ROLE INFO >> ", selectedMember.role);
      if (
        selectedMember.role &&
        selectedMember.role.permissions &&
        selectedMember.role.permissions.length
      ) {
        selectedMember.role.permissions.forEach((permission: any) => {
          permission.isChecked = false;
        });
      }
      selectedMember.member.role = selectedMember.role;
      // console.log("MEMBERS >> ", state.data.invite.selectedMembers);
      // console.log("FINAL >> ", selectedMember.member);
      state.data.selectedMemberForRole = selectedMember;
      state.data.invite.selectedMembers.forEach((item: any) => {
        if (item.email === selectedMember.member.email) {
          item.role = selectedMember.role;
        }
      });
    }
    function setNewSelectedMembers(fullName: string, email: string) {
      // if (state.data.invite.selectedMembers.length === 0) {
      // res.data.data.phase_members.forEach()
      // common.findEmailMatch
      state.data.invite.selectedMembers.push({
        name: fullName,
        amountType: "",
        isExist: common.findEmailMatch(email, phaseDetails.value.phase_members),
        amountTypeNumber: "",
        role: "",
        email: email,
        slots: [],
        // eslint-disable-next-line @typescript-eslint/camelcase
        approval_required: false,
      });
      // } else {
      //   state.data.invite.selectedMembers.forEach((member: any) => {
      //     if (
      //       member.email.toLowerCase() !=
      //       state.data.invite.existingMemberSelected.toLowerCase()
      //     ) {
      //       state.data.invite.selectedMembers.push({
      //     name: fullName,
      //     amountTypeNumber: "",
      //     role: "",
      //     email: email,
      //   });
      //     }
      //   });
      //   state.data.invite.selectedMembers =
      //             state.data.invite.selectedMembers.filter(
      //               (item: any, index: any) =>
      //                 state.data.invite.selectedMembers.indexOf(item) === index
      //             );
      // }
      state.data.invite.existingMemberSelected = "";
    }
    function changeDetailsByEmail() {
      if (
        !validationService.isValidEmail(
          state.data.invite.existingMemberSelected
        )
      )
        return false;
      const selectedEmail = state.data.invite.existingMemberSelected;
      state.ui.error.errorList.phaseDetails = [];
      state.ui.error.hasError = false;

      if (state.ui.isLoading.changeDetailsByEmail) return false;
      state.ui.isLoading.changeDetailsByEmail = true;
      ethitransService
        .detailsByemail(selectedEmail.toString())
        .then((res: any) => {
          console.log("Details by Email Check:>>> ", res.data.data);
          if (res.data.data.email && res.data.data.full_name) {
            setNewSelectedMembers(res.data.data.full_name, res.data.data.email);
          } else {
            setNewSelectedMembers("", selectedEmail);
          }

          state.ui.isLoading.changeDetailsByEmail = false;
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.phaseDetails)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.phaseDetails.push(value[0]);
                }
              }
            });
        });
    }
    function checkTaskIdSelected() {
      const filteredArray = phaseDetails.value.phase.child_phases.filter(
        (item: any) => item.uuid === router.query.taskId
      );
      // const filteredArray = filteredPhases.value.filter((item: any) => item.uuid !== router.query.taskId);
      const startDate = filteredArray[0].start_date;
      const endDate = filteredArray[0].end_date;
      if (startDate && endDate) {
        showAddMemberToTaskModal();
        selectedChecked(filteredArray[0]);
      } else {
        showTaskUpdateModal();
        selectedTask(filteredArray[0]);
      }
    }

    function getToDoData() {
      state.ui.error.errorList.phaseDetails = [];
      state.ui.error.hasError = false;

      if (state.ui.isLoading.phaseDetails) return false;
      state.ui.isLoading.phaseDetails = true;
      ethitransService
        .addMemberInPhase(id.toString())
        .then((res: any) => {
          // console.log("getToDoData Check:>>> ", res.data.data);
          // eslint-disable-next-line @typescript-eslint/camelcase
          res.data.data.phase.edit_budget = JSON.parse(
            JSON.stringify(res.data.data.phase.budget)
          );
          if (res.data.data.smart_contract.end_date) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            res.data.data.smart_contract.end_date = common.localDateOnly(
              res.data.data.smart_contract.end_date
            );
          }
          if (res.data.data.smart_contract.start_date) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            res.data.data.smart_contract.start_date = common.localDateOnly(
              res.data.data.smart_contract.start_date
            );
          }
          if (res.data.data.phase.end_date) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            res.data.data.phase.end_date = common.localDateOnly(
              res.data.data.phase.end_date
            );

            // eslint-disable-next-line @typescript-eslint/camelcase
            res.data.data.phase.edit_end_date = JSON.parse(
              JSON.stringify(common.localDateOnly(res.data.data.phase.end_date))
            );
            // eslint-disable-next-line @typescript-eslint/camelcase
          }
          // eslint-disable-next-line @typescript-eslint/camelcase
          if (res.data.data.phase.start_date) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            res.data.data.phase.start_date = common.localDateOnly(
              res.data.data.phase.start_date
            );
            // eslint-disable-next-line @typescript-eslint/camelcase
            res.data.data.phase.edit_start_date = JSON.parse(
              JSON.stringify(
                common.localDateOnly(res.data.data.phase.start_date)
              )
            );
          }
          // eslint-disable-next-line @typescript-eslint/camelcase
          if (
            res.data.data.phase.child_phases &&
            res.data.data.phase.child_phases.length
          ) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            res.data.data.phase.child_phases.forEach((item: any) => {
              item.isChecked = false;
              // eslint-disable-next-line @typescript-eslint/camelcase
              item.edit_budget = JSON.parse(JSON.stringify(item.budget));
              // eslint-disable-next-line @typescript-eslint/camelcase
              if (item.start_date) {
                // eslint-disable-next-line @typescript-eslint/camelcase
                item.edit_start_date = JSON.parse(
                  JSON.stringify(common.localDateOnly(item.start_date))
                );
              }
              // eslint-disable-next-line @typescript-eslint/camelcase
              if (item.end_date) {
                // eslint-disable-next-line @typescript-eslint/camelcase
                item.edit_end_date = JSON.parse(
                  JSON.stringify(common.localDateOnly(item.end_date))
                );
              }
            });
          }
          // if(res.data.data.members && res.data.data.members.length){

          // }
          if (res.data.data.permissions && res.data.data.permissions.length) {
            res.data.data.permissions.forEach((per: any) => {
              per.isChecked = false;
            });
            res.data.data.amount_depends.forEach((per: any) => {
              per.isChecked = false;
            });
          }
          if (
            res.data.data.phase_permissions &&
            res.data.data.phase_permissions.length
          ) {
            res.data.data.phase_permissions.forEach((per: any) => {
              per.isChecked = false;
            });
            res.data.data.amount_depends.forEach((per: any) => {
              per.isChecked = false;
            });
          }
          if (
            res.data.data.task_permissions &&
            res.data.data.task_permissions.length
          ) {
            res.data.data.task_permissions.forEach((per: any) => {
              per.isChecked = false;
            });
            res.data.data.amount_depends.forEach((per: any) => {
              per.isChecked = false;
            });
          }
          state.data.minDate = res.data.data.phase
            ? moment(res.data.data.phase.edit_start_date).format("Y-MM-DD")
            : "";
          state.data.maxDate = res.data.data.phase
            ? moment(res.data.data.phase.edit_end_date).format("Y-MM-DD")
            : "";
          phaseDetails.value = res.data.data;
          state.ui.isLoading.phaseDetails = false;
          // Get a reference to the button element by its ID
          const button = document.getElementById("member-task-tab");
          if (router.query.taskId) {
            // selectedTab('task')
            // Check if the button element exists
            if (button) {
              // Trigger a click event on the button
              setTimeout(function () {
                // Trigger a click event on the button
                button.click();
                checkTaskIdSelected();
              }, 500);
              // console.log("CLICKED>>")
            } else {
              console.error("Button not found!");
            }
          }
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.phaseDetails)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.phaseDetails.push(value[0]);
                }
              }
            });
        });
    }

    // const userAllInfo: any = localStorage.getItem("current_user_info");
    // const userInfo: any = JSON.parse(userAllInfo);
    // state.data.loggedInUserUUID = userInfo.uuid;
    // // console.log("<<< CHECK UUID:>>>>", state.data.loggedInUserUUID);
    // // Pusher.logToConsole = true;s
    // const token = localStorage.getItem("current_user_token");
    // let newToken = "";
    // if (token) {
    //   newToken = token.replace(/"/gi, "");
    // }
    // const pusher = new Pusher(`${process.env.VUE_APP_WEBSOCKETS_KEY}`, {
    //   cluster: "ap2",
    //   // authEndpoint: 'http://localhost:8000/api/broadcasting/auth',
    //   authEndpoint: `${config.privateChannel}broadcasting/auth`,
    //   auth: {
    //     headers: {
    //       Accept: "application/json",
    //       Authorization: "Bearer " + newToken,
    //     },
    //   },
    // });

    // const todoChannel = pusher.subscribe(
    //   `private-phaseAddMember.${id.toString()}`
    // );
    // todoChannel.bind("phaseAddMember.updated", function (data: any) {
    //   console.log("PUSHER CHECK", data);
    //   getToDoData();

    //   // if (abc) {
    //   //   let url = new URL(
    //   //     "https://localhost:8080/#/todo/add-member/e4401720-e02a-4dee-962b-b67a842b9545?taskId=76d61910-1860-4d4f-af9a-ea174158e74c"
    //   //   );

    //   //   url.searchParams.delete("taskId");
    //   //   let updatedUrl = url.toString();
    //   // }

    //   // if (JSON.stringify(data)) {
    //   //   console.log("DELETE NOTIFICATION CHECK >>>> ", data);
    //   // }
    // });
    function changeInviteMember() {
      // console.log("CHECK MSG", state.inviteMember);

      if (state.data.invite.newUsers) {
        // if(state.data.invite.existingMembers){
        if (validationService.isValidEmail(state.data.invite.newUsers)) {
          // console.log("CHECK THE INVITE MEMBER", state.inviteMember);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if (state.data.invite.selectedMembers.length === 0) {
            state.data.invite.selectedMembers.push({
              name: "",
              hourly: "",
              email: state.data.invite.newUsers,
              // eslint-disable-next-line @typescript-eslint/camelcase
              sub_contractor: false,
              member: false,
              error: false,
            });
          } else {
            state.data.invite.selectedMembers.forEach((member: any) => {
              // console.log("member", member);
              // console.log("member", state.data.invite.newUsers);
              if (member.member) {
                member.member = false;
              }
              if (
                member.email.toLowerCase() !=
                state.data.invite.newUsers.toLowerCase()
              ) {
                state.data.invite.selectedMembers.push({
                  name: "",
                  hourly: "",
                  email: state.data.invite.newUsers.toLowerCase(),
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  sub_contractor: false,
                  member: false,
                  error: false,
                });
              }
            });
          }
          state.data.invite.newUsers = "";
          state.data.invite.existingMembers = "";
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          state.data.invite.selectedMembers = Array.from(
            new Set(state.data.invite.selectedMembers.map((s: any) => s.email))
          ).map((email) => {
            return {
              email: email,
              // eslint-disable-next-line @typescript-eslint/camelcase
              sub_contractor: state.data.invite.selectedMembers.find(
                (s: any) => s.email === email
              ).sub_contractor,
              member: state.data.invite.selectedMembers.find(
                (s: any) => s.email === email
              ).member,
              name: state.data.invite.selectedMembers.find(
                (s: any) => s.email === email
              ).name,
              hourly: state.data.invite.selectedMembers.find(
                (s: any) => s.email === email
              ).hourly,
              error: state.data.invite.selectedMembers.find(
                (s: any) => s.email === email
              ).error,
            };
          });
          // state.data.invite.selectedMembers = Array.from(
          //   new Set(state.data.invite.selectedMembers.map((s: any) => s.email))
          // ).map((email) => {
          //   return {
          //     email: email,
          //     // eslint-disable-next-line @typescript-eslint/camelcase
          //     sub_contractor: false,
          //     member: false,
          //   };
          // });
        }
      } else {
        if (validationService.isValidEmail(state.data.invite.existingMembers)) {
          // console.log("CHECK THE INVITE MEMBER", state.inviteMember);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if (state.data.invite.selectedMembers.length === 0) {
            state.data.invite.selectedMembers.push({
              name: "",
              hourly: "",
              email: state.data.invite.existingMembers,
              // eslint-disable-next-line @typescript-eslint/camelcase
              sub_contractor: false,
              member: false,
              error: false,
            });
          } else {
            state.data.invite.selectedMembers.forEach((member: any) => {
              console.log("member", member);
              console.log("member", state.data.invite.existingMembers);
              if (member.member) {
                member.member = false;
              }
              if (
                member.email.toLowerCase() !=
                state.data.invite.existingMembers.toLowerCase()
              ) {
                state.data.invite.selectedMembers.push({
                  name: "",
                  hourly: "",
                  email: state.data.invite.existingMembers.toLowerCase(),
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  sub_contractor: false,
                  member: false,
                  error: false,
                });
              }
            });
          }
          state.data.invite.existingMembers = [];

          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          state.data.invite.selectedMembers = Array.from(
            new Set(state.data.invite.selectedMembers.map((s: any) => s.email))
          ).map((email) => {
            return {
              email: email,
              // eslint-disable-next-line @typescript-eslint/camelcase
              sub_contractor: state.data.invite.selectedMembers.find(
                (s: any) => s.email === email
              ).sub_contractor,
              member: state.data.invite.selectedMembers.find(
                (s: any) => s.email === email
              ).member,
              name: state.data.invite.selectedMembers.find(
                (s: any) => s.email === email
              ).name,
              hourly: state.data.invite.selectedMembers.find(
                (s: any) => s.email === email
              ).hourly,
              error: state.data.invite.selectedMembers.find(
                (s: any) => s.email === email
              ).error,
            };
          });
        }
      }
      // state.data.invite.existedMembers = [];
    }
    function selectedMember(selectedMember: any) {
      state.data.selectedToRemove = selectedMember;
      state.data.showDeleteConfirmationModal =
        !state.data.showDeleteConfirmationModal;
      // props.members
      // state.data.members = state.data.members.filter((e: any) => e != selectedMember);
    }
    function removeSelectedMember() {
      // props.members
      state.data.invite.selectedMembers =
        state.data.invite.selectedMembers.filter(
          (e: any) => e != state.data.selectedToRemove
        );
    }
    function hideConfirmation() {
      state.data.showDeleteConfirmationModal =
        !state.data.showDeleteConfirmationModal;
    }
    function addMembersInPhases() {
      state.ui.error.errorList.phaseDetails = [];
      state.ui.error.hasError = false;

      const filterMembers = [] as any;
      state.data.invite.selectedMembers.forEach((member: any) => {
        const permissionList = [] as any;
        const timeSlots = [] as any;
        if (
          member.role &&
          member.role.permissions &&
          member.role.permissions.length
        ) {
          member.role.permissions.forEach((permission: any) => {
            if (permission.isChecked) {
              permissionList.push(permission.id);
            }
          });
        }
        member.filteredSlots.forEach((slot: any) => {
          timeSlots.push({
            // eslint-disable-next-line @typescript-eslint/camelcase
            start_time: slot.formatedStart,
            // eslint-disable-next-line @typescript-eslint/camelcase
            end_time: slot.formatedEnd,
            title: slot.title,
          });
        });
        filterMembers.push({
          email: member.email,
          name: member.name,
          // eslint-disable-next-line @typescript-eslint/camelcase
          role_id: member.role.id,
          // eslint-disable-next-line @typescript-eslint/camelcase
          amount_type_id: member.amountType.id,
          amount: member.amountTypeNumber,
          // eslint-disable-next-line @typescript-eslint/camelcase
          approval_required: member.approval_required,
          permissions: permissionList,
          // eslint-disable-next-line @typescript-eslint/camelcase
          time_slots: timeSlots,
        });
      });

      // add validation here

      if (state.ui.isPostLoading.addMembersInPhases) return false;
      state.ui.isPostLoading.addMembersInPhases = true;

      const payLoad = {
        phases: [
          {
            uuid: phaseDetails.value.phase.uuid,
            // eslint-disable-next-line @typescript-eslint/camelcase
            priority_id: phaseDetails.value.phase.priority_id,
            // eslint-disable-next-line @typescript-eslint/camelcase
            start_date: phaseDetails.value.phase.edit_start_date,
            // eslint-disable-next-line @typescript-eslint/camelcase
            end_date: phaseDetails.value.phase.edit_end_date,
            budget: phaseDetails.value.phase.edit_budget,
          },
        ],
        members: filterMembers,
      };
      console.log("PayLoad Add Member Check >> ", payLoad);
      ethitransService
        .addMembersInPhases(payLoad)
        .then((res: any) => {
          state.data.showSuccesCustomModal = true;
          console.log("Response Check >> ", res.data.data);
          // state.data.unitLists = res.data.data;
          state.ui.isPostLoading.addMembersInPhases = false;
        })
        .catch((error: any) => {
          state.ui.isPostLoading.addMembersInPhases = false;
          common
            .sdCatchErr(error, state.ui.error.errorList.phaseDetails)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.phaseDetails.push(value[0]);
                }
              }
            });
        });
    }
    function selectedPdf(link: any) {
      window.open(link, "_blank");
    }
    function receivedInvocieByPhaseId() {
      state.ui.error.errorList.phaseDetails = [];
      state.ui.error.hasError = false;

      if (state.ui.isPostLoading.receivedInvocieByPhaseId) return false;
      state.ui.isPostLoading.receivedInvocieByPhaseId = true;

      const payLoad = {
        id: id,
        status: state.data.filterReceivedInvoiceList.listByStatus,
        title: state.data.filterReceivedInvoiceList.listByTitle,
      };
      console.log("PayLoad Add Member Check >> ", payLoad);
      ethitransService
        .receivedInvocieByPhaseId(payLoad)
        .then((res: any) => {
          // state.data.showSuccesCustomModal = true;
          console.log("Response Check >> ", res.data.data);
          res.data.data.forEach((item: any)=> {
            item.modifiedCreateddate = common.formatDateAndTime(item.created_date);
          })
          state.data.filterReceivedInvoiceList.list = res.data.data
            ? res.data.data
            : [];
          // state.data.unitLists = res.data.data;
          state.ui.isPostLoading.receivedInvocieByPhaseId = false;
        })
        .catch((error: any) => {
          // state.ui.isPostLoading.receivedInvocieByPhaseId = false;
          common
            .sdCatchErr(error, state.ui.error.errorList.phaseDetails)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.phaseDetails.push(value[0]);
                }
              }
            });
        });
    }
    function selectedTab(type: string) {
      state.data.activeTab = type;
      if (type === "received-invoice") {
        const { mode, ...restQuery } = router.query;
        route.push({ query: restQuery });
        receivedInvocieByPhaseId();
      } else if (type === "requested-invoice") {
        const newQuery = { ...router.query, mode: 2 };
        route.push({ query: newQuery });
      } else {
        console.log("HELLO");
        const newQuery = { ...router.query, mode: 1 };
        route.push({ query: newQuery });
      }
    }
    function getPhaseDetails(id: any) {
      state.ui.error.errorList.getPhaseDetails = [];
      state.ui.error.hasError = false;

      if (state.ui.isLoading.getPhaseDetails) return false;
      state.ui.isLoading.getPhaseDetails = true;
      ethitransService
        .phaseDetailsById(id.toString())
        .then((res: any) => {
          // console.log("getPhaseDetails Response Check:>>> ", res.data.data);
          // console.log("LINK Check:>>> ", config.liveImageBaseUrl);
          res.data.data.phase.localStartedDate = common.localDateOnly(
            res.data.data.phase.start_date
          );
          res.data.data.phase.localEndDate = common.localDateOnly(
            res.data.data.phase.end_date
          );
          if (res.data.data.attachments) {
            res.data.data.attachments.forEach((attachment: any) => {
              // attachment.file_size
              attachment.activeLink =
                config.liveImageBaseUrl + attachment.file_link;
              attachment.convertedFileSize = common.formatBytes(
                parseInt(attachment.file_size)
              );
              // console.log("HELLO ATTACHMENT >>> ", attachment.activeLink)
            });
          }

          res.data.data.phase.child_phases.forEach((child: any) => {
            child.isChecked = false;
            if (child.start_date) {
              child.localStartedDate = common.localDateOnly(child.start_date);
            } else {
              child.localStartedDate = "";
            }
            if (child.end_date) {
              child.localEndDate = common.localDateOnly(child.end_date);
            } else {
              child.localEndDate = "";
            }
          });
          res.data.data.phase.budget = common.formatCurrency(
            res.data.data.phase.budget
          );
          console.log("CHECK BUDGET >>>> ", res.data.data.phase.budget);
          res.data.data.invoices = res.data.data.invoices
            ? res.data.data.invoices
            : {};
          state.data.phaseDetails = res.data.data;
          const button = document.getElementById("received-invoice-tab");
          if(state.data.phaseDetails && state.data.phaseDetails.role === 2){
            if (button) {
            setTimeout(function () {
                // Trigger a click event on the button
                button.click();
                selectedTab('received-invoice')
              }, 500);
          }
          }
          // res.data.data.phases.data.forEach((phase: any)=> {
          //   phase.localStartedDate = common.localDateAndTime(
          //       phase.start_date
          //     );
          //   phase.localEndDate = common.localDateAndTime(
          //       phase.end_date
          //     );
          // })

          // todoData.value = res.data.data;
          state.ui.isLoading.getPhaseDetails = false;
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.getPhaseDetails)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.getPhaseDetails.push(value[0]);
                }
              }
            });
        })
    }

    onMounted(() => {
      getPhaseDetails(id);
      // getToDoData();
      console.log("CHECK >>>");
      const newQuery = { ...router.query, mode: 1 };
      route.push({ query: newQuery });
    });

    return {
      state,
      checkPermissionExists,
      tabRoleStatus,
      choosePaymentMode,
      hideSelectedReceivedInvoice,
      selectedPdf,
      selectedInvoice,
      receivedInvocieByPhaseId,
      checkTaskIdSelected,
      taskId,
      memberFilteredSlotsValid,
      hasValidFilteredSlots,
      // filterSlots,
      clickEvent,
      selectedMemberIndex,
      createdEvent,
      deletedEvent,
      selectedTab,
      filteredEvents,
      findIndexById,
      checkWithRow,
      setMemberUpdatedTask,
      addMemberToTaskData,
      addMemberToTaskSuccess,
      selectedChecked,
      hideAddMemberToTaskModal,
      showAddMemberToTaskModal,
      setUpdatedTask,
      hideUpdateTaskModal,
      selectedTask,
      showTaskUpdateModal,
      filteredPhases,
      filteredChildPhases,
      hideAvaibilityModal,
      deleteRole,
      selectedMemberForCheckAvaibility,
      deleteAmountType,
      addNewAmountType,
      addNewRole,
      closeSuccessCustomModal,
      addMembersInPhases,
      selectedAmountTypeForMember,
      selectedRoleForMember,
      selectedMember,
      getToDoData,
      phaseDetails,
      hideConfirmation,
      changeInviteMember,
      changeDetailsByEmail,
      setNewSelectedMembers,
      removeSelectedMember,
    };
  },
});
