
import { defineComponent, reactive, onMounted, computed, ref, watch } from "vue";
import { ethitransService } from "@/services/EthitransService";
import { common } from "@/common/services/Common";
import { useRoute, useRouter, onBeforeRouteLeave } from "vue-router";
import PriceInputField from '@/common/PriceInputField.vue'
import Multiselect from 'vue-multiselect';
import moment from "moment";
import SuccessfulModalCustom from "@/components/modal/SuccessfulModalCustom.vue";
import CreateLineItemInJobEstimation from "./CreateLineItemInJobEstimation.vue";
import Editor from "@/components/mention/Editor.vue";
import { TabulatorFull as Tabulator } from 'tabulator-tables'; //import Tabulator library
import { config } from "@/Config";
import cloneDeep from 'lodash/cloneDeep';
import SignaturePadModal from "@/components/modal/SignaturePadModal.vue";
import SignatureUploadModal from "@/components/modal/SignatureUploadModal.vue";
import ErrorList from "@/components/commonDesign/ErrorList.vue";
import GoogleAddressCustomer from "../commonDesign/GoogleAddressCustomer.vue";

export default defineComponent({
    name: "UpdateJobEstimation",
    components: {
        SuccessfulModalCustom,
        CreateLineItemInJobEstimation,
        PriceInputField,
        Multiselect,
        Editor,
        SignaturePadModal,
        SignatureUploadModal,
        ErrorList,
        GoogleAddressCustomer
    },
    setup() {
        const router = useRouter();
        const {
            params: { estimationId },
        } = useRoute();
        const route = useRoute();
        const table = ref(); //reference to your table element
        const tabulator = ref(); //variable to hold your table
        const filterValue = ref();
        const {
            params: { projectId },
        } = useRoute();
        const isInvalid = ref(false);
        const latitude = ref<number>(36.0755227);  // Default latitude
        const longitude = ref<number>(-79.8203066);  // Default longitude
        const address = ref<string>('');
        const showMap = ref<boolean>(false);

        const updateLatitude = (newLatitude: number) => {
            latitude.value = newLatitude;
        };

        const updateLongitude = (newLongitude: number) => {
            longitude.value = newLongitude;
        };
        const customer = reactive({
            // eslint-disable-next-line @typescript-eslint/camelcase
            address_line1: ''

        });

        const updateAddress = (newAddress: string) => {
            // eslint-disable-next-line @typescript-eslint/camelcase
            customer.address_line1 = newAddress;


        };


        const state = reactive({
            tableData: [] as any,
            selectedRows: [] as any,
            fields: {

            } as any,
            projectId: projectId,
            estimationUUId: '' as any,
            selectUnitId: '' as any,
            selectSubItemIndex: '' as any,
            selectItemIndex: '' as any,
            selectSubItem: '' as any,
            selectDeleteItem: '' as any,
            approveByAll: false,
            selectIndex: '' as any,
            selectItemId: '' as any,
            dataFor: '' as any,
            cloningSelectedItem: false,
            selectItems: [

            ] as any,
            data: {
                removeUser: {} as any,
                haveProfileSign: false,
                useProfileSign: false,
                showSignaturePad: false,
                showSignatureUpload: false,
                deletedCustomerEmails: [] as string[],
                uploadSignatureFile: "" as any,
                signatureLink: "" as any,
                cropImage: "",
                newUploadImge: "" as any,
                cropSignature: "" as any,
                note: '',
                selectedCustomers: [] as any,
                allCustomer: [] as any,
                company: "" as any,
                signatureFileName: "" as any,
                details: {} as any,
                inputFrom: 'markUp',
                createDate: moment(new Date()).format("Y-MM-DD"),
                minDate: '',
                searchName: '' as any,
                lineItems: [] as any,
                unitLists: [] as any,
                title: '' as any,
                unit: {
                    name: '',
                    abbreviation: '',
                },
                showSuccesCustomModal: false,
                successFulMessage: '',
                sendarSignature: "" as any,
                uploadSignature: "" as any,
                // eslint-disable-next-line @typescript-eslint/camelcase
                signature_link: '' as any,
                companyInfo: {
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    company_name: "",
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    company_email: "",
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    company_phone_number: "",
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    company_address: "",
                    acknowledgement: "",
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    legal_statement: "",
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    logo: "",
                },
            },
            ui: {
                isLoading: {
                    isRemoveCustomer: false,
                    geJobEstimationList: false,
                    isEdit: false,
                    getItemList: false,
                    isUnitEdit: false,
                    getUnit: false,
                    addNewSubItem: false,
                    isSaveSend: false,
                    signaturePadLoad: false,
                    isCompanyEdit: false,
                    getData: false,
                },
                isPostLoading: {
                    delete: false,
                    addUnite: false,
                    selectedButton: false,
                    createJobEstimation: false,
                    checkSignature: false,
                    sendJobEstimation: false,
                    createCompanyIfno: false,
                },
                errors: {
                    errorList: {
                        createUnit: [] as any,
                        createJobEstimation: [] as any,
                        createCompanyIfno: [] as any,
                        sendJobEstimation: [] as any,
                    },
                    validationErrorList: {
                        itemName: "",
                        unitName: "",
                        unitAbbreviation: "",
                    },
                    hasError: false,
                    titleError: false
                },
            },
        });


        function readLogo(file: any) {
            const data = {} as any;
            const reader = new FileReader();
            reader.onload = (e: any) => {
                const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
                const extensionLower = extension.toLowerCase();
                data.file = e.target.result;
                data.filename = file.name.replaceAll(`.${extension}`, "");
                data.extension = extensionLower;
                data.isuploded = false;
                data.size = common.formatBytes(parseInt(file.size));
                state.data.details.logo = data;
            };
            reader.readAsDataURL(file);
        }


        function uploadLogo(event: any) {
            const files = event.target.files || event.dataTransfer.files;
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                // console.log("FILE CHECK >>>", file);
                // if(file.type)
                const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
                const extensionLower = extension.toLowerCase();
                readLogo(file);
            }
        }

        function readImage(file: any) {
            const data = {} as any;
            const reader = new FileReader();
            reader.onload = (e: any) => {
                const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
                const extensionLower = extension.toLowerCase();
                data.file = e.target.result;
                data.name = file.name.replaceAll(`.${extension}`, "");
                data.extension = extensionLower;
                console.log("CHECK DATA FOR UPLOAD>>", data);
                // state.imageList.push(data);
            };
            reader.readAsDataURL(file);
        }
        const dropFiles = (event: any) => {
            const files = event.target.files || event.dataTransfer.files;
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                readImage(file);
            }
        };

        function readSignature(file: any) {
            const data = {} as any;
            const reader = new FileReader();
            reader.onload = (e: any) => {
                const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
                const extensionLower = extension.toLowerCase();
                data.base64 = e.target.result;
                data.duplicateFile = false; // data.fileName = file.name;
                data.fileName = file.name.replaceAll(`.${extension}`, "");
                data.extension = extensionLower;
                data.isuploded = false;
                data.tag = [] as any;
                data.size = common.formatBytes(parseInt(file.size));
                // state.data.uploadImageData = data;
                state.data.newUploadImge = data.base64;
                state.data.cropImage = data.base64;
                state.data.signatureFileName = data.fileName;
            };
            reader.readAsDataURL(file);
        }

        function onDocumentChange(event: any) {
            const files = event.target.files || event.dataTransfer.files;
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                // console.log("FILE CHECK >>>", file);
                // if(file.type)
                const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
                const extensionLower = extension.toLowerCase();
                readSignature(file);
                $("#updateUpload").modal("show");
            }
        }

        function showUploadImageModel() {
            $("#updateUpload").modal("show");
        }

        function hideShowUploadImageModel() {
            $("#profileUpdate").val("");
            $("#updateUpload").modal("hide");
        }


        function enableSignaturPad() {

            state.ui.isLoading.signaturePadLoad = !state.ui.isLoading.signaturePadLoad;
        }

        function gotoJobEstimationSend() {
            router.push({
                name: "send-job-estimation",
                params: { estimationId: state.estimationUUId },
            });
        }

        function gotoJobEstimation() {
            router.push({
                name: "list-job-estimation",
                params: { projectId: projectId },
            });
        }

        function deleteItemShow(item: any) {
            state.selectDeleteItem = item;
            $("#deleteItem").modal("show");
        }
        function deleteItemClose() {
            state.selectDeleteItem = '';
            $("#deleteItem").modal("hide");
        }

        function closeSuccessCustomModal() {
            $("#SuccessfulModalCustom").modal("hide");
            if (state.ui.isLoading.isSaveSend) {
                gotoJobEstimationSend()
            } else {
                gotoJobEstimation();
            }
        }

        function validationCreateJobEstimation() {
            state.ui.errors.titleError = false;
            state.ui.errors.hasError = false;
            if (!state.data.title) {
                state.ui.errors.titleError = true;
                state.ui.errors.hasError = true;
            }
            state.selectItems.forEach((item: any) => {
                item.quantityError = false
                if (!item.quantity) {
                    item.quantityError = true
                    state.ui.errors.hasError = true;
                }
            });

            state.selectItems.forEach((item: any) => {
                item.unitError = false
                if (!item.unit) {
                    item.unitError = true
                    state.ui.errors.hasError = true;
                }
            });

            state.selectItems.forEach((item: any) => {
                item.costError = false
                if (!item.cost) {
                    item.costError = true
                    state.ui.errors.hasError = true;
                }
            });

        }


        function usdPriceFormate(data: any) {
            if (data != null) {
                return data.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                });
            }

        }

        function getItemList() {
            state.ui.isLoading.getItemList = true
            state.ui.isLoading.getData = true
            ethitransService
                .getActiveItemList(state.data.searchName)
                .then((res: any) => {
                    state.tableData = [];
                    res.data.data.mainitems.forEach((item: any) => {
                        const childData = [] as any;
                        item.subitems.forEach((subItem: any) => {
                            const subData = {
                                id: subItem.id,
                                name: subItem.name,
                                description: subItem.description,
                                unit: subItem.unit ? subItem.unit.name : '',
                                cost: usdPriceFormate(subItem.cost ? subItem.cost : 0),
                                markup: usdPriceFormate(subItem.markup ? subItem.markup : 0),
                            }
                            childData.push(subData)
                        });
                        const pushData = {
                            id: item.id,
                            name: item.name,
                            description: item.description,
                            unit: item.unit ? item.unit.name : '',
                            cost: usdPriceFormate(item.cost ? item.cost : 0),
                            markup: usdPriceFormate(item.markup ? item.markup : 0),
                            _children: childData,
                        };
                        state.tableData.push(pushData);
                    });
                    res.data.data.mainitems.forEach((item: any) => {
                        item.isChecked = false;
                        item.markup = item.markup ? item.markup : 0;
                        item.cost = item.cost ? item.cost : 0;
                        item.subTotal = item.cost ? item.cost : 0;
                        item.selectUnit = state.data.unitLists.filter((unit: any) => unit.id === item.unit_id)[0];
                        if (item.markup_type == 1) {
                            const parCal = (parseFloat(item.cost) * parseFloat(item.markup)) / 100;
                            item.total = parCal + parseFloat(item.cost);
                        } else {
                            item.total = parseFloat(item.cost) + parseFloat(item.markup);
                        }
                        item.subitems.forEach((subItem: any) => {
                            subItem.isChecked = false;
                            subItem.mainName = item.name;
                            subItem.selectUnit = state.data.unitLists.filter((unit: any) => unit.id === subItem.unit_id)[0];
                            if (subItem.markup_type == 1) {
                                const parCal = (parseFloat(subItem.cost) * parseFloat(subItem.markup)) / 100;
                                subItem.total = parCal + parseFloat(subItem.cost);
                            } else {
                                subItem.total = parseFloat(subItem.cost) + parseFloat(subItem.markup);
                            }
                            subItem.subTotal = subItem.cost ? subItem.cost : 0;
                        });
                    });
                    state.data.lineItems = res.data.data.mainitems;

                })
                .catch((error: any) => {
                    console.log(error);
                })
                .finally(() => {
                    state.ui.isLoading.getItemList = false;
                    state.ui.isLoading.getData = false;
                });
        }
        function checkSubItem(item: any) {
            if (item.subitems) {
                item.subitems.forEach((subItem: any) => {
                    subItem.isChecked = item.isChecked;
                });
            }
        }
        function checkItem(item: any) {
            if (item.subitems) {
                const allChecked = item.subitems.every((subItem: any) => subItem.isChecked);
                item.isChecked = allChecked;
                state.ui.isPostLoading.selectedButton = true;
            }
        }

        function closeSelected(index: any) {
            state.ui.isLoading.getData = true;
            state.data.lineItems.forEach((lineItem: any) => {
                if (tabulator.value.getSelectedData().find((item2: any) => item2.id === lineItem.id)) {
                    if (!state.selectedRows.includes(lineItem.id)) {
                        lineItem.isChecked = true;
                        lineItem.isNew = true;
                        state.selectedRows.push(lineItem.id);
                    }

                }
                lineItem.subitems.forEach((subItem: any) => {
                    if (tabulator.value.getSelectedData().find((item2: any) => item2.id === subItem.id)) {
                        if (!state.selectedRows.includes(subItem.id)) {
                            subItem.isChecked = true;
                            subItem.isNew = true;
                            state.selectedRows.push(subItem.id);
                        }
                    }
                })
            });

            state.data.lineItems.forEach((lineItem: any) => {
                lineItem.mainName = '';
                const found = state.selectItems.some((item: any) => item.id === lineItem.id);
                const emptyIndex = state.selectItems.findIndex((item: any) => Object.keys(item).length === 0 || item.id == 0);
                if (emptyIndex !== -1) {
                    state.selectItems.splice(emptyIndex, 1);
                }
                if (lineItem.isChecked && !found && lineItem.id != 0 && !state.selectItems.some((item: any) => item.sub_item_id === lineItem.id)) {
                    lineItem.quantity = 1;
                    state.selectItems.push(lineItem);
                }
                lineItem.subitems.forEach((subItem: any) => {
                    subItem.mainName = lineItem.name;
                    const found = state.selectItems.some((item: any) => item.id === subItem.id);
                    const emptyIndex = state.selectItems.findIndex((item: any) => Object.keys(item).length === 0 || item.id == 0);
                    if (emptyIndex !== -1) {
                        state.selectItems.splice(emptyIndex, 1);
                    }
                    if (subItem.isChecked && !found && subItem.id != 0 && !state.selectItems.some((item: any) => item.sub_item_id === subItem.id)) {
                        subItem.quantity = 1;
                        state.selectItems.push(subItem);
                    }


                });
            });
            if (state.selectItems.length == 0) {
                state.selectItems = [{
                    'id': 0,
                    'total': 0
                }];
            }
            state.ui.isLoading.getData = false
            $(`.job-item-dropdown${index}`).dropdown('toggle');
            $(`.job-item-dropdown${index}`).dropdown('hide')

        }

        function cloneItem(index: any, item: any) {

            state.cloningSelectedItem = true;

            const newItem = cloneDeep(state.selectItems);

            const originalItem = cloneDeep(newItem[index]);
            originalItem.isCopy = true;
            const updatedItems = newItem
                .slice(0, index + 1)
                .concat(originalItem)
                .concat(newItem.slice(index + 1));

            // eslint-disable-next-line @typescript-eslint/camelcase
            state.selectItems = [];
            setTimeout(() => {
                state.selectItems = updatedItems;
                state.cloningSelectedItem = false;
            }, 0);

        }
        function getAllUnits() {

            state.ui.isLoading.getUnit = true
            ethitransService
                .getUnitList()
                .then((res: any) => {
                    state.data.unitLists = res.data.data;

                })
                .catch((error: any) => {
                    console.log(error);
                })
                .finally(() => {
                    state.ui.isLoading.getUnit = false;
                });

        }

        function removeSelectedSubItem(item: any) {
            if (item.id) {
                ethitransService
                    .deleteJobEstimationItem(item.id)
                    .then((res: any) => {

                        //
                    })
                    .catch((error: any) => {
                        console.log(error);
                    })
                    .finally(() => {
                        // state.selectDeleteItem ='';               
                    });
            }
            item.isChecked = false;
            const selectedIndex = common.findIndex(state.selectItems, item);
            state.selectItems[selectedIndex].isChecked = false;
            state.selectItems = state.selectItems.filter((e: any) => e != item);
            state.selectDeleteItem = '';
            deleteItemClose();
        }

        function addMoreSubItems(item: any) {
            if (Object.keys(item).length != 0) {
                state.selectItems.push({ id: 0, total: 0, markup: 0, cost: 0 });
            }
        }

        function checkInputNumber(item: any) {
            const data = item.toString().replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
            return data;
        }


        const iconFormatter = (cell: any) => {

            const rows = tabulator.value.getRows();
            for (let i = 0; i < rows.length; i++) {
                const children = rows[i].getTreeChildren();
                for (let j = 0; j < children.length; j++) {
                    const child = children[j].getData();
                    if (state.selectedRows.includes(child['id'])) {
                        children[j].select();
                    }
                }
            }
            tabulator.value.selectRow(state.selectedRows);
            const row = cell.getRow();
            return row.getTreeParent() ? '' : '<i class="fa fa-plus"></i>';

        };
        function addSubItem(data: any) {

            state.selectItemId = data;
            state.dataFor = 'subItem';
            state.ui.isLoading.addNewSubItem = !state.ui.isLoading.addNewSubItem;
            $(`.job-item-dropdown${state.selectIndex}`).dropdown('toggle');
            $(`.job-item-dropdown${state.selectIndex}`).dropdown('hide');
            state.selectIndex = '';
        }



        function resizeTable() {
            if (state.selectItems.length > 0) {
                tabulator.value = new Tabulator(table.value, {

                    height: "311px",
                    layout: "fitColumns",
                    dataTree: true,
                    dataTreeStartExpanded: true,
                    dataTreeFilter: true, //disable child row filtering
                    // getData: true,
                    paginationSize: 20,
                    ajaxURL: `${config.apiBaseUrl}all/item`,
                    progressiveLoad: "scroll",
                    placeholder: "No Data Set",
                    ajaxProgressiveLoad: true,
                    ajaxProgressiveLoadScroll: 20,
                    progressiveLoadDelay: 500,
                    progressiveLoadScrollMargin: 500,
                    ajaxConfig: {
                        method: "GET", //set request type to Position
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("current_user_token")}`,
                        },
                    },
                    columns: [
                        // titleFormatter: "rowSelection",
                        {
                            formatter: "rowSelection", hozAlign: "center", width: 100, headerSort: false, cellClick: function (e: any, cell: any) {
                                cell.getRow().toggleSelect();
                            }
                        },
                        { title: "Name", field: "name", sorter: "string", width: 200, headerFilter: "input", headerFilterPlaceholder: "Find a item..." },
                        { title: "Description", field: "description", width: 300, sorter: "string" },
                        { title: "Unit", field: "unit", sorter: "string" },
                        { title: "Cost", field: "cost", sorter: "string" },
                        { title: "Markup", field: "markup", sorter: "string" },
                        {
                            title: "Action", field: "naiconme", hozAlign: "center", formatter: iconFormatter, cellClick: function (e: any, cell: any) {
                                const row = cell.getRow();
                                const parentId = row.getData().uuid;
                                addSubItem(parentId);
                            },
                            tooltip: function (e: any, cell: any, onRendered: any) {
                                //e - mouseover event
                                //cell - cell component
                                //onRendered - onRendered callback registration function

                                const el = document.createElement("div");
                                el.style.backgroundColor = "white";
                                el.innerText = 'Add a new sub item'

                                return el;
                            },
                        }], //define table columns


                });

            }

        }

        function onToggleChooseItem(index: any) {
            state.selectIndex = '';
            state.selectIndex = index;
            state.ui.isLoading.getData = true;
            state.selectItems[index].tableSelected = true;
            setTimeout(() => {
                resizeTable();
                state.ui.isLoading.getData = false;
            }, 2000)
        }
        function calculateTotal(item: any) {
            const selectedIndex = common.findIndex(state.selectItems, item);
            state.selectItems[selectedIndex].cost = checkInputNumber(item.cost ? item.cost : 0);
            state.selectItems[selectedIndex].markup = checkInputNumber(item.markup ? item.markup : 0);
            const total = computed(() => {
                if (item.markup_type == 1) {
                    const prCal = ((state.selectItems[selectedIndex].quantity ? parseFloat(state.selectItems[selectedIndex].quantity) : 0) * (state.selectItems[selectedIndex].cost ? parseFloat(state.selectItems[selectedIndex].cost) : 0) * (state.selectItems[selectedIndex].markup ? parseFloat(state.selectItems[selectedIndex].markup) : 0)) / 100;
                    return prCal + ((state.selectItems[selectedIndex].quantity ? parseFloat(state.selectItems[selectedIndex].quantity) : 0) * (state.selectItems[selectedIndex].cost ? parseFloat(state.selectItems[selectedIndex].cost) : 0));
                } else {

                    return ((state.selectItems[selectedIndex].cost ? parseFloat(state.selectItems[selectedIndex].cost) : 0) * (state.selectItems[selectedIndex].quantity ? parseFloat(state.selectItems[selectedIndex].quantity) : 0)) + (state.selectItems[selectedIndex].markup ? parseFloat(state.selectItems[selectedIndex].markup) : 0);
                }

            });
            state.selectItems[selectedIndex].total = total;
            state.selectItems[selectedIndex].subTotal = ((state.selectItems[selectedIndex].quantity ? parseFloat(state.selectItems[selectedIndex].quantity) : 0) * (state.selectItems[selectedIndex].cost ? parseFloat(state.selectItems[selectedIndex].cost) : 0));

        }

        function addUnitShow() {
            $("#addUnite").modal("show");
        }
        function addUniteClose() {
            state.ui.errors.validationErrorList.unitName = "";
            state.ui.errors.validationErrorList.unitAbbreviation = "";
            state.data.unit.abbreviation = "";
            state.data.unit.name = "";
            $("#addUnite").modal("hide");
            state.ui.isLoading.isUnitEdit = false;
        }

        function changeMarkupType(index: any, value: any) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            state.selectItems[index].markup_type = value;

            calculateTotal(index);
        }

        function changeUnit(index: any, value: any) {
            console.log(index, value);
            // eslint-disable-next-line @typescript-eslint/camelcase
            state.selectItems[index].unit_id = value.id;
            state.selectItems[index].unit = value;
        }

        function editUnit(item: any) {
            state.data.unit.name = item.name;
            state.data.unit.abbreviation = item.abbreviation;
            state.selectUnitId = item.id;
            state.ui.isLoading.isUnitEdit = true;
            addUnitShow();
        }

        function addNewUnit(item: any) {
            state.selectSubItemIndex = '';
            state.selectSubItemIndex = common.findIndex(state.selectItems, item);
            addUnitShow();
        }

        function deleteUnitList(item: any, unitList: any) {
            state.selectSubItemIndex = '';
            state.selectSubItemIndex = common.findIndex(state.selectItems, item);
            ethitransService
                .deleteUnite(unitList.id)
                .then((res: any) => {
                    if (state.selectItems[state.selectSubItemIndex].selectUnit) {
                        state.selectItems[state.selectSubItemIndex].selectUnit = '';
                    }
                    state.data.unitLists = res.data.data;


                })
                .catch((error: any) => {
                    console.log(error);
                })
                .finally(() => {
                    // state.ui.isLoading.getUnit = false;
                });

        }

        function deleteUnit(item: any, unitList: any) {
            const result = window.confirm('Do you want to delete?');
            if (result) {
                deleteUnitList(item, unitList);
                console.log('User clicked "Yes"');
            } else {

                console.log('User clicked "No"');
            }
        }


        function createUnitValidation() {
            state.ui.errors.validationErrorList.unitName = "";
            if (!state.data.unit.name) {
                state.ui.errors.validationErrorList.unitName = "Ener valid unit";
                state.ui.errors.hasError = true;
            }
            // if (!state.data.unit.abbreviation) {
            //     state.ui.errors.validationErrorList.unitAbbreviation = "Ener valid abbreviation";
            //     state.ui.errors.hasError = true;
            // }
        }

        function createUnit() {
            state.ui.isPostLoading.addUnite = false;
            state.ui.errors.hasError = false;
            createUnitValidation();
            if (state.ui.isPostLoading.addUnite || state.ui.errors.hasError) return false;
            const payLoad = {

                name: state.data.unit.name,
                abbreviation: state.data.unit.abbreviation,

            };
            state.ui.isPostLoading.addUnite = true;
            ethitransService
                .createUnit(payLoad)
                .then((res: any) => {
                    state.selectItems[state.selectSubItemIndex].unit = res.data.data[0];
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    state.selectItems[state.selectSubItemIndex].unit_id = res.data.data[0].id;

                    state.data.unitLists = res.data.data;
                })
                .catch((error: any) => {
                    common
                        .sdCatchErr(error, state.ui.errors.errorList.createUnit)
                        .then((res: any) => {
                            state.ui.errors.hasError = true;
                            for (const [key, value] of Object.entries(res)) {
                                if (Array.isArray(value)) {
                                    state.ui.errors.errorList.createUnit.push(value[0]);
                                }
                            }
                        });
                })
                .finally(() => {
                    state.ui.isPostLoading.addUnite = false;
                    addUniteClose();

                });
        }

        function updateUnit() {
            state.ui.isPostLoading.addUnite = false;
            state.ui.errors.hasError = false;
            createUnitValidation();
            if (state.ui.isPostLoading.addUnite || state.ui.errors.hasError) return false;
            const payLoad = {

                name: state.data.unit.name,
                abbreviation: state.data.unit.abbreviation,

            };
            state.ui.isPostLoading.addUnite = true;
            ethitransService
                .updateUnit(state.selectUnitId, payLoad)
                .then((res: any) => {
                    state.data.unitLists = res.data.data;

                })
                .catch((error: any) => {
                    common
                        .sdCatchErr(error, state.ui.errors.errorList.createUnit)
                        .then((res: any) => {
                            state.ui.errors.hasError = true;
                            for (const [key, value] of Object.entries(res)) {
                                if (Array.isArray(value)) {
                                    state.ui.errors.errorList.createUnit.push(value[0]);
                                }
                            }
                        });
                })
                .finally(() => {
                    state.ui.isPostLoading.addUnite = false;
                    state.selectUnitId = '';
                    addUniteClose();

                });
        }

        const total = computed(() => {

            const total = state.selectItems.reduce((total: any, item: any) => total + item.total, 0);
            return total ? total : 0;

        })


        const totalMarkUP = computed(() => {
            return state.selectItems.reduce((total: any, item: any) => {
                if (item.markup_type == 1) {
                    const tot = total + (parseFloat(item.cost) * parseFloat(item.markup) * parseFloat(item.quantity)) / 100;
                    // return tot ? common.usdPriceFormate(tot) : common.usdPriceFormate(0);
                    return tot ? tot : 0;

                } else {
                    const tot = (total + parseFloat(item.markup));
                    return tot ? tot : 0;
                    // return tot ? common.usdPriceFormate(tot) : common.usdPriceFormate(0);
                }
            }, 0);
        })

        function clearAllJobItem() {
            state.data.lineItems.forEach((item: any) => {
                item.isChecked = false;
                item.subitems.forEach((subItem: any) => {
                    subItem.isChecked = false;
                });
            });
            state.selectItems = [{}];
        }


        function creatJobEstimation() {
            state.ui.isPostLoading.createJobEstimation = false;
            state.ui.errors.hasError = false;
            validationCreateJobEstimation();
            if (state.ui.isPostLoading.createJobEstimation || state.ui.errors.hasError) return false;
            const payLoad = {
                // eslint-disable-next-line @typescript-eslint/camelcase
                project_id: state.projectId,
                items: state.selectItems,
                title: state.data.title,

            };
            state.ui.isPostLoading.createJobEstimation = true;
            ethitransService
                .createJobEstimation(payLoad)
                .then((res: any) => {
                    console.log(res);
                    state.estimationUUId = res.data.data.uuid;
                    state.data.successFulMessage = "Create JobEstimation Successfully.";
                    state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
                })
                .catch((error: any) => {
                    common
                        .sdCatchErr(error, state.ui.errors.errorList.createJobEstimation)
                        .then((res: any) => {
                            state.ui.errors.hasError = true;
                            for (const [key, value] of Object.entries(res)) {
                                if (Array.isArray(value)) {
                                    state.ui.errors.errorList.createJobEstimation.push(value[0]);
                                }
                            }
                        });
                })
                .finally(() => {
                    state.ui.isPostLoading.createJobEstimation = false;

                });
        }

        function addNewSubItem(index: any) {
            state.ui.isLoading.addNewSubItem = !state.ui.isLoading.addNewSubItem
            $(`.job-item-dropdown${index}`).dropdown('toggle');
            $(`.job-item-dropdown${index}`).dropdown('hide');
            // $('.job-item-dropdown').dropdown('toggle');
            // $('.job-item-dropdown').dropdown('hide')
        }

        function saveItem() {
            state.ui.isLoading.addNewSubItem = !state.ui.isLoading.addNewSubItem
            getItemList();
            getAllUnits();
        }

        function getJobestimationDetail() {
            state.ui.isLoading.getItemList = true
            ethitransService
                .getJobestimationDetail(route.query.id)
                .then((res: any) => {
                    state.selectItems = [];
                    state.data.title = res.data.data.title;
                    state.data.createDate = moment(res.data.data.created_date).format("Y-MM-DD");
                    res.data.data.items.forEach((subItem: any) => {
                        subItem.isChecked = true;
                        subItem.mainName = subItem.main_item_name;
                        subItem.subTotal = parseFloat(subItem.cost) * parseFloat(subItem.quantity);
                        subItem.selectUnit = state.data.unitLists.filter((unit: any) => unit.id === subItem.unit_id)[0];
                        if (subItem.markup_type == 1) {
                            const parCal = ((parseFloat(subItem.cost) * parseFloat(subItem.quantity)) * parseFloat(subItem.markup)) / 100;
                            subItem.total = parCal + (parseFloat(subItem.cost) * parseFloat(subItem.quantity));
                        } else {
                            subItem.total = (parseFloat(subItem.cost) * parseFloat(subItem.quantity)) + parseFloat(subItem.markup);
                        }
                    });

                    state.selectItems = res.data.data.items;
                })
                .catch((error: any) => {
                    console.log(error);
                })
                .finally(() => {
                    state.ui.isLoading.getItemList = false;
                });
        }

        function updateJobEstimation() {
            state.ui.isPostLoading.createJobEstimation = false;
            state.ui.errors.hasError = false;
            validationCreateJobEstimation();
            if (state.ui.isPostLoading.createJobEstimation || state.ui.errors.hasError) return false;
            const payLoad = {
                // eslint-disable-next-line @typescript-eslint/camelcase
                project_id: state.projectId,
                // eslint-disable-next-line @typescript-eslint/camelcase
                estimation_id: route.query.id,
                items: state.selectItems,
                title: state.data.title,

            };
            state.ui.isPostLoading.createJobEstimation = true;
            ethitransService
                .updateJobEstimation(payLoad)
                .then((res: any) => {
                    state.estimationUUId = res.data.data.uuid;
                    state.data.successFulMessage = "Update JobEstimation Successfully.";
                    state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
                })
                .catch((error: any) => {
                    common
                        .sdCatchErr(error, state.ui.errors.errorList.createJobEstimation)
                        .then((res: any) => {
                            state.ui.errors.hasError = true;
                            for (const [key, value] of Object.entries(res)) {
                                if (Array.isArray(value)) {
                                    state.ui.errors.errorList.createJobEstimation.push(value[0]);
                                }
                            }
                        });
                })
                .finally(() => {
                    state.ui.isPostLoading.createJobEstimation = false;

                });
        }

        function saveSend() {
            if (state.ui.isLoading.isEdit) {
                state.ui.isLoading.isSaveSend = true;
                updateJobEstimation();
            } else {
                state.ui.isLoading.isSaveSend = true;
                creatJobEstimation();
            }

        }

        onBeforeRouteLeave((to, from) => {
            addUniteClose()
            deleteItemClose()
            $("#SuccessfulModalCustom").modal("hide");
        })
        function updateItem(index: any, newValue: any) {

            state.selectItems[index].cost = newValue.replace(/[$,]/g, '')
        }

        function updateItemMarkup(index: any, newValue: any) {
            state.selectItems[index].markup = newValue.replace(/[$,]/g, '');
        }

        function updateItemAllowance(index: any, newValue: any) {
            state.selectItems[index].allowance = newValue.replace(/[$,]/g, '');
        }

        function fetchCustomerDetails(email: string) {
            ethitransService.detailsByemail(email)
                .then(response => {
                    const selectedCustomers: any = response.data;
                    if (selectedCustomers && selectedCustomers) {
                        const data = selectedCustomers.data;
                        if (data.email) {
                            const customerData = {
                                email: data.email,
                                code: data.email,
                                // eslint-disable-next-line @typescript-eslint/camelcase
                                first_name: data.first_name || '',
                                // eslint-disable-next-line @typescript-eslint/camelcase
                                last_name: data.last_name || '',
                                phone: data.contact_number || '',
                                // eslint-disable-next-line @typescript-eslint/camelcase
                                address_line1: data.address_line1 || '',
                                mailNotification: true,
                                smsNotification: false,
                            };

                            const existingCustomerIndex = state.data.selectedCustomers.findIndex((customer: any) => customer.email === data.email);
                            if (existingCustomerIndex !== -1) {
                                // Replace the entire object
                                state.data.selectedCustomers[existingCustomerIndex] = { ...customerData };
                            } else {
                                state.data.selectedCustomers.push(customerData);
                            }
                        }
                    }
                })
                .catch(error => {
                    console.error('Error fetching customer details:', error);
                });
        }
        function addCustomerEmail(data: any) {
            isInvalid.value = false;
            const email = data;
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (email == localStorage.getItem("current_user_email")) {
                isInvalid.value = true;
                return false;
            }
            if (!emailRegex.test(email)) {
                isInvalid.value = true;
                return false;
            }
            const existingCustomerIndex = state.data.selectedCustomers.findIndex((customer: any) => customer.email === email);
            if (existingCustomerIndex === -1) {
                const addData = {
                    email: email,
                    code: email,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    first_name: '',
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    last_name: '',
                    phone: '',
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    address_line1: '',
                    mailNotification: true,
                    smsNotification: false,
                };

                state.data.allCustomer.push(addData);
                state.data.selectedCustomers.push(addData);

                // Fetch additional details for the new customer
                fetchCustomerDetails(email);
            } else {
                // If email already exists, update the existing customer details
                fetchCustomerDetails(email);
            }

        }

        // function changeValue() {
        //     isInvalid.value = false;
        // }
        function changeValue(selectedCustomer: any) {
            isInvalid.value = false;
            if (selectedCustomer && selectedCustomer.email) {
                fetchCustomerDetails(selectedCustomer.email);
            }
        }


        const formatCurrency = (value: any) => {
            if (value && typeof value === 'string') {
                const numericValue = parseFloat(value.replace(/[^\d.]/g, '')) || 0;
                const formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                });

                return formatter.format(numericValue);
            } else {
                const formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                });

                return formatter.format(value);
            }
            // Format as currency using Intl.NumberFormat



        };
        const formatPrice = (index: any) => {
            // Update the price format when the input changes
            state.selectItems[index].cost = formatCurrency(state.selectItems[index].cost);
        };


        function convertValues(obj: any) {
            const convertedObject = {} as any;
            for (const key in obj) {
                if (Object.prototype.hasOwnProperty.call(obj, key)) {
                    convertedObject[key] = obj[key] === 1 ? true : false;
                }
            }
            return convertedObject;

        }

        // Convert values in myObject


        function getJobEstimationDetails(uuid: any) {
            state.data.details = {};
            state.ui.isLoading.geJobEstimationList = true
            ethitransService
                .geJobEstimationDetailsPreview(uuid)
                .then((res: any) => {
                    state.fields = convertValues(res.data.data.fields)
                    state.data.note = res.data.data.note
                    state.data.sendarSignature = res.data.data.signature
                    state.approveByAll = res.data.data.approve_by_all == 1 ? true : false;
                    res.data.data.total = 0;
                    res.data.data.customers.forEach((customer: any) => {
                        const data = {
                            email: customer.email,
                            code: customer.email,
                            phone: customer.contact_number,
                            // eslint-disable-next-line @typescript-eslint/camelcase
                            first_name: customer.first_name,
                            // eslint-disable-next-line @typescript-eslint/camelcase
                            last_name: customer.last_name,
                            mailNotification: customer.email_notification,
                            smsNotification: customer.sms_notification,
                            // eslint-disable-next-line @typescript-eslint/camelcase
                            address_line1: customer.address,
                        };
                        state.data.selectedCustomers.push(data);
                        console.log('The selected costumer data is ', res.data.data.customers
                        )
                    })
                    res.data.data.singed_customers.forEach((customer: any) => {
                        // eslint-disable-next-line @typescript-eslint/camelcase  
                        customer.signaute_date = common.localDateOnly(customer.signaute_date);
                    })
                    // eslint-disable-next-line @typescript-eslint/camelcase  
                    res.data.data.signature_date = common.localDateOnly(res.data.data.signature_date ? res.data.data.signature_date : '');
                    res.data.data.phases.forEach((item: any) => {
                        item.sub_items.forEach((subItem: any) => {
                            subItem.mainName = item.name;
                            subItem.selectUnit = state.data.unitLists.filter((unit: any) => unit.id === subItem.unit_id)[0];
                            if (subItem.markup_type == 1) {
                                const parCal = ((parseFloat(subItem.cost) * parseFloat(subItem.quantity)) * parseFloat(subItem.markup)) / 100;
                                subItem.total = parCal + (parseFloat(subItem.cost) * parseFloat(subItem.quantity));
                                res.data.data.total += subItem.total;
                                // subItem.total = common.usdPriceFormate(subItem.total);
                                // subItem.markup = common.takeLimitDigits(subItem.markup ? subItem.markup : 0);
                            } else {
                                subItem.total = (parseFloat(subItem.cost) * parseFloat(subItem.quantity)) + parseFloat(subItem.markup);
                                res.data.data.total += subItem.total;
                                // subItem.total = common.usdPriceFormate(subItem.total);
                                // subItem.markup = common.usdPriceFormate(subItem.markup ? subItem.markup : 0);
                            }
                            subItem.subTotal = (parseFloat(subItem.cost) * parseFloat(subItem.quantity));
                            // subItem.subTotal = common.usdPriceFormate(subItem.subTotal);
                            // subItem.cost = common.usdPriceFormate(subItem.cost);
                            // subItem.allowance = common.usdPriceFormate(subItem.allowance ? subItem.allowance : 0);
                            state.selectItems.push(subItem);
                        });
                    });
                    state.selectedRows = state.selectItems.map((item: any) => item.main_item_id);
                    // res.data.data.total = common.usdPriceFormate(res.data.data.total);
                    state.data.details = res.data.data;
                })
                .catch((error: any) => {
                    console.log(error);
                })
                .finally(() => {
                    state.ui.isLoading.geJobEstimationList = false;
                });
        }
        //Watch for changes in the products array and reformat prices
        // watch(state.selectItems, (newProducts: any) => {
        //     newProducts.forEach((product: any, index: any) => {
        //         product.cost = formatCurrency(product.cost);
        //     });
        // });

        function showCreateCompanyModel() {
            $("#addCompanyInfo").modal("show");
        }

        function hideCreateCompanyModel() {
            state.ui.errors.errorList.createCompanyIfno = [];
            $("#addCompanyInfo").modal("hide");
            state.ui.isLoading.isCompanyEdit = false;
        }
        function editCompany() {
            state.ui.isLoading.isCompanyEdit = !state.ui.isLoading.isCompanyEdit;
            $("#addCompanyInfo").modal("show");
        }
        // function showDelete(data: any) {
        //     state.data.allCustomer.length = 0;
        //     return false;
        // }
        
        function showDelete(customer: any) {
            if (!state.data.deletedCustomerEmails) {
             state.data.deletedCustomerEmails = [];
             }
            const indexInAllCustomer = state.data.allCustomer.findIndex((item: any) => item.email === customer.email);
            const indexInSelectedCustomers = state.data.selectedCustomers.findIndex((item: any) => item.email === customer.email);

            // Remove from allCustomer array if exists
            if (indexInAllCustomer !== -1) {
                state.data.allCustomer.splice(indexInAllCustomer, 1);
            }

            // Remove from selectedCustomers array if exists
            if (indexInSelectedCustomers !== -1) {
                state.data.selectedCustomers.splice(indexInSelectedCustomers, 1);
            }
           
        state.data.deletedCustomerEmails.push(customer.email);
   
        }

        function hideDelete() {
            $("#deleteModal").modal("hide");
        }
        function removeCustomer() {
            state.ui.isLoading.isRemoveCustomer = true;
            if (state.data.removeUser != null) {
                const customers = state.data.allCustomer.filter(function (user: any) {
                    return user.email == state.data.removeUser.email;
                })
            }
            state.data.allCustomer.length = 0;
            state.ui.isLoading.isRemoveCustomer = false;
            hideDelete();

        }
        function isEmailValid(item: any) {
            const email = item.email;
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (email == localStorage.getItem("current_user_email")) {
                item.emailError = "Owner email  not valid here.";
                return false;
            }
            if (!emailRegex.test(email)) {
                item.emailError = "Please enter valid email.";
                return false;
            }
            item.emailError = "";
            return true;
        }

        function isPhoneValid(item: any) {
            const phone = item.phone;
            const phoneRegex = /^\(?(\d{3})\)?[-.\s]?(\d{3})[-.\s]?(\d{4})$/;
            if (!phoneRegex.test(phone)) {
                item.phoneError = "Please enter valid phone.";
                return false;
            }
            item.phoneError = "";
            return true;
        }
        
        function editSelectCustomer(index: number) {
            state.data.selectedCustomers.forEach((customer: any) => {
           customer.isEdit = false;
           });
            state.data.selectedCustomers[index].isEdit = true;
        }

        function updateSelectCustomer(data: any) {
            const item = state.data.selectedCustomers[data];
            // eslint-disable-next-line @typescript-eslint/camelcase
            item.address_line1 = customer.address_line1 ? customer.address_line1 : item.address_line1;
            item.emailError = "";
            item.phoneError = "";
            if (item.phone) {
                if (isEmailValid(item)) {
                    item.emailError = "";
                    item.phoneError = "";
                }
                if (isPhoneValid(item)) {
                    item.emailError = "";
                    item.phoneError = "";
                    item.isEdit = false;
                }
            } else {
                if (isEmailValid(item)) {
                    item.emailError = "";
                    item.phoneError = "";
                    item.isEdit = false;
                }
            }
            if (item.smsNotification) {
                if (item.phone == undefined) {
                    item.phoneError = "Enter phone number.";
                    item.isEdit = true;
                } else if (item.phone.length != 10) {
                    item.phoneError = "Phone number must be of 10 digit.";
                    item.isEdit = true;
                }

            }

        }
        function removeSelectCustomer(data: any) {
            state.data.selectedCustomers = state.data.selectedCustomers.filter(
                (e: any) => e != data
            );
            hideDelete();
        }

        function validateCreateCompany() {
            // console.log("*** TODO VALIDATION  ***");
            state.ui.errors.hasError = false;
            state.ui.errors.errorList.createCompanyIfno = [];
            if (
                !state.data.companyInfo.company_name &&
                !state.ui.isLoading.isCompanyEdit
            ) {
                state.ui.errors.errorList.createCompanyIfno.push(
                    "Company name is required."
                );
            }
            if (
                !state.data.companyInfo.company_email &&
                !state.ui.isLoading.isCompanyEdit
            ) {
                state.ui.errors.errorList.createCompanyIfno.push(
                    "Company Email is required."
                );
            }
            if (!state.data.companyInfo.logo) {
                state.ui.errors.errorList.createCompanyIfno.push(
                    "Company logo is required."
                );
            }
            if (!state.data.companyInfo.acknowledgement) {
                state.ui.errors.errorList.createCompanyIfno.push(
                    "Company acknowledgement is required."
                );
            }
            // eslint-disable-next-line @typescript-eslint/camelcase
            if (!state.data.companyInfo.legal_statement) {
                state.ui.errors.errorList.createCompanyIfno.push(
                    "Company legal statement is required."
                );
            }
            if (
                !state.ui.errors.errorList.createCompanyIfno &&
                !state.ui.errors.errorList.createCompanyIfno.length
            ) {
                state.ui.errors.hasError = false;
            } else if (state.ui.errors.errorList.createCompanyIfno.length != 0) {
                state.ui.errors.hasError = true;
            }
        }
        //no profile set
        function createCompanyIfno() {
            state.ui.errors.errorList.createCompanyIfno = [];
            state.ui.errors.hasError = false;

            validateCreateCompany();

            if (state.ui.isPostLoading.createCompanyIfno) return false;
            if (
                state.ui.errors.hasError ||
                state.ui.errors.errorList.createCompanyIfno.length != 0
            )
                return false;

            if (state.ui.isLoading.isCompanyEdit) {
                hideCreateCompanyModel();
            }
            const payLoad: any = {
                // eslint-disable-next-line @typescript-eslint/camelcase
                company_name: state.data.companyInfo.company_name,
                // eslint-disable-next-line @typescript-eslint/camelcase
                logo: state.data.companyInfo.logo,
                // eslint-disable-next-line @typescript-eslint/camelcase
                company_phone_number: state.data.companyInfo.company_phone_number,
                // eslint-disable-next-line @typescript-eslint/camelcase
                company_email: state.data.companyInfo.company_email,
                // eslint-disable-next-line @typescript-eslint/camelcase
                acknowledgement: state.data.companyInfo.acknowledgement,
                // eslint-disable-next-line @typescript-eslint/camelcase
                legal_statement: state.data.companyInfo.legal_statement,
            };
            state.ui.isPostLoading.createCompanyIfno = true;
            ethitransService
                .updateCompanyInfo(payLoad)
                .then((res: any) => {
                    hideCreateCompanyModel();
                })
                .catch((error: any) => {
                    console.log(error);
                    common
                        .sdCatchErr(error, state.ui.errors.errorList.createCompanyIfno)
                        .then((res: any) => {
                            state.ui.errors.hasError = true;
                            for (const [key, value] of Object.entries(res)) {
                                if (Array.isArray(value)) {
                                    state.ui.errors.errorList.createCompanyIfno.push(value[0]);
                                }
                            }
                        });
                })
                .finally(() => {
                    state.ui.isPostLoading.createCompanyIfno = false;
                });
        }

        function validateSendInvitation() {
            state.ui.errors.errorList.sendJobEstimation = [];
            state.ui.errors.hasError = false;

            if (state.data.selectedCustomers.length == 0) {
                state.ui.errors.errorList.sendJobEstimation.push("Enter one custumer");
            }

            if (!state.data.uploadSignature && !state.data.signatureLink && !state.data.sendarSignature) {
                state.ui.errors.errorList.sendJobEstimation.push("Draw/Upload/ Use your profile Signature");

                state.ui.isPostLoading.checkSignature = true;
            }

            if (
                !state.ui.errors.errorList.sendJobEstimation &&
                !state.ui.errors.errorList.sendJobEstimation.length
            ) {
                state.ui.errors.hasError = false;
            } else if (state.ui.errors.errorList.sendJobEstimation.length != 0) {
                state.ui.errors.hasError = true;
            }
        }


        function sendJobEstimation() {
            state.ui.isPostLoading.sendJobEstimation = false;
            state.ui.errors.hasError = false;
            // if (!signaturePad.isEmpty() && signaturePad.toData()) {
            //     state.data.uploadSignature = signaturePad.toDataURL();
            // } else if (cropper?.getDataURL()) {
            //     state.data.uploadSignature = cropper?.getDataURL();
            // } else {
            //     state.data.uploadSignature = "";
            //     // eslint-disable-next-line @typescript-eslint/camelcase
            //     state.data.signature_link = state.data.sendarSignature;
            // }
            if (!state.data.signatureLink && state.data.sendarSignature) {
                state.data.signatureLink = state.data.sendarSignature;
            }
            validateSendInvitation();
            if (state.ui.isPostLoading.sendJobEstimation || state.ui.errors.hasError) return false;
            const selectedCustomer = [] as any;
            const newItem = [] as any;
            const oldItem = [] as any;
            state.selectItems.forEach((item: any) => {
                if (item.isNew || item.isCopy) {
                    newItem.push(item);
                } else {
                    oldItem.push(item);
                }
            });
            // state.data.selectedCustomers.forEach((customer: any) => {
            //     selectedCustomer.push(customer.email);
            // });
            state.data.selectedCustomers.forEach((customer: any) => {
                selectedCustomer.push(customer);
            });
            const payLoad = {
                // eslint-disable-next-line @typescript-eslint/camelcase
                estimation_id: route.query.id,
                fields: state.fields,
                // eslint-disable-next-line @typescript-eslint/camelcase
                approve_by_all: state.approveByAll,
                customers: selectedCustomer,
                signature: state.data.uploadSignature,
                items: oldItem,
                // eslint-disable-next-line @typescript-eslint/camelcase
                new_item: newItem,
                // eslint-disable-next-line @typescript-eslint/camelcase
                signature_link: state.data.signatureLink,
                note: state.data.note,
                // eslint-disable-next-line @typescript-eslint/camelcase
                deleted_customer_emails: state.data.deletedCustomerEmails,
                acknowledgement: state.data.details.acknowledgement,
                // eslint-disable-next-line @typescript-eslint/camelcase
                legal_statement: state.data.details.legal_statement,
                logo: state.data.details.logo

            };

            state.ui.isPostLoading.sendJobEstimation = true;
            ethitransService
                .sendJobEstimation(payLoad)
                .then((res: any) => {
                    console.log(res);
                    state.data.successFulMessage = "Send JobEstimation Successfully.";
                    state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
                })
                .catch((error: any) => {
                    common
                        .sdCatchErr(error, state.ui.errors.errorList.sendJobEstimation)
                        .then((res: any) => {
                            state.ui.errors.hasError = true;
                            for (const [key, value] of Object.entries(res)) {
                                if (Array.isArray(value)) {
                                    state.ui.errors.errorList.sendJobEstimation.push(value[0]);
                                }
                            }
                        });
                })
                .finally(() => {
                    state.ui.isPostLoading.sendJobEstimation = false;

                });
        }

        function userStatus() {

            const payLoad = {
                email: localStorage.getItem("current_user_email"),
            };
            ethitransService
                .userStatus(payLoad)
                .then((res: any) => {
                    state.data.companyInfo.logo = res.data.data.company.logo;
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    state.data.companyInfo.company_name = res.data.data.company.company_name;
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    state.data.companyInfo.company_phone_number = res.data.data.company.company_phone_number;
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    state.data.companyInfo.company_email = res.data.data.company.company_email;
                    state.data.companyInfo.acknowledgement = res.data.data.company.acknowledgement;
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    state.data.companyInfo.legal_statement = res.data.data.company.legal_statement;
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    state.data.companyInfo.company_address = res.data.data.company.company_address;
                    // console.log("User Detail Check:>>> ", res.data.data);
                })
                .catch((error: any) => {
                    //   common
                    //     .sdCatchErr(error, state.ui.error.errorsList)
                    //     .then((res: any) => {
                    //       state.ui.error.hasError = true;
                    //       // console.log("CHECK for Error >>>", res)
                    //       for (const [key, value] of Object.entries(res)) {
                    //         if (Array.isArray(value)) {
                    //           state.ui.error.errorsList.push(value[0]);
                    //         }
                    //       }
                    //       // if (res) {
                    //       //   if (res.error) {
                    //       //     console.log("CHECK for Error >>>", res.error[0])
                    //       //     // state.errorList.push(res.email[0]);
                    //       //     // state.errorList = [...state.errorList]
                    //       //   }
                    //       // }
                    //     });
                })
                .finally(() => {
                    //   state.ui.isLoading.userStatus = true;
                });
        }

        const applyFilter = () => {
            if (state.data.searchName === '') {
                // If filter is empty, reset to original data
                tabulator.value.setData(state.tableData);
            } else {
                const currentData = tabulator.value.getData();
                const filteredData = currentData.filter((row: any) =>
                    Object.values(row).some((value) =>
                        String(value).toLowerCase().includes(state.data.searchName.toLowerCase())
                    )
                );
                tabulator.value.setData(filteredData);
            }
        };

        function clearSignature() {
            state.data.uploadSignature = "";
            state.data.cropSignature = "";
            state.data.sendarSignature = "";

        }
        function showSignaturePad() {
            state.data.showSignaturePad = !state.data.showSignaturePad;
        }

        function hideSignaturePad() {
            state.data.showSignaturePad = !state.data.showSignaturePad;
            $("#signaturePadModal").modal("hide");
        }

        function hideSignatureUpload() {
            state.data.showSignatureUpload = false;
            $("#signatureUploadModal").modal("hide");
        }
        function uploadSignature(data: any) {
            state.data.uploadSignature = data;
            state.data.signatureLink = "";
            state.data.cropSignature = data;
            state.data.useProfileSign = false;
        }

        function uploadSignatureFile(event: any) {
            state.data.showSignatureUpload = true;
            state.data.uploadSignatureFile = event.target.files || event.dataTransfer.files;
        }

        function userSignature(from: any) {
            ethitransService
                .userSignature()
                .then((res: any) => {
                    if (from == 'onMount' && res.data.data.signature) {
                        state.data.haveProfileSign = true;
                    }
                    if (from == 'use' && res.data.data.signature) {
                        state.data.useProfileSign = true;
                        state.data.uploadSignature = "";
                        state.data.cropSignature = res.data.data.signature;
                        state.data.signatureLink = res.data.data.signature;
                    }
                })
                .catch((error: any) => {
                    //   common
                })
                .finally(() => {
                    //   state.ui.isLoading.userStatus = true;
                });
        }
        onMounted(() => {
            getJobEstimationDetails(route.query.id);
            userStatus();
            getItemList();
            userSignature('onMount');
        });
        return {
            state,
            getItemList,
            checkSubItem,
            checkItem,
            closeSelected,
            removeSelectedSubItem,
            addMoreSubItems,
            checkInputNumber,
            calculateTotal,
            addUnitShow,
            addUniteClose,
            changeMarkupType,
            changeUnit,
            addNewUnit,
            editUnit,
            deleteUnitList,
            deleteUnit,
            createUnit,
            updateUnit,
            total,
            totalMarkUP,
            clearAllJobItem,
            creatJobEstimation,
            gotoJobEstimation,
            closeSuccessCustomModal,
            addNewSubItem,
            saveItem,
            validationCreateJobEstimation,
            getJobestimationDetail,
            updateJobEstimation,
            saveSend,
            gotoJobEstimationSend,
            deleteItemClose,
            deleteItemShow,
            updateItem,
            updateItemMarkup,
            updateItemAllowance,
            usdPriceFormate,
            cloneItem,
            formatPrice,
            formatCurrency,
            getJobEstimationDetails,
            convertValues,
            addCustomerEmail,
            changeValue,
            isInvalid,
            readLogo,
            uploadLogo,
            readImage,
            dropFiles,
            readSignature,
            onDocumentChange,
            showUploadImageModel,
            hideShowUploadImageModel,
            enableSignaturPad,
            hideCreateCompanyModel,
            showCreateCompanyModel,
            createCompanyIfno,
            validateCreateCompany,
            editCompany,
            userStatus,
            tabulator,
            table,
            resizeTable,
            applyFilter,
            filterValue,
            onToggleChooseItem,
            iconFormatter,
            addSubItem,
            validateSendInvitation,
            sendJobEstimation,
            showSignaturePad,
            hideSignaturePad,
            uploadSignature,
            uploadSignatureFile,
            hideSignatureUpload,
            userSignature,
            clearSignature,
            showDelete,
            hideDelete,
            isEmailValid,
            isPhoneValid,
            removeSelectCustomer,
            editSelectCustomer,
            updateSelectCustomer,
            fetchCustomerDetails,
            address,
            latitude,
            longitude,
            showMap,
            updateLatitude,
            updateLongitude,
            updateAddress,
            customer,
        };
    },
});
