
import { defineComponent } from "vue";
import TheNavBar from "@/includes/TheNavBar.vue";
import TheSideNavBar from "@/includes/TheSideNavBar.vue";
import TheFooter from "@/includes/TheFooter.vue";

import { reactive } from "vue";

export default defineComponent({
  name: "TheLayOut",
  components: {
    TheNavBar,
    TheSideNavBar,
    TheFooter,
  },
  setup() {
    const state = reactive({
      projectStatusMsg: "const state = reactive()",
    });
    return {
      state,
    };
  },
});
