
import { defineComponent, onMounted, ref, reactive, computed } from "vue";
import { useRoute } from "vue-router";

import CustomEventCalender from "@/components/projectDetail/CustomEventCalender.vue";

import { authService } from "@/login/Services/AuthService";
import { common } from "@/common/services/Common";
import moment from "moment";

export default defineComponent({
  name: "PublicSmartContractCalender",
  components: {
    CustomEventCalender,
  },
  setup() {
    const {
      params: { smartContractId },
    } = useRoute();
    const contractCalender = ref({
      startDate: "" as any,
      endDate: "" as any,
    });
    const state = reactive({
      showCalender: false,
      data: {
        dates: {
          availability: [],
          smartcontract: {
            // eslint-disable-next-line @typescript-eslint/camelcase
            end_date: "",
            // eslint-disable-next-line @typescript-eslint/camelcase
            start_date: "",
            title: "",
          },
        } as any,
      },
      ui: {
        error: {
          hasError: false,
          errorList: {
            smartContractCalender: [] as any,
          },
        },
        isLoading: {
          smartContractCalender: false,
        },
      },
    });

    const filteredEvents = computed(() => {
      const dates: any = [];
      state.data.dates.availability.forEach((item: any) => {
        if (item.status != 0) {
          // eslint-disable-next-line @typescript-eslint/camelcase
          if (item.time_slots && item.time_slots.length) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            item.time_slots.forEach((slot: any) => {
              // if (props.disabled) {
              dates.push({
                start: `${common.formatDateAndTimeWithOutSeconds(
                  slot.start_time
                )}`,
                end: `${common.formatDateAndTimeWithOutSeconds(slot.end_time)}`,
                title: `${slot.title}`,
                deletable: false,
                resizable: false,
                draggable: false,
                titleEditable: false,
                // content: '<i class="icon material-icons">sentiment_satisfied_alt</i>',
                // class: 'health'
              });
              // } else {
              //   dates.push({
              //     start: `${common.formatDateAndTimeWithOutSeconds(
              //       slot.start_time
              //     )}`,
              //     end: `${common.formatDateAndTimeWithOutSeconds(
              //       slot.end_time
              //     )}`,
              //     title: `${selectedObject.phase.name}/${slot.title}`,
              //     // content: '<i class="icon material-icons">sentiment_satisfied_alt</i>',
              //     // class: 'health'
              //   });
              // }
            });
          }
        }
      });
      return dates;
    });
    function smartContractCalender() {
      state.ui.error.errorList.smartContractCalender = [];
      state.ui.error.hasError = false;
      state.showCalender = false;
      if (state.ui.isLoading.smartContractCalender) return false;
      state.ui.isLoading.smartContractCalender = true;
      authService
        .smartContractCalender(
          String(smartContractId),
          moment(contractCalender.value.startDate).format("Y-MM-DD"),
          moment(contractCalender.value.endDate).format("Y-MM-DD")
        )
        .then((res: any) => {
          const dates = [] as any;
          // state.data.dates = res.data.data;
          if (res.data.data.availability && res.data.data.availability.length) {
            res.data.data.availability.forEach((item: any) => {
              if (item.status === 0) {
                dates.push({
                  dot: true,
                  highlight: {
                    color: "purple",
                    fillMode: "outline",
                  },
                  dates: new Date(common.formatDate(item.day)),
                });
              } else {
                // if(item.time_slots && item.time_slots.length){
                item.time_slots.forEach((slot: any) => {
                  dates.push({
                    start: `${common.formatDateAndTimeWithOutSeconds(
                      slot.start_time
                    )}`,
                    end: `${common.formatDateAndTimeWithOutSeconds(
                      slot.end_time
                    )}`,
                    title: slot.title,
                    // content: '<i class="icon material-icons">sentiment_satisfied_alt</i>',
                    // class: 'health'
                  });
                });
                // }
              }
            });
          }
          console.log("PRA CHECK THE DATED>>> ", dates);

          // if(selecteduserDetails.slots && selecteduserDetails.slots.lenth){
          //   selecteduserDetails.slots.forEach((slot: any)=> {
          //     console.log("CHECK Slots >>> ", slot)
          //   })
          // }
          console.log("Formated:>>> ", dates);
                    // eslint-disable-next-line @typescript-eslint/camelcase
          res.data.data.smartcontract.start_date = common.localDateOnly(res.data.data.smartcontract.start_date
          );
          // eslint-disable-next-line @typescript-eslint/camelcase
          res.data.data.smartcontract.end_date = common.localDateOnly(res.data.data.smartcontract.end_date
          );
          res.data.data.formattedDate = dates;
          // console.log("getToDoData Check:>>> ", res.data.data);
          state.data.dates = res.data.data;
          state.ui.isLoading.smartContractCalender = false;
          state.showCalender = true;
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.smartContractCalender)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.smartContractCalender.push(value[0]);
                }
              }
            });
        });
    }
    onMounted(() => {
      console.log("PublicSmartContractCalender Loaded", smartContractId);
      const currentDate = new Date();

      // Get the first day of the current month
      const firstDay = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );

      // Get the last day of the current month
      const lastDay = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      );

      contractCalender.value.startDate = firstDay;
      contractCalender.value.endDate = lastDay;

      smartContractCalender();
    });

    // return { contractCalender, smartContractCalender, state };
    return { contractCalender, state, filteredEvents, smartContractCalender };
  },
});
