
import { defineComponent, onMounted, reactive, computed, watch} from "vue";
import { common } from "@/common/services/Common";
import { ethitransService } from "@/services/EthitransService";
import { useRoute, useRouter } from "vue-router";
// import PaymentHistory from "@/common/PaymentHistory.vue";
import ErrorList from "@/components/commonDesign/ErrorList.vue";
import SuccessfulModalCustom from "@/components/modal/SuccessfulModalCustom.vue";
import BreadcrumbRequestedInvoice from "@/components/invoice/BreadcrumbRequestedInvoice.vue";
import EmptyData from "@/components/commonDesign/EmptyData.vue";


export default defineComponent({
  name: "Draft Invoice",
  props: {
    showAddPhase: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    ErrorList,
    EmptyData,
    SuccessfulModalCustom,
    BreadcrumbRequestedInvoice,
    // PaymentHistory,
  },
  setup() {
    const router = useRouter();
    const routerQuery = useRoute();
    const {
      params: { phaseId },
    } = useRoute();
    const state = reactive({
      data: {
        breadcrumb: [] as any,
        invoices: [] as any,
        invoiceReceiverList: [] as any,
        invoiceDetails: {} as any,
        invoiceBillTo: {
          id: 0,
          email: "",
          // eslint-disable-next-line @typescript-eslint/camelcase
          full_name: "",
        } as any,
        addItemInInvoice: false,
        title: 'invoices',
        heading: 'Get started by creating a new invoice.',
        isPayActive: false,
        stripeSuccess: false,
        isIndividual: false,
        showSuccesCustomModal: false,
        stripeAccount: false,
        successFulMessage: "",
        selectedInvoice: {},
        stripeUrl: "",
        invoice: {
          total: 0,
          subTotal: 0,
          tax: 0,
          discount: 0,
          status: 1,
          mode: 1,
        },
      },
      ui: {
        error: {
          hasError: false,
          errorList: {
            getInvoiceByPhaseOrTask: [] as any,
            invoiceReceiverList: [] as any,
          },
        },
        isLoading: {
          getInvoiceByPhaseOrTask: false,
          stripeAccountCheck: false,
          phaseInvoiceListById: false,
        },
        isPostLoading: {
          generateInvoice: false,
          stripeAccount: false,
          confirmPayment: false,
          saveAsDraft: false,
          showInvoiceReceiverModal: false,
        },
      },
    });
    // eslint-disable-next-line vue/return-in-computed-property
    const invoiceTotal = computed(() => {
      return state.data.invoice.total;
    });
    // eslint-disable-next-line vue/return-in-computed-property
    const invoiceCalculate = computed(() => {
      if (
        state.data.invoiceDetails.invoice_items &&
        state.data.invoiceDetails.invoice_items.length
      ) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        state.data.invoice.subTotal = 0;
        state.data.invoiceDetails.invoice_items.forEach((invoice: any) => {
          invoice.amount = invoice.cost * invoice.quantity;
          const number = invoice.amount;
          state.data.invoice.subTotal += number;
        });
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        state.data.invoice.total =
          state.data.invoice.subTotal +
          (state.data.invoice.subTotal * state.data.invoice.tax) / 100 -
          state.data.invoice.discount;

        return state.data.invoiceDetails.invoice_items;
      }
    });
    // const invoiceSubtotal = invoiceCalculate.value.reduce((accumulator: any, selected: any)=> {
    //   return accumulator + selected.amount;
    // }, 0)
    // eslint-disable-next-line vue/return-in-computed-property
    const isAuthEdit = computed(() => {
      // if(state.data.invoiceDetails.draft_uuid) return false;
      if(state.data.invoiceDetails.generated === 1) return false;
      
      if (state.data.invoiceDetails && state.data.invoiceDetails.user_role) {
        // ROLE  1 = owner,4 = subcontractor, 2 = payer, 3 = member, 
        if (
          state.data.invoiceDetails.user_id === state.data.invoiceDetails.billed_from
        ) {
          // console.log("WOWOW ROLE");
          return true;
        } else {
          return false;
        }
        // if (
        //   state.data.invoiceDetails.user_role === 3 || state.data.invoiceDetails.user_role === 2
        // ) {
        //   // console.log("WOWOW ROLE");
        //   return false;
        // } else if (
        //   state.data.invoiceDetails.user_role === 1 ||
        //   state.data.invoiceDetails.user_role === 4 
        // ) {
        //   // console.log("HELLO");
        //   if (state.data.isIndividual) {
        //     // console.log("INDI TR");
        //     return true;
        //   } else {
        //     // console.log("INDI FA");
        //     return false;
        //   }
        // }
      }
    });
    function isAddItemInInvoice() {
     state.data.addItemInInvoice = false;
    }
     function addItemInInvoice() {
      state.data.addItemInInvoice = true;
      state.data.invoiceDetails.invoice_items.push({
        amount: 0,
        cost: 0,
        quantity: 0,
        title: "",
      });
      setTimeout(isAddItemInInvoice, 2000);
    }
    // phaseInvoiceReceivedByPhaseId
    function getInvoiceByPhaseOrTask(selected: any) {
      const mode: number = selected.mode;
      let invoiceId = '';
      if(mode === 1){
        invoiceId = selected.uuid;
      } else {
        invoiceId = phaseId.toString();
      }
      // console.log("Choose Payment Method", invoiceId);
      state.ui.error.errorList.getInvoiceByPhaseOrTask = [];
      state.ui.error.hasError = false;

      if (state.ui.isLoading.getInvoiceByPhaseOrTask) return false;
      
      state.ui.isLoading.getInvoiceByPhaseOrTask = true;
      ethitransService
        // .invoiceByPhaseOrTask(phaseId.toString())
        .invoiceByPhaseOrTask(invoiceId.toString(), mode)
        .then((res: any) => {
          console.log(
            "getInvoiceByPhaseOrTask Response Check:>>> ",
            res.data.data
          );
          // eslint-disable-next-line @typescript-eslint/camelcase
          if (res.data.data.invoice && res.data.data.invoice.invoice_date) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            res.data.data.invoice.invoice_date = common.localDateAndTime(
              res.data.data.invoice.invoice_date
            );
          }
              // eslint-disable-next-line @typescript-eslint/camelcase
          if(res.data.data.new_items && res.data.data.new_items.length){
            console.log("New Items")
            if( res.data.data.invoice && res.data.data.invoice.invoice_items && res.data.data.invoice.invoice_items.length){
              console.log("invoice items")
              res.data.data.new_items.forEach((item: any)=> {
                item.newAdded = true;
                res.data.data.invoice.invoice_items.push(item);
              })

            }
            
          }
          res.data.data.breadcrumb.reverse();
            state.data.invoiceDetails = res.data.data.invoice ? res.data.data.invoice : {};
            state.data.breadcrumb = res.data.data.breadcrumb ? res.data.data.breadcrumb: [];
          if(state.data.invoiceDetails.invoice_items && !state.data.invoiceDetails.invoice_items.length){
            addItemInInvoice();
          }
          if (
            localStorage.getItem("current_user_id") &&
            state.data.invoiceDetails.billed_from
          ) {
            const userId: any = localStorage.getItem("current_user_id");
            console.log("USER ID >>> ", userId)
            if (
              parseInt(userId) !=
              parseInt(state.data.invoiceDetails.billed_from)
            ) {
              state.data.isPayActive = true;
            } else {
              state.data.isPayActive = false;
            }
          }
          if (routerQuery.query.account === "success") {
            state.data.stripeSuccess = true;

            state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
            state.data.successFulMessage = "Stripe Account Setup Completed.";
            // generateInvoice();
          }
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.getInvoiceByPhaseOrTask)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.getInvoiceByPhaseOrTask.push(
                    value[0]
                  );
                }
              }
            });
        })
        .finally(() => {
          state.ui.isLoading.getInvoiceByPhaseOrTask = false;
        });
    }
    function invoiceeReceiverList() {
      state.ui.error.errorList.invoiceReceiverList = [];
      state.ui.error.hasError = false;
      // generateInvoice()
      if (state.ui.isPostLoading.showInvoiceReceiverModal) return false;
      state.ui.isPostLoading.showInvoiceReceiverModal = true;
      const data = {
        phase: phaseId,
      };
      ethitransService
        .invoiceReceiverList(data)
        .then((res: any) => {
          console.log("invoiceReceiverList Check:>>> ", res.data.data);
          state.data.invoiceReceiverList = res.data.data.receivers;
          state.ui.isPostLoading.showInvoiceReceiverModal = false;
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.invoiceReceiverList)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.invoiceReceiverList.push(value[0]);
                }
              }
            });
        });
    }
    
    function phaseInvoiceListById() {
      state.ui.error.errorList.getInvoiceByPhaseOrTask = [];
      state.ui.error.hasError = false;

      if (state.ui.isLoading.phaseInvoiceListById) return false;
      const payLoad: any = {
        id: phaseId,
      };
      state.ui.isLoading.phaseInvoiceListById = true;
      ethitransService
        .todoInvoiceDraftById(payLoad)
        .then((res: any) => {
          invoiceeReceiverList();
          console.log(
            "phaseInvoiceReceivedByPhaseId Response Check:>>> ",
            res.data.data
          );
          
          res.data.data.reverse();
          state.data.invoices = res.data.data ? res.data.data : [];
          state.data.invoiceDetails = [];
          if (state.data.invoices && state.data.invoices.length) {
            state.data.invoices.forEach((invoice: any) => {
              invoice.mode = 1;
              // eslint-disable-next-line @typescript-eslint/camelcase
              invoice.created_at = common.localDateOnly(
                invoice.created_at
              );
            });
          }
          // eslint-disable-next-line @typescript-eslint/camelcase
          // state.data.invoices.unshift({invoice_no : 'All', mode: 2, invoice_date: 'All Invoices For Phase'})
          // state.data.selectedInvoice = state.data.invoices[state.data.invoices.length-1].uuid
          // used in another
          // console.log("CHECK THE INVOICE ID >>> ", routerQuery.query.invoiceId)
          if (state.data.invoices && state.data.invoices.length) {
            if (routerQuery.query.invoiceId) {
            console.log("CHECK INVOICE LISt>>>",state.data.invoices);
            const invoiceId: any = routerQuery.query.invoiceId;
            state.data.invoices.forEach((item: any)=> {
              if(invoiceId === item.uuid){

                console.log("CHECK INVOICE >>>",invoiceId);
                console.log("CHECK UUID >>>",item.uuid);
                state.data.selectedInvoice = item;
              }
            })
            // state.data.selectedInvoice = state.data.invoices.filter((e: any) => e.uuid === invoiceId);
            // console.log("QUERY >>>", state.data.selectedInvoice)
            getInvoiceByPhaseOrTask(state.data.selectedInvoice);
          } else {
            console.log("AUTOSET >>>")
             const invoiceId: any = state.data.invoices[0];
            state.data.selectedInvoice = invoiceId;
            // console.log("Length >>> ", 0);
            // console.log("hello >>> ", invoiceId);
            getInvoiceByPhaseOrTask(state.data.selectedInvoice);
            //   state.data.invoices[state.data.invoices.length - 1].uuid
            // );
          }
          }
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.getInvoiceByPhaseOrTask)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.getInvoiceByPhaseOrTask.push(
                    value[0]
                  );
                }
              }
            });
        })
        .finally(() => {
          state.ui.isLoading.phaseInvoiceListById = false;
        });
    }
    function downloadFile() {
      window.open(state.data.invoiceDetails.pdf_url, "_blank");
    }
    function closeSuccessCustomModal() {
      state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
      state.data.stripeSuccess = false;
      $("#SuccessfulModalCustom").modal("hide");
    }
    
   
    function removeSelectedItemInInvoice(item: any) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.data.invoiceDetails.invoice_items =
        state.data.invoiceDetails.invoice_items.filter((e: any) => e != item);
    }
    function invoiceSuccess() {
      state.data.successFulMessage = "Invoice Generated Successfully.";
      // state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
      if (routerQuery.query.account === "success") {
        router.push({
          name: "admin-billing-invoice",
          // params: { invoiceId: invoiceId },
          query: { account: "" },
        });
      }
    }
    function generateInvoice(status: number) {
      state.ui.error.errorList.getInvoiceByPhaseOrTask = [];
      state.ui.error.hasError = false;
      // let phaseId = "";
      // invoiceCalculate.value.forEach((item: any) => {
      //   delete item.created_at;
      //   delete item.deleted_at;
      //   delete item.id;
      //   delete item.updated_at;
      //   delete item.uuid;
      // });
      // if (state.data.invoiceDetails && state.data.invoiceDetails.phase_id) {
      //   phaseId = state.data.invoiceDetails.phase_id;
      // } else {
      //   phaseId = state.data.invoiceDetails.invoice_phase.phase.uuid;
      // }

      if (state.ui.isPostLoading.generateInvoice) return false;
      if (state.ui.isPostLoading.saveAsDraft) return false;
      if (!phaseId) return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        receiver_id: state.data.invoiceBillTo.id,
        // eslint-disable-next-line @typescript-eslint/camelcase
        phase_id: phaseId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        invoice_id: state.data.invoiceDetails.uuid,
        items: invoiceCalculate.value,
        // eslint-disable-next-line @typescript-eslint/camelcase
        sub_total: state.data.invoice.subTotal,
        tax: state.data.invoice.tax,
        discount: state.data.invoice.discount,
        total: state.data.invoice.total,
        status: status,
        // status: state.data.invoice.status,
      };
      console.log("ID CHECK  WITH PAYLOAD>>>", payLoad);
      if(state.data.invoice.status === 4){

        state.ui.isPostLoading.saveAsDraft = true;
      } else {

        state.ui.isPostLoading.generateInvoice = true;
      }
      ethitransService
        .phaseGenerateInvoice(payLoad)
        .then((res: any) => {
          console.log(
            "getInvoiceByPhaseOrTask Response Check:>>> ",
            res.data.data
          );
          // eslint-disable-next-line @typescript-eslint/camelcase
          if (res.data.data.invoice.invoice_date) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            res.data.data.invoice.invoice_date = common.localDateAndTime(
              res.data.data.invoice.invoice_date
            );
          }
              // eslint-disable-next-line @typescript-eslint/camelcase
          if(res.data.data.new_items && res.data.data.new_items.length){
            console.log("New Items")
            if( res.data.data.invoice && res.data.data.invoice.invoice_items && res.data.data.invoice.invoice_items.length){
              console.log("invoice items")
              res.data.data.new_items.forEach((item: any)=> {
                item.newAdded = true;
                res.data.data.invoice.invoice_items.push(item);
              })

            }
            
          }

            state.data.invoiceDetails = res.data.data.invoice;
          // if(state.data.invoiceDetails.invoice_items && !state.data.invoiceDetails.invoice_items.length){
          //   addItemInInvoice();
          // }
          // if (
          //   localStorage.getItem("current_user_id") &&
          //   state.data.invoiceDetails.billed_from
          // ) {
          //   const userId: any = localStorage.getItem("current_user_id");
          //   if (
          //     parseInt(userId) !=
          //     parseInt(state.data.invoiceDetails.billed_from)
          //   ) {
          //     state.data.isPayActive = true;
          //   } else {
          //     state.data.isPayActive = false;
          //   }
          // }
          closeSuccessCustomModal();
          console.log("generateInvoice Response Check:>>> ", res.data.data);
          setTimeout(invoiceSuccess, 1000);
          // phaseInvoiceListById();
        })
        // .then((res: any) => {
        //   closeSuccessCustomModal();
        //   console.log("generateInvoice Response Check:>>> ", res.data.data);
        //   setTimeout(invoiceSuccess, 1000);
        // })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.getInvoiceByPhaseOrTask)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.getInvoiceByPhaseOrTask.push(
                    value[0]
                  );
                }
              }
            });
        })
        .finally(() => {
          if(state.data.invoice.status === 4){

        state.ui.isPostLoading.saveAsDraft = false;
      } else {

        state.ui.isPostLoading.generateInvoice = false;
      }
        });
    }
    function redirectToStripeAccount() {
      state.ui.isPostLoading.stripeAccount = true;
      window.location.replace(`${state.data.stripeUrl}`);
      state.ui.isPostLoading.stripeAccount = false;
    }
    function stripeAccountCheck() {
      // alert("HELLO");
      state.ui.error.errorList.getInvoiceByPhaseOrTask = [];
      state.ui.error.hasError = false;
      if (state.ui.isLoading.stripeAccountCheck) return false;
      if (
        state.ui.error.hasError ||
        state.ui.error.errorList.getInvoiceByPhaseOrTask.length != 0
      )
        return false;
      state.ui.isLoading.stripeAccountCheck = true;
      ethitransService
        .stripeAccountCheck(state.data.invoiceDetails.uuid)
        .then((res: any) => {
          // alert("THEN");
          // emit("logData", res.data.data);
          if (res.data.data.status === 0) {
            state.data.stripeUrl = res.data.data.link;
            console.log("Project Todo Response Check:>>> ", res.data.data);
            state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
            state.data.successFulMessage = "Stripe Account Checked";
          } else if (res.data.data.status === 1) {
            generateInvoice(1);
          }
          // logData(res.data.data);
          // hideTaskLogModal();
        })
        .catch((error: any) => {
          // alert("CATCH WHY");
          console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.getInvoiceByPhaseOrTask)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.getInvoiceByPhaseOrTask.push(
                    value[0]
                  );
                }
              }
            });
        })
        .finally(() => {
          state.ui.isLoading.stripeAccountCheck = false;
        });
    }
    
    function saveAsDraft(){
      state.data.invoice.status = 4;
      generateInvoice(4);
    }
    watch(
      // () => watchTour,
      () => state.data.addItemInInvoice,
      (newvalue: any, oldvalue: any) => {
        if (newvalue === true) {
          console.log("INVOICE CHANGED >>>", newvalue)
          state.data.invoiceDetails.status = 1;
          // toggleTourGuide();
        }
      }
    );
    onMounted(() => {
      if (routerQuery.query.mode === "1") {
        state.data.isIndividual = true;
      } else {
        state.data.isIndividual = false;
      }
      phaseInvoiceListById();
      // getInvoiceByPhaseOrTask();
    });
    return {
      state,
      getInvoiceByPhaseOrTask,
      phaseInvoiceListById,
      isAddItemInInvoice,
      downloadFile,
      saveAsDraft,
      invoiceTotal,
      redirectToStripeAccount,
      stripeAccountCheck,
      closeSuccessCustomModal,
      isAuthEdit,
      invoiceCalculate,
      addItemInInvoice,
      removeSelectedItemInInvoice,
      generateInvoice,
      invoiceSuccess,
    };
  },
});
