
import { defineComponent, onMounted } from "vue";
import { useRouter } from "vue-router";
// import PaymentHistory from "@/common/PaymentHistory.vue";

export default defineComponent({
  name: "BreadcrumbRequestedInvoice",
  props: {
    breadcrumbList: {
      type: Array,
      required: true,
    },
  },
  components: {
    // PaymentHistory,
  },
  setup() {
    const router = useRouter();
    function redirectToSelected(redirectTo: any, uuid: string) {
      if(redirectTo === 0){
        router.push({
        name: "admin-project-details",
        params: { projectId: uuid },
      });
      }
      if(redirectTo === 1 || redirectTo === 2){
        router.push({
        name: "user-todo-details",
        params: { phaseId: uuid },
      });
      }
      // window.open(state.data.invoiceDetails.pdf_url, "_blank");
    }

    onMounted(() => {
      // if (routerQuery.query.invoiceId) {
      //   const invoiceId: any = routerQuery.query.invoiceId;
      //   state.data.selectedInvoice = invoiceId;
      // }
    });
    return {
      redirectToSelected,
    };
  },
});
