import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "activity-list"
}
const _hoisted_2 = {
  key: 0,
  class: "activity-img"
}
const _hoisted_3 = {
  key: 1,
  class: "activity-img"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.activity)
    ? (_openBlock(), _createBlock("div", _hoisted_1, [
        (_ctx.activity.activity_by.image)
          ? (_openBlock(), _createBlock("div", _hoisted_2, [
              _createVNode("img", {
                src: _ctx.activity.activity_by.image,
                alt: ""
              }, null, 8, ["src"])
            ]))
          : (_openBlock(), _createBlock("div", _hoisted_3, [
              _createVNode("img", {
                src: _ctx.state.data.imageBaseUrl ?
    `${_ctx.state.data.imageBaseUrl}img/no-user.jpg` : 'img/no-user.jpg'
                    ,
                alt: ""
              }, null, 8, ["src"])
            ])),
        _createVNode("p", null, _toDisplayString(_ctx.activity.description), 1)
      ]))
    : _createCommentVNode("", true)
}