
import { defineComponent, onMounted, reactive, computed } from "vue";

import TitleComponent from "@/components/formComponents/TitleComponent.vue";
import TextBoxComponent from "@/components/formComponents/TextBoxComponent.vue";
import RadioButtonComponent from "@/components/formComponents/RadioButtonComponent.vue";
import SelectOption from "@/components/formComponents/SelectOption.vue";
import CheckBox from "@/components/formComponents/CheckBox.vue";
import TextArea from "@/components/formComponents/TextArea.vue";

import { useRoute, useRouter } from "vue-router";

import { ethitransService } from "@/services/EthitransService.ts";

import VPagination from "vue3-pagination";
import "vue3-pagination/dist/vue3-pagination.css";

export default defineComponent({
  name: "ProjectForms",
  components: {
    TitleComponent,
    TextBoxComponent,
    RadioButtonComponent,
    SelectOption,
    CheckBox,
    TextArea,
    VPagination,
  },
  props: {
    msg: String,
  },
  setup() {
    const {
      params: { projectId },
    } = useRoute();
    const router = useRouter();

    const state = reactive({
      name: "" as any,
      email: "" as any,
      data: {
        paging: {
          getAccountFormList: {
            perPage: 10,
            totalDocs: 0,
            totalPages: 0,
            currentPage: 1,
          },
        },
        projectDetails: {} as any,
        FormData: {} as any,
        formsList: {} as any,
        sortList: [
          {
            name: "Newest First",
            id: -1,
          },
          {
            name: "Oldest First",
            id: 1,
          },
          {
            name: "Form Name (A>Z)",
            id: 1,
          },
          {
            name: "Form Name (Z>A)",
            id: -1,
          },
        ],
        filter: {
          type: "all",
          formName: "",
          channelId: 0,
          selected: { name: "Newest First", id: -1 } as any,
          sortBy: -1,
          title: 1,
        },
      },
      ui: {
        isLoading: {
          getProjectDetail: false,
        },
        postLoading: {
          getAccountFormRetrieveById: false,
          getAccountFormList: false,
        },
      },
    });

    const templateList = computed(() => {
      if (state.data.formsList.docs && state.data.formsList.docs.length) {
        return state.data.formsList.docs.filter((form: any) => {
          // if (form.title) {
          //   return form.title.toLowerCase().match(state.searchBy.toLowerCase());
          // }
          return form;
        });
      } else {
        return [];
      }
    });

    function getAccountFormRetrieveByIdForSend(formId: any) {
      // console.log("Get Account form Response:>>>", formId);
      state.ui.postLoading.getAccountFormRetrieveById = true;
      ethitransService
        .accountFormRetrieveById(formId)
        .then((res: any) => {
          console.log(
            "getAccountFormRetrieveById Response Check:>>> ",
            res.data.elements
          );
          state.data.FormData = res.data ? res.data : {};
          // state.selectedFormForEdit = res.data ? res.data : {};
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.postLoading.getAccountFormRetrieveById = false;
        });
    }

    function hideTemplateModal() {
      $("#exampleModal").modal("hide");
    }
    function selectForm(form: any) {
      $("#exampleModal").modal("show");
      // console.log("Selected Form:>>", form);
      getAccountFormRetrieveByIdForSend(form._id);
    }
    function getAccountFormList() {
      if (
        state.data.filter.selected.name === "Newest First" ||
        state.data.filter.selected.name === "Oldest First"
      ) {
        state.data.filter.sortBy = state.data.filter.selected.id;
      } else {
        state.data.filter.title = state.data.filter.selected.id;
      }
      console.log("SORT : >>>>", state.data.filter.sortBy);
      console.log("TITLE : >>>>", state.data.filter.title);
      if (
        state.data.filter.selected.name === "Newest First" ||
        state.data.filter.selected.name === "Oldest First"
      ) {
        const payLoad = {
          search: {
            // eslint-disable-next-line @typescript-eslint/camelcase
            project_id: projectId,
            // eslint-disable-next-line @typescript-eslint/camelcase
            channel_id: state.data.filter.channelId ? state.data.filter.channelId : '',
            // eslint-disable-next-line @typescript-eslint/camelcase
            form_name: state.data.filter.formName,
          },
          filter: {
            type: state.data.filter.type,
            email: state.email,
          },
          // eslint-disable-next-line @typescript-eslint/camelcase
          sort_by: {
            // eslint-disable-next-line @typescript-eslint/camelcase
            created_date: state.data.filter.sortBy,
          },
          paging: {
            // eslint-disable-next-line @typescript-eslint/camelcase
            per_page: state.data.paging.getAccountFormList.perPage,
            // eslint-disable-next-line @typescript-eslint/camelcase
            current_page: state.data.paging.getAccountFormList.currentPage,
          },
        };
        // console.log("FINAL Payload", payLoad)
        state.ui.postLoading.getAccountFormList = true;
        ethitransService
          .projectAccountFormListSearch(payLoad)
          .then((res: any) => {
            // console.log("projectAccountFormListSearch Response : >>> ", res.data);
            state.data.formsList = res.data ? res.data : {};
            if(res.data.totalPages){
              state.data.paging.getAccountFormList.totalPages = res.data.totalPages;
            }
            if(res.data.totalDocs){
              state.data.paging.getAccountFormList.totalDocs = res.data.totalDocs;
            }
            // res.data.projects;
            // getChannelList(1);
          })
          .catch((error: any) => {
            console.log(error);
          })
          .finally(() => {
            state.ui.postLoading.getAccountFormList = false;
          });
      } else {
        const payLoad = {
          search: {
            // eslint-disable-next-line @typescript-eslint/camelcase
            project_id: projectId,
            // eslint-disable-next-line @typescript-eslint/camelcase
            channel_id: state.data.filter.channelId ? state.data.filter.channelId : '',
            // eslint-disable-next-line @typescript-eslint/camelcase
            form_name: state.data.filter.formName,
            
          },
          filter: {
            type: state.data.filter.type,
            email: state.email,
          },
          // eslint-disable-next-line @typescript-eslint/camelcase
          sort_by: {
            // eslint-disable-next-line @typescript-eslint/camelcase
            // created_date: state.data.filter.sortBy,
            title: state.data.filter.title,
          },
          paging: {
            // eslint-disable-next-line @typescript-eslint/camelcase
            per_page: state.data.paging.getAccountFormList.perPage,
            // eslint-disable-next-line @typescript-eslint/camelcase
            current_page: state.data.paging.getAccountFormList.currentPage,
          },
        };
        // console.log("FINAL Payload", payLoad)
        state.ui.postLoading.getAccountFormList = true;
        ethitransService
          .projectAccountFormListSearch(payLoad)
          .then((res: any) => {
            // console.log("projectAccountFormListSearch Response : >>> ", res.data);
            state.data.formsList = res.data ? res.data : {};
            // res.data.projects;
            // getChannelList(1);
          })
          .catch((error: any) => {
            console.log(error);
          })
          .finally(() => {
            state.ui.postLoading.getAccountFormList = false;
          });
      }
    }
    function nextPage(event: any) {
      // console.log("NEXT PAGE >>>", event)

      state.data.paging.getAccountFormList.currentPage = event;
      getAccountFormList();
    }
    function getProjectDetail() {
      state.ui.isLoading.getProjectDetail = true;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: projectId,
      };
      ethitransService
        .projectDetails(payLoad)
        .then((res: any) => {
          console.log("Project Details Response Check:>>> ", res.data.data);
          state.data.projectDetails = res.data.data;
          getAccountFormList();
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isLoading.getProjectDetail = true;
        });
    }
    function selectEditForm(form: any) {
      router.push({
        name: "admin-channel-create-form",
        query: { formId: form._id, mode: "edit" },
      });

    }
    function sendSelectedForm(form: any) {
      router.push({
        name: "admin-channel-create-form",
        query: { formId: form._id, mode: "send" },
      });
    }

    onMounted(() => {
      console.log("Project ID CHECK : >>>", projectId);
      state.name = localStorage.getItem("current_user_details");
      state.email = localStorage.getItem("current_user_email");
      getProjectDetail();
    });
    return { state, templateList, getProjectDetail, getAccountFormList, selectEditForm, sendSelectedForm, selectForm, hideTemplateModal, nextPage };
  },
});
