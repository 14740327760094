
import { defineComponent, reactive, onMounted, watch, computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ethitransService } from "@/services/EthitransService";
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import moment from "moment";
import { useStore } from "vuex";
import introJs from "intro.js";
import { config } from "@/Config";
import Multiselect from 'vue-multiselect';

export default defineComponent({
  name: "ProjectCalendar",
  components: {
    VueCal,
    Multiselect,
  },
  setup() {
    const options = ref([]) as any;
    const selectedOptions = ref([]) as any;
    const userOptions = ref([]) as any;
    const selectedUserOptions = ref([]) as any;
    const store = useStore();
    const {
      params: { userId },
    } = useRoute();
    const route = useRoute();
    const state: any = reactive({
      userLisrt: [],

      data: {
        showAllDayEvents: 0,
        shortEventsOnMonthView: false,
        currentView: 'month',
        cellHeight: 0,
        cellDateHeight: 20, // Never changes.
        eventHeight: 22, // Never changes.
        eventLimit: 2,
        showDialog: false,
        startDate: "" as any,
        endDate: "" as any,
        details: "" as any,
        selectedEvent: {} as any,
        allPhases: [] as any,
        status: "" as any,
        selectedDate: "" as any,
        projectIds: [] as any,
        userIds: [] as any,
        todoInMonthe: [] as any,
        selectedEventValue: {
          project: "" as any,
          phpase: "" as any,
          task: [] as any,
          user: [] as any,
        },
        filterProjects: [] as any,
        filterUsers: [] as any,
        currentMonth: moment(new Date()).format("Y-MM-DD"),
        appointmentLoad: false,
        showAppointCreateModel: false,
        appointmentList: [] as any,
        appointmet: [] as any,
      },
      ui: {
        isLoading: {
          getAccountFormList: false,
          getAllAppointments: false,
        },
        isPostLoading: {
          createUserIssue: false,
          searchButton: true,
          selectUser: true,
        },
      },
      error: {
        hasError: false,
        erorList: {
          uploadClientDocument: [] as any,
          errorsList: [] as any,
        },
      },
    });

    function truncatedText(text: any) {
      if (text.length > 5) {
        return `${text.substring(0, 5)}..`;
      } else {
        return text;
      }
    }

    function getaddAppointment(data: any) {
      console.log("emit data:::", data);
      state.data.showAppointCreateModel = !state.data.showAppointCreateModel;
    }
    function showAppointmentModal() {
      state.data.showAppointCreateModel = !state.data.showAppointCreateModel;
    }
    if (route.query.zoomMeeting === "true") {
      setTimeout(showAppointmentModal, 1000);
    }

    function getCurrentMonth() {
      ethitransService
        .userProjectCalendar(userId)
        .then((res: any) => {
          console.log("get user calendar data", res.data.data);
          const dateNow = moment(new Date()).format("Y-MM-DD HH:MM:SS");
          console.log("now date tiem", dateNow);
          res.data.data.forEach((appointmentData: any) => {
            const data = {
              // start: appointmentData.start_time,
              // end: appointmentData.end_time,
              // title: appointmentData.title,
              // contentFull: appointmentData
              // class:
              //   appointmentData.end_time > dateNow ? "blue-event" : "red-event",
              // content: "Meeting Details:" + appointmentData.note,
            };
            // state.data.appointmentList.push(data);
          });
          // toggleTourGuide();
          state.data.appointmentLoad = true;
        })
        .catch((error: any) => {
          //consoloe.log(error);
        })
        .finally(() => {
          // state.postLoading = false;
        });
    }


    function generateRandomColor(): string {
      const r = Math.floor(Math.random() * 256);
      const g = Math.floor(Math.random() * 256);
      const b = Math.floor(Math.random() * 256);
      const hex = `#${r.toString(16)}${g.toString(16)}${b.toString(16)}`;
      return hex;
    }
    function convertMonth(data: any) {
      const [year, month] = data.split("-");
      const date = new Date(year, month - 1);
      return date.toLocaleDateString('en', { year: 'numeric', month: 'long' });

    }
    function addEvent(appointmentData: any, fulData: any) {
      const appName = truncatedText(appointmentData.name);
      const projecName = truncatedText(appointmentData.todo.project.name)
      let html = `<p>${projecName} > ${appName}</p>`;

      appointmentData.tasks.forEach((task: any) => {
        const taskName = truncatedText(task.name);
        html += `<div class="phase_task"><span class="calendar-task d-block">${taskName}</span>`;
        if (task.allMembers.length > 0) {
          task.allMembers.slice(0, 3).forEach((member: any) => {
            if (member.image) {
              html += `<span class="calendar-av">
                       <img src="${member.image}" alt="${member.email}"/>
                        
                      </span>`;
            } else {
              html += `<span class="calendar-av">
                       <img src="${state.data.imageBaseUrl}img/no-user.jpg" alt="${member.email}"/>
                        
                      </span>`;
            }
          });
          if (task.allMembers.length > 3) {
            html += `<span class="more-member">+${task.allMembers.length - 3}</span>`

          }
        }
        html += `</div>`;

      });
      if (appointmentData.tasks.length > 2) {
        html += `<p><span class="more-url">${appointmentData.tasks.length - 2} More +</span></p>`;
      }
      const data = {
        start: moment(appointmentData.start_day).format("Y-MM-DD HH:MM:SS"),
        end: moment(appointmentData.end_day).format("Y-MM-DD HH:MM:SS"),
        title: html,
        contentFull: fulData,
        color: appointmentData.text_color,
        backgroundColor: appointmentData.bg_color,
        allDay: true
        // class:
        //   appointmentData.end_time > dateNow ? "blue-event" : "red-event",
        // content: "Meeting Details:" + appointmentData.note,
      };
      state.data.appointmentList.push(data);
    }

    function getUserCalendarMonthlyData() {
      state.ui.isLoading.getAllAppointments = true;
      state.data.appointmentList = [];
      state.data.todoInMonthe = [];
      // state.data.currentMonth = moment(new Date()).format("Y-MM-DD");
      const payLoad = {
        'month': state.data.currentMonth,
        'projects': state.data.projectIds,
        'users': state.data.userIds,
      };
      options.value = [];

      ethitransService
        .getProjectCalendarMonthlyData(userId, payLoad)
        .then((res: any) => {
          console.log("get user calendar data", res.data.data);
          const dateNow = moment(new Date()).format("Y-MM-DD HH:MM:SS");
          console.log("now date tiem", dateNow);
          res.data.data.phases.forEach((appointmentData: any) => {
            addEvent(appointmentData, appointmentData);
            if (appointmentData.sub_events.length > 0) {
              appointmentData.sub_events.forEach((eventData: any) => {
                addEvent(eventData, appointmentData);
              });
            }
          });
          res.data.data.projects.forEach((project: any) => {
            const data = {
              name: project.name,
              code: project.name,
              id: project.id,

            };
            options.value.push(data);
          });
          state.data.appointmentLoad = true;
          state.data.todoInMonthe = [];
          res.data.data.months.forEach((month: any) => {
            const data = convertMonth(month);
            state.data.todoInMonthe.push(data);
          });

          // state.data.todoInMonthe = res.data.data.months;
        })
        .catch((error: any) => {
          //consoloe.log(error);
        })
        .finally(() => {
          state.ui.isLoading.getAllAppointments = false;
          // state.postLoading = false;
        });
    }

    function getProjectWiseUsers() {
      userOptions.value = [];
      const payLoad = {
        'projects': state.data.projectIds,
      };
      ethitransService
        .getProejctWiseUser(userId, payLoad)
        .then((res: any) => {

          state.sortList = res.data.data.users;
          res.data.data.users.forEach((user: any) => {
            const data = {
              name: user.full_name,
              code: user.full_name,
              id: user.id,

            };
            userOptions.value.push(data);
          });
        })
        .catch((error: any) => {
          //consoloe.log(error);
        })
        .finally(() => {
          // state.ui.isLoading.getAllAppointments = false;
          // state.postLoading = false;
        });
    }


    function addTag(tag: any) {
      selectedOptions.value.push(tag);
    }

    function changeMonthData(data: any) {
      state.data.currentMonth = "";
      state.data.appointmentList = [];
      state.data.currentMonth = moment(data.startDate).format("Y-MM-DD");
      getUserCalendarMonthlyData();
    }


    function openEventDetails(event: any, e: any) {
      $("#evendDetailsModel").modal("show");
      state.data.selectedEvent = event;
      state.data.startDate = moment(event.contentFull.start_date).format("Y-MM-DD");
      state.data.endDate = moment(event.contentFull.end_date).format("Y-MM-DD");
      state.data.details = event.contentFull.description;
      state.data.status = event.contentFull.status;
      state.data.selectedEventValue.project = event.contentFull.todo.project.name;
      state.data.selectedEventValue.phase = event.contentFull.name;
      state.data.selectedEventValue.task = event.contentFull.tasks;
      state.data.selectedEventValue.user = event.contentFull.allMembers;
      console.log(event);
      e.stopPropagation()

    }

    function countOverflowingEvents(eventsCount: any) {
      if (eventsCount > 2) {
        return eventsCount - 2;
      }
      // return Math.ceil(
      //   eventsCount - (state.data.cellHeight - state.data.cellDateHeight) / state.data.eventHeight
      // );
    }

    function closeShowDataModel() {
      $("#evendDetailsModel").modal("hide");
      state.data.selectedEvent = {};
    }

    function closeListDataModel() {
      $("#evendListModel").modal("hide");
      state.data.selectedEvent = {};
    }


    const isMonthView = computed(() => {
      return state.data.currentView === 'month'

    });

    function phaseList(phases: any) {
      state.data.allPhases = phases;
      $("#evendListModel").modal("show");

      console.log("all", phases);

    }
    function changeDateFormate(date: any) {
      return moment(date).format("Y-MM-DD");
    }
    function serachData() {
      state.data.userIds = [];
      if (selectedUserOptions.value && selectedUserOptions.value.id) {
        state.data.userIds.push(selectedUserOptions.value.id)
      }
      getUserCalendarMonthlyData();
    }

    function clearData() {
      state.data.projectIds = [];
      selectedOptions.value = [];
      selectedUserOptions.value = {};
      state.data.userIds = [];
      userOptions.value = [];
      state.data.todoInMonthe = [];
      getUserCalendarMonthlyData();
    }

    function gotoMonth(data: any) {
      state.data.selectedDate = data;
      getUserCalendarMonthlyData();
      getProjectWiseUsers();
    }


    watch(
      // () => watchTour,
      () => selectedOptions.value,
      (newvalue, oldvalue) => {
        if (newvalue) {
          state.data.projectIds = [];
          newvalue.forEach((opt: any) => {
            state.data.projectIds.push(opt.id);
          });
          getProjectWiseUsers();
        }
      }
    );

    onMounted(() => {
      getCurrentMonth();
      state.data.selectedDate = new Date();
      getUserCalendarMonthlyData();
      state.data.imageBaseUrl = config.imageBaseUrl;
      getProjectWiseUsers();

    });

    return {
      state,
      isMonthView,
      getaddAppointment,
      showAppointmentModal,
      getCurrentMonth,
      getUserCalendarMonthlyData,
      changeMonthData,
      openEventDetails,
      closeShowDataModel,
      generateRandomColor,
      countOverflowingEvents,
      phaseList,
      closeListDataModel,
      truncatedText,
      addEvent,
      changeDateFormate,
      options,
      selectedOptions,
      addTag,
      serachData,
      clearData,
      getProjectWiseUsers,
      userOptions,
      selectedUserOptions,
      convertMonth,
      gotoMonth
    };
  },
});
