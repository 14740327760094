
import { defineComponent, onMounted } from "vue";

export default defineComponent({
  name: "AppImageList",
  props: {
    imagesToUpload: Array,
  },
  setup(props, { emit }) {
    function removeSelectedImage(selected: any) {
      emit("selectedToDelete", selected);
    }
    return {
      removeSelectedImage,
    };
  },
});
