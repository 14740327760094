
import { useStore } from "vuex";
import { defineComponent, reactive, watch, onMounted, computed } from "vue";
import { useRouter } from "vue-router";
// import ToDoPhaseTaskListUpdateModal from "@/components/todo/ToDoPhaseTaskListUpdateModal.vue";
import ToDoListAddPhaseModal from "@/components/todo/ToDoListAddPhaseModal.vue";
import ToDoMembersListForTaskTable from "@/components/todo/ToDoMembersListForTaskTable.vue";
import SuccessfulModalCustom from "@/components/modal/SuccessfulModalCustom.vue";

// import ToDoMembersList from "@/components/todo/ToDoMembersList.vue";
// import ToDoListMembersList from "@/components/todo/ToDoListMembersList.vue";
import ToDoTaskLogModal from "@/components/todo/ToDoTaskLogModal.vue";
import DragAndDropZone from "@/common/DragAndDropZone.vue";
import ToDoPhaseStatus from "@/components/todo/ToDoPhaseStatus.vue";
import AppImageList from "@/common/AppImageList.vue";
import { ethitransService } from "@/services/EthitransService";
import { common } from "@/common/services/Common";

import ErrorList from "@/components/commonDesign/ErrorList.vue";


export default defineComponent({
  name: "ToDoPhaseDetailTasks",
  props: {
    role: {
      type: Number,
      required: true,
    },
    phaseId: {
      type: String,
    },
    project: {
      type: Object,
      required: true,
    },
    phase: {
      type: Object,
      required: true,
    },
    subPhases: {
      type: Array,
      required: true,
    },
    showAction: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    SuccessfulModalCustom,
    ToDoListAddPhaseModal,
    ToDoMembersListForTaskTable,
    // ToDoListMembersList,
    ToDoTaskLogModal,
    ToDoPhaseStatus,
    AppImageList,
    ErrorList,
    // Button,
    // ErrorList,
    DragAndDropZone,
  },
  setup(props, { emit }) {
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      data: {
        uploadMaxSize : 25 * 1024 * 1024 as any,
        selectedTaskComplete: {
          details: "",
          attachments: [] as any,
          selected: {
            uuid: ""
          } as any
        } as any,
        selectedToEdit: {} as any,
        selected: {} as any,
        taskList: [] as any,
        imagesToUpload: [] as any,
        selectAll: false,
        updateTask: true,
        showAddPhaseModal: false,
        editMode: false,
        SuccessfulModalCustom: false,
        showSuccesRedirectModal: false,
        showToDoTaskLogModal: false,
        individualTaskChecked: false,
        stripeUrl: "",
        successFulMessage: "",
        editFrom: "task",
      },
      ui: {
        error: {
          hasError: false,
          errorList: {
            createPhase: [] as any,
          },
        },
        isLoading: {
          getToDoData: false,
          stripeAccountCheck: false,
        },
        isPostLoading: {
          createPhase: false,
          stripeAccount: false,
        },
      },
    });
    // eslint-disable-next-line vue/return-in-computed-property
    const taskComputed = computed(() => {
      const selectedTasks: any = state.data.taskList.filter(
        (e: any) => e.isChecked
      );
      const filteredTasks: any = selectedTasks.filter(
        (e: any) => e.status === "Close"
      );
      // emit("selectedTasks", selectedTasks);
      // emit("checkedTask", state.data.taskList.filter(
      //   (e: any) => e.isChecked
      // ));
      return filteredTasks;
    });
    // const hasLog = (childPhase: any) => {
    //   return childPhase.logs && childPhase.logs.length > 0;
    // };

    const hasDoneStatus = computed(() => {
      return !state.data.taskList.some((item: any) => item.status_detail.status.code === 'done');
    });

    watch(
      // () => watchTour,
      () => state.data.selectAll,
      (newvalue, oldvalue) => {
        if (newvalue === true) {
          // console.log("TRUE");
          state.data.taskList.forEach((item: any) => {
            if (item.status_detail.status.code === 'done') {
            // if (item.status_detail.status.code === 'done' && item.invoice === null) {
              item.isChecked = true;
              // emit("selectedTasks", item);
            } else {
              item.isChecked = false;
            }
          });
        } else {
          // console.log("FALSE");
          state.data.taskList.forEach((item: any) => {
            item.isChecked = false;
          });
        }
        emit(
          "checkedTask",
          state.data.taskList.filter((e: any) => e.isChecked)
        );
      }
    );
    function check(selectedData: any) {
      // const selectedTasks: any = selectedData.filter(
      //   (e: any) => e.isChecked
      // );
      // const filteredTasks: any = selectedTasks.filter(
      //   (e: any) => e.status === "Close"
      // );
      // // if(selectedData.status != 'Close') return false;
      // emit("checkedTask", filteredTasks);
      emit(
        "checkedTask",
        state.data.taskList.filter((e: any) => e.isChecked)
      );

      // state.data.individualTaskChecked = true;
      // state.data.SuccessfulModalCustom = !state.data.SuccessfulModalCustom;
      // $("#SuccessfulModalCustom").modal("hide");
    }
    function deleteAttachementTaskCompleted(item: any){
      state.data.selectedTaskComplete.attachments = state.data.selectedTaskComplete.attachments.filter(
        (e: any) => e !== item
      );
    }
    function readImage(file: any) {
      const data = {} as any;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
        const extensionLower = extension.toLowerCase();
        data.file = e.target.result;
        data.duplicateFile = false; // data.fileName = file.name;
        data.name = file.name.replaceAll(`.${extension}`, "");

         if (file.size>state.data.uploadMaxSize) {
          data.isValidToUpload = false;
        } else {
          data.isvalidToUpload = true;
        }
        data.extension = extensionLower;
        data.isuploded = false;
        data.tag = [] as any;
        data.size = common.formatBytes(parseInt(file.size));
        state.data.selectedTaskComplete.attachments.push(data);
      };
      reader.readAsDataURL(file);
    }
    const dropFiles = (event: any) => {
      const files = event.target.files || event.dataTransfer.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        readImage(file);
      }
    }
    function closeSuccessCustomModal() {
      state.data.SuccessfulModalCustom = !state.data.SuccessfulModalCustom;
      $("#SuccessfulModalCustom").modal("hide");
    }
    function logData(data: any) {
      emit("updateLog", data);
      // if(data && !state.data.stripeUrl){
      //    state.data.SuccessfulModalCustom = !state.data.SuccessfulModalCustom;
      //     state.data.successFulMessage = "Task Completed";
      // }
    }
    function updateSelected(data: any) {
      emit("updatePhaseList", data);
    }
    function stripeAccountCheck() {
      // alert("HELLO");
      state.ui.error.errorList.createPhase = [];
      state.ui.error.hasError = false;
      if (state.ui.isLoading.stripeAccountCheck) return false;
      if (
        state.ui.error.hasError ||
        state.ui.error.errorList.createPhase.length != 0
      )
        return false;
      state.ui.isLoading.stripeAccountCheck = true;
      ethitransService
        .stripeAccountCheck("")
        .then((res: any) => {
          // alert("THEN");
          // emit("logData", res.data.data);
          if (res.data.data.status === 0) {
            state.data.stripeUrl = res.data.data.link;
            console.log("Project Todo Response Check:>>> ", res.data.data);
            state.data.showSuccesRedirectModal =
              !state.data.showSuccesRedirectModal;
            state.data.successFulMessage = "Task Completion Logged";
          } else if (res.data.data.status === 1) {
            state.data.SuccessfulModalCustom = !state.data.SuccessfulModalCustom;
            state.data.successFulMessage = "Task Completed";
          }
          // logData(res.data.data);
          // hideTaskLogModal();
        })
        .catch((error: any) => {
          // alert("CATCH WHY");
          console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.createPhase)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.createPhase.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isLoading.stripeAccountCheck = false;
        });
    }
    // task complete
    function update(data: any) {
      state.ui.error.errorList.createPhase = [];
      state.ui.error.hasError = false;
      state.data.selectedTaskComplete.selected = data;
      state.data.selectedTaskComplete.attachments= [];
      state.data.selectedTaskComplete.details = "";
      // taskCompleteModal
      $("#taskCompleteModal").modal("show");
    }
    function hideTaskCompleteModal() {
      $("#taskCompleteModal").modal("hide");
    }
    function taskCompleted(data: any) {
      // alert("HELLO");
      state.ui.error.errorList.createPhase = [];
      state.ui.error.hasError = false;
      if (state.ui.isPostLoading.createPhase) return false;
      if (
        state.ui.error.hasError ||
        state.ui.error.errorList.createPhase.length != 0
      )
        return false;
      const payLoad: any = {
        id: state.data.selectedTaskComplete.selected.uuid,
        type: 5,
        detail: state.data.selectedTaskComplete.details  ? state.data.selectedTaskComplete.details  : "",
        files: state.data.selectedTaskComplete.attachments  ? state.data.selectedTaskComplete.attachments  : [],
      };
      console.log(" Update PAYLOAD CHECK ><><><><> ", payLoad);
      state.ui.isPostLoading.createPhase = true;
      ethitransService
        .setPhaselog(payLoad)
        .then((res: any) => {
          // alert("THEN");
          console.log("Project Todo Response Check:>>> ", res.data.data);
          // emit("logData", res.data.data);
          //  state.data.SuccessfulModalCustom = !state.data.SuccessfulModalCustom;
          // state.data.successFulMessage = "Task Completed";
          hideTaskCompleteModal();
          logData(res.data.data);
          stripeAccountCheck();

          // hideTaskLogModal();
        })
        .catch((error: any) => {
          // alert("CATCH WHY");
          console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.createPhase)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.createPhase.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.createPhase = false;
        });
    }
    function redirectToPhaseLogs(data: any) {
      // console.log("CHECK >>> ", data)
      router.push({
        name: "user-todo-task-log-details",
        params: { phaseId: data.uuid },
        query: { id: props.project.uuid },
        // props.phaseId
      });
    }
    function redirectToTaskEdit(data: any) {
      router.push({
        name: "edit-task",
        params: { taskId: data },
      });
    }
    function redirectToStripeAccount() {
      state.ui.isPostLoading.stripeAccount = true;
      window.location.replace(`${state.data.stripeUrl}`);
      state.ui.isPostLoading.stripeAccount = false;
    }
    function closeModal() {
      state.data.showToDoTaskLogModal = !state.data.showToDoTaskLogModal;
    }
    function showLogModal(data: any) {
      state.data.showToDoTaskLogModal = !state.data.showToDoTaskLogModal;
      console.log("SELECTED TASK >>>> ", data);
      state.data.selected = data;
    }
    function showAddPhaseModal() {
      state.data.showAddPhaseModal = !state.data.showAddPhaseModal;
    }
    function taskViewDetail(childPhase: any) {
      // console.log("Selected Task >>> ",childPhase)
      router.push({
        name: "user-todo-details",
        params: { phaseId: childPhase.uuid },
      });
    }
    function redirectToAddMemerToPhase(childPhase: any) {
      console.log("Selected Task >>> ", childPhase);
      router.push({
        name: "user-todo-add-member",
        // eslint-disable-next-line @typescript-eslint/camelcase
        params: { id: childPhase.parent_phase.uuid },
        query: { taskId: childPhase.uuid },
      });
    }
    function taskEdit(childPhase: any) {
      state.data.editMode = !state.data.editMode;
      state.data.selectedToEdit = childPhase;
      // console.log("Selected Task  for Edit>>> ",childPhase)
      showAddPhaseModal();
    }
    function addNewPhase(data: any) {
      emit("updatePhase", data);
      console.log("UPDATE THE PHASE");
    }
    watch(
      () => props.subPhases,
      () => {
        state.data.taskList = props.subPhases ? props.subPhases : [];
      }
    );
    function redirectToSelectedInvoice(
      invoiceId: string,
      selectedMode: string
    ) {
      // console.log("CHECK >>> ", data)
      router.push({
        name: "admin-billing-invoice",
        params: { invoiceId: invoiceId },
        query: { mode: selectedMode },
      });
    }
    function downloadFile(pdfLink: any) {
      // state.selectedPdf = pdfLink;

      window.open(pdfLink, "_blank");
    }
    function isActive(permissionList: any, action: string) {
      return common.isActionAllowded(props.role, permissionList, action);
    }
    onMounted(() => {
      state.data.taskList = props.subPhases ? props.subPhases : [];
    });

    return {
      state,
      hasDoneStatus,
      deleteAttachementTaskCompleted,
      dropFiles,
      readImage,
      hideTaskCompleteModal,
      taskCompleted,
      isActive,
      check,
      redirectToAddMemerToPhase,
      redirectToSelectedInvoice,
      taskComputed,
      redirectToStripeAccount,
      stripeAccountCheck,
      closeSuccessCustomModal,
      update,
      logData,
      redirectToPhaseLogs,
      taskViewDetail,
      taskEdit,
      showAddPhaseModal,
      addNewPhase,
      showLogModal,
      closeModal,
      downloadFile,
      updateSelected,
      redirectToTaskEdit,
      // hasLog
    };
  },
});
