
import { defineComponent, reactive, onMounted, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { ethitransService } from "@/services/EthitransService";

import RenameModal from "@/components/modal/RenameModal.vue";
import DetailModal from "@/components/modal/DetailModal.vue";
import DeleteModal from "@/components/modal/DeleteModal.vue";
import ShareModal from "@/components/modal/ShareModal.vue";

import ErrorList from "@/components/commonDesign/ErrorList.vue";
// import EmptyData from "@/components/commonDesign/EmptyData.vue";
import { common } from "@/common/services/Common";
import { config } from "@/Config";

export default defineComponent({
  name: "MyDriveFolder",
  props: {
    folders: {
      type: Array,
      required: true,
    },
  },
  components: {
    RenameModal,
    ErrorList,
    DetailModal,
    DeleteModal,
    ShareModal,
  },
  setup(props, { emit }) {
    const routerQuery = useRoute();
    const router = useRouter();
    const state = reactive({
      data: {
        folderRename: "" as any,
        folderId: "" as any,
        selectedFolder: {} as any,
        folders: [] as any,
        imageBaseUrl: "",
        showShareModal: false,
        showRenameModal: false,
        showDetailModal: false,
        showDeleteModal: false,
      },
      ui: {
        error: {
          hasError: false,
          errorList: {
            renameSelectedFolder: [] as any,
            downloadFolder: [] as any,
          },
        },
        isLoading: {
          projectListActivities: false,
        },
        isPostLoading: {
          closeChannel: false,
          renameSelectedFolder: false,
          downloadFolder: false,
        },
      },
    });

    // eslint-disable-next-line vue/return-in-computed-property
    // const selectedFolders = computed(() => {
    // const selectedFolder = [] as any
    // if(props.folders){
    //   props.folders.forEach((item: any)=> {
    //     if(item.isChecked){
    //       selectedFolder.push(item)
    //     }
    //   })
    // }
    // return selectedFolder;

    // });
    function formatDate(dateString: string) {
      return common.formatDateTimeWithTimeFormat(common.localDateAndTime(dateString));
      // return common.formatDateTimeWithTimeFormat(dateString);
    }
    function folderSelect() {
      emit("selectedFolder", state.data.folders);
    }

    function closeShareModal() {
      state.data.showShareModal = !state.data.showShareModal;
    }
    function showShareModal(folder: object) {
      state.data.selectedFolder = folder;
      state.data.showShareModal = !state.data.showShareModal;
    }
    function downloadFolder(folder: any) {
      state.ui.error.errorList.downloadFolder = [];
      state.ui.error.hasError = false;
      if (
        state.ui.error.errorList.renameSelectedFolder.length != 0 ||
        state.ui.error.hasError ||
        state.ui.isPostLoading.downloadFolder
      )
        return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        folder_id: folder.uuid,
      };
      state.ui.isPostLoading.downloadFolder = true;
      ethitransService
        .folderDownload(payLoad)
        .then((res: any) => {
          console.log("downloadFolder Response Check:>>> ", res.data.data);
          window.open(res.data.data, "_blank");
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.downloadFolder)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.downloadFolder.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.downloadFolder = false;
        });
    }
    function closeDeleteModal(data: any) {
      console.log("EMIT DATA OF FOLDER CHECK >>> ", data);
      if (data === true) {
        // alert("WOWOWOW")
        emit("delete", state.data.selectedFolder);
      }
      state.data.showDeleteModal = !state.data.showDeleteModal;
    }
    function showDeleteFolderModal(data: object) {
      // console.log("Folder  Check >>> ", data)
      state.data.selectedFolder = data;
      state.data.showDeleteModal = !state.data.showDeleteModal;
    }
    function validateRenameFolder() {
      if (!state.data.folderRename) {
        state.ui.error.errorList.renameSelectedFolder.push(
          "Folder name is required."
        );
      }
      if (
        !state.ui.error.errorList.renameSelectedFolder &&
        !state.ui.error.errorList.renameSelectedFolder.length
      ) {
        state.ui.error.hasError = false;
      } else if (state.ui.error.errorList.renameSelectedFolder.length != 0) {
        state.ui.error.hasError = true;
      }
    }
    function closeRenameModal() {
      state.data.showRenameModal = !state.data.showRenameModal;
      state.ui.error.errorList.renameSelectedFolder = [];
      state.ui.error.hasError = false;
      // emit("close");
      $("#renameModal").modal("hide");
    }
    function resetTheName(data: any) {
      emit("rename", data);
    }
    function showDetailModal(data: any) {
      console.log("Detail Modal");
      state.data.showDetailModal = !state.data.showDetailModal;
      state.data.selectedFolder = data;
    }
    function closeDetailModal() {
      state.data.showDetailModal = !state.data.showDetailModal;
    }
    function renameSelectedFolder() {
      state.ui.error.errorList.renameSelectedFolder = [];
      state.ui.error.hasError = false;
      validateRenameFolder();
      if (
        state.ui.error.errorList.renameSelectedFolder.length != 0 ||
        state.ui.error.hasError ||
        state.ui.isPostLoading.renameSelectedFolder
      )
        return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        folder_id: state.data.folderId,
        name: state.data.folderRename,
      };
      state.ui.isPostLoading.renameSelectedFolder = true;
      ethitransService
        .folderRename(payLoad)
        .then((res: any) => {
          console.log("Project List Response Check:>>> ", res.data.data);
          closeRenameModal();
          resetTheName(res.data.data);
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.renameSelectedFolder)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.renameSelectedFolder.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.renameSelectedFolder = false;
        });
    }
    function showRenameModal(folder: any) {
      state.ui.error.errorList.renameSelectedFolder = [];
      state.ui.error.hasError = false;
      // console.log("rename Modal opended", folder);
      state.data.showRenameModal = !state.data.showRenameModal;
      state.data.folderRename = folder.name;
      state.data.folderId = folder.uuid;
      // $("#renameModal").modal("hide");
    }

    function toggleDropdownMenuButton() {
      $(".dropdown-toggle").dropdown("toggle");
    }
    function redirectToDocument(folder: any) {
      if (!routerQuery.query.routerQuery) {
        const uuid = folder.uuid;
        // console.log("UUID CHECK >>> ", uuid)
        router.push({
          name: "admin-document-details",
          // params: { folderUUID: UUID },
          query: { folderId: uuid },
        });
      } else {
        console.log("URL WITH FOLDER ID");
      }
    }
    watch(
      () => props.folders,
      (newvalue, oldvalue) => {
        if (newvalue) {
          state.data.folders = props.folders;
        }
      }
    );
    onMounted(() => {
      state.data.folders = props.folders;
    });

    return {
      state,
      redirectToDocument,
      closeRenameModal,
      showRenameModal,
      toggleDropdownMenuButton,
      renameSelectedFolder,
      validateRenameFolder,
      resetTheName,
      closeDetailModal,
      showDetailModal,
      showDeleteFolderModal,
      closeDeleteModal,
      downloadFolder,
      showShareModal,
      closeShareModal,
      // selectedFolders,
      folderSelect,
      formatDate,
    };
  },
});
