
import { defineComponent, reactive, onMounted } from "vue";
export default defineComponent({
  name: "RadioButtonComponent",
  props: {
    data: Object,
    index: Number,
    editStatus: Boolean,
  },
  setup(props) {
    const state = reactive({
      title: "" as any,
      selectValues: [] as any,
    });
    function showTextFieldAdd() {
      if (props.data) {
        state.title = props.data.title;
        state.selectValues = props.data.select_values;
      }
    }
    onMounted(() => {
      showTextFieldAdd();
    });
    return { state, showTextFieldAdd };
  },
});
