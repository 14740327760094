
import { defineComponent, reactive, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
// import ErrorList from "@/components/commonDesign/ErrorList.vue";

import { ethitransService } from "@/services/EthitransService";
import { common } from "@/common/services/Common";
// import ToDoPhaseAddMemberTask from "@/components/todo/ToDoPhaseAddMemberTask.vue";
// import SuccessfulModal from "@/components/modal/SuccessfulModal.vue";
// import EmptyData from "@/components/commonDesign/EmptyData.vue";
// import QCalender from "@/components/projectDetail/QCalender.vue";
// import ProCalender from "@/components/projectDetail/ProCalender.vue";
// import CustomEventCalender from "@/components/projectDetail/CustomEventCalender.vue";

// import AppGoBack from "@/components/commonDesign/AppGoBack.vue";


// import { Data } from "@/components/todo/AddMember.ts";

export default defineComponent({
  name: "ToDoPhaseInvoiceForMiddleMan",
  components: {
    // ToDoPhaseAddMemberTask,
    // ErrorList,
    // SuccessfulModal,
    // AppGoBack,
    // CustomEventCalender,
    // QCalender,
    // ProCalender,
    // EmptyData,
    // ToDoListBreadCrumb,
    // ToDoListPhaseBlock,
    // ToDoListAddPhaseModal,
    // SuccessfulModal,
  },
  setup() {
    const {
      params: { smartId },
    } = useRoute();
    const router = useRoute();
    const route = useRouter();
    const phaseDetails = ref({
      phase: {
        // eslint-disable-next-line @typescript-eslint/camelcase
        child_phases: [] as any,
      } as any,
      roles: [] as any,
      // eslint-disable-next-line @typescript-eslint/camelcase
      smart_contract: {
        uuid: "",
      },
      // eslint-disable-next-line @typescript-eslint/camelcase
      amount_types: [] as any,
      // eslint-disable-next-line @typescript-eslint/camelcase
      phase_members: [] as any,
      members: [] as any,
    });
    const state = reactive({
      data: {
        phaseDetails: {} as any,
        activeUUID: "" as any,
        isPayActive: false,
        selectedInvoice: {} as any,
        title: "Received Invoices",
        heading: "Invoices not Requested",
        loggedInUserUUID: "",
        selectedMemberIndex: 0,
        activeTab: "generte-invoice",
        minDate: "",
        maxDate: "",
        filterReceivedInvoiceList: {
          list: [] as any,
          id: "",
          listByStatus: 1,
          listByTitle: "",
        },
        membersAdded: false,
        phaseDetailsStatus: true,
        agreeToInvitation: false,
        showAddMemberModal: false,
        showSuccesModal: false,
        showAvailibilityModal: false,
        selectedEmail: "",
        selectedUser: {},
        successFulMessage:
          "Selected Members are added with roles and amount type in Phase",
        deleteModalMessage: "Selected Member will be Delete. Please Confirm.",
        selectedMemberForRole: {},
        selectedToRemove: {},
        selectedRole: {},
        selectedTask: {},
        showDeleteConfirmationModal: false,
        showTaskUpdateModal: false,
        invite: {
          newUsers: "",
          existingMembers: [] as any,
          existingMemberSelected: [] as any,
          selectedMembers: [] as any,
        },
      },
      ui: {
        error: {
          hasError: false,
          errorList: {
            phaseDetails: [] as any,
            getInvoiceByPhaseOrTask: [] as any,
            getPhaseDetails: [] as any,
          },
        },
        isLoading: {
          phaseDetails: false,
          changeDetailsByEmail: false,
          getInvoiceByPhaseOrTask: false,
          getPhaseDetails: false,
        },
        isPostLoading: {
          confirmPayment: false,
          receivedInvocieByPhaseId: false,
          addMembersInPhases: false,
        },
      },
    });
    function getSentInvoice() {
      state.ui.error.errorList.getPhaseDetails = [];
      state.ui.error.hasError = false;

      if (state.ui.isLoading.getPhaseDetails) return false;
      const data = {
        id : smartId, // smart contract ID
        status : 1,
        // eslint-disable-next-line @typescript-eslint/camelcase
        phase_id : router.query.phaseId,
      }


      state.ui.isLoading.getPhaseDetails = true;
      ethitransService
        .invoiceSentList(data)
        .then((res: any) => {
          console.log("getSentInvoice Response Check:>>> ", res.data.data);
          state.ui.isLoading.getPhaseDetails = false;
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.getPhaseDetails)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.getPhaseDetails.push(value[0]);
                }
              }
            });
        })
    }

    onMounted(() => {
      getSentInvoice();
      console.log("CHECKING QUERIES >>> ", router.query.phaseId)
    });

    return {
      state,
      getSentInvoice,
    };
  },
});
