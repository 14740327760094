
import { defineComponent, reactive } from "vue";
export default defineComponent({
  name: "TextBoxComponent",
  props: {
    data: Object,
    editStatus: Boolean,
  },
  setup(props) {
    const state = reactive({
      title: "" as any,
    });
    function showTextFieldAdd() {
      if (props.data) {
        state.title = props.data.title;
        $("#editTitle").modal("show");
      }
    }
    return { state, showTextFieldAdd };
  },
});
