
import { ref, computed, defineComponent, reactive, onMounted, watch } from "vue";
import { ethitransService } from "@/services/EthitransService";
import { validationService } from "@/common/ValidationService";
import ErrorList from "@/components/commonDesign/ErrorList.vue";
import { passwordStrength } from "check-password-strength";

import { useRouter, useRoute } from "vue-router";
import { common } from "@/common/services/Common";
import { config } from "@/Config";

// import Multiselect from 'vue-multiselect';


import AppEmailAndPhoneNumberValidate from "@/components/commonDesign/AppEmailAndPhoneNumberValidate.vue";
import AppEmailInput from "@/components/modal/AppEmailInput.vue"

import AppPhoneNumberInput from "@/components/commonDesign/AppPhoneNumberInput.vue"
import AppOTPInput from "@/components/modal/AppOTPInput.vue"

import CounterComponent from "@/components/modal/CounterComponent.vue"



export default defineComponent({
  name: "SignUpWithOTP",
  props: {
    tab: String,
  },
  components: {
    CounterComponent,
    ErrorList,
    AppEmailInput,
    AppOTPInput,
    // Multiselect,
    AppEmailAndPhoneNumberValidate,
    AppPhoneNumberInput,
  },
  setup(props, { emit }) {
    const getRouter = useRoute();
    const router = useRouter();
    const enteredValue = ref('');
    const enteredSecondaryValue = ref('');
    const errorMessage = ref('');
    const state = reactive({
      passCode: {
        primarySuccessMessage: "",
        secondarySuccessMessage: ""
      },
      data: {
        validInput: false,
        secondaryErrorMessage: '',
        validSecondaryInput: false,
        setCounter: 90,
        setSecondaryCounter: 90,
        otp: {
          primary: "",
          secondary: "",
          primaryNotification: false,
          secondaryNotification: false,
        },
        isPrimaryEmail: false,
        isSecondaryEmail: false,
        primaryVerifiedBy: "",
        registrationDetails: {
          packages: [] as any,
          countries: [] as any,
        } as any,
        selectedSubscriptionId: {} as any,
        showSuccesModal: false,
        successFulMessage: "",
        phone: {
          countryCode: "",
          collection: [] as any,
          number: "",
          code: "+1",
          mobileCode: "+1",
          otp: "" as any,
          options: [] as any,
          isVerify: false,
        },
        imageBaseUrl: "",
        registration: {
          signByInput: "",
          firstName: "",
          middleName: "",
          lastName: "",
          email: "" as any,
          password: "",
          passwordConfirmation: "",
          mobile: "",
          phone: "",
          notification: {
            sms: false,
            email: false,
          },
        },
        success: false,
        passwordFieldType: "password",
        passwordFieldTypeConfirm: "password",
        passwordClass: "fas fa-eye-slash",
        passwordClassConfirm: "fas fa-eye-slash",
        timeCount: 130,
      },
      ui: {
        showUI: {
          changePrimaryInput: false,
          changeSecondaryInput: false,
          notificationBy: false,
          primaryOTPSent: false,
          secondaryOTPSent: false,
          counter: false,
          counterSecondary: false,
          resendPrimaryOTP: false,
          resendSecondaryOTP: false,
          primaryInputClass: "col-md-8",
          primaryIconClass: "col-md-4",
          secondaryInputClass: "col-md-8",
          secondaryIconClass: "col-md-4",
          primaryVerifyButton: false,
          secondaryVerifyButton: false,
          showEmailInput: false,
          showMobileNumber: false,
          showOTP: false,
          showChangeButton: false,
          showSecondaryOTP: false,
          showSecondaryOTPVerified: false,
          secondaryVerifiedOTP: false,
          secondaryVerifiedDisabled: false,
          primaryVerified: false,
          secondaryVerified: false,
        },
        disabled: {
          disableEmailCheckbox: false,
          disableSmsCheckbox: false,
          primary: false,
          secondary: false,
        },
        error: {
          signUpErrors: [] as any,
          validationList: [] as any,
          registerReplaceList: [] as any,
          hasError: false,
        },
        isLoading: {
          register: false,
        },
        postLoading: {
          verifyPrimary: false,
          verifySecondary: false,
          register: false,
          replaceRegister: false,
          checkSecondaryOtp: false,
        },
        inputOtp: false,
        sendOtpButton: true,
        verifyButton: true,
      },
      countryCodeList: [] as any,
      errorList: [] as any,
      isErrorStatus: false,
    });

    const checkNotificationSms = () => {
      state.ui.showUI.changeSecondaryInput = false;
      // if(state.data.registration.notification.sms){

      //   state.data.registration.notification.sms = true
      // } else {
      state.data.registration.notification.sms = !state.data.registration.notification.sms
      // }
      console.log("CHECK Email >>> ", state.data.registration.notification.sms)
      // state.ui.showUI.secondaryVerified = true
      if (state.data.isPrimaryEmail) {
        state.ui.showUI.showMobileNumber = !state.ui.showUI.showMobileNumber;
      }
      if (!state.data.registration.notification.sms) {
        state.ui.disabled.secondary = false;
        state.ui.showUI.secondaryVerifiedOTP = false;
        state.ui.showUI.showSecondaryOTP = false;
      }
    };
    const checkNotificationEmail = () => {
      state.ui.showUI.changeSecondaryInput = false;
      // if(state.data.registration.notification.email){

      state.data.registration.notification.email = !state.data.registration.notification.email
      // } else {
      //   state.data.registration.notification.email = false
      // }
      console.log("CHECK Email >>> ", state.data.registration.notification.email)
      // state.ui.showUI.secondaryVerified = true
      if (!state.data.isPrimaryEmail) {
        state.ui.showUI.showEmailInput = !state.ui.showUI.showEmailInput;
      }
      if (!state.data.registration.notification.email) {
        state.ui.disabled.secondary = false;
        state.ui.showUI.secondaryVerifiedOTP = false;
        state.ui.showUI.showSecondaryOTP = false;
      }
    };

    const isEmail = computed(() => {
      const input = state.data.registration.signByInput;

      // Check if the input is a valid email
      if (validationService.isValidEmail(input)) {
        return true; // It is a valid email
      }

      // Check if the input is a valid mobile number
      if (validationService.validateMobileNumber(input)) {
        return false; // It is a valid mobile number
      }

      return false;

    });

    const passwordStrengthCheck: any = computed(() => {
      if (state.data.registration.password) {
        return passwordStrength(`${state.data.registration.password}`).value;
      } else {
        return "";
      }
    });
    const passwordConfirmationStrengthCheck: any = computed(() => {
      if (state.data.registration.passwordConfirmation) {
        return passwordStrength(
          `${state.data.registration.passwordConfirmation}`
        ).value;
      } else {
        return "";
      }
    });
    const errorList = computed(() => {
      return state.ui.error.validationList;
    });
    const passwordStatus = computed(() => {
      return state.data.passwordClass;
    });
    function insertContryCode() {
      console.log("insert");
      if (!state.data.registration.mobile) {
        state.data.registration.mobile = `+1${state.data.registration.mobile}`;
      }
    }
    function onInput(event: any) {
      // console.log("EVENT DATA >>>> ", event.data)
      // if(!state.data.editUserDetails.contactNumber){
      if (event.data != undefined) {
        state.data.phone.collection.push(event.data);
      }
      if (event.data === null) {
        if (state.data.phone.collection) {
          state.data.phone.collection.pop();
        }
      }
      // console.log("CHECK ARRAY >>> ", state.data.phone.collection)
      state.data.registration.mobile = state.data.phone.collection.toString();
      state.data.registration.mobile = state.data.registration.mobile.replace(
        /,/g,
        ""
      );
      // console.log("CHECK NUMBER STRING >>> ", state.data.registration.mobile )
      // }

      // console.log("INPUT ARRAY", state.data.phone.collection)
      // let string ="";
      // string += event.data
      // if(state.data.editUserDetails.contactNumber){

      //   // console.log("FINAL UNEDIT", state.data.editUserDetails.contactNumber.replace(/null/g, ''));
      //   state.data.phone.number =  state.data.editUserDetails.contactNumber.replace(/null/g, '')
      //   if(state.data.phone.number && event.data === null ){
      //     // state.data.phone.number.slice(0, -1)
      //     state.data.phone.number.substring(0, state.data.phone.number.length - 1)
      //   }
      // }
      // console.log("FINAL NUMBER CHECK", state.data.phone.number);
    }
    function countryChanged(country: any) {
      // console.log("Country the input", country)
      if (country.dialCode) {
        // console.log("Country the input", country.dialCode)
        state.data.phone.countryCode = country.dialCode;
        // console.log("Country FINAL CHECK >>>", state.data.phone.countryCode )
      }
    }
    function hideReplaceRegister() {
      state.ui.error.registerReplaceList = [];
      state.ui.error.hasError = true;
      $("#replaceRegister").modal("hide");
    }
    function validatePhoneNumber(phone: string) {
      // link https://regex101.com/r/QXAhGV/1
      // return /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/.test(phone);
      // return /^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/.test(phone);
      return /(^\+[0-9]{2}|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9\-\s]{11}$)/.test(
        phone
      );
      // return /(^\+[2-9][0-9][0-9]|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9\-\s]{11}$)/.test(phone);
    }
    function switchVisibility() {
      // alert()
      state.data.passwordFieldType =
        state.data.passwordFieldType === "password" ? "text" : "password";
      if (state.data.passwordClass === "fas fa-eye") {
        state.data.passwordClass = "fas fa-eye-slash";
      } else {
        state.data.passwordClass = "fas fa-eye";
      }
      $("#password").toggleClass("fas fa-eye-slash").toggleClass("fas fa-eye");
    }
    function switchVisibilityConfirm() {
      // alert()
      state.data.passwordFieldTypeConfirm =
        state.data.passwordFieldTypeConfirm === "password"
          ? "text"
          : "password";
      if (state.data.passwordClass === "fas fa-eye-slash") {
        state.data.passwordClass = "fas fa-eye";
      } else {
        state.data.passwordClass = "fas fa-eye-slash";
      }
      $("#password").toggleClass("fas fa-eye-slash").toggleClass("fas fa-eye");
    }
    function validateRegistration() {
      state.ui.error.validationList = [];
      state.ui.error.hasError = false;

      if (!state.data.registration.firstName) {
        state.ui.error.validationList.push("First name is required.");
      }
      //  if (!state.data.phone.isVerify) {
      //   state.ui.error.validationList.push("Please verify your mobile number.");
      // }
      if (!state.data.registration.lastName) {
        state.ui.error.validationList.push("Last name is required.");
      }
      if (!enteredValue.value) {
        state.ui.error.validationList.push("Primary is required.");
      } else {
        if (!state.ui.showUI.primaryVerified) {
          state.ui.error.validationList.push("Primary Verification is required.");
        }
      }
      if (state.data.registration.notification.sms && state.data.registration.notification.email) {

        if (!enteredSecondaryValue.value) {
          state.ui.error.validationList.push("Secondary is required.");
        } else {
          if (!state.ui.showUI.secondaryVerifiedOTP) {
            state.ui.error.validationList.push("Secondary Verification is required.");
          }
        }
      }

      // if (!state.data.registration.email) {
      //   state.ui.error.validationList.push("Email address is required.");
      // } else if (
      //   !validationService.isValidEmail(common.lowerCase(state.data.registration.email))
      // ) {
      //   state.ui.error.validationList.push("Email address is not valid.");
      // }
      // if (!state.data.registration.phone) {
      //   state.ui.error.validationList.push("Phone Number is required.");
      // } else {
      //   if (!validatePhoneNumber(state.data.phone.code + '' + state.data.registration.phone)) {
      //     state.ui.error.validationList.push("Not Valid Number.");
      //   }
      // }

      // if (state.data.registration.notification.sms && !state.data.registration.phone) {
      //   state.ui.error.validationList.push("Mobile Number is required.");
      // } else {
      //   if (state.data.registration.notification.sms && !validatePhoneNumber(state.data.phone.mobileCode + '' + state.data.registration.mobile)) {
      //     state.ui.error.validationList.push("Not Valid Number.");
      //   }
      // }

      // if (state.data.registration.notification.sms && !state.data.phone.isVerify) {
      //   state.ui.error.validationList.push("Please verify your mobile number.");
      // }
      // if (!state.data.registration.password) {
      //   state.ui.error.validationList.push("Password is required.");
      // }
      // if (!state.data.registration.passwordConfirmation) {
      //   state.ui.error.validationList.push(
      //     "Password Confirmation is required."
      //   );
      // }
      // if (
      //   state.data.registration.password &&
      //   state.data.registration.passwordConfirmation
      // ) {
      //   if (
      //     state.data.registration.password !=
      //     state.data.registration.passwordConfirmation
      //   ) {
      //     state.ui.error.validationList.push(
      //       "Password & Password Confirmation doesnot match."
      //     );
      //   }
      // }

      if (
        !state.ui.error.validationList &&
        !state.ui.error.validationList.length
      ) {
        state.ui.error.hasError = false;
      } else if (state.ui.error.validationList.length != 0) {
        state.ui.error.hasError = true;
      }
    }
    function redirectToLogIn() {
      if (getRouter.query.uuid && getRouter.query.type === "invitation") {
        router.push({
          name: "admin-login",
          query: { type: getRouter.query.type, uuid: getRouter.query.uuid, email: getRouter.query.email },
        });
      } else if (getRouter.query.ref) {
        router.push({
          name: "admin-login",
          query: { ref: getRouter.query.ref },
        });
      } else {
        router.push({
          name: "admin-login",
        });
      }
    }
    function closeSuccessModal() {
      state.data.showSuccesModal = !state.data.showSuccesModal;
      $("#successfulModal").modal("hide");
    }
    function hideRegisterSuccessModal() {
      $("#registerSuccessModal").modal("hide");
      state.ui.showUI.primaryInputClass = "col-md-8"
      state.ui.showUI.primaryIconClass = "col-md-4"
      state.ui.showUI.secondaryInputClass = "col-md-8"
      state.ui.showUI.secondaryIconClass = "col-md-4"
      if (state.data.success) {
        // redirectToLogIn();
        emit("registrationSuccess");
      }
    }

    function resetCheck() {
      state.data.registration.mobile = "";
      state.data.phone.isVerify = false;
      state.data.phone.otp = "";
    }
    function handleValidEmail(email: string) {
      enteredSecondaryValue.value = email;
    }

    function register() {
      // console.log(
      //   "Check the format",
      //   validatePhoneNumber(state.data.registration.mobile)
      // );
      validateRegistration();
      if (
        state.ui.error.validationList.length != 0 ||
        state.ui.postLoading.register ||
        state.ui.error.hasError
      )
        return false;
      let payLoad: any = {};
      let loginWith = "";
      let email = "";
      let mobileNumber = "";
      if (state.data.isPrimaryEmail) {
        loginWith = 'email'

        // email= enteredValue.value;
        // // eslint-disable-next-line @typescript-eslint/camelcase
        // mobileNumber = common.filterPhoneNumber(enteredSecondaryValue.value);
        email = enteredValue.value;
        // eslint-disable-next-line @typescript-eslint/camelcase
        mobileNumber = common.filterPhoneNumber(enteredSecondaryValue.value);
      } else {
        // enteredSecondaryValue.value
        loginWith = 'mobile_number'
        email = enteredSecondaryValue.value;
        // eslint-disable-next-line @typescript-eslint/camelcase
        mobileNumber = common.filterPhoneNumber(enteredValue.value);
      }

      let packageId = 0;
      if (state.data.registrationDetails.packages && state.data.registrationDetails.packages.length) {
        packageId = state.data.registrationDetails.packages[0].id;
      }
      if (getRouter.query.uuid && getRouter.query.type === "invitation") {
        payLoad = {
          // eslint-disable-next-line @typescript-eslint/camelcase
          invitation_id: getRouter.query.uuid,
          // eslint-disable-next-line @typescript-eslint/camelcase
          first_name: state.data.registration.firstName,
          // eslint-disable-next-line @typescript-eslint/camelcase
          middle_name: state.data.registration.middleName,
          // eslint-disable-next-line @typescript-eslint/camelcase
          last_name: state.data.registration.lastName,
          // eslint-disable-next-line @typescript-eslint/camelcase
          dial_code: state.data.phone.code,
          // eslint-disable-next-line @typescript-eslint/camelcase
          dial_code_mobile: state.data.phone.mobileCode,
          // eslint-disable-next-line @typescript-eslint/camelcase
          is_mobile_verified: state.data.phone.isVerify,
          // contact_number: `+${state.data.phone.countryCode}${state.data.registration.mobile}`,
          // eslint-disable-next-line @typescript-eslint/camelcase
          contact_number: state.data.registration.phone,
          email: email,
          // eslint-disable-next-line @typescript-eslint/camelcase
          mobile_number: mobileNumber,
          // password: state.data.registration.password,
          // // eslint-disable-next-line @typescript-eslint/camelcase
          // password_confirmation: state.data.registration.passwordConfirmation,
          // eslint-disable-next-line @typescript-eslint/camelcase
          sms_notification: state.data.registration.notification.sms ? 1 : 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          email_notification: state.data.registration.notification.email
            ? 1
            : 0,
          ref: getRouter.query.ref ? getRouter.query.ref : '',
          // package_id: state.data.selectedSubscriptionId,
          // eslint-disable-next-line @typescript-eslint/camelcase
          package_id: packageId,
          // eslint-disable-next-line @typescript-eslint/camelcase
          login_with: loginWith
        };
      } else {
        payLoad = {
          email: email,
          // eslint-disable-next-line @typescript-eslint/camelcase
          mobile_number: mobileNumber,
          // eslint-disable-next-line @typescript-eslint/camelcase
          first_name: state.data.registration.firstName,
          // eslint-disable-next-line @typescript-eslint/camelcase
          middle_name: state.data.registration.middleName,
          // eslint-disable-next-line @typescript-eslint/camelcase
          last_name: state.data.registration.lastName,
          // eslint-disable-next-line @typescript-eslint/camelcase
          dial_code: state.data.phone.code,
          // eslint-disable-next-line @typescript-eslint/camelcase
          is_mobile_verified: state.data.phone.isVerify,
          // contact_number: `+${state.data.phone.countryCode}${state.data.registration.mobile}`,
          // eslint-disable-next-line @typescript-eslint/camelcase
          contact_number: state.data.registration.phone,
          // eslint-disable-next-line @typescript-eslint/camelcase
          dial_code_mobile: state.data.phone.mobileCode,
          // email: enteredValue.value,
          // password: state.data.registration.password,
          // // eslint-disable-next-line @typescript-eslint/camelcase
          // password_confirmation: state.data.registration.passwordConfirmation,
          // eslint-disable-next-line @typescript-eslint/camelcase
          sms_notification: state.data.registration.notification.sms ? 1 : 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          email_notification: state.data.registration.notification.email
            ? 1
            : 0,
          ref: getRouter.query.ref ? getRouter.query.ref : '',
          // package_id: state.data.selectedSubscriptionId,
          // eslint-disable-next-line @typescript-eslint/camelcase
          package_id: packageId,
          // eslint-disable-next-line @typescript-eslint/camelcase
          login_with: loginWith
        };
      }
      console.log("PAYLOAD CHECK ", payLoad);
      state.ui.postLoading.register = true;
      ethitransService
        .registration(payLoad)
        .then((res: any) => {
          // console.log("Registration Response Check:>>> ", res.data.data);
          // state.userList = res.data.data ? res.data.data : [];
          state.data.success = true;
          state.data.registration.firstName = "";
          state.data.registration.middleName = "";
          state.data.registration.lastName = "";
          state.data.registration.phone = "";
          state.data.registration.mobile = "";
          state.data.registration.email = "";
          state.data.registration.password = "";
          state.data.registration.passwordConfirmation = "";
          state.data.registration.notification.sms = false;
          state.data.registration.notification.email = false;
          state.data.selectedSubscriptionId = 0;
          $("#registerSuccessModal").modal("show");
        })
        .catch((error: any) => {
          if (error.response.status === 406) {
            $("#replaceRegister").modal("show");
            common
              .sdCatchErr(error, state.ui.error.registerReplaceList)
              .then((res: any) => {
                state.ui.error.hasError = true;
                for (const [key, value] of Object.entries(res)) {
                  if (Array.isArray(value)) {
                    state.ui.error.registerReplaceList.push(value[0]);
                  }
                }
              });
          }
          common
            .sdCatchErr(error, state.ui.error.validationList)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.validationList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.postLoading.register = false;
        });
    }
    function getCountryCodeList() {
      console.log("getprojectList WOWO:>>>");
      const payLoad = {};
      ethitransService
        .getCountryCodeList(payLoad)
        .then((res: any) => {
          state.countryCodeList = res.data.data ? res.data.data : [];
          // state.selectedFormForEdit = res.data ? res.data : {};
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          // state.ui.postLoading.getprojectList = false;
        });
    }
    // registrationsDetailList
    function getRegistrationsDetailList(data: any) {
      console.log("getprojectList WOWO:>>>");
      ethitransService
        .registrationsDetailList(data)
        .then((res: any) => {
          console.log("getRegistrationsDetailList Check:>>> ", res.data.data);
          state.data.registrationDetails = res.data.data;
          if (res.data.data.refferal != undefined) {
            state.data.selectedSubscriptionId = res.data.data.refferal.package_id;
          }
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          // state.ui.postLoading.getprojectList = false;
        });
    }
    function countDownTimer() {
      const interval = setInterval(() => {
        if (state.data.timeCount === 0) {
          state.ui.sendOtpButton = true;
          state.ui.inputOtp = false;
          clearInterval(interval);
          state.data.timeCount = 130;
          // state.data.phone.code = state.data.phone.code;
          // getCountryCodeList();
        } else {
          state.data.timeCount--;
        }
      }, 1000);
    }

    function sendOtp() {
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        mobile_number: state.data.registration.mobile,
        // eslint-disable-next-line @typescript-eslint/camelcase
        dial_code_mobile: state.data.phone.mobileCode,
      };
      state.ui.verifyButton = false;

      ethitransService
        .sendOtp(payLoad)
        .then((res: any) => {
          console.log("meesaom", res.code);

          state.countryCodeList = res.data.data ? res.data.data : [];
          // state.data.phone.code = res.data.data.dial_code;
          state.ui.inputOtp = true;
          state.ui.sendOtpButton = false;
          getCountryCodeList();
          countDownTimer();
          state.ui.error.validationList = [];
          state.data.phone.otp = "";

          // interval;
        })
        .catch((error: any) => {
          console.log(error);
          common
            .sdCatchErr(error, state.ui.error.validationList)
            .then((res: any) => {
              state.ui.error.validationList = [];
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.validationList.push(value[0]);
                }
              }
            });
          // state.ui.error.hasError = true;
          // state.ui.error.validationList = [];
          // state.ui.error.validationList.push(
          //   "pleae check your mobile number or country code."
          // );
        })
        .finally(() => {
          state.ui.verifyButton = true;
        });
    }

    function verifyMobile() {
      state.ui.error.hasError = false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        mobile_number: state.data.registration.mobile,
        // eslint-disable-next-line @typescript-eslint/camelcase
        dial_code_mobile: state.data.phone.mobileCode,
        otp: state.data.phone.otp,
      };
      ethitransService
        .verifyMobile(payLoad)
        .then((res: any) => {
          state.countryCodeList = res.data.data ? res.data.data : [];
          // state.data.phone.mobileCode = state.data.phone.mobileCode;
          getCountryCodeList();
          if (res.data.data.is_verify == true) {
            state.data.phone.isVerify = true;
            state.ui.inputOtp = false;
            state.ui.error.hasError = false;
          } else {
            state.data.phone.isVerify = false;
            state.ui.error.hasError = true;
            state.ui.error.validationList = [];
            state.ui.error.validationList.push("OTP didnot match.");
          }
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          // state.ui.postLoading.getprojectList = false;
        });
    }

    function checkInputValue() {
      console.log("Checking input value", state.data.phone.otp.length);
      if (state.data.phone.otp.length == 5) {
        verifyMobile();
      }
    }

    function registerReplace() {
      state.ui.error.registerReplaceList = [];
      state.ui.error.hasError = true;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        invitation_id: getRouter.query.uuid,
        // eslint-disable-next-line @typescript-eslint/camelcase
        first_name: state.data.registration.firstName,
        // eslint-disable-next-line @typescript-eslint/camelcase
        middle_name: state.data.registration.middleName,
        // eslint-disable-next-line @typescript-eslint/camelcase
        last_name: state.data.registration.lastName,
        // eslint-disable-next-line @typescript-eslint/camelcase
        dial_code: state.data.phone.code,
        // eslint-disable-next-line @typescript-eslint/camelcase
        is_mobile_verified: state.data.phone.isVerify,
        // contact_number: `+${state.data.phone.countryCode}${state.data.registration.mobile}`,
        // eslint-disable-next-line @typescript-eslint/camelcase
        contact_number: state.data.registration.phone,
        // eslint-disable-next-line @typescript-eslint/camelcase
        dial_code_mobile: state.data.phone.mobileCode,
        // eslint-disable-next-line @typescript-eslint/camelcase
        mobile_number: state.data.registration.mobile,
        email: state.data.registration.email,
        password: state.data.registration.password,
        // eslint-disable-next-line @typescript-eslint/camelcase
        password_confirmation: state.data.registration.passwordConfirmation,
        // eslint-disable-next-line @typescript-eslint/camelcase
        sms_notification: state.data.registration.notification.sms ? 1 : 0,
        // eslint-disable-next-line @typescript-eslint/camelcase
        email_notification: state.data.registration.notification.email ? 1 : 0,
      };
      ethitransService
        .registerReplace(payLoad)
        .then((res: any) => {
          // console.log("Registration Response Check:>>> ", res.data.data);
          // state.userList = res.data.data ? res.data.data : [];
          state.data.success = true;
          state.data.registration.firstName = "";
          state.data.registration.middleName = "";
          state.data.registration.lastName = "";
          state.data.registration.phone = "";
          state.data.registration.mobile = "";
          state.data.registration.email = "";
          state.data.registration.password = "";
          state.data.registration.passwordConfirmation = "";
          state.data.registration.notification.sms = false;
          state.data.registration.notification.email = false;
          state.data.showSuccesModal = !state.data.showSuccesModal;
          state.data.successFulMessage = "Registation resend successfully.";
          hideReplaceRegister();
          state.ui.error.validationList = [];
          state.ui.error.hasError = false;
          // $("#registerSuccessModal").modal("show");
          // router.push({
          //   name: "admin-login",
          // });
        })
        .catch((error: any) => {
          if (error.response.status === 406) {
            $("#replaceRegister").modal("show");
          }
          common
            .sdCatchErr(error, state.ui.error.registerReplaceList)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.registerReplaceList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.postLoading.register = false;
        });
    }
    // Watch the clear prop to clear the input value when it's set to true
    watch(() => props.tab, (newValue: any) => {
      if (newValue) {
        if (props.tab === 'create-account') {
          alert("HI")
        }
      }
    });
    function dataClear() {
      // set the class
      if (state.ui.showUI.primaryInputClass === "col-md-12" || state.ui.showUI.primaryInputClass === "col-md-10") {
        state.ui.showUI.changePrimaryInput = false;
      }
      // if(!state.data.isPrimaryEmail && state.ui.showUI.primaryOTPSent){
      //   state.ui.disabled.disableSmsCheckbox = false;
      //   state.data.registration.notification.sms = false;
      // }
      // if(state.data.isPrimaryEmail && state.ui.showUI.primaryOTPSent){
      //   state.ui.disabled.disableEmailCheckbox = false;
      //   state.data.registration.notification.email = false;
      // }
      state.ui.showUI.primaryInputClass = "col-md-8"
      state.ui.showUI.primaryIconClass = "col-md-4"
      state.ui.showUI.primaryOTPSent = false;
      state.ui.showUI.showOTP = false;
      state.ui.disabled.primary = false;
      state.ui.showUI.primaryVerified = false;
      state.ui.error.validationList = [];
      state.ui.error.hasError = false;
      state.ui.showUI.notificationBy = false;




      // reset
      state.ui.showUI.showEmailInput = false;
      state.ui.showUI.showMobileNumber = false;
      enteredSecondaryValue.value = "";
    }
    function dataSecondaryClear() {
      if (state.ui.showUI.secondaryInputClass === "col-md-12" || state.ui.showUI.secondaryInputClass === "col-md-10") {
        state.ui.showUI.changeSecondaryInput = false;
      }
      state.ui.showUI.secondaryInputClass = "col-md-8"
      state.ui.showUI.secondaryIconClass = "col-md-4"
      state.ui.showUI.secondaryOTPSent = false;
      state.ui.showUI.showSecondaryOTP = false;
      state.ui.disabled.secondary = false;
      state.ui.showUI.secondaryVerifiedOTP = false;
      state.ui.error.validationList = [];
      state.ui.error.hasError = false;
      // state.ui.showUI.secondaryVerifiedDisabled
    }
    function checkPrimaryOtp(otp: number) {
      state.ui.error.validationList = [];
      state.ui.error.hasError = false;
      if (state.ui.postLoading.verifyPrimary) return false;

      const payLoad = {
        client: common.filterPhoneNumber(enteredValue.value),
        otp: otp
      };
      if (validationService.isValidEmail(enteredValue.value)) {
        payLoad.client = enteredValue.value;
      } else {
        payLoad.client = common.filterPhoneNumber(enteredValue.value)
      }
      state.ui.postLoading.verifyPrimary = true;

      console.log("Checkpayload >>> ", payLoad)
      ethitransService
        .verifyOtpCode(payLoad)
        .then((res: any) => {
          state.ui.disabled.primary = true;
          state.ui.postLoading.verifyPrimary = false;
          state.ui.showUI.primaryVerified = true;
          state.ui.showUI.showOTP = false;
          if (state.data.isPrimaryEmail) {
            state.data.registration.notification.email = true;
          } else {
            state.data.registration.notification.sms = true;
          }
          console.log("CHECK the payload >>> ", res.data.data)

          // set the class
          state.ui.showUI.primaryInputClass = "col-md-10"
          state.ui.showUI.primaryIconClass = "col-md-2"
          state.ui.showUI.changePrimaryInput = true;
          state.ui.showUI.primaryOTPSent = false

          state.ui.showUI.notificationBy = true;
          // state.memberList = res.data.data ? res.data.data : [];
        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.ui.error.validationList)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.validationList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.postLoading.verifyPrimary = false;
        });
    }
    const handleSixDigitOTP = (otp: number) => {
      // console.log("CHECK THE OTP >>> ", otp)
      checkPrimaryOtp(otp);
    }
    function checkSecondaryOtp(otp: number) {
      state.ui.error.validationList = [];
      state.ui.error.hasError = false;
      if (state.ui.postLoading.checkSecondaryOtp) return false;

      const payLoad = {
        client: common.filterPhoneNumber(enteredSecondaryValue.value),
        otp: otp
      };
      if (validationService.isValidEmail(enteredSecondaryValue.value)) {
        payLoad.client = enteredSecondaryValue.value;
      } else {
        payLoad.client = common.filterPhoneNumber(enteredSecondaryValue.value)
      }
      state.ui.postLoading.checkSecondaryOtp = true;

      console.log("Checkpayload >>> ", payLoad)
      ethitransService
        .verifyOtpCode(payLoad)
        .then((res: any) => {
          state.ui.postLoading.checkSecondaryOtp = false;
          state.ui.showUI.secondaryVerifiedDisabled = true;
          state.ui.showUI.secondaryVerifiedOTP = true;
          state.ui.showUI.showSecondaryOTP = false;
          state.ui.disabled.secondary = true;
          state.ui.showUI.primaryVerified = true;
          // if (state.data.isSecondaryEmail) {
          //   state.data.registration.notification.email = true;
          // }
          // console.log("CHECK the payload >>> ", res.data.data)
          // state.memberList = res.data.data ? res.data.data : [];
          // set the class
          state.ui.showUI.secondaryInputClass = "col-md-10"
          state.ui.showUI.secondaryIconClass = "col-md-2"
          state.ui.showUI.changeSecondaryInput = true;
          state.ui.showUI.secondaryOTPSent = false
        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.ui.error.validationList)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.validationList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.postLoading.checkSecondaryOtp = false;
        });

    }
    const handleSixDigitOTPSeonndary = (otp: number) => {
      checkSecondaryOtp(otp)
    }
    onMounted(() => {
      // state.data.registration.notification.email = true;
      // state.data.registration.notification.sms = true;
      state.data.imageBaseUrl = config.imageBaseUrl;
      state.data.registration.email = getRouter.query.email;
      getCountryCodeList();

      if (getRouter.query.ref) {
        getRegistrationsDetailList({ 'ref': getRouter.query.ref });
      } else {
        getRegistrationsDetailList({});
      }
      // insertContryCode()
      //  $(".modal-backdrop").remove();
      // if(props.tab){

      // }
    });

    const handleInput = (value: any) => {

      enteredValue.value = value; // Update the entered value from child
      errorMessage.value = ''; // Clear error if input is valid
      if (validationService.isValidEmail(enteredValue.value)) {
        state.data.isPrimaryEmail = true;
      } else {
        state.data.isPrimaryEmail = false;
      }
    };
    const handleSecondaryInput = (value: any) => {
      enteredSecondaryValue.value = value; // Update the entered value from child
      errorMessage.value = ''; // Clear error if input is valid
      if (validationService.isValidEmail(enteredSecondaryValue.value)) {
        state.data.isSecondaryEmail = true;
      } else {
        state.data.isSecondaryEmail = false;
      }
    };

    const handleError = (error: any) => {
      errorMessage.value = error; // Capture the error emitted by the child
    };
    const handleClear = () => {
      enteredValue.value = ''; // Clear the entered value when input is cleared
      errorMessage.value = ''; // Optionally clear the error message
      state.ui.error.validationList = [];
      state.ui.error.hasError = false;
    };
    const handleSecondaryError = (error: any) => {
      errorMessage.value = error; // Capture the error emitted by the child
    };
    const handleSecondaryClear = () => {
      enteredSecondaryValue.value = ''; // Clear the entered value when input is cleared
      errorMessage.value = ''; // Optionally clear the error message
    };
    // eslint-disable-next-line @typescript-eslint/camelcase
    const nameWithId = ({ name, id }: any) => {
      // eslint-disable-next-line @typescript-eslint/camelcase
      return name ? `${name}` : '';
    };
    function checkOtpMessage(array: any) {
      return array[0] === "The OTP has already been sent.";
    }
    const formatMobileNumber = (number: any) => {
      return `+1 (${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(6)}`;
    };
    function keepFirstItemOnly(array: any) {
      if (array.length > 1) {
        // Keep only the first item at index 0 and remove the rest
        array.splice(1);
      }
      return array;
    }
    const validateInput = () => {
      // let errorMessage = '';
      const emailPattern = /^[\w.-]+@([\w-]+\.)+[\w-]{2,}$/;
      const mobilePattern = /^\+1 \(\d{3}\) \d{3}-\d{4}$/;


      if (emailPattern.test(enteredValue.value)) {
        // emit('validation-result', true); // Emit true if valid email
      } else if (/^\d{10}$/.test(enteredValue.value)) {
        enteredValue.value = formatMobileNumber(enteredValue.value);
        // emit('validation-result', true); // Emit true if valid unformatted mobile
      }
      else if (mobilePattern.test(enteredValue.value)) {
        // emit('validation-result', true); // Emit true if valid formatted mobile
      }
      else {
        // errorMessage = 'Invalid input. Please enter a valid email or a mobile number with exactly 10 digits.';
        state.ui.error.validationList.push("Invalid input. Please enter a valid email or a mobile number with exactly 10 digits.");
        state.ui.error.hasError = true;
        // emit('emit-error', errorMessage);
        // emit('validation-result', false); // Emit false if invalid
      }
    };
    function validatePrimary(){
      state.ui.error.validationList = [];
      state.ui.error.hasError = false;

      if (!enteredValue.value) {
        state.ui.error.validationList.push("Email/Mobile Number is required.");
      } else {
        validateInput();
      }

      if (
        !state.ui.error.validationList &&
        !state.ui.error.validationList.length
      ) {
        state.ui.error.hasError = false;
      } else if (state.ui.error.validationList.length != 0) {
        state.ui.error.hasError = true;
      }
    }
    function verifyPrimary() {
      state.ui.disabled.disableSmsCheckbox = false;
      state.data.registration.notification.sms = false;
      state.ui.disabled.disableEmailCheckbox = false;
      state.data.registration.notification.email = false;


      state.ui.error.validationList = [];
      state.ui.error.hasError = false;
      validatePrimary();
      if (state.ui.error.validationList && state.ui.error.validationList.length) return false;
      if (state.ui.postLoading.verifyPrimary) return false;

      const payLoad = {
        client: common.filterPhoneNumber(enteredValue.value),
      };
      if (validationService.isValidEmail(enteredValue.value)) {
        payLoad.client = enteredValue.value;
      } else {
        payLoad.client = common.filterPhoneNumber(enteredValue.value)
      }
      state.ui.postLoading.verifyPrimary = true;

      console.log("Checkpayload >>> ", payLoad)
      ethitransService
        .sendOtpCode(payLoad)
        .then((res: any) => {
          // set the class
          state.ui.showUI.primaryInputClass = "col-md-12"
          state.ui.showUI.changePrimaryInput = true;
          state.ui.showUI.primaryOTPSent = true;
          state.ui.showUI.counter = true;
          state.ui.showUI.primaryVerified = true;
          if (validationService.isValidEmail(enteredValue.value)) {
            state.ui.disabled.disableEmailCheckbox = true;
          } else {
            state.ui.disabled.disableSmsCheckbox = true;
          }
          state.ui.postLoading.verifyPrimary = false;
          state.ui.showUI.showOTP = true;
          state.ui.showUI.showChangeButton = true;
          state.ui.disabled.primary = true;
          console.log("CHECK the payload >>> ", res.data.data)
          state.passCode.primarySuccessMessage = "Pass Code send successfuly.";
          setTimeout(() => {
            state.passCode.primarySuccessMessage = "";
            // state.ui.showUI.resendPrimaryOTP = true;
          }, 2000);
          // state.memberList = res.data.data ? res.data.data : [];
        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.ui.error.validationList)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.validationList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.postLoading.verifyPrimary = false;
          // alert("ERROR");
          console.log("CHEK 123 ", checkOtpMessage(state.ui.error.validationList))
          if (checkOtpMessage(state.ui.error.validationList)) {
            state.ui.showUI.showOTP = true;
            state.ui.showUI.counter = true;
            state.data.setCounter = state.ui.error.validationList[1];
            keepFirstItemOnly(state.ui.error.validationList);
          }
        });
    }
    function verifyAgainPrimary() {
      state.data.setCounter = 90;
      state.ui.showUI.counter = true;
      state.ui.showUI.resendPrimaryOTP = false;
      verifyPrimary();

    }

    function verifySecondary() {
      state.ui.error.validationList = [];
      state.ui.error.hasError = false;
      if (state.ui.postLoading.verifySecondary) return false;
      console.log("CHECK <>>> ", enteredSecondaryValue.value)

      const payLoad = {
        client: common.filterPhoneNumber(enteredSecondaryValue.value),
      };
      if (validationService.isValidEmail(enteredSecondaryValue.value)) {
        payLoad.client = enteredSecondaryValue.value;
      } else {
        payLoad.client = common.filterPhoneNumber(enteredSecondaryValue.value)
      }
      state.ui.postLoading.verifySecondary = true;

      console.log("Checkpayload >>> ", payLoad)
      ethitransService
        .sendOtpCode(payLoad)
        .then((res: any) => {
          state.ui.showUI.secondaryInputClass = "col-md-12"
          state.ui.showUI.changeSecondaryInput = true;
          state.ui.postLoading.verifySecondary = false;
          state.ui.showUI.showSecondaryOTP = true;
          state.ui.showUI.secondaryOTPSent = true;
          state.ui.disabled.secondary = true;
          state.ui.showUI.counterSecondary = true;
          state.ui.showUI.showSecondaryOTPVerified = true;
          console.log("CHECK the payload >>> ", res.data.data)
          state.passCode.secondarySuccessMessage = "Pass Code send successfuly.";
          setTimeout(() => {
            state.passCode.secondarySuccessMessage = "";
          }, 9000);
          // state.memberList = res.data.data ? res.data.data : [];
        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.ui.error.validationList)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.validationList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.postLoading.verifySecondary = false;
          console.log("CHEK 123 ", checkOtpMessage(state.ui.error.validationList))
          if (checkOtpMessage(state.ui.error.validationList)) {
            state.ui.showUI.showSecondaryOTP = true;
            state.ui.showUI.counterSecondary = true;
            state.data.setSecondaryCounter = state.ui.error.validationList[1];
            keepFirstItemOnly(state.ui.error.validationList);
          }
        });
    }
    function verifyAgainSecondary() {
      state.ui.showUI.showSecondaryOTP = true;
      state.data.setSecondaryCounter = 90;
      state.ui.showUI.counterSecondary = true;
      state.ui.showUI.resendSecondaryOTP = false;
      verifySecondary();

    }

    const handleStatusUpdateForPrimary = (value: any) => {
      if (value === true) {
        state.ui.showUI.resendPrimaryOTP = true;
      }
    }
    const handleStatusUpdateForSecondary = (value: any) => {
      if (value === true) {
        state.ui.showUI.resendSecondaryOTP = true;
      }
    }
    function handleValidationResult(isValid: any) {
      state.data.validInput = isValid;
      // if (isValid) {
      //   console.log("Valid input received.");
      // } else {
      //   state.data.validInput = isValid;
      //   console.log("Invalid input.");
      // }
    }
    function handleSecondaryValidationResult(isValid: any) {
      console.log("CHECK THE >>>",  isValid)
      state.data.validSecondaryInput = isValid;
      // if (isValid) {
      //   console.log("Valid input received.");
      // } else {
      //   state.data.validInput = isValid;
      //   console.log("Invalid input.");
      // }
    }
    const buttonhasValidPrimaryInput = computed(() => {
      if (!enteredValue.value) return false;
      // return state.data.validInput;
      return true;
    });
    const buttonhasValidSecondaryInput = computed(() => {
      if (!enteredSecondaryValue.value) return false;
      return state.data.validSecondaryInput;
    });
    function handleEmailError(errorMessage: any) {
      console.error(errorMessage);
      state.data.secondaryErrorMessage = errorMessage;
    }
    function clearError() {
      state.data.secondaryErrorMessage = '';
    }
    // 
    return {
      state,
      formatMobileNumber,
      validateInput,
      validatePrimary,
      clearError,
      handleEmailError,
      handleSecondaryValidationResult,
      buttonhasValidPrimaryInput,
      buttonhasValidSecondaryInput,
      handleValidationResult,
      verifyAgainSecondary,
      keepFirstItemOnly,
      checkOtpMessage,
      handleStatusUpdateForPrimary,
      handleStatusUpdateForSecondary,
      handleSixDigitOTPSeonndary,
      handleSixDigitOTP,
      dataSecondaryClear,
      handleValidEmail,
      enteredSecondaryValue,
      handleSecondaryClear,
      handleSecondaryError,
      checkNotificationSms,
      checkNotificationEmail,
      verifySecondary,
      checkSecondaryOtp,
      checkPrimaryOtp,
      nameWithId,
      handleClear,
      enteredValue,
      errorMessage,
      handleInput,
      handleSecondaryInput,
      handleError,
      verifyPrimary,
      verifyAgainPrimary,
      isEmail,
      getRegistrationsDetailList,
      closeSuccessModal,
      registerReplace,
      hideReplaceRegister,
      insertContryCode,
      passwordStrengthCheck,
      passwordConfirmationStrengthCheck,
      onInput,
      countryChanged,
      validatePhoneNumber,
      errorList,
      register,
      hideRegisterSuccessModal,
      validateRegistration,
      redirectToLogIn,
      switchVisibility,
      passwordStatus,
      switchVisibilityConfirm,
      getCountryCodeList,
      sendOtp,
      verifyMobile,
      checkInputValue,
      countDownTimer,
      resetCheck,
      dataClear

    };
  },
});
