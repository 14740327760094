
import { defineComponent, reactive, onMounted, computed, watch } from "vue";
import { ethitransService } from "@/services/EthitransService";
import SuccessfulModalCustom from "@/components/modal/SuccessfulModalCustom.vue";
import EmptyData from "@/components/commonDesign/EmptyData.vue";
import { useRouter } from "vue-router";

// import FormView from "@/components/cards/FormView.vue";
export default defineComponent({
  name: "BusinessMailLog",
  components: {
    EmptyData,
    SuccessfulModalCustom,
  },
  setup() {
    const router = useRouter();
    const state = reactive({
      mailLogList: [] as any,
       userList: [] as any,
      templateList:[] as any,
      checkUser: [] as any,
      isCheckedAll: false,
      email: "" as any,
      subject: "" as any,
      message: "" as any,
      logId: "" as any,
      mailMessage:"" as any,

isSentMail:false,
      filter: {
        selected: '',
      },
      sortList: [
        {
          name: "All",
        },
        {
          name: "Online",
        },
        {
          name: "Offline",
        },
      ],
      data: {
        SuccessFulMessage: "",
        showSuccesCustomModal: false,
        mailData: {} as any,
        showNotificationMailModel: false,
        selectedUser: {} as any,
        title: "Business Mail Sent",
        heading: "No Business mail sent found .",
        paging: {
          clientDocumentList: {
            perPage: 10,
            totalDocs: 0,
            totalPages: 0,
            currentPage: 1,
          },
        },
      },
      ui: {
        isLoading: {},
        isPostLoading: {
          getUserActivityList: false,
          saveEditUser: false,
        },
         error: {
          validationList: [] as any,
          hasError: false,
        },
      },
    });

 
  
 function showMailTemplate(template: any) {
       state.mailMessage = template.mail_message;
         return $("#mailTemplate").modal("show");
     
    }

   function hideMailTemplate() {
      $("#mailTemplate").modal("hide");
    }

    function allBusinessMailLog() {
      ethitransService
        .businessMailLogList()
        .then((res: any) => {
          console.log("getProjectRoleList Response Check:>>> ", res.data.data);
          state.mailLogList = res.data.data ? res.data.data : [];
      
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          // state.ui.isPostLoading.getUserActivityList = false;
        });
    }





 function checkAll() {
      state.isCheckedAll = !state.isCheckedAll;
            state.isSentMail = true;

      state.checkUser = [];
      if (state.isCheckedAll) {
        // Check all
        for (const user in state.userList) {
          state.checkUser.push(state.userList[user].user_id);
        }
        console.log("Check Online user", state.checkUser);
        if (state.checkUser.length == 0) {
        state.isSentMail = false;
      }
      }
    }
    function updateCheckall() {
      if (state.checkUser.length == state.userList.length) {
        state.isCheckedAll = true;
                    state.isSentMail = true;

      } else {
        state.isCheckedAll = false;
                    state.isSentMail = true;

      }
      if (state.checkUser.length == 0) {
                    state.isSentMail = false;

      }
    }

    function showSendMail(template: any) {
        state.userList = template.log_user;
        state.subject= template.subject;
        state.message= template.mail_message;
        state.logId= template.id;

      //  allActiveUser();
      if (
        state.ui.error.validationList.length != 0||state.ui.error.hasError
      )
        return false; 
        else
         return $("#sendMail").modal("show");
     
      
    }

   function hideSendMail() {
      $("#sendMail").modal("hide");
    }

    function closeSuccessCustomModal() {
      state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
      $("#SuccessfulModalCustom").modal("hide");
    }
    function sendMail() {
      state.ui.isPostLoading.getUserActivityList = true;
      // console.log("HELLO GROM ROLE LIST FROM PROJET DETAILS");
      const payLoad = {
        subject: state.subject,
        message: state.message,
        users: state.checkUser,
        template: state.filter.selected,
                // eslint-disable-next-line @typescript-eslint/camelcase
        log_id:state.logId,
      };
      ethitransService
        .resendBusinessMail(payLoad)
        .then((res: any) => {
          console.log("getProjectRoleList Response Check:>>> ", res.data.data);
          hideSendMail();
          state.checkUser = [];
          state.subject = "";
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
          state.data.SuccessFulMessage = "Mail sent Successfully.";
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.getUserActivityList = false;
        });
    }
    function selectedTemplate(){
      state.message= state.filter.selected;
    }


    function createBusinessMailTemplate(){
      router.push({
          name: "create-business-mail-template",
        });
    }

  
    onMounted(() => {
      allBusinessMailLog();

    });
    return {
      state,
      allBusinessMailLog,
      createBusinessMailTemplate,
       selectedTemplate,
      showSendMail,
      hideSendMail,
      checkAll,
      updateCheckall,
      sendMail,
      closeSuccessCustomModal,
      showMailTemplate,
      hideMailTemplate
    };
  },
});
